import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
interface StateType {
    [key: string]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    location: any;
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openPictureUploadError: boolean,
    contactus: boolean;
    pdfselectopen:boolean,
    questionanswer: {
        data: [{
            attributes: {
                id: string;
                question: string,
                answer: string;
            }
        }]
    };
    email: string,
    subject: string,
    yourquery: string,
    file: any,
    selectedFile:any,
    open: boolean,
    complteopen : boolean,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    questionanswerApiCallid: string = "";
    contactusApiCallid: string = "";
    getProfileApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openPictureUploadError: false,
            open:false,
            complteopen:false,
            pdfselectopen:false,
            selectedFile:[],
            email: '',
            subject: '',
            yourquery: '',
            file: '',
            questionanswer: {
                data: [{
                    attributes: {
                        id: "",
                        question: "",
                        answer: ""
                    }
                }]
            },
            contactus: true

        }
        // Customizable Area Start

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area End
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.questionanswerApiCallid) {
                    this.helpQutionAnswer(responseJson);
                }
                if (apiRequestCallId === this.contactusApiCallid) {
                    console.log(responseJson)
                }
                if (apiRequestCallId === this.getProfileApiId) {
                    const dataTest = responseJson.data.attributes;
                    this.setState({
                        email: dataTest.email,
                      });
                }
            }
        }

    };

    async componentDidMount() {
        this.profileDataGet();
        this.getHelpAnswerData();
        const authToken = await getStorageData("authToken")
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
    };

    async componentDidUpdate(prevProps:any, prevState:any) {
        if (this.state.contactus && !prevState.contactus) {
          await this.getHelpAnswerData();
        }
      }
    // Customizable Area End

    // web events

    // Customizable Area Start
    contactusDataaValue = (values:{subject:string,yourquery:string}) => {
        this.setState({
            subject: values.subject,
            yourquery: values.yourquery,
           
        })
        
        this.contactusDataAdd(values)
        this.setState({ complteopen: true });
    };

    openPictureUpload = async () => {
        const inputPicherPortable = document.createElement('input');
        inputPicherPortable.type = 'file';
        inputPicherPortable.accept = ".jpg,.jpeg,.pdf"
        inputPicherPortable.multiple = true
        inputPicherPortable.click();
        inputPicherPortable.setAttribute('data-testid', 'file-input');
        inputPicherPortable.onchange = (event: any) => {
            this.openPictureUploadInSide(event);
        }
    };

    openPictureUploadInSide = async (event: any) => {
        const uplodeFilesArray = Array.from(event.target.files);
        uplodeFilesArray.forEach(async (file: any, index: number) => {
            const extet: [] = file.name.split('.')
            if (this.state.selectedFile.length < 5) {
                if (["jpg", "jpeg", "pdf"].includes(extet[extet.length - 1])) {
                    this.setState({ selectedFile: [...this.state.selectedFile, file] })
                } else {
                    this.setState({ pdfselectopen: true })
                }

            } else {
                this.setState({ open: true })
            }
        })
    };

    contactusPage = () => {
        this.setState({ contactus: false });
    };

    helpQutionAnswer = (responseJson: any) => {
        const answerData = responseJson;
        this.setState({ questionanswer: answerData })
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    complteclosed = () => {
        this.setState({ complteopen: false });
    };

    pdfValidationclosed = () => {
        this.setState({ pdfselectopen: false });
    };

    navigatehelp = () =>{
        this.setState({ contactus: true })
        this.setState({ complteopen: false });
    };

    propertyImageUpload = (formData: any) => {

        if (this.state.selectedFile?.length > 0) {
            this.state.selectedFile.forEach((it: any, index: number) => {
                it && formData.append("data[images][]", it);
            })
        }
        this.setState({selectedFile:[]})
    };

    handleRemoveMain = (ind: number) => {
        const newDoument = [...this.state.selectedFile]
        newDoument.splice(ind, 1)
        this.setState({ selectedFile: newDoument })
    };

    getBorderstyle = (touched?:boolean | undefined,errors?:string) =>{
     return touched && errors ? '1px solid red' : '1px solid rgb(203, 213, 225)'
    };

    contactusDataAdd = async (value : any) =>{
        const token = await getStorageData("authToken");

        const header = {
            token: token,
        };

        let formdata = new FormData();
        formdata.append("data[email]", this.state.email);
        formdata.append("data[subject]", value.subject);
        formdata.append("data[description]", value.yourquery);

        this.propertyImageUpload(formdata)


        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.contactusApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.contactusEndpoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    getHelpAnswerData = async () => {
        const token = await getStorageData("authToken");

        const header = {
            "Content-Type": configJSON.contactUsApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.questionanswerApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listingfaqsEndpoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    profileDataGet = async () => {
        const token = await getStorageData("authToken");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProfileApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProfileUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token,
            })
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod        
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
