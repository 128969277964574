import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  logBook,
  schedule,
  preferance,
  exportImg,
  reports,
  analytics,
  patientResources,
  settings,
  profile,
  help,
  logOut,
  logo,
  sideBarOpen,
  sideBarClose,
  keyicon,
  dashbord,
  crosicorn,
} from './assets';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Modal, styled } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";

import { IBlock } from "../../framework/src/IBlock";
import Cookies from "js-cookie";
import { setStorageData, getStorageData } from "../../framework/src/Utilities";
import { Link } from "react-router-dom";
interface PersistentDrawerLeftProps {
  navigation: any;
  isNavigation?:boolean | undefined;
  chnngesModal?: (link:string) => void
  code?: string | undefined;
  fillform?: boolean;
  showData?: (event: React.FormEvent) => void;
}

interface DrawerItem {
  src: string;
  text: string;
  link: string;
}

interface PersistentDrawerLeftState {
  isPrimiumEnded: boolean;
  inviteCode: string;
  userType: string;
  open: boolean;
  openModel: boolean;
  allData: DrawerItem[];
  openLink: string;
  innerWidth: number;
  filteredData:DrawerItem[];
  checkState: boolean;
  routeName: string;
  routeLink: string;
  checkButton: boolean;
  notPresentPopup:boolean;
}

class PersistentDrawerLeft extends Component<
  PersistentDrawerLeftProps,
  PersistentDrawerLeftState
> {
  logoutApiCallId: string = "";
  getProfileCallId: string = "";
  subScribedMessages: string[];

  constructor(props: PersistentDrawerLeftProps) {
    super(props);
    this.receive = this.receive.bind(this);
  this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
  ];

    this.state = {
      isPrimiumEnded: false,
      userType: "",
      inviteCode: "",
      open: false,
      openModel: false,
      allData: [
        { src: dashbord, text: "Dashboard", link: "Analytics" },
        { src: logBook, text: "Logbook", link: "Task" },
        { src: schedule, text: "Schedule Events", link: "EventsSchedule" },
        { src: preferance, text: "Preferences", link: "Preferences" },
        { src: exportImg, text: "Export PDF Logbook", link: "SaveAsPdf" },
        { src: reports, text: "Reports", link: "CustomForm" },
        { src: analytics, text: "Phe Level Trend", link: "Pheleveltrend" },
        {
          src: patientResources,
          text: "Patient Resources",
          link: "PatientResources",
        },
        { src: keyicon, text: 'Key Contacts', link: 'ContentManagement' },
        { src: settings, text: "Settings", link: "Settings" },
        { src: profile, text: "Profile", link: "CustomisableUserProfiles" },
        { src: help, text: "Help & Support", link: "Contactus" },
      ],
      openLink: "",
      innerWidth: window.innerWidth,
      filteredData:[],
      checkState:false,
      routeName: "",
      routeLink: "",
      checkButton:false,
      notPresentPopup:false
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock , this.subScribedMessages);
  }

  async componentDidMount() {
    const meta = await getStorageData("meta")
    const parseMeta = JSON.parse(meta)
    const token = await getStorageData("authToken") || parseMeta.token
    if (token) {
      this.getProfile(token)
    }else {
      this.props.navigation.navigate("EmailAccountLoginBlock");

    }
    window.addEventListener("resize", this.handleResize);
    window.addEventListener('storage', this.handleStorageChange);
    const pathName = window.location.pathname;

    this.setState({
      openLink: pathName.slice(1),
    });

    const widthSm = window.innerWidth < 1200;
    this.setState({
      open: widthSm,
    });

      const userType = await getStorageData('user_type');
      if(userType){
        const filteredData = this.state.allData.filter((item:DrawerItem) => {
          return userType === "invited" ? item : item.text !== 'Key Contacts';
        });

        this.setState({ filteredData });
      }
  }

  getProfile = (token: string) => {
    const header = {
        token,
        "Content-Type": "application/json",
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_profile/profile"
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};
  handleStorageChange = async () => {
    const user = await getStorageData("user_type")
    if (user === "invited" && this.state.userType !== user) {
      this.checkUserTypeChange();
      this.setState({isPrimiumEnded: true})
    }
  }

  async componentDidUpdate(prevProps: Readonly<PersistentDrawerLeftProps>, prevState: Readonly<PersistentDrawerLeftState>) {
    const user = await getStorageData("user_type")    
    if(user && this.state.userType && user === "invited" && this.state.userType === "regular" && user !== this.state.userType){        
        this.setState({isPrimiumEnded: true})
       await setStorageData("user_type", this.state.userType)
    }
    if(user && this.state.userType && user === "regular" && this.state.userType === "invited" && user !== this.state.userType && this.props.code){
       setStorageData("user_type", this.state.userType)
       this.checkUserTypeChange()
  }
}

  checkUserTypeChange = async () => {
    const user = await getStorageData("user_type");
    if(user){
      const filteredData = this.state.allData.filter((item:DrawerItem) => {
        return user === "invited" ? item : item.text !== 'Key Contacts';
      });
      this.setState({ filteredData });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener('storage', this.handleStorageChange);

  }

  handleResize = () => {
    const widthSm = window.innerWidth < 1200;
    this.setState({
      open: widthSm,
    });
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  addingModalRendering = () => {
    return (
        <>
            <ModalRendendringStyle >
                <Box className="closeButton">
                  <ClearIcon onClick={()=> this.setState({checkState:false})}  />
                  </Box>
                <Box className="keepChangesBox">
                    <Typography className="keepChanges">Keep Changes?</Typography>
                    <Typography className="profileChange">You have made changes to your profile. Do you want to save them before exiting?
                    </Typography>
                </Box>
                <Divider className="divider" />
                <Box className="buttonStyle">
                    <Button className="discardBtn" onClick={() => this.discardButton()} >Discard changes</Button>
                    <Button className="saveBtn" onClick={(event)=> this.checkCondition(event)} variant="contained">Save changes</Button>
                </Box>
            </ModalRendendringStyle>
        </>
    )
}

  discardButton = () => {
    this.setState({ checkState: false }),
    this.setRoute(this.state.routeName, this.state.routeLink);
  }

  checkCondition = (event: React.FormEvent) => {
    if (this.props.showData) {
      this.props.showData(event);
    }
    this.setState({ checkState: false })
  }

  setRoute = (text: string, link: string) => {
    if(this.props?.isNavigation==false && this.props.chnngesModal !== undefined){
      this.props.chnngesModal(link)
    }else{
      this.setState({
        openLink: link,
      });
      this.props.navigation.navigate(link);
    }
  };

  logOut = () => {
    this.setState({
      openModel: true,
    });
  };

  cancelBtn = () => {
    this.setState({
      openModel: false,
    });
  };

  async receive(from: string, message: Message ) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.logoutApiCallId) {
            this.setState({
              checkButton: false
            })
          }
            if (apiRequestCallId === this.logoutApiCallId && responseJson.message) {
              this.setState({
                openModel: false,
              });
              localStorage.clear();
              this.props.navigation.navigate("EmailAccountLoginBlock");
            }

            if (apiRequestCallId === this.getProfileCallId) {
              const codeStatus = responseJson?.data?.attributes?.invitation_code_status
              const userTypeReguler = responseJson?.data?.attributes?.user_type
              const removeUser = responseJson?.errors[0]?.message              
              if(removeUser === 'Profile not present.'){      
                this.setState({ notPresentPopup: true });
              }
              if (codeStatus === "in-active") {
                  this.setState({ userType: "regular" })
              } else if (codeStatus === "active"){
                  this.setState({ userType: "invited" })
                  setStorageData("user_type", "invited")
              }
          }
    
        }
    }
};

closePopup = () => {
  this.checkUserTypeChange()
  this.setState({
      isPrimiumEnded: false
  })
}

  handleLogOutUserApiCall = async() =>{
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken"),
    };
    const bodyData = {
      device_id: Cookies.get("notificationToken"),
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/logout"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  logoutBtn = () => {
    this.handleLogOutUserApiCall();
    this.setState({
      checkButton: true,
    })
  };
  render() {
    const { open, openModel } = this.state;

    return (
      <>
        {
          this.state.checkState &&
          (
              <Modal
                open={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
              {this.addingModalRendering()}
              </Modal>
          )
        }
        <Dialog
          open={openModel}
          style={{ borderRadius: "50px" }}
          PaperProps={{
            style: {
              borderRadius: "24px",
            },
          }}
        >
          <div
            style={{ borderBottom: "1px solid #E2E8F0", paddingBottom: "20px" }}
          >
            <Box
              display={"flex"}
              style={{
                justifyContent: "flex-end",
                margin: "3%",
                marginBottom: "0px",
                cursor: "pointer",
              }}
              onClick={this.cancelBtn}
            >
              <ClearIcon />
            </Box>
            <DialogContent>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#0F172A",
                  fontFamily: "Raleway",
                  margin: "0px",
                }}
              >
                Are you sure you want to logout?
              </p>
            </DialogContent>

            <DialogContent
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#0F172A",
                fontFamily: "Raleway",
                lineHeight: "26px",
              }}
            >
              Logging out will end your current session, and you'll need to sign
              in again to access your account.
            </DialogContent>
          </div>
          <DialogActions style={{ flexWrap: "wrap", margin: "15px 13px 15px" }}>
            <button
              onClick={this.cancelBtn}
              style={{
                fontSize: "16px",
                fontWeight: 700,
                fontFamily: "Raleway",
                color: "#0F172A",
                backgroundColor: "#FCFCFC",
                border: "none",
                width: "140px",
                height: "56px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={this.logoutBtn}
              style={{
                backgroundColor: "#DC2626",
                border: "none",
                width: "151px",
                height: "56px",
                borderRadius: "50px",
                fontSize: "16px",
                fontWeight: 700,
                fontFamily: "Raleway",
                color: "#fff",
                cursor: "pointer"
              }}
              disabled={this.state.checkButton}
            >
              Logout
            </button>
          </DialogActions>
        </Dialog>
        <div style={{borderRight: "1px solid #E2E8F0"}}>
          <Grid
            container
            direction="column"
            style={{
              height: "auto",
              minHeight: "100vh",
              width: this.state.open ? "auto" : "245px",
              padding: this.state.open ? "0px 10px" : "0px 20px",
              borderBottom:"none"
            }}
          >
            {this.state.open ? (
              <>
                <Grid item style={{ margin: "40px 10px" }}>
                  <img
                    src={sideBarClose}
                    alt="sideBarClose"
                    style={{ marginLeft: "7px" }}
                    onClick={() =>
                      this.setState({
                        open: false,
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    {this.state.filteredData.map((value: DrawerItem, index: number) => 
                    (
                        <Grid item key={index} style={{ margin: "2px 0px" }}>
                          <Grid
                            container
                            alignItems="center"
                            style={{
                              backgroundColor:
                                this.state.openLink &&
                                  this.state.openLink === value.link
                                  ? "#D1EDFF"
                                  : "#ffffff",
                              padding: "20px",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if(this.props.fillform){
                                this.setState({ checkState: true, routeName: value.text, routeLink: value.link })
                              }
                              else {
                                this.setRoute(value.text,value.link);
                              }
                            } }
                          >
                            <img src={value.src} alt={`${value.src}`} />
                          </Grid>
                        </Grid>
                    
                    ))}
                    <Grid item style={{ margin: "2px 0px" }}>
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          padding: "20px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={this.logOut}
                      >
                        <img src={logOut} alt="logOut" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "15% 0px" }}
                  >
                    <img src={logo} alt="logo" />
                    <img
                      src={sideBarOpen}
                      alt="sideBarOpen"
                      style={{ marginLeft: "20px" }}
                      onClick={() =>
                        this.setState({
                          open: true,
                        })
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column">
                    {this.state.filteredData.map((value:DrawerItem, index: number) => (
                      <Grid item key={index} style={{ margin: "10px 0px 0px" }}>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            backgroundColor:
                              this.state.openLink &&
                                this.state.openLink === value.link
                                ? "#D1EDFF"
                                : "#fff",
                            padding: "13px 18px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if(this.props.fillform){
                              this.setState({ checkState: true, routeName: value.text, routeLink: value.link })
                            }
                            else {
                              this.setRoute(value.text,value.link);
                            }}}
                        >
                          <div>
                            <img src={value.src} alt={`${value.src}`} />
                          </div>
                          {value.link ? (
                            <Typography
                              style={{
                                marginLeft: "15px",
                                fontSize: "14px",
                                fontWeight:
                                  this.state.openLink === value.link
                                    ? 700
                                    : 500,
                                fontFamily: "Raleway",
                                color:
                                  this.state.openLink === value.link
                                    ? "#13689E"
                                    : "#0F172A",
                              }}
                            >
                              {value.text}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                marginLeft: "15px",
                                fontSize: "14px",
                                fontWeight:
                                  this.state.openLink === value.text
                                    ? 700
                                    : 500,
                                fontFamily: "Raleway",
                                color:
                                  this.state.openLink === value.text
                                    ? "#13689E"
                                    : "#0F172A",
                              }}
                            >
                              {value.text}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      style={{ margin: "10px 0px 0px 2px", cursor: "pointer" }}
                      onClick={this.logOut}
                    >
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          padding: "13px 18px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <img src={logOut} alt="logOut" />

                        <Typography
                          style={{
                            marginLeft: "15px",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Raleway",
                            color: "#DC2626",
                          }}
                        >
                          Logout
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Dialog
            open={this.state.isPrimiumEnded}
            style={styles.dailogMainborder}
            PaperProps={{
              style: styles.signUpPopup,
            }}
          >
            <DialogContainer style={styles.dailogGridMain}>
              <Box style={styles.dailogBox}>
                <div style={styles.dailogContentText}>
                  <DialogContent>
                    <Typography style={styles.dilogTitels}>Premium Access Ended</Typography>
                  </DialogContent>
                 
                  <img src={crosicorn} style={styles.clerIcon} onClick={this.closePopup} data-test-id="clearError" />
                </div>
              </Box>
              <DialogContent
                style={styles.dilogText}
              >
                Your premium features are no longer active. You are now on our basic plan.
              </DialogContent>
            </DialogContainer>
          </Dialog>
          <Dialog
            open={this.state.notPresentPopup}
            aria-labelledby="account-deleted-dialog-title"
            aria-describedby="account-deleted-dialog-description"
            PaperProps={{
              style: styles.removeFirstCss
            }}
          >
            <div style={styles.secoundRemoveCss}>
              <DialogContent style={styles.removeContent}>
                <DialogContentText
                  id="account-deleted-dialog-description"
                  style={styles.textCss}
                >
                  Your account has been deleted from administration due to terms of service violations.
                  Please login again with other credentials or sign up now!
                </DialogContentText>
              </DialogContent>
            </div>
            <Divider />
            <div style={styles.btnDiv}>
              <DialogActions style={styles.actionBtn}>
                <Link to="/EmailAccountLoginBlock" style={styles.removeUnderline}>
                  <Button style={styles.btnLastCss}>
                    Close
                  </Button>
                </Link>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}

export default PersistentDrawerLeft;

const DialogContainer = styled(Box)({
  "& .signup-btn": {
    background: "#FCFCFC",
    color: "#13689E",
    borderRadius: "8px",
  },
  "& .action-btns": {
    padding: "16px",
  },
  "& .contact-btn": {
    background: "#13689E",
    borderRadius: "8px",
    color: "white",
  },
});

const styles = {
  signUpPopup:{ 
      borderRadius: "24px", padding:'10px'
  },
  dilogText: {
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: "Raleway",
      padding: "0px 20px 20px 23px",
      lineHeight:'26px',
      color:'#334155',
      marginTop:'18px'
  },
clerIcon: {  padding: "5px", cursor: "pointer" },
dilogTitels: {
  fontFamily: "Raleway",
  color: "#1E293B",
  fontSize: "32px",
  marginBottom: "0px",
  wordBreak:"break-word" as "break-word",
  fontWeight: 700,
  lineHeight: "37.57px",
  textAlign: "left" as "left"
},
dailogMainborder:{ borderRadius: "50px" },
dailogContentText:{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
dailogBox:{display:"flex",justifyContent:"space-between", paddingRight:"16px"},
dailogGridMain:{borderRadius:"24px"},
removeUnderline: { textDecoration : "none"},
removeFirstCss:{ borderRadius: '20px'},
secoundRemoveCss: { padding: '15px', textAlign: 'center' as 'center' },
removeContent: { padding: '16px 0' },
textCss: {color: '#0F172A', fontFamily: "Raleway",fontSize: "18px", fontWeight: 500, lineHeight: "26px",textAlign: "center" as "center" },
btnDiv:{ padding: '10px', textAlign: 'center' as "center"  },
actionBtn : { justifyContent: 'center', padding: '8px 0 0 0' },
btnLastCss: {color: '#1976d2', fontFamily: "Inter",fontSize: "18px",fontWeight: 600,lineHeight: "26px",textAlign: "center" as "center", textTransform: 'none' as 'none'}
}

const ModalRendendringStyle = styled(Box)({
  height: "min-content",
  maxWidth: "600px",
  width: "calc(100% - 40px)",
  backgroundColor: "white",
  overflowY: "hidden",
  position: "relative",
  borderRadius: "24px",
  top: "50%",
  left: "50%",
  maxHeight: "380px",
  transform: "translate(-50%, -50%)",
  fontFamily: "Raleway",
  "& .closeButton": {
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
    cursor: "pointer",
  },
  "& .keepChangesBox": {
    margin: "7px 40px 20px 40px"
  },
  "& .profileChange": {
    marginTop: "16px",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight:"26px",
    paragraph:"12px",
    fontFamily: "Raleway"
  },
  "& .keepChanges": {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0F172A",
    fontFamily: "Raleway"
  },
  "& .divider": {
    marginTop: "45px"
  },
  "& .buttonStyle": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    padding: "24px"
  },
  "& .saveBtn": {
    backgroundColor: "#13689E",
    color: "white",
    textTransform: "capitalize",
    borderRadius: "50px",
    fontWeight: "700",
    width: "151px",
    height: "56px",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontSize: "16px",
    lineHeight: "24px"
  },
  "& .discardBtn": {
    color: "#13689f",
    backgroundColor: "#FCFCFC",
    textTransform: "capitalize",
    padding: "5px 20px",
    borderRadius: "8px",
    fontWeight: "700",
    height: "56px",
    fontFamily: "Raleway",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "24px"
  }
})
