import React from "react";

// Customizable Area Start
import {
  Box,
  Select,
  MenuItem,
  Popover,
  Button,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { pheLevelHeader,selectImages,redmgImages,greenmgImages,monthNextDate,monthPrevDate } from "./assets";
import SideBar from "../../../components/src/SideBar"
import WebHeader from "../../../components/src/WebHeader.web";
import DatePicker from "react-datepicker";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateRange } from 'react-date-range';
import { enUS } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import PheleveltrendController, { Props, configJSON } from "./PheleveltrendController.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: 'none !important'
        },
      }
    },
    MuiListItem: {
      root: {
        ":hover, &.Mui-selected": {
          fontWeight: "600 !important",
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected ": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop: "20px"
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "12px",
        position: "absolute",
        background: "#F8FAFC",
        left: "732px",
        top: "266px",
      },
      list: {
        backgroundColor:"#F1F5F9",
        boxSizing: "border-box",
        fontFamily: 'Raleway',
        color: "#13689E",
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: '22px',
        fontWeight: 500,
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'

      },
    },  
  MuiInputAdornment: {
      positionEnd: {
        height: "16px",
        marginLeft: 0,
        justifyContent: "center",
        width: "16px",
        marginRight: "8px",
        display: "flex",
        alignItems: "center",
      },
    }, 
  },
  });

// Customizable Area End

export default class Pheleveltrend extends PheleveltrendController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ display: "flex", boxSizing: "border-box" }}>
          <SideBar navigation={this.props.navigation} />
          <MainContainerlevelbox>
                    <PheHeaderDiv>
                        <WebHeader title={ `${this.state.currentToggel} Level Trend`}  icon={pheLevelHeader}   />
            </PheHeaderDiv>
            <PhelevelSelectDiv>
              <PhelevelSwich>
                <Box data-testid="PheButton" onClick={() => this.swichTypeData('Phe')} style={{ ...webStyle.mainTab, ...this.state.currentToggel == 'Phe' ? webStyle.activeTab : '', textAlign: "center" }}>{configJSON.phe}</Box>
                <Box data-testid="tyrButton" onClick={() => this.swichTypeData('Tyr')} style={{ ...webStyle.mainTab, ...this.state.currentToggel == 'Tyr' ? webStyle.activeTab : '', textAlign: "center" }}>{configJSON.tyr}</Box>
              </PhelevelSwich>

              <MedicationDataSelect
                data-testid="open_select"
                className="selectInputValue"
                onChange={ this.handleChangeValue}
                onClick={this.handleClickValue}
                name="treatment_type"
                id="demo-simple-select-helper"
                value={this.state.valueChart}
                labelId="demo-simple-select-helper-label"
                inputProps={{
                  "data-testid": "demoSelect",
                  className: "select_inputpadding",
                }}
                open={this.state.selectMenu}
                onOpen={this.setMenus}
                onClose={this.setMenus}
                defaultValue={this.state.levelMonthly}
                IconComponent={() => (
                  <img src={selectImages} style={{ cursor: "pointer" }} data-testid="closed_menu"
                    onClick={this.setMenus}
                  />
                )}
                disableUnderline={true}
                MenuProps={{ PaperProps: { style: { marginTop: '55px', width: "148px" } }, className: "select-input-custom" }}
              >
                {
                  ["Monthly", "Quarterly", "Custom Range"].map((item, indexnum, array) => (
                    <MenuItem
                      className="Monthly"
                      style={{ ...webStyle.menuSelect, borderBottom: indexnum === array.length - 1 ? "none" : "1px solid #E2E8F0" }}
                      key={item}
                      value={item}>
                      {item}

                    </MenuItem>
                  ))}
               
              </MedicationDataSelect>   
           <Popover
                open={this.state.isMonthlySelected}
                PaperProps={{
                  style: {
                    borderRadius: '16px',
                  },
                }}
                anchorEl={this.state.anchorEl}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MonthDatepicker>
                  <DatePicker
                    dateFormat="MM/yyyy"
                    selected={this.state.monthlyDate}
                    onChange={date => this.selectMonth(date)}
                    data-testid="selectmonth"
                    showMonthYearPicker
                    inline
                    renderCustomHeader={({
                      date,
                      decreaseYear,
                      increaseYear,
                    }) => (
                      <div style={{ display: 'flex',justifyContent: "space-between"}}>
                        <img style={{cursor:"pointer"}} onClick={decreaseYear} src={monthPrevDate} />
                        <div style={{fontFamily: "Raleway",fontSize: "14px",fontWeight: 700,cursor:'context-menu'}}>{date.getFullYear()}</div>
                        <img style={{cursor:"pointer"}} onClick={increaseYear} src={monthNextDate} />
                      </div>
                    )}
                  />
                </MonthDatepicker>
              </Popover>

         <Popover
                open={this.state.selectDaterange}
                PaperProps={{
                  style: {
                    borderRadius: '16px',
                  },
                }}
                anchorEl={this.state.anchorElDaterange}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <CustomMonthDatepicker>
                  <DateRange
                    editableDateInputs={false}
                    showMonthAndYearPickers={false}
                    onChange={this.customSetDate}
                    ranges={this.state.tempRanges}
                    moveRangeOnFirstSelection={false}
                    locale={enUS}
                  />
                  <Button color={"primary"} data-testid="rangeselectok" onClick={this.submitcustomDate}>Ok</Button>
                </CustomMonthDatepicker>

              </Popover>
            </PhelevelSelectDiv>
            {this.state.isLoading && <Loader />}
            <MainchartData style={{marginRight:"28px"}}>
              <HighchartsReact highcharts={Highcharts} options={this.state.options}  />
            </MainchartData>
            <PhelevelMgdata>
              <Legendss>Legends</Legendss>
              {this.state.currentToggel === 'Phe' ? 
              <div>
                <PhelevelMgimagediv>
                  <MgImageschart><img src={redmgImages} alt="redmgImages" /></MgImageschart>
                  <AboveBelowDiv><AboveValue>Above 6</AboveValue>&nbsp;<UmolandMg>mg/dL</UmolandMg></AboveBelowDiv>
                </PhelevelMgimagediv>
                <PhelevelMgimagediv>
                  <MgImageschart><img src={greenmgImages} alt="greenmgImages" /></MgImageschart>
                  <AboveBelowDiv><AboveValue>Below 6</AboveValue>&nbsp;<UmolandMg> mg/dL</UmolandMg></AboveBelowDiv>
                </PhelevelMgimagediv>
              </div>:
              <div>
                <TyrlevelMgimagediv>
                  <MgImageschart><img src={redmgImages} alt="redmgImages" /></MgImageschart>
                  <AboveBelowDiv><AboveValue>Above</AboveValue>&nbsp;<UmolandMg>120 umol/L</UmolandMg></AboveBelowDiv>
                </TyrlevelMgimagediv>
                <TyrlevelMgimagediv>
                  <MgImageschart><img src={greenmgImages} alt="greenmgImages" /></MgImageschart>
                  <AboveBelowDiv><AboveValue>30-120</AboveValue>&nbsp;<UmolandMg>umol/L</UmolandMg></AboveBelowDiv>
                </TyrlevelMgimagediv>
              </div>
              }
            </PhelevelMgdata>


          </MainContainerlevelbox>
        </Box>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  menuSelect:{fontFamily: "Raleway",fontSize: "14px",fontWeight: 500,lineHeight: "22px"},
  activeTab: { backgroundColor: '#FFFFFF', fontWeight: 600, color: "#0F172A" },
  mainTab: { borderRadius: '26px', width: "84px", height: "38px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500, color: "#334155", fontFamily: "Raleway",fontSize:"14px",cursor:"pointer" }
}

const MainContainerlevelbox = styled(Box)({
  width: "100%",
  justifyContent: "conter",
  fontFamily: "Raleway",
  background: '#fafafa',
  justifyItems: "center",
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
});

const PheHeaderDiv = styled('div')({
  background: "#FFFFFF",
  "& .icon": {
    width: "34px",
    marginRight: "20px",
    height: "34px",
  },
});

const PhelevelSelectDiv = styled('div')({
    display:'flex',
    justifyContent: 'space-between',
    margin: "28px 28px",
    "@media (max-width: 400px)": {
      flexWrap:"wrap",
      gap:"10px"
    },
});

const PhelevelSwich = styled('div')({
  backgroundColor: "#F1F5F9",
  display: 'flex',
  padding: '4px',
  borderRadius: '50px',
  width: "176px",
  height: "46px",
  boxSizing: "border-box"
});

const MedicationDataSelect = styled(Select)({

  "& .select_inputpadding": {
    lineHeight: "16px",
    padding: "4px 9px 4px 3px",
    color: "#334155",
    fontWeight:500,
    background:"#F1F5F9",
  },
  "& .select_inputpaddingnumber": {
    lineHeight: "16px",
    padding: "4px 9px 4px 5px",
    color: "#334155",
    fontWeight:500,
    background:"#F1F5F9",
  },
  fontFamily: 'Raleway',
  paddingBlock: '4px',
  fontStyle: 'normal',
  fontWeight: 500,
  color: "#334155",
  lineHeight: '16px',
  border: '1px solid #E2E8F0',
  paddingInline: '10px',
  fontSize: '12px',
  borderRadius: '26px',
  backgroundColor: '#F1F5F9',
});

const MgImageschart = styled('div')({
  height: "24px",
  width: "24px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
});

const PhelevelMgdata = styled('div')({
  display:"flex",
  flexDirection:"column",
  marginLeft:"48px",
  marginBottom:"38px",
  maxHeight:"80px",
  gap:"8px"
});

const PhelevelMgimagediv = styled('div')({
  maxWidth:"147px",
  display:"flex",
  fontSize:"16px",
  fontFamily:"Raleway",
  fontWeight:500,
  justifyContent:"space-around",
  alignItems:"center"
});

const TyrlevelMgimagediv = styled('div')({
  maxWidth:"235px",
  fontSize:"16px",
  display:"flex",
  fontWeight:500,
  fontFamily:"Raleway",
  alignItems:"center",
  gap:'4px'
});

const Legendss = styled('div')({
  fontFamily: "Raleway",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "24px",
  color:"#1E293B"
});

const UmolandMg = styled('div')({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 400,
  color:"#475569"
});

const AboveBelowDiv = styled('div')({
  display:"flex"
});

const AboveValue = styled('div')({
color:'#0F172A'
});

const MonthDatepicker = styled('div')({
  '& .react-datepicker': {
    width:"250px !important",
    border: '1px solid #E2E8F0 !important',
    boxShadow: '0px 4px 8px 0px #00000008 !important',
  },

  '& .react-datepicker__header': {
    backgroundColor: 'transparent !important',
    borderBottom: 'transparent !important',
    padding:"20px 20px 12px 20px !important"
  },

  '& .react-datepicker__month':{
    margin:"0px 0px 20px 0px !important",
    fontFamily:"Raleway !important",
    display: "flex",
    flexDirection: "column",
    gap: "8px !important"
  },
  
  '& .react-datepicker__month-text:hover' :{
    backgroundColor:"transparent !important" 
  },

  '& .react-datepicker__month-wrapper':{
    fontWeight:"500 !important",
    fontFamily:"Raleway !important",
  },
  
  '& .react-datepicker__month .react-datepicker__month-text':{
    border: '1px solid #68B9EC00 ',
    padding:"4px 0px !important"
  },

  '& .react-datepicker__month-text.react-datepicker__month--selected:hover':{
      backgroundColor:"#D1EDFF !important"
  },

  '& .react-datepicker__month-text--today':{
    fontWeight:500
  },

  "& .react-datepicker__month-container": {
    width:"100%"
  },

'& .react-datepicker__month-text--keyboard-selected': {
    margin: '16px 0px',
    padding: '4px 0px',
    borderRadius: '15px !important',
    color: 'rgb(0, 0, 0)'
},

'& .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text': {
    padding: '4px 0px',
    border: '1px solid #92d1ef00',
    backgroundColor: '#d3effe00',
    
},

'& .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range': {
    backgroundColor: '#d3effe !important',
    border: '1px solid #92d1ef !important',
    borderRadius: '15px',
    color: "#1c6f9a",
    padding: '4px 0px',
},
'& .react-datepicker__day-names, .react-datepicker__week': {
    display: 'none',
},

});
const CustomMonthDatepicker =  styled('div')({
  display:'flex',
  flexDirection: 'column',
  '& .rdrDayToday .rdrDayNumber span::after' :{
    content: '',
    position: 'absolute',
    bottom: '4px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '0px',
    height: '2px',
    borderRadius: '2px',
  },
  '& .rdrDateDisplayWrapper':{
    display:'none'
  },

  '& .rdrCalendarWrapper':{
    border: "1px solid #E2E8F0",
    boxShadow: "0px 4px 8px 0px #00000008",
    borderRadius: "16px",
  },

  '& .rdrDayNumber span':{
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 400,
  },

  '& .rdrNextPrevButton:hover':{
    backgroundColor: "transparent"
  },

  '& .rdrNextPrevButton':{
    backgroundColor: "transparent"
  },

  '& .rdrMonthAndYearPickers':{
    fontFamily: "Raleway",
    fontSize: "14px",
    color:"#0F172A",
    fontWight: 700,
  },

  '& .rdrWeekDays':{
    fontSize: "14px",
    fontFamily: "Raleway",
    fontWight: 500,
    color:"#64748B"
  },

  '& .rdrMonthAndYearPickers .rdrMonthAndYearWrapper .rdrMonthPicker, & .rdrMonthAndYearPickers .rdrMonthAndYearWrapper .rdrYearPicker': {
    display: 'inline',
  },
  
})

const MainchartData = styled('div')({
  margin: "0px 28px 0px 30px",

  "& .highcharts-container": {
    overflowX: "scroll !important" as 'scroll',

    "&::-webkit-scrollbar": {
      width: '4px',
      height: '4px'
    },
    "&::-webkit-scrollbar-track": {
      background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: '8px',
      background: '#94A3B8',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#90A4AE',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
  },

  "@media (max-width: 400px)": {
    "& .highcharts-container": {

      width: '230px !important'
    }
  },

  "@media (min-width: 401px) and (max-width: 500px)": {

    "& .highcharts-container": {
      width: '280px !important'
    }
  },

  "@media (min-width: 501px) and (max-width: 600px)": {
    "& .highcharts-container": {
      width: '380px !important'

    },
    "& .dataon": {
      width: '600px'
    }
  },

  "@media (min-width: 601px) and (max-width: 700px)": {
    "& .highcharts-container": {
      width: '480px !important'
    },
    "& .twosele": {
      width: '500px',
      padding: '0px'
    }

  },

  "@media (min-width: 701px) and (max-width: 800px)": {
    "& .highcharts-container": {
      width: '580px !important'
    },
    "& .twosele": {
      width: '380px',
      margin: "0px"
    }

  },

  "@media (min-width: 801px) and (max-width: 900px)": { "& .highcharts-container": { width: '680px !important' } },

  "@media (min-width: 901px) and (max-width: 1000px)": {
    "& .writecode": {
      height: '100px',
      width: '590px'
    },
    "& .highcharts-container": {
      width: '780px !important'
    },

  },
  "@media (min-width: 1001px) and (max-width: 1100px)": {
    "& .highcharts-container": {
      width: '880px !important'
    },
    "& .hendaldata": {
      display: 'flex'
    },
  },

  "@media (min-width: 1101px) and (max-width: 1199px)": {

    "& .highcharts-container": {
      width: '980px !important'
    }
  },

  "@media (min-width: 1200px) and (max-width: 1300px)": {
    "& .highcharts-container": {

      width: '910px !important'

    }
  },

  "@media (min-width: 1301px) and (max-width: 1400px)": {
    "& .highcharts-container": {
      width: '1000px !important'
    }

  },

  "@media (min-width: 1401px) and (max-width: 1500px)": {
    "& .highcharts-container": {
      width: '1100px !important'
    },
    "& .highchadder": {
      flexDirection: 'column-reverse'
    },

  },

  "@media (min-width: 1501px) and (max-width: 1600px)": {
    "& .highcharts-container": {
      width: '1200px !important'
    },
    "& .highchiner": {
      flexDirection: 'column'
    },

  },

  "@media (min-width: 1601px) and (max-width: 1700px)": {
    "& .highcharts-container": {
      width: '1300px !important'
    },
    "& .tdataadd": {
      height: "10px"
    }

  },

  "@media (min-width: 1701px) and (max-width: 1780px)": {
    "& .dotdat": {
      padding: '1000px'
    },
    "& .highcharts-container": {
      width: '1390px !important'
    },
    "& .eightpad": {
      margin: '900px'
    },
  },

  "@media (min-width: 1781px)": {
    "& .highcharts-container": {
      width: '1500px !important'
    }
  },

});
// Customizable Area End
