Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.clientID =
  "298465077090-9j4d75763jb2q0g0j388lqt1dvcmnapf.apps.googleusercontent.com";
exports.googleLoginAPiEndPoint = "/account/accounts";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.EmailRequired = "Email is required";
exports.Passwordrequired = 'Password is required';
exports.Password8Char = 'Password must be at least 8 characters long'
exports.Password20Char = 'Password must be less than 20 characters long';
exports.passwordLetterDgt  = 'Password must contain at least one uppercase letter, one lowercase letter, and one digit';
exports.putMethod = "PUT";
exports.timeZoneEndPoint = "account/update_timezone";

exports.loginFailed = "Something went wrong!"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.ipAddressApi = "https://api64.ipify.org/?format=json";
exports.resType ="multipart/form-data";
// Customizable Area End