import React, { Component } from "react";
import { Box, Button, styled, Dialog, List } from "@material-ui/core";
import { dialogBoxCloseIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import CustomBG from "./CustomBG.web";

type MyProps = {

};

type stateType = {
    isDialogBoxOpen: boolean;
    closeImg: string
};

export default class ConnectinFaildeBox extends Component<MyProps, stateType> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            isDialogBoxOpen: true,
            closeImg: ""
        };
    }

    componentDidMount(): void {
        const closeImg = new Image();
        closeImg.src = dialogBoxCloseIcon;
        closeImg.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = closeImg.width;
            canvas.height = closeImg.height;
            const imageDim = canvas.getContext("2d");
            imageDim?.drawImage(closeImg, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            this.setState({closeImg: dataURL})
        };
    }
    retryOnline = () => {
        window.location.reload();
    }
    closeDialog = () => {
        this.setState({ isDialogBoxOpen: false });
    }
    render() {
        return (
            <CustomBG>
            <ConnectinFaildeBoxMain>
                < Dialog open={this.state.isDialogBoxOpen} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    <List className="addEventDilogMainSave" style={{
                        padding: "11px 0px 22px 0px", height: "auto", flexDirection: "column", display: "flex", maxWidth: "600px"
                    }} >
                        <div className="dialogHading" style={{
                            justifyContent: "flex-end", padding: "0px 15px", width: "auto",
                            display: "flex",
                            alignItems: "center",
                            height: "40px",
                            marginBottom: "10px",
                        }}>
                            <span className="dialogHadingCloseRetryDeeletBtn" style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50px",
                                color: "#1C1B1F",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "20px",
                                cursor: "pointer",
                            }}
                                onClick={this.closeDialog}
                            >
                                <CloseIcon />
                            </span>
                        </div>
                        <div className="overlapTextMsg" style={{
                            textAlign: "left",
                            maxWidth: "520px",
                            padding: "0px 32px"
                        }}>
                            <span className="overlapsText" style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                color: "#0F172A",
                                fontFamily: "Raleway",
                                maxWidth: "520px",
                                width: "100%"
                            }}>Oops! It seems like there's an issue connecting to the service.</span>
                            <p style={{
                                fontWeight: 400,
                                fontSize: "18px",
                                color: "#0F172A",
                                fontFamily: "Raleway",
                                maxWidth: "520px",
                                width: "100%"
                            }}>Please check your internet connection and ensure you have the latest browser version for optimal performance.</p>
                        </div>
                        <Box className="borderBotttomDialog" style={{
                            width: "100%",
                            borderBottom: "1px solid #E2E8F0",
                        }}></Box>
                        <List className="dialogSubBtnDiv" style={{
                            marginTop: "18px", padding: "0px 24px", display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            fontFamily: "Raleway",
                            flexDirection: "row"
                        }}>
                            <Button className="deleteCalendarBtn" style={{
                                width: "151px",
                                height: "56px",
                                backgroundColor: "#13689E",
                                color: "#ffffff",
                                borderRadius: "8px",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "24px",
                                textTransform: 'none',
                            }}
                                onClick={this.retryOnline}
                            >
                                Retry
                            </Button>
                        </List>
                    </List>
                </Dialog >
            </ConnectinFaildeBoxMain>
            </CustomBG>
        );
    }
}

const ConnectinFaildeBoxMain = styled(Box)({
    background: "#36415B59",
    position: "relative",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    '& .bgbottomImg': {
        position: "absolute",
        bottom: "0px",
        left: "0px",
    },
});
