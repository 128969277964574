import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
  Button,
  styled,
  Box,
  Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blueLine, noNotification, notificationCal, notificationCalCheck, notificationFire, notificationMainIcon, notificationMsg, notificationPin, notificationTrofi, notificationWatch } from "./assets";
import SideBar from "../../../components/src/SideBar";
import WebHeader from "../../../components/src/WebHeader.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import PushnotificationsController, {
  Props,
  configJSON,
} from "./PushnotificationsController";

export default class Pushnotifications extends PushnotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  todayNotification = () => {
    return (
      <ScrollCss>
        <Grid spacing={2} style={styles.secoundBorderLine}>
          {this.state.isnotificationsText?.map((notification, index) => (
            <React.Fragment key={index}>
              <Grid style={styles.firstNotification} data-test-id="redirectionMenu" onClick={(event) => this.singleReadNotification(event, notification.id, notification.attributes.notification_type, notification.attributes.redirect_to)}>
                <Grid style={styles.firstIconText}>
                  {notification.attributes.headings === "Refill Alert" && (
                    <img
                      src={notificationPin}
                      alt="notificationPin"
                      style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings === "Adherence Milestone: 1 Week!" && (
                    <img
                      src={notificationPin}
                      alt="notificationPin"
                      style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings === "Badge Earned!" && (
                    <img
                      src={notificationTrofi}
                      alt="notificationTrofi"
                      style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings.includes("Event") && (
                    <img
                      src={notificationCal}
                      style={styles.allnotificationIconCss}
                      alt="notificationCal" />
                  )}
                  {notification.attributes.headings === "Message Notification" && (
                    <img
                      src={notificationMsg}
                      alt="notificationMsg"
                      style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings.includes("Treatment") && (
                    <img
                      src={notificationWatch}
                      style={styles.allnotificationIconCss}
                      alt="notificationWatch"
                    />
                  )}
                  {notification.attributes.headings.includes("Milestone") && (
                    <img
                      src={notificationCalCheck}
                      style={styles.allnotificationIconCss}
                      alt="notificationCalCheck" />
                  )}
                  {notification.attributes.headings.includes("Streak") && (
                    <img
                      src={notificationFire}
                      style={styles.allnotificationIconCss}
                      alt="notificationFire"
                    />
                  )}
                  <Grid style={styles.contant}>
                    <Typography style={{ ...styles.textMainName, fontWeight: notification.attributes.notification_status === "unread" ? '600' : '400' }} >{notification.attributes.headings}</Typography>

                    <PropertyDeveloperCss>
                      <Typography
                        className={notification.attributes.notification_status ===  "unread" ? '' : 'todaycontect'}
                        dangerouslySetInnerHTML={{ __html: notification.attributes.contents }}
                        style={styles.textSecoundaryName}
                      />
                    </PropertyDeveloperCss>
                  </Grid>
                </Grid>
                <Grid
                  style={styles.firstIconText}
                >
                  <Grid>
                    <Grid
                      style={styles.textTime}>
                      {moment(notification.attributes.created_at).format('hh:mm A')}
                    </Grid>
                    <Grid
                      style={{ ...styles.readMarkSkip, color: this.handleTextColor(notification.attributes.notification_status) }}>
                      {this.handleShowStatus(notification.attributes.notification_status, notification.attributes.notification_type)}
                    </Grid>
                  </Grid>
                  <Grid>
                      <IconButton
                        aria-controls={`long-menu-${index}`}
                        aria-label="more"
                        style={{ visibility: (notification.attributes.notification_status === "unread" && notification.attributes.notification_type === "treatment_reminder") ? undefined : 'hidden' }} 
                        data-test-id="todayIcon"
                        aria-haspopup="true"
                        onClick={(event) => this.handleClick(event, notification)} >
                        <MoreVertIcon />
                      </IconButton>
                    <Menu
                      elevation={0}
                      id={`long-menu-${index}`}
                      data-test-id="optionMenuButton"
                      onClose={this.closeFuction}
                      anchorEl={(this.state.anchorEl)}
                      open={Boolean(this.state.anchorEl)}
                      PaperProps={{ style: styles.optionsMenuCss }}>
                      {this.state.options.map((option, optionIndex) => (
                        <>
                          <MenuItem
                            key={option.id}
                            style={styles.menuIconTextCss}
                            onClick={(event) => this.putUpdateNotification(event, option.status)}
                            data-test-id="menuTodayIcon"
                          >
                            <img src={option.imageLink} alt={`Option ${optionIndex}`} />
                            {option.text}

                          </MenuItem>
                          {optionIndex !== this.state.options.length - 1 && <Divider style={styles.dievderUnderLine} />}</>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              {index !== this.state.isnotificationsText.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Grid>
      </ScrollCss>
    )
  }

  yesterdayNotification = () => {
    return (
      <ScrollCss>
        <Grid spacing={2} style={styles.secoundBorderLine}>
          {this.state.yesterdaynotificationsText?.map((notification, index) => (
            <React.Fragment key={index}>
              <Grid style={styles.firstNotification} data-test-id="yesterdayRedirectionMenu" onClick={(event) => this.singleReadNotification(event, notification.id, notification.attributes.notification_type,notification.attributes.redirect_to)}>
                <Grid style={styles.firstIconText}>
                  {notification.attributes.headings.includes("Treatment") && (
                    <img src={notificationWatch} style={styles.allnotificationIconCss} alt="notificationWatch" />
                  )}
                  {notification.attributes.headings.includes("Streak") && (
                    <img src={notificationFire} style={styles.allnotificationIconCss} alt="notificationFire" />
                  )}
                  {notification.attributes.headings.includes("Event") && (
                    <img src={notificationCal} style={styles.allnotificationIconCss} alt="notificationCal" />
                  )}
                  {notification.attributes.headings.includes("Milestone") && (
                    <img src={notificationCalCheck} style={styles.allnotificationIconCss} alt="notificationCalCheck" />
                  )}
                  {notification.attributes.headings === "Adherence Milestone: 1 Week!" && (
                    <img src={notificationPin} alt="notificationPin" style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings === "Refill Alert" && (
                    <img src={notificationPin} alt="notificationPin" style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings === "Badge Earned!" && (
                    <img src={notificationTrofi} alt="notificationTrofi" style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings === "Message Notification" && (
                    <img src={notificationMsg} alt="notificationMsg" style={styles.allnotificationIconCss} />
                  )}
                  <Grid style={styles.contant}>
                    <Typography style={{ ...styles.textMainName, fontWeight: notification.attributes.notification_status === "unread" ? '600' : '400' }}>
                      {notification.attributes.headings}
                      </Typography>
                    <PropertyDeveloperCss>
                      <Typography style={styles.textSecoundaryName} className={notification.attributes.notification_status === "unread" ? '' : 'todaycontect'} dangerouslySetInnerHTML={{ __html: notification.attributes.contents }} />
                    </PropertyDeveloperCss>
                  </Grid>
                </Grid>
                <Grid style={styles.firstIconText}>
                  <Grid>
                    <Grid style={styles.textTime}>{moment(notification.attributes.created_at).format('hh:mm A')}</Grid>
                    <Grid style={{ ...styles.readMarkSkip, color: this.handleTextColor(notification.attributes.notification_status) }}>{this.handleShowStatus(notification.attributes.notification_status, notification.attributes.notification_type)}</Grid>
                  </Grid>
                  <Grid>
                      <IconButton aria-label="more" ria-haspopup="true" aria-controls={`long-menu-${index}`} onClick={(event) => this.handleYesterdayClick(event, notification)} style={{ visibility: (notification.attributes.notification_status === "unread" && notification.attributes.notification_type === "treatment_reminder") ? undefined : 'hidden' }}  data-test-id="yesterdayIcon"><MoreVertIcon /></IconButton>
                    <Menu id={`long-menu-${index}`} elevation={0} onClose={this.yesterdayCloseFuction} anchorEl={(this.state.yesterdayanchorEl)} open={Boolean(this.state.yesterdayanchorEl)} data-test-id="yesterdayCloseMenu" PaperProps={{ style: styles.optionsMenuCss }}  >
                      {this.state.options.map((options, yesterdayoptionIndex) => (
                        <React.Fragment key={options.id}>
                          <MenuItem data-test-id="menuYesterdayIcon" style={styles.menuIconTextCss} onClick={(event) => this.putUpdateNotification(event, options.status)}>
                            <img src={options.imageLink} alt={`options ${yesterdayoptionIndex}`} />
                            {options.text}
                          </MenuItem>
                          {yesterdayoptionIndex !== this.state.options.length - 1 && <Divider style={styles.dievderUnderLine} />}
                        </React.Fragment>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              {index !== this.state.yesterdaynotificationsText.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Grid>
      </ScrollCss>
    )
  }

  olderNotification = () => {
    return (
      <ScrollCss>
        <Grid spacing={2} style={{ ...styles.olderSecoundBorderLine, marginBottom: "15px" }}>
          {this.state.oldernotificationsText?.map((notification, index) => (
            <React.Fragment key={index}>
              <Grid style={styles.firstNotification} data-test-id="olderRedirectionMenu" onClick={(event) => this.singleReadNotification(event, notification.id, notification.attributes.notification_type,notification.attributes.redirect_to)} >
                <Grid style={styles.firstIconText}>
                  {notification.attributes.headings === "Refill Alert" && (
                    <img style={styles.allnotificationIconCss} src={notificationPin} alt="notificationPin" />
                  )}
                  {notification.attributes.headings === "Adherence Milestone: 1 Week!" && (
                    <img src={notificationPin} style={styles.allnotificationIconCss} alt="notificationPin" />
                  )}
                  {notification.attributes.headings.includes("Event") && (
                    <img src={notificationCal} style={styles.allnotificationIconCss} alt="notificationCal" />
                  )}
                  {notification.attributes.headings === "Message Notification" && (
                    <img src={notificationMsg} alt="notificationMsg" style={styles.allnotificationIconCss} />
                  )}
                  {notification.attributes.headings.includes("Milestone") && (
                    <img src={notificationCalCheck} style={styles.allnotificationIconCss} alt="notificationCalCheck" />
                  )}
                  {notification.attributes.headings.includes("Treatment") && (
                    <img src={notificationWatch} style={styles.allnotificationIconCss} alt="notificationWatch" />
                  )}
                  {notification.attributes.headings.includes("Streak") && (
                    <img src={notificationFire} style={styles.allnotificationIconCss} alt="notificationFire" />
                  )}
                  {notification.attributes.headings === "Badge Earned!" && (
                    <img src={notificationTrofi} style={styles.allnotificationIconCss} alt="notificationTrofi" />
                  )}
                  <Grid>
                    <Typography
                      style={{ ...styles.textMainName, fontWeight: notification.attributes.notification_status === "unread" ? '600' : '400' }} >
                      {notification.attributes.headings}
                    </Typography>
                    <PropertyDeveloperCss>
                      <Typography style={styles.textSecoundaryName} dangerouslySetInnerHTML={{ __html: notification.attributes.contents }} className={notification.attributes.notification_status === "unread" ? '' : 'todaycontect'} />
                    </PropertyDeveloperCss>

                  </Grid>
                </Grid>
                <Grid style={styles.firstIconText}>
                  <Grid>
                    <Grid style={styles.textTime}>{moment(notification.attributes.created_at).format('D MMMM')}</Grid>
                    <Grid style={{ ...styles.readMarkSkip, color: this.handleTextColor(notification.attributes.notification_status) }}>{this.handleShowStatus(notification.attributes.notification_status, notification.attributes.notification_type)}</Grid>
                  </Grid>
                  <Grid>
                      <IconButton style={{ visibility: (notification.attributes.notification_status === "unread" && notification.attributes.notification_type === "treatment_reminder") ? undefined : 'hidden' }}  aria-label="more" onClick={(event) => this.handleOlderClick(event, notification)} aria-controls={`long-menu-${index}`} aria-haspopup="true" data-test-id="olderIcon" >
                        <MoreVertIcon />
                      </IconButton>
                    <Menu elevation={0} id={`long-menu-${index}`} anchorEl={(this.state.olderanchorEl)} onClose={this.olderCloseFuction} data-test-id="moreIconId" open={Boolean(this.state.olderanchorEl)} PaperProps={{ style: styles.optionsMenuCss }}>
                      {this.state.options.map((olderoptions, olderoptionIndex) => (<>
                        <MenuItem
                          style={styles.menuIconTextCss}
                          key={olderoptions.id}
                          data-test-id="menuOlderIcon"
                          onClick={(event) => this.putUpdateNotification(event, olderoptions.status)}
                        >
                          <img src={olderoptions.imageLink} alt={`olderoptions ${olderoptionIndex}`} />
                          {olderoptions.text}
                        </MenuItem>
                        {olderoptionIndex !== this.state.options.length - 1 && <Divider style={styles.dievderUnderLine} />}
                      </>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              {index !== this.state.oldernotificationsText.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Grid>
      </ScrollCss>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {this.state.loading && <Loader />}
      <ThemeProvider theme={theme}>
      <Grid style={styles.mainNotificationGridCss}>
        <SideBar navigation={this.props.navigation} />
          <Grid style={styles.HeaderAndConatint}>
            <Grid  style={styles.webHeaderCss}>
              <WebHeader title={"Notifications"} icon={notificationMainIcon} data-test-id={"notification"} getNotificationDot={this.state.getNotificationDot} />
            </Grid>

            <Container maxWidth="md" >
              <Grid >
                {!this.state.isnotificationsText.length && !this.state.yesterdaynotificationsText.length && !this.state.oldernotificationsText.length ? (
                  <>
                    <Grid style={styles.withOutDataCantent}>
                      <img src={noNotification} alt="noNotification" />
                    </Grid>
                    <Grid style={styles.cantentBothData}>
                      <Typography style={styles.withOutCantentFirst}>No notifications yet!</Typography>
                      <Typography style={styles.withOutCantentSecound}>Create new alerts from the Reminder tab,<br /> schedule events on the calendar, or stay <br /> tuned for messages from your health expert.</Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box style={styles.markLastBtn}>
                      <Typography style={styles.markAsReadCss} data-test-id="markeAsReadSatusId" onClick={this.markeAsReadSatus}>Mark all as read</Typography>
                    </Box>
                    {this.state.isnotificationsText.length !== 0 &&
                      (<Paper style={styles.paperMainDiv}>
                        <Box sx={{ p: 2 }}>
                          <Grid item style={styles.blueLineCss}>
                            <img src={blueLine} alt={blueLine} />
                            <Grid>
                              <Typography style={styles.blineSideText}>Today</Typography>
                            </Grid>
                          </Grid>
                          {this.todayNotification()}
                        </Box>
                      </Paper>)
                    }
                    <Snackbar
                      open={this.state.snackbarOpen}
                      data-test-id="closeIconID"
                      onClose={this.handleCloseSnackbar}
                      autoHideDuration={4000}
                      message={this.state.notificationMessage}
                      action={
                        <>
                          <Button
                            size="small"
                            aria-label="close"
                            style={styles.undoButton}
                            data-test-id="snacbarUndoButton"
                            onClick={(event) => this.putUpdateNotification(event, "unread")}>
                            undo
                          </Button>
                        </>
                      }
                      ContentProps={{
                        style: styles.snackbarFullCss,
                      }}
                    ></Snackbar>

                    {this.state.yesterdaynotificationsText.length !== 0 &&
                      (<Paper style={styles.paperMainDiv}>
                        <Box sx={{ p: 2 }}>
                          <Grid item style={styles.blueLineCss} >
                            <img src={blueLine} alt={blueLine} />
                            <Grid>
                              <Typography style={styles.blineSideText}>Yesterday</Typography>
                            </Grid>
                          </Grid>
                          {this.yesterdayNotification()}
                        </Box>
                      </Paper>)
                    }

                    {this.state.oldernotificationsText.length !== 0 &&
                    
                      (
                        <Paper style={{ ...styles.paperMainDiv, marginBottom: "100px",paddingBottom:"5px" }}>
                        <Box sx={{ p: 2 }} style={styles.lastOlderData}>
                          <Grid item style={styles.blueLineCss} >
                            <img src={blueLine} alt={blueLine} />
                            <Grid>
                              <Typography style={styles.blineSideText}>Older</Typography>
                            </Grid>
                          </Grid>

                          {this.olderNotification()}
                        </Box>
                        <Grid style={styles.paginationLast}>
                        <Pagination
                          count={this.state.paginationData.total_pages}
                          page={this.state.paginationData.current_page}
                          data-test-id="olderPaginantion"
                          onChange={this.handlePaginationChange}
                        />
                      </Grid>
                      </Paper>

                      )}
                  </>
                )}
              </Grid>
            </Container>
          </Grid>
      </Grid>
    </ThemeProvider>
    </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const PropertyDeveloperCss = styled(Box)({
  '& .todaycontect': {
    '& strong': {
      fontWeight: 400,
    }
  },
});

const ScrollCss = styled(Box)({
  "& .scrollBar": {
    display: "flex",
    padding: "32px",
    gap: "14px",
    alignItems: "center",
    margin: 'auto',
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      width: "5px"
    },
    "&::-webkit-scrollbar-thumb": {
      width: "5px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      width: "5px"
    },
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = {
  mainNotificationGridCss:{ display: "flex" },
  HeaderAndConatint:{width:"100%",minWidth: "100",height: "220vh" },
  mainContainer:{ justifyContent: "center", alignItems: "center", height: "100vh" },
  allnotificationIconCss:{ backgroundColor: '#F8FAFC', borderRadius: '20px', padding: '10px' },
  blueLineCss:{display: "flex", width: "50%", marginBottom: "10px", gap: "20px"},
  blineSideText:{ fontFamily: 'Raleway', fontSize: '20px', fontWeight: 700, lineHeight: '32px', letterSpacing: '-0.02em', textAlign: 'left' as 'left',color:'#1A1D1F' },
  firstNotification:{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px", margin: '35px 0px 35px 0px',  cursor: "pointer"},
  firstIconText:{ display: "flex", justifyContent: "center", alignItems: "center", gap: '30px' },
  secoundBorderLine:{border:"1px solid #F8FAFC",padding:"20px 20px  20px",maxHeight:'340px', overflowY: 'auto' as 'auto',backgroundColor:"#FEFEFE"},
  paperMainDiv:{ justifyContent: "center", alignItems: "center",maxWidth:'100%',margin:"35px 0 35px 0",borderRadius:'13px' },
  contant: {display: "flex",flexDirection: "column" as "column",gap: "10px"},
  webHeaderCss:{ width: "100%", backgroundColor: "#FAFAFA"},
  readMarkSkip:{fontFamily: 'Raleway', fontSize: '17px', fontWeight: 600, lineHeight: '20px', textAlign: 'right' as 'right', marginTop: 7},
  textMainName:{ fontFamily: 'Raleway', fontSize: '16px',  lineHeight: '24px', textAlign: 'left' as 'left',color:'#0F172A' },
  optionsMenuCss:{ height: '90px',backgroundColor:'#F8FAFC', boxShadow:"none",borderRadius:"12px",padding:'5px' },
  olderSecoundBorderLine:{border:"1px solid #F8FAFC",padding:"20px 20px 20px", overflowY: 'auto' as 'auto',backgroundColor:"#FEFEFE"},
  menuIconTextCss:{gap:'10px',fontFamily: 'Raleway',fontSize: '16px',fontWeight: 500,lineHeight: '22px',textAlign: 'left' as 'left',color: '#334155'},
  textTime:{ fontFamily: 'Raleway', fontSize: '14px', fontWeight: 400, lineHeight: '20px', textAlign: 'end' as 'end',color:'#475569' },
  withOutDataCantent:{ display: "flex", justifyContent: "center", marginTop: "35px" },
  cantentBothData: { marginTop: "35px" },
  dievderUnderLine: {width:"90%",marginInline:"auto",color:'#E2E8F0'},
  textSecoundaryName:{ fontFamily: 'Raleway', fontSize: '14px', fontWeight: 400, lineHeight: '16px', textAlign: 'left' as 'left',color:'#64748B' },
  withOutCantentFirst: {fontFamily: 'Raleway',fontSize: '20px',fontWeight: 600,lineHeight: '28.18px',letterSpacing: '0.011em',textAlign: 'center' as 'center',color: "#151515"},
  withOutCantentSecound: {fontFamily: 'Raleway',fontSize: '14px',fontWeight: 500,lineHeight: '24px',letterSpacing: '0.011em',textAlign: 'center' as 'center',color: "#475569"},
  undoButton:{backgroundColor: "#DBF1FF", borderRadius: "8px", fontFamily: 'Raleway',fontSize: '14px',fontWeight: 600,lineHeight: '24px',textAlign: 'left' as 'left',textTransform: 'none' as 'none', color: "#13689E"},
  markAsReadCss: { textAlign: 'end' as 'end', marginTop: '30px', cursor: "pointer", fontFamily: 'Raleway', fontSize: '18px', fontWeight: 600, lineHeight: '24px', color: '#13689E', marginRight: "35px" },
  snackbarFullCss : {minWidth: "830px",backgroundColor: "#FFFFFF",color: '#0F172A',marginLeft: '246px',fontFamily: 'Raleway', fontWeight: 500,borderRadius:'12px'},
  paginationLast:{margin:'0 30px 20px '},
  markLastBtn : { display: "flex", justifyContent: "flex-end" },
  lastOlderData:{paddingBottom:'0px'}
}
// Customizable Area End
