import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  withStyles,
  Box,
  styled,
  Container,
  Checkbox,
  Dialog,
  DialogContent,
  CheckboxProps,
  Divider,
  InputAdornment,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import EmailRegistrationWithoutInvCodeController, {
  Props,
} from "./EmailRegistrationWithoutInvCodeController.web";
import {
  apple_icon,
  google_icon,
  mainLogo,
} from "./assets";
import { Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import * as Yup from "yup";
import ClearIcon from '@material-ui/icons/Clear';
import CustomBG from '../../../components/src/CustomBG.web';
import Loader from "../../../components/src/Loader.web";
export const configJSON = require("./config.js");
const CssTextFieldCode = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc6c72b",
      borderWidth: "1.8px",

    },
    "& .MuiFilledInput-underline::after": {
      border: "none",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f13426",
      fontWeight: 500,
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },

    "& .MuiFilledInput-root": {
      border: "1px solid rgba(0, 0, 0, 0.09)",
      backgroundColor: "white",
    },
    "& .MuiInputBase-input": {
      color: "rgb(51, 51, 57)",
      backgroundColor: "#f4faffbd",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#f4faffbd",
    },
    "& .MuiInputLabel-root": {
      color: "var(--Neutrals-Cool-gray-500, #64748B)",
    },
    '& ::placeholder': {
      color: "#64748B",
      opacity: 1
    }
  },
})(TextField);

const MainContainer = styled(Box)({
  height: "100vh",
  display: "flex",
  "& .longmodal": {},
  "& .smallmodal": {},
  "& .MuiDialog-paper ": {
    backgroundColor: "red",
    borderRadius: "16px",
  },
});


const DialogContainer = styled(Box)({
  "& .signup-btn": {
    background: "#FCFCFC",
    color: "#13689E",
    borderRadius: "8px",
  },
  "& .action-btns": {
    padding: "16px",
  },
  "& .contact-btn": {
    background: "#13689E",
    borderRadius: "8px",
    color: "white",
  },
});

interface CustomCheckboxProps extends CheckboxProps {
  "data-test-id": string;
}

const CustomCheckbox = withStyles({
  checked: {},
  root: {
    padding: "0px",
    marginTop: "-3px",
    "&$checked": {
      color: "rgba(19, 104, 158, 1)",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "26px",
    },
  },
})((props: CustomCheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End

export default class EmailRegistrationWithoutInvCode extends EmailRegistrationWithoutInvCodeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStepViceForm = () => {
    const { step } = this.state;
    let result;

    if (step === 0) {
      result = this.getFormOneSignwithout();
    }

    return result;
  };

  buttonGrid = (props: any) => {
    return (
      <>
      <button
  data-test-id="handle_continue_click"
  style={{
    ...styles.button,
    opacity: this.state.isAgree && (
      props.values.email &&
    props.values.firstName && props.values.lastName &&
      props.values.pass 
    ) ? 1 : 0.4,
  }}
  type="submit"
  disabled={
    !this.state.isAgree ||
    !props.values.email  ||
    !props.values.firstName  ||
    !props.values.lastName ||
    !props.values.pass ||
    this.state.btnDisabel
  }
>
  Signup
</button></>
    )
  }

  getFormOneSignwithout = () => {
    return (
      <>
        {this.state.data && <Formik
data-test-id="Formik"
          initialValues={{
            firstName: this.state.data?.first_name || "",
            lastName: this.state.data?.last_name || "",
            email: this.state.data?.email || "",
            pass: this.state.data?.password || "",
            tc: false,
            vis: false,
          }}
          onSubmit={(values) => {
            this.verifySignUpAPiWithOut(values);
          }}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .min(2, "Firstname must be at least 2 characters")
              .required("Firstname is required"),
            lastName: Yup.string()
            .min(2, "Lastname must be at least 2 characters")
            .required("Lastname is required"),
            email: Yup.string()
              .email("Please enter a valid email address.")
              .required("Please enter a valid email address."),
          })}
        >
          {(props) => {
            const passwordValidation = this.validatePassword(props.values?.pass?.length > 1 ? props.values?.pass : this.state.data?.password);
            
            return (
              <form onSubmit={props.handleSubmit} style={{ marginBottom: '0' }}>
                <img src={mainLogo} alt="VITA" style={{ ...styles.mainLogo, marginBottom: "8%" }} />
                <Typography variant="h6" style={styles.head}>
                  Ready to Begin?
                </Typography>
                <Typography style={styles.paraText}>
                  Get started now. Sign up by filling in your details.
                </Typography>

                <CssTextFieldCode
                data-test-id="firstNameId"
                  placeholder="First Name"
                  name="firstName"
                  value={props.values.firstName}
                  onChange={(event) => {
                    props.handleChange(event);
                    this.firstNameChange(event)
                  }}
                  style={styles.signupInput}
                  error={!!props.errors?.firstName}
                  variant="outlined"
                  helperText={props.errors?.firstName}
                />
                <CssTextFieldCode
                data-test-id="lastNameId"
                  placeholder="Last Name"
                  name="lastName"
                  value={props.values.lastName}
                  onChange={(event) => {
                    props.handleChange(event);
                    this.lastNameChange(event)
                  }}
                  style={styles.signupInput}
                  error={!!props.errors?.lastName}
                  variant="outlined"
                  helperText={props.errors?.lastName}
                />
                <CssTextFieldCode
                  placeholder="Email"
                  name="email"
                  value={props.values.email}
                  onChange={(event) => {
                    props.handleChange(event);
                    this.emailChange(event);
                    
                  }}
                  style={styles.signupInput}
                  error={
                   !!props.errors.email
                  }
                  variant="outlined"
                  helperText={props.errors?.email}
                  data-test-id="secondChange"
                />
                <CssTextFieldCode
                  style={{
                    ...styles.signupInput,
                    height: props?.values?.pass?.length > 0 ? 0 : "18px",
                  }}
                  placeholder="Password"
                  name="pass"
                  value={ props.values.pass}
                  onChange={(event) => {
                    props.handleChange(event);
                    this.passwordChange(event)
                    
                  }}
                  data-test-id="pass-change"
                  variant="outlined"
                  error={!!props.errors?.pass}
                  type={props.values.vis ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "rgba(100, 116, 139, 1)",
                        }}
                        onClick={() =>
                          props.setFieldValue("vis", !props.values.vis)
                        }
                        data-test-id="togglePassword"
                      >
                        {props.values.vis ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                {


                  (props.values.pass?.length > 1 ? props.values.pass?.length > 0 : this.state.data?.password?.length > 0) && (
                    <div style={{ margin: "16px 0px 0px -7px", fontSize: "14px", fontWeight: 600, fontFamily: "Raleway" }}>
                      {[
                        {
                          isValid: passwordValidation.isCapitalLetter,
                          message: "At least one capital letter",
                        },
                        {
                          isValid: passwordValidation.isLowerCaseLetter,
                          message: "At least one lowercase letter",
                        },
                        {
                          isValid: passwordValidation.isNumber,
                          message: "At least one number",
                        },
                        {
                          isValid: passwordValidation.isMinLength,
                          message: "The minimum character length is 8 characters",
                        },
                      ].map((validItem, idx) => (
                        <Grid
                          style={{
                            display: "flex",
                          }}
                          key={idx}
                        >
                          <div>
                          {validItem.isValid ? (
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_15976_139"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="21"
                              >
                                <rect
                                  y="0.827637"
                                  width="20"
                                  height="20"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_15976_139)">
                                <path
                                  d="M8.33352 12.4576L13.3944 7.38866C13.5093 7.27327 13.6556 7.21558 13.8332 7.21558C14.0108 7.21558 14.1572 7.27308 14.2726 7.38808C14.388 7.50308 14.4457 7.64944 14.4457 7.82718C14.4457 8.0049 14.388 8.15145 14.2726 8.26683L8.86075 13.6707C8.71011 13.8213 8.53437 13.8966 8.33352 13.8966C8.13267 13.8966 7.95693 13.8213 7.80629 13.6707L5.72777 11.6002C5.61238 11.4853 5.55469 11.339 5.55469 11.1614C5.55469 10.9838 5.61219 10.8374 5.72719 10.722C5.84219 10.6066 5.98856 10.5489 6.16629 10.5489C6.34401 10.5489 6.49056 10.6066 6.60594 10.722L8.33352 12.4576Z"
                                  fill="#059669"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_15976_4858"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="21"
                              >
                                <rect
                                  y="0.827637"
                                  width="20"
                                  height="20"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_15976_4858)">
                                <path
                                  d="M9.99995 11.7137L7.2948 14.4189C7.17408 14.5396 7.02771 14.5986 6.8557 14.5959C6.6837 14.5933 6.53734 14.5316 6.41662 14.4109C6.29589 14.2901 6.23553 14.1424 6.23553 13.9678C6.23553 13.7931 6.29589 13.6454 6.41662 13.5247L9.11376 10.8275L6.4086 8.14322C6.28787 8.0225 6.22885 7.8748 6.23153 7.70012C6.2342 7.52543 6.29589 7.37773 6.41662 7.25701C6.53734 7.13629 6.68504 7.07593 6.85972 7.07593C7.03439 7.07593 7.18208 7.13629 7.3028 7.25701L9.99995 9.96218L12.6843 7.25701C12.805 7.13629 12.9514 7.07593 13.1234 7.07593C13.2954 7.07593 13.4417 7.13629 13.5624 7.25701C13.6917 7.38629 13.7563 7.53613 13.7563 7.70653C13.7563 7.87694 13.6917 8.0225 13.5624 8.14322L10.8653 10.8275L13.5705 13.5327C13.6912 13.6534 13.7516 13.7998 13.7516 13.9718C13.7516 14.1438 13.6912 14.2901 13.5705 14.4109C13.4412 14.5401 13.2914 14.6048 13.1209 14.6048C12.9505 14.6048 12.805 14.5401 12.6843 14.4109L9.99995 11.7137Z"
                                  fill="#DC2626"
                                />
                              </g>
                            </svg>
                          )}
                          </div>
                          <span
                            style={
                              validItem.isValid
                                ? styles.PasswordValidation
                                : styles.wrongPasswordValidation
                            }
                          >
                            {validItem.message}
                          </span>
                        </Grid>
                      ))}
                    </div>
                  )}
                <Box style={styles.tcPara}>
                  <CustomCheckbox
                    data-test-id="checkbox"
                    size="small"
                    style={styles.ccBox}
                    checked={this.state.isAgree}
                    onChange={() => this.handleTermsCondition(props)}
                  />
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: 401,
                      color: "#0F172A",
                      fontFamily:"Raleway"
                    }}
                  >
                    By accepting you agree to the{" "}
                    <span onClick={() => this.goToTerms(props.values)} data-test-id="goToTerms" style={styles.contactLink}>
                      {" "}
                      Terms of Service{" "}
                    </span>{" "}
                    &{" "}
                    <span onClick={() => this.goToPrivacy(props.values)} data-test-id="goToPrivacy" style={styles.contactLink}>
                      Privacy Policy.{" "}
                    </span>
                  </Typography>
                </Box>
                {this.buttonGrid(props)}
                
                <Typography style={styles.alreadyAccount}>
                  Already have an account?
                  <Link to="/EmailAccountLoginBlock" style={styles.contactLink}>
                    {"   Login"}
                  </Link>
                </Typography>
                <Typography style={styles.linkCen}>
                  Login via
                  <Link
                    to="/EmailRegistrationWithInvCode"
                    style={styles.contactLink}
                  >
                    {"   Invitation Code? "}
                  </Link>
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexShrink: 1,
                    margin: "8px 0",
                  }}
                >
                  <Divider style={{ width: "45%", marginTop: "12px" }} />
                  <Typography style={{ color: "rgba(100, 116, 139, 1)" }}>
                    or
                  </Typography>
                  <Divider style={{ width: "45%", marginTop: "12px" }} />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "18px",
                  }}
                >
                  <Box style={styles.signUpIcon}>
                    <Button
                      style={{ border: 'none', padding: '0', minWidth: '0', background: 'transparent' }}
                      data-test-id="googleLoginId"
                      onClick={() => this.handleGoogleSignUp()}
                    >
                      <img src={google_icon} alt="google" />
                    </Button>
                  </Box>
                  <Box style={styles.signUpIcon}>
                    <Button
                      onClick={() => this.handleAppleSignUp()}
                      data-test-id="appleLoginId"
                      style={{ background: 'transparent', border: 'none', minWidth: '0', padding: '0' }}
                    >
                      <img src={apple_icon} alt="apple" />
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      }</>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { modalTypeError } = this.state;
    return (
      <>
      {this.state.loading && <Loader/>}
      <CustomBG>
        <MainContainer>
          <Container
            maxWidth="sm"
            style={{ marginTop: "auto", marginBottom: "auto"}}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid style={styles.headSecondgrid}>
                  {this.getStepViceForm()}
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Dialog
            open={this.state.emailError.length > 1}
            style={{ borderRadius: "50px" }}
            PaperProps={{
              style: styles.signUpPopup,
            }}
          >
            <DialogContainer borderRadius={"24px"}>
              <Box
                display={"flex"}
                style={{ justifyContent: "space-between" }}
                paddingRight={"16px"}
              >
                <DialogContent>
                  <p style={styles.dilogTitels}>Email already registered!</p>
                </DialogContent>

                <div style={styles.clerIcon} onClick={this.clearError} data-test-id="clearError"><ClearIcon /></div>
              </Box>

              <DialogContent
                style={styles.dilogText}
              >
                Oops! It looks like this email is already registered. If not, you can use a different email to sign up.
              </DialogContent>
            </DialogContainer>
          </Dialog>
        </MainContainer>
      </CustomBG>
      </>
      // Customizable Area End
    );
  }
}

const styles: Record<string, React.CSSProperties> = {
  // Customizable Area Start
  tcPara: { display: "flex", marginTop: "10px" },
  ccBox: { marginBottom: "18px", padding: "0 7px 0 0" },
  errorTexts: {
    color: "red",
    fontSize: "0.75rem",
    fontFamily: "Raleway",
  },
  clerIcon: { backgroundColor: "#F1F5F9", padding: "7px", height: "24px", borderRadius: "50%", color: "#1C1B1F", cursor: "pointer" },
  dilogTitels: {
    fontWeight: 800,
    fontFamily: "Raleway",
    color: "#1E293B",
    fontSize: "32px",
    marginBottom: "0px",
    wordBreak:"break-word"
  },
  dilogText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Raleway",
    color: "#0F172A",

    padding: "0px 20px 40px 23px",


  },
  signUpIcon: {
    borderRadius: "50px",
    background: "#ECF5FA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "18px",
    paddingRight: "18px",
    width: "24px",
  },
  closeIcon: {
    borderRadius: "50px",
    background: "#F1F5F9",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    width: "24px",
  },
  mainLogo: {
    margin: "0 0 0 0",
width: "140px",
    height: "auto",
  },
  paraText: {
    color: "#475569",
    marginTop: "6px",
    marginBottom: "16px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Raleway"
  },
  signupInput: {
    height: "20px",
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "45px",
    color: "#1E293B",
    width: "100%",
  },
  button: {
    marginTop: "5px",
    marginBottom: "5px",
    fontSize: "16px",
    height: "46px",
    borderRadius: "5px",
    width: "100%",
    backgroundColor: "rgba(19, 104, 158, 1)",
    cursor: "pointer",
    color: "white",
    fontWeight: 700,
    border: "none",
    fontFamily: 'Raleway',
  },

  headSecondgrid: {
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    borderRadius: "24px",
    zIndex: 1,
    backgroundColor: "#fff",
    padding: "32px",
    position: "relative",
    height: "auto",
  },
  head: {
    color: "#3C3C3B",
    fontWeight: 700,
    fontSize: "24px",
    textAlign: "left",
  },
  alreadyAccount: {
    textAlign: "center",
    marginTop: "5px",
    fontSize: "15px",
    fontFamily:"Raleway"
  },
  contactLink: {
    fontSize: "15px",
    fontWeight: 600,
    color: "rgba(19, 104, 158, 1)",
    textDecoration: "none",
    textTransform: "none",
    cursor: "pointer",
    fontFamily:"Raleway"
  },
  linkCen: {
    textAlign: "center",
    justifyContent: "center",
    marginTop: "4px",
    fontSize: "15px",
  },
  PasswordValidation: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
    fontSize: "15px",
    color: "rgba(5, 150, 105, 1)",
  },
  wrongPasswordValidation: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "15px",
    alignSelf: "stretch",
    color: "rgba(220, 38, 38, 1)",
  },
  signUpPopup:{ 
    borderRadius: "24px",padding:'10px'
  }
  // Customizable Area End
};