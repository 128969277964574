// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export const configJSON = require("./config");
import { NavigationStackProp } from "react-navigation-stack";

interface ResponseDataApp {
  id: number;
  os_type: string;
  is_maintenance: boolean;
  is_downtime: boolean;
  email: string;
}

interface ApiAppupdateResponse {
  data: ResponseDataApp;
}
export interface Props {
  navigation: NavigationStackProp;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  appMaintenance:boolean;
  downtime:boolean;
  isModal:boolean;
  links:string;
  email:string;
}

interface SS {
  id: string;
}

export default class AppupdatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getupdateApiCallid : string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      appMaintenance:false,
      downtime:false,
      isModal:false,
      links:'',
      email:'',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
 async componentDidMount() {
  const url = new URL(document.URL)
  this.setState({links:url.pathname})
  this.appUpdateGetapi();
}
  componentDidUpdate() {
    const urlOne = new URL(document.URL)
    if(this.state.links !== urlOne.pathname){
      this.setState({links:urlOne.pathname})
      this.appUpdateGetapi()
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.getupdateApiCallid) {
              this.updateApp(responseJson)
        }
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  
  appMaintenanceModalclosed = () =>{
    this.setState({appMaintenance:false})
  };

  downtimeModalclosed = () =>{
    this.setState({downtime:false})
  };

  appUpdateGetapi = async() =>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getupdateApiCallid = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.applatestUpdate}?os_type=web`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);

  };

  updateApp = (dataResponse:ApiAppupdateResponse) =>{
    const maintenanceModal = dataResponse?.data?.is_maintenance
    const downtimeModal = dataResponse?.data?.is_downtime
    const maintenanceEmail = dataResponse?.data?.email
      if(maintenanceModal){
           this.setState({appMaintenance:true,email:maintenanceEmail})
      }else if(downtimeModal){
        this.setState({downtime:true})
      }
  };

  mailtoSendMe = () =>{
    return window.location.href = `mailto:${this.state?.email}`
  }
}

// Customizable Area End
