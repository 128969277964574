import React from "react";

// Customizable Area Start
import Box from "@material-ui/core/Box";
import { Card, Container, Grid, Typography, styled } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CustomBG from "../../../components/src/CustomBG.web";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
    {this.state.isLoading ?<Loader />: <CustomBG>
      <MainContainer>
        <Container maxWidth="md">
          <Box className="scrollBar">
          <Card elevation={0} className="card-container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.state.termsConds?.data !== undefined && this.state.termsConds?.data.map((item:any) => {
                    return (
                      <Box key={item}>
                        <Typography className="IntroductionBox1">Introduction</Typography> 
                        <Typography className="IntroductionText" dangerouslySetInnerHTML={{ __html: item.description }} />
                      </Box>
                    )
                })}
              </Grid>
                <Grid item xs={12}>
                  <Box className="main-button-container" sx={{mb:5}}>
                    <Button className="buttonstwo" variant="contained" data-test-id="termsAgreeButton" onClick={() => this.handleNavigate()}>
                      Agree
                    </Button>
                      <CancelButton className="buttonstwo2" data-test-id="termsCancelButton" onClick={() => this.props.navigation.goBack()}>Cancel</CancelButton>
                  </Box>
                </Grid>
            </Grid>
          </Card>
          </Box>
        </Container>
      </MainContainer>
    </CustomBG>}
    </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding:"32px 0",
  position:"relative",
  overflow: "hidden",
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd":{
      maxWidth: 700
    } 
  },
  "& .scrollBar":{
    overflow: "scroll",
    height:"100vh",
  "&::-webkit-scrollbar": {
    display:"none"
  },
  "&::-webkit-scrollbar-track": {
    display:"none"
  },
  "&::-webkit-scrollbar-thumb":{
    display:"none"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    display:"none"
  },
  "@media (max-width: 600px)": {
    height:"100vh",
  },
  },
  "& .main-button-container":{
    display:"flex",
    gap:10,
    marginBottom:"20px !important",
    "@media (max-width: 600px)": {
        flexDirection:"column"
    }
  },
    "& .checkboxColor": {
    color: "#13689E",
  },
    "& .textColor": {
      "& .MuiFormControlLabel-label":{
          fontFamily: "Raleway",
          marginBottom: 0,
          fontWeight: "600 !important"
      }
  },
  "& .card-container": {
    background: "#F8FAFC",
    borderRadius: 12,
    padding: 24,
    "@media (max-width: 400px)": {
      overflow: "scroll",
    },
    maxHeight:'calc(100vh - 112px)',
    height:"fit-content",
    overflowY:"scroll",
  "&::-webkit-scrollbar": {
    display:"none"
  },
  "&::-webkit-scrollbar-track": {
    display:"none"
  },
  "&::-webkit-scrollbar-thumb":{
    display:"none"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    display:"none"
  },
  },
      "& .IntroductionBox1": {
        fontFamily: "Raleway",
        color: "#000",
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: "500",
      },
    "& .IntroductionText": {
      color: "#475569",
      fontFamily: "Raleway",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: "500",
      textTransform: "capitalize",
      "& br":{
          display:"none",
      },
      "& p":{
          fontSize:14
      }
    },
    "& .buttonstwo": {
      display: "flex",
      width: "168px",
      padding: "12px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius: 50,
      color: "white",
      fontWeight:700,
      backgroundColor: "#13689E",
      textTransform: 'capitalize',
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    "& .buttonstwo2": {
      fontSize:"14px",
      cursor:"pointer",
      display: "flex",
      width: "168px",
      padding: "12px 16px",
      justifyContent: "center",
      alignItems: "center",
      fontWeight:700,
      gap: "8px",
      borderRadius: 50,
      textTransform: 'capitalize',
      "@media (max-width: 600px)": {
        width: "100%",
      },
  },
  })

  const CancelButton = styled(Button)({
    display: "flex",
    width: "168px",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight:700,
    gap: "8px",
    borderRadius: 50,
    textTransform: 'capitalize',
    "@media (max-width: 600px)": {
      width: "100%",
    },
    "&:hover":{
      backgroundColor: 'transparent',
    },
  })  
// Customizable Area End