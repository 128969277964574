import React, { Component } from "react";
import { Box, Button, Popover, Typography, styled } from "@material-ui/core";
import { onBoardingArrow } from "./assets";

type MyProps = {
    title: any,
    discripation: any,
    open: any
    anchorEl: any,
    next: any,
    skip: any,
    anchorhorizontal: any,
    transformhorizontal: any,
    btnDisable?: boolean,
    classForImg?:string,
    classForContainer?:string,
    classForLeftImg?:string,
    classForLeftThreeImg?:string,
    classForLeftFourImg?:string,
};

type stateType = {

};

export default class WebOnboarding extends Component<MyProps, stateType> {
    constructor(props: MyProps) {
        super(props);
        this.state = {

        };
    }


    componentDidUpdate(prevProps: MyProps) {
        if (prevProps.open !== this.props.open) {
            if (this.props.open) {
                document.body.style.height = "200vh";
            } else {
                document.body.style.height = "auto";
            }
        }
    }
    componentWillUnmount() {
        document.body.style.height = "auto";
    }

    renderClassName = (title: string) => {
        if (window.location.pathname === "/Task") {
            if (title === "Step 1") {
                return "customPopoverPaper-first"
            } else if (title === "Step 4") {
                return "customPopoverPaper-four"
            } else if (title === "Step 5") {
                return "customPopoverPaper-five"
            } else if (title === "Step 6") {
                return "customPopoverPaper-six"
            } else {
                return "customPopoverPaper"
            }
        } else if (window.location.pathname === "/PatientResources/Recipes") {
            if (title === "Step 1") {
                return "customPopoverPaper-patientResources-first"
            }else if (title === "Step 3") {
                return "customPopoverPaper-patientResources-three"
            } else if (title === "Step 4") {
                return "customPopoverPaper-patientResources-four"
            } else if (title === "Step 5") {
                return "customPopoverPaper-patientResources-five"
            } else {
                return "customPopoverPaper"
            }
        }else if(window.location.pathname === "/Analytics"){
            if (title === "Step 1") {
                return "analyticsStep1"
            } else if (title === "Step 2") {
                return "analyticsStep2"
            } else if (title === "Step 3") {
                return "analyticsStep3"
            }
        }else if(window.location.pathname === "/Preferences"){
            if (title === "Step 1"){
                return "customPopoverPaper-prefrence-first"
            }else if (title === "Step 4") {
                return "customPopoverPaper-prefrence-four"
            }else {
                return "customPopoverPaper"
            }
        } else if (window.location.pathname === '/CustomForm'){
            if (title === 'Step 1'){
                return 'customPopoverPaper-CustomForm-one'
            }
            if (title === 'Step 2'){
                return 'customPopoverPaper-CustomForm-two'
            }
             if(title === 'Step 3'){
                return 'customPopoverPaper-CustomForm-three'
            }else {
                return 'customPopoverPaper'
            }
        } else if (window.location.pathname === '/Gamification'){
            if(title === 'Step 4'){
                return 'customPopoverPaper-Gamification-four'
            }else {
                return 'customPopoverPaper'
            }
        }
        else {
            return "customPopoverPaper"
        }
    }

    render() {
        const { title, discripation, anchorEl, next, open, skip, anchorhorizontal, transformhorizontal, btnDisable ,classForImg, classForContainer, classForLeftImg,classForLeftThreeImg,classForLeftFourImg} = this.props;
        return (
            <MainPopoverBox open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: anchorhorizontal,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: transformhorizontal,
                }}
                PaperProps={{
                    className: this.renderClassName(title),
                }}
            >
                <Box className={`${classForContainer} container`}>
                   {window.location.pathname !== "/Preferences" && <img src={onBoardingArrow} alt="" className={transformhorizontal === "left" ? title === 'Step 3'?`${classForLeftThreeImg} leftarrowImg`:title === 'Step 4'?`${classForLeftFourImg} leftarrowImg`:`${classForLeftImg} leftarrowImg` : ` arrowImg ${classForImg}`}  />}
                   {window.location.pathname === "/Preferences" && <img src={onBoardingArrow} alt="" className={title === 'Step 4'? 'arrowImg' : `leftarrowImg`}  />}
                    <Typography className="titleHading" gutterBottom>
                        {title}
                    </Typography>
                    <Typography className="onboardDiscripation" gutterBottom>
                        {discripation}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" className="buttonContainer" >
                        <Button variant="contained" className={btnDisable ? "nextBtnDisable" : "nextBtn"} disabled={btnDisable} id="next-btn-popup" onClick={next} >
                            Next
                        </Button>
                        <Button variant="outlined" className="skipBtn" id="skip-btn-popup" onClick={skip}>
                            Skip
                        </Button>
                    </Box>
                </Box>
            </MainPopoverBox>
        );
    }
}

const MainPopoverBox = styled(Popover)({
    background: "#36415B59",
    position: "relative",

    '& .leftarrowImg': {
        position: "absolute",
        top: "-22px",
        left: "12%",
        transform: "translateX(-50%)",
        zIndex: 1000,
        width: "auto",
        height: "auto",
        "@media(max-width: 670px)":{
            left: "40%"
        }
    },
    "& .customPopoverPaper": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
    },
    "& .customPopoverPaper-first": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
        '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "42%",
            },
        },
    },
    "& .customPopoverPaper-four": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 20px',
        "@media(max-width:412px)": {
            margin: '23px 5px',
          },
          '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "57%",
            },
        },
    },
    "& .customPopoverPaper-five": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 25px',
        "@media(max-width:450px)": {
            margin: '23px 5px',
          },
          '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "3%",
            },
        },
    },
    "& .customPopoverPaper-patientResources-first": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
        '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "42%",
            },
        },
    },
    "& .customPopoverPaper-patientResources-three": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 20px',
        "@media(max-width:500px)": {
            margin: '23px 8px',

          },
        "@media(max-width:450px)": {
            margin: "25px 0px 0px 0px",
          },
          "@media(max-width:360px)": {
            marginTop: '30px',

          },
          '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "61%",
            },
        },
    },

    "& .customPopoverPaper-patientResources-four": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 20px',
        "@media(max-width:360px)": {
            margin: '23px 7px',
          },
        "@media(max-width:450px)": {
            margin: '25px 0px',
          },
          '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "60%",
            },
        },
    },
    "& .customPopoverPaper-patientResources-five": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '25px 10px',
        "@media(max-width:450px)": {
            margin: '10px 0px',
          },
          "@media(max-width:360px)": {
            margin: '28px 0px 0px 10px',
            '& .arrowImg': {
                left: "32%",
            },
          },
          '@media (max-width: 450px)': {
            '& .arrowImg': {
                right: "60%",
            },
        },
        '@media (max-width: 500px)': {
            '& .arrowImg': {
                left: "22%",
            },
        },
        '@media (max-width: 810px)': {
            '& .arrowImg': {
                left: "22%",
            },
        },
    },
    "& .customPopoverPaper-prefrence-four": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
            '& .arrowImg': {
                left: "10% !important" ,
            },
            '@media (max-width: 900px)': {
                '& .arrowImg': {
                    left: "75% !important",
                },
            },        
    },
    "& .customPopoverPaper-prefrence-first": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
            '& .arrowImg': {
                right: "70% !important" ,
            },
            '@media (max-width: 360px)': {
            '& .arrowImg': {
                left: "75% !important" ,
            },
        },
    },
    "& .customPopoverPaper-CustomForm-one":{
        borderRadius: "24px",
        overflow: "initial",
        margin: '60px 5px',
    },
    "& .customPopoverPaper-CustomForm-two":{
        borderRadius: "24px",
        overflow: "initial",
        margin: '60px 5px',
    },
    "& .customPopoverPaper-CustomForm-three": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '60px 5px',
        '& .leftarrowImg': {
            position: "absolute",
            top: "-22px",
            left: "14%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            width: "auto",
            height: "auto",
            "@media(max-width: 670px)":{
                left: "40%"
            }
        },
    },
    "& .customPopoverPaper-Gamification-four": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '23px 5px',
        '& .leftarrowImg': {
            position: "absolute",
            top: "-22px",
            left: "14%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            width: "auto",
            height: "auto",
            "@media(max-width: 640px)":{
                left: "70%"
            }
        },
    },
    "& .customPopoverPaper-six": {
        borderRadius: "24px",
        overflow: "initial",
        margin: '25px 5px',
    },
    "& .analyticsStep1":{
        width:"auto",
        borderRadius: "24px",
        overflow: "initial",
        marginTop: "20px",
        marginLeft: "10px",
        "@media(max-width:1200px)": {
            marginLeft: "10px",
        },
        "@media(max-width:991px)": {
            marginLeft: "10px",
        },
        "@media(max-width:450px)": {
            marginTop:"-37px",
            '& .arrowImg': {
                right: "17% !important",
            },
        },
        "@media(max-width:420px)": {
            marginTop:"30px",
            '& .arrowImg': {
                right: "63% !important",
            },
        },
        "@media(max-width:370px)": {
            marginTop:"30px",
            '& .arrowImg': {
                right: "57% !important",
            },
        },
    },
    "& .analyticsStep2":{
        width:"auto",
        borderRadius: "24px",
        overflow: "initial",
        marginTop: "20px",
        marginLeft: "10px",
       
        "@media(max-width:1200px)": {
            marginLeft: "10px",
        },
        "@media(max-width:991px)": {
            marginLeft: "10px",
        },
        "@media(max-width:670px)": {
            marginTop: "20px",
            '& .arrowImg': {
                right: "80% !important",
                backgroundColor: "red",
            },
        },
        "@media(max-width:576px)": {
            marginTop:"20px",
            '& .arrowImg': {
                right: "80% !important",
            },
        },
        "@media(max-width:450px)": {
            marginTop:"25px",
            marginLeft:"0px",
            
        },
        "@media(max-width:476px)": {
            marginTop:"25px",
            marginLeft:"0px",
            '& .arrowImg': {
                right: "50% !important",
            },
        },
        "@media(max-width:420px)": {
            marginTop:"30px",
            marginLeft:"0px",
            '& .arrowImg': {
                right: "50% !important",
            },
        },
        "@media(max-width:370px)": {
            marginTop:"30px",
            marginLeft:"0px",
            '& .arrowImg': {
                right: "57% !important",
            },
        },
    },
    "& .analyticsStep3":{
        width:"auto",
        borderRadius: "24px",
        overflow: "initial",
        marginTop: "20px",
        marginLeft: "10px",
        "@media(max-width:1200px)": {
            marginLeft: "10px",
            marginTop:"-46px"
        },
        "@media(max-width:991px)": {
            marginLeft: "10px",
            marginTop: "-50px"
        },
        "@media(max-width:768px)": {
            marginLeft: "10px",
            marginTop: "-50px"
        },
        "@media(max-width:476px)": {
            marginTop:"-46px",
            '& .arrowImg': {
                right: "9% !important",
            },
        },
        "@media(max-width:450px)": {
            marginTop:"-45px",
            '& .arrowImg': {
                right: "10% !important",
            },
        },
        "@media(max-width:420px)": {
            marginTop:"-45px",
            marginLeft: "0px",
            '& .arrowImg': {
                right: "0% !important",
            },
        },
        "@media(max-width:370px)": {
            marginTop:"-30px",
            marginLeft: "0px",
            '& .arrowImg': {
                right: "7% !important",
            },
        },
    },
    "& .customFormStep1":{
        width:"auto",
        borderRadius: "24px",
        overflow: "initial",
        marginTop: "30px",
        marginLeft: "31%",
        "@media(max-width:1200px)": {
            marginLeft: "8%",
            marginTop:"0%",
            '& .arrowImg': {
                right: "40% !important",
            },
        },
        "@media(max-width:768px)": {
            marginLeft: "8%",
            marginTop:"6%"
        },
        "@media(max-width:576px)": {
            marginTop:"10%",
            '& .arrowImg': {
                right: "9% !important",
            },
        },
        "@media(max-width:476px)": {
            marginTop:"25%",
            '& .arrowImg': {
                right: "9% !important",
            },
        },
        "@media(max-width:450px)": {
            marginTop:"55%",
            marginLeft: "0px",
            '& .arrowImg': {
                right: "10% !important",
            },
        },
        "@media(max-width:420px)": {
            marginTop:"67%",
            marginLeft: "0px",
            '& .arrowImg': {
                right: "0% !important",
            },
        },
        "@media(max-width:370px)": {
            marginTop:"85%",
            marginLeft: "0px",
            '& .arrowImg': {
                right: "7% !important",
            },
        },
    },
    '& .container': {
        maxWidth: "430px",
        width: "100%",
        fontFamily: "Raleway",
        boxSizing: "border-box",
    },
    '& .titleHading': {
        fontSize: "24px",
        fontWeight: 700,
        color: "#000000",
        lineHeight: "32px",
        borderBottom: "1px solid #E2E8F0",
        padding: "16px 40px 16px 40px",
        fontFamily: "Inter",
    },
    '& .onboardDiscripation': {
        fontSize: "16px",
        fontWeight: 500,
        color: "#334155",
        lineHeight: "20px",
        paragraph: "8px",
        padding: "16px 40px 16px 40px",
        fontFamily: "Raleway",
    },
    '& .buttonContainer': {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 40px 32px 40px"
    },
    '& .nextBtn': {
        background: "#13689E",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        width: "168px",
        height: "48px",
        borderRadius: "50px",
        padding: "12px 16spx",
        textTransform: 'none',
        fontFamily: "Raleway",
    },
    '& .nextBtnDisable': {
        background: "#13689E80",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        width: "168px",
        height: "48px",
        borderRadius: "50px",
        padding: "12px 16px",
        textTransform: 'none',
        fontFamily: "Raleway",
        '&:hover': {
            background: '#13689E80',
            boxShadow:'none'
          }, 
    },

    '& .skipBtn': {
        color: "#000000",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        width: "168px",
        height: "48px",
        borderRadius: "50px",
        padding: "12px 16spx",
        textTransform: 'none',
        border: "1px solid transparent",
        fontFamily: "Raleway",
    },
    '& .arrowImg': {
        position: "absolute",
        top: "-22px",
        right: "-1%",
        transform: "translateX(-50%)",
        zIndex: 1000,
        width: "auto",
        height: "auto",
    },
    '@media (max-width: 425px)': {
        '& .arrowImg': {
            right: "35%",
        },
        '& .leftarrowImg': {
            left: "50%",
        }, 
    },
    '@media (max-width: 700px)': {
        '& .stepPrompt': {
            right: '5%'
        },
    },
    '@media (max-width: 640px)': {
        '& .stepPrompt': {
            right: '11%'
        },
    },
    '@media (max-width: 590px)': {
        '& .stepPrompt': {
            right: '18%'
        },
    },
    '@media (max-width: 540px)': {
        '& .stepPrompt': {
            right: '23%'
        },   
    },
    '@media (max-width: 550px)': {
        '& .leftStepPrompt': {
            left: '28%'
        },
    },
    '@media (max-width: 490px)': {
        '& .stepsContainer': {
            maxWidth: '400px'
        },
    },
    '@media (max-width: 465px)': {
        '& .leftStepPrompt': {
            left: '30%'
        },
    },
    '@media (max-width: 450px)': {
        '& .stepsContainer': {
            maxWidth: '375px'
        },
    },
    '@media (max-width: 565px)': {
        '& .leftStepThreePrompt': {
            left: '18%'
        },
    },
    '@media (max-width: 545px)': {
        '& .leftStepThreePrompt': {
            left: '23%'
        },
    },
    '@media (max-width: 520px)': {
        '& .leftStepThreePrompt': {
            left: '29%'
        },
    },
    '@media (max-width: 460px)': {
        '& .leftStepThreePrompt': {
            left: '38%'
        },
    },
    '@media (max-width: 420px)': {
        '& .leftStepThreePrompt': {
            left: '45%'
        },
    },
    '@media (max-width: 415px)': {
        '& .leftStepPrompt': {
            left: '40%'
        },
    },
    '@media (max-width: 930px)': {
        '& .leftStepFourPrompt': {
            left: '25%'
        },
    },
    '@media (max-width: 860px)': {
        '& .leftStepFourPrompt': {
            left: '29%'
        },
    },
    '@media (max-width: 780px)': {
        '& .leftStepFourPrompt': {
            left: '38%'
        },
    },
    '@media (max-width: 720px)': {
        '& .leftStepFourPrompt': {
            left: '45%'
        },
    },
    '@media (max-width: 680px)': {
        '& .leftStepFourPrompt': {
            left: '50%'
        },
    },
    '@media (max-width: 500px)': {
        '& .leftStepFourPrompt': {
            left: '70%'
        },
    },
    '@media (max-width: 400px)': {
        '& .leftStepFourPrompt': {
            left: '80%'
        },
    },

});
