// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
  id: string;
  navigation: any;
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  step: number;
  code: any;
  errorModal: string;
  errorEmails:string
  errorModelOpen: boolean;
  isAgree: boolean;
  data: any;
  allData: any;
  userType: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailRegistrationWithInvCodeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  varifyPutRespoID: string = "";
  varifyCodeSignupID: string = "";
  verufyPassInvId: string = "";

  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      step: 0,
      code: "",
      errorModal: "",
      errorEmails: "",
      errorModelOpen: false,
      isAgree: false,
      data: null,
      allData: {code:"", firstName: "", lastName: "", email: "", password: "", conformPassword: "", c2: false, step:3},
      userType: ""
      // Customizable Area End
    };
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  async componentDidMount(){
    let allData = await getStorageData("allDataInvalid");
    let datas = JSON.parse(allData);
    let weAreBack = await getStorageData("weGotoMFA");
    datas&&this.setState({
      allData: datas,
    })
    weAreBack && this.setState({
      step: 3
    })
    let data = await getStorageData("datas")
    if(data){
      this.setState({
        data: JSON.parse(data),
        step: Number(data.step) || 2,
        isAgree: true
        
      })
    } else {
      this.setState({
        data: true
      })
    }
    
    
    let isChecked = await getStorageData("isChecked")  
    if(isChecked === 'true'){
   this.setState({isAgree: true })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.varifyCOdeSIgnUpreceive(apiRequestCallId, responseJson);
      } else {
        this.varifyCOdeSIgnUpreceiveError(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  varifyCOdeSIgnUpreceive = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.varifyPutRespoID) {
      this.setState((prevState) => ({
        step: this.state.step + 1,
        code: responseJson?.invitation_code,
        allData:{
          ...prevState.allData,
          firstName: responseJson?.invitation_code?.first_name,
          lastName: responseJson?.invitation_code?.last_name,
        }
      }))
    }
    if (apiRequestCallId === this.varifyCodeSignupID) {
        this.setState({ step: this.state.step + 1, userType: responseJson.account.data?.attributes?.user_type });
        setStorageData("meta", JSON.stringify(responseJson.meta));
        setStorageData("authToken", responseJson?.meta?.token);
    }
    if (apiRequestCallId === this.verufyPassInvId) {

      setStorageData("allDataInvalid", JSON.stringify(this.state.allData));
      setStorageData("weGotoMFA", JSON.stringify("true"));
      this.props.navigation.navigate("SignupMFA");

      
    }
  };

  varifyCOdeSIgnUpreceiveError = (apiRequestCallId: any, responseJson: any) => {
    const errorMsg = responseJson?.errors[0]?.message;
    
    if (apiRequestCallId === this.varifyPutRespoID && errorMsg == "Invitation code Expired.") {
      this.setState({ errorModal: "", errorModelOpen: true });
    } else {
      this.setState({ errorModal: errorMsg, errorModelOpen: false });
    }
    if(apiRequestCallId === this.varifyCodeSignupID){
      this.setState({
        errorEmails:errorMsg
      })
      localStorage.removeItem("datas")
    }
    setStorageData("allDataInvalid", JSON.stringify(this.state.allData))

  };

  verifyCodePutAPI = (code: string) => {
    const verifyCodeAPIMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account/verify_invitation_code`
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          invitation_code: code,
        },
      })
    );
    this.varifyPutRespoID = verifyCodeAPIMes.messageId;
    runEngine.sendMessage(verifyCodeAPIMes.id, verifyCodeAPIMes);
    setStorageData("verifyCode",JSON.stringify(code))
  };

  checkValidattion=()=>{
    if(this.state.code.length < 1){
      this.setState({
        errorModal:"Please enter a valid invitation code."
      })
    } 
  }

  verifySignUpPassAPI = async (data: any) => {
    setStorageData("allDataInvalid", JSON.stringify(this.state.allData))
    setStorageData("user_type", "invited");
    this.checkValidattion();
    const meta = JSON.parse(await getStorageData("meta"));
    const verifyCodeAPIMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/set_password`
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    verifyCodeAPIMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        password: data.new_pass,
        password_confirmation: data.conf_pass,
      })
    );
    this.verufyPassInvId = verifyCodeAPIMes.messageId;
    runEngine.sendMessage(verifyCodeAPIMes.id, verifyCodeAPIMes);
  };
  verifyCodeSignUpAPi = (data: any) => {
    setStorageData("allDataInvalid", JSON.stringify(this.state.allData));
    setStorageData("emailId", JSON.stringify(data?.email));
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/invited_user`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            first_name: this.state.code.first_name,
            last_name: this.state.code.last_name,
            email: data?.email,
            invitation_code: this.state.code.invitation_code,
            is_terms_accepted: true,
          },
        },
      })
    );
    this.varifyCodeSignupID = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  handleTermsCondition = (props: any) => {
    props.setFieldValue("c1", !props.values.c1);
    this.setState({isAgree: !this.state.isAgree})
    setStorageData("isChecked", JSON.stringify(!this.state.isAgree))
  };

  goBackFn =()=>{
    this.props.navigation.goBack()
  }

  gototerms= (data: any)=>{
    this.props.navigation.navigate("TermsConditions");
    const datas = {
      email: data?.email,
      last_name: this.state.code?.last_name,
      first_name: this.state.code?.first_name,
      step: this.state.step,
      isAgree: this.state.isAgree,
      c2: data?.c2
    }
    setStorageData("datas",JSON.stringify(datas) );
  }
  gotoprivacy= (data: any)=>{
    this.props.navigation.navigate("PrivacyPolicy");

    const datas = {
      email: data?.email,
      last_name: this.state.code?.last_name,
      first_name: this.state.code?.first_name,
      step: this.state.step,
      isAgree: this.state.isAgree,
      c2: data?.c2
    }
    setStorageData("datas",JSON.stringify(datas) );
  };

  handelNameChange  = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      errorModal: "",
      allData: { code: event.target.value }
    })
  };

  emailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState((prevState) => ({
      errorEmails: "",
      allData: {
        ...prevState.allData,
        email: event.target.value
      }
    }))
  };

  checkBoxSecChange = (props: boolean) => {
    this.setState((prevState) => ({
      allData: {
        ...prevState.allData,
        c2: props
      }
    }));
  };

  secondClick = () => {
    this.setState({
      step: this.state.step - 1
    })
  };
  newPassChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
    this.setState((prevState) => ({
      allData: {
        ...prevState.allData,
        password: event.target.value
      }
    }))
  };

  confPassChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
    this.setState((prevState) => ({
      allData: {
          ...prevState.allData,
          conformPassword: event.target.value
      }
  }))
  };
  openedModelContact = () =>{
    this.setState({ errorModal: "" })
  };
  openedModelClose = () => {
    this.setState({ errorModal: "", errorModelOpen: false })
  };
  handle_continue_click =()=>{
    this.setState({ step: this.state.step + 1 })
  }
/* istanbul ignore next */
  handleNavigate = async () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), 'ContactSupport');
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  };
  // Customizable Area End
}
