Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";

exports.logBookDateEndPoint = "user_logbooks";
exports.reminderEndPoint = "/reminders";
exports.reminderNewEndPoint = "reminders";
exports.reminderTakenEndPoint = "reminders/reminder_time_update"
exports.reminderStatusEndPoint = "/reminders/update_status"
exports.curdate = { day: 'numeric', month: 'short', year: 'numeric' };
exports.noEventText = "Looks like you haven't scheduled any events yet."
exports.disclaimerText = "The mood and symptom tracker data is not shared with Cycle. If you have "+"a"+"n"+"y"+" issues with your symptoms or your medication please speak with your doctor."
// Customizable Area End
