// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const logbookicon = require("../assets/logbook_logo_image.svg");
export const upcomingevent = require("../assets/upcomingevent_image.svg");
export const noSymptoms = require("../assets/nosymptoms_images.svg");
export const adddisableSymptoms = require("../assets/addsymptoms_image.svg");
export const plusTreatment = require("../assets/treatment_plus_image.svg");
export const remindersIcon = require("../assets/remindersicon_image.svg");
export const reportThree= require("../assets/reportThreedot_image.svg");
export const pdfImage= require("../assets/pdf_image.svg");
export const logbbokiImage= require("../assets/iicon_image.svg");
export const thermostatImage= require("../assets/image_device_thermostat_image.svg");

export const noActivity = require("../assets/noactivity_image.svg");
export const cyclingActivity = require("../assets/image_cycling.svg");
export const poolActivity = require("../assets/image_pool.svg");
export const runningActivity = require("../assets/image_running.svg");
export const yogaActivity = require("../assets/image_yoga.svg");
export const gymActivity = require("../assets/image_gym.svg");
export const addActivity = require("../assets/image_add.svg");

export const calmMood = require("../assets/calm_image.svg");
export const excitedMood = require("../assets/image_excited.svg");
export const loveMood = require("../assets/image_loved.svg");
export const happyMood = require("../assets/image_happy.svg");
export const confusedMood = require("../assets/image_confused.svg");
export const nervousMood = require("../assets/image_nervous.svg");
export const sadMood = require("../assets/image_sad.svg");

export const addactivityClosed = require("../assets/addactivityclosed_image.svg");
export const addactivityRemove = require("../assets/addactivityremove_image.svg");

export const borderRight = require("../assets/right_image.svg");
export const symptoClosed = require("../assets/symclosed_image.svg");
export const symptoRight = require("../assets/symsright_image.svg");
export const activityRight = require("../assets/activtiy_image.svg");
export const reportImage = require("../assets/reportimage_image.svg");
export const noreportImage = require("../assets/noreport_image.svg");

export const upcomingTimeImage = require("../assets/upcommingtime_image.svg");
export const upcomingLocationImage = require("../assets/upcominglocation_image.svg");

export const selectImage = require("../assets/select_image.svg");
export const addTretImage = require("../assets/addtretcaps_image.svg");

export const saveDataClosed = require("../assets/logbookdatasaveclosed_image.svg");
export const reminderPlustre = require("../assets/reminderplus_image.svg");

export const saveDataChecked = require("../assets/checked_image.svg");
export const saveDataunChecked = require("../assets/unchecked_image.svg");
export const noreminderImage = require("../assets/image_.png");

export const medicationRightImage = require("../assets/medicationright_image.svg");
// Customizable Area End