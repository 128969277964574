export const Info = require("../assets/info.svg");
export const ReportChart = require("../assets/image.svg");
export const ThreeDots = require("../assets/three_dots.png");
export const AddImage = require("../assets/image_add.png");
export const ButtonClose = require("../assets/button_close.svg");
export const UploadImage = require("../assets/image_upload_file.svg");
export const AddBorder = require("../assets/add_border.svg");
export const ImageClose = require("../assets/image_close.svg");
export const PheClose = require("../assets/phe_close.svg");
export const PheEdit = require("../assets/phe_edit.svg");
export const ImageDocument = require("../assets/image_document.svg");
export const ImageCloseSmall = require("../assets/image_close_small.svg");
export const closeModal = require("../assets/group_mobile_popover_.svg");
export const calenderIcon = require("../assets/calender.png");
export const imageIcon = require("../assets/image_icon.svg");
export const downloadIcon = require("../assets/downloadIcon.svg");
export const backArrowIcon = require("../assets/backArrowIcon.svg");
