import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from "@material-ui/core";
import { calenderIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import CloseIcon from '@material-ui/icons/Close';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

const overrides: Overrides = {
  MuiPickersCalendarHeader: {
    switchHeader: {
      fontWeight: 600,
      ['& > div > p']: {
        fontWeight: 600,
      },
    },
    dayLabel: {
      fontWeight: 600,
      color:"#64748B"
    },
  
  },
  MuiPickersDay: {
    daySelected: {
        backgroundColor: "#D1EDFF",
        border:"1px solid #68B9EC",
        color: "#68B9EC",
    },
    current: {
        color: "#0F172A",
        ['& > span > p']: {
          fontWeight: 600,
        },
    },
  },
  MuiPaper:{
    rounded:{
      borderRadius:20
    }
  },
  MuiOutlinedInput: { 
    root: {
      '&$focused $notchedOutline': {
        borderColor: '#64748B',
      },
    },
    notchedOutline: {
      borderColor: '#64748B',
    },
    input: {
      "&::placeholder": {
        opacity: 1,
        color: '#64748B',
        fontFamily: 'Raleway',
        fontSize: '17px',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'left',
      },
    },
  },
} as Overrides;

const theme = createTheme({
  palette: {
    primary: {
      main: "#D1EDFF",
      contrastText: "#68B9EC",
    },
    
  },
  typography: {
    fontFamily: 'Raleway',
    h6: {
      fontWeight: 500,
    },
  },
  overrides
});
import { Overrides } from "@material-ui/core/styles/overrides";
// Customizable Area End

import SaveAsPdfController, { Props, configJSON } from "./SaveAsPdfController";

export default class SaveAsPdf extends SaveAsPdfController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <div style={styles.mainExportCss}>
        <SideBar navigation={this.props.navigation} />
        <Grid style={styles.mainContainer}>
            <Grid>
              <Grid container justifyContent="space-between" alignItems="center" style={styles.dialogmainGrid}>
                <Dialog open={this.state.open} fullWidth PaperProps={{ style: styles.exportMainDialog }}>
                  {this.state.isLoading && <Loader />}
                  <Grid style={styles.dialogTitleGrid}>
                    <Grid style={styles.dialogMainTitle}>Export Logbook Data</Grid>
                    <CloseIcon data-test-id="closeIconButton" onClick={this.handleClose} style={styles.dialogCancleIcon} />
                  </Grid>
                  <Grid style={styles.dialogSecoundMainTitle}>Select Date Range</Grid>
                  <div style={styles.bothDatepikers}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        autoOk
                        style={styles.dialogFirstInputField}
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        format="DD/MM/YYYY"
                        maxDate={new Date()}
                        margin="normal"
                        data-test-id="fromDateInput"
                        inputProps={{ placeholder: "From", readOnly: true }}
                        value={this.state.fromDate}
                        onChange={this.handleDateChange}
                        keyboardIcon={<img src={calenderIcon} alt="calendar" />}
                        PopoverProps={{
                          style: { marginTop: 10 },
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disableToolbar
                          maxDate={(this.handleMaxDate())}
                          minDate={this.state.fromDate}
                          readOnly={!this.state.fromDate}
                          inputVariant="outlined"
                          variant="inline"
                          style={styles.dialogFirstInputField}
                          format="DD/MM/YYYY"
                          margin="normal"
                          data-test-id="toDateInput"
                          inputProps={{ placeholder: "To", readOnly: true }}
                          value={this.state.toDate}
                          onChange={this.toDateInput}
                          keyboardIcon={<img src={calenderIcon} alt="calendar" />}
                          PopoverProps={{
                            style: { marginTop: 10 }
                          }}
                        />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Box style={styles.dialogBothButton} >
                    <Button style={styles.dialogFirstButton} data-test-id="handleClose" onClick={() => this.exportDataApi("email")} disabled={!this.state.fromDate || !this.state.toDate}>Send To Email</Button>
                    <Button style={{ ...styles.dialogSecoundButton, backgroundColor: this.state.fromDate && this.state.toDate ? '#13689E' : 'lightblue' }} data-test-id="handleDownload" onClick={() => this.exportDataApi("download")} disabled={!this.state.fromDate || !this.state.toDate} >Download Now</Button>
                  </Box>
                </Dialog>
              </Grid>
            </Grid>

        </Grid>
          <Dialog
            keepMounted
            open={this.state.successmodel}
            data-test-id="successResetModel"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: styles.firstDialogMainCSS }}>
            <DialogContent style={styles.dialogTextContaent}>
              <DialogContentText id="alert-dialog-slide-description">
                <Box style={styles.dialogMainText}>Report Sent Successfully!</Box>
                <Box style={styles.dialogLongText} >Your requested report has been successfully generated and sent to your email address. Please check your email inbox for a link to download the report.</Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions style={styles.dialogButtonDiv}>
              <Button
                data-test-id="successId"
                onClick={() => this.handleSuccessModelClose()}
                style={styles.dialogCloseButton}>Close</Button>
            </DialogActions>
          </Dialog>


          <Dialog
            keepMounted
            open={this.state.pdfdownload}
            data-test-id="successResetModel"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: styles.firstDialogMainCSS }}>
            <DialogContent style={styles.dialogTextContaent}>
              <DialogContentText id="alert-dialog-slide-description">
                <Box style={styles.closeButtonDialogLongText} >Your logbook has been downloaded successfully. You can now view it on your device.</Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions style={styles.dialogButtonDiv}>
              <Button
                data-test-id="successIdDownload"
                onClick={() => this.handleDownloadModelClose()}
                style={styles.dialogLastCloseButton}>Close</Button>
            </DialogActions>
          </Dialog>
      </div>
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  exportMainDialog: {
    display: 'inline-flex',
    padding: '35px 35px 25px 35px',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '24px',
    background: 'var(--Surface, #FFF)',
    maxWidth: "375px",
  },
  dialogmainGrid: { padding: "10px 15px", backgroundColor: "#fff", borderBottom: "1px solid #E2E8F0", marginBottom: "10px" },
  mainExportCss: { display: "flex" },
  mainContainer: { width: "100%", backgroundColor: "#FAFAFA", height: "auto", minHeight: "100vh" },
  dialogTitleGrid: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
  dialogMainTitle: {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600
  },
  dialogCancleIcon: {
    backgroundColor: "#F1F5F9",
    borderRadius: "30px",
    padding: "6px",
    cursor: "pointer"
  },
  dialogSecoundMainTitle: {
    color: '#1E293B',
    fontWeight: 600,
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '22px'
  },
  bothDatepikers: {
    width: "100%",
    flexDirection: "column" as "column",
    display: "flex",
  },
  dialogFirstInputField: {
    width: "100%", borderRadius: '8px',
    fontWeight: 600,
    border: '0.5px solid #F1F5F9',
    background: '#F1F5F9',
  },
  dialogBothButton: {
    display: "flex",
    gap: "15px",
    width: "100%"
  },
  dialogFirstButton: {
    fontFamily: 'Raleway',
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    textTransform: "none" as "none",
    borderRadius: '50px',
    fontStyle: 'normal',
    padding: "18px",
    lineHeight: '22px',
    width: "50%"
  },
  dialogSecoundButton: {
    color: '#FFF',
    fontSize: '14px',
    textTransform: "none" as "none",
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    borderRadius: '50px',
    fontWeight: 700,
    background: '#13689E',
    lineHeight: '22px',
    padding: "18px",
    width: "50%"
  },
  dialogCloseButton: {
    fontFamily: "Raleway",
    color: "#007AFF",
    fontWeight: 700,
    fontSize: "18px",
    fontStyle: "normal",
    textTransform: "none" as "none"
  },
  firstDialogMainCSS: {
    maxWidth: "750px",
    background: " #EAEAEA",
    height: 'auto',
    borderRadius: '24px',
  },
  dialogLastCloseButton: {
    color: "#007AFF",
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: "Raleway",
    lineHeight: "24px",
    fontSize: "17px",
    textTransform: "none" as "none",
  },
  dialogButtonDiv: {
    display: "flex",
    textAlign: 'center' as 'center',
    justifyContent: "center",
    color: '#007AFF',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px'
  },
  dialogLongText: {
    fontSize: "17px",
    color: '#334155',
    paddingTop: "16px",
    fontFamily: "Raleway",
    fontWeight: 500,
  },
  closeButtonDialogLongText: {
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.4000000059604645px",
    textAlign: "center" as "center",
    color: "#334155",
    padding:"9px 15px 0px 15px"
  },
  dialogMainText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: '#1E293B'
  },
  dialogTextContaent: {
    display: "flex",
    textAlign: "center" as "center",
    padding: "22px 16px 16px 16px"
  },
};
// Customizable Area End