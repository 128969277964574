import React from "react";
// Customizable Area Start
import { closeIcon, VitaLogo } from "./assets";
import {
  Typography,
  Box,
  styled,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CustomBG from "../../../components/src/CustomBG.web";
// Customizable Area End

import SplashscreenLoaderController, { Props } from "./SplashscreenLoaderController";

export default class SplashscreenLoader extends SplashscreenLoaderController {
  static instance(): any {
    throw new Error("Method not implemented.");
  }
  static state(arg0: string): any {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading, open } = this.state;
    return (
      <CustomBG>
        <SplashContainer component={"div"}>
          {loading && <Grid>
            <Grid>
              <img src={VitaLogo} className="MainVitaLogo" />
            </Grid>
            <Grid>
              <Box className="loaderBox">
                <CircularProgress className="vitaLogo" size={80} />
              </Box>
              <Box>
                <Typography className="text">Loading...</Typography>
              </Box>
            </Grid>
          </Grid>}
          <Dialog
            keepMounted
            open={open}
            onClose={this.handleClickOnRetry}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: {
                maxWidth: "600px",
                borderRadius: 24,
                padding: "20px",
                background: "#fff"
              },
            }}
          >
            <img src={closeIcon} className="closeIcon" style={{
              width: '24px',
              height: '24px',
              padding: '1px',
              borderRadius: '30px',
              alignSelf: 'flex-end'
            }} />
            <DialogContainer>
              <DialogContentText id="alert-dialog-slide-description" className="title" >
                Oops! It seems like there's an issue connecting to the app.
              </DialogContentText>
              <Typography className="metaTitle">To troubleshoot, ensure you are connected to a stable internet connection, check that you have the latest version of the app installed for optimal performance.</Typography>
            </DialogContainer>
            <DialogActions className="btnContainer">
              <Button
                onClick={() => this.handleClickOnRetry()}
                className="btn-retry"
                style={{
                  padding: '16px 55px',
                  borderRadius: '8px',
                  background: '#13689E',
                  color: "#FFF",
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  fontWeight: 700,
                  textTransform: "none",
                  lineHeight: '24px',
                }}
              >Retry</Button>
            </DialogActions>
          </Dialog>
        </SplashContainer>
      </CustomBG>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const DialogContainer = styled(DialogContent)({
  padding: "10px 10px",
  '& .closeIcon': {
    float: 'right',
    width: '32px',
    height: '32px',
    top: '16px',
    left: '552px',
    padding: '4px',
    borderRadius: '30px',
    gap: '10px',

  },
  '& .title': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '-0.12px'
  },
  '& .metaTitle': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  '& .btnContainer': {
    display: 'flex',
    width: '600px',
    height: '104px',
    padding: '24px 24px 24px 425px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
  },
});

const SplashContainer = styled(Box)({
  display: "flex",
  overflow: "auto",
  justifyContent: "conter",
  alignItems: "center",
  margin: 'auto',
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
  '& .loaderBox': {
    display: 'flex',
    justifyContent: 'center',
    margin: '50 0',
  },
  '& .vitaLogo': {
    color: 'rgb(19, 104, 158)',
  },
  '& .text': {
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
    width: '97%'
  },
  "& .MainVitaLogo":{
    "@media (max-width: 400px)": {
      width:"250px"
    },
    "@media (min-width: 400px)": {
      width:"336px"
    }
  }
  
});

// Customizable Area End
