import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { RefObject, createRef } from "react";
// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    calender: boolean,
    selectedYear: any,
    selectedMonth: any,
    openAddEvent: boolean,
    checkedAllDay: boolean,
    openRepetEvent: boolean,
    repetOpatonSelect: any,
    repetOpatonSelectIndex: any,
    saveRepetEvent: any,
    saveRepeatEventIndex: any,
    recuringOpationShow: any,
    repetOptionArray: any,
    openAddTime: boolean,
    openReminderEvent: boolean,
    reminderOpationSelect: any,
    saveReminderEvent: any,
    noReminder: boolean,
    location: any,
    notes: any,
    eventDate: any,
    selectedDate: any,
    selectedTime: any,
    selectedEndTime: any,
    saveEventMsg: boolean,
    editEventOpen: boolean,
    editEventDataSet: any,
    deleteConfirmation: boolean,
    token: any,
    eventName: any,
    overlapEvent: boolean,
    getEventListData: any,
    getEventReminderListData: any,
    editEventId: any,
    showEditEventDateUi: any,
    saveRecurringEvent: boolean,
    openReccuringEvent: boolean,
    deleteReceivingEvent: boolean,
    openDeleteReceuringEvent: boolean,
    conditionReceivingEvent: boolean,
    addTocalenderToggle: boolean,
    pastDateEventList: any,
    selectPastDateFlag: boolean,
    isLoading: boolean,
    disableSave: boolean,
    startTimeError: boolean,
    endTimeError: boolean,
    onBoarding: any;
    openOnboarding: boolean;
    onBoardingTitle: string;
    onBoardingText: string;
    onBoardingRef: string;
    currentStep: number;
    anchorHorizontal: string;
    transformHorizontal: string;
    selectDateFormated: any;
    onboardingRefs: RefObject<HTMLTableCellElement>[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EventsScheduleController extends BlockComponent<
    Props,
    S,
    SS
> {
    minuteInput: any;
    hourInput: any;

    // Customizable Area Start
    createAndUpadteEvents: string = "";
    getEventListApiCallId: string = "";
    getRemiderListApiCallId: string = "";
    getSingleEventDetailsCallId: string = "";
    getEventByDateCallId: string = "";
    deleteSingleEventCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            calender: true,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,
            openAddEvent: false,
            checkedAllDay: false,
            openRepetEvent: false,
            repetOpatonSelect: "Does not repeat",
            repetOpatonSelectIndex: "",
            saveRepetEvent: "Does not repeat",
            saveRepeatEventIndex: "",
            recuringOpationShow: "",
            repetOptionArray: ['Does not repeat', 'Every day', 'Every weekday (Mon - Fri)', 'Every week', 'Every month', 'Every year'],
            openAddTime: false,
            openReminderEvent: false,
            reminderOpationSelect: [],
            saveReminderEvent: [],
            noReminder: false,
            location: "",
            notes: "",
            eventDate: new Date().toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', }),
            selectedDate:  new Date(),
            selectedTime: this.convertISOToCustomTimeRound(new Date()),
            selectedEndTime: this.convertISOToCustomEndTimeRound(new Date()),
            saveEventMsg: false,
            editEventOpen: false,
            editEventDataSet: null,
            deleteConfirmation: false,
            token: "",
            eventName: "",
            overlapEvent: false,
            getEventListData: [],
            getEventReminderListData: [],
            editEventId: "",
            showEditEventDateUi: "",
            saveRecurringEvent: true,
            openReccuringEvent: false,
            deleteReceivingEvent: true,
            openDeleteReceuringEvent: false,
            conditionReceivingEvent: true,
            addTocalenderToggle: false,
            pastDateEventList: [],
            selectPastDateFlag: false,
            isLoading: false,
            disableSave: false,
            startTimeError: false,
            endTimeError: false,
            selectDateFormated: "",
            onboardingRefs: Array.from({ length: 2 }, () =>
                createRef<HTMLTableCellElement>()
            ),
            onBoarding: [{
                "id": 1,
                "onboarding_title": "Step 1",
                "onboarding_description": "Schedule Doctor's Appointment: Click here to set up your next medical appointment. Keep your health on track with timely scheduling.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 2,
                "onboarding_title": "Step 2",
                "onboarding_description": "Add Your Event: Click here to include your upcoming event. Stay organized by logging important dates and activities.",
                "transformHorizontal": "left",
                "anchorHorizontal": "left",
            },
            ],
            openOnboarding: false,
            onBoardingTitle: "",
            onBoardingText: "",
            onBoardingRef: "",
            currentStep: 0,
            anchorHorizontal: "right",
            transformHorizontal: "right"
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId === this.createAndUpadteEvents) {
                if (responseJson.message === "Event is successfully created" || responseJson.message === "Events are created in sometime") {
                    setTimeout(() => {
                        this.getEventsList();
                        this.getEventByDate(this.state.selectDateFormated);
                    }, 2000);
                    this.setState({ openAddEvent: !this.state.openAddEvent, saveEventMsg: !this.state.saveEventMsg, isLoading: false, });
                    if (this.state.addTocalenderToggle) {
                        this.generateICSFile();
                    }
                    this.handleCloseAddEvent();
                }
                if (responseJson.message === "Events are updated after sometime") {
                    setTimeout(() => {
                        this.getEventsList();
                        this.getEventByDate(this.state.selectDateFormated);
                    }, 2000);
                    this.setState({ openAddEvent: !this.state.openAddEvent, isLoading: false, });
                    if (this.state.addTocalenderToggle) {
                        this.generateICSFile();
                    }
                    this.handleCloseAddEvent();
                }
                if(responseJson.message === "Frequency changes to does not repeat"){
                    setTimeout(() => {
                        this.getEventsList();
                        this.getEventByDate(this.state.selectDateFormated);
                    }, 2000);
                    this.setState({ openAddEvent: !this.state.openAddEvent, isLoading: false, });
                }
                if (responseJson.data) {
                    this.getEventsList();
                    this.getEventByDate(this.state.selectDateFormated);
                    this.setState({ openAddEvent: !this.state.openAddEvent, isLoading: false, });
                    if (this.state.addTocalenderToggle) {
                        this.generateICSFile();
                    }
                    this.handleCloseAddEvent();
                }
                else if (responseJson.message === "Event is overlapping with existing events") {
                    this.setState({ openAddEvent: !this.state.openAddEvent, overlapEvent: !this.state.overlapEvent, isLoading: false });

                }
            }
            if (apiRequestCallId === this.getEventListApiCallId) {
                this.setState({ getEventListData: responseJson.events.data, isLoading: false })
            }
            if (apiRequestCallId === this.getRemiderListApiCallId) {
                this.setState({ getEventReminderListData: responseJson.data }, () => {
                    if (this.state.getEventReminderListData?.length > 0) {
                        const firstOption = this.state.getEventReminderListData[0];
                        this.setState({ reminderOpationSelect: [firstOption] });
                        this.setState({ saveReminderEvent: [firstOption] })
                    }
                });
            }
            if (apiRequestCallId === this.getSingleEventDetailsCallId) {
                this.setState({ editEventDataSet: responseJson.data })
                this.setState({
                    editEventOpen: !this.state.editEventOpen,
                    location: this.state.editEventDataSet.attributes.address,
                    notes: this.state.editEventDataSet.attributes.notes,
                    checkedAllDay: this.state.editEventDataSet.attributes.all_day,
                    noReminder: !this.state.editEventDataSet.attributes.is_reminder,
                    eventName: this.state.editEventDataSet.attributes.title,
                    saveReminderEvent: this.state.editEventDataSet.attributes.Reminders,
                    reminderOpationSelect: this.state.editEventDataSet.attributes.Reminders,
                    repetOpatonSelect: this.state.editEventDataSet.attributes.repeat,
                    saveRepetEvent: this.state.editEventDataSet.attributes.repeat,
                    saveRepeatEventIndex: this.state.repetOptionArray.indexOf(this.state.editEventDataSet.attributes.repeat),
                    recuringOpationShow: this.state.repetOptionArray.indexOf(this.state.editEventDataSet.attributes.repeat),
                    showEditEventDateUi: this.convertDateForEdit(this.state.editEventDataSet.attributes.date),
                    selectedTime: this.convertISOToCustomTime(this.state.editEventDataSet.attributes.start_time),
                    selectedEndTime: this.convertISOToCustomTime(this.state.editEventDataSet.attributes.end_time),
                    eventDate: this.convertDate(this.state.editEventDataSet.attributes.date),
                    selectedDate: new Date(this.state.editEventDataSet.attributes.date),
                });
            }
            if (apiRequestCallId === this.getEventByDateCallId) {
                if (responseJson.data) {
                    this.setState({ pastDateEventList: responseJson.data });
                }
                else {
                    this.setState({ pastDateEventList: [] });
                }
            }
            if (apiRequestCallId === this.deleteSingleEventCallId) {
                if (responseJson.message === "Successfully deleted the record" || responseJson.message === "Successfully deleted the all events record") {
                    this.setState({ isLoading: false })
                    this.getEventsList();
                    this.setState({ openDeleteReceuringEvent: !this.state.openDeleteReceuringEvent });
                    this.getEventByDate(this.state.selectDateFormated);
                    this.handleCloseAddEvent();
                }
                if(responseJson.message === "Events are deleted after sometime"){
                    setTimeout(()=>{
                        this.setState({ isLoading: false })
                        this.getEventsList();
                        this.setState({ openDeleteReceuringEvent: !this.state.openDeleteReceuringEvent })
                        this.getEventByDate(this.state.selectDateFormated);
                        this.handleCloseAddEvent();
                    },5000)
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getEventsList();
        this.getReminderListingList();

        let onboarding = await getStorageData("onBoarding");
        let authToken = await getStorageData("authToken");
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
        if (onboarding === null) {
            this.setState({ openOnboarding: true });
            this.nextOnBoarding();
        }
        // Customizable Area End
    }

    // Customizable Area Start


    convertDateStartTimeToDate(selectDate: any, selectTime: any) {
        // selectdate = Date Wed Apr 18 2024 00:00:00 GMT+0530 (Coordinated Universal Time)selectetime = "1445" in this code convert in this
        //  Date Wed Apr 18 2024 14:45:00 GMT+0000 (Coordinated Universal Time)
        const selectDates = selectDate;
        const hours = parseInt(selectTime.substr(0, 2), 10);
        const minutes = parseInt(selectTime.substr(2), 10);
        // Set the hours and minutes in the local time zone
        selectDates.setHours(hours);
        selectDates.setMinutes(minutes);
        // Format the date to the desired output "YYYYMMDDTHHMMSS"
        const year = selectDates.getFullYear();
        const month = String(selectDates.getMonth() + 1).padStart(2, '0');
        const day = String(selectDates.getDate()).padStart(2, '0');
        const hour = String(selectDates.getHours()).padStart(2, '0');
        const minute = String(selectDates.getMinutes()).padStart(2, '0');
        const second = String(selectDates.getSeconds()).padStart(2, '0');
        return `${year}${month}${day}T${hour}${minute}${second}`;
    }


    generateICSFile = () => {
        const formattedStartDateTime = this.convertDateStartTimeToDate(this.state.selectedDate, this.state.checkedAllDay ? "0005" : this.state.selectedTime);
        const formattedEndDateTime = this.convertDateStartTimeToDate(this.state.selectedDate, this.state.checkedAllDay ? "2355" : this.state.selectedEndTime);

        let rrule;
        switch (this.state.saveRepeatEventIndex) {
            case 1: // Every Day
                rrule = 'RRULE:FREQ=DAILY';
                break;
            case 2: // Every Weekday (Mon - Fri)
                rrule = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';
                break;
            case 3: // Every Week
                rrule = 'RRULE:FREQ=WEEKLY';
                break;
            case 4: // Every Month
                rrule = 'RRULE:FREQ=MONTHLY';
                break;
            case 5: // Every Year
                rrule = 'RRULE:FREQ=YEARLY';
                break;
            case 0: // No Recurrence
            default:
                rrule = '';
        }

        const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${this.state.eventName}
DESCRIPTION:${this.state.notes}
LOCATION:${this.state.location}
DTSTART:${formattedStartDateTime}
DTEND:${formattedEndDateTime}
${rrule}
BEGIN:VALARM
TRIGGER:-PT10M
ACTION:DISPLAY
DESCRIPTION:Reminder
END:VALARM
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `calendar${formattedStartDateTime}.ics`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Clean up
        URL.revokeObjectURL(url);
    };

    nextOnBoarding = () => {
        const { onBoarding, currentStep } = this.state;
        if (onBoarding.length > currentStep) {
            this.setState({
                onBoardingTitle: onBoarding[currentStep].onboarding_title,
                onBoardingText: onBoarding[currentStep].onboarding_description,
                transformHorizontal: onBoarding[currentStep].transformHorizontal,
                anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
                currentStep: currentStep + 1
            })
            const targetRef = this.state.onboardingRefs[currentStep];
            if (targetRef && targetRef.current) {
                targetRef.current.scrollIntoView({
                    block: 'center',
                });
            }
        }
        else {
            this.setState({
                openOnboarding: false
            });
            this.props.navigation.navigate('Preferences');

        }
    }

    skipOnBoarding = async () => {
        this.setState({
            openOnboarding: false,
            currentStep: 0
        })
        await setStorageData("onBoarding", "true");
    }

    handleChange = (event: any) => {
        this.setState({ calender: !this.state.calender }, () => this.getEventsList());
        this.setState({ selectPastDateFlag: false, eventDate: new Date().toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', }) });
    };

    handleYearChange = (event: any) => {
        this.setState({ selectedYear: parseInt(event.target.value) }, () => this.getEventsList());
        this.setState({ selectPastDateFlag: false, eventDate: new Date().toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', }), });
    }

    handleMonthChange = (event: any) => {
        this.setState({ selectedMonth: parseInt(event.target.value) }, () => this.getEventsList());
        this.setState({ selectPastDateFlag: false, eventDate: new Date().toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', }), });
    }

    prevYear = () => {
        this.setState({ selectedYear: this.state.selectedYear - 1 }, () => this.getEventsList());

    }

    nextYear = () => {
        this.setState({ selectedYear: this.state.selectedYear + 1 }, () => this.getEventsList());
    }

    convertISOToCustomTimeRound = (isoString: any) => {
        const date = new Date(isoString);
        const hours = date.getHours();
        const minutes = date.getMinutes() + 4;
        const roundedMinutes = Math.round(minutes / 5) * 5;
        let roundedHours = hours;
        if (roundedMinutes === 60) {
            roundedHours++;
        }
        const formattedHours = roundedHours.toString().padStart(2, '0');
        const formattedMinutes = roundedMinutes.toString().padStart(2, '0');

        return `${formattedHours}${formattedMinutes}`;
    }

    convertISOToCustomEndTimeRound = (isoString: any) => {
        const date = new Date(isoString);
        const hours = date.getHours();
        const minutes = date.getMinutes() + 9;
        const roundedMinutes = Math.round(minutes / 5) * 5;
        let roundedHours = hours;
        if (roundedMinutes === 60) {
            roundedHours++;
        }
        const formattedHours = roundedHours.toString().padStart(2, '0');
        const formattedMinutes = roundedMinutes.toString().padStart(2, '0');

        return `${formattedHours}${formattedMinutes}`;
    }

    

    handleClickAddEventOpen = () => {
        this.setState({ selectedTime: this.convertISOToCustomTimeRound(new Date()), selectedEndTime: this.convertISOToCustomEndTimeRound(new Date()),openAddEvent: true, });
    };

    handleCloseAddEvent = () => {
        this.setState({
            openAddEvent: false,
        });
        this.resetStateFunction();
    }

    handleAllDayChange = () => {
        this.setState({ checkedAllDay: !this.state.checkedAllDay })
        if (!this.state.checkedAllDay) {
            this.setState({ selectedTime: "0005", selectedEndTime: "2355" })
        }
        else {
            this.setState({ selectedTime: this.convertISOToCustomTimeRound(new Date()), selectedEndTime: this.convertISOToCustomEndTimeRound(new Date()) })
        }
    }

    handleCloseRepetEvent = () => {
        this.setState({ openRepetEvent: false });
        this.setState({ openAddEvent: true });
    }

    handleClickRepetEventOpen = () => {
        this.setState({ openRepetEvent: true });
        this.setState({ openAddEvent: false });
    }

    handleOptionChangeRepet = (option: any, index: any) => {
        this.setState({ repetOpatonSelect: option, repetOpatonSelectIndex: index });
    }

    handleSaveRepetEvent = () => {
        this.setState({ saveRepetEvent: this.state.repetOpatonSelect, saveRepeatEventIndex: this.state.repetOpatonSelectIndex });
        this.handleCloseRepetEvent();
    }

    handleCloseReminderEvent = () => {
        this.setState({ openReminderEvent: false });
        this.setState({ openAddEvent: true });
    }

    handleClickReminderEventOpen = () => {
        this.setState({ openReminderEvent: true });
        this.setState({ openAddEvent: false });
    }

    handleOptionChangeReminder = (option: any) => {
        const { reminderOpationSelect } = this.state;

        const isAlreadySelected = reminderOpationSelect.some(
            (reminder: any) => reminder.id === option.id
        );
        const updatedOptions = isAlreadySelected
            ? reminderOpationSelect.filter((item: any) => item.id !== option.id)
            : [...reminderOpationSelect, option];

        this.setState({ reminderOpationSelect: updatedOptions });
    };


    handleSaveReminderEvent = () => {
        this.setState({ saveReminderEvent: this.state.reminderOpationSelect });
        this.handleCloseReminderEvent();
    }

    handleRemoveReminder = (optionToRemove: any) => {
        const updatedReminders = this.state.saveReminderEvent.filter((option: any) => option !== optionToRemove);
        this.setState({ saveReminderEvent: updatedReminders });
        this.setState({ reminderOpationSelect: updatedReminders });
    }

    noRemiderHandalChange = () => {
        this.setState({ noReminder: !this.state.noReminder });
        if (!this.state.noReminder) {
            this.setState({ saveReminderEvent: [] });
            this.setState({ reminderOpationSelect: [] });
        }
    }

    handleLocationChange = (event: any) => {
        this.setState({ location: event.target.value });
    }

    handleNotesChange = (event: any) => {
        this.setState({ notes: event.target.value });
    }

    handleEventNameChange = (event: any) => {
        this.setState({ eventName: event.target.value })
    }

    handleDateClick = (date: any, pastDate: boolean) => {
        const dateSelect = new Date(date);
        const day = dateSelect.getDate();
        const month = dateSelect.getMonth() + 1;
        const year = dateSelect.getFullYear();
        const paddedDay = day < 10 ? `0${day}` : day;
        const paddedMonth = month < 10 ? `0${month}` : month;
        const formattedDate1 = `${paddedDay}-${paddedMonth}-${year}`;
        this.setState({ selectDateFormated: formattedDate1 });
        this.getEventByDate(formattedDate1);
        this.setState({ selectPastDateFlag: true, eventDate: "" });
        if (!pastDate) {
            const formattedDate = date.toLocaleString('en-US', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            });
            this.setState({ eventDate: formattedDate, selectedDate: date });
        }
    }

    filteredEvents = () => {
        // Assuming this.state.getEventListData is an array
        return this.state.getEventListData
            ?.sort((a: any, b: any) => {
                const dateA = new Date(a.attributes.date) as any as number;
                const dateB = new Date(b.attributes.date) as any as number;
                return dateA - dateB;
            })
            ?.filter((event: any) => {
                const eventDate = new Date(event.attributes.date);
                const today = new Date();
                // Compare event date with today's date
                return eventDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0);
            }) || []; // Ensure that it always returns an array
    };

    formateDateForToday = (date: any) => {
        const formattedDate = date.toLocaleString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        return formattedDate;
    }

    onHandleChangeSvaeReccuring = (event: any) => {
        this.setState({ saveRecurringEvent: event });
    }

    handleOpenReccuringEvent = () => {
        this.setState({ openReccuringEvent: !this.state.openReccuringEvent });
    }

    handleCloseReccuringEvent = () => {
        this.setState({ openReccuringEvent: !this.state.openReccuringEvent });
    }

    onHandleChangeDeleteReccuringEvent = (event: any) => {
        this.setState({ deleteReceivingEvent: event });
    }

    handleCloseDeleteReccuringEvent = () => {
        this.setState({ openDeleteReceuringEvent: !this.state.openDeleteReceuringEvent });
        this.handleCloseAddEvent();
    }

    handleOpenDeleteReccuringEvent = () => {
        this.setState({ openDeleteReceuringEvent: !this.state.openDeleteReceuringEvent });
    }

    deleteAppointmentConfirme = () => {
        this.setState({ editEventOpen: false, openDeleteReceuringEvent: true });
    }

    handleDeleteData = () => {
        const { editEventDataSet } = this.state;
        this.deleteSingleEvent(editEventDataSet.id)
    }

    handleSaveEditData = () => {
        this.setState({ conditionReceivingEvent: true, openReccuringEvent: !this.state.openReccuringEvent }, () => this.saveAppointment());
    }

    addTolocalCalendar = () => {
        this.setState({ addTocalenderToggle: !this.state.addTocalenderToggle }, () => this.saveAppointment());
    }


    convertDateTimeToLocal(dateString: any, timeString: any) {
        const [day, month, year] = dateString.split('-').map(Number);
        const hours = timeString.slice(0, 2);
        const minutes = timeString.slice(2);
        const localDate = new Date(year, month - 1, day, hours, minutes);
        const localYear = localDate.getFullYear();
        const localMonth = localDate.getMonth() + 1;
        const localDay = localDate.getDate();
        const localHours = localDate.getHours();
        const localMinutes = localDate.getMinutes();
        const isPM = localHours >= 12;
        const formattedHours = localHours % 12 || 12;
        const ampm = isPM ? 'pm' : 'am';

        const formattedDate = `${localDay.toString().padStart(2, '0')}-${localMonth.toString().padStart(2, '0')}-${localYear}`;
        const formattedTime = `${formattedHours}:${localMinutes.toString().padStart(2, '0')} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    }


    saveAppointment = async () => {
        const { eventName, eventDate, checkedAllDay, saveRepeatEventIndex, saveReminderEvent, location, notes, selectedTime, selectedEndTime, noReminder, editEventId, saveRecurringEvent } = this.state;
        // dateformate
        const date = new Date(eventDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const paddedDay = day < 10 ? `0${day}` : day;
        const paddedMonth = month < 10 ? `0${month}` : month;
        const formattedDate = `${paddedDay}-${paddedMonth}-${year}`;
        // Wed, 01 Feb, 2024 to 01-01-2024
        // dateformate


        const reminderLisrArray = saveReminderEvent.map((item: any) => item.id);
        // [id:1,reminder:'5 minutes before', id:3 ,reminder:'15 minutes before'] select event convert in [1,3]
        const requestMethod = editEventId === "" ? "POST" : "PUT";

        const requestEndPoint = editEventId === "" ? "bx_block_events/events" : "bx_block_events/update_events";

        const httpBody = editEventId === "" ? {
            "event": {
                "title": eventName,
                "all_day": checkedAllDay,
                "date": formattedDate,
                "start_time": checkedAllDay ? `${this.convertDateTimeToLocal(formattedDate, "0005")}` : `${this.convertDateTimeToLocal(formattedDate, selectedTime)}`,
                "end_time": checkedAllDay ? `${this.convertDateTimeToLocal(formattedDate, "2355")}` : `${this.convertDateTimeToLocal(formattedDate, selectedEndTime)}`,
                "repeat": saveRepeatEventIndex === "" ? 0 : saveRepeatEventIndex,
                "reminders": !noReminder ? reminderLisrArray : [],
                "is_reminder": !noReminder,
                "address": location,
                "notes": notes
            }
        } : {
            "event": {
                "id": editEventId,
                "title": eventName,
                "all_day": checkedAllDay,
                "date": formattedDate,
                "start_time": checkedAllDay ? `${this.convertDateTimeToLocal(formattedDate, "0005")}` : `${this.convertDateTimeToLocal(formattedDate, selectedTime)}`,
                "end_time": checkedAllDay ? `${this.convertDateTimeToLocal(formattedDate, "2355")}` : `${this.convertDateTimeToLocal(formattedDate, selectedEndTime)}`,
                "repeat": saveRepeatEventIndex === "" ? 0 : saveRepeatEventIndex,
                "reminders": !noReminder ? reminderLisrArray : [],
                "is_reminder": !noReminder,
                "address": location,
                "notes": notes
            },
            "this_event": `${saveRecurringEvent}`
        }
        if (this.state.conditionReceivingEvent) {
            this.setState({ isLoading: true });
            const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createAndUpadteEvents = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), requestMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
        else {
            this.handleOpenReccuringEvent()
        }

    }

    getFormattedDateForUrl = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    getEventsList = async () => {
        const startOfMonth = new Date(this.state.selectedYear, this.state.selectedMonth - 1, 1, 1, 0);
        const startDateTime = `${this.getFormattedDateForUrl(startOfMonth)} 00:05 am`;
        const endOfMonth = new Date(this.state.selectedYear, this.state.selectedMonth, 0, 23, 45);
        const endDateTime = `${this.getFormattedDateForUrl(endOfMonth)} 11:55 pm`;

        const startOfYear = new Date(this.state.selectedYear, 0, 1, 1, 0);
        const startDateYear = `${this.getFormattedDateForUrl(startOfYear)} 00:05 am`;
        const endOfYear = new Date(this.state.selectedYear, 12, 0, 23, 45);
        const endDateYear = `${this.getFormattedDateForUrl(endOfYear)} 12:55 pm`;

        this.setState({ isLoading: true });
        const requestEndPoint = this.state.calender ? `bx_block_events/events?start_date_time=${startDateTime}&end_date_time=${endDateTime}` :
            `bx_block_events/events?start_date_time=${startDateYear}&end_date_time=${endDateYear}`

        const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEventListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSingleEventDetails = async (appointmentId: any) => {

        const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSingleEventDetailsCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_events/events/${this.state.editEventId ? this.state.editEventId : appointmentId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getEventByDate = async (date: any) => {

        const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEventByDateCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_events/events_by_date?date=${date}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteSingleEvent = async (deleteId: any) => {
        this.setState({ isLoading: true })
        const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteSingleEventCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_events/events/${this.state.editEventId ? this.state.editEventId : deleteId}?this_event=${this.state.deleteReceivingEvent}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getReminderListingList = async () => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRemiderListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_events/reminder_listing');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleStartTimeChange = (event: any) => {
        const selectedTime = event.target.value;
        this.setState({ selectedTime }, this.validateTimes);
    }

    handleEndTimeChange = (event: any) => {
        const selectedEndTime = event.target.value;
        this.setState({ selectedEndTime }, this.validateTimes);
    }

    validateTimes = () => {
        const { selectedTime, selectedEndTime, selectedDate } = this.state;
        const startTime = parseInt(selectedTime) - 5;
        const endTime = parseInt(selectedEndTime) - 5;
        const now = new Date();
        const currentHour = parseInt(this.convertISOToCustomTimeRound(now))-10;
        const isSameDay = selectedDate.setHours(0, 0, 0, 0) === now.setHours(0, 0, 0, 0);

        let disableSave = false;
        let startTimeError = false;
        let endTimeError = false;

        if (startTime > endTime) {
            endTimeError = true;
            disableSave = true;
        }
        if (isSameDay && startTime < currentHour) {
            startTimeError = true;
            disableSave = true;
        }
        if (isSameDay && endTime < currentHour) {
            endTimeError = true;
            disableSave = true;
        }
        this.setState({
            disableSave,
            startTimeError,
            endTimeError,
        });
    }

    handleCloseSaveEventMsg = () => {
        this.setState({
            saveEventMsg: !this.state.saveEventMsg,
        });
        this.resetStateFunction();
    }

    resetStateFunction = () => {
        this.setState({
            location: "",
            notes: "",
            eventDate: new Date().toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', }),
            eventName: "",
            selectedDate:  new Date(),
            selectedTime: this.convertISOToCustomTimeRound(new Date()),
            selectedEndTime: this.convertISOToCustomEndTimeRound(new Date()),
            repetOpatonSelect: "Does not repeat",
            saveRepetEvent: "",
            saveRepeatEventIndex: "",
            recuringOpationShow: "",
            reminderOpationSelect: [],
            saveReminderEvent: [],
            editEventId: "",
            openReccuringEvent: false,
            openDeleteReceuringEvent: false,
            deleteReceivingEvent: true,
            conditionReceivingEvent: true,
            checkedAllDay: false,
            addTocalenderToggle: false,
            disableSave: false,
            startTimeError: false,
            endTimeError: false,
        });
        this.getReminderListingList();
    }

    showEvent = (appointmentId: any, date: any) => {
        if (this.formatDateToISOString(date)) {
            this.setState({ editEventId: appointmentId });
            this.getSingleEventDetails(appointmentId);
        }
    }


    formatDateToISOString = (eventDate:string) =>{
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const days = ('0' + currentDate.getDate()).slice(-2);
        const hours = ('0' + currentDate.getHours()).slice(-2);
        const minutes = ('0' + currentDate.getMinutes()).slice(-2);
        const seconds = ('0' + currentDate.getSeconds()).slice(-2);
        const formattedCurrentDate = `${year}-${month}-${days}T${hours}:${minutes}:${seconds}.000Z`;
        return formattedCurrentDate < eventDate;
    }
      
    closeShowEvent = () => {
        this.setState({ editEventOpen: false })
        this.handleCloseAddEvent();
    }

    convertDate = (inputDate: any) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const [year, month, day] = inputDate?.split('-');
        const dateObject = new Date(year, month - 1, day);
        const dayOfWeek = dateObject.getDay();
        const formattedDate = `${months[dateObject.getMonth()]} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
        const formattedDayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
        return `${formattedDayOfWeek}, ${formattedDate}`;
    };

    convertDateForEdit = (inputDate: any) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const [year, month, day] = inputDate?.split('-');
        const dateObject = new Date(year, month - 1, day);
        const dayOfWeek = dateObject.getDay();
        const formattedDate = `${dateObject.getDate()} ${months[dateObject.getMonth()]}`;
        const formattedDayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
        return `${formattedDayOfWeek}, ${formattedDate}`;
    }

    convertISOToCustomTime = (isoString: any) => {
        const date = new Date(isoString);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}${formattedMinutes}`;
    }

    editAppointment = () => {
        this.setState({
            editEventOpen: false,
            openAddEvent: true,
            conditionReceivingEvent: false,
        });
    }

    overlapEventClose = () => {
        this.setState({
            overlapEvent: !this.state.overlapEvent
        })
        this.handleCloseAddEvent();
    }

    overlapEventRetry = () => {
        this.setState({
            openAddEvent: !this.state.openAddEvent,
            overlapEvent: !this.state.overlapEvent
        })
        if (this.state.editEventId !== "") {
            this.setState({ conditionReceivingEvent: false })
        }
    }

    formatDateString = (startTime: string, endTime: string): string => {
        const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
        const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };

        const startDate: Date = new Date(startTime.slice(0, 19).replace('T', ' '));
        const endDate: Date = new Date(endTime.slice(0, 19).replace('T', ' '));

        const currentDate: Date = new Date();

        if (startDate.toDateString() === currentDate.toDateString()) {
            return `Today at ${startDate.toLocaleTimeString('en-US', timeOptions)} - ${endDate.toLocaleTimeString('en-US', timeOptions)}`;
        }

        const formattedDate: string = startDate.toLocaleDateString('en-GB', dateOptions)
            .replace(/\d+(st|nd|rd|th)?/, (newdayday) => newdayday)
            .replace(/(\s\d{1,2}\s\w{3})(\s\d{4})/, "$1,$2");

        const formattedStartTime: string = startDate.toLocaleTimeString('en-US', timeOptions);
        const formattedEndTime: string = endDate.toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate} \u00B7 ${formattedStartTime} - ${formattedEndTime}`;
    };


    // Customizable Area End
}