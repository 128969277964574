import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Container,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import {  document, closesmall, closesmodel, closessucsessmodel, selectimage, helpimage } from "./assets";
import ContactSupportController, { Props,configJSON } from "./ContactSupportController.web";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomBG from "../../../components/src/CustomBG.web";

const styles = {
  input: {
    border: '1px solid #CBD5E1',
    background: '#f4faffbd',
    borderRadius: '8px',
    minHeight: '50px',
    fontFamily: "Raleway"
  },
  textarea: {
    minHeight: '116px',
    background: '#f4faffbd',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
  },
  boxWidth:{width:"100%"},
  emailTextfield:{ color: '#64748B', fontFamily: "Raleway" },
  emailTextfieldInput:{ marginLeft: "0px", fontFamily: "Raleway"},
  emailTextfieldQurey:{ minHeight: "116px" },
  qureyFieldCss:{ minHeight: "116px" },
  modelMain:{ boxShadow: 24 },
  modelImgCss:{ cursor: "pointer" },
  modelMainBox:{ display: "flex", gap: "22px"},
  modelMainBoxSecound: {
    overflow: 'auto' as 'auto',
    whiteSpace: 'nowrap', width: {
      md: 'auto', lg: 'auto',
    }, padding: '10px 2px'
  },
  modelLastCss : {position: "relative" as  "relative", background: "#f4faffbd", borderRadius: "50px", padding: "5px 4px" },
  boxTypography:{
    fontSize: "12px", display: "flex",
    alignItems: "center" as "center",
    fontFamily: "Raleway",
    whiteSpace: 'nowrap' as "nowrap",
  },
  boxImgField: { minHeight: "18px", maxWidth: "18px", position: "relative" as "relative", bottom: 16, cursor: "pointer" },
  boxSubmit:{ display: "flex", justifyContent: "end" },
  submitBox:{ boxShadow: 24 },
  submitImg:{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" },
  lastSelect:{ boxShadow: 24 }






};
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ContactSupport extends ContactSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <CustomBG>
      <ThemeProvider theme={theme}>
        {/* Customizable Area Start */}
        <Box style={styles.boxWidth}>
            <MainContainerbox>
              <Container maxWidth="md">
                <Formik
                  initialValues={{
                    email: '',
                    subject: '',
                    yourquery: '',
                    file: '',
                  }}
                  data-testid="Formik"
                  validationSchema={Yup.object({
                    email: Yup.string().email('Please enter a valid email address.').required('Please enter email address.'),
                    subject: Yup.string().max(1000, configJSON.subValidation).required('Please enter subject.'),
                    yourquery: Yup.string().required('Please enter query.'),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.contactusDataaValue(values)
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({ values, handleChange, errors, handleSubmit, setFieldValue, touched }) => (
                    <HelpDataSubmit data-testid="contact-datasubmit" onSubmit={handleSubmit}>
                      <Box className="HelpContainer">
                        <Box>
                          <img src={helpimage} alt={helpimage} />
                        </Box>
                        <Box className="helpTitle">Help & Support</Box>
                      </Box>
                      <TextField
                        id="email"
                        onChange={handleChange}
                        InputLabelProps={{ style: styles.emailTextfield  }}
                        InputProps={{ disableUnderline: true, style: { ...styles.input, border: this.getBorderstyle(touched.email, errors.email) } }}
                        label="Email"
                        variant="filled"
                        value={values.email}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                       FormHelperTextProps={{ style: styles.emailTextfieldInput }}
                      />
                      <TextField
                        id="subject"
                        onChange={handleChange}
                        InputLabelProps={{ style: styles.emailTextfield  }}
                        InputProps={{ disableUnderline: true, style: { ...styles.input, border: this.getBorderstyle(touched.subject, errors.subject) } }}
                        label="Subject"
                        variant="filled"
                        value={values.subject}
                        error={touched.subject && !!errors.subject}
                        helperText={touched.subject && errors.subject}
                        FormHelperTextProps={{ style: styles.emailTextfieldInput }}
                      />
                      <StyledTextField
                        onChange={handleChange}
                        InputLabelProps={{ style: styles.emailTextfield  }}
                        InputProps={{ disableUnderline: true, style: { ...styles.input, border: this.getBorderstyle(touched.yourquery, errors.yourquery) } }}
                        inputProps={{ style: styles.emailTextfieldQurey }}
                        id="yourquery"
                        label="Enter your query"
                        value={values.yourquery}
                        multiline
                        variant="filled"
                        style={styles.qureyFieldCss}
                        error={touched.yourquery && !!errors.yourquery}
                        helperText={touched.yourquery && errors.yourquery}
                        FormHelperTextProps={{ style: styles.emailTextfield  }}
                      />
                      <SelectFile data-testid="select_file" onClick={() => { this.openPictureUpload() }}>
                        <img src={selectimage} alt="Select_File" />
                        <SelectFileDiv> Select File </SelectFileDiv>
                      </SelectFile>
                      <SelectFileValidation>
                        {configJSON.pdfSelectValidation}
                      </SelectFileValidation>
                      <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableBackdropClick
                      >
                        <ModelInnerBox sx={styles.modelMain}>
                          <ModelErrorOne>You've reached the maximum limit of 5 files. Please </ModelErrorOne>
                          <ModelErrorTwo>remove some files to add more.</ModelErrorTwo>
                          <ModelErrorBorder></ModelErrorBorder>
                          <img className="uploadBoximg" data-testid="handleCloseButton" style={styles.modelImgCss} onClick={this.handleClose} src={closesmodel} />
                        </ModelInnerBox>
                      </Modal>
                      <PdfMainBox style={styles.modelMainBox} sx={styles.modelMainBoxSecound}>
                        {this.state.selectedFile?.map((value: { name: string }, index: number) => {
                          return (
                            <Box className="fieldAreaShow" key={index} style={{...styles.modelLastCss, display: value?.name ? 'flex' : 'none',}} data-test-id="portableNewOneiamghe">
                              <Typography className="fieldAreaFileName portableNewOneiamghe" data-test-id="handleRemove" style={styles.boxTypography}>
                                <img className="uploadBoximg" src={document} />{value?.name}</Typography>
                              <img className="fieldImg" style={styles.boxImgField} data-testid="handleRemoves" src={closesmall} onClick={() => this.handleRemoveMain(index)} />
                            </Box>
                          )
                        })
                        }
                      </PdfMainBox>
                      <SubmitQueryButton data-testid="errormodel_submit" type="submit">
                        Submit
                      </SubmitQueryButton>
                      <Modal
                        open={this.state.complteopen}
                        onClose={this.complteclosed}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableBackdropClick
                      >
                        <SubmitComplate sx={styles.submitBox}>
                          <div style={styles.boxSubmit}>
                            <img onClick={this.complteclosed} style={styles.submitImg} src={closessucsessmodel} />
                          </div>
                          <ComplateOne>Thank you for reaching out!</ComplateOne>
                          <ComplateTwo>Your message has been received. Our team is working diligently to address your inquiry. We appreciate your patience and will get back to you shortly.</ComplateTwo>
                          <ComplateButtonDiv>
                            <Complatehelp data-testid="Cancel_submit" onClick={this.complteclosed}>Cancel</Complatehelp>
                          </ComplateButtonDiv>
                        </SubmitComplate>
                      </Modal>
                      <Modal
                        open={this.state.pdfselectopen}
                        onClose={this.pdfValidationclosed}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableBackdropClick
                      >
                        <SelectValidationComplate sx={styles.lastSelect}>
                          <PdfvalidationOne>Trouble uploading a file. Please choose a different file</PdfvalidationOne>
                          <PdfvalidationTwo>format</PdfvalidationTwo>
                          <ComplateValidationClose data-testid="Cancelpdf_submit" onClick={this.pdfValidationclosed}>Close</ComplateValidationClose>
                        </SelectValidationComplate>
                      </Modal>
                    </HelpDataSubmit>
                  )}
                </Formik>
              </Container>
            </MainContainerbox>
        </Box>
        {/* Customizable End Start */}
      </ThemeProvider >
      </CustomBG>
      //Merge Engine End DefaultContainer 
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const StyledTextField = styled(TextField)({
  "& textarea": {
    resize: "both"
  },

});

const MainContainerbox = styled(Box)({
  fontFamily: "Raleway",
  "& .MuiContainer-maxWidthMd-13": {
    maxWidth: 700
  },
  "& .HelpContainer" : {
    display: "flex",
    alignItems: "center",
    gap: "15px"
  },
  "& .helpTitle":{
    fontWeight: 700,
    fontSize: "20px",
    display: "flex",
    minHeight: "32px",
    alignItems: "center",
    '@media (max-width: 400px)': {
      fontSize: "18px"
    },
  },
});

const HelpDataSubmit = styled(Form)({
  margin: "28px 30px 10px 28px",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  fontFamily: "Raleway",
});

const SelectFile = styled('div')({
  border: '1px solid #CBD5E1',
  background: "#f4faffbd",
  borderRadius: "8px",
  minHeight: "164px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const SelectFileDiv = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  paddingTop: '8px'
});

const SelectFileValidation = styled('div')({
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 500,
  color: "#64748B",
  margin: "-20px 0px -35px 5px",
});

const SubmitQueryButton = styled(Button)({
  maxWidth: "119px",
  minHeight: "48px",
  borderRadius: "50px",
  background: "#13689E",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  '&:hover': {
    background: '#13689E',
  },

});

const ModelInnerBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: 'rgb(229, 229, 229)',
  border: 'none',
  padding: 0,
  borderRadius: "16px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '95%',
  },

});

const ModelErrorOne = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",
  },

});

const ModelErrorTwo = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",

  },

});

const ModelErrorBorder = styled('p')({
  borderBottom: "0.33px solid #3C3C435C",
  margin: "16px 0px 10px 0px",
  width:"100%"
});

const SubmitComplate = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 610,
  background: 'white',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  minHeight: "320px",
  display: "flex",
  flexDirection: "column",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },

});

const ComplateOne = styled(Typography)({
  color: "black",
  fontSize: "24px",
  fontFamily: "Raleway",
  fontWeight: 700,
  padding: "21px 40px 16px 40px",
  '@media (max-width: 450px)': {
    fontSize: "19px",
  },

});

const ComplateTwo = styled(Typography)({
  color: "black",
  fontSize: "18px",
  fontFamily: "Raleway",
  fontWeight: 400,
  padding: "0px 40px 30px 40px",
  borderBottom: "1px solid #E2E8F0",
  '@media (max-width: 450px)': {
    fontSize: "15px",
  },

});

const ComplateButtonDiv = styled('div')({
  display: "flex",
  justifyContent: "end",
  padding: "24px 24px 24px 0px"
});

const SelectValidationComplate = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: 'rgb(229,229,229)',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },

});

const PdfvalidationOne = styled('div')({
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 400,
  paddingTop:"16px",
  color:"#334155",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },

});

const PdfvalidationTwo = styled('div')({
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 400,
  color:"#334155",
  paddingTop:"8px",
  width:"100%",
  textAlign:"center",
  paddingBottom: "16px",
  borderBottom: "0.33px solid #b9b9bb",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },

});

const ComplateValidationClose = styled(Button)({
  minWidth: "140px",
  color: "#007AFF",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  marginTop: "5px",
  '&:hover': {
    background: 'none',
  },

});

const Complatehelp = styled(Button)({
  minWidth: "151px",
  minHeight: "56px",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  borderRadius: "50px",
  background: "#13689E",
  '&:hover': {
    background: '#13689E',
  },

});

const PdfMainBox = styled(Box)({
  marginTop:"5px",
  "@media (max-width: 900px)": {
    width: '680px!important',
  },
  "@media (max-width: 800px)": {
    width: '580px!important',
  },
  "@media (max-width: 700px)": {
    width: '480px!important',
  },
  "@media (max-width: 600px)": {
    width: '380px!important',
  },
  "@media (max-width: 500px)": {
    width: '250px!important',
  },
  "@media (max-width: 400px)": {
    width: '200px!important',
  },
});

// Customizable Area End
