import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  LockSettings, deleteSettings, infoSettings, LockSettingsActive, infoSettingsActive, gdprPolicyActive,gdprPolicy,privacyPolicyActive,privacyPolicy
} from "./assets";
import { Message } from "framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { RefObject, createRef } from "react";
import StorageProvider from "framework/src/StorageProvider";
import moment from "moment";
import { number } from "yup";

// Customizable Area Start

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "boolean"
  | "file"
  | "checkbox"
  | "date"
  | "text"
  | "datetime"
  | "number"
  | "float"
  | "integer"
  | "string"
  | "textarea";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  is_enable: boolean;
  title: string;
  is_required: boolean;
  field_type: FieldType;
}

interface TermsData { id: number; created_at: string; description: string; }

interface ImageOptions {
  icon: string;
  text: string;
  activeIcon: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedSideMenu: string;
  imageTextOption: ImageOptions[];
  javygatorInfoData: { titel: string, sub_title: string };
  showPassword: boolean,
  password: string,
  newPassword: boolean,
  newIsPassword: string,
  confirmPassword: boolean,
  confirmIsPassword: string,
  open: boolean,
  termsConds: TermsData[];
  privacyPolicy: TermsData[];
  gDPRPolicy:TermsData[];
  successReset: boolean;
  passwordError: string;
  token: string;
  oldPasswordError: string;
  isNewPasswordValid: string;
  passwordsMatch: string;
  onBoarding: any;
  onBoarding1: any;
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  onboardingRefs: RefObject<HTMLTableCellElement>[];
  onboardingRefs1:RefObject<HTMLTableCellElement>[];
  userType: string;
  socialMediaType:boolean;
  loginUserType: string | null
  ipAddress: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getPrivacyPolicyCallId: string = "";
  getGDPRPolicyCallId: string = "";
  deleteAccountApi: string = "";
  resetPasswordApi: string = "";
  getSocialmediaCallId: string = "";
  getActivityApiCallId: string = "";
  getIpApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];


    this.state = {
      // Customizable Area Start
      javygatorInfoData: { titel: "", sub_title: "" },
      imageTextOption: [],
      selectedSideMenu: "Change Password",
      showPassword: false,
      password: '',
      newPassword: false,
      newIsPassword: '',
      confirmPassword: false,
      confirmIsPassword: '',
      open: false,
      termsConds: [],
      privacyPolicy: [],
      gDPRPolicy:[],
      successReset: false,
      passwordError: "",
      token: '',
      oldPasswordError: '',
      isNewPasswordValid: '',
      passwordsMatch: '',
      onboardingRefs: Array.from({ length: 5 }, () =>
        createRef<HTMLTableCellElement>()
      ),
      onboardingRefs1: Array.from({ length: 4 }, () =>
        createRef<HTMLTableCellElement>()
      ),
      onBoarding: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Change Password: Enhance your account security by updating your password regularly to safeguard your personal information.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "Terms and Conditions: Review the guidelines and agreements governing your use of this platform for clarity on rights, responsibilities, and legal obligations.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Privacy Policy: Understand how your personal data is collected, used, and protected on this platform to ensure transparency and trust",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 5,
        "onboarding_title": "Step 5",
        "onboarding_description": "Delete Account: Permanently remove your account and associated data from the platform",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Delete Account: Permanently remove your account and associated data from the platform",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      onBoarding1: [
      {
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Terms and Conditions: Review the guidelines and agreements governing your use of this platform for clarity on rights, responsibilities, and legal obligations.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "Privacy Policy: Understand how your personal data is collected, used, and protected on this platform to ensure transparency and trust",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 3",
        "onboarding_description": "Delete Account: Permanently remove your account and associated data from the platform",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Delete Account: Permanently remove your account and associated data from the platform",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      userType: "",
      socialMediaType: false,
      loginUserType: localStorage.getItem("user_type"),
      ipAddress: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getIpAddress();
      this.getSocialmediaApicall();
      const fileUrl = window.location.pathname;
      const extractFileUrl = decodeURIComponent(fileUrl.substring(fileUrl.lastIndexOf("/") + 1));
       if(extractFileUrl)
      this.setState({ selectedSideMenu: extractFileUrl });
      const SidebarImageText = [
        { icon: LockSettings, text: "Change Password", activeIcon: LockSettingsActive },
        { icon: infoSettings, text: "Terms & Conditions", activeIcon: infoSettingsActive},
        { icon: privacyPolicy, text: "Privacy Policy", activeIcon: privacyPolicyActive },
        { icon: gdprPolicy, text: "GDPR Policy", activeIcon: gdprPolicyActive },
        { icon: deleteSettings, text: "Delete Account", activeIcon: deleteSettings },
      ];
  
      this.setState({
        imageTextOption: SidebarImageText
      })
      this.getTermsConds();
      this.getPrivacyPolicy();
      const meta = await getStorageData("meta")
      const metaToken = JSON.parse(meta)
      const authToken = await getStorageData("authToken")
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
      const token =  authToken || metaToken?.token
      this.setState({ token },() => this.getGDPRPolicy())
  
      const userType = await getStorageData('user_type');
      const loginUserType = JSON.parse(userType);
      if (userType) {
        this.setState({ userType: loginUserType });
          }
      if (!this.state.loginUserType) {
            this.redirectToLogin()
          }
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  // Customizable Area Start


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId === this.getIpApiCallId) {
          this.setState({ipAddress: responseJson.ip})
        }
        switch (apiRequestCallId) {
            case this.getTermsCondsCallId:
                this.setState({ termsConds: responseJson.data });
                break;
            case this.getPrivacyPolicyCallId:
                this.setState({ privacyPolicy: responseJson.data });
                break;
            case this.getGDPRPolicyCallId:
                this.setState({ gDPRPolicy: responseJson.data });
                break;
            case this.deleteAccountApi:
                if (responseJson?.message === "Account deleted successfully.") {
                    this.setState({ open: false });
                    this.props.navigation.navigate('EmailAccountLoginBlock');
                }
                break;
            case this.resetPasswordApi:
                this.handlePasswordResponse(responseJson);
                break;
            case this.getSocialmediaCallId:
              this.handleSocialMediaResponse(responseJson)
                
                break;
            default:
                break;
        }
    }
}

  // Customizable Area End


  // Customizable Area Start
 /* istanbul ignore next */
  handleSocialMediaResponse = (responseJson:any) => {
    const setMediatype = responseJson?.data.attributes.profile.data.attributes.is_social_account;
    const SidebarImageText = [
      { icon: infoSettings, text: "Terms & Conditions", activeIcon: infoSettingsActive},
      { icon: privacyPolicy, text: "Privacy Policy", activeIcon: privacyPolicyActive },
      { icon: gdprPolicy, text: "GDPR Policy", activeIcon: gdprPolicyActive },
      { icon: deleteSettings, text: "Delete Account", activeIcon: deleteSettings },
    ];
    const NormalSidebarImageText = [
      { icon: LockSettings, text: "Change Password", activeIcon: LockSettingsActive },
      { icon: infoSettings, text: "Terms & Conditions", activeIcon: infoSettingsActive},
      { icon: privacyPolicy, text: "Privacy Policy", activeIcon: privacyPolicyActive },
      { icon: gdprPolicy, text: "GDPR Policy", activeIcon: gdprPolicyActive },
      { icon: deleteSettings, text: "Delete Account", activeIcon: deleteSettings },
    ];
if (setMediatype) {
  this.setState({
    imageTextOption: SidebarImageText
  })
  this.setState({ selectedSideMenu: 'Terms & Conditions' },()=> window.history.pushState(null, '', `/Settings/${this.state.selectedSideMenu}`))
} else {
  this.setState({
    imageTextOption: NormalSidebarImageText
  })
  this.setState({ selectedSideMenu: 'Change Password' },()=> window.history.pushState(null, '', `/Settings/${this.state.selectedSideMenu}`))
}
    this.setState({ socialMediaType: setMediatype },async()=>{
      let onboarding = await getStorageData("onBoarding");
      if (onboarding === null) {
        this.setState({ openOnboarding: true });
        this.nextOnBoarding();
      }
    });
  }

 /* istanbul ignore next */
  nextOnBoarding = () => {
    const { onBoarding, currentStep, onBoarding1 } = this.state;
    const onBoard = this.state.socialMediaType ? onBoarding1 : onBoarding
    if (onBoard.length > currentStep) {
      if (this.state.socialMediaType && currentStep === 2) {
        this.setState({ currentStep: currentStep + 1 }, () => this.nextOnBoarding());
      }
      if (!this.state.socialMediaType && currentStep === 3) {
        this.setState({currentStep: currentStep + 1}, () => this.nextOnBoarding());
      }
      else {
        this.changeData();
      }
    }
    else {
      this.setState({
        openOnboarding: false
      });
      if (this.state.userType === "invited") {
        this.props.navigation.navigate('ContentManagement');
      }
      else {
        this.props.navigation.navigate('CustomForm');
      }

    }
  }
 /* istanbul ignore next */
  changeData = () => {
    const { onBoarding, currentStep, onBoarding1 } = this.state;
    if(this.state.socialMediaType){
      this.setState({
        onBoardingTitle: onBoarding1[currentStep].onboarding_title,
        onBoardingText: onBoarding1[currentStep].onboarding_description,
        transformHorizontal: onBoarding1[currentStep].transformHorizontal,
        anchorHorizontal: onBoarding1[currentStep].anchorHorizontal,
        currentStep: currentStep + 1
      })
    }else {
      this.setState({
        onBoardingTitle: onBoarding[currentStep].onboarding_title,
        onBoardingText: onBoarding[currentStep].onboarding_description,
        transformHorizontal: onBoarding[currentStep].transformHorizontal,
        anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
        currentStep: currentStep + 1
      })
    }

    const currentRefs = this.state.socialMediaType ? this.state.onboardingRefs1 : this.state.onboardingRefs
    
    const targetRef = currentRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }

  skipOnBoarding = () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    localStorage.setItem("onBoarding", "true")
  }

  nextInvitedUser = () => {
    const { onBoarding, currentStep } = this.state;
    if (onBoarding.length > currentStep) {
      this.setState({
        onBoardingTitle: onBoarding[currentStep].onboarding_title,
        onBoardingText: onBoarding[currentStep].onboarding_description,
        transformHorizontal: onBoarding[currentStep].transformHorizontal,
        anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
        currentStep: currentStep + 1
      })
    }
    if (this.state.userType === "invited") {
      this.props.navigation.navigate('ContentManagement');
    }
  }

  skipOnBoardingInvitedUser = () => {
    if (this.state.userType === "invited") {
      this.setState({
        openOnboarding: true,
        currentStep: 0
      })
      localStorage.setItem("onBoarding", "true")
    }
    else if((this.state.userType === "regular")) {
      this.setState({
        openOnboarding: false,
        currentStep: 0
      })
      localStorage.setItem("onBoarding", "true")
    }
    else{   
      this.setState({
        openOnboarding: false,
        currentStep: 0
      })
      localStorage.setItem("onBoarding", "false")
    }
  }


  handleRemoveError = () => {
    this.setState({
      passwordError: ""
    })
  }
  handlePasswordResponse = (response: { errors: [{ message: string }] }) => {
    if (response?.errors && response.errors[0].message) {
      this.callApiLogActivity("change password failed", false);   
      this.setState({ passwordError: response.errors[0].message })
    } else {
      this.setState({ successReset: true, password: "", newIsPassword: "", confirmIsPassword: "" })
      this.callApiLogActivity("change password success", true);   
    }
  }

  handleSuccessModelClose = () => {
    localStorage.clear()
    this.props.navigation.navigate("EmailAccountLoginBlock")
    this.setState({ successReset: false })
  }

  formSubmitButton = (values: { old_pass: string; new_pass: string; conf_pass: string }, { setSubmitting, setErrors }: { setSubmitting: (isSubmitting: boolean) => void, setErrors: (errors: { old_pass: string }) => void }) => {
    if (values.old_pass === values.new_pass) {
      setErrors({ old_pass: "Old password and new password cannot be the same" });
      setSubmitting(false);
    } else {
      this.handleResetApi(values);
    }
  }


  handleResetApi = async (value: { new_pass: string, conf_pass: string, old_pass: string }) => {
    const { token } = this.state

    const formData = new FormData()

    formData.append("old_password", value.old_pass);
    formData.append("new_password", value.new_pass);
    formData.append("new_confirmation_password", value.conf_pass);

    const header = {
      token,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApi = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);


  }

  getTermsConds = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getprivacyPolicyApiEndPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };


  getGDPRPolicy = () => {    
    const {token} = this.state
    const header = {
       token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGDPRPolicyCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGDPRPolicyApiEndPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  togglePasswordVisibility = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  resetNewPassword = () => {
    this.setState(prevState => ({ newPassword: !prevState.newPassword }));
  }

  resetConfirmPassword = () => {
    this.setState(prevState => ({ confirmPassword: !prevState.confirmPassword }));
  }

  handleOpen = () => {
    this.setState({ open: true });
  }



  handleCloseDeleteModal = () => {
    this.setState({ open: false });
  }

  handleClose = async () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountApi = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

  }

  slectFromSide = (value: string) => {
    if (value === "Delete Account") {
      this.handleOpen();
      this.setState(prevState => ({ selectedSideMenu: prevState.selectedSideMenu }));
    } else {
      this.setState({ selectedSideMenu: value },()=> window.history.pushState(null, '', `/Settings/${this.state.selectedSideMenu}`))
    }
  };

  getSocialmediaApicall = async () => {
    const token = await getStorageData("authToken");
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSocialmediaCallId = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userProfileEndPoint
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
};
getIpAddress = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getIpApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.ipAddressApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify({})
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

callApiLogActivity = async (type: string, isSuccess: boolean) => {
  let token = await StorageProvider.get("authToken")
  let viewProfile= `activity?action_name=change password&response=${isSuccess?'success':'fail'}&message=${isSuccess?'change password success':'change password failed'}&platform_type=web&ip_address=${this.state.ipAddress}&time=${moment().utc().format('HH:mm')}`
  const header = {
    "Content-Type": configJSON.apiType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getActivityApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    viewProfile  

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

redirectToLogin = () => {
  const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
  navMes.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
  navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(navMes);
}; 
  // Customizable Area End
}