import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAur6xFqp1T6z7qzKm_9L-EF72WvVzGPs8",
    authDomain: "cyclevitaapp.firebaseapp.com",
    projectId: "cyclevitaapp",
    storageBucket: "cyclevitaapp.appspot.com",
    messagingSenderId: "298465077090",
    appId: "1:298465077090:web:9bc107c997b31803faee3b",
    measurementId: "G-57Z72TFYWM"
};

export const firebaseAPI = initializeApp(firebaseConfig);

export const messaging = getMessaging();