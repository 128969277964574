import React from "react";

// Customizable Area Start
import Box from "@material-ui/core/Box";
import { Card, Container, Grid, Typography, styled } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CustomBG from "../../../components/src/CustomBG.web";

import Loader from "../../../components/src/Loader.web";
// Customizable Area End
import PrivacyPolicyController, { Props } from "./PrivacyPolicyController.web";

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
           {this.state.isLoading ? <Loader/> : <CustomBG>
                <PolicyContainer>
                    <Container maxWidth="md">
                        <Box className="main">
                            <Card elevation={0} className="privacy_policy_container">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {this.state.privacyPolicy?.data !== undefined && this.state.privacyPolicy?.data.map((item: any) => {
                                            return (
                                                <Box key={item}>
                                                    <Typography className="title">Introduction</Typography>
                                                    <Typography className="content" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </Box>
                                            )
                                        })}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="main-button-container" sx={{ mb: 5 }}>
                                            <Button className="btnContinue" variant="contained" data-test-id="privacyButton" onClick={() => this.props.navigation.goBack()}>
                                                Continue
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Container>
                </PolicyContainer>
            </CustomBG>}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PolicyContainer = styled(Box)({
    position: "relative",
    padding: "32px 0",
    overflow: "hidden",
    "@media (max-width: 1310px)": {
        "& .MuiContainer-maxWidthMd": {
            maxWidth: 700
        }
    },
    "& .main": {
        height: "100vh",
        overflow: "scroll",
        "&::-webkit-scrollbar-track": {
            display: "none"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            display: "none"
        },
        "&::-webkit-scrollbar-thumb": {
            display: "none"
        },
        "@media (max-width: 600px)": {
            height: "100vh",
        },
    },
    "& .main-button-container": {
        gap: 10,
        display: "flex",
        marginBottom:"20px !important",
        "@media (max-width: 600px)": {
            flexDirection: "column"
        }
    },
    "& .privacy_policy_container": {
        padding: 24,
        borderRadius: 12,
        background: "#F8FAFC",
        height: "fit-content",
        maxHeight: 'calc(100vh - 112px)',
        overflowY: "scroll",
        "@media (max-width: 400px)": {
            overflow: "scroll",
        },
        "&::-webkit-scrollbar-track": {
            display: "none"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            display: "none"
        },
        "&::-webkit-scrollbar-thumb": {
            display: "none"
        },
    },
    "& .title": {
        color: "#000",
        fontSize: 18,
        fontFamily: "Raleway",
        fontWeight: "500",
        fontStyle: "normal",
    },
    "& .content": {
        fontFamily: "Raleway",
        fontSize: 14,
        color: "#475569",
        fontWeight: "500",
        textTransform: "capitalize",
        fontStyle: "normal",
        "& p": {
            fontSize: 14
        },
        "& br": {
            display: "none",
        },
    },
    "& .btnContinue": {
        width: "168px",
        padding: "12px 16px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        justifyContent: "center",
        fontWeight: 700,
        borderRadius: 50,
        color: "white",
        textTransform: 'capitalize',
        backgroundColor: "#13689E",
        "@media (max-width: 600px)": {
            width: "100%",
        },
    },
})
// Customizable Area End