import React from "react";
// Customizable Area Start
import { withStyles, StyleRules, styled } from "@material-ui/core/styles";
import EventsScheduleController, { Props } from "./EventsScheduleController.web";
import ToggleButton from '@material-ui/lab/ToggleButton';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Box, Button, Dialog, Divider, Grid, InputBase, List, ListItem, MenuItem, Radio, RadioProps, Select, Typography } from "@material-ui/core";
import SideBar from "../../../components/src/SideBar"
import { closeIcon, eventTickCircle, notificationIconEvent, calender, emptyEventImg, eventImg, locationColorIcon, locationIcon, notesIcon, repeatIcon, schedulewatchColorIcon, schedulewatchIcon, closeIconDelete } from "../src/assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Loader from "../../../components/src/Loader.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import WebHeader from "../../../components/src/WebHeader.web";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    eventMainContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
        backgroundColor: "#F7F7F7",
        padding: "30px",
        minHeight: "100vh",
        height: "auto",
        width: "100%",
        boxSizing: "border-box"
    },
    choosedataText: {
        display: "flex",
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "25px",
        marginTop: "8px"
    },
    shceduleEventText: {
        display: "flex",
        fontSize: "18px",
        fontWeight: 700,
        marginBottom: "25px",
        width: "100%",
        color: "#1A1D1F"
    },
    shceduleEventText1: {
        display: "flex",
        fontSize: "18px",
        fontWeight: 700,
        marginBottom: "25px",
        width: "100%",
        color: "#1A1D1F",
    },
    calenderBtn: {
        backgroundColor: "#E2E8F0",
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 4px",
        height: "40px",
        width: "168px",
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: "#FFFFFF",
            borderRadius: "50px",
            height: "32px",
            color: "#0F172A",
        },
        '& .MuiButtonBase-root:hover': {
            borderRadius: "50px",
            height: "32px"
        },
        '& .MuiButtonBase-root': {
            fontWeight: 500,
            fontSize: "16px",
            color: "#334155",
            width: "80px",
            textTransform: 'none',
        }
    },
    toggleBtn: {
        border: "0px",
        padding: "2px 20px",
    },
    emptyEventDiv: {
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        border: "0.92px solid #F1F5F9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "324px",
        boxSizing: "border-box",
        padding: "24px 24px 153px 24px",
        gap: "8px",
    },
    eventListDiv: {
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        border: "0.92px solid #F1F5F9",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "360px",
        padding: "24px",
        gap: "8px",
        maxHeight: "420px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: '8px',
        },
        "&::-webkit-scrollbar-track": {
            background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#94A3B8',
            borderRadius: '9px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#90A4AE',
        },
    },
    "& .eventListDiv": {
        "&::-webkit-scrollbar": {
            width: '8px',
        },
        "&::-webkit-scrollbar-track": {
            background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#94A3B8',
            borderRadius: '9px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#90A4AE',
        },
    },
    evetListHading: {
        fontWeight: 600,
        fontSize: "16px",
        color: "#000000",
        fontFamily: "Raleway",
    },
    scheduleListEvent: {
        width: "14px",
        marginRight: "8px"
    },
    scheduleListText: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#475569",
        display: "flex",
        alignItems: "center",
        marginBottom: "8px",
    },
    descriptionHadding: {
        fontWeight: 500,
        fontSize: "14px",
        color: "#475569",
        fontFamily: "Raleway"
    },
    descriptionDetails: {
        color: "#475569",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Raleway"
    },
    eventListMain: {
        border: "1px solid #F1F5F9",
        width: "274px",
        boxSizing: "border-box",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        cursor: "pointer",
        padding: "16px"
    },
    allMonthCalenderEvent: {
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        border: "0.92px solid #F1F5F9",
        maxWidth: "684px",
        padding: "16px 6px",
    },
    calenderEventDiv: {
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        border: "0.92px solid #F1F5F9",
        maxWidth: "701px",
        padding: "29.49px 25px 29.49px 25px",
    },
    calanderMainDiv: {
        '& tr td ': {
            width: "88px",
            height: "39px",
            color: "#1A191F",
            textAlign: "center",
            fontWeight: 400,
            fontSize: "15px",
            cursor: "context-menu",
            borderRadius: "40px",
        },
        '& tr td span': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "75px",
            width: "100%",
            height: "39px",
            color: "#1A191F",
            textAlign: "center",
            fontWeight: 400,
            fontSize: "15px",
            cursor: "context-menu",
            borderRadius: "40px",
        },
        '& tr th ': {
            fontWeight: 700,
            fontSize: "15px",
            color: "#19181A"
        }
    },
    addEventBtn: {
        width: "180px",
        height: "48px",
        fontSize: "16px",
        fontWeight: 700,
        backgroundColor: "#13689E",
        color: "#FFFFFF",
        padding: "12px 16px 12px 16px",
        gap: "8px",
        borderRadius: "50px",
        marginTop: "20px",
        marginBottom: "7px",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#13689E",
        },
    },
    addEventBtnMain: {
        '& .MuiButton-root.Mui-disabled': {
            color: "#FFFFFF",
            backgroundColor: "#A5C6DE",
        }
    },
    emptyEventText: {
        fontFamily: "Raleway",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        textAlign: "center",
        maxWidth: "298px"
    },
    selectDateMainDiv: {
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        marginBottom: "22px",
        '& .MuiSelect-select:focus': {
            backgroundColor: "#F1F5F9",
            color: "#13689E"
        }
    },
    selectDateDropdwon: {
        fontWeight: 700,
        fontSize: "14px",
        backgroundColor: "#F1F5F9",
        color: "#13689E",
        paddingLeft: "18px",
        paddingTop: "3px",
        paddingBottom: "3px",
        borderRadius: "7px",
        '& .MuiSelect-icon': {
            color: "#13689E",
            marginRight: "7px",
            fontWeight: 400,
        },
        '& .MuiSelect-select.MuiSelect-select': {
            marginRight: "10px",
        },
        "&::-webkit-scrollbar": {
            width: '8px',
        },
        "&::-webkit-scrollbar-track": {
            background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#94A3B8',
            borderRadius: '9px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#90A4AE',
        },
    },
    monthContainer: {
        width: "217px",
        '&   tr td': {
            width: "26px",
            height: "26px",
            color: "#0F172A",
            fontSize: "9px",
            fontWeight: 400,
            textAlign: "center",
            cursor: "context-menu",
            borderRadius: "40px"
        },
        '&   tr td span': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "26px",
            height: "26px",
            color: "#0F172A",
            fontSize: "9px",
            fontWeight: 400,
            textAlign: "center",
            cursor: "context-menu",
            borderRadius: "40px"
        },
        '& tr th': {
            color: "#64748B",
            fontSize: "9px",
            fontWeight: 400,
        }
    },
    monthHeading: {
        fontSize: "12px",
        fontWeight: 600,
        color: "#0F172A",
        width: "90%",
        display: "flex",
        justifyContent: "center"
    },
    yearHeading: {
        fontSize: "18px",
        fontWeight: 700,
        color: "#0F172A",
        width: "100%",
        display: "flex",
        padding: "0px 10px",
        justifyContent: "space-between"
    },
    calanderTable: {
        maxWidth: "752px",
        width: "100%"
    },
    allMonthMain: {
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        width: "100%",
        justifyContent: "center"
    },
    calanderIcon: {
        fontSize: "16px",
        cursor: "pointer"
    },
    mainDilogboxScroll: {
        '& .MuiDialog-paperWidthSm': {
            overflowX: 'hidden',
        }
    },
    addEventDilogMain: {
        height: "auto",
        maxWidth: "390px",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
    },
    addTimeDilogMain: {
        height: "auto",
        width: "264px",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
    },
    dialogHading: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        marginBottom: "20px",
    },
    dialogHadingText: {
        fontSize: "20px",
        fontWeight: 600,
        fontFamily: "Raleway",
        color: "#0F172A"
    },
    dialogHadingEventNameText: {
        '& .MuiInputBase-input': {
            fontSize: "20px",
            fontWeight: 600,
            color: "#0F172A",
            fontFamily: "Raleway"
        },
        '& .MuiInputBase-input::placeholder': {
            color: "#64748B",
            opacity: 1,
        }
    },
    dialogHadingCloseBtn: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        backgroundColor: "#F1F5F9",
        border: "1px solid #E2E8F0",
        color: "#1C1B1F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        cursor: "pointer",
    },
    dialogHadingCloseRetryDeeletBtn: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        color: "#1C1B1F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        cursor: "pointer",
    },
    dialogSubDiv: {
        paddingLeft: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Raleway",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500,
    },
    dialogSubInputDiv: {
        padding: "0px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        fontFamily: "Raleway",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500,
        '& .MuiInputBase-input': {
            color: "#0F172A"
        }
    },
    dialogSubBtnDiv: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontFamily: "Raleway",
        flexDirection: "row"
    },
    addToCalendarBtn1: {
        height: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#13689E",
        textTransform: 'none',
        marginRight: "10px"
    },
    addToCalendarBtn2: {
        height: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#13689E",
        textTransform: 'none',
        marginRight: "10px",
    },
    addtocalenderbtnMain: {
        '& .MuiButton-root.Mui-disabled': {
            color: "#13689E",
        }
    },
    savebtnMainDIv: {
        '& .MuiButton-root.Mui-disabled': {
            color: "#FFFFFF",
            backgroundColor: "#A5C6DE",
        }
    },
    mainDialogSubBtnDiv: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontFamily: "Raleway",
        flexDirection: "row",
    },
    //switch
    toggleWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",

    },
    mobileToggle: {
        opacity: 0,
        position: "absolute",
        cursor: "pointer",
        '& + label': {
            position: "relative",
            display: "inline-block",
            userSelect: "none",
            transition: "0.4s ease",
            height: "30px",
            width: "55px",
            borderRadius: "60px",
            marginLeft: "2px",
            background: "#e4e4e4",
            cursor: "pointer",
            '&:before': {
                content: "''",
                position: "absolute",
                display: "block",
                transition: "0.2s cubic-bezier(.24, 0, .5, 1)",
                height: "30px",
                width: "54px",
                top: 0,
                left: 0,
                borderRadius: "30px",
                marginRight: "2px",
            },
            '&:after': {
                content: "''",
                position: "absolute",
                display: "block",
                transition: "0.35s cubic-bezier(.54, 1.60, .5, 1)",
                background: "whitesmoke",
                height: "28px",
                width: "28px",
                top: "1px",
                left: "2px",
                borderRadius: "60px",

            },
        },
        // When Active
        '&:checked + label:before': {
            background: "#13689E",
            transition: "width 0.2s cubic-bezier(0, 0, 0, .1)",
        },
        '&:checked + label:after': {
            left: "24px",
        },
    },
    dialoLiteText: {
        color: "#475569",
        marginLeft: "auto",
        textAlign: "right",
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        textTransform: 'none',
    },
    iconImg: {
        marginRight: "12px"
    },
    removeReminderIcon: {
        width: "9px",
        height: "9px",
        marginLeft: "10px",
        marginTop: "3px",
    },
    addToCalendarBtn: {
        height: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#13689E",
        textTransform: 'none',
        marginRight: "20px"
    },
    cancelDeletBtn: {
        width: "140px",
        height: "56px",
        borderRadius: "50px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#13689E",
        textTransform: 'none',
        marginRight: "20px"
    },
    saveCalendarBtn: {
        width: "93px",
        height: "36px",
        backgroundColor: "#13689E",
        color: "#ffffff",
        borderRadius: "50px",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#13689E",
        },
    },
    dialogRepetEvent: {
        fontFamily: "Raleway",
        fontSize: "14px",
        fontWeight: 500,
    },
    addTimeFilde: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiOutlinedInput-input': {
            width: "54px",
            height: "60px",
            fontSize: "45px",
            padding: " 10px 10px",
            color: "#1E1A1D",
            backgroundColor: "#13689E33"
        }
    },
    timeType: {
        fontSize: "12px",
        color: "#4D444C",
        fontWeight: 400,
        fontFamily: "Raleway",

    },
    reminderEventList: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    noReminderEventList: {
        color: "#94A3B8",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    reminderDialogSubDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Raleway",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500,
    },
    dialogBoxBorderInline: {
        borderBottom: "1px solid #F1F5F9",
        maxWidth: "330px",
        marginLeft: "50px"
    },
    dilogBoxWidth: {
        width: "330px",
        marginLeft: "50px",
    },
    iconHaddingDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    selectTime: {
        color: "#475569",
        fontSize: "14px",
        fontWeight: 500,
        "&::-webkit-scrollbar": {
            width: '8px',
        },
        "&::-webkit-scrollbar-track": {
            background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#94A3B8',
            borderRadius: '9px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#90A4AE',
        },
    },
    selectTimeError: {
        fontSize: "14px",
        fontWeight: 500,
        color: "red",
        '& .MuiSelect-icon': {
            color: "red"
        },
        "&::-webkit-scrollbar": {
            width: '8px',
        },
        "&::-webkit-scrollbar-track": {
            background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#94A3B8',
            borderRadius: '9px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#90A4AE',
        },
    },
    timeCalculation: {
        color: "#475569",
        fontSize: "14px",
        fontWeight: 500,

    },
    timeLastCalculatio: {
        color: "#475569",
        fontSize: "14px",
        fontWeight: 500,
        padding: "5px 0px"
    },
    saveEventMsg: {
        borderBottom: "1px solid rgba(60, 60, 67, 0.2)",
        fontSize: "22px",
        fontWeight: 600,
        color: "#334155",
        textAlign: "center",
        paddingBottom: "10px",
        maxWidth: "370px"
    },

    addEventDilogMainSave: {
        height: "auto",
        padding: "16px 32px",
        display: "flex",
        flexDirection: "column",
    },
    dialogSubBtnDivSave: {
        display: "flex",
        justifyContent: "center",
        fontFamily: "Raleway",
        flexDirection: "row",
        marginTop: "17px"
    },
    deleteEventBtn: {
        width: "93px",
        height: "36px",
        backgroundColor: "#DC2626",
        color: "#ffffff",
        borderRadius: "50px",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#DC2626",
        },
    },
    overlapMsg: {
        maxWidth: "390px",
        borderBottom: "1px solid rgba(60, 60, 67, 0.33)",
        fontSize: "22px",
        fontWeight: 600,
        color: "#334155",
        textAlign: "center"
    },
    overlapsText: {
        fontWeight: 600,
        fontSize: "24px",
        color: "#0F172A",
        fontFamily: "Raleway",
        maxWidth: "520px",
        width: "100%",
        letterSpacing: -0.5
    },
    deleteCalendarBtn: {
        width: "151px",
        height: "56px",
        backgroundColor: "#13689E",
        color: "#ffffff",
        borderRadius: "50px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#13689E",
        },
    },
    overlapTextMsg: {
        textAlign: "left",
        maxWidth: "520px",
        padding: "0px 32px"
    },
    borderBotttomDialog: {
        width: "100%",
        borderBottom: "1px solid #E2E8F0",
        marginTop: "32px"
    },
    yearChangeArrow: {
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        backgroundColor: "#F1F5F9",
        color: "#1C1B1F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    menuPaperTime: {
        marginTop: "118px",
        marginLeft: "-106px",
        height: "210px",
        width: "163px",
    },
    "@media (max-width: 600px)": {
        menuPaperTime: {
            marginTop: "200px",
            marginLeft: "-60px",
        },
    },
    "@media (max-width: 420px)": {
        menuPaperTime: {
            marginTop: "190px",
            marginLeft: "-40px",
        },
        eventListMain: {
            maxWidth: "252px",
            width: "100%"
        },
        eventMainContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            padding: "5px"
        },
        calenderEventDiv: {
            padding: "15px 5px 15px 5px",
        },
        allMonthCalenderEvent: {
            width: "auto"
        },
        emptyEventDiv: {
            maxWidth: "247px"
        }
    },
    "@media (min-width: 420px) and (max-width: 460px)": {
        eventMainContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            padding: "10px"
        },
    }
    // Customizable Area End
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});




const BpRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },

}));

const BpRadioCheckedIcon = styled(BpRadioIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },

});



export class EventsSchedule extends EventsScheduleController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    BpCheckbox(props: CheckboxProps) {
        return (
            <Checkbox
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                {...props}
            />
        );
    }


    BpRadio(props: RadioProps) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpRadioCheckedIcon />}
                icon={<BpRadioIcon />}
                {...props}
            />
        );
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { selectedYear, selectedMonth } = this.state;
        const months = [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' }
        ];

        const timeArray = [];
        for (let hour = 0; hour <= 23; hour++) {
            for (let minute = 0; minute < 60; minute += 5) {
                let formattedHour = hour.toString();
                let formattedMinute = minute.toString();
                if (hour < 10) {
                    formattedHour = `0${hour}`;
                }
                if (minute === 0) {
                    formattedMinute = '00';
                } else if (minute < 10) {
                    formattedMinute = `0${minute}`;
                }
                const timeLabel = `${formattedHour}:${formattedMinute}`;
                const timeValue = `${formattedHour}${formattedMinute}`;
                timeArray.push({ label: timeLabel, value: timeValue });
            }
        }

        const isEventListEmpty = this.state.selectPastDateFlag
            ? this.state.pastDateEventList?.length === 0
            : this.filteredEvents()?.length === 0;

        return (
            <>
                {this.state.isLoading && <Loader />}
                <Box style={{ display: "flex", flexDirection: "row-reverse", fontFamily: "Raleway" }}>
                    <WebOnboarding
                        title={this.state.onBoardingTitle}
                        discripation={this.state.onBoardingText}
                        anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
                        next={this.nextOnBoarding}
                        open={this.state.openOnboarding}
                        skip={this.skipOnBoarding}
                        anchorhorizontal={this.state.anchorHorizontal}
                        transformhorizontal={this.state.transformHorizontal}
                    />
                    <Grid style={{ width: "100%", backgroundColor: "#FAFAFA", height: "auto", minHeight: "100vh", boxSizing: "border-box" }}>
                        <WebHeader title={"Schedule Events"} icon={eventImg} />

                        <Grid className={classes.eventMainContainer} >
                            <Grid item   >
                                <Box style={{ display: "flex", justifyContent: "space-between", maxWidth: "752px", width: "100%" }}>
                                    <Typography className={classes.choosedataText}>Choose a Date</Typography>
                                    <ToggleButtonGroup data-test-id="month-year-toggleBtn" size="small" className={classes.calenderBtn} value={this.state.calender} exclusive onChange={this.handleChange}>
                                        <ToggleButton value={true} className={classes.toggleBtn} >
                                            Month
                                        </ToggleButton>
                                        <ToggleButton value={false} className={classes.toggleBtn}>
                                            Year
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>

                                <Box style={{ maxWidth: "715px", width: "100%" }}>
                                    {this.state.calender ?
                                        <Box className={classes.calenderEventDiv}>
                                            <Box className={classes.selectDateMainDiv}>
                                                <Select data-test-id="select-month-dropdawon"
                                                    disableUnderline
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    className={classes.selectDateDropdwon}
                                                    value={selectedMonth}
                                                    onChange={this.handleMonthChange}>
                                                    {months.map(month => (
                                                        <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
                                                    ))}
                                                </Select>
                                                <Select data-test-id="select-yera-dropdawon"
                                                    disableUnderline
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    className={classes.selectDateDropdwon}
                                                    value={selectedYear}
                                                    onChange={this.handleYearChange}>
                                                    {[...Array(100)].map((_, i) => (
                                                        <MenuItem key={2000 + i} value={2000 + i}>{2000 + i}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                            <div className={classes.calanderMainDiv}>
                                                <table className={classes.calanderTable}>
                                                    <thead>
                                                        <tr>
                                                            <th>Mon</th>
                                                            <th>Tue</th>
                                                            <th>Wed</th>
                                                            <th>Thu</th>
                                                            <th>Fri</th>
                                                            <th>Sat</th>
                                                            <th>Sun</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderCalendarGrid(selectedYear, selectedMonth)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Box>
                                        :
                                        <Box className={classes.allMonthCalenderEvent}>
                                            <Box className={classes.allMonthMain}>
                                                <Typography className={classes.yearHeading}><span data-test-id="prev-year-btn" className={classes.yearChangeArrow} onClick={this.prevYear} ><ArrowBackIosIcon className={classes.calanderIcon} style={{ marginLeft: "6px" }} /></span >  {selectedYear} <span className={classes.yearChangeArrow} data-test-id="next-year-btn" onClick={this.nextYear}><ArrowForwardIosIcon className={classes.calanderIcon} /></span> </Typography>
                                                {months.map((month, index) => (
                                                    <Box key={month.value} className={classes.monthContainer}>
                                                        <Typography className={classes.monthHeading}>{month.label} </Typography>
                                                        <table >
                                                            <thead>
                                                                <tr>
                                                                    <th>Mon</th>
                                                                    <th>Tue</th>
                                                                    <th>Wed</th>
                                                                    <th>Thu</th>
                                                                    <th>Fri</th>
                                                                    <th>Sat</th>
                                                                    <th>Sun</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.renderCalendarGrid(selectedYear, month.value)}
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                ))
                                                }
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                                <Box className={classes.addEventBtnMain}>
                                    <Button disabled={this.state.eventDate === ""} className={classes.addEventBtn} onClick={this.handleClickAddEventOpen}>
                                        <span ref={this.state.onboardingRefs[1]}>
                                            Add Event
                                        </span>
                                    </Button>
                                </Box>
                            </Grid>

                            {isEventListEmpty ? <Grid item style={{ alignItems: "center", flexDirection: "column" }}>
                                <Box className={classes.emptyEventDiv}>
                                    <Typography className={classes.shceduleEventText1}>Schedule Events</Typography>
                                    <img src={emptyEventImg} alt="emptyEventImg" />
                                    <Typography className={classes.emptyEventText}>Looks like you haven't scheduled any events yet. Tap <span style={{ fontWeight: 700 }}>'Add Event'</span> to plan your day ahead!</Typography>
                                </Box>
                            </Grid>
                                :
                                <Grid item style={{ alignItems: "center", flexDirection: "column" }}>
                                    <Box className={classes.eventListDiv}>
                                        <Typography className={classes.shceduleEventText}>Schedule Events</Typography>
                                        {(this.state.selectPastDateFlag ? this.state.pastDateEventList : this.filteredEvents())?.map((appointment: any, index: any) => (
                                            <Box className={classes.eventListMain} style={{ cursor: this.formatDateToISOString(appointment.attributes.start_time) ? "pointer" : "context-menu", }} key={index} onClick={() => this.showEvent(appointment.id, appointment.attributes.start_time)}>
                                                <Typography className={classes.evetListHading}>
                                                    {appointment.attributes.title ? appointment.attributes.title : "(No title)"}
                                                </Typography>
                                                <Box>
                                                    <Typography className={classes.scheduleListText}>
                                                        <span>
                                                            <img src={schedulewatchColorIcon} className={classes.scheduleListEvent} alt="schedule" />
                                                        </span>
                                                        {this.formatDateString(appointment.attributes.start_time, appointment.attributes.end_time)}
                                                    </Typography>
                                                    {appointment.attributes.address && <Typography className={classes.scheduleListText}>
                                                        <span>
                                                            <img src={locationColorIcon} className={classes.scheduleListEvent} alt="schedule" />
                                                        </span>
                                                        {appointment.attributes.address}
                                                    </Typography>}
                                                </Box>
                                                {appointment.attributes.notes && <Box style={{ borderBottom: "1px solid #E2E8F0" }}></Box>}
                                                {appointment.attributes.notes && <Box>
                                                    <Typography className={classes.descriptionHadding}>
                                                        Description
                                                    </Typography>
                                                    <Typography className={classes.descriptionDetails}>
                                                        {appointment.attributes.notes}
                                                    </Typography>
                                                </Box>}
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>}
                        </Grid>
                    </Grid>
                    <SideBar navigation={this.props.navigation} />
                </Box >
                <Dialog open={this.state.openAddEvent} onClose={this.handleCloseAddEvent} style={{ height: "100vh" }
                } PaperProps={{ style: { borderRadius: '20px' } }} className={classes.mainDilogboxScroll}>
                    {this.state.isLoading && this.state.openAddEvent ? <Loader /> : null}
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingEventNameText}>
                                <InputBase
                                    className={classes.inputBaseFiled}
                                    inputProps={{ 'aria-label': 'naked' }}
                                    value={this.state.eventName}
                                    placeholder="Event Name"
                                    onChange={this.handleEventNameChange}
                                />
                            </h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.handleCloseAddEvent}>
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <List>
                            <Box className={classes.dialogSubDiv}>
                                <Box className={classes.iconHaddingDiv} >
                                    <img className={classes.iconImg} src={schedulewatchIcon} alt="schedulewatchIcon" />
                                    <span>All day</span>
                                </Box>
                                <Box  >
                                    <div className={classes.toggleWrapper} >
                                        <input type="checkbox" name="toggle1" className={classes.mobileToggle} id="toggle1" checked={this.state.checkedAllDay} onChange={this.handleAllDayChange} />
                                        <label htmlFor="toggle1"></label>
                                    </div>
                                </Box>
                            </Box>
                            <List className={classes.dialogSubDiv} style={{ marginLeft: "34px" }}>
                                <Box  >
                                    <p>{this.state.eventDate}</p>
                                    {!this.state.checkedAllDay && <p>{this.state.eventDate}</p>}
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                    {!this.state.checkedAllDay &&
                                        <Select
                                            data-test-id="select-month-dropdown"
                                            disableUnderline
                                            IconComponent={KeyboardArrowDownIcon}
                                            className={this.state.startTimeError ? classes.selectTimeError : classes.selectTime}
                                            value={this.state.selectedTime}
                                            onChange={this.handleStartTimeChange}
                                            MenuProps={{ PaperProps: { className: classes.menuPaperTime } }}
                                        >
                                            {timeArray.map((time) => (
                                                <MenuItem key={time.value} value={time.value} className={classes.timeCalculation}>
                                                    {time.label}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    }
                                    {!this.state.checkedAllDay &&
                                        <Select
                                            data-test-id="select-month-dropdown"
                                            disableUnderline
                                            IconComponent={KeyboardArrowDownIcon}
                                            className={this.state.endTimeError ? classes.selectTimeError : classes.selectTime}
                                            value={this.state.selectedEndTime}
                                            onChange={this.handleEndTimeChange}
                                            MenuProps={{ PaperProps: { className: classes.menuPaperTime } }}
                                        >
                                            {timeArray.map((time) => (
                                                <MenuItem key={time.value} value={time.value} className={classes.timeCalculation}>
                                                    {time.label}
                                                </MenuItem>
                                            ))}
                                        </Select>}
                                </Box>
                            </List>
                        </List>
                        <div className={classes.dilogBoxWidth}></div>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List className={classes.dialogSubDiv}>
                            <Box className={classes.iconHaddingDiv} >
                                <img className={classes.iconImg} src={repeatIcon} alt="repeatIcon" />
                                <Box >
                                    <Box >
                                        <p>Repeat</p>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Button onClick={this.handleClickRepetEventOpen} className={classes.dialoLiteText}>{this.state.saveRepetEvent === "" ? 'Does not repeat' : this.state.saveRepetEvent}</Button>
                            </Box>
                        </List>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List className={classes.dialogSubDiv} style={{ alignItems: "start" }}>
                            <Box className={classes.iconHaddingDiv} >
                                <img className={classes.iconImg} src={notificationIconEvent} alt="notificationIcon" />
                                <Box >
                                    <Box>
                                        <p>Reminders</p>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box style={{ width: "100%", marginLeft: "auto", }}>
                                    {this.state.saveReminderEvent.length !== 0 && <Box style={{ paddingTop: "10px" }}>
                                        {this.state.saveReminderEvent.map((option: any, index: any) => (
                                            <Typography key={index} className={classes.dialoLiteText} style={{ marginBottom: "8px" }} >
                                                <span>{option.reminder_name} </span>
                                                <img src={closeIcon} alt="closeIcon" onClick={() => this.handleRemoveReminder(option)} className={classes.removeReminderIcon} />
                                            </Typography>
                                        ))}
                                    </Box>}
                                    <Button className={classes.dialoLiteText} onClick={this.handleClickReminderEventOpen} style={{ marginTop: "4px" }}>Add Reminder</Button>
                                </Box>
                            </Box>
                        </List>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List className={classes.dialogSubInputDiv}>
                            <ListItem disableGutters >
                                <Box style={{ display: "flex" }}>
                                    <div>
                                        <img className={classes.iconImg} style={{ marginTop: "8px" }} src={locationIcon} alt="locationIcon" />
                                    </div>
                                    <span >
                                        <InputBase
                                            className={classes.inputBaseFiled}
                                            inputProps={{ 'aria-label': 'naked' }}
                                            value={this.state.location}
                                            placeholder="Location"
                                            onChange={this.handleLocationChange}
                                        />
                                    </span>
                                </Box>
                            </ListItem>
                        </List>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List className={classes.dialogSubInputDiv}>
                            <ListItem disableGutters >
                                <Box style={{ display: "flex" }}>
                                    <div>
                                        <img className={classes.iconImg} style={{ marginTop: "8px" }} src={notesIcon} alt="notesIcon" />
                                    </div>
                                    <span >
                                        <InputBase
                                            className={classes.inputBaseFiled}
                                            value={this.state.notes}
                                            placeholder="Notes"
                                            onChange={this.handleNotesChange}
                                            inputProps={{ 'aria-label': 'naked' }}
                                        />
                                    </span>
                                </Box>
                            </ListItem>
                        </List>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List className={classes.mainDialogSubBtnDiv}>
                            <span className={classes.addtocalenderbtnMain}>
                                <Button className={this.state.disableSave ? classes.addToCalendarBtn2 : classes.addToCalendarBtn1} disabled={this.state.disableSave || this.state.startTimeError || this.state.endTimeError} onClick={this.addTolocalCalendar}>
                                    Add To Calendar
                                </Button>
                            </span>
                            <span className={classes.savebtnMainDIv}>
                                <Button className={classes.saveCalendarBtn} disabled={this.state.disableSave || this.state.startTimeError || this.state.endTimeError} onClick={this.saveAppointment}>
                                    Save
                                </Button>
                            </span>
                        </List>
                    </List>
                </Dialog>


                < Dialog open={this.state.openRepetEvent} className={classes.mainDilogboxScroll} onClose={this.handleCloseRepetEvent} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingText}>Repeat</h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.handleCloseRepetEvent} >
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <div className={classes.dilogBoxWidth}></div>
                        <Box>
                            <List className={classes.dialogRepetEvent}>
                                {this.state.repetOptionArray.map((option: any, index: any) => (
                                    <Box key={index} >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <this.BpRadio id={`option${index}`} name="repeatOption"
                                                onChange={() => this.handleOptionChangeRepet(option, index)}
                                                checked={option === this.state.repetOpatonSelect} />
                                            <label htmlFor={`option${index}`} >{option}</label>
                                        </div>
                                    </Box>
                                ))}
                            </List>
                        </Box>
                        <List className={classes.dialogSubBtnDiv}>
                            <Button onClick={this.handleCloseRepetEvent} className={classes.addToCalendarBtn}>
                                Close
                            </Button>
                            <Button onClick={this.handleSaveRepetEvent} className={classes.saveCalendarBtn}>
                                Save
                            </Button>
                        </List>
                    </List>
                </Dialog >


                {/* Reminders dialogbox */}
                < Dialog open={this.state.openReminderEvent} className={classes.mainDilogboxScroll} onClose={this.handleCloseReminderEvent} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingText}>Reminders</h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.handleCloseReminderEvent} >
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <div className={classes.dilogBoxWidth}></div>
                        <List className={classes.reminderDialogSubDiv}>
                            <ListItem disableGutters >
                                <span >No reminders</span>
                            </ListItem>
                            <ListItem disableGutters >
                                <div className={classes.toggleWrapper} >
                                    <input type="checkbox" name="toggle1"
                                        className={classes.mobileToggle}
                                        id="toggle1"
                                        checked={this.state.noReminder}
                                        onChange={this.noRemiderHandalChange} />
                                    <label htmlFor="toggle1"></label>
                                </div>
                            </ListItem>
                        </List>
                        <div className={classes.dilogBoxWidth}></div>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <List>
                            <List className={classes.dialogRepetEvent}>
                                <Box>
                                    <div className={this.state.noReminder ? classes.noReminderEventList : classes.reminderEventList}>
                                        <label style={{ marginBottom: "10px" }}>Before the event</label>
                                    </div>
                                </Box>
                                {this.state.getEventReminderListData?.map((option: any, index: any) => (
                                    <Box key={index} >
                                        <div className={this.state.noReminder ? classes.noReminderEventList : classes.reminderEventList}>
                                            <label htmlFor={`option${index}`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{option.reminder_name}</label>
                                            <this.BpCheckbox
                                                id={`option${index}`}
                                                checked={this.state.reminderOpationSelect.some((reminder: any) => reminder.id === option.id)}
                                                onChange={() => this.handleOptionChangeReminder(option)}
                                                disabled={this.state.noReminder}
                                            />
                                        </div>
                                    </Box>
                                ))}
                            </List>
                        </List>
                        <List className={classes.dialogSubBtnDiv}>
                            <Button onClick={this.handleCloseReminderEvent} className={classes.addToCalendarBtn}>
                                Close
                            </Button>
                            <Button onClick={this.handleSaveReminderEvent} className={classes.saveCalendarBtn}>
                                Save
                            </Button>
                        </List>
                    </List>
                </Dialog >


                {/* eventsavesuccuses dialogbox */}
                < Dialog open={this.state.saveEventMsg} className={classes.mainDilogboxScroll} onClose={this.handleCloseSaveEventMsg} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    <Box className={classes.addEventDilogMainSave}>
                        <div className={classes.saveEventMsg}>
                            <span className={classes.dialogHadingText} style={{ color: "#334155" }}>{this.state.addTocalenderToggle ? "The event has been added to your calendar." : "Your event has been successfully saved. You can view or edit it in the calendar."}</span>
                        </div>
                        <div className={classes.dilogBoxWidth}></div>
                        <Box className={classes.dialogSubBtnDivSave}>
                            <Button onClick={this.handleCloseSaveEventMsg} className={classes.addToCalendarBtn}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Dialog >

                {/* showEvent dialogbox */}
                < Dialog open={this.state.editEventOpen} className={classes.mainDilogboxScroll} onClose={this.closeShowEvent} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    {this.state.isLoading && this.state.editEventOpen ? <Loader /> : null}
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingText} style={{ color: "#0F172A" }}>{this.state.eventName ? this.state.eventName : "(No title)"}</h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.closeShowEvent}>
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <Box>
                            <Box className={classes.dialogSubDiv}>
                                <ListItem disableGutters >
                                    <img className={classes.iconImg} src={schedulewatchIcon} alt="schedulewatchIcon" />
                                    <span> {`${this.state.showEditEventDateUi} • ${this.convertTiemFormate(this.state.selectedTime)} - ${this.convertTiemFormate(this.state.selectedEndTime)}`}</span>
                                </ListItem>
                            </Box>
                        </Box>
                        <div className={classes.dilogBoxWidth}></div>
                        <Box className={classes.dialogBoxBorderInline}></Box>
                        <Box className={classes.dialogSubDiv} style={{ alignItems: "start" }}>
                            <ListItem disableGutters style={{ alignItems: "start" }}>
                                <img className={classes.iconImg} style={{ marginTop: "4px", marginRight: "20px" }} src={notificationIconEvent} alt="notificationIcon" />
                                <Box>
                                    {this.state.editEventDataSet && this.state.editEventDataSet?.attributes.Reminders.length === 0 ? <Box style={{ marginTop: "6px" }}>
                                        <span  >No Reminders</span>
                                    </Box> : <Box style={{ display: "flex", flexDirection: "column" }}>
                                        {this.state.editEventDataSet && this.state.editEventDataSet?.attributes.Reminders.map((x: any, index: any) => (
                                            <span style={{ marginTop: "6px", marginBottom: "12px" }} key={index}>{x.reminder_name}</span>
                                        ))}
                                    </Box>}
                                </Box>
                            </ListItem>
                        </Box>
                        <Box className={classes.dialogSubBtnDiv}>
                            <Button className={classes.addToCalendarBtn} onClick={this.editAppointment}>
                                Edit
                            </Button>
                            <Button className={classes.deleteEventBtn} onClick={this.deleteAppointmentConfirme}>
                                Delete
                            </Button>
                        </Box>
                    </List>
                </Dialog >

                {/* eventis oevrlaps dialogbox */}
                < Dialog open={this.state.overlapEvent} onClose={this.overlapEventClose} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    <List className={classes.addEventDilogMainSave} style={{ padding: "11px 0px 22px 0px" }} >
                        <div className={classes.dialogHading} style={{ justifyContent: "flex-end", padding: "0px 15px", width: "auto" }}>
                            <span className={classes.dialogHadingCloseRetryDeeletBtn} onClick={this.overlapEventClose} >
                                <img src={closeIconDelete} alt="closeIcon" />
                            </span>
                        </div>
                        <div className={classes.overlapTextMsg}>
                            <span className={classes.overlapsText}>Oops! It looks like you already have another event scheduled at this time. Are you sure you want to create this event? If not, please select a different time slot.</span>
                        </div>
                        <Box className={classes.borderBotttomDialog}></Box>
                        <List className={classes.dialogSubBtnDiv} style={{ marginTop: "18px", padding: "0px 24px" }}>
                            <Button className={classes.cancelDeletBtn} onClick={this.overlapEventClose}>
                                Cancel
                            </Button>
                            <Button data-testId="overlapEvent" onClick={this.overlapEventRetry} className={classes.deleteCalendarBtn}>
                            Edit Event Time
                            </Button>
                        </List>
                    </List>
                </Dialog >


                {/* delete recurring events for delete all or only once*/}
                < Dialog open={this.state.openDeleteReceuringEvent} className={classes.mainDilogboxScroll} onClose={this.handleCloseDeleteReccuringEvent} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    {this.state.isLoading && this.state.openDeleteReceuringEvent ? <Loader /> : null}
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingText} style={{ color: "#0F172A" }}>Delete Event</h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.handleCloseDeleteReccuringEvent} >
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <div className={classes.dilogBoxWidth}></div>
                        <List>
                            <List className={classes.dialogRepetEvent}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <this.BpRadio id="option1" name="deleteReccuring"
                                        onChange={() => this.onHandleChangeDeleteReccuringEvent(true)}
                                        checked={this.state.deleteReceivingEvent}
                                    />
                                    <label htmlFor="option1" style={{ marginLeft: "8px" }}>This event</label>
                                </div>
                                {this.state.recuringOpationShow !== 0 ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <this.BpRadio id="option2" name="deleteReccuring"
                                            onChange={() => this.onHandleChangeDeleteReccuringEvent(false)}
                                            checked={!this.state.deleteReceivingEvent}
                                        />
                                        <label htmlFor="option2" style={{ marginLeft: "8px" }}>This and following events</label>
                                    </div>
                                    : null}
                            </List>
                        </List>
                        <List className={classes.dialogSubBtnDiv}>
                            <Button onClick={this.handleCloseDeleteReccuringEvent} className={classes.addToCalendarBtn}>
                                Close
                            </Button>
                            <Button onClick={this.handleDeleteData} className={classes.deleteEventBtn}>
                                Delete
                            </Button>
                        </List>
                    </List>
                </Dialog >

                {/* save recurring events for edit all or only once*/}
                < Dialog open={this.state.openReccuringEvent} className={classes.mainDilogboxScroll} onClose={this.handleCloseReccuringEvent} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
                    {this.state.isLoading && this.state.openReccuringEvent ? <Loader /> : null}
                    <List className={classes.addEventDilogMain}>
                        <div className={classes.dialogHading}>
                            <h5 className={classes.dialogHadingText} style={{ color: "#0F172A" }}>Save Recurring Event</h5>
                            <span className={classes.dialogHadingCloseBtn} onClick={this.handleCloseReccuringEvent} >
                                <img src={closeIcon} alt="closeIcon" />
                            </span>
                        </div>
                        <div className={classes.dilogBoxWidth}></div>
                        <List>
                            <List className={classes.dialogRepetEvent}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <this.BpRadio id="option1" name="repeatOption"
                                        onChange={() => this.onHandleChangeSvaeReccuring(true)}
                                        checked={this.state.saveRecurringEvent}
                                    />
                                    <label htmlFor="option1" style={{ marginLeft: "8px" }}>This event</label>
                                </div>
                                {this.state.recuringOpationShow !== 0 ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <this.BpRadio id="option2" name="repeatOption"
                                            onChange={() => this.onHandleChangeSvaeReccuring(false)}
                                            checked={!this.state.saveRecurringEvent}
                                        />
                                        <label htmlFor="option2" style={{ marginLeft: "8px" }}>This and following events</label>
                                    </div>
                                    : null}
                            </List>
                        </List>
                        <List className={classes.dialogSubBtnDiv}>
                            <Button onClick={this.handleCloseReccuringEvent} className={classes.addToCalendarBtn}>
                                Close
                            </Button>
                            <Button onClick={this.handleSaveEditData} className={classes.saveCalendarBtn}>
                                Save
                            </Button>
                        </List>
                    </List>
                </Dialog >
            </>
        )
        // Customizable Area End
    }

    convertTiemFormate = (time: any) => {
        let hoursStr = parseInt(time.substring(0, 2));
        let minutesStr = parseInt(time.substring(2));
        let totalMinutes = hoursStr * 60 + minutesStr + 0;
        let newHours = Math.floor(totalMinutes / 60) % 24;
        let newMinutes = totalMinutes % 60;
        return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
    };

    renderCalendarGrid = (year: any, month: any) => {
        const calendarGrid = [];
        const today = new Date();
        const daysInMonth = new Date(year, month, 0).getDate();
        const startDay = new Date(year, month - 1, 0).getDay();
        const twoYearsFromToday = new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());
        const renderPreviousMonthDay = (day: any) => (
            <td key={`empty-prev-${day}`} style={{ color: "#94A3B8" }}>
                {day}
            </td>
        );

        const renderCurrentMonthDay = (day: any) => {
            const currentDate = new Date(year, month - 1, day);
            let isEventDate
            if (Array.isArray(this.state.getEventListData)) {
                isEventDate = this.state.getEventListData.some((event: any) => {
                    const parseDate = (dateString: string) => {
                        const [year, month, days] = dateString.split('-').map(Number);
                        return { days, month, year };
                    };
                    const dateObjects = parseDate(event.attributes.date)
                    const eventDate = new Date(dateObjects.year, dateObjects.month - 1, dateObjects.days);
                    return currentDate.toDateString() === eventDate.toDateString();
                });
            }

            let style = {};
            if (currentDate.toDateString() === this.state.selectedDate?.toDateString()) {
                style = { ...style, backgroundColor: "#F1F5F9" };
            }
            if (isEventDate) {
                style = { ...style, backgroundColor: "#F1F5F9", position: "relative" };
            }
            if (currentDate <= twoYearsFromToday) {
                style = { ...style, cursor: "pointer" }
            }
            if (currentDate.toDateString() === today.toDateString()) {
                style = { ...style, backgroundColor: "#D1EDFF" };
            }
            
            return (
                <td>
                    <span key={`day-${day}`} style={style}
                        ref={this.state.onboardingRefs[0]}
                        onClick={() => {
                            if (currentDate <= twoYearsFromToday && currentDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)) {
                                this.handleDateClick(currentDate, false);
                            }
                            else if (currentDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
                                this.handleDateClick(currentDate, true)
                            }
                        }}>
                        {day}
                        {isEventDate && <img src={eventTickCircle} style={{ position: "absolute", bottom: "2px", right: "2px", width: this.state.calender ? "10px" : "5px", height: this.state.calender ? "10px" : "5px" }} />}                    
                    </span>
                </td>
            );
        };

        const renderNextMonthDay = (day: any) => (
            <td key={`empty-next-${day}`} style={{ color: "#94A3B8" }}>
                {day}
            </td>
        );

        let prevMonthCounter = new Date(year, month - 1, 0).getDate() - startDay + 1;
        let currentMonthCounter = 1;
        let nextMonthCounter = 1;

        for (let week = 0; week < 6; week++) {
            const weekDays = [];

            for (let day = 0; day < 7; day++) {
                if (week === 0 && day < startDay) {
                    weekDays.push(renderPreviousMonthDay(prevMonthCounter));
                    prevMonthCounter++;
                } else if (currentMonthCounter <= daysInMonth) {
                    weekDays.push(renderCurrentMonthDay(currentMonthCounter));
                    currentMonthCounter++;
                } else {
                    if (nextMonthCounter <= 6) {
                        weekDays.push(renderNextMonthDay(nextMonthCounter));
                    }
                    nextMonthCounter++;
                }
            }
            calendarGrid.push(<tr key={`week-${week}`}>{weekDays}</tr>);
            if (currentMonthCounter > daysInMonth) {
                break; // Break if all days of the current month are displayed
            }
        }

        return calendarGrid;
    }


}

// Customizable Area Start
// @ts-ignore
export default withStyles(styles)(EventsSchedule);
// Customizable Area End
