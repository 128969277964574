export const remove = require("../assets/remove-32.png");
export const removeItem = require("../assets/remove-30.png");
export const addNew = require("../assets/add-new-30.png");
export const notificationIconEvent = require("../assets/notifications_icon.png");
export const calender = require("../assets/image_calendar_month.png");
export const profileIconImg = require("../assets/profileIcon.svg");
export const fireIcon = require("../assets/fire_icon.png");
export const emptySummary = require("../assets/empty_summary.png");
export const filterListIcon = require("../assets/filter_list.png");
export const lockIcon = require("../assets/lock.png");
export const toolTipIcon = require("../assets/tooltip.svg");