import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";

import SideBar from "../../../components/src/SideBar"
import WebHeader from "../../../components/src/WebHeader.web";
import { helpimage, selectimage, document, closesmall, closesmodel, closessucsessmodel } from "./assets";
import ContactusController, { Props,configJSON } from "./ContactusController.web";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const styles = {
  input: {
    border: '1px solid #CBD5E1',
    background: '#F8FAFC',
    borderRadius: '8px',
    minHeight: '50px',
    fontFamily: "Raleway"
  },
  textarea: {
    minHeight: '116px',
    background: '#F8FAFC',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
  },
};
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        {/* Customizable Area Start */}
        <Box style={{ display: "flex" }}>
          <SideBar navigation={this.props.navigation} />
          <MainContainerbox>
            <HeaderDiv>
              <WebHeader title={"Help & Support"} icon={helpimage} />
            </HeaderDiv>
            {this.state.contactus ?
              <FaqsBox>
                <InnerFaqsBox>
                  <FaqsTittle><BorderDiv></BorderDiv><p>{configJSON.faqsstaticTittle}</p></FaqsTittle>
                  <AnsMainDiv>
                    {this.state.questionanswer && Array.isArray(this.state.questionanswer.data) && this.state.questionanswer.data.map((element) => (
                      <FaqsDetails key={element.attributes?.id}>
                        <TittleOne>{element.attributes?.question}</TittleOne>
                        <TittleTwo dangerouslySetInnerHTML={{ __html: `${element.attributes?.answer}` }}></TittleTwo>
                      </FaqsDetails>
                    ))}
                  </AnsMainDiv>
                </InnerFaqsBox>
                <ContactMainDiv>
                  <ContactTittle>{configJSON.findAnswer}</ContactTittle>
                  <ContactButton data-testid="contact-us" onClick={this.contactusPage}>{configJSON.contactButtontext}</ContactButton>
                </ContactMainDiv>
              </FaqsBox>
              :
              <Formik
                initialValues={{
                  subject: '',
                  yourquery: '',
                  file: '',
                }}
                validationSchema={Yup.object({
                  subject: Yup.string().max(1000, configJSON.subValidation).required('Please enter subject.'),
                  yourquery: Yup.string().required('Please enter query.'),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  this.contactusDataaValue(values)
                  setSubmitting(false);
                  resetForm();
                }}
              >
                {({ values, handleChange, errors, handleSubmit, setFieldValue, touched }) => (
                  <HelpDataSubmit data-testid="contact-datasubmit" onSubmit={handleSubmit}>
                    <TextField
                      id="email"
                      onChange={handleChange}
                      InputLabelProps={{ style: { color: '#64748B', fontFamily: "Raleway" } }}
                      InputProps={{ disableUnderline: true, style: { ...styles.input, border: '1px solid rgb(203, 213, 225)' }, readOnly: true }}
                      label="Email"
                      variant="filled"
                      value={this.state.email}
                      
                    />
                    <TextField
                      id="subject"
                      onChange={handleChange}
                      InputLabelProps={{ style: { color: '#64748B', fontFamily: "Raleway" } }}
                      InputProps={{ disableUnderline: true, style: { ...styles.input, border: this.getBorderstyle(touched.subject , errors.subject) } }}
                      label="Subject"
                      variant="filled"
                      value={values.subject}
                      error={touched.subject && !!errors.subject}
                      helperText={touched.subject && errors.subject}
                      FormHelperTextProps={{ style: { marginLeft: "0px", fontFamily: "Raleway", } }}
                    />
                    <StyledTextField
                      onChange={handleChange}
                      InputLabelProps={{ style: { color: '#64748B', fontFamily: "Raleway", } }}
                      InputProps={{ disableUnderline: true, style: { ...styles.input, border: this.getBorderstyle(touched.yourquery , errors.yourquery) } }}
                      inputProps={{ style: { minHeight: "116px" } }}
                      id="yourquery"
                      label="Enter your query"
                      value={values.yourquery}
                      multiline
                      variant="filled"
                      style={{ minHeight: "116px" }}
                      error={touched.yourquery && !!errors.yourquery}
                      helperText={touched.yourquery && errors.yourquery}
                      FormHelperTextProps={{ style: { marginLeft: "0px", fontFamily: "Raleway", } }}
                    />
                    <SelectFile data-testid="select_file" onClick={() => { this.openPictureUpload() }}>
                      <img src={selectimage} alt="Select_File" />
                      <SelectFileDiv> Select File </SelectFileDiv>
                    </SelectFile>
                    <SelectFileValidation>
                      {configJSON.pdfSelectValidation}
                    </SelectFileValidation>
                    <Modal
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableBackdropClick
                    >
                      <ModelInnerBox sx={{ boxShadow: 24 }}>
                        <ModelErrorOne>You've reached the maximum limit of 5 files. Please </ModelErrorOne>
                        <ModelErrorTwo>remove some files to add more.</ModelErrorTwo>
                        <ModelErrorBorder></ModelErrorBorder>
                        <img className="uploadBoximg" style={{ cursor: "pointer" }} onClick={this.handleClose} src={closesmodel} />
                      </ModelInnerBox>
                    </Modal>
                    <PdfMainBox style={{ display: "flex", gap: "22px", }} sx={{
                      overflow: 'auto',
                      whiteSpace: 'nowrap', width: {
                        md: 'auto', lg: 'auto',
                      }, padding: '10px 2px'
                    }}>
                      {this.state.selectedFile?.map((value: { name: string }, index: number) => {
                        return (
                          <Box className="fieldAreaShow" key={index} style={{ display: value?.name ? 'flex' : 'none', position: "relative",background:"#F8FAFC",borderRadius:"50px",padding:"5px 4px" }} data-test-id="portableNewOneiamghe">
                            <Typography className="fieldAreaFileName portableNewOneiamghe" data-test-id="handleRemove" style={{
                              fontSize: "12px", display: "flex",
                              alignItems: "center",
                              fontFamily: "Raleway",
                              whiteSpace: 'nowrap'}}>
                              <img className="uploadBoximg" src={document} />{value?.name}</Typography>
                            <img className="fieldImg" style={{ minHeight: "18px", maxWidth: "18px", position: "relative", bottom: 16, cursor: "pointer" }} data-test-id="handleRemoves" src={closesmall} onClick={() => this.handleRemoveMain(index)} />
                          </Box>
                        )
                      })
                      }
                    </PdfMainBox>
                    <SubmitQueryButton data-testid="errormodel_submit" type="submit">
                      Submit
                    </SubmitQueryButton>
                    <Modal
                      open={this.state.complteopen}
                      onClose={this.complteclosed}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableBackdropClick
                    >
                      <SubmitComplate sx={{ boxShadow: 24 }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <img onClick={this.complteclosed} style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} src={closessucsessmodel} />
                        </div>
                        <ComplateOne>Thank you for reaching out!</ComplateOne>
                        <ComplateTwo>Your message has been received. Our team is working diligently to address your inquiry. We appreciate your patience and will get back to you shortly.</ComplateTwo>
                        <ComplateButtonDiv>
                          <ComplateCancel data-testid="Cancel_submit" onClick={this.complteclosed}>Cancel</ComplateCancel>
                          <Complatehelp onClick={this.navigatehelp}>Visit Help</Complatehelp>
                        </ComplateButtonDiv>
                      </SubmitComplate>
                    </Modal>
                    <Modal
                      open={this.state.pdfselectopen}
                      onClose={this.pdfValidationclosed}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableBackdropClick
                    >
                      <SelectValidationComplate sx={{ boxShadow: 24 }}>
                        <PdfvalidationOne>Trouble uploading a file. Please choose a different file</PdfvalidationOne>
                        <PdfvalidationTwo>format</PdfvalidationTwo>
                        <ComplateValidationClose data-testid="Cancelpdf_submit" onClick={this.pdfValidationclosed}>Close</ComplateValidationClose>
                      </SelectValidationComplate>
                    </Modal>
                  </HelpDataSubmit>
                )}
              </Formik>

            }
          </MainContainerbox>
        </Box>
        {/* Customizable End Start */}
      </ThemeProvider >
      //Merge Engine End DefaultContainer 
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const StyledTextField = styled(TextField)({
  "& textarea": {
    resize: "both"
  },

});

const MainContainerbox = styled(Box)({
  width: "100%",
  justifyContent: "conter",
  justifyItems: "center",
  background: '#F4F4F4',
  fontFamily: "Raleway",
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
});

const HeaderDiv = styled('div')({
  background: "#FFFFFF",
  "& .icon": {
    width: "38px",
    height: "38px",
    marginRight: "18px"
  },
});

const FaqsBox = styled(Box)({
  minHeight: "504px",
  maxWidth: "933px",
  borderRadius: "12px",
  margin: "28px 30px 32px 28px",
});

const InnerFaqsBox = styled(Box)({
  padding: "12px",
  background: "white",
  borderRadius: "12px",
  boxshadow: "0px 2px 8px 0px #00000014"
});

const AnsMainDiv = styled('div')({
  height: "490px",
  overflow: "auto"
});

const BorderDiv = styled('div')({
  padding: "0px 4px",
  background: "#13689E",
  borderRadius: "4px",
  marginRight: "16px",
  minHeight: "32px"
});

const FaqsTittle = styled('div')({
  fontWeight: 700,
  fontSize: "20px",
  display: "flex",
  minHeight: "32px",
  alignItems: "center",
  '@media (max-width: 400px)': {
    fontSize: "18px"
  },

});

const FaqsDetails = styled(Box)({
  background: "#F8FAFC",
  marginTop: "20px",
  borderRadius: "12px",
  minHeight: "80px",
  flexDirection: "column",
  display: "flex",
  gap: "4px"
});

const TittleOne = styled('div')({
  fontSize: "14px",
  display: "flex",
  fontWeight: 500,
  alignItems: "center",
  minHeight: "48px",
  paddingRight: "16px",
  paddingLeft: "16px",
});

const TittleTwo = styled('div')({
  fontWeight: 400,
  fontSize: "12px",
  display: "flex",
  minHeight: "28px",
  paddingLeft: "16px",
  alignItems: "center",
  paddingRight: "16px",
  paddingBottom: "12px",
});

const ContactMainDiv = styled(Box)({
  maxWidth: "262px",
  minHeight: "96px",
  display: "flex",
  paddingTop: "25px",
  flexDirection: "column",
  justifyContent: "space-between"
});

const ContactTittle = styled('div')({
  fontSize: "24px",
  fontWeight: 600,
  '@media (max-width: 400px)': {
    fontSize: "18px"
  },

});

const ContactButton = styled(Button)({
  maxWidth: "163px",
  minHeight: "48px",
  padding: "12px 16px ",
  borderRadius: "50px",
  fontWeight: 700,
  background: "#13689E",
  color: "white",
  fontFamily: "Raleway",
  textTransform: "none",
  '&:hover': {
    background: '#13689E',
  },

});

const HelpDataSubmit = styled(Form)({
  minHeight: "582px",
  maxWidth: "752px",
  margin: "28px 30px 100px 28px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  fontFamily: "Raleway",
});

const SelectFile = styled('div')({
  border: '1px solid #CBD5E1',
  background: "#F8FAFC",
  borderRadius: "8px",
  minHeight: "164px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const SelectFileDiv = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  paddingTop: '8px'
});

const SelectFileValidation = styled('div')({
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 500,
  color: "#64748B",
  margin: "-20px 0px -35px 5px",
});

const SubmitQueryButton = styled(Button)({
  maxWidth: "119px",
  minHeight: "48px",
  borderRadius: "50px",
  background: "#13689E",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  '&:hover': {
    background: '#13689E',
  },

});

const ModelInnerBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: 'rgb(229, 229, 229)',
  border: 'none',
  padding: 0,
  borderRadius: "16px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '95%',
  },

});

const ModelErrorOne = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",
  },

});

const ModelErrorTwo = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",

  },

});

const ModelErrorBorder = styled('p')({
  borderBottom: "0.33px solid #3C3C435C",
  margin: "16px 0px 10px 0px",
  width:"100%"
});

const SubmitComplate = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 610,
  background: 'white',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  minHeight: "320px",
  display: "flex",
  flexDirection: "column",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },

});

const ComplateOne = styled(Typography)({
  color: "black",
  fontSize: "24px",
  fontFamily: "Raleway",
  fontWeight: 700,
  padding: "21px 40px 16px 40px",
  '@media (max-width: 450px)': {
    fontSize: "19px",
  },

});

const ComplateTwo = styled(Typography)({
  color: "black",
  fontSize: "18px",
  fontFamily: "Raleway",
  fontWeight: 400,
  padding: "0px 40px 30px 40px",
  borderBottom: "1px solid #E2E8F0",
  '@media (max-width: 450px)': {
    fontSize: "15px",
  },

});

const ComplateButtonDiv = styled('div')({
  display: "flex",
  justifyContent: "end",
  padding: "24px 24px 24px 0px"
});

const ComplateCancel = styled(Button)({
  minWidth: "140px",
  minHeight: "56px",
  color: "#13689E",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  '&:hover': {
    background: 'none',
  },

});

const SelectValidationComplate = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: 'rgb(229,229,229)',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },

});

const PdfvalidationOne = styled('div')({
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 400,
  paddingTop:"16px",
  color:"#334155",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },

});

const PdfvalidationTwo = styled('div')({
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 400,
  color:"#334155",
  paddingTop:"8px",
  width:"100%",
  textAlign:"center",
  paddingBottom: "16px",
  borderBottom: "0.33px solid #b9b9bb",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },

});

const ComplateValidationClose = styled(Button)({
  minWidth: "140px",
  color: "#007AFF",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  marginTop: "5px",
  '&:hover': {
    background: 'none',
  },

});

const Complatehelp = styled(Button)({
  minWidth: "151px",
  minHeight: "56px",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  borderRadius: "50px",
  background: "#13689E",
  '&:hover': {
    background: '#13689E',
  },

});

const PdfMainBox = styled(Box)({
  marginTop:"5px",
  "@media (max-width: 900px)": {
    width: '680px!important',
  },
  "@media (max-width: 800px)": {
    width: '580px!important',
  },
  "@media (max-width: 700px)": {
    width: '480px!important',
  },
  "@media (max-width: 600px)": {
    width: '380px!important',
  },
  "@media (max-width: 500px)": {
    width: '250px!important',
  },
  "@media (max-width: 400px)": {
    width: '200px!important',
  },
});

// Customizable Area End
