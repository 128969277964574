import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  withStyles,
  Box,
  styled,
  Container,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CheckboxProps,
  InputAdornment,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import EmailRegistrationWithInvCodeController, {
  Props,
} from "./EmailRegistrationWithInvCodeController.web";
import {
  bottom_left,
  closeIcon,
  code_verified,
  mainLogo,
  top_right,
} from "./assets";
import { Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import CustomBG from '../../../components/src/CustomBG.web';
import * as Yup from "yup";

const DialogBox = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "24px",
  },
});
const CssTextFieldCode = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1.8px",
      borderColor: "#ccc6c72b",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontWeight: 500,
      color: "#f13426",
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },
    "& .MuiFilledInput-underline::after": {
      border: "none",
    },
    "& .MuiFilledInput-root": {
      border: "1px solid rgba(0, 0, 0, 0.09)",
      backgroundColor: "white",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "#f4faffbd",
      color: "var(--Neutrals-Cool-gray-500, #1B1C1E)",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiInputLabel-root": {
      color: "var(--Neutrals-Cool-gray-500, #64748B)",
    },
    '& ::placeholder': {
      color: "#64748B",
      opacity: 1
    }
  },
})(TextField);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1dddc",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#ff0c00",
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },
    "& .MuiFilledInput-underline::after": {
      border: "none",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f0f0f0",
      border: "1px solid rgba(0, 0, 0, 0.09)",
    },
    "& .MuiInputBase-input": {
      color: "rgb(51, 51, 57)",
      backgroundColor: "#f4faffbd",
    },
    "& .MuiInputLabel-root": {
      color: "rgb(51, 51, 57)",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#f4faffbd",
    },
    '& ::placeholder': {
      color: "#64748B",
      opacity: 1
    }
  },
})(TextField);

const MainContainer = styled(Box)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
});

const DialogContainer = styled(Box)({
  width: 600,
  borderRadius: 24,
  margin: 'auto',
  background: '#FFFFFF',

  "& .signup-btn": {
    borderRadius: "8px",
    background: "#FCFCFC",
    color: "#13689E",
  },
  "& .contact-btn": {
    borderRadius: "8px",
    background: "#13689E",
    color: "white",
  },
  "& .action-btns": {
    padding: "16px",
  },
});

interface CustomCheckboxProps extends CheckboxProps {
  "data-test-id": string;
}

const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "rgba(19, 104, 158, 1)",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "26px",
    },
    padding: "0px",
  },
  checked: {},
})((props: CustomCheckboxProps) => <Checkbox color="default" {...props} />);
// Customizable Area End

export default class EmailRegistrationWithInvCode extends EmailRegistrationWithInvCodeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStepViceForm = () => {
    const { step } = this.state;
    let result;

    if (step === 0) {
      result = this.getFormOneInvCode();
    } else if (step === 1) {
      result = this.getSrcTwoCodeVerifed();
    } else if (step === 2) {
      result = this.getFormThreeSignup();
    } else if (step === 3) {
      result = this.getFormFourConinue();
    }
    return result;
  };
  getFormOneInvCode = () => {
    return (
      <>
        <Formik
          data-test-id="Formik"
          initialValues={{ code: this.state.allData.code || "" }}
          onSubmit={(values) => {
            this.verifyCodePutAPI(values?.code);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Box style={styles.closeIcon}>
                  <img src={closeIcon} alt="x" onClick={this.goBackFn} data-test-id="firstClick"/>
                </Box>
                <img src={mainLogo} alt="VITA" style={styles.mainLogo} />
                <Typography variant="h5" style={styles.head}>
                  Please enter your <br /> Invitation Code
                </Typography>
                <Typography style={styles.paraText}>
                  Enter your invitation code and enter the associated email
                  address to unlock exclusive access.
                </Typography>

                <CssTextFieldCode
                  placeholder="Enter your Invitation Code"
                  onChange={(event) => {
                    props.handleChange(event);
                    event.target.value.length > 1 &&
                    this.handelNameChange(event)
                      
                  }}
                  data-test-id="handleChangeCode"
                  name="code"
                  style={{ ...styles.inputbox, marginBottom: "20px" }}
                  value={props.values.code}
                  error={Boolean(this.state.errorModal.length > 1)}
                  variant="outlined"
                />
                <span style={styles.errorMsgText}>{this.state.errorModal}</span>
                <button
                  data-test-id="handle_continue_click"
                  style={{
                    ...styles.button,
                    opacity: props.values.code.length > 0 ? 1 : 0.4,
                  }}
                  disabled={!props.values.code}
                  type="submit"
                >
                  Continue
                </button>
                <Typography style={styles.alreadyAccount}>
                  <p style={{ ...styles.contactLink, marginTop: "0px", cursor:"pointer" }}  data-test-id="navigationButton" onClick={this.handleNavigate}>
                    Contact Support?
                  </p>
                </Typography>
              </form>
            );
          }}
        </Formik>
      </>
    );
  };

  getSrcTwoCodeVerifed = () => {
    return (
      <>
        <img src={code_verified} alt="Code" style={styles.mainLogo} />
        <Typography variant="h5" style={styles.codeVerifiedText}>
          Congrats! Your Invitation Code is Verified
        </Typography>
        <Typography style={styles.paraTextVerified}>
          Feel free to explore and enjoy personalized health features tailored
          just for you.
        </Typography>

        <button
          data-test-id="handle_continue_click"
          style={styles.button}
          onClick={this.handle_continue_click}
        >
          Continue
        </button>
      </>
    );
  };

  getFormThreeSignup = () => {
    return (
      <> {this.state.data &&
        <Formik
          data-test-id="Formik"
          initialValues={{
            email: this.state.data?.email || this.state.allData.email || "",
            c1: false,
            c2: this.state.allData?.c2?.length > 1 || false,
          }}
          onSubmit={(val) => {
            this.verifyCodeSignUpAPi(val);
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Please enter a valid email address.")
              .required("Email is required."),
          })}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <img src={mainLogo} alt="VITA" style={styles.mainLogo} />
                <Typography variant="h5" style={styles.head}>
                  Ready to Begin?
                </Typography>
                <Typography style={styles.paraText}>
                  Please take a moment to confirm and ensure the accuracy of
                  your pre-filled details.
                </Typography>

                <CssTextFieldCode
                  style={styles.inputbox}
                  name="first"
                  placeholder="Enter your Email ID"
                  value={this.state.code?.first_name || this.state.data?.first_name || this.state.allData?.firstName}
                  id="filled-required"
                  onChange={(e) => {
                    props.handleChange(e);

                  }}
                  defaultValue="Hello World"
                  label="First Name"
                  variant="filled"
                  data-test-id="first-change"
                  inputProps={{
                    color: "rgb(51, 51, 57)",
                  }}
                  disabled={true}
                />

                <CssTextFieldCode
                  style={styles.inputbox}
                  placeholder="Enter your Email ID"
                  value={this.state.code?.last_name || this.state.data?.last_name || this.state.allData?.lastName}
                  name="last"
                  onChange={(e) => {
                    props.handleChange(e);


                  }}
                  id="filled-required"
                  defaultValue="Hello World"
                  label="Last Name"
                  variant="filled"
                  data-test-id="last-change"
                  inputProps={{
                    color: "rgb(51, 51, 57)",
                  }}
                  disabled={true}
                />

                <CssTextFieldCode
                  placeholder="Email"
                  name="email"
                  value={props.values.email}
                  onChange={(event) => {
                    props.handleChange(event);
                    this.emailChange(event)
                   

                  }}
                  style={{ ...styles.inputbox, marginBottom: "20px" }}
                  error={
                    !!props.errors?.email || this.state.errorEmails.length > 1
                  }
                  data-test-id="errorEmailsHandleChange"
                  variant="outlined"
                  helperText={props.errors?.email || this.state.errorEmails}
                />

                <Box style={{ ...styles.tcPara, margin: "30px 0px 2%" }}>
                  <CustomCheckbox
                    style={styles.ccBox}
                    data-test-id="aggreCheckbox"
                    checked={this.state.isAgree}
                    onChange={() => this.handleTermsCondition(props)}
                  />
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      color: "#0F172A",
                    }}
                  >
                    By accepting you agree to the{" "}
                    <span onClick={() => this.gototerms(props.values)} data-test-id="gototerms" style={styles.contactLink}>
                      {" "}
                      Terms of Service{" "}
                    </span>{" "}
                    &{" "}
                    <span onClick={() => this.gotoprivacy(props.values)} data-test-id="gotoprivacy" style={styles.contactLink}>
                      Privacy Policy.{" "}
                    </span>
                  </Typography>
                </Box>
                <Box style={styles.tcPara}>
                  <CustomCheckbox
                    data-test-id="checkboxSec"
                    style={styles.acceptBox}
                  checked={this.state.allData.c2}
                    onChange={() => {
                      props.setFieldValue("c2", !props.values.c2);
                      this.checkBoxSecChange(!props.values.c2)
                      
                    }}
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      color: "#0F172A",
                    }}
                  >
                    I acknowledge that all details are accurate
                  </Typography>
                </Box>
                <button
                  data-test-id="handleContinueClick"
                  style={{
                    ...styles.button,
                    opacity: this.state.isAgree && this.state.allData.c2 && props.values.email ? 1 : 0.4,
                  }}
                  type="submit"
                  disabled={!this.state.isAgree || !this.state.allData.c2 || !props.values.email}
                >
                  Continue
                </button>
              </form>
            );
          }}
        </Formik>
      }

      </>
    );
  };

  getFormFourConinue = () => {
    return (
      <>
        <Formik
          data-test-id="Formik"
          initialValues={{
            new_pass: this.state.allData.password || "",
            conf_pass: this.state.allData.conformPassword ||  "",
            vis1: false,
            vis2: false,
          }}
          onSubmit={(val) => {
            this.verifySignUpPassAPI(val);
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            new_pass: Yup.string().required("Password is required"),
            conf_pass: Yup.string()
              .oneOf([Yup.ref("new_pass")], "Passwords must match")
              .required("Password is required")
              .min(8, "Password must be at least 8 characters"),
          })}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Box style={styles.closeIcon} onClick={
                this.secondClick
                } data-test-id="secondClick">
                  <img src={closeIcon} alt="x" />
                </Box>
                <img src={mainLogo} alt="VITA" style={styles.mainLogo} />
                <Typography
                  variant="h5"
                  style={{ ...styles.head, marginBottom: "32px" }}
                >
                  Set your password
                </Typography>

                <CssTextField
                  placeholder="New Password"
                  style={{ ...styles.inputbox, marginBottom: "22px" }}
                  name="new_pass"
                  onChange={(event) => {
                    props.handleChange(event);
                    this.newPassChange(event)
                    

                  }}
                  value={props.values.new_pass}
                  variant="outlined"
                  error={!!props.errors?.new_pass}
                  data-test-id="new_pass-change"
                  type={props.values.vis1 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "rgba(100, 116, 139, 1)",
                        }}
                        onClick={() =>
                          props.setFieldValue("vis1", !props.values.vis1)
                        }
                        data-test-id="togglePassword"
                      >
                        {props.values.vis1 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <CssTextField
                  placeholder="Confirm Password"
                  value={props.values.conf_pass}
                  data-test-id="old_pass-change"
                  name="conf_pass"
                  onChange={(event) => {
                    props.handleChange(event); 
                    this.confPassChange(event)

                    

                  }}
                  style={styles.inputbox}
                  variant="outlined"
                  error={!!props.errors?.conf_pass}
                  type={props.values.vis2 ? "text" : "password"}
                  helperText={props.errors?.conf_pass}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "rgba(100, 116, 139, 1)",
                        }}
                        onClick={() =>
                          props.setFieldValue("vis2", !props.values.vis2)
                        }
                        data-test-id="togglePassword"
                      >
                        {props.values.vis2 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <button
                  style={{
                    ...styles.button,
                    marginTop: "12px",
                    opacity:
                      props?.values?.new_pass?.length &&
                        props?.values?.conf_pass?.length
                        ? 1
                        : 0.4,
                  }}
                  data-test-id="handle_continue_click"
                  type="submit"
                >
                  Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <CustomBG>
        <MainContainer>
          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <Grid style={styles.headSecondgrid}>
                  {this.getStepViceForm()}
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Dialog
          open={this.state.errorModelOpen}
          style={{ borderRadius: "50px" }}
          PaperProps={{
            style: {
              borderRadius: "24px",
            },
          }}
        >
          <Box className="popup_box"
                display={"flex"}
                paddingTop={"16px"}
                justifyContent={"end"}
                paddingRight={"16px"}
                borderRadius={"24px"}
              >
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  onClick={
                    this.openedModelClose   
                  }
                  data-test-id="openedModelClose"
                />
              </Box>
              <DialogContent>
                <p style={styles.dilogTitel}>Invitation code expired</p>
              </DialogContent>
              <DialogContent style={{ borderBottom: "1px solid #E2E8F0" }}>
                <p style={styles.dilogBodyText}>
                  The invitation code you entered has expired. Please contact our
                  support team for assistance or proceed to sign up as a regular
                  user.
                </p>
              </DialogContent>
              <DialogActions style={{flexWrap:"wrap"}}>
                
                    <Link to="/" style={{textTransform:"none"}}>
                      <Button
                        // onClick={() => this.props.navigation.navigate("")}
                        style={styles.signBtnText}
                      >
                        Sign up
                      </Button>
                    </Link>
                    <Button
                      className="contact-btn"
                      onClick={this.openedModelContact}
                      data-test-id="openedModelContact"
                      style={styles.contactBtnText}
                    >
                      Contact Support
                    </Button>
              </DialogActions>
        </Dialog>
          
        </MainContainer>
      </CustomBG>
      // Customizable Area End
    );
  }
}

const styles: Record<string, React.CSSProperties> = {
  // Customizable Area Start
  signBtnText: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#13689E",
    textTransform: "none",
    backgroundColor: "rgba(232, 232, 232, 0.47)",
    height: "56px",
    width: "151px",
    textDecoration: "none",
    margin:"10px 0px"
  },
  contactBtnText: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#fff",
    textTransform: "none",
    backgroundColor: "#13689E",
    height: "56px",
    width: "151px",
    margin:"10px 0px"
  },
  dilogBodyText: {
    fontFamily: "Raleway",
    fontSize: "18px",
    fontWeight: 400,
    color: "#0F172A",
    marginTop: "0px",
  },
  dilogTitel: {
    fontFamily: "Raleway",
    fontSize: "24px",
    fontWeight: 700,
    color: "#0F172A",
    margin: "0px"
  },
  tcPara: { display: "flex", alignItems: "flex-start" },
  errorMsgText: {
    color: "red",
    fontSize: "0.75rem",
    fontFamily: "Raleway",
  },
  ccBox: { padding: "0 10px 0 0" },
  acceptBox: { padding: "0 10px 0 0" },
  codeVerifiedText: {
    color: "#3C3C3B",
    fontWeight: 700,
    fontSize: "32px",
    textAlign: "center",
    width: "100%",
    margin: "auto",
    maxWidth: "270px",
  },
  closeIcon: {
    padding: "10px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    background: "#F1F5F9",
    gap: "12px",
    width: "24px",
    alignItems: "center",
    cursor: "pointer",
  },
  mainLogo: {
    margin: "32px 0",
  },
  paraText: {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    marginBottom: "32px",
    marginTop: "8px",
    fontWeight: 500,
  },
  paraTextVerified: {
    color: "#475569",
    textAlign: "center",
    width: "100%",
    fontWeight: 500,
    maxWidth: "372px",
    margin: "auto",
    marginTop: "8px",
    marginBottom: "6px",
  },
  inputbox: {
    height: "40px",
    fontSize: "16px",
    marginBottom: "40px",
    fontWeight: 500,
    borderRadius: "1px solid rgba(241, 245, 249, 1)",
    marginTop: "5px",
    width: "100%",
    color: "#1E293B",
  },
  headSecondgrid: {
    borderRadius: "24px",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    zIndex: 1,
    backgroundColor: "#fff",
    padding: "16px 48px",
    position: "relative",
    overflow: "auto",
  },
  button: {
    borderRadius: "5px",
    height: "46px",
    marginBottom: "15px",
    fontSize: "14px",
    border: "none",
    width: "100%",
    marginTop: "24px",
    color: "white",
    backgroundColor: "rgba(19, 104, 158, 1)",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: "Raleway",
  },

  contactLink: {
    color: "rgba(19, 104, 158, 1)",
    fontWeight: 600,
    textDecoration: "none",
    textTransform: "none",
    fontFamily: "Raleway",
    fontSize: "14px",
    cursor:"pointer"
  },

  head: {
    fontWeight: 700,
    color: "#3C3C3B",
    textAlign: "left",
    fontSize: "32px",
  },
  alreadyAccount: {
    marginTop: "24px",
    textAlign: "center",
  },
  // Customizable Area End
};