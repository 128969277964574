import React from "react";

// Customizable Area Start
import {
  Modal,
  Box,
  Button,
  Paper,
  TextField,
  InputBase,
  Typography,
  Select,
  Divider,
  Input,
  MenuItem,
  Switch,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { logbookicon, upcomingevent, noSymptoms, adddisableSymptoms, plusTreatment, remindersIcon, pdfImage, logbbokiImage, thermostatImage, addactivityClosed, addactivityRemove, borderRight, symptoClosed, symptoRight, reportImage, noreportImage, upcomingTimeImage, upcomingLocationImage, selectImage, addTretImage, saveDataClosed,reminderPlustre,saveDataChecked,saveDataunChecked,noreminderImage } from "./assets";
import SideBar from "../../../components/src/SideBar"
import WebHeader from "../../../components/src/WebHeader.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TaskController, { Props, configJSON, TretReminder, ReminderTime } from "./TaskController.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: 'none !important'
        },
      }
    },
    MuiListItem: {
      root: {
        ":hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
          fontWeight: "600 !important",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected ": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop: "20px"
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "12px",
        background: "#F8FAFC",
        position: "absolute",
        top: "266px",
        left: "732px",
        "&::-webkit-scrollbar": {
          width: '4px',
          height:'4px'
        },
        "&::-webkit-scrollbar-track": {
          background: '#F1F5F9',
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: '8px',
          background: '#94A3B8',
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: '#90A4AE',
        },
        "&::-webkit-scrollbar:vertical": {
          width: '4px',
        },
      },
      list: {
        boxSizing: "border-box",
        backgroundColor:"#F1F5F9",
        color: "#13689E",
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

      },
    },  
  MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
        height: "16px",
        width: "16px",
        justifyContent: "center",
        display: "flex",
        marginRight: "8px",
        alignItems: "center",
      },
    },
    MuiPickersDay: {   
      daySelected: {
        
        "&:hover": {
          backgroundColor: "#D1EDFF !important",
        },
        backgroundColor: "#D1EDFF !important",
        color: "#13689E !important",
        borderRadius: "50%",
        border: "0.8px solid #68B9EC"
    },
    current: { color: "none !important" },

 
  },
  MuiPickersCalendarHeader: {
    transitionContainer: {
      "& p": {
        color: "#0F172A",
        fontSize: "11.13px",
        fontWeight: 700,
        textAlign: "center",
        cursor:"context-menu",
        fontFamily: "Raleway !important",
      },
    },
    dayLabel: {
      fontSize: "11.13px !important",
      color: "#64748B",
      fontWeight:500,
      cursor:"context-menu",
      fontFamily: "Raleway !important",
    },
  },
  MuiPopover: {
    paper: {
      border: "0.8px solid #E2E8F0",
     borderRadius:"12.73px"
    },
  },
  MuiTypography: {
    body2: {
      fontFamily: "Raleway !important",
    },
  },
  MuiIconButton: {
    root: {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
    
  } as {
    MuiPickersDay?: {
      daySelected?: {
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
    };
    MuiIconButton?: {
      root?: {
        "&:hover"?: {
          backgroundColor: string;
        };
      };
    };
  },
  });

const stylesTextfiled = {
  input: {
    border: '1px solid #F1F5F9',
    background: '#F8FAFC',
    borderRadius: '50px',
    fontFamily: "Raleway",
    height: "46px",
    padding: "12px 20px",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 500,
  },
};
export const AntintakeSwitch = withStyles((theme) => ({
  root: {
    width: 33,
    margin: theme.spacing(1),
    marginRight: "0px",
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    paddingTop: "2.5px",
    '&$checked': {
      transform: 'translate(15px,1px)',
      color: theme.palette.common.white,
      paddingTop: "1.5px !important",
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: '#13689E',
      },
    },
    '& .MuiIconButton-label-66': {
      marginTop: "2px !important",
      marginLeft: "2px !important",
    },
    '&$focusVisible $thumb': {
      color: '#13689E',
      border: '6px solid #fff',
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#13689E !important'
    },
  },
  track: {
    borderRadius: 29 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#EFEFEF",
  },
  thumb: {
    height: 15,
    width: 15,
  },
  checked: {},
  focusVisible: {},
}))(Switch);

export const AntintakeSwitchTwo = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 48,
    height: 31,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      marginTop: "1px",
      color: theme.palette.common.white,
      marginLeft: "2px",
      '& + $track': {
        opacity: 1,
        backgroundColor: '#13689E',
        border: 'none',
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#13689E !important'
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
      color: '#13689E',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    height: 27,
    width: 27,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);

const RedioIconset = ({url}: { url: string | undefined }) => {
  return (
    <img src={url} />
  )
}
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <WebOnboarding
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable && !this.state.openOneScreenOnboarding}
        />
        <Box style={{ display: "flex", boxSizing: "border-box" }}>
          <SideBar navigation={this.props.navigation} isNavigation={this.state.logbookApplyButton} chnngesModal={this.chnngesModal} />
          <MainContainerbox>
            <LogHeaderDiv>
              <WebHeader title={"Log Book"} caladerref={this.onboardingRefs[0]} icon={logbookicon} iconTwo={logbbokiImage} webHeaderCelender={this.handlelogbookDatePicker} openOnBoarding={this.handleOpneOnboarding} />
            </LogHeaderDiv>
            <LogbookDataDiv>
                 {this.state.isLoading && <Loader />}
              <LogbookMainDiv>
                <DisclaimerDiv>
                  <span style={{ fontWeight: 600, fontStyle: "italic", color: "#1E293B" }}>Disclaimer: </span>
                  {configJSON.disclaimerText}
                </DisclaimerDiv>
                <LogbookMainDate>{this.state.logbookDate}</LogbookMainDate>
              </LogbookMainDiv>

              <MainDivactivityMood>
                <div>
                  <LogBookActivity>
                    <AddPhysicalTittle>
                      Add your physical activity
                    </AddPhysicalTittle>
                    <PhysicalBox className={this.getDivsClassNames()}>
                      {this.state.activitydata?.map((activity:{id: number,activity_name: string,activity_icon: string,selected: boolean}, indno: number) => {
                        return (
                          <PhyButton key={indno} data-testid="activity_select" onClick={() => { this.activitySelected(activity.id) }} style={this.activeStyleBorder(activity.id)}>
                            <PhysicalButton className={this.activityButtons(activity.id)}>
                            {this.activityImage(activity.id)}
                              <img  src={activity.activity_icon} />
                            </PhysicalButton>
                            <PhysicalTittle  ref={activity.activity_name === "Cycling" ? this.onboardingRefs[1] : null} >{activity.activity_name}</PhysicalTittle>
                          </PhyButton>
                        )
                      })}
                    </PhysicalBox>
                    {this.state.isActivityAdded && (<div>
                      <StyledPaperNewFirst>
                        <StyledInputBaseNew
                          data-testid="activity_input"
                          onChange={this.inlinehandleActivityChange}
                          value={this.state.addNewActivities.join(', ')}
                        />
                        <img src={addactivityRemove} data-testid="activity_displayremove" onClick={this.renderActivityRemoveValue} style={{ padding: "17px 22px 17px 0px", cursor: "pointer" }} />
                      </StyledPaperNewFirst>
                    </div>
                    )}
                    <Modal
                      open={this.state.isAddingActivity}
                      onClose={this.completeClosed}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableEnforceFocus
                      disableAutoFocus
                      disableBackdropClick
                    >
                      <AddactivityModal>
                        <Box style={webStyle.numberBox}>
                          <Box style={{ fontSize: '20px', fontWeight: '600', fontFamily: "Raleway", color: "#0F172A" }}>Add Activity</Box>
                          <Box>
                            <img src={addactivityClosed} data-testid="activity_moal_closed" onClick={this.completeClosed} style={{ cursor: "pointer" }} />
                          </Box>
                        </Box>
                        <StyledPaperNew>
                          <StyledInputBaseNew
                            placeholder="Enter your activities"
                            onChange={this.handleActivityChange}
                            value={this.state.activitiesModelValue}
                          />
                          <img src={addactivityRemove} data-testid="remove_activity" onClick={this.addactRemoveValue} style={{ padding: "17px 22px 17px 0px", cursor: "pointer" }} />
                        </StyledPaperNew>
                        <AddactivityButton disabled={this.addActivityDisable() === "disable_button"} className={this.addActivityDisable()} data-testid="add_actdata" onClick={this.addActivity}>Add</AddactivityButton>
                      </AddactivityModal>
                    </Modal>
                  </LogBookActivity>

                  <LogBookActivity>
                    <AddPhysicalTittle>
                      Select your mood
                    </AddPhysicalTittle>
                    <PhysicalBox className={this.getDivsClassNames()}>
                      {this.state.moodsdata?.map((moods:{id: number,mood_name: string,mood_icon: string,selected: boolean}, indnum: number) => {return(
                        <PhyButton key={indnum} data-testid="activity_mood" onClick={() => this.handleMoodsSelect(moods.id)}>
                          <PhysicalButton className={moods.selected  ? 'mood-selected' : 'mood-notselected'}>
                            {moods.selected && (
                              <img style={{ position: "absolute", right: "0px", bottom: "1px" }} src={borderRight} />
                            )}
                            <img style={{height:"56px",width:"56px"}}src={moods?.mood_icon} />
                          </PhysicalButton>
                          <PhysicalTittle ref={moods.mood_name === "Loved" ? this.onboardingRefs[2] : null} >{moods.mood_name}</PhysicalTittle>
                        </PhyButton>
                      )})}
                    </PhysicalBox>
                  </LogBookActivity>
                  <PhysicalTwoBoxDiv>
                    <LogYourSymptomsItem>
                      <AddPhysicalTittle>
                        Log your symptoms
                      </AddPhysicalTittle>
                      <LogyoursymptomsDiv className={`${this.getDivsClassNames()} eventListDiv`}>
                        <NoAddSymptomsDiv className={this.noSymDisable("Add Symptoms")} data-testid="add_symptos" onClick={() => { this.noSymptos("No Symptoms") }}>
                          {this.getSymRight()}
                          <NoAddSymptomsImageDiv>
                            <img src={noSymptoms} ></img>
                          </NoAddSymptomsImageDiv>
                          <NoAddSymptomsTextDiv>No Symptoms</NoAddSymptomsTextDiv>
                        </NoAddSymptomsDiv>
                        <NoAddSymptomsDiv className={this.noSymDisable("No Symptoms")} data-testid="no_symptos" onClick={() => this.addSymptosdataSet("Add Symptoms")}>
                          {this.getAddSymRight()}
                          <NoAddSymptomsImageDiv ref={this.onboardingRefs[3]}>
                            <img src={adddisableSymptoms} ></img>
                          </NoAddSymptomsImageDiv>
                          <NoAddSymptomsTextDiv >Add Symptoms</NoAddSymptomsTextDiv>
                        </NoAddSymptomsDiv>
                        {this.state.symptosData?.map((element, indno) => (
                          <>
                            {element !== "No Symptoms" && element !== "Add Symptoms" &&
                              <NoAddSymptomsDiv key={indno} style={this.getBorderStyle(indno)} data-testid="add_symptosmore" onDoubleClick={() => this.handleRemoveSym(indno)}>
                                {this.state.symptElementIndex === indno ? (
                                  <img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} data-testid="removesym" onClick={this.symptosRemoveData} src={symptoClosed} />
                                ) : (<img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} src={symptoRight} />)}
                                <NoAddSymptomsImageDiv style={this.symptosErrorImage(indno)}>
                                  <img src={thermostatImage} ></img>
                                </NoAddSymptomsImageDiv>
                                <NoAddSymptomsTextDiv style={this.symptosErrorText(indno)}>{element}</NoAddSymptomsTextDiv>
                              </NoAddSymptomsDiv>
                            }
                          </>
                        ))}
                      </LogyoursymptomsDiv>
                      {this.state.symptosData.includes("Add Symptoms") && (
                        <AddSymptos>
                          <TextField
                            data-testid="addsymptoms"
                            InputProps={{ disableUnderline: true, style: stylesTextfiled.input }}
                            placeholder="Add your symptoms"
                            FormHelperTextProps={{ style: { marginLeft: "0px", fontFamily: "Raleway", } }}
                            onChange={this.symptosValue}
                            value={this.state.newSymptos}
                          />
                          <StyledButton data-testid="addsymptos_button" onClick={this.addSymptos} variant="contained">
                            Add
                          </StyledButton>
                        </AddSymptos>
                      )}

                    </LogYourSymptomsItem>
                    <LogYourSymptomsItem>
                      <AddYourTreatmentPlus data-testid="addplus" onClick={this.plusTreat}>
                        <AddPhysicalTittle>
                          Add your Treatment
                        </AddPhysicalTittle>
                        <AddYourTreatmentPlusImage >
                          <img src={plusTreatment} ></img>
                        </AddYourTreatmentPlusImage>
                      </AddYourTreatmentPlus>
                      <span ref={this.onboardingRefs[4]}></span>
                      {!!this.state.combineTreatmentData.length && (
                        <AddTretmainReminderDiv className="eventListDiv">
                          {this.state.combineTreatmentData
                            .filter((value: TretReminder) => value.attributes.status === 'active')
                            .map((value: TretReminder) => {
                              return value.attributes.reminder_times.data?.map((treatele: ReminderTime, indno: number) => {
                                return (
                                  <AddTreatData data-testid="takenreminder"
                                  onDoubleClick={() => this.handleDoubleClick(treatele.attributes.id)} className={`${this.formulaBorder(value.attributes.treatment_type)} ${this.takenBackground(value.attributes.treatment_type,treatele.attributes.status)}`} key={indno}>
                                    <AddtretCap className={this.formulaBorder(value.attributes.treatment_type)}>
                                      <img src={addTretImage} alt="add-treatment" />
                                    </AddtretCap>
                                    <AddTretText className={this.formulaBorder(value.attributes.treatment_type)}>{value.attributes.treatment_name} {'\u00B7'} {treatele.attributes.time}</AddTretText>
                                   {this.rightImageTretment(treatele.attributes.status,value.attributes.treatment_type)} 
                                  </AddTreatData>
                                )
                              });
                            })}
                        </AddTretmainReminderDiv>
                      )}
                      <ManageRemindersDiv data-testid="reminder_modalopen" onClick={this.manageReminderModal}>
                        <AddYourTreatText>Manage Reminders</AddYourTreatText>
                        <AddYourTreatRemindersImage>
                          <img src={remindersIcon} ></img>
                        </AddYourTreatRemindersImage>
                      </ManageRemindersDiv>
                    </LogYourSymptomsItem>
                    <Modal
                      disableAutoFocus
                      disableEnforceFocus
                      open={this.state.treatmentPlusImage}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableBackdropClick
                    >
                          <AddTreatmentMainBox >
                          <AddTreatmentMainDiv>
                            <TitleAddTreatment>Add Treatment</TitleAddTreatment>
                            <img src={addactivityClosed} style={{ cursor: "pointer" }} data-testid="closed_tretmodal" onClick={this.addTretmentModelClosed} />
                          </AddTreatmentMainDiv>
                          <AddTreatInput data-testid="tretName" placeholder="Treatment Name" disableUnderline={true} name="treatmentname" onChange={this.handleChangeValue} />
                          <Divider style={{ color: '#F1F5F9', width: "100%", position: "absolute", left: 0 }} />
                          <AddTreaactivityadd>
                            <MedicationSelect>
                              <div>Treatment Type</div>
                              <MedicationDataSelect
                                className="selectInputValue"
                                data-testid="demoSelect"
                                name="type"
                                onChange={this.handleChangeValue}
                                id="demo-simple-select-helper"
                                labelId="demo-simple-select-helper-label"
                                value={this.state.treatmentData.type}
                                inputProps={{
                                  className: "select_inputpadding",
                                }}
                                open={this.state.selectMenu === "type"}
                                onOpen={this.setMenus}
                                onClose={this.closedMenuTime}
                                IconComponent={() => (
                                  <img src={selectImage} style={{ cursor: "pointer" }} data-testid="selectMenu" onClick={this.setMenus} />
                                )}
                                disableUnderline={true}
                                MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-64px", width: "160px" } }, className: "select-input-custom" }}
                              >
                                {
                                  ["Medication", "Formula"].map((item, indexnum, array) => (
                                    <MenuItem
                                      className="Medication"
                                      style={{ ...webStyle.menuSelect,borderBottom: indexnum === array.length - 1 ? "none" : "1px solid #E2E8F0" }}
                                      key={item}
                                      value={item.charAt(0).toLowerCase() + item.slice(1)}>
                                      {item}
  
                                    </MenuItem>
                                  ))}
  
                              </MedicationDataSelect>
                            </MedicationSelect>
                            <MedicationSelect>
                              <div>Times taken per day</div>
                              <MedicationDataSelect
                                labelId="demo-simple-select-helper-label"
                                value={this.state.treatmentData?.reminders?.length}
                                onChange={this.handleChangeTreatData}
                                data-testid="addReminderCount"
                                name="reminderno"
                                disableUnderline={true}
                                id="demo-simple-select-helper"
                                open={this.state.selectMenu === "time"}
                                onOpen={this.setMenusTime}
                                onClose={this.closedMenuTime}
                                inputProps={{
                                  className: "select_inputpaddingnumber",
                                }}
                                IconComponent={() => (
                                  <img src={selectImage} style={{ cursor: "pointer" }} data-testid="addreminSelect" onClick={this.setMenusTime} />
                                )}
                                MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-20px", width: "71px",height:"78.5px" } }, className: "select-input-custom" }}
                              >
                                {[1, 2, 3, 4, 5].map((item, indno, array) => (
  
                                  <MenuItem
                                    value={item}
                                    key={item}
  
                                    style={{ ...webStyle.TimesTakesDays, borderBottom: indno === array.length - 1 ? "none" : "1px solid #E2E8F0" }}
                                  >
                                    <div style={{ textAlign: "center", width: "100%" }}>{item}</div>
                                  </MenuItem>
  
                                ))}
  
                              </MedicationDataSelect>
                            </MedicationSelect>
                            {this.state.treatmentData?.reminders?.map((item: { name?: string, time?: string }, indno: number) =>
                              <MedicationSelect>
                                <div>{item.name}</div>
                                <MedicationDataSelect
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  name='reminder'
                                  onChange={(event: React.ChangeEvent<{ value: unknown | string }>) => this.addTimeTreat(event.target.value as string, indno)}
                                  data-testid={`addRemindermulti-${indno}`}
                                  value={item?.time}
                                  className="selectInput"
                                  disableUnderline={true}
                                  inputProps={{
                                    className: "select_inputpadding",
                                  }}
                                  open={this.state.selectMenu === `timereminder${indno}`}
                                  onOpen={() => this.selectTimereminder(indno) }
                                  onClose={this.closedMenuTime}
                                  MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-43px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
                                  IconComponent={() => (
                                    <img src={selectImage} data-testid="addtimerem" style={{ cursor: "pointer" }} onClick={() => this.selectTimereminder(indno) } />
                                  )}
                                >
                                  {this.combineData(item).map((items:string, indno:number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          borderBottom: indno === (12 * 24 - 1) ? "none" : "1px solid #0000001c",
                                        }}
                                        key={indno}
                                        value={items}
                                      >
                                        {items}
                                      </MenuItem>
                                    );
                                  })}
  
                                </MedicationDataSelect>
                              </MedicationSelect>)}
                              <Dateselect>
                              <MedicationSelect>
                                <div>Start Date</div>
                              <MuiPickersUtilsProvider utils={MomentUtils} >
                                <StartDateCalendar
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  format="DD MMM, YYYY"
                                  margin="normal"
                                  inputProps={{readOnly: true,'data-testid':"add_reminder_startDate" }}
                                  value={this.state.treatmentData?.date || new Date()}
                                  onChange={(date) => this.handleChangeStartDate(date, 0)}
                                  minDate={this.state.pastDate}
                                  InputProps={{
                                    id:"teststart",
                                    disableUnderline: true,
                                    style:{ fontFamily:"Raleway",fontWeight:500,fontSize:"12px",color:"#334155",paddingLeft: "12px"},
                                  }}
                                  keyboardIcon={<img src={selectImage} alt="calendar_startdate" />
                                  }
                                />
                              </MuiPickersUtilsProvider>
                               
                              </MedicationSelect></Dateselect>
                            <MedicationSelect>
                              <div>Intake end date</div>
                              <div>
                                <AntintakeSwitch name="checkedC" checked={this.state.intakeDatatoogle} data-testid="checkBox" onClick={(event: React.MouseEvent<HTMLElement>) => this.intakelDtaToogledata(event, "add")} className="toggle_button" />
                              </div>
                            </MedicationSelect>
                            {this.state.intakeDatatoogle === true && <Dateselect>
                              <MedicationSelect>
                                <div>Select Date</div>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                <StartDateCalendar
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  format="DD MMM, YYYY"
                                  margin="normal"
                                  inputProps={{readOnly: true,'data-testid':"selectInputTyperem"  }}
                                  value={this.state.treatmentData?.end_time}
                                  minDate={this.state.treatmentData?.date}
                                  onChange={(date) => this.handleChangeINtakelDate(date, 0)}
                                  InputProps={{
                                    disableUnderline: true,
                                    style:{ fontFamily:"Raleway",fontWeight:500,fontSize:"12px",color:"#334155",paddingLeft: "12px"},
                                  }}
                                  keyboardIcon={<img src={selectImage} alt="calendar_startdate" />
                                  }
                                />
                              </MuiPickersUtilsProvider>
                                
                              </MedicationSelect></Dateselect>}
                          </AddTreaactivityadd>
                          <SavetretmentButton
                            disabled={this.validationButton() === "disableTeat"}
                            onClick={this.saveAddTretment}
                            className={this.validationButton()}
                            data-testid="saveAddTretment_dataremin"
                          >Save</SavetretmentButton>
                        </AddTreatmentMainBox>
                    </Modal>
                    <Modal
                      open={this.state.manageReminder}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableEnforceFocus
                      disableAutoFocus
                      disableBackdropClick
                    >
                      <AddTreatmentMainBox >
                        <AddTreatmentMainDiv>
                          <TitleAddTreatment>Reminders</TitleAddTreatment>
                          <img src={addactivityClosed} data-testid="Reminder_closed" style={{ cursor: "pointer" }} onClick={this.manageReminderClosedModal} />
                        </AddTreatmentMainDiv>
                        {this.state.combineTreatmentData?.length ?
                          <AddTretCombine className="eventListDiv">
                            <AddTreatReminder>
                              {this.state.combineTreatmentData?.map((reminde, indno) => {
                                return <>
                                  <ReminderSelect key={indno}>
                                    <div data-testid="edit_rem_select" style={{ fontFamily: "Raleway", fontSize: "16px", cursor: "pointer", fontWeight: 500, lineHeight: "24px", color: "#151515" }} onClick={() => this.remnderEdit(reminde.id)} >{reminde.attributes.treatment_name}</div>
                                    <div>
                                      <AntintakeSwitchTwo checked={reminde.attributes.status === "active"} name="checkedC" onClick={() => this.remnderStatusPutApi(reminde.id)} className="toggle_button" id="swith" data-testid="user_statusadd" />
                                    </div>
                                  </ReminderSelect>
                                  <div>
                                    {!!reminde.attributes.status && reminde.attributes.status === 'active' && (
                                      reminde.attributes.reminder_times.data
                                        ?.sort((sorta, sortb) => {
                                          const sortaName = sorta.attributes.name ?? '';
                                          return sortaName.localeCompare(sortb.attributes.name ?? '');
                                        })
                                        .map((addtret: ReminderTime, idxnum: number) => (
                                          <div key={idxnum} style={{ display: "flex", flexDirection: "column", height: "40px", justifyContent: "space-around" }}>
                                            <div>
                                              <Typography style={{ fontFamily: "Raleway", fontSize: "14px", fontWeight: 450, lineHeight: "20px", color: "#57534E" }}>{addtret.attributes.name} {'\u00B7'} {addtret.attributes.time}</Typography>
                                            </div>
                                            <div>
                                              {(reminde.attributes.reminder_times.data.length - 1 !== idxnum) && (<Divider style={{ color: '#F1F5F9', width: "302px" }} />)}
                                            </div>
                                          </div>
                                        ))
                                    )}
                                  </div>
                                </>
                              })}
                            </AddTreatReminder>
                          </AddTretCombine>
                          : <NoreminderDiv>
                            <img src={noreminderImage} />
                            <NoreminderTextBox>
                              <NoreminderTextone>You have no reminders set up</NoreminderTextone>
                              <NoreminderTextwo>Start managing your medication schedule by</NoreminderTextwo>
                              <NoreminderTextwo>adding a new reminder.</NoreminderTextwo>
                            </NoreminderTextBox>
                          </NoreminderDiv>
                        }
                        <div style={{display:"flex",justifyContent:"end"}}>
                        <ReminderPlusAdd onClick={this.addTretModalopen}>
                        <img src={reminderPlustre}></img>
                        </ReminderPlusAdd>
                        </div>
                      </AddTreatmentMainBox>
                    </Modal>
                    <Modal
                      open={this.state.editModalReminder}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      disableBackdropClick
                      disableEnforceFocus
                      disableAutoFocus
                    >
                      <>
                      {this.state.initialSingalReminder?.map((editremin,indkey) =>{
                        return(
                          <AddTreatmentMainBox key={indkey}>
                          <AddTreatmentMainDiv>
                            <TitleAddTreatment>Reminders</TitleAddTreatment>
                            <img src={addactivityClosed} style={{ cursor: "pointer" }} onClick={this.addTretmentModelClosed} />
                          </AddTreatmentMainDiv>
                          <AddTreatInput placeholder="Treatment Name" value={editremin.attributes.treatment_name} disableUnderline={true} name="treatment_name" onChange={this.handleEditChangeValue} />
                          <Divider style={{ color: '#F1F5F9', width: "100%", position: "absolute", left: 0 }} />
                          <AddTreaactivityadd>
                            <MedicationSelect>
                              <div>Treatment Type</div>
                              <MedicationDataSelect
                                className="selectInputValue"
                                data-testid="demoSelect"
                                onChange={this.handleEditChangeValue}
                                name="treatment_type"
                                id="demo-simple-select-helper"
                                value={editremin.attributes.treatment_type}
                                labelId="demo-simple-select-helper-label"
                                inputProps={{
                                  className: "select_inputpadding",
                                }}
                                open={this.state.selectMenu === "type"}
                                onClose={this.closedMenuTime}
                                onOpen={this.setMenus}
                                IconComponent={() => (
                                  <img src={selectImage} style={{ cursor: "pointer" }} data-testid="selectMenu" onClick={this.setMenus} />
                                )}
                                disableUnderline={true}
                                MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-64px", width: "160px" } }, className: "select-input-custom" }}
                              >
                                {
                                  ["Medication", "Formula"].map((item, indexnum, array) => (
                                    <MenuItem
                                      className="Medication"
                                      style={{ ...webStyle.menuSelect,borderBottom: indexnum === array.length - 1 ? "none" : "1px solid #E2E8F0" }}
                                      key={item}
                                      value={item.charAt(0).toLowerCase() + item.slice(1)}>
                                      {item}
  
                                    </MenuItem>
                                  ))}
  
                              </MedicationDataSelect>
                            </MedicationSelect>
                            <MedicationSelect>
                              <div>Times taken per day</div>
                              <MedicationDataSelect
                                labelId="demo-simple-select-helper-label"
                                onChange={this.handleEditChangeTreatData}
                                value={editremin.attributes?.times_taken_per_day}
                                name="times_taken_per_day"
                                data-testid="editaddReminderCount"
                                disableUnderline={true}
                                id="demo-simple-select-helper"
                                onOpen={this.setMenusTime}
                                open={this.state.selectMenu === "time"}
                                onClose={this.closedMenuTime}
                                inputProps={{
                                  className: "select_inputpaddingnumber",
                                }}
                                MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-20px", width: "71px",height:"78.5px" } }, className: "select-input-custom" }}
                                IconComponent={() => (
                                  <img src={selectImage} style={{ cursor: "pointer" }} data-testid="edit_rem_perday" onClick={this.setMenusTime} />
                                )}
                              >
                                {[1, 2, 3, 4, 5].map((item, indno, array) => (
  
                                  <MenuItem
                                    value={item}
                                    key={item}
                                    style={{ ...webStyle.TimesTakesDays, borderBottom: indno === array.length - 1 ? "none" : "1px solid #E2E8F0" }}
                                  >
                                    <div style={{ textAlign: "center", width: "100%" }}>{item}</div>
                                  </MenuItem>
  
                                ))}
  
                              </MedicationDataSelect>
                            </MedicationSelect>
                              {editremin.attributes.reminder_times?.data?.map((item:ReminderTime, indno: number) => {
                                if (!item.attributes._destroy) {
                                return (<MedicationSelect>
                                  <div>{item.attributes?.name}</div>
                                  <MedicationDataSelect
                                    id="demo-simple-select-helper"
                                    labelId="demo-simple-select-helper-label"
                                    name='reminder'
                                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) => this.addTimeEditTreat(event.target.value as string, indno)}
                                    value={item.attributes?.time}
                                    data-testid="selectedittime"
                                    className="selectInput"
                                    inputProps={{
                                      className: "select_inputpadding",
                                    }}
                                    disableUnderline={true}
                                    onOpen={() => this.selectTimereminder(indno)}
                                    open={this.state.selectMenu === `timereminder${indno}`}
                                    onClose={this.closedMenuTime}
                                    MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-43px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
                                    IconComponent={() => (
                                      <img src={selectImage} data-testid="edit_addtimerem" style={{ cursor: "pointer" }} onClick={() => this.selectTimereminder(indno)} />
                                    )}
                                  >
                                    {this.editTakeFilterTime(editremin.attributes.date, editremin.attributes.intake_end_date, item).map((items:string) => {
                                
                                   return (
                                        <MenuItem
                                          style={{
                                            borderBottom: indno === (12 * 24 - 1) ? "none" : "1px solid #0000001c"
                                          }}
                                          key={indno}
                                          value={items}
                                        >
                                          {items}
                                        </MenuItem>
                                      );
                                    })}
                                  </MedicationDataSelect>
                                </MedicationSelect>
                                )}
                              })}
                              <Dateselect>
                              <MedicationSelect>
                                <div>Start Date</div>
                                  <EditDateinaddReminder>{this.state.moments(editremin?.attributes?.date, 'DD-MM-YYYY').format('DD MMM, yyyy')}
                                    <img src={selectImage} />
                                  </EditDateinaddReminder>
                              </MedicationSelect></Dateselect>
                            <MedicationSelect>
                              <div>Intake end date</div>
                              <div>
                                <AntintakeSwitch name="checkedC" checked={editremin?.attributes?.intake_end_date} data-testid="edit_checkBox" onClick={this.editinTakedate} className="toggle_button" />
                              </div>
                            </MedicationSelect>
                            {editremin?.attributes?.intake_end_date === true && <Dateselect>
                              <MedicationSelect>
                                <div>Select Date</div>
                              <MuiPickersUtilsProvider utils={MomentUtils} >
                                                               <StartDateCalendar
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  margin="normal"
                                  format="DD MMM, YYYY"
                                  inputProps={{readOnly: true,'data-testid':"editSelect_date"  }}
                                  value={this.formatEditDate(editremin?.attributes?.date, editremin?.attributes?.end_time)}
                                  minDate={this.state.moments(editremin?.attributes?.date, 'DD-MM-YYYY').format('DD MMM, yyyy')}
                                  onChange={(date) => this.handleChangeEndtakelDate(date, 0)}
                                  InputProps={{
                                    className:"hovermouse",
                                    disableUnderline: true,
                                    style:{ fontFamily:"Raleway",fontWeight:500,fontSize:"12px",color:"#334155",paddingLeft: "12px"},
                                  }}
                                  keyboardIcon={<img src={selectImage} alt="calendar_startdate" />
                                  }
                                />
                              </MuiPickersUtilsProvider>
                              </MedicationSelect></Dateselect>}
                          </AddTreaactivityadd>

                          <div style={{display:"flex"}}>
                          <EditDeletetretmentButton data-testid='delete_modalopen' onClick={this.deleteModalOpen}>
                          Delete
                          </EditDeletetretmentButton>
                                                    <EditSavetretmentButton
                           disabled={this.validationEditModelButton()=== "disableTeat"}
                            className={this.validationEditModelButton()}
                            onClick={this.saveEditdata}
                            data-testid="save_editreminderdataremin"
                          >Save</EditSavetretmentButton>
                          </div>
                          
                        </AddTreatmentMainBox>
                        )
                      } )}
                        </>
                    </Modal>
                  </PhysicalTwoBoxDiv>
                </div>
                <UpcomingEvents>
                  <UpcomingEventsText>Upcoming Events</UpcomingEventsText>
                  {this.state.upcomingEvent ?
                    <>
                      <IncomingMaindiv className="eventListDiv">
                         
                        {this.state.upcomingEvent?.map((eventdata, indno) =>(
                          
                          <IncomingScrolldiv data-testid="navigationEvent" onClick={() => this.getNavigationEvent(this.props)} key={indno}>
                            <div>
                              <div style={{ fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>{eventdata.attributes.title}</div>
                              <IncomingLocationDiv >
                                <IncomingImagesSet><img src={upcomingTimeImage} /></IncomingImagesSet>
                                <IncomingTimedv style={{ [this.state.styCss]: "anywhere" }}>{this.formatDateString(eventdata.attributes.start_time, eventdata.attributes.end_time)}</IncomingTimedv>
                                <span  ref={this.onboardingRefs[6]} ></span>
                              </IncomingLocationDiv>
                              {eventdata.attributes.address &&
                                <IncomingTimeDiv>
                                  <IncomingImagesSet><img src={upcomingLocationImage} /></IncomingImagesSet>
                                  <IncomingLocationTimedv style={{ [this.state.styCss]: "anywhere" }}>{eventdata.attributes.address}</IncomingLocationTimedv>
                                </IncomingTimeDiv>}
                            </div>
                            {eventdata.attributes.notes &&
                              <div style={{ paddingTop: "8px", marginTop: "12px", borderTop: "1px solid #E2E8F0" }}>
                                <IncomingDiscription>Description</IncomingDiscription>
                                <IncomingDiscriptionSubdiv style={{ [this.state.styCss]: "anywhere" }}>{eventdata.attributes.notes}</IncomingDiscriptionSubdiv>
                              </div>
                              }

                          </IncomingScrolldiv>
                        ))}
                      </IncomingMaindiv>
                    </>
                    :
                    <UpcomingEventsInnerBOX>
                      <UpcomingEventsImageDiv>
                        <div><img src={upcomingevent} ></img></div>
                        <EventsImageButtomText ><span ref={this.onboardingRefs[6]}>{configJSON.noEventText}</span></EventsImageButtomText>
                      </UpcomingEventsImageDiv>
                    </UpcomingEventsInnerBOX>}
                </UpcomingEvents>
              </MainDivactivityMood>

              <ReportdMainDiv>
                <ReportdMainText ref={this.onboardingRefs[5]} >Reports</ReportdMainText>
                {this.state.reportsData ?
                  <ReportScrollDiv className="eventListDiv">
                    {this.state.reportsData?.map((reportvalue, indno) => (
                      <ReportBoxDiv data-testid="navigationReport"  onClick={() => this.getNavigationReport(this.props)} key={indno}>
                        <ReportTittleDiv>
                          <ReportTittleText>{reportvalue?.attributes.report_name}</ReportTittleText>
                          <ReportTittleDate>{new Date(reportvalue?.attributes.report_date).toLocaleDateString('en-GB')}</ReportTittleDate>
                        </ReportTittleDiv>
                        {reportvalue.attributes.report_notes && <ReportNoteDiv >
                          <ReportNoteTittle>Notes</ReportNoteTittle>
                          <ReportNoteTittleTwo>{reportvalue?.attributes.report_notes}</ReportNoteTittleTwo>
                        </ReportNoteDiv>}
                        {reportvalue.attributes?.attached_files?.data?.map((value: { attributes: { file: string } }, indno: number) => {
                          return (<ReportPdfDiv style={{ [this.state.styCss]: "anywhere" }}>
                            <ReportPdfImageDiv> <img src={value.attributes.file.split('.').pop() === 'pdf' ? pdfImage : reportImage} /></ReportPdfImageDiv>
                            <ReportPdfTextDiv>{value.attributes.file.split('/').pop()}</ReportPdfTextDiv>
                          </ReportPdfDiv>)
                        })}
                      </ReportBoxDiv>
                    ))}
                  </ReportScrollDiv> : <div>
                    <div style={{ padding: "24px 0px 12px 0px" }}><img src={noreportImage} /></div>
                    <div style={{ fontWeight: 500, color: "#1E293B", fontSize: "14px", lineHeight: "22px", fontFamily: "Raleway" }}>No Reports Found</div>
                  </div>}
              </ReportdMainDiv>

              <div style={{ padding: "44px 0px 40px 0px" }}>
                <LogbookApplyButton data-testid="logbook_Apply" disabled={this.state.logbookApplyButton} className={this.applybuttonColor()} onClick={this.applyCallputButton}>Apply</LogbookApplyButton>
              </div>

               <p onClick={this.saveChangesModal} data-testid="hendalSave_modal" style={{display:"none"}}>SaveChanges modal</p> 

              <div>
                {this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true && (
                <Modal
                  open={this.state.editEventSave}
                  onClose={this.logBookDataClosedmodal}
                  aria-labelledby="modal-modal-title"
                  disableAutoFocus
                  disableEnforceFocus
                  aria-describedby="modal-modal-description"
                  disableBackdropClick
                >
                  <LogbookDataSave sx={{ boxShadow: 24 }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <img  onClick={this.logBookDataClosedmodal} style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} src={saveDataClosed} />
                    </div>
                    <LogbookEventEditsaveText>Save Recurring Event</LogbookEventEditsaveText>
                    <SaveCheckedModal>
                      <FormControlLabel
                        data-test-id="radioButton"
                        value="this_reminder_only"
                        control={<Radio
                          color="default"
                          id="legend"
                          checkedIcon={<RedioIconset url={saveDataChecked} />}
                          icon={<RedioIconset url={saveDataunChecked} />}
                        checked={this.state.dateEventSave === "this_reminder_only"} 
                        />}
                        label={<EventTextModal>This event</EventTextModal>}
                        onClick={this.deleteRadioselectone}
                      />

                      <FormControlLabel
                        value="this_and_following_reminders"
                        control={<Radio
                          color="default"
                          id="legend"
                          checkedIcon={<RedioIconset url={saveDataChecked} />}
                          icon={<RedioIconset url={saveDataunChecked} />}
                        checked={this.state.dateEventSave === "this_and_following_reminders"} 
                        />}
                        label={<EventTextModal>This and future events</EventTextModal>}
                        onClick={this.deleteRadioselecttwo}
                      />
                    </SaveCheckedModal>
                    <EventEditSaveButton>
                      <DiscardChangeButt data-testid="Discard_Apply" onClick={this.logBookDataClosedmodal}>Close</DiscardChangeButt>
                      <SaveChange data-testid="Save_Apply" onClick={this.saveEditdataPut}>Save</SaveChange>
                    </EventEditSaveButton>
                  </LogbookDataSave>
                </Modal>
                )}
              </div>             

              <div>
                <Modal
                  open={this.state.dataSavelogbook}
                  onClose={this.logBookDataClosedmodal}
                  aria-labelledby="modal-modal-title"
                  disableEnforceFocus
                  disableAutoFocus
                  aria-describedby="modal-modal-description"
                  disableBackdropClick
                >
                  <LogbookDataSave sx={{ boxShadow: 24 }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <img data-testid="logbooksaveclosed" onClick={this.logBookDataClosedmodal} style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} src={saveDataClosed} />
                    </div>
                    <LogbookDatasaveText>Keep Changes?</LogbookDatasaveText>
                    <LogbookDatasaveTextTwo>You have made changes to the logbook. Do you want to save them before exiting?</LogbookDatasaveTextTwo>
                    <LogbookDatasaveDiv>
                      <DiscardChangeButt data-testid="Discard_Apply" onClick={() => this.discardChangeData("Discard Changes")}>Discard Changes</DiscardChangeButt>
                      <SaveChange data-testid="Save_Apply" onClick={() => this.discardChangeData("Save Changes")}>Save Changes</SaveChange>
                    </LogbookDatasaveDiv>
                  </LogbookDataSave>
                </Modal>
              </div>

              <div>
                <Modal
                  open={this.state.apply}
                  onClose={this.applyModalclosed}
                  disableEnforceFocus
                  disableAutoFocus
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableBackdropClick
                >
                  <Apply_modalBox sx={{ boxShadow: 24 }}>
                    <ApplyClicktextOne>Logbook details saved successfully!</ApplyClicktextOne>
                    <ApplyClicktextTwo>Your information is now up to date.</ApplyClicktextTwo>
                    <ApplymodalClosed data-testid="closed_modalapply" onClick={() => this.applyModalclosed(this.props)}>Close</ApplymodalClosed>
                  </Apply_modalBox>
                </Modal>
              </div>

              <div>
                {this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true && (
              <Modal
                  open={this.state.deleateModal}
                  onClose={this.deleteModalClosed}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableEnforceFocus
                  disableAutoFocus
                  disableBackdropClick
                >
                  <LogbookDataSave sx={{ boxShadow: 24 }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <img  onClick={this.deleteModalClosed} style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} src={saveDataClosed} />
                    </div>
                    <LogbookEventEditsaveText>Delete Event</LogbookEventEditsaveText>
                    <SaveCheckedModal>
                      <FormControlLabel
                        value="this_reminder_only"
                        data-test-id="radioButton"
                        control={<Radio
                          color="default"
                          id="legend"
                          checkedIcon={<RedioIconset url={saveDataChecked} />}
                          icon={<RedioIconset url={saveDataunChecked} />}
                        checked={this.state.dateEventSave === "this_reminder_only"} 
                        />}
                        label={<EventTextModal>This event</EventTextModal>}
                        onClick={this.deleteRadioselectone}
                      />

                      <FormControlLabel
                        value="this_and_following_reminders"
                        control={<Radio
                          color="default"
                          id="legend"
                          checkedIcon={<RedioIconset url={saveDataChecked} />}
                          icon={<RedioIconset url={saveDataunChecked} />}
                          checked={this.state.dateEventSave === "this_and_following_reminders"} 
                        />}
                        label={<EventTextModal>This and future events</EventTextModal>}
                        onClick={this.deleteRadioselecttwo}
                      />
                    </SaveCheckedModal>
                    <EventEditSaveButton>
                      <DiscardChangeButt data-testid="Discard_Apply" onClick={this.deleteModalClosed}>Close</DiscardChangeButt>
                      <SaveDeletechange data-testid="Save_Apply" onClick={this.deleteReminderApi}>Delete</SaveDeletechange>
                    </EventEditSaveButton>
                  </LogbookDataSave>
                </Modal>)}
              </div>
            </LogbookDataDiv>


          </MainContainerbox>
        </Box>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  menuSelect:{fontFamily: "Raleway",fontSize: "14px",fontWeight: 500,lineHeight: "22px"},
  numberBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  TimesTakesDays: {justifyContent: 'center !important',fontFamily: "Raleway",fontSize: "14px",fontWeight: 500,minHeight:"36px" },

}

const MainContainerbox = styled(Box)({
  width: "100%",
  fontFamily: "Raleway",
  justifyContent: "conter",
  justifyItems: "center",
  background: '#F4F4F4',
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
});

const LogHeaderDiv = styled('div')({
  background: "#FFFFFF",
  "& .icon": {
    width: "34px",
    height: "40px",
    marginRight: "18px"
  },
});

const LogbookDataDiv = styled('div')({
  maxWidth: "1128px",
  margin: "28px 28px",
});

const LogbookMainDiv = styled('div')({
  display: "flex",
  gap: "20px",
  "@media (min-width:1200px) and (max-width: 1310px)": {
    flexWrap: "wrap-reverse"
  },
  "@media (max-width: 1150px)": {
    flexWrap: "wrap-reverse"
  },
  "@media (max-width: 400px)": {
    width:"250px"
   },
  "@media (max-width: 374px)": {
   width:"200px"
  },
});


const DisclaimerDiv = styled('div')({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 400,
  fontStyle: "italic",
  color: "#1E293B",
  maxWidth: "954px",
  lineHeight: "24px",
});

const LogbookMainDate = styled('div')({
  alignItems: "center",
  display: "flex",
  fontSize: "24px",
  fontWeight: 700,
  color: "#475569",
  whiteSpace: "nowrap",
});

const MainDivactivityMood = styled('div')({
  display: "flex",
  gap: "24px",
  "@media (min-width:1200px) and (max-width: 1310px)": {
    flexWrap: "wrap-reverse",
  },
  "@media (max-width: 1150px)": {
    flexWrap: "wrap-reverse"
  },
  "@media (max-width: 450px)": {
    width:"240px"
  },
});

const LogBookActivity = styled(Box)({
  maxWidth: "744px",
  border: "1px solid #F1F5F9",
  background: "white",
  borderRadius: "24px",
  marginTop: "32px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  boxSizing: "border-box",
  "@media (max-width: 880px)": {
    width:"600px"
   },
  "@media (max-width: 710px)": {
   width:"550px"
  },
  "@media (max-width: 665px)": {
    width:"460px"
   },
   "@media (max-width: 575px)": {
    width:"400px"
   },
   "@media (max-width: 510px)": {
    width:"330px"
   },
   "@media (max-width: 450px)": {
    width:"270px"
   },
   "@media (max-width: 385px)": {
    width:"200px"
   },
});

const AddPhysicalTittle = styled('div')({
  fontWeight: 600,
  lineHeight: "24px",
  fontSize: "16px"
});

const PhysicalBox = styled('div')({
  display: "flex",
  fontFamily: "Releway",
  height: "92px",
  gap: "24px",
  overflowY: "hidden",
  overflowX: 'auto',
  paddingBottom:"5px",

  "&::-webkit-scrollbar": {
    height:'4px',
    width: '4px',
  },
  "&::-webkit-scrollbar-track": {
    background: '#F1F5F9'
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: '8px',
    background: '#94A3B8',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: '#90A4AE',
  },
  "&::-webkit-scrollbar:vertical": {
    width: '4px',
  },

  "&.disableddiv": {
    cursor: 'not-allowed',
    pointerEvents:"none",
    opacity: "0.5",
  },
  "@media (max-width: 850px)": {
    gap: "15px"
  },
  "@media (max-width: 710px)": {
    gap: "24px",
    overflow:"auto",
  },
});

const PhyButton = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  textAlign: "center",
  width: "80px",
  height: "92px",
  cursor: "pointer",
  position: "relative"
});

const PhysicalButton = styled('div')({
  borderRadius: "50px",
  background: "#E0F3FF",
  height: "56px",
  width: "56px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",

  "&.selected": {
    border: "2px solid #13689E",
    opacity: "1",
  },
  "&.notSelected": {
    border: "2px solid transparent",
    opacity: "1",
  },
  "&.mood-selected": {
    border: "1.5px solid #13689E",
    padding: "1.5px",
  },
  "&.mood-notselected": {
    border: "1.5px solid transparent",
    padding: "1.5px",
  }
});

const PhysicalTittle = styled('div')({
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Raleway",
  whiteSpace:"nowrap",
});

const LogYourSymptomsItem = styled(Box)({
  width: "100%",
  maxHeight: "224px",
  border: "1px solid #F1F5F9",
  borderRadius: "24px",
  background: "white",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  maxWidth: "360px",
  boxSizing: "border-box",
  "@media (max-width: 374px)": {
    width:"200px"
   },
});

const AddYourTreatmentPlus = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "12px",
  cursor: "pointer"
});

const AddYourTreatmentPlusImage = styled('div')({
  width: "32px",
  height: "32px",
  borderRadius: "50px",
  backgroundColor: "#f1f5f9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor:"pointer"
});

const ManageRemindersDiv = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "8px",
  borderTop: "1px solid #F1F5F9",
  cursor: "pointer"
});

const AddYourTreatText = styled('div')({
  fontSize: "12px",
  fontWeight: 450,
  fontFamily: "Raleway",
  color: "#0F172A"
});

const AddYourTreatRemindersImage = styled('div')({
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const PhysicalTwoBoxDiv = styled('div')({
  display: "flex",
  gap: "24px",
  marginTop: "32px",
  boxSizing: "border-box",
  "@media (max-width: 870px)": {
    flexWrap: "wrap"
  },
});

const ReportScrollDiv = styled('div')({
  display: "flex", 
  gap: "24px", 
  flexWrap: "wrap", 
  overflow: "auto",
  "&.eventListDiv": {
    "&::-webkit-scrollbar": {
        height:'4px',
        width: '4px',
    },
    "&::-webkit-scrollbar-track": {
        background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
        background: '#94A3B8',
        borderRadius: '9px',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: '#90A4AE',
    },
  },
});

const AddTretCombine = styled('div')({
  maxHeight: "350px", 
  overflow: "auto", 
  marginTop: "10px", 
  marginBottom: "16px",
  "&.eventListDiv": {
    "&::-webkit-scrollbar": {
      height:'4px',
        width: '8px',
    },
    "&::-webkit-scrollbar-track": {
        background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
        background: '#94A3B8',
        borderRadius: '9px',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: '#90A4AE',
    },
  },
});

const AddTretmainReminderDiv = styled('div')({
  display: "flex",
  gap: "8px",
  overflow: "auto",
  maxHeight: "77px",
  flexWrap: "wrap",
  marginBottom:"12px",
  "&.eventListDiv": {
    "&::-webkit-scrollbar": {
      height:'4px',
        width: '8px',
    },
    "&::-webkit-scrollbar-track": {
        background: '#F1F5F9'
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
    "&::-webkit-scrollbar-thumb": {
        background: '#94A3B8',
        borderRadius: '9px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: '#90A4AE',
    },
  },
});

const AddtretCap = styled('div')({
  width: "30px",
  height: "30px",
  borderRadius: "50px",
  backgroundColor: "#34D399",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "1px",
  "&.formulaborder":{
    backgroundColor: "#818CF8",
  }
});

const AddTreatData = styled('div')({
  padding: "0px 8.6px 0px 0px",
  display: "flex",
  alignItems: "center",
  height: "32px",
  borderRadius: "50px",
  gap: "4px",
  border: "1.5px solid #34D399",
  position: "relative",
  cursor:"pointer",
  "&.formulaborder":{
    border: "1.5px solid #818CF8",
  },
  "&.takenbackgroundcolor":{
     background: "#E7FDF3"
  },
  "&.mediactionbgcolor":{
    background: "#E0E7FF"
  },
});

const AddTretText = styled('div')({
  fontSize: "10px",
  fontWeight: 500,
  fontFamily: "Raleway",
  color: "#059669",
  whiteSpace:"nowrap",
  "&.formulaborder":{
    color: "#4F46E5",
  }
});

const LogyoursymptomsDiv = styled('div')({
  display: "flex",
  gap: "8px",
  overflow: "auto",
  maxHeight: "77px",
  flexWrap: "wrap",
  margin: "24px 0px 16px 0px",
  minHeight: "35px",
  "&.eventListDiv": {
    "&::-webkit-scrollbar": {
        width: '8px',
        height:'4px'
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
    "&::-webkit-scrollbar-track": {
        background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
        background: '#94A3B8',
        borderRadius: '9px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: '#90A4AE',
    },
  },
  "&.disableddiv": {
    cursor: 'not-allowed',
    pointerEvents: "none",
    opacity: "0.5",
  },
});

const NoAddSymptomsDiv = styled('div')({
  padding: "0px 8.5px 0px 0px",
  display: "flex",
  alignItems: "center",
  height: "32px",
  borderRadius: "50px",
  gap: "4px",
  border: "1.5px solid #60A5FA",
  cursor: "pointer",
  position: "relative",
  background: "#E9F5FE",
  "&.no_selected": {
    opacity: 0.5,
    cursor: 'not-allowed'
  }
});

const NoAddSymptomsImageDiv = styled('div')({
  width: "30px",
  height: "30px",
  borderRadius: "50px",
  backgroundColor: "#60A5FA",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "1px",
});

const NoAddSymptomsTextDiv = styled('div')({
  fontSize: "10px",
  fontWeight: 500,
  fontFamily: "Raleway",
  color: "#1E89DD",
  whiteSpace:"nowrap"
});

const UpcomingEvents = styled('div')({
  width:"100%",
  marginTop: "32px",
  backgroundColor: '#ffffff',
  borderRadius:"24px",
  padding: "24px 14px 0px 24px",
  "@media (min-width:1200px) and (max-width: 1310px)": {
    marginTop: "20px",
    marginBottom: "-25px",
    paddingBottom:"10px",
  },
  "@media (max-width: 1150px)": {
    marginTop: "20px",
    marginBottom: "-25px",
    paddingBottom:"10px",
  },
  "@media (max-width: 400px)": {
    width:"225px"
   },
  "@media (max-width: 374px)": {
    width:"175px"
   },
});

const UpcomingEventsText = styled('div')({
  fontSize: "16px",
  fontWeight: 550,
  fontFamily: "Raleway",
  lineHeight:"24px",
  display: "flex",
  alignItems: "center",
  marginBottom: '24px',
  color:"#1A1D1F",
});

const UpcomingEventsInnerBOX = styled('div')({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media (min-width:1200px) and (max-width: 1310px)": {
    height: "250px",
  },
  "@media (max-width: 1150px)": {
    height: "250px",
  },
});

const UpcomingEventsImageDiv = styled('div')({
  width: "224px",
  height: "210px",
  padding: "0px 10px",
});

const EventsImageButtomText = styled('div')({
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Raleway",
  textAlign: "center",
  "@media (max-width: 374px)": {
    fontSize:"8px"
   },
});

const ReportdMainDiv = styled(Box)({
  maxWidth: "1128px",
  maxHeight: "310px",
  background: "white",
  borderRadius: "24px",
  marginTop: "32px",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  boxSizing: "border-box",
  "@media (max-width: 893px)": {
    width: "450px"
  },
  "@media (max-width: 570px)": {
    width: "350px"
  },
  "@media (max-width: 475px)": {
    width: "270px"
  },
  "@media (max-width: 400px)": {
    width: "200px"
  },
});

const ReportBoxDiv = styled("div")({
  width: "343.67px",
  borderRadius: "12px",
  padding: "16px",
  background: "#F8FAFC",
  border: "0.5px solid #F1F5F9",
  minHeight: "193px",
  boxSizing: "border-box",
  cursor:"pointer"
});

const ReportTittleDiv = styled("div")({
  borderBottom: "1px solid #E2E8F0",
  paddingBottom: "13px"
});

const ReportdMainText = styled('div')({
  fontSize: "16px",
  fontWeight: 550
});

const ReportTittleText = styled("div")({
  lineHeight: "28px",
  fontWeight: 600,
  fontSize: "18px"
});

const ReportTittleDate = styled("div")({
  color: "#64748B",
  fontSize: "12px",
  fontWeight: 400
});

const ReportNoteDiv = styled("div")({
  paddingTop: "8px"
});

const ReportNoteTittle = styled("div")({
  lineHeight: "22px",
  fontSize: "14px",
  fontWeight: 500
});

const ReportNoteTittleTwo = styled("div")({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#475569"
});

const ReportPdfDiv = styled("div")({
  display: "flex",
  fontSize: "12px",
  lineHeight: "28px",
  alignItems: "center",
  marginTop: "12px",
  gap:"8px"
});

const ReportPdfImageDiv = styled("div")({
  height: "24px",
  display: "flex",
  alignItems: "center"
});

const ReportPdfTextDiv = styled("div")({
  color: "#475569",
  fontSize: "12px"
});

const LogbookApplyButton = styled(Button)({
  width: "264px",
  height: "48px",
  background: "#13689E",
  borderRadius: "50px",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "none",
  boxShadow: "none",
  '&:hover': {
    background: '#13689E',
  },
  "@media (max-width: 374px)": {
    width: "200px"
  },
  '&.apply_button': {
    opacity: 0.5,
    cursor: 'not-allowed',
    color: "#FFFFFF",
  },
});

const AddSymptos = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

});
const StyledButton = styled(Button)({
  backgroundColor: '#13689E',
  color: '#FFFFFF',
  borderRadius: '50px',
  width: "100px",
  height: "46px",
  fontWeight: 700,
  fontFamily: "Raleway",
  fontSize: "16px",
  textTransform: "none",
  boxShadow: "0px",
  '&:hover': {
    background: '#13689E',
  },
});

const AddactivityModal = styled(Box)({
  background: '#FFFFFF',
  width: '438px',
  height: '224px',
  borderRadius: '24px',
  padding: '24px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  border: 'none',
  transform: 'translate(-50%, -50%)',
  boxSizing: "border-box",
  '@media (max-width: 600px)': {
    width: '95%',
  }
});

const StyledPaperNewFirst = styled(Paper)({
  border: "1px solid #F1F5F9",
  background: "#F8FAFC",
  margin: '0px 0px 17px 0px',
  boxShadow: 'none',
  flexDirection: "row",
  display: "flex",
  borderRadius: "8px",
  height: "48px",
  alignItems: "center",
});

const StyledPaperNew = styled(Paper)({
  background: "#F8FAFC",
  border: "1px solid #F1F5F9",
  boxShadow: 'none',
  margin: '32px 0px 17px 0px',
  display: "flex",
  flexDirection: "row",
  height: "48px",
  borderRadius: "8px",
  alignItems: "center",
});

const StyledInputBaseNew = styled(InputBase)({
  color: "black",
  fontFamily: "Raleway",
  borderBottom: "none",
  flex: 1,
  fontWeight: 500,
  fontSize: "15px",
  padding: "12px 16px 12px 16px",
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
});

const AddactivityButton = styled(Button)({
  backgroundColor: "#13689E",
  width: "92px",
  color: "#FFFFFF",
  fontWeight: 700,
  borderRadius: "50px",
  textTransform: "none",
  fontSize: "14px",
  fontFamily: "Raleway",
  '&:hover': {
    background: '#13689E',
  },
  '&.disable_button': {
    opacity: 0.5,
    cursor: 'not-allowed',
    color: "#FFFFFF",
  },
});

const IncomingMaindiv = styled('div')({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  maxHeight: "400px",
  overflow: "auto",
  maxWidth: "290px",
  "&.eventListDiv": {
    "&::-webkit-scrollbar": {
        width: '8px',
        height:'4px'
    },
    "&::-webkit-scrollbar-track": {
        background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
        background: '#94A3B8',
        borderRadius: '9px',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: '#90A4AE',
    },
},
  "@media (max-width: 1150px)": {
    flexWrap: "wrap",
    maxWidth: "100%",
    flexDirection: "unset",
  },
  "@media (min-width:1200px) and (max-width: 1310px)": {
    flexWrap: "wrap",
    maxWidth: "100%",
    flexDirection: "unset",
  },
});

const IncomingScrolldiv = styled('div')({
  border: "1px solid #F1F5F9",
  boxSizing: "border-box",
  padding: "16px",
  borderRadius: "12px",
  background: "#FFFFFF",
  maxHeight: "208px",
  cursor:"pointer",
  "@media (min-width:1200px) and (max-width: 1310px)": {
    width: "290px"
  },
  "@media (max-width: 1150px)": {
    width: "290px"
  },
});

const IncomingTimeDiv = styled('div')({
  display: "flex", 
  gap: "6px", 
  paddingTop: "8px", 
});

const IncomingLocationDiv = styled('div')({
  display: "flex", 
  gap: "6px", 
  paddingTop: "12px" 
});

const IncomingImagesSet = styled('div')({
  display: "flex", 
  alignItems: "center", 
  height: "18px", 
  minWidth: "18px", 
  justifyContent: "center", 
  paddingTop: "2px" 
});

const IncomingTimedv = styled('div')({
  color: "#475569", 
  lineHeight: "22px", 
  fontWeight: 500, 
  fontSize: "14px",
});

const IncomingLocationTimedv = styled('div')({
  color: "#475569", 
  lineHeight: "22px", 
  fontWeight: 500, 
  fontSize: "14px",
  height:"41px",
  overflow:"auto",
  "&::-webkit-scrollbar": {
    height:'4px',
    width: '4px',
  },
  "&::-webkit-scrollbar-track": {
    background: '#F1F5F9'
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: '8px',
    background: '#94A3B8',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: '#90A4AE',
  },
  "&::-webkit-scrollbar:vertical": {
    width: '4px',
  },
 
});

const IncomingDiscription = styled('div')({
  color: "#475569", 
  fontSize: "14px", 
  fontWeight: 500, 
  lineHeight: "22px" 
});

const IncomingDiscriptionSubdiv = styled('div')({
  color: "#475569", 
  fontSize: "12px", 
  fontWeight: 400, 
  lineHeight: "16px",
  height:"32px",
  overflow:"auto",
  "&::-webkit-scrollbar": {
    height:'4px',
    width: '4px',
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: '8px',
    background: '#94A3B8',
  },
  "&::-webkit-scrollbar-track": {
    background: '#F1F5F9'
  },

  "&::-webkit-scrollbar:vertical": {
    width: '4px',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: '#90A4AE',
  },
 
});

const AddTreatmentMainBox = styled(Box)({
  background: '#FFFFFF',
  width: '398px',
  borderRadius: '24px',
  padding: '24px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  border: 'none',
  transform: 'translate(-50%, -50%)',
  boxSizing: "border-box",
  "@media (max-width: 380px)": {
    width: "320px !important"
  },
});

const TitleAddTreatment = styled(Typography)({
  color: '#0F172A',
  fontFamily: 'Raleway',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
});

const AddTreatmentMainDiv = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const AddTreatInput = styled(Input)({
  fontFamily: 'Raleway',
  fontWeight: 500,
  lineHeight: '24px',
  paddingBlock: '12px',
  marginTop: '12px',
  fontSize: '16px',
  fontStyle: 'normal',
  width:"100%"
});

const AddTreatReminder = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const AddTreaactivityadd = styled(Box)({
  gap: '20px',
  paddingBlock: '16px',
  flexDirection: "column",
  display: "flex",
  maxHeight: "360px",
  overflow: "auto",
      "&::-webkit-scrollbar": {
      width: '4px',
      height:'4px'
    },
    "&::-webkit-scrollbar-track": {
      background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: '8px',
      background: '#94A3B8',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#90A4AE',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '8px',
    },

});

const MedicationSelect = styled(Box)({
  justifyContent: "space-between",
  alignItems: 'center',
  display: "flex",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
});

const ReminderSelect = styled(Box)({
  justifyContent: "space-between",
  alignItems: 'center',
  display: "flex",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
  paddingTop:"15px",
});

const MedicationDataSelect = styled(Select)({

  "&::-webkit-scrollbar-track": {
    background: '#F1F5F9'
  },
  "&::-webkit-scrollbar": {
    width: '4px',
    height:'4px'
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: '#90A4AE',
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: '8px',
    background: '#94A3B8',
  },
  "&::-webkit-scrollbar:vertical": {
    width: '4px',
  },
  "& .select_inputpadding": {
    padding: "4px 9px 4px 3px",
    lineHeight: "16px",
    background:"#F1F5F9",
    color: "#334155",
  },
  "& .select_inputpaddingnumber": {
    padding: "4px 9px 4px 5px",
    lineHeight: "16px",
    background:"#F1F5F9",
    color: "#334155",
  },
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  paddingBlock: '4px',
  color: "#334155",
  fontWeight: 500,
  border: '1px solid #E2E8F0',
  lineHeight: '16px',
  fontSize: '12px',
  paddingInline: '10px',
  borderRadius: '26px',
  backgroundColor: '#F1F5F9',
});

const Dateselect = styled('span')({
  "& .selectInput": {
    fontFamily: 'Raleway',
    fontSize: '12px',
    border: '1px solid #E2E8F0',
    fontStyle: 'normal',
    color: "#334155",
    borderRadius: '26px',
    lineHeight: '16px',
    backgroundColor: '#F1F5F9',
    paddingInline: '10px',
    paddingBlock: '3px',
    fontWeight: 500,
    height:"32px"
  },
});

const SavetretmentButton = styled(Button)({
  display: 'flex',
  height:"40px",
  width: '88px',
  fontFamily: 'Raleway',
  gap: '8px',
  fontSize: "14px",
  fontWeight: 700,
  color: "white",
  marginTop:"16px",
  marginLeft: "auto",
  textTransform: "capitalize",
  borderRadius: '50px',
  background: '#13689E',
  '&:hover': {
    background: '#13689E',
  },
  '&.disableTeat':{
    opacity: 0.5,
    cursor: 'not-allowed',
    color: "white",
  },
});

const EditSavetretmentButton = styled(Button)({
  display: 'flex',
  height:"48px",
  width: '171px',
  fontFamily: 'Raleway',
  gap: '8px',
  fontSize: "14px",
  fontWeight: 700,
  color: "white",
  marginLeft: "auto",
  textTransform: "capitalize",
  borderRadius: '50px',
  background: '#13689E',
  '&:hover': {
    background: '#13689E',
  },
  '&.disableTeat':{
    opacity: 0.5,
    cursor: 'not-allowed',
    color: "white",
  },
});
const EditDeletetretmentButton = styled(Button)({
  display: 'flex',
  height:"48px",
  width: '171px',
  fontFamily: 'Raleway',
  gap: '8px',
  fontSize: "14px",
  fontWeight: 700,
  color: "white",
  marginLeft: "auto",
  textTransform: "capitalize",
  borderRadius: '50px',
  background: '#EF4444',
  '&:hover': {
    background: '#EF4444',
  },
  '&.disableTeat':{
    opacity: 0.5,
    cursor: 'not-allowed',
    color: "white",
  },
});

const Apply_modalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: '#FFFFFF',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },

});

const ApplyClicktextOne = styled('div')({
  fontSize: "22px",
  fontFamily: "Raleway",
  fontWeight: 600,
  paddingTop: "20px",
  color: "#334155",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },
});

const ApplyClicktextTwo = styled('div')({
  fontSize: "22px",
  fontFamily: "Raleway",
  fontWeight: 600,
  color:"#334155",
  paddingTop:"8px",
  width:"80%",
  textAlign:"center",
  paddingBottom: "16px",
  borderBottom: "0.33px solid #b9b9bb",
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },
});

const ApplymodalClosed = styled(Button)({
  minWidth: "140px",
  color: "#007AFF",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  marginTop: "5px",
  marginBottom:"5px",
  fontSize:"18px",
  '&:hover': {
    background: 'none',
  },
});


const LogbookDataSave = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 610,
  background: '#FFFFFF',
  border: 'none',
  padding: 0,
  borderRadius: "24px",
  maxHeight: "320px",
  display: "flex",
  flexDirection: "column",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '92%',
  },
});

const LogbookDatasaveText = styled(Typography)({
  color: "black",
  fontSize: "24px",
  fontFamily: "Raleway",
  fontWeight: 700,
  padding: "21px 40px 16px 40px",
  '@media (max-width: 450px)': {
    fontSize: "19px",
  },
});

const LogbookDatasaveTextTwo = styled(Typography)({
  color: "black",
  fontSize: "18px",
  fontFamily: "Raleway",
  fontWeight: 400,
  padding: "0px 40px 55px 40px",
  borderBottom: "1px solid #E2E8F0",
  '@media (max-width: 450px)': {
    fontSize: "15px",
  },
});

const LogbookDatasaveDiv = styled('div')({
  display: "flex",
  justifyContent: "end",
  padding: "24px 24px 24px 0px"
});

const DiscardChangeButt = styled(Button)({
  minWidth: "140px",
  minHeight: "56px",
  color: "#13689E",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  paddingRight:"16px",
  '&:hover': {
    background: 'none',
  },
});
const SaveDeletechange = styled(Button)({
  minWidth: "151px",
  minHeight: "56px",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  borderRadius: "50px",
  background: "#EF4444",
  '&:hover': {
    background: '#EF4444',
  },
});

const SaveChange = styled(Button)({
  minWidth: "151px",
  minHeight: "56px",
  color: "white",
  fontWeight: 700,
  fontFamily: "Raleway",
  textTransform: "none",
  borderRadius: "50px",
  background: "#13689E",
  '&:hover': {
    background: '#13689E',
  },
});

const ReminderPlusAdd = styled("div")({
  height:"45px",
  width:"45px",
  background:"#23B8B1",
  borderRadius:"32px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center", 
  cursor:"pointer"
});

const SaveCheckedModal = styled('div')({
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    padding: "22px 0px 0px 40px",
});

const LogbookEventEditsaveText = styled(Typography)({
  color: "black",
  fontSize: "24px",
  fontFamily: "Raleway",
  fontWeight: 700,
  padding: "10px 40px 0px 40px",
  '@media (max-width: 450px)': {
    fontSize: "19px",
  },
});

const EventTextModal = styled('div')({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "26px",
  color:"#0F172A",
  fontFamily: "Raleway !important",
});

const EventEditSaveButton = styled('div')({
  display: "flex",
  justifyContent: "end",
  padding: "24px 24px 24px 0px",
  borderTop:"1px solid #E2E8F0",
  marginTop:"30px",
});

const NoreminderDiv = styled('div')({
  display:"flex",
  justifyContent:"center",
  flexDirection:"column",
  alignItems:"center"
});

const NoreminderTextBox = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center"
});

const NoreminderTextone = styled('div')({
  fontFamily: "Raleway",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "21.13px", 
  color:"#151515", 
  paddingBottom:"4px",
});

const NoreminderTextwo= styled('div')({
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16.44px",
  color:"#475569"
});

const StartDateCalendar = styled(KeyboardDatePicker)({
  width: "112px", 
  height:"32px",
  borderRadius: '26px',
  fontWeight: 500,
  border: "1px solid #E2E8F0",
  background: '#F1F5F9',
  fontFamily:"Raleway",
  color:"#334155",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  margin:0,
});

const EditDateinaddReminder = styled('div')({
  width: "112px",
  border: "1px solid #E2E8F0",
  display: "flex",
  height: "32px",
  textAlign: "center",
  background: "#F1F5F9",
  fontFamily: "Raleway",
  alignItems: "center",
  color: "#334155",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px",
  borderRadius:"26px",
  cursor:"not-allowed",
  marginLeft:"16px",
  justifyContent:"space-evenly",
}); 

// Customizable Area End
