import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOnline: boolean,
  loading: boolean,
  open: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenLoaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      isOnline: navigator.onLine,
      loading: true,
      open: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    setInterval(()=> {
      this.setState({ loading: false }, () => {
      this.state.isOnline ? this.handleNavigate() : this.setState({open: true})
      });
    },2000);
     
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);

    // Customizable Area End
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
    // Customizable Area End
  }

  // Customizable Area Start
  handleOnline = () => {
    this.setState({ isOnline: true }, () => this.handleNavigate());
  };
  handleOffline = () => {
    this.setState({ isOnline: false, open:true });
  };
  handleNavigate = () => {
    if(this.state.isOnline){
      this.props.navigation.navigate('EmailRegistrationWithoutInvCode')
    }
 }
 handleClickOnRetry = () => {
  this.setState({open: false,loading: true})
 }
  // Customizable Area End
}
