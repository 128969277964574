export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const SettingsMainLogo = require("../assets/SettingLogo.svg");
export const notificationIcon = require("../assets/notificationIcon.png");
export const calender = require("../assets/calender.png");
export const LockSettings = require("../assets/LockSettings.svg");
export const LockSettingsActive = require("../assets/LockSettingsActive.svg");
export const deleteSettings = require("../assets/deleteSettings.svg");
export const infoSettings = require("../assets/infoSettings.svg");
export const infoSettingsActive = require("../assets/infoSettingsActive.svg");
export const visible = require("../assets/visible.svg");
export const inVisible = require("../assets/invisible.svg");
export const gdprPolicyActive = require("../assets/gdprPolicyActive.svg");
export const gdprPolicy = require("../assets/gdprPolicy.svg");
export const privacyPolicyActive = require("../assets/privacyPolicyActive.svg");
export const privacyPolicy = require("../assets/privacyPolicy.svg");



