Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.clientID =
  "298465077090-9j4d75763jb2q0g0j388lqt1dvcmnapf.apps.googleusercontent.com";
exports.googleLoginAPiEndPoint = "/account/accounts";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.putSignUpMethod = "PUT";
exports.timeSignUpZoneEndPoint = "account/update_timezone";


exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.contactusEndpoint = "bx_block_contact_us/contacts";
exports.pdfSelectValidation = "Supported document formats include: JPG, JPEG, and PDF";

exports.validationApiMethodType = "GET";
exports.phonenumberValid = /^[\d\-()/.\s]{5,14}\d$/;
exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.phoneValidation = "Please enter a valid phone number";
exports.ipAddressApi = "https://api64.ipify.org/?format=json";
exports.apiType = "multipart/form-data";
// Customizable Area End
