import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { RefObject, createRef } from "react";
import Chart from 'react-apexcharts';
import { getStorageData } from "../../../framework/src/Utilities";
import { takenImage, skippedImage, upcomingImage } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
// Customizable Area Start

interface ResponseCalender{
  "current_month_data":
  {
    "date": string,
    "progress_bar": {
      "treatment": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      },
      "formula": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      }
    }[]
  },
  "past_month_data":
  {
    "date":string,
    "progress_bar": {
      "treatment": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      },
      "formula": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      }
    }[]
  },
  "next_month_data":
  {
    "date": "03/06/24",
    "progress_bar": {
      "treatment": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      },
      "formula": {
        "total_treatment_count": number,
        "taken_treatment_count": number,
        "percentage": number | null
      }
    }
  }[],
}

interface OnboardingStep {
  id: number;
  onboarding_title: string;
  onboarding_description: string;
  transformHorizontal: string;
  anchorHorizontal: string;
}
interface RadialBarChartOptions {
  colors: string[];
  chart: {
    height: number;
    type: 'radialBar';
  };
  plotOptions: {
    radialBar: {
      track: {
        background: string;
        innerRadius: string;
        outerRadius: string;
        margin: number;
      };
      hollow: {
        size: string;
        margin: number;
      };
      dataLabels: {
        name: {
          show: boolean;
        };
        value: {
          show: boolean;
        };
      };
    };
  };
  stroke: {
    width: number;
    lineCap: 'round';
  };
}

interface ChartResponseJson {
  data: {
    treatment: {
      treatment_id: number;
      treatment_name: string;
      treatment_date: string;
      reminders: {
        time: string;
        status: "upcoming" | "taken" | "missed";
      }[];
    }[];
  };
  progress_bar: {
    treatment: {
      total_treatment_count: number;
      taken_treatment: number;
      percentage: number | null;
    };
    formula: {
      total_formula_count: number;
      taken_formula: number;
      percentage: number | null;
    };
  };
}

interface IntimeData {
  date: string;
  progress_bar: {
    treatment: {
      total_treatment_count: number;
      taken_treatment_count: number;
      percentage: number | null;
    };
    formula: {
      total_treatment_count: number;
      taken_treatment_count: number;
      percentage: number | null;
    };
  };
}
interface CalenderChartType {
  options: {
    grid: {
      padding: {
        top: number;
      };
    };
    colors: string[];
    chart: {
      width:number,
      height: number;
      type: 'radialBar';
    };
    plotOptions: {
      radialBar: {
        track: {
          background: string;
          innerRadius: string;
          outerRadius: string;
          margin: number;
        };
        hollow: {
          size: string;
          margin: number;
        };
        dataLabels: {
          name: {
            show: boolean;
          };
          value: {
            show: boolean;
          };
        };
      };
    };
    stroke: {
      width: number;
      lineCap: 'round';
    };
  };
};
export interface Treatmentdetails{
  treatment_id: number;
  treatment_name: string;
  treatment_date: string;
  reminders: {
    time: string;
    status: "upcoming" | "taken" | "missed" | "unread";
  }[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chartRingdata:boolean;
  chartCalendarLoading:boolean;
  options: RadialBarChartOptions;
  dateSelectChart: string;
  TreatmentFormula: number[];
  selectedDate:Date,
  isOpen: boolean,
  date:null,
  calenderChart:CalenderChartType,
  current_month_data?: string[], 
  dateChatEndCalender:string,
  firstName:string,
  lastName:string,
  wordWrap:string,
  anchorEl:null | HTMLElement ,
  selectedItems:number[],
  sortDataAnalytic?: Treatmentdetails[];
  onBoarding: OnboardingStep[];
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  onboardingRefs: RefObject<HTMLTableCellElement>[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  analyticschartgetApiCallid : string = "";
  analyticCalendarApiCallid : string = "";
  getnameApiCallid : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chartRingdata:false,
      chartCalendarLoading:false,
      anchorEl:null,
      selectedItems:[],
      sortDataAnalytic:[],
      firstName:"",
      lastName:"",
      wordWrap:'wordWrap',
      TreatmentFormula:[],
      dateChatEndCalender:"",
      isOpen:false,
      dateSelectChart:"",
      date: null,
      current_month_data: [],
      selectedDate: new Date(),
      options: {
        colors:['#006AFF','#FF6666'],
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            track: {
              background: '#F1F5F9',
              innerRadius: '60%',
              outerRadius: '70%',
              margin: 7,
            },
            hollow: {
              size: '55%',
              margin: 10,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          width: 10, 
          lineCap: 'round',
        },
      },
      calenderChart:{
        options: {
          grid:{
            padding:{
              top:-10,
            }
  
          },
          colors:['#006AFF','#FF6666'],
          chart: {
            width: 120,
            height: 100,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              track: {
                background: '#F1F5F9',
                innerRadius: '10%',
                outerRadius: '40%',
                margin: 2,
              },
              hollow: {
                size: '40',
                margin: 1,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            width: 7, 
            lineCap: 'round',
          },
        },
      },
      onboardingRefs: Array.from({ length: 3 }, () =>
        createRef<HTMLTableCellElement>()
      ),
      onBoarding: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Tap here to see any new notifications or messages.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "Choose a date to view the Treatments scheduled for that day.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Tap here to sort your logged medicines.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area Start
 
    // Customizable Area Start
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {

            if (apiRequestCallId === this.analyticschartgetApiCallid) {
                   this.chartDataGet(responseJson)
            }else if(apiRequestCallId === this.getnameApiCallid){
              this.firstLastnameGet(responseJson.data)
            }else if(apiRequestCallId === this.analyticCalendarApiCallid){
              this.dateSetGetArray(responseJson)
              setTimeout(() => {this.setState({chartRingdata:false})
              }, 1000); 
              
            }
        }
    }
    // Customizable Area End
  }

// Customizable Area Start


  async componentDidMount() {
    this.dataRecivedSelectDate(new Date());
    this.getnameUserApicalling();
    this.analyticsChartDataget();
    const authToken = await getStorageData("authToken")
    if (!authToken) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    let onboarding = await getStorageData("onBoarding");
    if (onboarding === null) {
      this.setState({ openOnboarding: true });
      this.nextOnBoarding();
    }
  };

  nextOnBoarding = () => {
    const { onBoarding, currentStep } = this.state;
    if (onBoarding.length > currentStep) {
      this.setState({
        onBoardingTitle: onBoarding[currentStep].onboarding_title,
        onBoardingText: onBoarding[currentStep].onboarding_description,
        transformHorizontal: onBoarding[currentStep].transformHorizontal,
        anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
        currentStep: currentStep + 1
      })
      const targetRef = this.state.onboardingRefs[currentStep];
      if (targetRef && targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    else {
      this.setState({
        openOnboarding: false
      }); 
        this.props.navigation.navigate('Gamification');
    }
  }

  skipOnBoarding = () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    localStorage.setItem("onBoarding", "true")
  }

  skipOnBoardingInvitedUser = () => {
      this.setState({
        openOnboarding: true,
        currentStep: 0
      })
  }
  
  getnameUserApicalling = async () =>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getnameApiCallid = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsNamegerEndpoint}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);


  };

  analyticsChartDataget = async () => {
    this.setState({chartCalendarLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.analyticschartgetApiCallid = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsChartEndpoint}?date=${this.state.dateSelectChart}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  analyticCalendarOpen = async ()=>{
    this.setState({chartRingdata:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.analyticCalendarApiCallid = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsCalendarChartEndpoint}?date=${this.state.dateChatEndCalender}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);

  }
 // Customizable Area End

  // Customizable Area Start

  dataRecivedSelectDate = (valueDate: Date |string) => {
    const analyUtc = new Date(valueDate);
    this.setState({ dateSelectChart: new Date(analyUtc).toLocaleDateString('en-GB') })
    this.analyticsChartDataget();
  };

  chartDataGet = (responseJson:ChartResponseJson) => {
    const formulaPercentage = responseJson.progress_bar?.formula.percentage ?? 0;
    const treatmentPercentage = responseJson.progress_bar?.treatment.percentage ?? 0;
    this.setState({TreatmentFormula:[formulaPercentage,treatmentPercentage]})
    this.setState({sortDataAnalytic:responseJson.data.treatment})
    this.setState({chartCalendarLoading:false})
  };

  firstLastnameGet = (responseget:{attributes:{first_name:string,last_name:string}}) =>{
    const firstName = responseget.attributes?.first_name
    const lastName = responseget.attributes?.last_name
    this.setState({firstName:firstName,lastName:lastName})
  };

  dateSetGetArray = (responseJson:ResponseCalender)=>{
    this.setState({
      current_month_data: JSON.parse(JSON.stringify(responseJson.current_month_data)),
    });
  }

  handleCalendarClick = () => {
    this.setState({ isOpen: true });
    this.analyticCalendarOpen()
  };
  
  handleClosedCalender = () =>{
    this.setState({isOpen:false})
  };

  formatDate = (dateSelect:Date) => {
    const selectDate = new Date(dateSelect);
    const addDateselect = selectDate.toISOString();
    const nthNumber = (number: number) => this.newNumber(number);

    let dateFormate = new Date(addDateselect)
        .toLocaleDateString('en-GB', configJSON.fordate);
        dateFormate = dateFormate.replace(/\b(\d{1,2})\b/g, (match, newDay) => {
        return match + nthNumber(parseInt(newDay));
    });
    dateFormate = dateFormate.replace(/(\w{3}) (\d{4})/, "$1, $2");
    return dateFormate;
  };

  newNumber = (number: number): string => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
  
    switch (lastDigit) {
      case 1:
        return lastTwoDigits !== 11 ? 'st' : 'th';
      case 2:
        return lastTwoDigits !== 12 ? 'nd' : 'th';
      case 3:
        return lastTwoDigits !== 13 ? 'rd' : 'th';
      default:
        return 'th';
    }
  };
  
  setCalenderChart = (
    dateCharts: Date,
  ) => {
    const currentMonthData = this.getDateData(dateCharts, [
       ...JSON.parse(JSON.stringify(this.state.current_month_data)),
      ]);

    const treatment = !!currentMonthData ? currentMonthData.progress_bar.treatment.percentage : 0
    const formula = !!currentMonthData ? currentMonthData.progress_bar.formula.percentage : 0

    return !!currentMonthData && <div style={{ pointerEvents: 'none' }}>
      <Chart options={this.state.calenderChart.options} series={[formula || 0, treatment || 0]} type="radialBar" height={this.state.calenderChart.options.chart.height} 
      width={this.state.calenderChart.options.chart.width}/>
    </div>
  };

  getDateData(dateget: Date, monthData: IntimeData[]) {
    const currentDate = (dateget.getMonth() + 1) + "/" + dateget.getDate() + "/" + dateget.getFullYear();

    return monthData.find((item: IntimeData) => {
      const parts = item.date.split('/');
      const currentTermDate = (parseInt(parts[1], 10)) + "/" + (parseInt(parts[0], 10)) + "/" + (parseInt(parts[2], 10) + 2000);

      return currentDate === currentTermDate;
    });
  };

  dateSetDiaplay = (selectDAte:Date) =>{
    this.setState({selectedDate:new Date(selectDAte),isOpen:false,selectedItems: []})
    this.dataRecivedSelectDate(selectDAte)
    this.analyticsChartDataget()
  };

  nextPrevesDate = (datecurrent:Date) =>{
    const selectnextprevDate = new Date(datecurrent);
    const setCalenderDate = `${("0" + selectnextprevDate.getDate()).slice(-2)}/${("0" + (selectnextprevDate.getMonth() + 1)).slice(-2)}/${selectnextprevDate.getFullYear()}`;
    this.setState({dateChatEndCalender:setCalenderDate});
    this.analyticCalendarOpen()
  };


  handlePrevDateClick = () => {
    const currentDate = this.state.selectedDate;
    const previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
    this.setState({ selectedDate: previousDate,selectedItems: [] });
    this.dataRecivedSelectDate(previousDate)
  };

  handleNextDateClick = () => {
    const currentDate = this.state.selectedDate;
    const nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    this.setState({ selectedDate: nextDate,selectedItems: [] });
    this.dataRecivedSelectDate(nextDate)
  };

  handleMenuItemClick = (indexno: number) => () => {
    const selectedIndex = this.state.selectedItems.indexOf(indexno);
    let newSelected : number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedItems, indexno);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedItems.slice(1));
    } else if (selectedIndex === this.state.selectedItems.length - 1) {
      newSelected = newSelected.concat(this.state.selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedItems.slice(0, selectedIndex),
        this.state.selectedItems.slice(selectedIndex + 1),
      );
    }

    this.setState({ selectedItems: newSelected });
  };
  

  
  handleSelectAllClick = () => {
      this.setState({ selectedItems: [] });
  };

  handleClick = (event:   React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  getMenuItemClassName = (indexnum:number) => {
    return this.state.selectedItems.includes(indexnum) ? "selectedMenuItem" : "";
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  sortTableDate = (formatesort:string) =>{
    const dateSort = new Date(formatesort);
    const optionsSort = configJSON.fordateSortdate;
    const formattedDateSort = new Intl.DateTimeFormat('en-GB', optionsSort).format(dateSort);

    const partsSort = formattedDateSort.split(' ');
    const dayMonthSort = partsSort.slice(0, 2).join(' ');
    const yearSort = partsSort[2];
  
    return `${dayMonthSort}, ${yearSort}`;
  };

  getStatusImage = (status:string) => {
    if (status === 'upcoming') {
      return upcomingImage;
    } else if (status === 'read') {
      return takenImage;
    } else {
      return skippedImage;
    }
  };

  calendarLoader=()=>{
    return this.state.chartRingdata && <Loader />
  }
 
  
  // Customizable Area End
}
