import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  idphoto: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  idphoto: string;
  // Customizable Area End
}

export default class VideoEmbeddingYoutubeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      // Customizable Area Start

      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

   
  }

  async receive(from: string, message: Message) {
        // Customizable Area Start


    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area End
  }
    // Customizable Area Start

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
    autoCompleteType: "email",
  };

  txtInputProps = this.txtInputWebProps

  btnShowHideImageProps = {
    source: imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let messag = new Message(getName(MessageEnum.AccoutLoginSuccess));
    messag.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(messag);
  }

  // web events
 



  // Customizable Area End
}
