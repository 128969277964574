Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.userProfileApiEndPoint = "bx_block_profile/profile";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.deleteMethod = "DELETE";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";
exports.reportsText = "Reports";
exports.helloText = "Hello,";
exports.userNameText = " Anna Beaumarchais!";
exports.subHeadingText = "Upload new reports and analyse old reports.";
exports.pheReportText = "Phenylalanine (Phe) Blood Test Report";
exports.tyrReportText = "Tyrosine (Tyr) Blood Test Report";
exports.geneticTestText = "Genetic Testing Report";
exports.pheBeforeMealText = "Phe Report - Before Meal";
exports.pheLevelText = "Phe Level";
exports.tyrLevelText = "Tyr Level";
exports.viewText = "View";
exports.deleteText = "Delete";
exports.pheImageText = "59151155.jpg";
exports.addReport = "Add Report";
exports.pheLevelQtyText = "24 mg/dL";
exports.reportDateText = "Jan 18, 2024";
exports.cardDateText = "18/01/2024";
exports.uploadReportText = "Upload Reports";
exports.uploadSubHeading = "Securely submit your health-related documents for personalized insights and tracking,";
exports.reportNameInput = "Enter Report Name";
exports.testDateText = "Select The Date Of The Test";
exports.pheLevelsText = "Enter Your Phe Levels";
exports.enterYourTyrLevelText = "Enter Your Tyr Levels";
exports.addNotesText = "Add Notes";
exports.selectFileText = "Select a file to upload";
exports.dragFileText = "Drag and drop the files here";
exports.supportDocumentsText = "Supported document formats include: Images and PDF";
exports.browseFileText = "Browse file to upload";
exports.submitText = "Submit";
exports.successUpload = "Report Uploaded Successfully!";
exports.successMessage = "Your document has been successfully uploaded. Continue tracking your progress or review your uploaded documents in the reports section."
exports.closeButton = "Close";
exports.cancelButton = "Cancel";
exports.logoutButton = "Delete";
exports.deleteHeading = "Delete Reports";
exports.deleteMessage = "Are you sure you want to delete this report? This action cannot be undone.";
exports.selectedFileName = "24548461.pdf";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.userReportApiEndPoint = "user_reports";
exports.limitExceedTextOne = "You've reached the maximum limit of 5 files. Please ";
exports. fileValidation = "remove some files to add more";
exports.reportNameMessage = "Please enter a valid report name.";
exports.dateMessage = "Please select a date for the test.";
exports.pheLevelMessage = "Please enter valid Phe levels.";
exports.tryLevelMessage = "Please enter valid Tyr levels.";
exports.fileError = "Please Select a file.";
exports.invalidFileError = "Trouble uploading photo. Choose a different file type.";
exports.ipAddressApi = "https://api64.ipify.org/?format=json";
// Customizable Area End