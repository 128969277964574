export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bottom_left = require("../assets/bottom_left.svg");
export const top_right = require("../assets/top_right.svg");
export const closeIcon = require("../assets/close_icon.svg");
export const mainLogo = require("../assets/vitaLogo.svg");
export const BackGround = require("../assets/Signup_Page.jpg");
export const code_verified = require("../assets/code_verified.png");
export const email_verified = require("../assets/emailVerified.svg");
export const apple_icon = require("../assets/apple_icon.svg");
export const google_icon = require("../assets/google_icon.svg");
export const document = require("../assets/image_document.svg");
export const closesmall = require("../assets/image_close_small.svg");
export const closesmodel = require("../assets/group_mobile_popover_.svg");
export const closessucsessmodel = require("../assets/image_close.svg");
export const selectimage = require("../assets/image_image.svg");
export const helpimage = require("../assets/image_help.svg");


