import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/styles";
import SideBar from "../../../components/src/SideBar";
import {
  Info,
  ReportChart,
  ThreeDots,
  AddImage,
  ButtonClose,
  UploadImage,
  AddBorder,
  ImageClose,
  PheEdit,
  PheClose,
  ImageDocument,
  ImageCloseSmall,
  closeModal,
  calenderIcon,
  imageIcon,
  downloadIcon,
  backArrowIcon
} from "./assets";
import CustomFormController, {
  Props, configJSON, TyrosineReport, PhenylalanineReport, GeneticReport, FileData
} from "./CustomFormController.web";
import { Formik } from "formik";
import WebHeader from "../../../components/src/WebHeader.web";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import Loader from "../../../components/src/Loader.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import DocViewer, { DocViewerRenderers} from "react-doc-viewer";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFileName = (item: Array<FileData>,report_name: string) => {
    return (
      <Box style={styles.fileList} >
        {
          item?.map((fileItem: FileData) => {
            let fileName = this.getFileName(fileItem.attributes.file)
            let pdfCheck = this.checkFileType(fileName)
            return <PheImageText data-testId="fileBtn" onClick={() => this.handleReportOpener(fileItem.attributes.file, report_name)}><img src={pdfCheck ? ImageDocument : imageIcon} />{fileName}</PheImageText>
          })
        }
      </Box>
    )
  }

  exceedUploadModal = () => {
    return (
      <Modal
        data-testId="exceedModal"
        open={this.state.fileExceedModal}
        onClose={this.handleExceedModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelInnerBox sx={{ boxShadow: 24 }}>
          <ModelErrorOne>{configJSON.limitExceedTextOne}</ModelErrorOne>
          <ModelErrorTwo>{configJSON.fileValidation}</ModelErrorTwo>
          <ModelErrorBorder></ModelErrorBorder>
          <img style={styles.cursorPointer} onClick={this.handleExceedModalClose} src={closeModal} />
        </ModelInnerBox>
      </Modal>
    )
  }

  reportModal = () => {
    return (
      <>
        <CustomModal
          data-testId="reportModal"
          open={this.state.showAddReportModal || this.state.isEditReportForm}
          maxWidth={"md"}
          onClose={this.handleAddReportModalClose}
        >
          {this.state.loading && <Loader />}
          <ReportModalContainer>
            <FormContainer>
              <UploadReportContainer>
                <DialogHeadingContainer>
                  <UploadReportHeading>{configJSON.uploadReportText}</UploadReportHeading>
                  <UploadReportSubHeading>{configJSON.uploadSubHeading}</UploadReportSubHeading>
                </DialogHeadingContainer>
                <CloseButtonContainer onClick={this.handleAddReportModalClose}>
                  <img src={ButtonClose} />
                </CloseButtonContainer>
              </UploadReportContainer>
              <ReportSeparatorLine></ReportSeparatorLine>
              <Formik
                data-testId="formik"
                initialValues={{
                  report_name: this.getReportName(),
                  testDate: this.getReportDate(),
                  level: this.getReportLevelValue(),
                  report_notes: this.getReportNote(),
                  file: this.getReportFile()
                }}
                validationSchema={this.reportSchema}
                onSubmit={(values) => this.handleAddReports(values)}>
                {({ errors, touched, handleChange, handleSubmit, setFieldValue, values }) => (
                  <ReportForm onSubmit={handleSubmit} noValidate autoComplete="off">
                    <TextField name="report_name" placeholder={configJSON.reportNameInput} onChange={handleChange}
                      value={values.report_name}
                      InputProps={{ disableUnderline: true, style: { ...styles.fieldStyle, border: this.getBorderstyle(touched.report_name, errors.report_name) } }}
                      error={touched.report_name && !!errors.report_name}
                      helperText={touched.report_name && errors.report_name}
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <CustomDatePicker
                        style={styles.dialogFirstInputField}
                        inputProps={{ placeholder: configJSON.testDateText }}
                        inputVariant="outlined"
                        data-testId="formDateInput"
                        format="DD/MM/YYYY"
                        keyboardIcon={<img src={calenderIcon} alt="calendar" />}
                        value={values.testDate}
                        onChange={(event) => {
                          setFieldValue("testDate", event._d);
                        }}
                        variant="dialog"
                        maxDate={new Date()}
                        onKeyDown={(event) => event.preventDefault()}
                        error={touched.testDate && !!errors.testDate}
                        helperText={touched.testDate && errors.testDate}
                        InputProps={{ disableUnderline: true, style: { ...styles.fieldStyle, border: this.getBorderstyle(touched.testDate, errors.testDate) } }}
                      />
                    </MuiPickersUtilsProvider>
                    {!this.renderThirdCardCondition() && <TextField name="level" placeholder={this.getModalLevelPlaceholder()}
                      data-testId="level"
                      value={values.level}
                      onChange={(event) => { setFieldValue("level", event.target.value.replace(/[^\d.]/g, "")) }}
                      InputProps={{ disableUnderline: true, style: { ...styles.fieldStyle, border: this.getBorderstyle(touched.level, errors.level) } }}
                      error={touched.level && !!errors.level}
                      helperText={touched.level && errors.level}
                    />}
                    <NoteInput name="report_notes" placeholder={configJSON.addNotesText}
                      value={values.report_notes}
                      onChange={handleChange}
                      InputProps={{ disableUnderline: true, style: { ...styles.noteFieldStyle } }}
                    />
                    <Box>
                      <DragDropContainer data-testId="dropContainer" onDrop={this.handleDrop}
                        onDragOver={this.handleDragOver}
                        style={{ border: touched.file && !!errors.file && this.state.selectedFile.length === 0  ? "1px solid red" : "2px dashed #CBD5E1" }}
                      >
                        <DragImage><img src={UploadImage} /></DragImage>
                        <SelectFileText>{configJSON.selectFileText}</SelectFileText>
                        <DragFilesText>{configJSON.dragFileText}</DragFilesText>
                        <SupportDocumentsText>{configJSON.supportDocumentsText}</SupportDocumentsText>
                        <UploadBox>
                          {this.state.selectedFile?.map((value, fileIndex: number) => {
                            let pdfCheck = this.checkFileType(value.file.name)
                            return (
                              <>
                               <Box data-testId="fileOpen" onClick={() => this.handleFileOpen(fileIndex)} key={fileIndex} style={styles.uploadContainer} data-test-id="portableNewOneiamghe">
                                  <Typography data-test-id="handleRemove" style={styles.uploadBox}>
                                    <img src={pdfCheck ? ImageDocument : imageIcon} />{value?.file.name}</Typography>
                                       <img style={styles.uploadImageIcon} data-testId="removeFile" src={ImageCloseSmall} onClick={() => {
                                          this.handleRemoveMain(fileIndex, setFieldValue)  
                                        }} />
                                </Box>
                              </>
                            )
                          })
                          }
                        </UploadBox>
                        <FileUploadLabel htmlFor="fileUpload">
                          <AddBrowseImageContainer><img src={AddBorder} /></AddBrowseImageContainer>
                          <BrowseFileUploadText>{configJSON.browseFileText}</BrowseFileUploadText>
                        </FileUploadLabel>
                        <input id="fileUpload" data-testId="fileUploadBtn" multiple={true} accept="image/*,.pdf" type="file" name="file" style={styles.hideBrowser} onChange={(event) => {
                          setFieldValue("file", event.target.files)
                          this.openPictureUploadInSide(event)
                        }
                        } />
                      </DragDropContainer>
                      {touched.file && this.state.selectedFile.length === 0 && <span style={styles.validationError}>{errors.file}</span>}
                    </Box>
                    <SubmitButton type="submit" data-testId="submitBtn">
                      <SubmitButtonText>{configJSON.submitText}</SubmitButtonText>
                    </SubmitButton>
                  </ReportForm>
                )}
              </Formik>
            </FormContainer>
          </ReportModalContainer>
        </CustomModal>
      </>
    )
  }

  reportSuccessModal = () => {
    return (
      <ReportSuccess open={this.state.successMsg} data-testId="successModal">
        <ReportSuccessContainer>
          <ReportSuccessContent>
            <ReportSuccessHeading>
              {configJSON.successUpload}
            </ReportSuccessHeading>
            <ReportSuccessMessage>
              {configJSON.successMessage}
            </ReportSuccessMessage>
            <Divider style={styles.suceessDivider} />
          <CloseButton data-testId="closeSuccessModal" onClick={this.handleSuccesModalClose}>{configJSON.closeButton}</CloseButton>          
          </ReportSuccessContent>
        </ReportSuccessContainer>                   
      </ReportSuccess>
    )
  }

  deleteReportModal = () => {
    return (
      <ReportDelete data-testId="deleteModal" open={this.state.showDeleteModal} >
        <ReportDeleteContainer>
          <ReportCloseImage data-testId="closeDeleteBtn" onClick={this.handleDeleteModalClose}>
            <img src={ImageClose} />
          </ReportCloseImage>
          <DialogContent>
            <ReportDeleteHeading>
              {configJSON.deleteHeading}
            </ReportDeleteHeading>
          </DialogContent>
          <ReportDeleteContent>
            {configJSON.deleteMessage}
          </ReportDeleteContent>
        </ReportDeleteContainer>
        <ButtonContainer>
          <CancelButton onClick={this.handleDeleteModalClose}>
            {configJSON.cancelButton}
          </CancelButton>
          <LogoutButton data-testId="deleteReportBtn" onClick={this.handleDeleteReport}>
            {configJSON.logoutButton}
          </LogoutButton>
        </ButtonContainer>
      </ReportDelete>
    )
  }

  viewReportModal = () => {
    const { selectedReport } = this.state;
    return (
      <PheReportModal data-testId="viewReportModal" open={this.state.showViewReportModal} onClose={this.handleViewReportModalClose} maxWidth={"md"}>
        {this.state.isEditLoading && <Loader />}
        <PheReportModalContainer>
          <PheModalHeader>
            <PheCrossImage data-testId="closeReportModal" onClick={this.handleViewReportModalClose} ><img src={PheClose} /></PheCrossImage>
            <PheCrossImage data-testId="editReportModal" onClick={this.handleIsEditReportFormOpen}><img src={PheEdit} /></PheCrossImage>
          </PheModalHeader>
          <PheViewLine></PheViewLine>
          <EditReportForm>
            <EditPheInput >{selectedReport.attributes.report_name}</EditPheInput>
            <EditTestInput style={styles.calView}> 
            {selectedReport.attributes.report_date}
              <img src={calenderIcon} alt="calendar" />
            </EditTestInput>
            {
              !this.renderThirdCardCondition() && <EditTestInput>{this.getViewReportLevel()}</EditTestInput>
            }
            <PheNotesInput >{selectedReport?.attributes?.report_notes}</PheNotesInput>
            <SelectedFilePadding>
              {
                selectedReport?.attributes?.attached_files?.data?.map((fileItem: FileData) => {
                  let fileName = this.getFileName(fileItem.attributes.file)
                  let pdfCheck = this.checkFileType(fileName)
                  return <SelectedFiles onClick={() => this.handleReportOpener(fileItem.attributes.file)}>
                    <FileImage><img src={pdfCheck ? ImageDocument : imageIcon} /></FileImage>
                    <FileName>{fileName}</FileName>
                    <ImageSelectedFile><img src={ImageCloseSmall} /></ImageSelectedFile>
                  </SelectedFiles>
                })
              }
            </SelectedFilePadding>
          </EditReportForm>
        </PheReportModalContainer>
      </PheReportModal>
    )
  }

  renderOption = () => {
    return (
      <Popover elevation={0} open={Boolean(this.state.anchorEl)}
        data-testId="optionPopover"
        anchorEl={this.state.anchorEl}
        onClose={this.handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <OptionContainer>
          <ViewText data-testId="viewReport" onClick={() => this.handleViewReportModalOpen()}>{configJSON.viewText}</ViewText>
          <ModalOptionSeparator></ModalOptionSeparator>
          <ViewText data-testId="deleteReport" onClick={() => this.handleDeleteModalOpen(this.state.selectedReport.id)}>{configJSON.deleteText}</ViewText>
        </OptionContainer>
      </Popover>
    )
  }

  renderReportHeader = (item: PhenylalanineReport & TyrosineReport & GeneticReport) => {
    return (
      <CardContentContainer>
        <Box>
          <PheHeadingContainer>
            <CardHeading noWrap>{item.attributes.report_name}</CardHeading>
            <ThreeDotsImage data-testId="cardModal" onClick={(event) => this.handleDotClick(event, item)}><img src={ThreeDots} /></ThreeDotsImage>
          </PheHeadingContainer>
          <CardDateText>{item.attributes.report_date}</CardDateText>
          <ModalMainSeparator></ModalMainSeparator>
        </Box>
      </CardContentContainer>
    )
  }

  renderFirstCard = () => {
    return (
        <ReportContentCardContainer >
          {
            this.state.pheReport.map((item: PhenylalanineReport, index: number) => {
              return (
                <ReportContentCard>
                  {this.renderReportHeader(item)}
                  <PheLevelContainer>
                    <PheLevelText>{configJSON.pheLevelText}</PheLevelText>
                    <PheLevelQtyText >{item.attributes.phenylalanine_level} </PheLevelQtyText>
                  </PheLevelContainer>
                  <PheImageContainer >
                    {this.renderFileName(item.attributes.attached_files.data, item.attributes.report_name)}
                  </PheImageContainer>       
                  {this.renderOption()}
                </ReportContentCard>
              )
            })}
          <ReportContentAddCard data-testId="addReportPhe" onClick={this.handleAddReportModalOpen} >
            <img src={AddImage} />
            <AddReport>{configJSON.addReport}</AddReport>
            <div style={styles.onboardingStyle }>
            <span style={styles.onboardingWrapper}  ref={this.state.onboardingRefs[3]} ></span>
            </div>
          </ReportContentAddCard>
        </ReportContentCardContainer>
    )
  }

  troubleDialog = () => {
    return (
      <Dialog
        data-testId="troubleDialogError"
        open={this.state.openTroubleDialog}
        style={{ borderRadius: "50px" }}
        PaperProps={{
          style: styles.invalidFileModal
        }}
      >
        <div style={styles.invalidBox}>
          <DialogContent>
            <p style={styles.invalidFileHeader}>
              {configJSON.invalidFileError}</p>
          </DialogContent>
          <Divider style={styles.invalidFileDivider} />
        </div>
        <DialogActions style={styles.invalidFileBox}>
          <button
            onClick={this.cancelTroubleDialog}
            data-testId="cancelBtn"
            style={styles.invaildFileMOdalClose}>
            {configJSON.closeButton}
          </button>
        </DialogActions>
      </Dialog>
    )
  }

  renderImageUpload = () => {
    return (
      <StyleWrapper>
        <Box className="headerAppBar">
          <Box className="leftBox">
            <img className="backBtn" src={backArrowIcon} style={styles.cursorPointer} data-testId="backBtn" onClick={this.handleDocumentOpener} />
            <Typography className="documentHeadingTxt">{this.state.report_name || this.state.selectedReport.attributes.report_name}</Typography>
          </Box>
          <Box className="rightBox">
            <Button className="downloadBtn" data-testId="downloadBtn" onClick={this.handleDownload} >
              <img src={downloadIcon} style={styles.cursorPointer} />
            </Button>
          </Box>
        </Box>
        <Box className="pdfDisplayBox">
          <Box className="displayBox">
            {this.state.selectedReportOpen !== null && (
              <>
                {this.state.selectedReportOpen.endsWith(".pdf") ? (
                     <StyledDocViewer
                    data-testid="docViewer"
                    style={{ width: "100%" }}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{uri:this.state.selectedReportOpen,fileType:"pdf"}]}
                  />

                ) : (
                  <img src={this.state.selectedReportOpen} className="img" alt="Image" />
                )}
              </>
            )}
          </Box>
                  </Box>
      </StyleWrapper>
    )
  }
  renderViewCard = () => {
    return (
        <ReportCard >
          {
            this.state.pheReport.map((item: PhenylalanineReport, index: number) => {
              return (
                <CardCointainer>
                  {this.renderReportHeader(item)}
                  <MaxLevelContainer>
                    <MinLevelText>{configJSON.pheLevelText}</MinLevelText>
                  </MaxLevelContainer>
                  <img src={AddImage} />
                  <AddReport>{configJSON.addReport}</AddReport>
                </CardCointainer>
              )
            })}
          <ReportContentAddCard data-testId="addReportPhe" onClick={this.handleAddReportModalOpen} >
            <img src={AddImage} />
            <MinLevelText>{configJSON.pheLevelText}</MinLevelText>
            <AddReport>{configJSON.addReport}</AddReport>
          </ReportContentAddCard>
        </ReportCard>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <WebOnboarding
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable && !this.state.openOneScreenOnboarding}
        />
        {
          this.state.reportOpener === false ? (
            <ReportContainer>
              <SideBar navigation={this.props.navigation} />
              <Container>
                <WebHeader title={"Reports"} icon={ReportChart} iconTwo={Info} webHeaderCelender={this.handleReportbookDatePicker} isFutuereDate={false} openOnBoarding={this.handleOpneOnboarding} />
                <ReportContenOuterContainer>
                  <ReportContentContainer>
                    <ReportContentHeadingContainer>
                      <Box>
                        <ReportContentHeading>{configJSON.helloText}<span style={styles.userNameText}>{" " + this.state.firstName + " " + this.state.lastName + "!"}</span></ReportContentHeading>
                        <ReportContentSubHeading>{configJSON.subHeadingText}</ReportContentSubHeading>
                      </Box>
                      <ScheduleContainer>
                        <ReportDateText>{this.state.presentDate}</ReportDateText>
                      </ScheduleContainer>
                    </ReportContentHeadingContainer>
                    <ReportTestCategories>
                      {
                        this.state.screenNameArray.map((tabName: string, index: number) => {
                          return (
                            <>
                              {
                                this.renderCardCondition(tabName) ?
                                  <ActivePheReportContainer onClick={() => this.handleActiveButton(tabName)} data-testId="activeBtn">
                                    <div style={styles.onboardingStyle}>
                                      <span style={styles.onboardingWrapper} ref={this.state.onboardingRefs[index]} ></span>
                                    </div>
                                    <PheReportText>{tabName}</PheReportText>
                                  </ActivePheReportContainer>
                                  :
                                  <PheReportContainer onClick={() => this.handleActiveButton(tabName)} data-testId="inActiveBtn">
                                    <div style={styles.onboardingStyle}>
                                      <span style={styles.onboardingWrapper} ref={this.state.onboardingRefs[index]} ></span>
                                    </div>
                                    <PheReportTextInActive>{tabName}</PheReportTextInActive>
                                  </PheReportContainer>
                              }
                            </>
                          )
                        })
                      }
                    </ReportTestCategories>
                    <ReportContentCardContainer>
                      {
                        this.renderFirstCardCondition() &&
                        this.renderFirstCard()
                      }
                      {
                        this.renderSecondCardCondition() &&
                        <ReportContentCardContainer>
                          {
                            this.state.tyrReport.map((item: TyrosineReport) => {
                              return (
                                <ReportContentCard>
                                  {this.renderReportHeader(item)}
                                  <PheLevelContainer>
                                    <PheLevelText>{configJSON.tyrLevelText}</PheLevelText>
                                    <PheLevelQtyText>{item.attributes.tyrosine_level}</PheLevelQtyText>
                                  </PheLevelContainer>
                                  <PheImageContainer>
                                    {this.renderFileName(item.attributes.attached_files.data,item.attributes.report_name)}
                                  </PheImageContainer>
                                  {this.renderOption()}
                                </ReportContentCard>
                              )
                            })
                          }
                          <ReportContentAddCard data-testId="addReportTry" onClick={this.handleAddReportModalOpen}>
                            <img src={AddImage} />
                            <AddReport>{configJSON.addReport}</AddReport>
                          </ReportContentAddCard>
                        </ReportContentCardContainer>
                      }
                      {
                        this.renderThirdCardCondition() &&
                        <ReportContentCardContainer>
                          {
                            this.state.geneticReport.map((item: GeneticReport) => {
                              return (
                                <ReportContentCard>
                                  {this.renderReportHeader(item)}
                                  <GenImageContainer>
                                    {this.renderFileName(item.attributes.attached_files.data,item.attributes.report_name)}
                                  </GenImageContainer>
                                  {this.renderOption()}
                                </ReportContentCard>
                              )
                            })
                          }
                          <ReportContentAddCard data-testId="addReportGen" onClick={this.handleAddReportModalOpen}>
                            <img src={AddImage} />
                            <AddReport>{configJSON.addReport}</AddReport>
                          </ReportContentAddCard>
                        </ReportContentCardContainer>
                      }
                    </ReportContentCardContainer>
                  </ReportContentContainer>
                  {this.reportModal()}
                  {this.reportSuccessModal()}
                  {this.deleteReportModal()}
                  {this.viewReportModal()}
                  {this.exceedUploadModal()}
                  {this.troubleDialog()}
                  {this.renderViewCard()}
                </ReportContenOuterContainer>
              </Container>
            </ReportContainer>
          ) : (
            this.renderImageUpload()
          )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const styles: Record<string, React.CSSProperties> = {
  fieldStyle: {
    width: "100%",
    padding: "0px 16px",
    background: "#F1F5F9",
    borderRadius: "8px",
    height: "50px"
  },

  noteFieldStyle: {
    width: "100%",
    padding: "14px 16px",
    background: "#F1F5F9",
    borderRadius: "8px",
    minHeight: "116px",
    height: "auto"
  },

  userNameText: {
    fontWeight: 400
  },

  dialogFirstInputField: {
    width: "100%",
    borderRadius: "8px",
  },

  uploadContainer: {
    display: "flex",
    background: "#F8FAFC",
    borderRadius: "50px",
    padding: "11px 14px",
    minHeight:"20px"
  },

  uploadBox: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Raleway",
    whiteSpace: "nowrap",
    justifyContent: "center",
    gap: "10px"
  },

  uploadImageIcon: {
    minHeight: "16px",
    maxWidth: "18px",
    bottom: 16,
    cursor: "pointer",
    marginTop: "-35px",
    marginRight: "-8px"
  },

  hideBrowser: {
    display: "none"
  },

  cursorPointer: {
    cursor: "pointer"
  },

  calView: {
    display: "flex",
    justifyContent: "space-between"
  },

  validationError: {
    fontSize: "0.75rem",
    fontWeight: "400px",
    color: "#f44336",
    marginTop: "3px",
    lineHeight: 2.1,
    fontFamily: "Raleway"
  },

  fileList: {
    display: "flex",
    gap: "10px"
  },

  invaildFileMOdalClose: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "#007AFF",
    backgroundColor: "transparent",
    fontWeight: 700,
    cursor: "pointer",
    height: "35px",
    border: "none",
    marginTop: "5px"
  },

  invalidFileHeader: {
    fontSize: "22px",
    color: "#334155",
    fontFamily: "Raleway",
    margin: "0px",
    fontWeight: 600,
    lineHeight: "33px",
    letterSpacing: "-0.4px",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off"
  },

  invalidFileDivider: {
    color: "#E2E8F0",
    width: "90%",
    marginLeft: "23px"
  },

  invalidFileModal: {
    borderRadius: "24px",
    display: "flex",
    padding: "6px 9px",
    flexDirection: "column",
    width: "483px",
    alignItems: "center",
    justifyContent: "center"
  },

  invalidBox: {
    paddingBottom: "10px",
    width: "100%"
  },
  invalidFileBox: {
    justifyContent: "center"
  },
  suceessDivider: {
    color: "#E2E8F0",
    width: "100%"
  },
  onboardingStyle: {
    display: "flex", 
    alignSelf: "center",
    position:"relative", 
    height:"5px",
    width:"5px"
  },
  onboardingWrapper: {
    height:"50%",
  }
}

const CustomDatePicker = styled(KeyboardDatePicker)({
  "& .MuiOutlinedInput-adornedEnd": {
    background: "#F1F5F9"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px"
  },
  "& .MuiInputBase-input:focus": {
    pointerEvents: "none"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px"
  }
})

const ReportContainer = styled(Box)({
  display: "flex",
  width: "100%"
});

const ReportSuccess = styled(Dialog)({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiDialog-paper": {
    borderRadius: "24px"
  }
})

const ReportSuccessContainer = styled(Box)({
  display: "flex",
  gap: "26px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "16px 0px",
  backgroundColor: "#E5E5E5"

})

const ReportSuccessContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

const ReportSuccessHeading = styled(Box)({
  color: "#1E293B",
  textAlign: "center",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "capitalize",
  marginBottom: "5px",
  padding: "0px 16px"
})

const ReportSuccessMessage = styled(Box)({
  color: "#334155",
  textAlign: "center",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "22px",
  letterSpacing: "-0.4px",
  padding: "0px 16px",
  paddingBottom: "16px !important"
})

const ReportCloseImage = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  margin: "3%",
  marginBottom: "0px",
  cursor: "pointer"
})

const CloseButton = styled(Typography)({
  color: "#007AFF",
  textAlign: "center",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  cursor: "pointer",
  padding: "0px 10px",
  marginTop: "12px"
})

const ReportDelete = styled(Dialog)({
  borderRadius: "50px",
  "& .MuiDialog-paperWidthSm": {
    borderRadius: "24px"
  },
});

const ReportDeleteContainer = styled(Box)({
  borderBottom: "1px solid #E2E8F0",
  paddingBottom: "20px"
})

const OptionContainer = withStyles({
  root: {
    borderRadius: "16px",
    width: "112px",
    height: "88.5px",
    border: "1px solid #E2E8F0"
  }
})(Box);

const ReportDeleteContent = styled(DialogContent)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#0F172A",
  fontFamily: "Raleway",
  lineHeight: "26px"
})

const ReportDeleteHeading = styled(`p`)({
  textTransform: "capitalize",
  fontSize: "24px",
  fontWeight: 700,
  color: "#0F172A",
  fontFamily: "Raleway",
  margin: "0px"
})

const ButtonContainer = styled(DialogActions)({
  flexWrap: "wrap",
  margin: "15px 13px 15px"
})

const CancelButton = styled("button")({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Raleway",
  color: "#0F172A",
  backgroundColor: "#FCFCFC",
  border: "none",
  width: "140px",
  height: "56px",
  cursor: "pointer"
})

const UploadBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  flexWrap: "wrap",
  justifyContent: "center",
  width: "100%",
  overflowX: "auto",
  whiteSpace: "nowrap",
  minHeight:"60px",
  paddingTop: "5px",
  "&::-webkit-scrollbar": {
    width: "8px",
    height:"8px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#F1F5F9"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#94A3B8",
    borderRadius: "9px"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#90A4AE"
  }
})

const LogoutButton = styled("button")({
  backgroundColor: "#DC2626",
  border: "none",
  width: "151px",
  height: "56px",
  borderRadius: "50px",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Raleway",
  color: "#fff",
  cursor: "pointer"
})

const PheReportModal = styled(Dialog)({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center"
})

const PheReportModalContainer = styled(Box)({
  display: "flex",
  padding: "24px 0px 40px 0px",
  flexDirection: "column",
  width: "780px",
  "@media (max-width:1240px)": {
    width: "750px",
  },
  "@media (max-width:991px)": {
    width: "700px",
  },
  "@media (max-width:768px)": {
    width: "600px",
  },
  "@media (max-width:676px)": {
    width: "550px",
  },
  "@media (max-width:576px)": {
    width: "500px",
  },
  "@media (max-width:520px)": {
    width: "450px",
  },
  "@media (max-width:476px)": {
    width: "400px",
  },
  "@media (max-width:420px)": {
    width: "350px",
  },
  "@media (max-width:350px)": {
    width: "300px",
  },
  "@media (max-width:300px)": {
    width: "300px",
  },
})

const PheModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px 40px 24px 40px",
  borderBottom: "1px solid #E2E8F0"
})

const PheViewLine = styled(Box)({
  width: "100%",
  borderBottom: "1px solid #E2E8F0"
})

const PheCrossImage = styled(Box)({
  display: "flex",
  padding: "10px",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
  borderRadius: "50px",
  backgroundColor: "#F1F5F9"
})

const EditPheInput = styled(Typography)({
  display: "flex",
  padding: "14px 16px",
  alignItems: "center",
  borderRadius: "8px",
  background: "#F1F5F9",
  border: "none",
  marginTop: "16px"
})

const EditTestInput = styled(Typography)({
  display: "flex",
  padding: "14px 16px",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "8px",
  background: "#F1F5F9",
  border: "none"
})

const NoteInput = styled(TextField)({
  "& .MuiInput-root": {
    display: "flex",
    alignItems: "flex-start"
  }
})

const PheNotesInput = styled(Typography)({
  padding: "8px 14px",
  alignItems: "center",
  minHeight: "100px",
  height: "auto",
  alignSelf: "stretch",
  borderRadius: "8px",
  background: "#F1F5F9",
  border: "none",
  wordWrap: "break-word"
})

const SelectedFiles = styled(Box)({
  display: "inline-flex",
  gap: "4px",
  padding: "4px 8px",
  borderRadius: "50px",
  backgroundColor: "#F1F5F9",
  alignItems: "center",
  position: "relative"
})

const ImageSelectedFile = styled(Box)({
  position: "absolute",
  bottom: 30,
  right: -5
})

const SelectedFilePadding = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "15px",
  padding: "10px 0px",
  overflowX: "auto",
  whiteSpace: "nowrap",
  "&::-webkit-scrollbar": {
    width: "8px",
    height:"8px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#F1F5F9"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#94A3B8",
    borderRadius: "9px"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#90A4AE"
  }
})

const StyledDocViewer = styled(DocViewer)({
    width: "468px",
    height: "671px",
    margin: "25px 0px",
    borderRadius: "12.1px",
  "& .kyGmrG": {
    display: "none"
  },
  "& .dTuydp": {
    display: "none"
  }
})

const FileName = styled(Typography)({
  color: "#1E293B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  cursor: "pointer"
})

const FileImage = styled(Box)({
  display: "flex",
  width: "24px",
  height: "24px",
  padding: "3px 5.017px 2.632px 5px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
})

const CustomModal = styled(Dialog)({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  overflow: "scroll",
  borderRadius: "24px",
  "& .MuiDialog-paper": {
    borderRadius: "24px"
  }
})

const DialogHeadingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px"
})

const ReportModalContainer = styled(Box)({
  display: "flex",
  width: "808px",
  backgroundColor: "#fff",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  paddingBottom: "16px",
  "@media (max-width:991px)": {
    width: "700px",
  },
  "@media (max-width:768px)": {
    width: "500px",
  },
  "@media (max-width:576px)": {
    width: "400px",
  },

  "@media (max-width:440px)": {
    width: "350px",
  },
  "@media (max-width:420px)": {
    width: "300px",
  },
  "@media (max-width:376px)": {
    width: "200px",
  },
})

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%"
})

const UploadReportContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "24px 40px",
  alignItems: "center"
})

const UploadReportHeading = styled(Box)({
  color: "#000",
  fontFamily: "Raleway",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.12px"
})

const UploadReportSubHeading = styled(Box)({
  color: "#334155",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "-0.154px",
  width: "70%",
  "@media (max-width:576px)": {
    width: "90%"
  }
})

const CloseButtonContainer = styled(Box)({
  width: "24px",
  height: "24px"
})

const TestDateInput = styled("input")({
  display: "flex",
  padding: "14px 16px",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "8px",
  background: "#F1F5F9",
  border: "none"
})

const ReportForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "0px 40px",
  "@media (max-width:576px)": {
    padding: "20px"
  }
})

const EditReportForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "8px 40px 0px 40px"
})

const DragImage = styled(Box)({
  width: "48px",
  height: "48px",
  flexShrink: 0
})

const SelectFileText = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
})

const DragFilesText = styled(Typography)({
  color: "#334155",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "center"
})

const SupportDocumentsText = styled(Typography)({
  color: "#64748B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "center",
  letterSpacing: "0.132px"
})

const BrowseFileContainer = styled(Box)({
  display: "flex",
  height: "24px",
  paddingRight: "1px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
})

const AddBrowseImageContainer = styled(Box)({
  width: "24px",
  height: "24px"
})

const BrowseFileUploadText = styled(Typography)({
  color: "#13689E",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  cursor: "pointer"
})

const SubmitButton = styled("button")({
  display: "flex",
  padding: "12px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "50px",
  backgroundColor: "#13689E",
  border: "none",
  cursor: "pointer"
})

const SubmitButtonText = styled(Typography)({
  color: "#fff",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "capitalize"
})

const ReportSeparatorLine = styled(Box)({
  height: "0.5px",
  width: "100%",
  backgroundColor: "#E2E8F0",
  marginBottom: "16px"
})

const DragDropContainer = styled(Box)({
  display: "flex",
  padding: "32px 24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
  borderRadius: "16px",
  overFlow: "scroll"
})


const Container = styled(Box)({
  width: "100%"
});


const ReportContenOuterContainer = styled(Box)({
  marginTop: "25px",
  padding: "0px 28px"
})

const ReportContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  alignItems: "flex-start",
})

const ReportContentHeadingContainer = styled(Box)({
  display: "flex",
  gap: "24px",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "@media (max-width:450px)": {
    flexDirection: "column",
    alignItems: "flex-start"
  },
})

const ReportContentHeading = styled(Typography)({
  color: "#475569",
  fontFamily: "Raleway",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  wordBreak: "break-word",
  marginBottom: "8px"
})

const ReportContentSubHeading = styled(Typography)({
  color: "#94A3B8",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
})

const ReportTestCategories = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "24px",
  width: "100%",
  borderBottom: "1px #E2E8F0 solid",
  "@media (max-width:824px)": {
    width: "90%"
  },
  "@media (max-width:476px)": {
    flexDirection: "column"
  }
})

const ActivePheReportContainer = styled(Box)({
  display: "flex",
  padding: "14px 16px 18px 16px",
  alignItems: "center",
  gap: "8px",
  width: "100%",
  justifyContent: "center",
  borderBottom: "3px solid #13689E",
  cursor: "pointer",
  "@media (max-width:480px)": {
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
})

const PheReportContainer = styled(Box)({
  display: "flex",
  padding: "14px 16px 18px 16px",
  alignItems: "center",
  gap: "8px",
  width: "100%",
  justifyContent: "center",
  cursor: "pointer",
  "@media (max-width:480px)": {
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
})

const PheReportText = styled(Typography)({
  color: "#13689E",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px"
})

const PheReportTextInActive = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px"
})

const PheHeadingContainer = styled(Box)({
  display: "flex",
  gap: "12px",
  justifyContent: "space-between",
  width: "328px",
  "@media (max-width:491px)": {
    width: "300px"
  },
  "@media (max-width:450px)": {
    width: "270px"
  },
  "@media (max-width:420px)": {
    width: "230px"
  }
})

const ReportContentCardContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
  flexWrap: "wrap",
})

const ReportContentCard = styled(Box)({
  display: "flex",
  padding: "16px",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  border: "0.5px solid #E2E8F0",
  backgroundColor: "#fff",
  width: "326px",
  maxheight: "169px",
  position: "relative",
  height: "160px",
  "@media (max-width:491px)": {
    width: "300px"
  },
  "@media (max-width:450px)": {
    width: "270px"
  },
  "@media (max-width:420px)": {
    width: "230px"
  }
})

const CardContentContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "12px",
  alignSelf: "stretch",
  borderRadius: "8px",
  height: "fixed"
})

const CardHeading = styled(Typography)({
  color: "#000",
  fontFamily: "Raleway",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  width: "100%",
  height: "fixed"
})

const CardDateText = styled(Typography)({
  color: "#64748B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px"
})

const PheLevelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
  flex: "1 0 0"
})

const PheLevelText = styled(Box)({
  color: "#1E293B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 550,
  lineHeight: "16px"
})

const PheLevelQtyText = styled(Box)({
  color: "#64748B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px"
})

const PheImageContainer = styled(Box)({
  display: "flex",
  gap: "4px",
  width: "100%",
  overflowX: "auto",
  whiteSpace: "nowrap",
  "&::-webkit-scrollbar": {
    width: "8px",
    height:"8px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#F1F5F9"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#94A3B8",
    borderRadius: "9px"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#90A4AE"
  }
})

const GenImageContainer = styled(Box)({
  display: "flex",
  gap: "4px",
  width: "100%",
  overflowX: "auto",
    whiteSpace: "nowrap",
  marginTop: "74px",
  "&::-webkit-scrollbar": {
    width: "8px",
    height:"8px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#F1F5F9"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#94A3B8",
    borderRadius: "9px"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#90A4AE"
  }
})

const PheImageText = styled(Box)({
  color: "#0F172A",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px",
  overflowY: "hidden",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  cursor: "pointer"
})

const ReportContentAddCard = styled(Box)({
  display: "flex",
  width: "358px",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  border: "0.5px solid #CBD5E1",
  backgroundColor: "#F1F5F9",
  maxheight: "169px",
  position: "relative",
  height: "190px",
  marginBottom:"20px",
  "@media (max-width:491px)": {
    width: "330px"
  },
  "@media (max-width:450px)": {
    width: "302px"
  },
  "@media (max-width:420px)": {
    width: "262px"
  }
})

const ScheduleContainer = styled(Box)({
  display: "flex",
  gap: "16px"
})

const AddReport = styled(Box)({
  color: "#000",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "capitalize"
})

const ThreeDotsImage = styled(Box)({
  width: "24px",
  height: "24px",
  cursor: "pointer"
})

const ReportDateText = styled(Box)({
  color: "#334155",
  textAlign: "right",
  fontFamily: "Raleway",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "28.18px"
})

const ViewText = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  color: "#13689E",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "22px",
  cursor: "pointer",
  padding: "11px"
})

const ModalOptionSeparator = styled(Box)({
  height: "0.5px",
  alignSelf: "stretch",
  borderRadius: "4px",
  background: "#E2E8F0",
  width: "75%",
  margin: "auto"
})

const ModalMainSeparator = styled(Box)({
  height: "0.5px",
  alignSelf: "stretch",
  borderRadius: "4px",
  background: "#E2E8F0",
  width: "100%",
  marginTop: "12px"
})

const ModelInnerBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  background: 'rgb(229, 229, 229)',
  border: 'none',
  padding: 0,
  borderRadius: "16px",
  minHeight: "126px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '95%',
  },
});

const ModelErrorOne = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",
  },
});

const ModelErrorTwo = styled(Typography)({
  color: "#334155",
  fontSize: "14px",
  fontFamily: "Raleway",
  fontWeight: 500,
  '@media (max-width: 400px)': {
    fontSize: "12px",
  },
});

const ModelErrorBorder = styled('p')({
  borderBottom: "0.33px solid #3C3C435C",
  margin: "16px 0px 10px 0px",
  width: "100%"
});

const FileUploadLabel = styled('label')({
  display: "flex",
  margin: "auto"
})

const StyleWrapper = styled(Box)({
  "& .headerAppBar": {
    height: "min-content",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 50px",
    boxShadow: "0px 2px 8px 0px #00000014",
    "@media (max-width:450px)": {
      padding: "20px 20px"
    }
  },
  "& .documentHeadingTxt": {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#111827",
    "@media (max-width:400px)": {
      fontSize: "15px"
    }
  },
  "& .leftBox": {
    display: "flex",
    alignItems: "center"
  },
  "& .backBtn": {
    minWidth: "0px",
    marginRight: "8px",
    "@media (max-width: 450px)": {
      marginRight: "5px"
    }
  },
  "& .pdfDisplayBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#f0f0f0"
  },
  "& .displayBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .img": {
    width: "468px",
    height: "671px",
    margin: "45px 0px",
    borderRadius:"12px",
    "@media (max-width: 650px)": {
      width: "70%"
    },
    "@media (max-width: 500px)": {
      width: "90%"
    }
  },
  "& .pdfViewer": {
    width: "468px",
    height: "671px",
    margin: "25px 0px",
    borderRadius:"12.1px",
    "& .toolbar": {
      display: "none !important",
      height: "0px !important"
    },
    "@media (max-width: 650px)": {
      width: "70%"
    },
    "@media (max-width: 500px)": {
      width: "90%"
    },
    "@media (max-width: 400px)": {
      width: "95%"
    }
  }
});

const ReportCard = styled(Box)({
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
  flexWrap: "wrap",
  display:"none"
})

const CardCointainer = styled(Box)({
  display: "flex",
  padding: "16px",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  border: "0.5px solid #E2E8F0",
  backgroundColor: "#fff",
  width: "326px",
  maxheight: "169px",
  position: "relative",
  height: "160px",
  "@media (max-width:491px)": {
    width: "300px"
  },
  "@media (max-width:450px)": {
    width: "270px"
  },
  "@media (max-width:420px)": {
    width: "230px"
  }
})

const MaxLevelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
  flex: "1 0 0"
})

const MinLevelText = styled(Box)({
  color: "#1E293B",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 550,
  lineHeight: "16px"
})
const viewContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  width: "358px",
  justifyContent: "center",
  flexDirection: "column",
  gap: "8px",
  maxheight: "169px",
  borderRadius: "12px",
  border: "0.5px solid #CBD5E1",
  backgroundColor: "#F1F5F9",
  position: "relative",
  height: "190px",
  marginBottom:"20px",
  "@media (max-width:450px)": {
    width: "302px"
  },
  "@media (max-width:420px)": {
    width: "262px"
  }
})


// Customizable Area End