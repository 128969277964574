import React from "react";
// Customizable Area Start
import {
    Button,
    TextField,
    Grid,
    Divider,
    FormHelperText,
    Box,
    Avatar,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    InputAdornment,
    styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import "react-datepicker/dist/react-datepicker.css";
import SideBar from "../../../components/src/SideBar";
import WebHeader from "../../../components/src/WebHeader.web";
import GeoNotification from "../../../components/src/GeoNotification.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import Loader from "../../../components/src/Loader.web";
import {
    profileIcon,
    strickeIcon,
    msgIcon,
    notificationIcon,
    calender,
    Avtar, strickImg
} from "../src/assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { withStyles } from "@material-ui/styles";

const CssTextFieldCodeCode = withStyles({
    root: {
        border: '1px solid #red',
        overflow: 'hidden',
        fontFamily: "Raleway",
        '& #filled-required': {
            backgroundColor: "#F5F9FD",
            fontWeight: 600,
        },
        '& label': {
            fontFamily: 'Raleway',
        },
        '&:hover': {
            '& .MuiFilledInput-root': {
                background: 'none',
            }
        },
        '&$focused': {
            color: '#64748B',
        },
    },

    focused: {},
})(TextField);


// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#64748B",
            contrastText: "#fff"
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                color: '#0F172A',
                backgroundColor: 'grey[400]',
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: 500,
                '&.Mui-disabled': {
                    color: '#0F172A',
                    backgroundColor: 'grey[400]',
                    fontFamily: "Raleway"
                },
            }
        }
    }
});

import CustomisableUserProfilesController, {
    Props,
} from "./CustomisableUserProfileController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

backgroundCss = (code:string) => {
    return code ? "#FFFFFF" : "#F1F5F9"
}


validationCodeOpacity = (code:string) => {
    return  code ? 1 : 0.4
}

secondDropIcon = (secondDropOpen:boolean) => {
   return secondDropOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
}

    /* istanbul ignore next */
    validCode = () => {
        const { validationMsg, validationTrue, code, secondDropOpen } = this.state;

        let inputBorderStyle;
        let verifyBtnStyle;
        let verifyBtnText;
        let iconToShow;

        if (validationMsg) {
            inputBorderStyle = { border: "none" };
            verifyBtnStyle = { ...styles.veryfyBtn, opacity: 0.4 };
            verifyBtnText = "Verify";
            iconToShow = <ClearIcon style={styles.clearIconStyle as React.CSSProperties} onClick={this.removeCode} data-test-id="removeInvalidCode" />;
        } else if (validationTrue) {
            inputBorderStyle = { border: "1px solid green" };
            verifyBtnStyle = { ...styles.veryfyBtn, backgroundColor: "#f4faffbd", color: "#DC2626" };
            verifyBtnText = "Remove";
            iconToShow = <CheckIcon style={styles.checkIconStyle as React.CSSProperties} />;
        } else {
            inputBorderStyle = { border: "none" };
            verifyBtnStyle = {
                ...styles.veryfyBtn,opacity:this.validationCodeOpacity(code)            };
            verifyBtnText = "Verify";
            iconToShow = null;
        }
     

        return (
            <>
                <Grid style={{ marginBottom: "44px", marginTop: "40px" }}>
                    <Grid onClick={this.dropDownToggleSec} data-test-id="dropDownToggleSec"  ref={this.state.onboardingRefs[3]} style={{...secondDropOpen ? styles.inputOfDropOpen : styles.inputOfDrop,borderBottom:"1px solid rgb(203, 213, 225)"}}>
                        <span style={{ ...styles.optionText, fontWeight: 500 }}>Invitation Code</span>
                    
                        {this.secondDropIcon(secondDropOpen)}
                    </Grid>

                    {secondDropOpen && (
                        <Grid style={styles.dropDownGridSecCompt}>
                            <Grid container spacing={3} justifyContent="space-between" style={{ padding: "10px", flexWrap: "wrap" }} >
                                <Grid item lg={9} md={9} sm={12} style={{ width: "100%" }}>
                                    <Grid style={{ position: "relative",marginBottom:"3px" }}  ref={this.state.onboardingRefs[3]}>
                                        <input
                                            type="text"
                                            style={{
                                                ...styles.inputStyle, backgroundColor: this.backgroundCss(code),
                                                ...inputBorderStyle,
                                                border:  validationMsg  ? "1px solid #DC2626" : validationTrue ? "1px solid #34D399":code ? "1px solid grey" : "transparent"} as React.CSSProperties}
                                            name="code"
                                            onChange={this.verifyCodeChange}
                                            value={code}
                                            data-test-id="verifyCodeChange"
                                            disabled={validationTrue}
                                            placeholder="Enter Invitation Code"
                                           
                                        />
                                        {iconToShow}

                                    </Grid>
                                    <div style={{marginLeft:"7px"}}>{this.errrosForvalidCode()}</div>

                                </Grid>
                                <Grid item lg={3} md={3} sm={12} style={{ width: "100%" }}>
                                    <button
                                        style={verifyBtnStyle}
                                        disabled={!code}
                                        onClick={validationTrue ? this.removeCode : this.verifyCode}
                                        data-test-id="verifyCode"
                                        type="button"
                                    >
                                        {verifyBtnText}
                                    </button>

                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }


    errrosForvalidCode = () => {
        const { errors, validationMsg, validationTrue } = this.state
        return (
            <>
                {(errors.code || validationMsg || validationTrue) && <span style={{ ...styles.errorText, color: validationTrue ? "#10B981" : "#DC2626" }}>
                    {errors.code || validationMsg || (validationTrue && "Invitation code added")}</span>}</>
        )
    }

    saveBtn = () => {


        return (
            <>
                <button
                    style={{ ...styles.saveBtn, opacity: this.state.validationMsg.length > 1 ? 0.4 : 1 }}
                    type="submit"
                    disabled={this.state.validationMsg.length > 1}
                >
                    Save
                </button>
            </>
        );
    };

    ageSelect = () => {
        const { errors } = this.state;
        return (
            <Grid style={{ marginBottom: errors.age ? "4.3%" : "40px" }}>
                <Grid onClick={this.dropDownToggle} data-test-id="dropDownToggle" style={{...this.state.firstDropOpen ? styles.inputOfDropOpen : styles.inputOfDrop,borderBottom:"1px solid  rgb(203, 213, 225)"}}>
                    <span style={{ ...styles.optionTextSec,paddingLeft: "0px",color: '#0F172A',
                                fontFamily: 'Raleway',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '22px',
                                textTransform: 'capitalize' }}>{this.state.age ? this.state.age : "Age Group"}</span>
                    {this.state.firstDropOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Grid>

                {this.state.firstDropOpen && (
                    <Grid style={styles.dropDownGrid}>
                        {this.state.ageList.map((value: { age_group: string, id: string }, index: number) => (
                <p key={index} style={{ ...styles.optionTextSec, backgroundColor: this.state.age === value.age_group ? "rgb(228, 244, 255)" : (this.state.age === value.age_group ? "rgb(228, 244, 255)" : "#F5F9FD") }} onClick={() => this.dropDownOpenClose(value.id, value.age_group)} 
                            
                            data-test-id="dropDownOpenClose">
                                {value.age_group}
                            </p>
                        ))}
                    </Grid>
                )}
            </Grid>
        )
    }

    thirdDropOPenBox = () => {
        return (<>
            {this.state.thirdDropOpen && (
                <>
                    <Grid style={styles.dropDownGrid}>
                        <p
                            style={{
                                ...styles.optionTextSec,
                                backgroundColor: this.state.caregiver === "No" ? "rgb(228, 244, 255)" : "none",
                            }}
                            onClick={() => this.dropDownOpenCloseFirst("No")}
                            data-test-id="dropDownOpenCloseFirsts"
                        >
                            No
                        </p>
                        <p style={{ ...styles.optionTextSec, backgroundColor: this.state.caregiver === "Yes" ? "rgb(228, 244, 255)" : "none" }} data-test-id="dropDownOpenCloseFirst"  onClick={() => this.dropDownOpenCloseFirst("Yes")}>
                            Yes
                        </p>

                    </Grid>
                </>
            )}
        </>)
    }

    caregiver = () => {
        const { errors } = this.state;        
        return (
            <Grid>

                <Grid>
                    {this.state.caregiver ?
                        <Grid  data-test-id='dropDownFirst' onClick={this.dropDownToggleFirst}
                            style={{

                                ...styles.inputOfDrop,
                                borderRadius: this.state.thirdDropOpen ? "8px 8px 0px 0px" : "8px",borderBottom:"1px solid  rgb(203, 213, 225)"
                            }}
                            >

                            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", }}>
                                <div  style={{
                                    color: "rgba(0, 0, 0, 0.54)", fontWeight: 400,
                                    fontSize: "12px", fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, paddingBottom: "5px"
                                }}>Are You A Caregiver?
                                </div>

                                <span style={{ ...styles.optionText, fontWeight: 500, fontSize: "14px" }}>{this.state.caregiver}</span>

                            </div>
                            {this.state.thirdDropOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Grid> :
                        <Grid onClick={this.dropDownToggleFirst} data-test-id="dropDownToggleFirst"
                            style={{
                                ...(this.state.thirdDropOpen ? styles.inputOfDropOpen : styles.inputOfDrop),
                                border: errors.caregiver ? "1px solid #F87171" : "1px solid #CBD5E1"
                            }}
                        >
                            <span  ref={this.state.onboardingRefs[1]}   style={{
                                ...styles.optionText, padding: "2% 0px", color: '#0F172A',
                                fontFamily: 'Raleway',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '22px',
                                textTransform: 'capitalize'
                            }}>{"Are You A Caregiver?"}</span>
                            {this.state.thirdDropOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Grid>}

                    {this.  thirdDropOPenBox()}

                    {errors.caregiver && <p style={{ ...styles.errorText, marginTop: "5px", color: "rgb(220, 38, 38)" }}>{errors.caregiver}</p>}
                </Grid>

            </Grid>
        )
    }

    forCaregiver = () => {
                const { errors } = this.state;
        const CssTextFieldCode = CssTextFieldCodeCode as any
        return (
            <>
                {this.state.caregiver == "Yes" &&
                    <>
                        <Grid>
                            <CssTextFieldCode
                                InputProps={{ disableUnderline: true }}
                                style={{ ...styles.inputbox, marginTop: "41px", border: errors.caregiverName ? "1px solid #F87171" : "1px solid #CBD5E1" }}

                                value={this.state.caregiverName}
                                name="caregiverName"
                                onChange={this.handleChange}
                                id="filled-required"
                                label="Name Of Caregiver"
                                variant="filled"
                                data-test-id="caregiverNameChange"
                                inputProps={{
                                    color: "rgb(51, 51, 57)",
                                }} />
                            <FormHelperText style={styles.errorText}>{errors.caregiverName}</FormHelperText>
                                </Grid>

                        <Grid>
                                <CssTextFieldCode
                                InputProps={{ disableUnderline: true }}
                                style={{ ...styles.inputbox, marginTop: errors.caregiverName ? "18px" : "38px" , border: errors.caregiverEmail ? "1px solid #F87171" : "1px solid #CBD5E1" }}

                                value={this.state.caregiverEmail}
                                name="caregiverEmail"
                                onChange={this.handleChange}
                                id="filled-required"
                                label="Caregiver Email"
                                variant="filled"
                                data-test-id="caregiverNameChange"
                                inputProps={{
                                    color: "rgb(51, 51, 57)",
                                }}
                            />
                         <FormHelperText style={styles.errorText}>{errors.caregiverEmail}</FormHelperText>
                        </Grid>

                        <Grid>
                            <Box style={{ marginBottom: errors.caregiverPhone ? "0px" : "5px" ,marginTop:"38px"}}>
                            <Box style={{ display: "flex",gap: "10px", alignItems: "center" , position:"relative"}}>
                                                     <DropDownWrapper1 data-test-id="drop1">

                                                        {this.dropDownModalRender1()}
                                                        {this.state.cDropdownOpen && (
                                                            <Box className="dropDownConatiner">
                                                                <TextField
                                                                    value={this.state.cSearchData}
                                                                    onChange={this.careGiverHandleSearchData}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <SearchIcon className="searchDataIcon" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    data-test-id="searchDataId1"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className="searchField"
                                                                    placeholder="Search a country"
                                                                    style={{ border: 'none' }}
                                                                />
                                                                <Divider className="dividerColor" />
                                                                <Box className="dropDownOptions">
                                                                    {this.state.filteredData.map((country,countryValue: number) => (
                                                                        <Box className="optionsValue"
                                                                            data-test-id="countrySelectId1"
                                                                            key={countryValue}
                                                                            onClick={() => this.careGiverHandleCountrySelect(country, country.phone_code)}
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    country?.name === this.state.careGiverCountry.name
                                                                                        ? "active"
                                                                                        : "updated"
                                                                                }
                                                                            >
                                                                                <span  data-test-id="countryName" className="phoneCode" >{country.phone_code}</span> <span>{country.name}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </DropDownWrapper1>
                                                    <CssTextFieldCode
                                        InputProps={{ disableUnderline: true }}
                                        style={{ ...styles.inputbox }}

                                        value={this.state.caregiverPhone}
                                        name="caregiverPhone"
                                        onChange={this.handleChange}
                                        id="filled-required"
                                        label="Caregiver Mobile Number"
                                        variant="filled"
                                        data-test-id="caregiverNameChange"
                                        inputProps={{
                                            color: "rgb(51, 51, 57)",
                                        }} />
                                     </Box>
                            </Box>
                            <FormHelperText style={styles.errorText}>{errors.caregiverPhone}</FormHelperText>
                        </Grid>
                    </>}</>
        )
    };

    openDilog = () => {
        return (
            <Dialog
                open={this.state.openModel}
                style={{ borderRadius: "50px" }}
                PaperProps={{
                    style: {
                        borderRadius: "24px",
                        display: 'flex',
                        width: '483px',
                        padding: '6px 9px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                }}
            >
                <div style={{paddingBottom: "10px", width: "100%" }}>

                    <DialogContent>
                        <p style={{ fontSize: "22px", fontWeight: 600, color: "#334155", fontFamily: "Raleway", margin: "0px", textAlign: "center", lineHeight: '33px', letterSpacing: '-0.4px', fontFeatureSettings: "'clig' off, 'liga' off", }}>
                            {this.state.successMsg ? `Profile details saved successfully! Your information is now up to date.` : 'Profile picture updated'}
                        </p>
                    </DialogContent>
                    <Divider style={{color:"#E2E8F0",width:"90%",marginLeft:"23px"}}/>

                </div>
                <DialogActions style={{ justifyContent: "center" }}>
                    <button
                        onClick={this.cancelBtn}
                        data-test-id="cancelBtn1"
                        style={{
                            fontSize: "18px", fontWeight: 700, fontFamily: "Inter", color: "#007AFF",
                            backgroundColor: "transparent", border: "none", cursor: "pointer", height: "35px", marginTop: "5px"
                        }}>
                        Close
                    </button>

                </DialogActions>
            </Dialog>
        )
    }
    dropDownModalRender = () => {
                return (
          <Box className="dropDownHeader" data-test-id="toggleCountryId"
            onClick={this.toggleDropdown}
          >
            {this.state.countryCodeData ? (
              <Box className="selectedCountry">
                <Typography className="selectedPhoneCode">  {this.state.selectedCountry?.phone_code}</Typography>
              </Box>
            ) : (
              <Typography>{this.state.isCountryData}</Typography>
            )}
            <span className="selectDropdown"><ExpandMoreIcon />
            </span>
          </Box>
        )
      }

      dropDownModalRender1 = () => {
        return (
          <Box className="dropDownHeader" data-test-id="toggleCountryId1"
            onClick={this.careGiverToggleDropdown}
          >
            {this.state.countryCodeData ? (
              <Box className="selectedCountry">
                <Typography className="selectedPhoneCode">  {this.state.careGiverCountry?.phone_code}</Typography>
              </Box>
            ) : (
              <Typography>{this.state.isCountryData}</Typography>
            )}
            <span className="selectDropdown"><ExpandMoreIcon />
            </span>
          </Box>
        )
      }
    troubleDialog  = () => {
        return (
            <Dialog
                open={this.state.openTroubleDialog}
                style={{ borderRadius: "50px" }}
                PaperProps={{
                    style: {
                        borderRadius: "24px",
                        display: 'flex',
                        padding: '6px 9px',
                        flexDirection: 'column',
                        width: '483px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                }}
            >
                <div style={{paddingBottom: "10px", width: "100%" }}>

                    <DialogContent>
                        <p style={{ fontSize: "22px", color: "#334155", fontFamily: "Raleway", margin: "0px", fontWeight: 600,lineHeight: '33px', letterSpacing: '-0.4px', textAlign: "center", fontFeatureSettings: "'clig' off, 'liga' off", }}>
                        Trouble uploading photo. Choose a different file type.</p>
                    </DialogContent>
                    <Divider style={{color:"#E2E8F0",width:"90%",marginLeft:"23px"}}/>

                </div>
                <DialogActions style={{ justifyContent: "center" }}>
                    <button
                        onClick={this.cancelTroubleDialog}
                        data-test-id="cancelBtn"
                        style={{
                            fontSize: "18px", fontFamily: "Inter", color: "#007AFF",
                            backgroundColor: "transparent", fontWeight: 700,cursor: "pointer", height: "35px", border: "none",  marginTop: "5px"
                        }}>
                        Close
                    </button>

                </DialogActions>
            </Dialog>
        )
    }




    // Customizable Area End
    render() {
        // Customizable Area Start
        const { errors, successMsg } = this.state;
        const CssTextFieldCode = CssTextFieldCodeCode as any

        return (
            <>
            <GeoNotification country={this.state.locationCountry} open={this.state.wclPopup} data-test-id="handleWlcClose-profile" handleWlcClose={this.handleWlcClose}/>
            <ThemeProvider theme={theme}>
                 {this.state.isLoading && <Loader />}
                <WebOnboarding
                    title={this.state.onBoardingTitle}
                    anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
                    next={this.nextOnBoarding}
                    discripation={this.state.onBoardingText}
                    open={this.state.openOnboarding}
                    anchorhorizontal={this.state.anchorHorizontal}
                    skip={this.skipOnBoarding}
                    transformhorizontal={this.state.transformHorizontal}
                />
                {this.openDilog()}
                {this.troubleDialog()}
                <div style={{ display: "flex"}}>
                <SideBar navigation={this.props.navigation} code={this.state.code} fillform={this.state.errorPopUp} showData={this.setAllData} />
                    <Grid style={styles.mainContainer}>
                    <WebHeader title={"Profile Information"} icon={profileIcon}/>
                        {this.state.editProfileToggle ? <Grid style={{ backgroundColor: "#FAFAFA", padding: "16px" }}>
                            <Grid container>
                                <Grid item style={styles.profileMain} >
                                    <span  data-test-id="edit-profile"  style={styles.editProfileBtn} onClick={this.editProfile}>Edit Profile</span>
                                    <Grid item style={{ position: "relative" }}>
                                        <Avatar src={this.state.imgData || undefined} style={styles.avtarImgMainDiv as React.CSSProperties}>
                                            {this.state.firstNameImg && this.state.firstNameImg.charAt(0).toUpperCase()}
                                        </Avatar>
                                        <div data-test-id="gamification-btn" onClick={this.gamificationScreen} style={styles.wizardMainDiv}>
                                            <img style={{ width: "18px", height: "18px" }} src={this.state.wizardData && this.state.wizardData.attributes.logo} alt="" />
                                        </div>
                                    </Grid>
                                        <Typography style={styles.nameTextDiv}>{this.state.first_name} {this.state.last_name}</Typography>
                                        <Typography data-test-id="streak-btn"style={styles.streaksTextDiv}  onClick={() => {this.redirectToLogin()}}>Current Streaks<img src={strickeIcon} alt="" style={{marginRight:"5px", marginLeft:"5px"}} /> <span>{this.state.streakData.current_streak}</span> </Typography>
                                </Grid>
                                <Grid item style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "32px",width:"100%" }}>
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Patient First Name</Typography>
                                        <Typography style={styles.detailsText}>{this.state.first_name}</Typography>
                                    </DetailBox>
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Patient Last Name</Typography>
                                        <Typography style={styles.detailsText}>{this.state.last_name}</Typography>
                                    </DetailBox>
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Patient Email</Typography>
                                        <Typography style={styles.detailsText}>{this.state.email}</Typography>
                                    </DetailBox>
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Patient Mobile Number</Typography>
                                        {this.state.phoneNumber && <Typography style={styles.detailsText}>{this.state.selectedCountry.phone_code}{this.state.phoneNumber}</Typography>}
                                    </DetailBox>
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Are You A Caregiver?</Typography>
                                        <Typography style={styles.detailsText}>{this.state.caregiver}</Typography>
                                    </DetailBox>
                                    {this.state.caregiver === "Yes" &&
                                        <>
                                            <DetailBox>
                                                <Typography style={styles.detailsHadding}>Name Of Caregiver</Typography>
                                                <Typography style={styles.detailsText}>{this.state.caregiverName}</Typography>
                                            </DetailBox>
                                            <DetailBox>
                                                <Typography style={styles.detailsHadding}>Caregiver Email</Typography>
                                                <Typography style={styles.detailsText}>{this.state.caregiverEmail}</Typography>
                                            </DetailBox>

                                            <DetailBox>
                                                <Typography style={styles.detailsHadding}>Caregiver Mobile Number</Typography>
                                                {this.state.caregiverPhone && <Typography style={styles.detailsText}>{this.state.careGiverCountry.phone_code + this.state.caregiverPhone}</Typography>}
                                            </DetailBox>
                                        </>
                                    }
                                    <DetailBox>
                                        <Typography style={styles.detailsHadding}>Age Group?</Typography>
                                        <Typography style={styles.detailsText}>{this.state.age}</Typography>
                                    </DetailBox>
                                    
                                </Grid>
                            </Grid>
                        </Grid> :
                        <Grid style={{ backgroundColor: "#FAFAFA", padding: "16px" }}>

                            <Grid container alignItems="center" style={{ margin: "0% 12px 30px" }}>
                                <Grid item style={{ marginRight: "15px", marginBottom: "10px" }}>

                                    <Avatar src={this.state.imgData || undefined} style={styles.avtarImg as React.CSSProperties}>
                                        {this.state.firstNameImg && this.state.firstNameImg.charAt(0).toUpperCase()}
                                    </Avatar>


                                </Grid>

                                <Grid item>


                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <input
                                                style={{ display: "none" }}
                                                accept="image/jpeg, image/png, image/svg+xml"
                                                id="contained-button-file"
                                                type="file"
                                                onChange={this.uploadImage}
                                                data-test-id="uplodeImage"
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" component="span" style={styles.addPicBTN}>
                                                    <div style={{ display: "flex", alignItems: "center" }}><AddCircleOutlineIcon style={{ color: "#fff", marginRight: "10px", fontSize: "29px" }} /><span  ref={this.state.onboardingRefs[0]} style={{
                                                        fontSize: "16px",
                                                        fontWeight: 700, color: "#fff", textTransform: "none", fontFamily: "Raleway"
                                                    }}>Upload New Picture</span></div>
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <Button style={styles.removePicBtn}
                                                data-test-id="makeimageNull"
                                                onClick={this.makeimageNull}
                                            >
                                                <span style={{
                                                    fontSize: "16px",
                                                    fontWeight: 700, color: "#13689E", textTransform: "none", fontFamily: "Raleway"
                                                }}>Remove</span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <FormWrapper onSubmit={this.setAllData} data-test-id="setAllData">
                                <Grid container spacing={4} style={{padding:"13px",marginBottom:'50px'}}>

                                    <Grid item xs={12} lg={6} >
                                        <Grid>
                                            <Box style={{ marginBottom: errors.first_name ? "12px" : "35px" }}>
                                                <CssTextFieldCode
                                                    InputProps={{ disableUnderline: true }}
                                                    style={{ ...styles.inputbox, border: errors.first_name ? "1px solid #F87171" : "1px solid #CBD5E1" }}

                                                    value={this.state.first_name}
                                                    name="first_name"
                                                    onChange={this.handleChange}
                                                    id="filled-required"
                                                    label="First Name"
                                                    variant="filled"
                                                    data-test-id="firstchange"
                                                    inputProps={{
                                                        color: "rgb(51, 51, 57)",
                                                    }}
                                                />
                                                <FormHelperText style={styles.errorText}>{errors.first_name}</FormHelperText>
                                            </Box>

                                        </Grid>
                                        <Grid>

                                            <Box style={{ marginBottom: errors.last_name ? "18px" : "40px" }}>
                                                <CssTextFieldCode
                                                    InputProps={{ disableUnderline: true }}
                                                    style={{ ...styles.inputbox, border: errors.last_name ? "1px solid #F87171" : "1px solid #CBD5E1" }}

                                                    value={this.state.last_name}
                                                    name="last_name"
                                                    onChange={this.handleChange}
                                                    id="filled-required"
                                                    label="Last Name"
                                                    variant="filled"
                                                    data-test-id="lastchange"
                                                    inputProps={{
                                                        color: "rgb(51, 51, 57)",
                                                    }}
                                                />
                                                <FormHelperText style={styles.errorText}>{errors.last_name}</FormHelperText>
                                            </Box>

                                        </Grid>
                                        {this.caregiver()}
                                        {this.forCaregiver()}

                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Grid >
                                            <Box style={{ marginBottom: errors.phoneNumber ? "15px" : "38px" }}>
                                            <Box style={{ display: "flex",gap: "10px", alignItems: "center"}}>
                                                    <DropDownWrapper>
                                                        {this.dropDownModalRender()}
                                                        {this.state.dropdownOpen && (
                                                            <Box className="dropDownConatiner">
                                                                <TextField
                                                                    value={this.state.searchData}
                                                                    onChange={this.handleSearchData}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <SearchIcon className="searchDataIcon" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    data-test-id="searchDataId"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className="searchField"
                                                                    placeholder="Search a country"
                                                                    style={{ border: 'none' }}
                                                                />
                                                                <Divider className="dividerColor" />
                                                                <Box className="dropDownOptions">
                                                                    {this.state.filteredData.map((country,countryValue: number) => (
                                                                        <Box className="optionsValue"
                                                                            data-test-id="countrySelectId"
                                                                            key={countryValue}
                                                                            onClick={() => this.handleCountrySelect(country, country.phone_code)}
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    country?.name === this.state.selectedCountry.name
                                                                                        ? "active"
                                                                                        : "updated"
                                                                                }
                                                                                                                                                            >
                                                                                <span className="phoneCode" >{country.phone_code}</span> <span>{country.name}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </DropDownWrapper>
                                                   <CssTextFieldCode
                                                    InputProps={{ disableUnderline: true }}
                                                    style={{ ...styles.inputbox, border: errors.phoneNumber ? "1px solid #F87171" : "1px solid #CBD5E1" }}
                                                    value={this.state.phoneNumber}
                                                    name="phoneNumber"
                                                    onChange={this.handleChange}
                                                    id="filled-required"
                                                    label="Patient Mobile Number"
                                                    variant="filled"
                                                    data-test-id="phonechange"
                                                    inputProps={{
                                                        color: "rgb(51, 51, 57)",
                                                    }}
                                                />
                                            </Box>
                                            <Typography style={styles.errorText}>{errors.phoneNumber}</Typography> 
                                            </Box>
                                        </Grid>
                                        <Grid >
                                            <Box style={{ marginBottom: "40px" }}>
                                                <CssTextFieldCode
                                                    style={{ ...styles.inputbox, border: errors.email ? "1px solid #F87171" : "1px solid #CBD5E1", marginTop: "2px" }}
                                                    ref={this.state.onboardingRefs[2]}
                                                    value={this.state.email}
                                                    name="email"
                                                    id="filled-required"
                                                    label="Patient Email"
                                                    variant="filled"
                                                    data-test-id="emailchange"
                                                    readOnly
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                                <FormHelperText style={styles.errorText}>{errors.email}</FormHelperText>
                                            </Box>

                                        </Grid>
                                        {this.ageSelect()}
                                        {this.validCode()}
                                        {this.saveBtn()}
                                    </Grid>



                                </Grid>

                            </FormWrapper>
                        </Grid>}
                    </Grid>
                </div>
            </ThemeProvider>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    dividerStyle: { backgroundColor: "#000", height: "40px", width: "2px" },
    addPicBTN: {
        backgroundColor: "#13689E", padding: "0px 33px",
        borderRadius: "50px", border: "none", height: "50px", boxShadow: "none"
    },
    removePicBtn: {
        boxShadow: "none", backgroundColor: "#f4faffbd", padding: "0px 33px", borderRadius: "50px",
        border: "1px solid #CBD5E1", height: "50px",
    },
    profileMain:{backgroundColor:"#13689E", borderRadius:"16px",padding:"16px", width:"100%", display:"flex", justifyContent:"center", flexDirection:"column",alignItems:"center",position:"relative"} as const,
    wizardMainDiv:{cursor:"pointer", width: "30px", height: "30px", position: 'absolute', bottom: "3px", right: "3px", borderRadius: "50px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#fff" } as const,
    mainContainer: { width: "100%", backgroundColor: "#FAFAFA", height: "auto", minHeight: "100vh" },
    detailsText:{fontFamily:"Raleway", fontSize:"14px",fontWeight:600,lineHeight:"22px",color:"#0F172A"},
    nameTextDiv:{fontFamily:"Raleway",fontSize:"18px",fontWeight:600,lineHeight:"21px",color:"#FFFFFF" ,textAlign:"center",marginTop:"12px"} as const,
    errorText: { color: '#DC2626', fontSize: "0.75rem", fontFamily: 'Inter', letterSpacing: "0.4px" },
    streaksTextDiv:{cursor: "pointer",fontFamily:"Raleway",fontSize:"12px",fontWeight:500,lineHeight:"16px",color:"#FFFFFF" ,textAlign:"center",marginTop:"4px",display:"flex"} as const,
    detailsHadding:{fontFamily:"Raleway", fontSize:"10px",fontWeight:500,lineHeight:"14px",color:"#64748B"},
    editProfileBtn:{cursor:"pointer",position:"absolute",borderBottom:"1px solid #FFFFFF",color:"#FFFFFF",top:"23px",right:"23px", fontWeight:600, fontSize:"12px",lineHeight:"12px", fontFamily:"Raleway",} as const,
    saveBtn: {
        border: "none", borderRadius: "50px", opacity: 1, cursor: "pointer",backgroundColor: "#13689E", height: "48px", width: "264px", color: "#fff", fontFamily: "Raleway", fontSize: "16px", fontWeight: 700,
    },
    inputStyle: {
        width: "100%", height: "50px", border: "none", margin: "0.5% 1px", backgroundColor: "#F1F5F9",
        fontFamily: "Raleway", fontSize: "14px", fontWeight: 600, color: "#0F172A", borderRadius: "8px", outline: 'none', paddingLeft: "12px",

    },
    clearIconStyle: { position: "absolute", right: "4%", top: "28%", fontSize: "16px", padding: "4px", borderRadius: "50%", backgroundColor: "#F1F5F9", color: "#1C1B1F" },
    checkIconStyle: { position: "absolute", right: "4%", top: "28%", fontSize: "16px", padding: "4px", borderRadius: "50%", backgroundColor: "#10B981", color: "#fff" },
    veryfyBtn: {
        width: "99%", height: "50px", border: "none", backgroundColor: "#23B8B1"
        , fontFamily: "Raleway", fontSize: "16px", fontWeight: 700, color: "#fff", borderRadius: "8px", minWidth: "70px",
        marginTop: "4px", cursor: "pointer"
    },
    dropDownGrid: {
        width: "auto", backgroundColor: "#F5F9FD",
        border: "1px solid #CBD5E1", height: "auto", padding: "5px 0px", borderRadius: "0px 0px 8px 8px", borderTop: "none",
    },
    dropDownGridSec: {
        padding: "15px 0px 0px",
        display: "flex", alignItems: "center", position: "relative", flexWrap: "wrap", justifyContent: "space-between"
    },
    dropDownGridSecCompt: {
        width: "auto", backgroundColor: "#F5F9FD",
        border: "1px solid #CBD5E1",
        borderRadius: "0px 0px 8px 8px", borderTop: "none",
    },
    optionText: { fontSize: "14px", fontWeight: 400, fontFamily: "Raleway", color: "#0F172A", padding: "2% 0px", borderRadius: "8px", cursor: "pointer" },
    optionTextSec: { fontSize: "14px", fontWeight: 400,  color: "#0F172A", padding: "20px 0px 20px 13px", cursor: "pointer",fontFamily: "Raleway", margin: "0px" },

    sterkComponent: { display: "flex", alignItems: "center", marginTop: "5%" },
    sterkText: { fontSize: "14px", fontFamily: "Raleway", fontWeight: 500, color: "#475569" },
    avtarImg: { width: "100px", height: "100px", objectFit: "none", fontSize: "40px" },
    avtarImgMainDiv:{width: "100px", height: "100px", objectFit: "none", fontSize: "40px",},
    nameText: { fontSize: "22px", fontFamily: "Raleway", fontWeight: 600, color: "#0F172A" },
    headerProfilreIcon: {
        width: "55px",
        marginRight: "25px",
        height: "55px",
    },
    hesderProfileText: { fontSize: "24px", fontWeight: 700, fontFamily: "Raleway" },
    hederProfileCOntainer: {
        display: "flex",
        alignItems: "center"
    },
    massageImgContainer: {
        height: "45px", width: "45px", borderRadius: "12px", boxShadow: "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014", display: "flex",
        justifyContent: "center", alignItems: "center", backgroundColor: "#fff"
    },
    mssgeImg: {},
    hederNotificationConatiner: { position: "relative", marginRight: "30px" },
    notificationNum: {
        position: "absolute", backgroundColor: "#FF4A23", padding: "3px", borderRadius: "50%",
        top: "14px", right: "15px", textAlign: "center", fontSize: "12px", fontWeight: 600, color: "#fff", fontFamily: "Poppins"
    },
    inputbox: {
        fontSize: "14px",
        fontWeight: 600,
        borderRadius: "8px",
        marginTop: "5px",
        width: "100%",
        color: "#0F172A",
        fontFamily: "Raleway",
        border: "0.4px solid #CFDCEC"
    },
    inputOfDrop: {
        borderRadius: "8px",
        marginTop: "5px",
        width: "auto",
        backgroundColor: "#F5F9FD",
        color: "#64748B",
        border: "1px solid #CBD5E1",
        height: "54px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 12px",
        cursor: "pointer"

    },
    inputOfDropSec: {
        marginTop: "5px",
        backgroundColor: "#F1F5F9",
        width: "auto",
        color: "#64748B",
        height: "54px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 12px",
        cursor: "pointer"
    },
    inputOfDropOpen: {
        borderRadius: "8px 8px 0px 0px",
        cursor: "pointer",
        marginTop: "5px",
        width: "auto",
        border: "1px solid #CBD5E1",
        backgroundColor: "#F5F9FD",
        justifyContent: "space-between", padding: "0px 12px", color: "#64748B", borderBottom: "none",
         height: "54px", display: "flex", alignItems: "center",
    }
}

const FormWrapper = styled("form")({
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        display: "none"
    },
})
const DropDownWrapper = styled(Box)({
    position: "relative",
      cursor: "pointer",
    "& .dropDownHeader": {
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#f4faffbd",
      height: "32px",
      marginTop: "4px",
      alignItems: "center",
      width: "86px",
      color: "black",
      border:"1px solid #CBD5E1",
      fontWeight: 400,
      fontSize: "16px",
      "& .selectDropdown": {
        color: "black"
      }
    },
    "& .selectedCountry": {
      display: "flex"
    },
    "& .dropDownConatiner":{
      left: 0,
      width: "256px",
      border: "1px solid #F1F5F9",
      borderRadius: "12px",
      marginTop: "10px",
      borderTop: "none",
      background: "#F8FAFC",
    position: "absolute",
    bottom:"-265px",
              zIndex: 99,
      padding: "10px",
      marginBottom: "10px",
      color: "white",
      backgroundColor:"#F1F5F9",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
      "& .searchField": {
        marginTop: "0px",
        marginBottom: "12px",
        borderRadius: "50px",
        color: "black",
        background: "#E2E8F0",
        position:"sticky",
        height: "48px",
        border: "none",
        "& .MuiOutlinedInput-notchedOutline-296":{
          border: "none !important",
          outline: "none !important"
        },
        "& .MuiOutlinedInput-notchedOutline":{
            border: "none !important",
            outline: "none !important"
          },
        "& fieldset": { border: "none" }
      },
    "& .dropDownOptions": {
      left: 0,
      border: "1px solid #F1F5F9",
      borderTop: "none",
      background: "#F8FAFC",
      height: "160px",
      color: "white",
      overflow: "auto",
      backgroundColor:"#F1F5F9",
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "12px",
        boaderRadius: "50px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#F1F5F9"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#94A3B8",
        borderRadius: "9px"
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#90A4AE"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "none",
        borderRadius: "50px",
        border: "none"
      },
      "& .MuiOutlinedInput-input": {
        padding: "14px 0px"
      },
      "& .MuiOutlinedInput-adornedStart": {
        paddingLeft: "25px"
      },
          "& .MuiInputBase-input": {
        color: "#64748B",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px"
      },
      "& .optionsValue": {
          display: "flex",
          padding: "10px",
        color: "black",
        "& .active": {
          color: "#13689E",
          fontWeight: 600,
          display: "flex"
        },
        "& .updated": {
          color: "#151515",
          fontWeight: 500,
          display: "flex"
        },
        "&.sortDataScrollDiv": {
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-track": {
            background: "#F1F5F9"
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            background: "#94A3B8"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#90A4AE"
          },
          "&::-webkit-scrollbar:vertical": {
            width: "4px"
          },
        },
      }
    },
  },
    "& .selectedPhoneCode": {
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      paddingLeft: "5px"
    },
    "& .dividerColor": {
      background: "#E2E8F0"
    },
    "& .phoneCode": {
      marginRight: "15px",
      minWidth: "55px"
    },
    "& .searchDataIcon": {
      color: "#64748B"
    },
  })

const DropDownWrapper1 = styled(Box)({
    position: "relative",
    cursor: "pointer",
    "& .dropDownConatiner": {
        width: "256px",
        borderTop: "none",
        borderRadius: "12px",
        border: "1px solid #F1F5F9",
        marginTop: "10px",
        position: "absolute",
        background: "#F8FAFC",
        zIndex: 99,
        padding: "10px",
        bottom: "-265px",
        marginBottom: "10px",
        color: "white",
        backgroundColor: "#F1F5F9",
        "& .searchField": {
            marginTop: "0px",
            marginBottom: "12px",
            background: "#E2E8F0",
            color: "black",
            borderRadius: "50px",
            position: "sticky",
            border: "none",
            height: "48px",
            left: 0,
            "& .MuiOutlinedInput-notchedOutline-296": {
                outline: "none !important",
                border: "none !important"

            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none !important",
                outline: "none !important"
            },
            "& fieldset": { border: "none" }
        },
        "& .selectedPhoneCode": {
            color: "#0F172A",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            paddingLeft: "5px"
        },
        "& .dropDownOptions": {
            borderTop: "none",
            background: "#F8FAFC",
            border: "1px solid #F1F5F9",
            left: 0,
            height: "160px",
            overflow: "auto",
            color: "white",
            backgroundColor: "#F1F5F9",
            "&::-webkit-scrollbar": {
                width: "4px",
                height: "12px",
                boaderRadius: "50px"
              },
              "&::-webkit-scrollbar-track": {
                background: "#F1F5F9"
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#94A3B8",
                borderRadius: "9px"
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#90A4AE"
              },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "none",
                borderRadius: "50px",
                border: "none"
            },
            "& .MuiOutlinedInput-input": {
                padding: "14px 0px"
            },
            "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "25px"
            },
            "& .MuiInputBase-input": {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#64748B",
            },
            "& .optionsValue": {
                display: "flex",
                color: "black",
                padding: "10px",
                "& .active": {
                    display: "flex",
                    color: "#13689E",
                    fontWeight: 600
                },
                "& .updated": {
                    color: "#151515",
                    display:"flex",
                    fontWeight: 500
                },
                "&.sortDataScrollDiv": {
                    "&::-webkit-scrollbar": {
                        width: "4px"
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#90A4AE"
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#F1F5F9"
                    },
                    "&::-webkit-scrollbar:vertical": {
                        width: "4px"
                    },
                     "&::-webkit-scrollbar-thumb": {
                        borderRadius: "8px",
                        background: "#94A3B8"
                    }     
                },
            }
        },
    },
    "& .dividerColor": {
        background: "#E2E8F0"
    },
    "& .phoneCode": {
        marginRight: "15px",
        minWidth: "55px"
    },

    "& .selectedCountry": {
        display: "flex"
    },
    "& .searchDataIcon": {
        color: "#64748B"
    },
    "& .dropDownHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px",
        background: "#f4faffbd",
        height: "35px",
        width: "86px",
        color: "black",
        border: "1px solid #CBD5E1",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        marginTop:"5px",
        "& .selectDropdown": {
            color: "black"
        }
    },
})

const DetailBox = styled(Box)({
border:"0.5px solid #F1F5F9",
padding:"8px 16px 8px 16px",
borderRadius:"12px",
backgroundColor:"#FFFFFF",
})
// Customizable Area End
