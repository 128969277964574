import React, { Component } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { getStorageData, setStorageData } from '../../framework/src/Utilities';

interface AppState {
  openState: string
}
interface AppProps {
  country: string;
  open: boolean;
  handleWlcClose: () => void;
}
class GeoNotification extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      openState: ""
    };
  }
  
  async componentDidMount() {
    const openState = await getStorageData("wlcPopup")
    if (openState == "false") {
      this.setState({ openState })
    }

  }

  render() {
    
    return (
      <>
      {(this.props.country !== "us" && this.state.openState !== "false") && <Box>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleWlcClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 24},
          }}        >
          <Typography style={styles.dailogMainTitle}> <b> Welcome to VitaPKU </b> </Typography>
          <DialogContent style={styles.dailogSubText}>
            Please note that VitaPKU is primarily intended for users in the <br/> United States. While there are no geo-restrictions in place, our <br/> content and services are tailored to a US audience. Would you like <br/>to continue exploring the site?
          </DialogContent>
          <DialogActions style={styles.bothButtonDaiolg}>
            <Button onClick={this.props.handleWlcClose} variant="contained" style={styles.dailogFirstButton}>Continue</Button>
          </DialogActions>
        </Dialog>
      </Box>}
      </>
    );
  }
}

export default GeoNotification;

const styles = {
  dailogMainTitle: {
    fontFamily: "Raleway",
    fontSize: "21px",
    fontWeight: 500,
    lineHeight: "28px",
    textAlign: "center" as "center",
    color: '#0F172A',
    padding:'30px 0 10px'
  },
  dailogSubText: {
    textAlign: 'center'  as "center",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: '#0F172A'
  },
  bothButtonDaiolg:{ 
    justifyContent: 'center',
    padding:'5%',
    display:'flex' 
  },
  dailogFirstButton: {
    marginRight: '10px', 
    padding: '10px 20px', 
    backgroundColor: '#13689E', 
    fontFamily: "Raleway",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left" as "left",
    color: '#FFFFFF',
    width: '44%',
    borderRadius: '50px',
    textTransform: 'none' as 'none',
    height:'44px'
  },
}