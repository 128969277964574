Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.fordate = { day: 'numeric', month: 'short', year: 'numeric' };
exports.fordateSortdate = { day: '2-digit', month: 'short', year: 'numeric' };
exports.analyticsChartEndpoint = "treatment_details";
exports.analyticsCalendarChartEndpoint = "/monthly_treatment_details"
exports.analyticsNamegerEndpoint = "/bx_block_profile/profile"
exports.phelevelchartEndpoint = "/phe_trends_analytics"
exports.tryPheEndpoint = "/phe_threshold"
exports.phe = "Phe";
exports.tyr = "Tyr";
exports.Upcoming = "Upcoming";
exports.Skipped = "Skipped";
exports.Taken = "Taken";
exports.Legends = "Legends";
exports.TreatmentName = "Treatment Name";
exports.Dates = "Dates";
exports.ReminderTimes = "Reminder Times";
exports.Status = "Status";


// Customizable Area End