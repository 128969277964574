import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  withStyles,
  Box,
  styled,
  Container,
  FormControl,
  RadioGroup,
  Dialog,
  DialogActions,
  Divider,
  DialogContentText,
  DialogContent,
  Button,
  InputAdornment,
  Modal
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import {
  closeIcon,
  code_verified,
  mainLogo,
  email_verified
} from "../../email-account-registration/src/assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomRadio } from "../../email-account-registration/src/SignupMFA.web";
import ResetPasswordMFAController, { Props } from "./ResetPasswordMFAController";
import CustomBG from "../../../components/src/CustomBG.web";
export const configJSON = require("./config");
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

const CssTextField = withStyles({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#ff0c00",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1dddc",
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f0f0f0",

      border: "1px solid rgba(0, 0, 0, 0.09)",
    },
    "& .MuiInputBase-input": {
      color: "rgb(51, 51, 57)",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiInputLabel-root": {
      color: "rgb(51, 51, 57)",
    },
  },
})(TextField);

const MainContainer = styled(Box)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  fontFamily: 'Raleway',
  '& .inputbox': {
    '& .MuiOutlinedInput-input': {
      background: "#F1F5F9",
    },
    '& ::placeholder': {
      color: "#64748B",
      opacity: 1
    }
  }
});
const DialogContainer = styled(DialogContent)({
  padding: "16px 32px",
  textAlign: "center",
  '& .title': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '-0.12px',
    textAlign: 'center',
    lineHeight: '32px',
  },
  '& .metaTitle': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'center',
  },
  '& .btnContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .textCode': {
    "@media (max-width: 400px)": {
      display: "grid",
    },
  }
});

const CustomTypography = styled(Typography)({
  // Base styles
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '24px',
  alignItems: 'center',
  // Media query
  '@media (max-width: 400px)': {
    display: 'grid',
    textAlign: 'center',
  },
})

const formatCode = (code: any) => {
  const parts = code?.replace(/\D/g, '').match(/.{1,3}/g);
  if (parts) {
    return parts.join('-');
  } else {
    return code;
  }
};

const CountryWrapper = styled(Box)({
  position: "relative",
    cursor: "pointer",
  "& .dropDownHeader": {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "5px",
    background: "#f4faffbd",
    height: "36px",
    width: "86px",
    color: "black",
    border: "1px solid #ccc6c72b",
    fontWeight: 400,
    fontSize: "16px",
    "& .selectDropdown": {
      color: "black"
    }
  },
  "& .selectedCountry": {
    display: "flex"
  },
  "& .dropDownConatiner":{
    left: 0,
    width: "256px",
    border: "1px solid #F1F5F9",
    borderTop: "none",
    borderRadius: "12px",
    marginTop: "10px",
    background: "#F8FAFC",
    position: "absolute",
    zIndex: 1,
    padding: "10px",
    marginBottom: "10px",
    color: "white",
    backgroundColor:"#F1F5F9",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "& .searchField": {
      marginTop: "10px",
      marginBottom: "12px",
      borderRadius: "50px",
      background: "#E2E8F0",
      color: "black",
      position:"sticky",
      border: "none",
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none !important"
      }
    },
  "& .dropDownOptions": {
    left: 0,
    border: "1px solid #F1F5F9",
    borderTop: "none",
    background: "#F8FAFC",
    maxHeight: "160px",
    overflow: "auto",
    color: "white",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "12px",
      boaderRadius: "50px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F5F9"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#94A3B8",
      borderRadius: "9px"
    },
    backgroundColor:"#F1F5F9",
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#90A4AE"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "none",
      borderRadius: "50px",
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 0px"
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "25px"
    },
        "& .MuiInputBase-input": {
      color: "#64748B",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px"
    },
    "& .optionsValue": {
      padding: "10px",
      display: "flex",
      color: "black",
      "& .active": {
        color: "#13689E",
        fontWeight: 600,
        display: "flex"
      },
      "& .updated": {
        color: "#151515",
        fontWeight: 500,
        display: "flex"
      },
      "&.sortDataScrollDiv": {
        "&::-webkit-scrollbar": {
          width: '4px',
        },
        "&::-webkit-scrollbar-track": {
          background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: '8px',
          background: '#94A3B8',
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: '#90A4AE',
        },
        "&::-webkit-scrollbar:vertical": {
          width: '4px',
        },
      },
    }
  },
},
  "& .selectedPhoneCode": {
    color: "#0F172A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "5px"
  },
  "& .dividerColor": {
    background: "#E2E8F0"
  },
  "& .phoneCode": {
    marginRight: "15px",
    minWidth:"55px"
  },
  "& .searchDataIcon": {
    color: "#64748B"
  },
})


const SocialmediaBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  background: '#e5e5e5',
  width: 600,
  padding: 0,
  border: 'none',
  minHeight: "126px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  transform: 'translate(-50%, -50%)',

  '@media (max-width: 600px)': {
    width: '92%',
  },
});

const SocialmediaErroOne = styled('div')({
  fontFamily: "Raleway",
  fontSize: "22px",
  paddingTop: "20px",
  fontWeight: 600,
  color: "#334155",
  
  '@media (max-width: 450px)': {
    fontSize: "13px",
  },
});

const SocialmediaErroTwo = styled('div')({
  fontFamily: "Raleway",
  fontSize: "22px",
  color:"#334155",
  fontWeight: 600,
  width:"100%",
  paddingTop:"8px",
  paddingBottom: "16px",
  textAlign:"center",
  borderBottom: "0.33px solid #b9b9bb",

  '@media (max-width: 450px)': {
    fontSize: "13px",
  },
});

const SocialmediaErroButton = styled(Button)({
  color: "#007AFF",
  minWidth: "140px",
  fontFamily: "Raleway",
  fontWeight: 700,
  marginTop: "5px",
  textTransform: "none",
  fontSize:"18px",
  marginBottom:"5px",

  '&:hover': {
    background: 'none',
  },
});
// Customizable Area End

export default class ResetPasswordMFA extends ResetPasswordMFAController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStepViceForm = () => {
    const { step } = this.state;
    let result;

    if (step === 0) {
      result = this.selectMFAValType();
    } else if (step === 1) {
      result = this.enterMFAData();
    } else if (step === 2) {
      result = this.varifyMfa();
    } else if (step === 3) {
      result = this.varifyLastNameMfa()
    }
    return result;
  };

  selectMFAValType = () => {
    return (
      <>
        <img src={closeIcon} style={styles.closeIconStyle} alt="Close" onClick={() => this.props.navigation.navigate('EmailAccountLoginBlock')} data-test-id="closeIcon" />
        <div>
          <img alt="Logo" src={mainLogo} style={styles.mainLogo} />
          <Typography style={styles.multiFectorHeadingStyle} variant="h5">
            {`Choose Verification Method`}
          </Typography>
          <Typography style={styles.paregraphText}>
            {`Select your preferred method of verification: SMS or Email. We'll send a verification code to complete the password reset process.`}
          </Typography>
          <Formik
           data-test-id="Formik"
            initialValues={{
              rad: 0,
            }}
            validateOnBlur={false}
            onSubmit={(_values) => { }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(),
            })}
          >
            {(props) => {
              return (
                <>
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="gender" name="customized-radios">
                        <div
                        data-test-id="rad1"
                          style={{
                            ...styles.radioStyle,
                            opacity: props.values.rad === 0 ? 1 : 0.4,
                            marginTop: "24px",
                          }}
                          onClick={() => props.setFieldValue("rad", 0)}
                        >
                          <CustomRadio
                            data-test-id="check"
                            checked={props.values.rad === 0}
                          />
                          <img
                            style={styles.verfiyLogostyle}
                            src={code_verified}
                            alt="Code"
                          />
                          <div style={{ display: "grid", gap: "10px" }}>
                            <Typography style={styles.smsTxt}>SMS</Typography>
                            <Typography style={styles.smsPeregraph}>
                              {`Authenticate using a code sent via text message to your phone.`}
                            </Typography>
                          </div>
                        </div>

                        <div
                          data-test-id="rad2"
                          style={{
                            ...styles.radioStyle,
                            opacity: props.values.rad === 1 ? 1 : 0.4,
                            marginTop: "24px",
                          }}
                          onClick={() => props.setFieldValue("rad", 1)}
                          >
                          <CustomRadio
                            checked={props.values.rad === 1}
                            data-test-id="check1"
                          />
                          <img
                            style={styles.verfiyLogostyle}
                            alt="Code"
                            src={email_verified}
                          />
                          <div style={{ display: "grid", gap: "10px" }}>
                            <Typography style={styles.smsTxt}>EMAIL</Typography>
                            <Typography style={styles.smsPeregraph}>
                              {`Authenticate using a code sent via email to your email id.`}
                            </Typography>
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <button
                    data-test-id="handle_continue_click"
                    onClick={() =>
                      this.setState({
                        step: this.state.step + 1,
                        validTypeEmail: !!props.values.rad,
                      })
                    }
                    style={styles.button}
                  >
                    Continue
                  </button>
                </>
              );
            }}
          </Formik>
        </div>
      </>
    );
  };

  sendCodeBtn = (props: any) => (
    <>
      {this.state.validTypeEmail ?
        <button
          style={props.values?.email && !props.errors.email ? styles.button : styles.disableButton}
          type="submit"
          data-test-id="handleContinueClick"
          disabled={props.values?.email && !props.errors.email ? false : true}
        >
          Send Code
        </button> : (
          <button
            style={props.values?.phone && !props.errors?.phone ? styles.button : styles.disableButton}
            type="submit"
            data-test-id="handleContinueClick"
            disabled={props.values?.phone && !props.errors?.phone ? false : true}
          >
            {`Send Code`}
          </button>)}
    </>
  )

  countryCodeRender = () => {
    return (
      <Box className="dropDownHeader" data-test-id="toggleCountryId"
        onClick={this.toggleDropdown}
      >
        {this.state.countryCodeData ? (
          <Box className="selectedCountry">
            <Typography className="selectedPhoneCode">  {this.state.selectedCountry?.phone_code}</Typography>
          </Box>
        ) : (
          <Typography>{this.state.isCountryData}</Typography>
        )}
        <span className="selectDropdown"><ExpandMoreIcon />
        </span>
      </Box>
    )
  }

  enterMFAData = () => {
    const { validTypeEmail } = this.state;
    return (
      <>
        {validTypeEmail ? (
          <>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(val, { resetForm }) => {
                this.setState({
                  validData: { email: val.email },
                });
                this.createMFACodeAPI();
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Please enter a valid email ID.')
              })}
              validateOnBlur={true}
              validateOnChange={true}
              data-test-id="Formik"
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <img
                      alt="Close"
                      data-test-id="icon"
                      style={styles.closeIconStyle}
                      src={closeIcon}
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                    />
                    <div>
                      <img onClick={() => this.socialMediaModalClosed()} data-test-id="socialmedia_closedmodal" src={mainLogo} alt="VITA" style={styles.mainLogo} />
                      <Typography style={styles.head} variant="h5">
                        Verify Your Email
                      </Typography>
                      <Typography style={styles.paraText}>
                        Please enter the email address associated with your account to proceed with verification. We'll send a verification code to this email.
                      </Typography>

                      <CssTextField
                        style={styles.signupInput}
                        className="inputbox"
                        value={props.values.email}
                        name="email"
                        id="filled-required"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder="Enter your email ID"
                        defaultValue="Hello World"
                        data-test-id="email-change"
                        inputProps={{
                          color: "rgba(148, 163, 184, 1)",
                        }}
                        variant="outlined"
                        error={!!props.errors?.email}
                        helperText={props.errors?.email}
                      />
                     {this.sendCodeBtn(props)}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <Formik
              initialValues={{ phone: "" }}
              onSubmit={(val, { resetForm }) => {
                this.setState({
                  // step: this.state.step + 1,
                  validData: { phone: val.phone },
                });
                this.createMFACodeAPI();
              }}
              data-test-id="Formik"
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .matches(configJSON.phonenumberValid, 'Please enter a valid phone number.')
              })}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <img
                      style={styles.closeIconStyle}
                      src={closeIcon}
                      alt="Close"
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                    />
                    <div>
                      <img alt="VITA" style={styles.mainLogo} src={mainLogo} />
                      <Typography style={styles.head} variant="h5">
                        Verify Your phone number
                      </Typography>
                      <Typography style={styles.paraText}>
                        Please enter the mobile number linked to your account to proceed with verification. We'll send a verification code to this number.
                      </Typography>
                      <Box style={{ display: "flex",gap: "10px", alignItems: "center"}}>
                      <CountryWrapper>
                          {this.countryCodeRender()}
                          {this.state.dropdownOpen && (
                            <Box className="dropDownConatiner">
                              <TextField
                                value={this.state.searchData}
                                onChange={this.handleSearchData}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon className="searchDataIcon" />
                                    </InputAdornment>
                                  ),
                                }}
                                data-test-id="searchDataId"
                                variant="outlined"
                                fullWidth
                                className="searchField"
                                placeholder="Search a country"
                              />
                              <Divider className="dividerColor" />
                              <Box className="dropDownOptions">
                                {this.state.filteredData.map((country, countryIndex: number) => (
                                  <Box className="optionsValue"
                                    data-test-id="countrySelectId"
                                    key={countryIndex}
                                    onClick={() => this.handleCountrySelect(country, country.phone_code)}
                                  >
                                    <Typography
                                      className={
                                        country?.name === this.state.selectedCountry.name
                                          ? "active"
                                          : "updated"
                                      }
                                                                          >
                                    <span className="phoneCode" >{country.phone_code}</span> <span>{country.name}</span>
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          )}
                        </CountryWrapper>

                      <CssTextField
                        placeholder="Enter your phone number"
                        data-test-id="phone-change"
                        style={styles.signupInput}
                        className="inputbox"
                        value={props.values.phone}
                        name="phone"
                        id="filled-required"
                        onChange={(e) => {
                          props.handleChange(e)
                        }}
                        defaultValue="Hello World"
                        inputProps={{
                          color: "rgba(148, 163, 184, 1)",
                        }}
                        variant="outlined"
                        error={!!props.errors?.phone}
                      />
                      </Box>
                      <Typography style={styles.errorMessage}>{props.errors?.phone}</Typography> 
                      {this.sendCodeBtn(props)}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
        <div>
          <Modal
            open={this.state.socialMedia}
            disableEnforceFocus
            disableAutoFocus
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableBackdropClick
          >
            <SocialmediaBox sx={{ boxShadow: 24 }}>
              <SocialmediaErroOne>Your account is already linked. Please sign</SocialmediaErroOne>
              <SocialmediaErroTwo>in using the linked account provider.</SocialmediaErroTwo>
              <SocialmediaErroButton onClick={() => this.socialMediaModalClosed()}>Close</SocialmediaErroButton>
            </SocialmediaBox>
          </Modal>
        </div>
      </>
    );
  };

  varifyMfa = () => {
    const { validTypeEmail, validData } = this.state;
    return (
      <>
        <>
          <Formik
            data-test-id="Formik"
            initialValues={{ code: "" }}
            onSubmit={(val) => {
              this.verifyMFACodeAPI(val?.code);
            }}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              code: Yup.string()
                .test('is-valid-code', 'Please enter a valid authentication code.', function(value) {
                  if (!value) return false; 
                  if (value.includes('-')) {
                    return value.length === 7; 
                  } else {
                    return value.length === 6; 
                  }
                })
                .required('Code is required') 
            })}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <img
                    alt="Close"
                    src={closeIcon}
                    data-test-id="close"
                    style={styles.closeIconStyle}
                    onClick={() =>
                      this.setState({
                        step: this.state.step - 1,
                      })
                    }
                  />
                  <div>
                    <img style={styles.mainLogo} src={mainLogo} alt="VITA" />
                    <Typography style={styles.head} variant="h5">
                      Verify the authentication code
                    </Typography>
                    <Typography style={styles.paraText}>
                      We have sent you the authentication code by {validTypeEmail ? `email to "${validData.email}”` : `SMS to "${this.state.selectedCountry.phone_code + this.state.validData.phone}”`}.
                      Please enter the code to complete the verification.
                    </Typography>

                    <CssTextField
                      placeholder="Enter your authentication code"
                      name="code"
                      style={styles.signupInput}
                      className="inputbox"
                      onChange={(e)=> {
                        props.handleChange(e);
                        this.setState({otpError:''})
                      }}
                      value={formatCode(props.values?.code) || ""}
                      defaultValue="Hello World"
                      id="filled-required"
                      data-test-id="code-change"
                      inputProps={{
                        color: "rgba(148, 163, 184, 1)",
                        maxLength: 7,
                      }}
                      variant="outlined"
                      error={!!props.errors?.code || !!this.state.otpError}
                      helperText={props.errors?.code || this.state.otpError}
                    />
                    <button
                      style={props.values?.code && !props.errors?.code ? styles.button : styles.disableButton}
                      data-test-id="handleContinueClick"
                      type="submit"
                      disabled={props.values?.code && !props.errors?.code ? false : true}
                    >
                      Verify
                    </button>
                    <CustomTypography  className="textCode">
                      Didn’t receive a code?{" "}
                      <Link to="#" style={styles.bottomLink} onClick={this.resendCode} data-test-id="resendCode">
                        Send a new code
                      </Link>
                    </CustomTypography>
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      </>
    );
  };

  varifyLastNameMfa = () => {
    return (
      <>
        <Formik
          initialValues={{ lastName: "" }}
          onSubmit={(val) => {
            this.verifyMFALastNameAPI(val.lastName);
          }}
          data-test-id="Formik"
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <img
                  style={styles.closeIconStyle}
                  alt="Close"
                  src={closeIcon}
                  onClick={() =>
                    this.setState({
                      step: this.state.step - 1,
                    })
                  }
                  data-test-id="iconCLose"
                />
                <div>
                  <img src={mainLogo} style={styles.mainLogo} alt="VITA" />
                  <Typography style={styles.head} variant="h5">
                    Verify Last Name for Added Security
                  </Typography>
                  <Typography style={styles.paraText}>
                    For an extra layer of protection, please confirm your last name before proceeding.
                  </Typography>

                  <CssTextField
                    placeholder="Enter your Last Name "
                    name="lastName"
                    value={props.values?.lastName || ""}
                    style={styles.signupInput}
                    className="inputbox"
                    onChange={(e)=> {
                      props.handleChange(e);
                      this.setState({lastNameError:''})
                    }}
                    defaultValue="Hello World"
                    id="filled-required"
                    data-test-id="code-change"
                    inputProps={{
                      color: "rgba(148, 163, 184, 1)",
                    }}
                    variant="outlined"
                    error={!!props.errors?.lastName && props.touched.lastName || !!this.state.lastNameError}
                    helperText={props.errors?.lastName || this.state.lastNameError}
                  />
                  <button
                    style={props.values?.lastName && !props.errors?.lastName ? styles.button : styles.disableButton}
                    type="submit"
                    data-test-id="handleContinueClick"
                    disabled={props.values?.lastName && !props.errors?.lastName ? false : true}
                  >
                    Verify
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { type, open, validTypeEmail, otpError, lastNameError } = this.state;

    return (
      <CustomBG>
        <MainContainer>
          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <Grid style={styles.headSecondgrid}>
                  {this.getStepViceForm()}

                  <Dialog
                    keepMounted
                    open={open}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                      style: {
                        maxWidth: "497px",
                        height: 'auto',
                        borderRadius: 24,
                        padding: "16px 32px",
                        background: "#fff"
                      },
                    }}
                  >
                    <DialogContainer>
                      <DialogContentText id="alert-dialog-slide-description" className="title" >
                        {type === 'email' && 'Email Not Registered'}
                        {type === 'phone' && 'Phone Number Not Registered'}
                      </DialogContentText>
                      <Typography className="metaTitle">
                        {type === 'email' && `The provided email isn't registered. Please check and try again or sign up now!`}
                        {type === 'phone' && `The provided mobile number isn't registered. Please check and try again or sign up now!`}
                        {type === 'resend' && !validTypeEmail && `We've sent you a new OTP. Please check your phone and enter the code to proceed.`}
                        {type === 'resend' && validTypeEmail && `We've sent you a new OTP. Please check your email and enter the code to proceed.`}
                      </Typography>
                    </DialogContainer>
                    <Divider />
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => this.handleClose()}
                        data-test-id="handleClose"
                        style={{
                          color: "#007AFF",
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          textTransform: "none"
                        }}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MainContainer>
      </CustomBG>
      // Customizable Area End
    );
  }
}

const styles: Record<string, React.CSSProperties> = {
  // Customizable Area Start

  closeIconStyle: {
    cursor: "pointer",
    borderRadius: "50px",
    background: "#F1F5F9",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    width: "24px",
  },
  mainLogo: {
    margin: "24px 0",
  },
  paraText: {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    marginTop: "8px",
    marginBottom: "20px",
    fontWeight: 500,
  },
  button: {
    borderRadius: "8px",
    width: "100%",
    fontSize: "16px",
    border: "none",
    marginTop: "24px",
    padding: '16px',
    backgroundColor: "rgba(19, 104, 158, 1)",
    color: "#FFF",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  disableButton: {
    opacity: 0.4,
    borderRadius: "8px",
    width: "100%",
    fontSize: "16px",
    border: "none",
    marginTop: "24px",
    padding: '16px',
    backgroundColor: "rgba(19, 104, 158, 1)",
    color: "#FFF",
    fontWeight: 700,
    fontFamily: 'Raleway',
  },

  headSecondgrid: {
    borderRadius: "24px",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    backgroundColor: "#fff",
    zIndex: 1,
    position: "relative",
    padding: "32px",
  },
  head: {
    fontWeight: 700,
    color: "#3C3C3B",
    textAlign: "left",
    fontSize: "32px",
  },

  verfiyLogostyle: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
  signupInput: {
    backgroundColor: '#F1F5F9',
    height: "20px",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "40px",
    color: "#1E293B",
    width: "100%",
  },
  error: {
    backgroundColor: '#F1F5F9',
    height: "20px",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "40px",
    color: "#1E293B",
    width: "100%",
    borderRadius: '5px',
    border: "1px solid #DC2626",
    background: "#2B2B2B",
  },
  multiFectorHeadingStyle: {
    color: "#3C3C3B",
    fontFamily: "Raleway",
    fontSize: "32px",
    fontWeight: 700,
    letterSpacing: "0.64px",
  },
  paregraphText: {
    color: "#475569",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.154px",
    maxWidth: "384px",
    marginTop: "8px",
  },
  smsPeregraph: {
    color: "#475569",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  radioStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "22px",
    cursor: "pointer",
  },

  smsTxt: {
    color: "var(--Text-Black, #151515)",
    fontFamily: "Raleway",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.198px",
  },
  bottomTextCode: { display: "flex", justifyContent: "center", paddingTop: '24px' },
  bottomLink: {
    color: "#13689E",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textDecoration: "none",
    paddingLeft: '5px',
  },
  errorMessage: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#D62626"
  }
  
  // Customizable Area End
};
