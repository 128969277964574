export const leftSideImg = require("./assets/leftSideImg.png");
export const rightSideImg = require("./assets/rightSideImg.png");
export const dietImg = require("./assets/image_restaurant.png");
export const logBook = require("./assets/image_local_library.svg");
export const schedule = require("./assets/image_event.svg");
export const preferance = require("./assets/image_notifications (1).svg");
export const exportImg = require("./assets/image_sim_card_download.svg");
export const reports = require("./assets/image_lab_profile.svg");
export const analytics = require("./assets/image_analytics.svg");
export const patientResources = require("./assets/image_local_hospital.svg");
export const settings = require("./assets/image_settings.svg");
export const profile = require("./assets/image_account_circle (1).svg");
export const help = require("./assets/image_help.svg");
export const logOut = require("./assets/image_logout.svg");
export const logo = require("./assets/image_ (4).svg");
export const sideBarOpen = require("./assets/group_slide left.svg");
export const sideBarClose = require("./assets/group_slide left (1).png");
export const msgIcon = require("./assets/image_forum.png");
export const notificationImgDots = require("./assets/image_notifications.svg");
export const notificationIcon = require("./assets/notificationImgDots.svg");
export const notificationFire = require("./assets/notificationFire.svg");
export const notificationTrofi = require("./assets/notificationTrofi.svg");
export const calender = require("./assets/celender.svg");
export const patientResourceses = require("./assets/patientResources.svg");
export const serchIcon = require("./assets/serchIcon.svg");
export const keyicon = require("./assets/keycontent_image.svg");
export const onBoardingArrow = require("./assets/onboardingArrow.png");
export const dashbord = require("./assets/dashbord.svg");
export const notificationPin = require("./assets/notificationPin.svg");
export const notificationWatch = require("./assets/notificationWatch.svg");
export const notificationMeg = require("./assets/notificationMeg.svg");
export const poperNoNotification = require("./assets/poperNoNotification.svg");
export const notificationCal = require("./assets/notificationCal.svg");
export const notificationCalCheck = require("./assets/notificationCalCheck.svg");
export const bgTopImg = require("./assets/image_bg_top.png");
export const bgBottomImg = require("./assets/image_bg_bottom.png");
export const dialogBoxCloseIcon = require("./assets/image_close.svg");
export const crosicorn = require("./assets/crosicorn.svg");
