export const bellIcon = require("../assets/bell.png");
export const circleIcon = require("../assets/circle.png");
export const crossIcon = require("../assets/cross.png");
export const blueLine = require("../assets/blueLine.svg");
export const notificationMainIcon = require("../assets/notificationMainIcon.svg");
export const notificationMsg = require("../assets/notificationMsg.svg");
export const notificationPin = require("../assets/notificationPin.svg");
export const notificationWatch = require("../assets/notificationWatch.svg");
export const notificationTrofi = require("../assets/notificationTrofi.svg");
export const notificationFire = require("../assets/notificationFire.svg");
export const notificationMenuReade = require("../assets/notificationMenuReade.svg");
export const notificationMenuSkip = require("../assets/notificationMenuSkip.svg");
export const noNotification = require("../assets/noNotification.svg");
export const notificationCal = require("../assets/notificationCal.svg");
export const notificationCalCheck = require("../assets/notificationCalCheck.svg");
