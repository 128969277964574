// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { ChangeEvent } from 'react';
export interface Props {
  id: string;
  navigation: any;
}
interface CountryCodeData {
  phone_code: string;
  name: string;
}
export interface CountryCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  step: number;

  validTypeEmail: boolean;
  validData: { email?: string; phone?: string };
  mfa: any;
  opt_meta: any;
  open: boolean;
  type: string;
  otpError:string;
  lastNameError: string;
  countryCodeData: CountryCodeData[];
  selectedCountry: CountryCodeData;
  dropdownOpen: boolean;
  isCountryData: string;
  searchData: string;
  phoneNumber: string;
  socialMedia:boolean;
  filteredData: CountryCodeData[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ResetPasswordMFAController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createMfaId: string = "";
  verifyMfaInvId: string = "";
  verifyMfaLastNameId: string = "";
  countryCodeApiCallId: string = "";

  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      step: 0,

      validTypeEmail: false,
      validData: { email: "", phone: "" },
      mfa: {},
      opt_meta: {},
      open: false,
      type: '',
      otpError:'',
      lastNameError: '',
      countryCodeData: [],
      dropdownOpen: false,
      isCountryData: "",
      selectedCountry: { phone_code: "+1", name: "United States of America" },
      searchData: "",
      phoneNumber: "",
      socialMedia:false,
      filteredData: []
      // Customizable Area End
    };
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start

  async componentDidMount() {
    this.makeRemoteRequest();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.varifyCOdeSIgnUpreceive(apiRequestCallId, responseJson);
        this.setState({ countryCodeData: this.countryCodesToDropDown(responseJson?.data)});
        this.setState({ filteredData: this.countryCodesToDropDown(responseJson?.data) });
      } 
    }
    // Customizable Area End
  }

  validEmailRes = (responseJson: any) => {
    if (responseJson?.errors || responseJson?.message) {
      this.setState({ open: true, type: this.state.validTypeEmail ? 'email' : 'phone' });
      this.setState({socialMedia:true,open: false,})
    } else {
      this.setState({ step: this.state.type !== 'resend' ? this.state.step + 1 : this.state.step, opt_meta: responseJson.meta });
    }
  }

  otpResponce = (responseJson: any) => {
    if (responseJson?.errors) {
      this.setState({ type: 'code', otpError: responseJson?.errors[0].otp ||  responseJson?.errors[0].pin });
    } else {
      this.setState({ step: this.state.step + 1, opt_meta: responseJson.meta  });
      setStorageData("user_token", responseJson.meta.user_token)
    }
  }

  lastNameResponce = (responseJson: any) => {
    if (responseJson?.message === 'Last name not present' || responseJson?.message === 'Last name not match') {
      this.setState({ type: 'lastname', lastNameError: 'Please enter the correct last name.' });
    } else if (responseJson?.message === "Last name verify successfully!") {
      this.props.navigation.navigate("ForgotPassword")
    }
  }  

  varifyCOdeSIgnUpreceive = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createMfaId) {
     this.validEmailRes(responseJson);
    }
    if (apiRequestCallId === this.verifyMfaInvId) {
     this.otpResponce(responseJson);
    }
    if (apiRequestCallId === this.verifyMfaLastNameId) {
     this.lastNameResponce(responseJson)
    }
  };


  createMFACodeAPI = async () => {
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/otps`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        this.state.validTypeEmail
          ? {
            data: {
              attributes: {
                email: this.state.validData.email,
              },
            }
          }
          : {
            data: {
              attributes: {
                full_phone_number: this.state.selectedCountry.phone_code + this.state.validData.phone,
              },
            },
          })
    );
    this.createMfaId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  verifyMFACodeAPI = async (val: string) => {
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let number = val?.replace(/-/g, '');    
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/otp_confirmations`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          token: this.state.opt_meta.token,
          otp_code: number,
        },
      }));
    this.verifyMfaInvId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  verifyMFALastNameAPI = async (val: string) => {    
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/verify_last_name`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",

      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        token: this.state.opt_meta.user_token,
        last_name: val,
      }));
    this.verifyMfaLastNameId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  handleClose = () => {
    this.setState({ open: !this.state.open })
  }

  resendCode = () => {
    this.createMFACodeAPI()
    this.setState({ open: true, type: 'resend' })
  }

  countryCodesToDropDown = (data: Array<CountryCode>) => {
    return data.map((item) => ({
      name: item.attributes.name,
      phone_code: `+${item.attributes.country_code}`
    }));
  };

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );
  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
    }));
    this.setState({ 
      searchData: "",
      filteredData: this.state.countryCodeData
   });
  };
  
  handleCountrySelect = (country: CountryCodeData, code: string) => {
    this.setState({
        selectedCountry: country,
        dropdownOpen: false,
        isCountryData: code,
    });
  };

  handleSearchData = (event: ChangeEvent<HTMLInputElement>) => {
    const searchData = event.target.value.toLowerCase();
    const filteredDatalist = this.state.countryCodeData.filter(item =>
      item.name.toLowerCase().includes(searchData) || item.phone_code.toLowerCase().includes(searchData)
    );
    this.setState({ 
      searchData: event.target.value,
      filteredData: filteredDatalist
    });
  };

  socialMediaModalClosed = () =>{
    this.setState({socialMedia:false})
  }
  // Customizable Area End
}
