import React from "react";
// Customizable Area Start
import { Button, Grid, Divider, CircularProgress } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import {
  profileIcon,
  notificationIcon,
  calender,
  serchIcon,
  image_favorite,
  javygoter,
  mainLogo,
  like,
  liked,
  foodImg,
  group_top,
  group_tops,
  noresult,
  backButton,
  noFavoriteImg
} from "../src/assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import PatientResourcesController, {
  Props,
  IField,
  configJSON
} from "./PatientResourcesController";
import WebHeader from "../../../components/src/WebHeader.web";
import ReactPlayer from "react-player";
// Customizable Area End

export default class PatientResources extends PatientResourcesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  javygtorInformationBlock = () => {
    return (
      <>
        {this.state.javygatorInfoData?.title ? (
          <>
            <Grid>
              <Grid container justifyContent="space-between">
                <Grid item style={{ display: "flex" }}>
                  <Grid style={styles.recommandTextGrid}></Grid>
                  <Grid>
                    <Grid style={styles.recipeText}>
                      {this.state.javygatorInfoData.title}
                    </Grid>
                    <Grid style={styles.recipyDisc}>
                      {this.state.javygatorInfoData?.sub_title}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <img
                    src={this.state.javygatorInfoData?.icon}
                    alt="javygoter"
                    style={{ width: "150px", height: "50px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                backgroundColor: "#F8FAFC",
                borderRadius: "12px",
                padding: "1% 10px 10px",
                marginTop: "8px",
              }}
            >
              <Grid
                dangerouslySetInnerHTML={{
                  __html: this.state.javygatorInfoData?.description,
                }}
                style={{ fontFamily: "Raleway" }}
              ></Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  PKUInformationBlock = () => {
    return (
      <>
        {this.state.pkuInfoData?.title ? (
          <>
            <Grid>
              <Grid container justifyContent="space-between">
                <Grid item style={{ display: "flex" }}>
                  <Grid
                    style={{ ...styles.recommandTextGrid, height: "none" }}
                  ></Grid>
                  <Grid>
                    <Grid
                      style={{ ...styles.recipeText, paddingBottom: "7px" }}
                    >
                      {this.state.pkuInfoData?.title}
                    </Grid>
                    <Grid style={styles.recipyDisc}>
                      {this.state.pkuInfoData?.sub_title}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <img
                    src={this.state.pkuInfoData?.icon}
                    alt="mainLogo"
                    style={{ width: "150px", height: "50px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid
                style={{
                  backgroundColor: "#F8FAFC",
                  borderRadius: "12px",
                  padding: "1% 10px 10px",
                  marginTop: "8px",
                }}
              >
                <Grid
                  dangerouslySetInnerHTML={{
                    __html: this.state.pkuInfoData?.description,
                  }}
                  style={{ fontFamily: "Raleway" }}
                ></Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  serchBtn = () => {
    const {
      advanceSearchDataNot,
      advanceSearchData,
      favouritesTick,
    } = this.state;
    return (
      <Grid item lg={5} md={12} sm={12} xs={12}>
        <Grid
          style={
            this.state.serchText
              ? (styles.mainGridCardActive as React.CSSProperties)
              : (styles.mainGridCard as React.CSSProperties)
          }
        >
          <Grid style={styles.inputSerchGrid}>
            <span ref={this.state.onboardingRefs[3]}>
              <img src={serchIcon} alt="serchIcon" />
            </span>
            <input
              type="text"
              style={styles.inputStyle as React.CSSProperties}
              data-test-id="search-recipe"
              placeholder="Search for a recipe"
              value={this.state.serchText}
              onChange={this.searchRecipes}
            />
            {this.state.showCloseIcon && <CloseIcon style={{ cursor: "pointer", color: "#13689E" }} onClick={this.clearSearchText} />}
            {advanceSearchDataNot && this.state.serchText ? (
              <>
                <Grid style={styles.boxContainerNo as React.CSSProperties}>
                  <>
                    <Grid>
                      <p style={styles.noResult}>
                        No results found for
                        <span
                          style={{ fontStyle: "italic", color: "#13689E" }}
                        >{`"${this.state.serchText}"`}</span>
                      </p>
                    </Grid>
                    <Grid style={{ margin: "20% 0px 1%" }}>
                      <img
                        src={noresult}
                        alt="noresult"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid style={{ textAlign: "center" }}>
                      <p style={styles.noMatchText}>
                        No Matching recipes found
                      </p>
                      <span
                        style={{
                          color: '#334155',
                          textAlign: 'center',
                          fontFamily: 'Raleway',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '20px',
                        }}
                      >Sorry, we couldn't find any recipes matching your search. Please try a different search term or explore other recipes.</span>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Button
                        style={styles.exploreRecipesBtn}
                        onClick={this.exploreRecipes}
                      >
                        Explore Recipes
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </>
            ) : (
              <>
                {this.state.serchText && (
                  <Grid style={styles.serchGridmain as React.CSSProperties}>
                    {this.state.serchText.length < 3 && (
                      <p style={styles.serchErrorText}>
                        *Please enter at least 3 characters to begin your food
                        search.
                      </p>
                    )}
                    <Grid
                      style={styles.cardMainContainer as React.CSSProperties}
                    >
                      <>
                        {advanceSearchData ? (
                          <>
                            <Grid style={{ borderBottom: "1px solid #E2E8F0" }}>
                              <p style={styles.serchRecomandText}>
                                Recommendations
                              </p>
                            </Grid>
                            {advanceSearchData?.map(
                              (value: {
                                id: string, type: string, attributes: {
                                  image: string,
                                  name: string,
                                  difficulty: string,
                                  is_favourite: boolean
                                }
                              }, index: number) => (
                                <Grid
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                  key={index}
                                >
                                  <Grid
                                    style={
                                      styles.favoriteImgsSearch as React.CSSProperties
                                    }
                                    data-test-id="favouritesItems-1"
                                    onClick={() =>
                                      this.favouritesItems(value.id, value.type, value.attributes.is_favourite, advanceSearchData, '')
                                    }
                                  >
                                    <img
                                      src={
                                        value.attributes.is_favourite
                                          ? liked
                                          : like
                                      }
                                      alt="like"
                                    />
                                  </Grid>
                                  <Grid
                                    style={{
                                      ...styles.cardContainer,
                                      marginTop: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => this.openRecipes(value.id, "")}
                                    data-test-id="openRecipes-advancesearch"
                                  >
                                    <Grid>
                                      <img
                                        src={
                                          value.attributes.image
                                        }
                                        alt="group_tops"
                                        style={{
                                          width: "100%",
                                          height: "170px",
                                          minWidth: "200px",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      style={{
                                        padding: "10px 0px 10px 20px",
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <p style={styles.recipeName}>
                                        {value.attributes.name.length > 30
                                          ? `${value.attributes.name.substring(
                                            0,
                                            30
                                          )}...`
                                          : value.attributes.name}
                                      </p>
                                      <p style={styles.dificuktyText}>
                                        Difficulty:{" "}
                                        {value.attributes.difficulty}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </>
                        ) : (
                          <CircularProgress />
                        )}
                      </>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  viewAllTextmargin = () => {
    return this.state.viewAllText ? "inherit" : "none"
  };

  viewAllTextGridMain = () => {
    return <>
      {!this.state.viewAllText && (
        <Grid style={{ ...styles.recommandTextGrid, height: "none" }} ></Grid>
      )}
    </>
  }

  viewAllTextGridSecond = () => {
    return <>
      {this.state.viewAllText && (
        <>
          <Grid>
            <img data-test-id="backButton" onClick={this.viewAllBackButton} src={backButton} style={{ cursor: "pointer", marginRight: "15px" }} />
          </Grid>
        </>
      )}
    </>
  }

  viewAllTextMarginTop = () => {
    return this.state.viewAllText ? '9px' : '0px'
  }

   /* istanbul ignore next */
  YoutubeVideoAndImg = (value: {
    id: string, type: string, attributes: {
      youtube_link: string;
      image: string,
      name: string,
      difficulty: string,
      is_favourite: boolean
    }
  }) => {
    return <>
      <Grid>
        {value?.attributes?.image ?
          <img
            src={value.attributes.image}
            alt="recipe_image"
            style={styles.cardImg}
          />:
          <ReactPlayer
            url={value?.attributes?.youtube_link}
            width="100%"
            height="270px"
            style={{ ...styles.cardImg, display: 'block', borderRadius: '12px' }}
            controls={true}
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  showinfo: 0
                }
              }
            }}
            data-test-id="firstMenu"
            onPlay={() => this.openRecipes(value.id,"")}
          />
        }
      </Grid>
    </>
  }

  noFavoriteImg = (favritDataAvailabel: boolean) => {
    return <>
      {favritDataAvailabel && <>
        <Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={noFavoriteImg} alt='' style={styles.noFavoriteImg} />
          </div>
          <Grid style={{ textAlign: "center" }}>
            <p style={styles.noMatchText1}>No Favorites Yet</p>
            <span
              style={styles.noMatchSubTex}
            >Tap the heart icon on recipes you love to add them to your favorites and access them here anytime.</span>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Button
              style={styles.exploreRecipesBtn1}
              onClick={this.exploreRecipes}
            >
              Explore Recipes
            </Button>
          </Grid>
        </Grid>
      </>}
    </>
  }

  /* istanbul ignore next */
  recipesBlock = () => {
    const {
      selectedFoodType,
      viewAllText,
      recommendedRecipes,
      catagoryData,
      featuredRecipes,
      favoriteAllDatas,
      viewAllState
    } = this.state;
    let allData = [] as any;
    let dataName = "" as string;
    let favritDataAvailabel = false as boolean
    let dataText = "";
    if (viewAllText === "Recommendations" && viewAllState) {
      allData = recommendedRecipes;
      dataName = "recommendedRecipes"
      dataText = `Showing ${recommendedRecipes.length} results for recommendations`;
    } else if (viewAllText === "Featured Recipes") {
      allData = featuredRecipes;
      dataName = "featuredRecipes"
      dataText = `Showing ${featuredRecipes.length} results for recommendations`;
    } else if (
      viewAllText === "Breakfast" ||
      viewAllText === "Lunch" ||
      viewAllText === "Dinner" ||
      viewAllText === "Snacks"
    ) {
      allData = catagoryData;
      dataName = "catagoryData"
      dataText = `Showing ${catagoryData.length} results for recommendations`;
    } else if (viewAllText === "Your Favourite Recipes") {
      favritDataAvailabel = favoriteAllDatas.length ? false : true;
      allData = favoriteAllDatas;
      dataName = "favoriteAllDatas"
    } else {
    }
    return (
      <>
        <Grid>
          <Grid container justifyContent="space-between" spacing={4} alignItems="center" >
            <Grid item lg={6} md={6} sm={12} xs={12} style={{ margin: this.viewAllTextmargin() }}>
              <Grid style={styles.mainConGrid}>
                {this.viewAllTextGridMain()}
                {this.viewAllTextGridSecond()}
                <Grid style={{ marginTop: this.viewAllTextMarginTop() }}>
                  <Grid style={{ ...styles.recipeText, marginBottom: "7px" }}>
                    {viewAllText || "Recipes"}
                  </Grid>
                  <Grid style={styles.recipyDisc}>
                    {dataText ||
                      `Discover delectable recipes for every taste bud.`}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.serchBtn()}
            <Grid item>
              <Grid
                style={styles.favoriteImg}
                onClick={() => this.openFavorite("Your Favourite Recipes")}
                data-test-id="openFavorite"
                ref={this.state.onboardingRefs[4]}
              >
                <img
                  src={image_favorite}
                  alt="image_favorite"
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        {this.noFavoriteImg(favritDataAvailabel)}
        {this.state.viewAllState ? (<>
          <Grid style={{ margin: "5% 0px" }}>
        <Grid container spacing={4}>
          {allData.map((value: {
            id: string, type: string, attributes: {
              image: string,
              name: string,
              difficulty: string,
              is_favourite: boolean,
              youtube_link: string

            }
          }, index: number) => (
            <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
              <Grid
                style={{
                  position: "relative",
                  width: "99%",
                  cursor: "pointer",
                }}
              >
                <Grid
                  style={styles.favoriteImgs as React.CSSProperties}
                  onClick={() => this.favouritesItems(value.id, value.type, this.state.recipeData?.attributes?.is_favourite ? this.state.recipeData?.attributes?.is_favourite : value.attributes.is_favourite, allData, dataName)}
                  data-test-id="favouritesItemsFirst"
                >
                  <img
                    src={
                      value.attributes.is_favourite
                        ? liked
                        : like
                    }
                    alt="like"
                  />
                </Grid>
                <Grid
                  style={styles.cardContainer}
                  onClick={() => this.openRecipes(value.id, dataName)}
                  data-test-id="openRecipesFirst"
                >
                  <Grid>
                    {value?.attributes?.image ?
                      <img
                        src={value.attributes.image}
                        alt="group_top"
                        style={{
                          width: "100%",
                          height: "240px",
                          minWidth: "200px",
                        }}
                      /> :
                      <ReactPlayer
                        url={value?.attributes?.youtube_link}
                        height="240px"
                        style={{ ...styles.cardImg, display: 'block', borderRadius: '12px' }}
                        controls={true}
                        config={{
                          youtube: {
                            playerVars: {
                              modestbranding: 1,
                              showinfo: 0
                            }
                          }
                        }}
                        width="100%"
                        onPlay={() => this.openRecipes(value.id, "")}
                      />
                    }
                  </Grid>
                  <Grid
                    style={{
                      padding: "10px 0px 10px 20px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <p style={styles.recipeName}>{value.attributes.name}</p>
                    <p style={styles.dificuktyText}>
                      Difficulty: {value.attributes.difficulty}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
        </>
        ) : (
          <>
          <Grid style={{ marginTop: "45px" }}>
              <Grid style={styles.viewAllGrid}>
                <p style={styles.recommndText}>Recommendations</p>
                <p
                  style={styles.viewAllText}
                  onClick={() => this.viewAllOpen("Recommendations")}
                >
                  view all <ChevronRightIcon />
                </p>
              </Grid>
              <Grid container spacing={4} wrap="nowrap">

                {recommendedRecipes.map((value: {
                  id: string, type: string, attributes: {
                    youtube_link: string;
                    image: string,
                    name: string,
                    difficulty: string,
                    is_favourite: boolean
                  }
                }, index: number) => (
                  <Grid
                    item
                    style={{ position: "relative", cursor: "pointer" }}
                    key={index}
                  >
                    <Grid
                      style={styles.favoriteImgs as React.CSSProperties}
                      onClick={() => this.favouritesItems(value.id, value.type, value.attributes.is_favourite, recommendedRecipes, '')}
                    >
                      <img
                        src={
                          value.attributes.is_favourite
                            ? liked
                            : like
                        }
                        alt="like"
                      />
                    </Grid>
                    <Grid
                      style={styles.cardContainer}
                      onClick={() => this.openRecipes(value.id, "recommendedRecipes")}
                      data-test-id="openRecipes-recommended"
                    >
                      {this.YoutubeVideoAndImg(value)}

                      <Grid style={styles.cardContentText}>
                        <p style={styles.recipeName}>
                          {value.attributes.name.length > 30
                            ? `${value.attributes.name.substring(0, 30)}...`
                            : value.attributes.name}
                        </p>
                        <p style={styles.dificuktyText}>
                          Difficulty: {value.attributes.difficulty}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid style={{ margin: "40px 0px" }}>
              <p style={{ ...styles.catagoryText, margin: "30px 0px" }}>
                Categories
              </p>
              <Grid>
                <Grid container spacing={2}>
                  {this.state.categorys &&
                    this.state.categorys.map((value: { attributes: { name: string, id: number } }) => (
                      <Grid item>
                        <Grid
                          style={
                            selectedFoodType === value.attributes.name
                              ? (styles.breckFastText as React.CSSProperties)
                              : (styles.breckFastTextNot as React.CSSProperties)
                          }
                          onClick={() =>
                            this.selectFoodType(
                              value.attributes.name,
                              value.attributes.id
                            )
                          }
                          ref={this.state.onboardingRefs[5]}
                        >
                          {value.attributes.name}
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <p style={styles.showAllResultsText}>
                  Showing {catagoryData?.length} results for{" "}
                  {selectedFoodType.toLowerCase()}
                </p>
              </Grid>
              <Grid style={styles.viewAllGrid}>
                <p style={styles.recommndText}>
                  Recipes for {selectedFoodType}
                </p>
                <p
                  style={styles.viewAllText}
                  onClick={() => this.viewAllOpen(selectedFoodType)}
                >
                  view all <ChevronRightIcon />
                </p>
              </Grid>
              <Grid>
                <Grid container spacing={4} wrap="nowrap">
                  {catagoryData.map((value: {
                    id: string, type: string, attributes: {
                      image: string,
                      name: string,
                      difficulty: string,
                      is_favourite: boolean
                      youtube_link: string
                    }
                  }, index: number) => (
                    <Grid
                      item
                      style={{ position: "relative", cursor: "pointer" }}
                      key={index}
                    >
                      <Grid
                        style={styles.favoriteImgs as React.CSSProperties}
                        onClick={() =>
                          this.favouritesItems(value.id, value.type, value.attributes.is_favourite, catagoryData, "")
                        }
                      >
                        <img
                          alt="like"
                          src={
                            value.attributes.is_favourite
                              ? liked
                              : like
                          }
                        />
                      </Grid>
                      <Grid
                        onClick={() => this.openRecipes(value.id, "catagoryData")}
                        style={styles.cardContainer}
                      >
                        <Grid>
                          {value?.attributes?.image ?
                            <img
                              src={value.attributes.image}
                              style={styles.cardImg}
                              alt="group_top"
                            /> :
                            <ReactPlayer
                              url={value?.attributes?.youtube_link}
                              height="270px"
                              width="100%"
                              controls={true}
                              onPlay={() => this.openRecipes(value.id,"")}
                              style={{ ...styles.cardImg, display: 'block', borderRadius: '12px' }}
                              config={{
                                youtube: {
                                  playerVars: {
                                    showinfo: 0,
                                    modestbranding: 1
                                  }}
                              }}/>
                          }
                        </Grid>
                        <Grid style={styles.cardContentText}>
                          <p style={styles.recipeName}>
                            {value.attributes.name.length > 30
                              ? `${value.attributes.name.substring(0, 30)}...`
                              : value.attributes.name}
                          </p>

                          <p style={styles.dificuktyText}>
                            Difficulty: {value.attributes.difficulty}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <p style={{ ...styles.catagoryText, margin: "45px 0px 20px" }}>
                Featured
              </p>

              <Grid style={styles.viewAllGrid}>
                <p style={{ ...styles.recommndText, marginTop: "0px" }}>
                  Featured Recipes
                </p>
                <p
                  style={styles.viewAllText}
                  onClick={() => this.viewAllOpen("Featured Recipes")}
                >
                  view all <ChevronRightIcon />
                </p>
              </Grid>
              <Grid container spacing={4} wrap="nowrap">
                {featuredRecipes &&
                  featuredRecipes.map((value: {
                    id: string, type: string, attributes: {
                      image: string,
                      name: string,
                      youtube_link: string
                      difficulty: string,
                      is_favourite: boolean
                    }
                  }, index: number) => (
                    <Grid
                      item
                      style={{ position: "relative", cursor: "pointer" }}
                      key={index}
                    >
                      <Grid
                        style={styles.favoriteImgs as React.CSSProperties}
                        onClick={() =>
                          this.favouritesItems(value.id, value.type, value.attributes.is_favourite, featuredRecipes, '')
                        }
                      >
                        <img
                          src={
                            value.attributes.is_favourite
                              ? liked
                              : like
                          }
                          alt="like"
                        />
                      </Grid>
                      <Grid
                        onClick={() => this.openRecipes(value.id, "featuredRecipes")}
                        style={styles.cardContainer}
                      >
                        <Grid>
                          {value?.attributes?.image ?
                            <img
                              src={value.attributes.image}
                              alt="group_top"
                              style={styles.cardImg}
                            /> 
                            :
                            <ReactPlayer
                              width="100%"
                              url={value?.attributes?.youtube_link}
                              height="270px"
                              controls={true}
                              style={{ ...styles.cardImg, borderRadius: '12px', display: 'block' }}
                              onPlay={() => this.openRecipes(value.id, "")}
                              config={{
                                youtube: { playerVars: { modestbranding: 1, showinfo: 0 } }
                              }}
                            />
                            }
                        </Grid>
                        <Grid style={styles.cardContentText}>
                          <p style={styles.recipeName}>
                            {value.attributes.name.length > 30? `${value.attributes.name.substring(0, 30)}...` : value.attributes.name}
                          </p>
                          <p style={styles.dificuktyText}> Difficulty: {value.attributes.difficulty}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
           
          </>
        )}
      </>
    );
  };

    /* istanbul ignore next */
  mainCard = () => {
        return (
      <>
        {this.state.recipeData && (
          <Grid style={{ padding: "20px", backgroundColor: "#FAFAFA" }}>
            <Grid container style={{ margin: "0px -8px 2%" }}>
              <Grid>
                <img data-test-id="backButton" onClick={this.backButton} src={backButton} style={{ cursor: "pointer" }} />
              </Grid>
              <Grid style={{ marginLeft: "8px" }}>
                <Grid style={styles.recipyNameText}>
                  {this.state.recipeData?.attributes?.name}
                </Grid>
                <Grid container alignItems="center" style={styles.likeAndTextCss}>

                  <Grid
                    onClick={() =>
                      this.favouritesItems(this.state.recipeData.id, this.state.recipeData.type, this.state.recipeData?.attributes?.is_favourite, [], "")
                    }
                    style={styles.pointer}
                  >
                    <img
                      alt="like"
                      src={
                        this.state.recipeData?.attributes?.is_favourite
                          ? liked
                          : like
                      }
                    />
                  </Grid>
                  <span style={styles.serviceCount}>
                    Servings: {this.state.recipeData?.attributes?.servings || "0"}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <div>
                {this.state.recipeData?.attributes?.youtube_link ? (
                  <>
                        <ReactPlayer
                          url={this.state.recipeData?.attributes?.youtube_link}
                          width="100%"
                          height="270px"
                          style={{ borderRadius: '12px' }}
                          title="YouTube video player"
                          controls={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          referrerPolicy="strict-origin-when-cross-origin"
                        />
                  </>
                ) : (
                  <img
                    alt="foodImg"
                    src={this.state.recipeData?.attributes?.image}
                    style={{
                      width: "100%",
                      height: "315px",
                      borderRadius: "12px",
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid container style={styles.resipyName as React.CSSProperties}>
              <Grid style={styles.recipyName}></Grid>

              <Grid style={styles.recipyNameTexts}>
                {`Phenylalanine (Phe), Tyrosine (Tyr) and Protein Per Serving`}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Grid style={{ maxWidth: "550px" }}>
                  <Grid  style={styles.resipisCard}>
                    <Grid style={{ display: "flex" }}>
                      <Grid style={styles.recipyName}></Grid>
                      <div>
                        <p style={styles.resipisCardText}>
                          Nutritional Information
                        </p>
                      </div>
                    </Grid>
                    <Grid
                      dangerouslySetInnerHTML={{
                        __html: this.state.recipeData?.attributes?.nutritional_information,
                      }}
                      style={styles.lastBox}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Grid style={{ maxWidth: "550px" }}>
                <Grid  style={styles.resipisCard}>
                    <Grid style={styles.textCssMain}>
                      <Grid style={styles.recipyName}></Grid>
                      <div>
                        <p style={styles.resipisCardText}>Ingredients</p>
                      </div>
                    </Grid>
                    <Grid
                      dangerouslySetInnerHTML={{
                        __html: this.state.recipeData?.attributes?.ingredients,
                      }}
                      style={styles.lastBox}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Grid style={{ maxWidth: "550px" }}>
                <Grid  style={styles.resipisCard}>
                    <Grid style={styles.textCssMain}>
                      <Grid style={styles.recipyName}></Grid>
                      <div>
                        <p style={styles.resipisCardText}>Directions</p>
                      </div>
                    </Grid>
                    <Grid
                      dangerouslySetInnerHTML={{
                        __html: this.state.recipeData?.attributes?.directions,
                      }}
                      style={styles.lastBox}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

   checkRedirection = () => {  
    return (
      <>
        {this.state.selectedSideText === configJSON.javText && this.javygtorInformationBlock()}
        {this.state.selectedSideText === configJSON.pkuText && this.PKUInformationBlock()}
        {this.state.selectedSideText === configJSON.recipesText && this.recipesBlock()}
        {/^(\/RecipesDetail\/\d+)$/.test(this.state.selectedSideText) && this.mainCard()}
      </>
    );
  };

  // Customizable Area End
  render() {
    const {
      selectedSideText,
      openRecipes,
      pkuInfoData,
      catagoryData,
    } = this.state;

    const SideBaru = SideBar;


    // Customizable Area Start
    return (
      <>
      {
        this.state.loginUserType ? (
          <ThemeProvider theme={theme}>
        <WebOnboarding
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable && !this.state.openOneScreenOnboarding}
        />
        {this.state.isLoading && <Loader />}
        <div style={{ display: "flex" }}>
          <SideBaru navigation={this.props.navigation} />
          <Grid style={styles.mainContainer}>
          <WebHeader title={"Patient Resources"} icon={profileIcon}/>
            {openRecipes ? (
              <Grid lg={12}>{this.mainCard()}</Grid>
            ) : (
              <Grid container style={{ backgroundColor: "#FAFAFA" }}>
                <Grid item lg={2} md={12} sm={12} xs={12} style={styles.sideMenuTextCss}>
                  <Grid>

                    {this.state.imageOption.map((value: { text: string, src: string }, index: number) => {
                      return (
                        <Grid
                          key={index}
                          style={{
                            ...styles.imgContainer,
                            backgroundColor:
                              selectedSideText === value.text
                                ? "#F1F5F9"
                                : "#fff",
                          }}
                          onClick={() => this.slectFromSide(value.text)}
                          data-test-id={value.text}

                        >
                          <Grid item>
                            <img
                              src={value.src}
                              alt={`${value.src}`}
                              style={{ width: "20px", height: "18px" }}

                            />
                          </Grid>
                          <Grid
                            item
                            style={{
                              ...styles.imgText,
                              fontWeight:
                                selectedSideText === value.text ? 600 : 400,
                            }}
                            ref={this.state.onboardingRefs[index]}
                          >
                            {value.text}
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item lg={10} md={12} sm={12} style={{ padding: "20px 20px 20px 20px", width: "80vw" }}>
                    {this.checkRedirection()}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </ThemeProvider>

        ):(
          this.redirectToLogin()
        )
      }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  serchGridmain: {
    position: "absolute",
    top: "100%",
    zIndex: 1,
    left: "0px",
    width: "91%",
  },
  serchErrorText: {
    fontFamily: "Raleway",
    fontSize: "12px",
    fontWeight: 400,
    color: "#475569",
    margin: "5px 0px 0px",
  },
  cardMainContainer: {
    position: "absolute",
    width: "98%",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "24px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
    maxHeight: "58vh",
    overflow: "auto",
    marginTop: "10px",
  },
  cardContentText: {
    padding: "10px 0px 10px 20px",
    backgroundColor: "#fff",
    width: "260px",
    height: "41px",
  },
  likeAndTextCss: { gap: "7px" },
  noResult: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#64748B",
    fontFamily: "Raleway",
  },
  resipyName: {
    margin: "2% 0px",
    flexWrap: "nowrap",
    padding: "12px 10px",
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
  },
  textCssMain:{ display: "flex" },
  lastBox: { fontFamily: "Raleway", margin: "6px 0px 6px 18px"},
  boxContainerNo: {
    position: "absolute",
    // width: "91%",
    backgroundColor: "#fff",
    top: "135%",
    zIndex: 1,
    padding: "20px",
    borderRadius: "24px",
    left: "0px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
  },
  resipisCard: {
    margin: "10px 0px",
    padding: "15px 10px",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
  },
  resipisCardText: {
    margin: "6px 0px 6px 9px",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Raleway",
    color: "#1A1D1F",
  },
  viewAllGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceCount: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 500,
    color: "#475569",
  },
  recipyName: { backgroundColor: "#13689E", width: "8px", borderRadius: "4px" },
  recipyNameText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  recipyNameTexts: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    margin: "10px 0px 10px 8px",
  },
  sideMenuTextCss: { padding: "20px 20px 0px 10px" },
  noMatchText: {
    margin: "0px",
    fontSize: "16px",
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: "Raleway",
    color: "#0F172A",
    textTransform: 'capitalize' as 'capitalize',
  },
  noMatchText1: {
    margin: "0px",
    fontSize: "18px",
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: "Raleway",
    color: "#0F172A",
    textTransform: 'capitalize' as 'capitalize',
  },
  noMatchSubTex: {
    color: '#334155',
    textAlign: 'center' as "center",
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  noFavoriteImg: {
    marginTop: "90px",
    width: "50%",

  },
  exploreRecipesBtn: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: 'normal',
    color: "#FFFFFF",
    backgroundColor: "#13689E",
    padding: "10px 30px",
    // lineHeight: '22px',
    width: "40%",
    borderRadius: "50px",
    margin: "5% 13% 18%",
    textTransform: 'capitalize' as 'capitalize',
  },
  exploreRecipesBtn1: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: 'normal',
    color: "#FFFFFF",
    backgroundColor: "#13689E",
    padding: "10px 30px",
    // lineHeight: '22px',
    // width: "40%",
    borderRadius: "50px",
    // margin: "3% 13% 18% 36%",
    textTransform: 'capitalize' as 'capitalize',
  },
  showAllResultsText: {
    fontFamily: "Raleway",
    fontSize: "12px",
    fontWeight: 500,
    color: "#94A3B8",
  },
  catagoryText: {
    fontFamily: "Raleway",
    fontSize: "20px",
    fontWeight: 700,
    color: "#1A1D1F",
  },
  serchRecomandText: {
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 550,
    color: "#0F172A",
    marginBottom: "5px",
  },
  breckFastText: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 600,
    color: "#1A1D1F",
    border: "1px solid #13689E",
    padding: "10px 0px",
    textAlign: "center",
    width: "92px",
    backgroundColor: "#E0F3FF",
    borderRadius: "50px",
    boxShadow:
      "0px 2px 8px 0px #00000014",
    cursor: "pointer",
  },
  breckFastTextNot: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 600,
    color: "#334155",
    padding: "10px 0px",
    textAlign: "center",
    width: "92px",
    backgroundColor: "#fff",
    borderRadius: "50px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
    cursor: "pointer",
  },
  listTextHead: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 600,
  },
  overViewText: {
    fontFamily: "Raleway",
    fontSize: "18px",
    fontWeight: 600,
  },
  overViewDiscText: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 500,
    color: "#475569",
    lineHeight: "22px",
  },
  dividerStyle: { backgroundColor: "#000", height: "40px", width: "2px" },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 13% 12px 3%",
    backgroundColor: "#F1F5F9",
    marginBottom: "10px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "transparent",
    boxShadow:
      "0px 2px 8px 0px #00000014",
    borderRadius: "16px",
    overflow: "hidden",
  },
  recipeName: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 700,
    color: "#0F172A",
    margin: "0px",
  },
  cardImg: { width: "280px", height: "270px", borderRadius: "6px 6px 0px 0px" },
  dificuktyText: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    fontStyle: "italic",
    margin: "0px",
    marginTop: "5px",
  },
  favoriteImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    boxShadow:
      "0px 2px 8px 0px #00000014",
    borderRadius: "50%",
    backgroundColor: "#fff",
  },
  favoriteImgs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "45px",
    height: "45px",
    boxShadow:
      "0px 2px 8px 0px #00000014",
    borderRadius: "50%",
    position: "absolute",
    top: "29px",
    right: "29px",
    backgroundColor: "#fff",
  },
  favoriteImgsSearch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "45px",
    height: "45px",
    boxShadow:
      "0px 2px 8px 0px #00000014",
    borderRadius: "50%",
    position: "absolute",
    top: "11px",
    right: "7px",
    backgroundColor: "#fff",
  },
  viewAllText: {
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 600,
    color: "#334155",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  recommndText: {
    fontFamily: "Raleway",
    fontSize: "18px",
    fontWeight: 600,
    color: "#0F172A",
  },
  recommandTextGrid: {
    width: "8px",
    backgroundColor: "#13689E",
    borderRadius: "4px",
    height: "100%",
    marginRight: "18px",
  },
  inputSerchGrid: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    width: "90%",
    backgroundColor: "#fff",
  },
  recipeText: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Raleway",
    color: "#1A1D1F",
  },
  imgText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    fontFamily: "Raleway",
    marginLeft: "10px",
  },
  mainContainer: {
    backgroundColor: "#FAFAFA",
    height: "auto",
    minHeight: "100vh",
    width: "100%",
  },
  errorText: {
    color: "#DC2626",
    fontSize: "0.75rem",
    fontFamily: "Inter",
    paddingTop: "3px",
    letterSpacing: "0.4px",
  },
  saveBtn: {
    backgroundColor: "#13689E",
    height: "48px",
    width: "264px",
    color: "#fff",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 700,
    border: "none",
    borderRadius: "50px",
    opacity: 1,
  },
  inputStyle: {
    all: "unset",
    backgroundColor: "transparent",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Raleway",
    marginLeft: "4px",
    width: "90%",
    color: "#000000",
  },
  mainConGrid: {
    display: "flex",
    width: "100%",
    marginLeft: "12px"
  },
  recipyDisc: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Raleway",
    color: "#64748B",
  },
  mainGridCard: {
    boxShadow:
      "0px 2px 8px 0px #00000014",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "50px",
    position: "relative",
    minWidth: "250px",
    maxWidth: "550px",
    backgroundColor: "#ffffff"
  },
  mainGridCardActive: {
    boxShadow: "0px 2px 8px 0px #9ACBEA",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "50px",
    position: "relative",
    minWidth: "250px",
    maxWidth: "550px",
    border: "1px solid #13689E",
    backgroundColor: "#ffffff"
  },
  nameText: {
    fontSize: "22px",
    fontFamily: "Raleway",
    fontWeight: 600,
    color: "#0F172A",
  },
  headerProfilreIcon: {
    width: "45px",
    height: "45px",
    marginRight: "25px",
  },
  hesderProfileText: {
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Raleway",
  },
  hederProfileCOntainer: {
    display: "flex",
    alignItems: "center",
  },
  hederNotificationConatiner: { position: "relative", marginRight: "30px" },
  massageImgContainer: {
    height: "45px",
    width: "45px",
    borderRadius: "12px",
    boxShadow:
      "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  notificationNum: {
    position: "absolute",
    backgroundColor: "#FF4A23",
    padding: "3px",
    borderRadius: "50%",
    top: "14px",
    right: "15px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
    color: "#fff",
    fontFamily: "Poppins",
  },
  pointer: {
    cursor: "pointer"
  }
};
// Customizable Area End