import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Card, Divider, Grid, Input, InputAdornment, Typography, styled ,Dialog,
  DialogActions,
  
  DialogContentText,
  DialogContent,} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { appleLogo, googleLogo, VitaLogo, visibleIcon, unvisibleIcon } from './assets';
import { Formik } from 'formik';
import * as Yup from "yup";
import CustomBG from "../../../components/src/CustomBG.web";
import Loader from "../../../components/src/Loader.web";
export const configJSON = require("./config.js");

const MainContainerbox = styled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "conter",
  justifyItems: "center",
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
  "& .Styled\(MuiBox\)-root-1 .MuiContainer-maxWidthMd-9": {
    display: "flex",
    justifyContent: "center"
  },
  '& .MuiContainer-maxWidthMd-9': {
    display: "flex",
    justifyContent: "center"
  },
  "& .scrollBarLogin": {
    display: "flex",
    padding: "32px",
    gap: "14px",
    alignItems: "center",
    margin: 'auto',
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      display: "none",
    },
    "@media (max-width: 200px)": {
      height: "auto",
      overflow: "auto",
    },
  },
  "& .main-button-container": {
    display: "flex",
    gap: 10,
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  "& .checkboxColor": {
    color: "#13689E",
  },
  "& .textColor": {
    fontFamily: "Raleway",
    color: "#475569",
    marginBottom: 0,
  },
  "& .Styled\(MuiBox\)-root-282 .MuiContainer-maxWidthMd-290": {
    display: "flex",
    justifyContent: "center"
  },
  "& .card-container1": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 24,
    maxWidth: 460,
    background: "#F8FAFC",
    borderRadius: 24,
    padding: 32,
    backgroundColor: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    "@media (max-width: 600px)": {
      height: "auto",
      overflowY: "scroll",
    },
    "@media (max-width:900px)": {
      height: "auto",
      overflowY: "scroll",
    },
  },
  "& .MuiContainer-maxWidthMd": {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  "& .getstarted": {
    color: "rgba(60, 60, 59, 1)",
    fontFamily: "Raleway",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.64px",
  },
  "& .personalizedText": {
    color: "rgb(79, 85, 94)",
    marginTop: "0.5%",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.154px",
  },
  "& .txtInputEmail": {
    display: "flex",
    width: "100%",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "#F1F5F9"
  },
  "& .txtInputEmail .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F1F5F9",
  },
  "& .MuiOutlinedInput-notchedOutline-179": {
    borderColor: "#F1F5F9"
  },
  "& .MuiOutlinedInput-notchedOutline-452": {
    borderColor: "#F1F5F9"
  },

  "& .txtInputEmail .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F1F5F9",
  },
  '& .EnableLigin': {
    borderRadius:8,
    display: "flex",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    border: "8px",
    background: "#13689E",
    textTransform: 'capitalize',
    fontWeight: 700,
    lineHeight: "20px",
    color: "rgba(255, 255, 255, 1)",
    marginTop: "10px",
    '& span' :{
      fontFamily: "Raleway",
      fontSize: '16px',
    }
  },
  '& .disabled': {
    borderRadius:8,
    display: "flex",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    border: "8px",
    background: "#13689E",
    opacity: 0.4,
    textTransform: 'capitalize',
    fontWeight: 700,
    lineHeight: "24px",
    color: "rgba(255, 255, 255, 1)",
    marginTop: "10px",
    '& span' :{
      fontFamily: "Raleway",
      fontSize: '16px',
    }
  },
  "& .blurColorText": {
    cursor: 'pointer',
    color: "#13689E",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "-22px",
  },
  "& .blackColorText": {
    lineHeight: '22px',
    textAlign: "center",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 400,
    color: "#151515",
    padding: '3px',
  },
  "& .dividerdiv": {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  '& .orText': {
    marginBottom: "0",
    color: "#64748B",
    textAlign: "center",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  '& .VitaImgLOgo': {
    margin: "20px 0",
  },
  '& .LogosBox': {
    display: "flex",
    justifyContent: "center",
    alignItems: "flexStart",
    gap: "24px",
    alignSelf: "stretch"
  },
  '& .baseIcons': {
    width: "24px",
    height: "24px",
  },
  '& .loginWithGoogle': {
    borderRadius: "50%",
    boxSizing: "border-box",
    backgroundColor: 'rgb(236, 245, 250)',
    width: '56px',
    height: '56px',
    textAlign: 'center',
    padding: "16px",
    gap: "12px",
  },
  '& .forgetPasstxt': {
    cursor: 'pointer',
    color: "#151515",
    fontFamily: "Raleway",
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
    fontStyle: "normal",
    "@media (max-width: 600px)": {
      paddingTop: "10px",
    },
  },
  '& .error-message': {
    color: "#DC2626",
    fontFamily: "Raleway",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .inputboxLoginBox": {
    height: "20px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "5px",
    margin: "4px 0",
    width: "100%",
    color: "#1E293B",
    backgroundColor: "#F8FAFC",
    border: "1px solid #E2E8F0",
    background: "#2B2B2B",
    padding: '25px 8px',
    fontFamily: "Raleway",
    fontStyle: "normal",
    '&.inputboxLoginBox:hover': {
      border: "1px solid black",
    },
    '& ::placeholder':{
      color:"#64748B",
      opacity:1
    }
  },
  "& .errorBox": {
    display: "flex",
    width: "100%",
    alignSelf: "stretch",
    borderRadius: "5px",
    border: "1px solid #DC2626",
    height: "20px",
    fontWeight: 500,
    fontSize: "16px",
    margin: "4px 0",
    color: "#1E293B",
    backgroundColor: "#F8FAFC",
    background: "#2B2B2B",
    padding: '25px 14px',
    '&.errorBox:hover': {
      border: "1px solid red !important",
    },
  },
  '& .PrivateNotchedOutline-root-208': {
    borderStyle: "none"
  },
  "& .MuiInputBase-root-193": {
    borderWidth: "1px",
    border: "1px solid #F1F5F9",
    background: "#F8FAFC"
  },
  "& .MuiContainer-maxWidthMd-290": {
    display: "flex",
    justifyContent: "red"
  },
  "& .MuiInputBase-input-468": {

    background: "none"

  },
  "& .appleLoginButton":{ 
    background: 'transparent', 
    border: 'none', 
    minWidth: '0', 
    padding: '0',
    cursor:"pointer" 
  }
});

const DialogContainer = styled(DialogContent)({
  padding: "16px 32px",
  textAlign: "center",
  '& .title': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '-0.12px',
    textAlign: 'center',
    lineHeight: '32px',
  },
  '& .metaTitle': {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'center',
  },
  '& .btnContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .textCode': {
    "@media (max-width: 400px)": {
      display: "grid",
    },
  }
});

const styles ={ 
  dialogMainFull: {
    maxWidth: "497px",
    height: 'auto',
    borderRadius: 24,
    padding: "16px 32px",
    background: "#fff"
  },
  dailogButton:{ display: "flex", justifyContent: "center" },
  dailogCloseButton:{
    color: "#007AFF",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none" as "none"
  }

}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 handleSubmit = (values: any) => {
};
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       <>
       {this.state.isLoading && <Loader/>}
      <ThemeProvider theme={theme}>
        <CustomBG>
          <MainContainerbox>
            <Grid container justifyContent="center">
              <Box className="scrollBarLogin">
                <Card elevation={0} className="card-container1">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <img
                        src={VitaLogo}
                        alt="Your Image"
                        className="VitaImgLOgo"
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <Typography className="getstarted">Get Started</Typography>
                      <Typography className="personalizedText">Log in for your personalized health journey.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Formik
                        data-test-id="Formik"
                        initialValues={{ email: '', conf_pass: '', vis1: false, vis2: false }}
                        onSubmit={(values, actions) => {
                          this.handleSubmit(values);
                        }}
                        validateOnChange={true} // Validate form on change
                        validateOnBlur={true}   // Validate form on blur
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email('Please enter a valid email address.').required('Please enter email.'),
                          conf_pass: Yup.string()
                            .required('Please enter password.')
                            .min(8, 'Password enter valid password'),
                        })}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <Grid container spacing={2} >
                              <Grid item xs={12}>
                                <Input
                                  placeholder="Email"
                                  name="email"
                                  value={props.values.email}
                                  onChange={(e) => {
                                    this.setState({ emailError: '' })
                                    props.handleChange(e)
                                  }}
                                  onBlur={props.handleBlur}
                                  className={!!props.errors?.email ? 'inputboxLoginBox errorBox' : 'inputboxLoginBox'}
                                  disableUnderline
                                  error={!!props.errors?.email && props.touched.email}
                                  type="email"
                                  data-test-id="emailInput"
                                />

                                <Box>
                                  <Typography variant="caption" color="error" className='error-message' >
                                    {props.errors?.email  }
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  placeholder="Password"
                                  name="conf_pass"
                                  value={props.values.conf_pass}
                                  onChange={(e) => {
                                    this.setState({ emailError: '' })
                                    props.handleChange(e)
                                  }}
                                  onBlur={props.handleBlur} // Trigger onBlur event for validation
                                  className={!!props.errors?.conf_pass ? 'inputboxLoginBox errorBox' : 'inputboxLoginBox'}
                                  error={!!props.errors?.conf_pass && props.touched.conf_pass} // Check if password field is touched
                                  disableUnderline
                                  data-test-id="password"
                                  type={props.values.vis2 ? "text" : "password"}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      style={{ cursor: "pointer", color: 'rgba(100, 116, 139, 1)' }}
                                      onClick={() => props.setFieldValue("vis2", !props.values.vis2)}
                                      data-test-id="togglePassword"
                                    >
                                      {props.values.vis2 ? (
                                        // <Visibility />
                                        <img src={visibleIcon}/>
                                      ) : (
                                        <img src={unvisibleIcon}/>
                                      )}
                                    </InputAdornment>}
                                />
                                <Box >
                                  <Typography variant="caption" color="error" className='error-message'>
                                    {props.errors?.conf_pass}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} style={{ textAlign: 'right', display: "flex", justifyContent: "flex-end", padding: '1px 8px' }}>
                                <Typography variant="subtitle1" className="forgetPasstxt" data-test-id='forgotepass' onClick={() => this.props.navigation.navigate('ResetPasswordMFA')}>
                                  Forgot your password?
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  fullWidth
                                  data-test-id='submit-btn'
                                  onClick={() => this.handleUserLogin(props)}
                                  className={`${props.isValid && props.values.email ? 'EnableLigin' : 'disabled'}`}
                                  disabled={!props.values.conf_pass && !props.values.email} // Disable the button if the form is not valid
                                >
                                  Login
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Typography
                        data-test-id={"btnForgotPassword"}
                        variant="subtitle1"
                        className="blackColorText"
                      >
                        Don't have an account or invitation code? <span className="blurColorText" data-test-id='signUpBttn' onClick={() => this.props.navigation.navigate('EmailRegistrationWithoutInvCode')}>Signup</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="dividerdiv">
                      <Divider style={{ width: "40%" }} />
                      <Box className="orText">or</Box>
                      <Divider style={{ width: "40%" }} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="LogosBox"
                    >
                      <div
                        className="loginWithGoogle"
                      >
                        <Button
                          style={{ background: 'transparent', border: 'none', minWidth: '0', padding: '0' }}
                          data-test-id="googleLoginId"
                          onClick={() => { this.handleGoogleLogin() }}
                        >
                          <img
                            src={googleLogo}
                            alt="abx"
                            className="baseIcons"
                          />
                        </Button>
                      </div>
                      <div
                        className="loginWithGoogle"
                      >
                          <button onClick={() => this.handleAppleLogin()} data-test-id="appleLoginId" className="appleLoginButton">
                              <img src={appleLogo} alt="abx" className="baseIcons"/>
                            </button>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
              <Dialog
                keepMounted
                open={!!this.state.emailError}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                  style: styles.dialogMainFull,
                }}
              >
                <DialogContainer>
                  <DialogContentText id="alert-dialog-slide-description" className="title" >
                     {this.state.emailError === "Login Failed" ? 'Incorrect Password' : "Incorrect Email"}
                  </DialogContentText>
                  <Typography className="metaTitle">
                  {this.state.emailError === "Login Failed" ? 'The password you entered is incorrect. Please try again or reset your password.' : "Account not found, or not activated."}
                  </Typography>
                </DialogContainer>
                <Divider />
                <DialogActions style={styles.dailogButton}>
                  <Button
                    onClick={() => this.handleClose()}
                    data-test-id="handleClose"
                    style={styles.dailogCloseButton}>Close</Button>
                </DialogActions>
              </Dialog>
          </MainContainerbox>
        </CustomBG>
      </ThemeProvider>
      </>
       // Customizable Area End
    );
  }
}
