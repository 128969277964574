export const alarm = require("../assets/alarm.svg");
export const note = require("../assets/note.svg");
export const notification = require("../assets/image_notifications.svg");
export const closeButton = require("../assets/closeButton.png");
export const selectArrow = require("../assets/selectArrow.png");
export const reminder = require("../assets/reminder.png");
export const checkRedioImg = require("../assets/checkRedio.svg");
export const unCheckRedioImg = require("../assets/unCheckRedio.svg");
export const preferenceIcon = require("../assets/preferenceIcon.svg");
export const tooltip = require("../assets/tooltip.svg");
export const notificationSmall = require("../assets/notification.svg");
export const selectImage = require("../assets/select_image.svg");
