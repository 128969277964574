import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, createRef } from "react";
import moment from "moment";
import StorageProvider from "framework/src/StorageProvider";
import * as Yup from "yup";
import { Page } from "react-pdf";
import { downloadFile } from "../../../components/src/downloadFile";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

export interface FileData {
  id: string;
  type: string;
  attributes: {
    id: number;
    file: string;
  },
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}

export interface TyrosineReport {
  id: string,
  type: string,
  attributes: {
    id: number,
    report_name: string,
    report_date: string,
    report_type: string,
    phenylalanine_level: string,
    tyrosine_level: string,
    account_id: number,
    report_notes: null,
    created_at: string
    updated_at: string
    attached_files: {
      data: Array<FileData>;
    }
  }
}

export interface PhenylalanineReport {
  id: string,
  type: string,
  attributes: {
    id: number,
    report_name: string,
    report_date: string,
    report_type: string,
    phenylalanine_level: string,
    tyrosine_level: string,
    account_id: number,
    report_notes: null,
    created_at: string
    updated_at: string
    attached_files: {
      data: Array<FileData>;
    }
  }
}

export interface ReportFormValue {
  report_name: string,
  testDate: Date | null,
  level: string | undefined,
  report_notes: string | null;

}

export interface GeneticReport {
  id: string,
  type: string,
  attributes: {
    id: number,
    report_name: string,
    report_date: string,
    report_type: string,
    phenylalanine_level: string,
    tyrosine_level: string,
    account_id: number,
    report_notes: null,
    created_at: string
    updated_at: string
    attached_files: {
      data: Array<FileData>;
    }
  }
}

interface UserProfile {
  data: {
    id: string,
    type: string,
    attributes: {
      first_name: string,
      last_name: string,
      full_name: null,
      full_phone_number: string,
      country_code: null,
      phone_number: null,
      email: string,
      activated: true,
      invitation_code: null,
      user_type: string,
      invitation_code_status: string,
      profile: {}
    }
  }
}
// Customizable Area End

interface S {
  // Customizable Area Start
  activeScreenName: string;
  screenNameArray: Array<string>;
  isReportOpen: boolean;
  cardModalOpen: number;
  date: Date;
  pheReport: Array<PhenylalanineReport>;
  tyrReport: Array<TyrosineReport>;
  geneticReport: Array<GeneticReport>;
  presentDate: string;
  showDeleteModal: boolean;
  deleteReportId: string;
  showAddReportModal: boolean;
  editReportId: string;
  selectedFile: Array<{ file: File, fileId: string }>;
  pdfselectopen: boolean;
  fileExceedModal: boolean;
  anchorEl: null | HTMLElement;
  showViewReportModal: boolean;
  selectedReport: PhenylalanineReport & TyrosineReport & GeneticReport;
  isEditReportForm: boolean;
  fileSelectionError: boolean;
  firstName: string;
  lastName: string;
  openTroubleDialog: boolean;
  successMsg: boolean;
  removedFileIds: Array<string>;
  loading: boolean;
  file: string | null;
  fileName: string;
  reportOpener: boolean;
  selectedReportOpen: string;
  numPages: number | null;
  isLoading: boolean;
  isEditLoading: boolean;
  onBoarding1: any;
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  openOneScreenOnboarding: boolean;
  onBoardingBtnDisable: boolean;
  onboardingRefs: (RefObject<HTMLTableCellElement> | RefObject<HTMLButtonElement>)[];
  report_name: string;
  ipAdress: string;
  loginUserType: string | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTestReportApiCallId: string = "";
  deleteReportApiCallId: string = "";
  addReportApiCallId: string = "";
  updateReportApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  getActivityApiCallId: string = "";
  getIpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isReportOpen: false,
      cardModalOpen: -1,
      activeScreenName: "Phenylalanine (Phe) Blood Test Report",
      screenNameArray: ["Phenylalanine (Phe) Blood Test Report", "Tyrosine (Tyr) Blood Test Report", "Genetic Testing Report"],
      date: new Date(),
      pheReport: [],
      tyrReport: [],
      geneticReport: [],
      presentDate: moment(new Date()).format("DD MMM, YYYY"),
      showDeleteModal: false,
      deleteReportId: "",
      showAddReportModal: false,
      editReportId: "",
      selectedFile: [],
      pdfselectopen: false,
      fileExceedModal: false,
      anchorEl: null,
      showViewReportModal: false,
      selectedReport: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          report_name: "",
          report_date: "",
          report_type: "",
          phenylalanine_level: "",
          tyrosine_level: "",
          account_id: 0,
          report_notes: null,
          created_at: "",
          updated_at: "",
          attached_files: {
            data: []
          }
        }
      },
      isEditReportForm: false,
      fileSelectionError: false,
      firstName: "",
      lastName: "",
      openTroubleDialog: false,
      successMsg: false,
      removedFileIds: [],
      loading: false,
      file: null,
      fileName: "",
      reportOpener: false,
      selectedReportOpen: "",
      numPages: 1,
      isLoading: true,
      isEditLoading: false,
      onboardingRefs: [createRef<HTMLTableCellElement>(), createRef<HTMLTableCellElement>(), createRef<HTMLButtonElement>(), createRef<HTMLButtonElement>()],
      onBoarding1: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "View all your phenylalanine reports here. Tap the icon to view or delete reports.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "View all your Tyrosine reports here.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "View all your Genetic reports here.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Tap here to add reports.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      }],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "left",
      transformHorizontal: "left",
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      report_name: "",
      ipAdress: "",
      loginUserType: localStorage.getItem("user_type")
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isInValidResponse(responseJson)) {
        this.responseFailureCallBack(apiRequestCallId);
      }
      else if (this.isValidResponse(responseJson)) {
        this.responseSucessCallBack(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId === this.getIpApiCallId) {
        this.setState({ipAdress: responseJson.ip})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleReportbookDatePicker(new Date());
    this.getTestReport()
    this.getUserProfile()
    this.getIpAddress();

    let onboarding = await getStorageData("onBoarding");
    let authToken = await getStorageData("authToken");
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
    if (onboarding === null) {
      setTimeout(() => {
        this.setState({ openOnboarding: true });
        this.nextOnBoarding();
      }, 500)
    }
    
    if (!this.state.loginUserType) {
      this.redirectToLogin()
    }
  }

   /* istanbul ignore next */
  nextOnBoarding = () => {
    const { onBoarding1, currentStep } = this.state;
    if (onBoarding1.length > currentStep) {
          if (currentStep === 0) {
        this.changeStep2();
      }
      if (currentStep === 1) {
          this.changeStep2();
      }
      if (currentStep === 2) {
        this.changeStep2();
      }
      if (currentStep === 3) {
         this.changeStep2();
        this.setState({ onBoardingBtnDisable: true });
      }
    }
    else {
      if (this.state.openOneScreenOnboarding) {
        this.props.navigation.navigate('Analytics');
      }
      this.resetDataFunction();
    }
  }


  resetDataFunction = () => {
    this.setState({
      openOnboarding: false,
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
    });
  }


  handleOpneOnboarding = () => {
    this.setState({
      openOnboarding: true,
      openOneScreenOnboarding: false,
      currentStep: 0,
      activeScreenName: "Phenylalanine (Phe) Blood Test Report",
    }, () => this.nextOnBoarding())
  }

  changeStep2 = () => {
    const {  currentStep, onBoarding1 } = this.state;
    this.setState({
      onBoardingTitle: onBoarding1[currentStep].onboarding_title,
      onBoardingText: onBoarding1[currentStep].onboarding_description,
      transformHorizontal: onBoarding1[currentStep].transformHorizontal,
      anchorHorizontal: onBoarding1[currentStep].anchorHorizontal,
      currentStep: currentStep + 1
    })
    const targetRef = this.state.onboardingRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }

  skipOnBoarding = async () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    this.resetDataFunction();
    await setStorageData("onBoarding", "true");
  }


  getReportName = () => {
    return this.state.isEditReportForm ? this.state.selectedReport?.attributes?.report_name : "";
  }

  getReportDate = () => {
    return this.state.isEditReportForm ? this.getDateInFormat(this.state.selectedReport?.attributes?.report_date) : null;
  }

  getReportLevelValue = () => {
    return this.state.isEditReportForm ? this.getLevelValue() : "";
  }

  getReportNote = () => {
    return this.state.isEditReportForm ? this.state.selectedReport?.attributes?.report_notes : "";
  }

  getReportFile = () => {
    return this.state.isEditReportForm ? this.state.selectedFile : null;
  }

  isValidResponse = (responseJson: { [key: string]: string }) => {
    return responseJson && !responseJson.error;
  };

  isInValidResponse = (responseJson: { [key: string]: string }) => {
    return responseJson && responseJson.errors;
  };

  responseSucessCallBack = (apiRequestCallId: string, responseJson: { data: Array<PhenylalanineReport> } & { data: Array<TyrosineReport> } & { data: Array<GeneticReport> } & UserProfile) => {
    if (apiRequestCallId === this.getTestReportApiCallId) {
      this.handleGetReportSuccess(responseJson.data)
    }

    if (apiRequestCallId === this.deleteReportApiCallId) {
      this.setState({ showDeleteModal: false, anchorEl: null }, () => {
        this.getTestReport()
      })
    }

    if (apiRequestCallId === this.addReportApiCallId) {
      this.setState({
        showAddReportModal: false,
        selectedFile: [], successMsg: true, loading: false
      }, () => {
        this.callApiLogActivity("create", true);
        this.getTestReport()
      })
    }

    if (apiRequestCallId === this.updateReportApiCallId) {
      this.setState({ isEditReportForm: false, anchorEl: null, selectedFile: [], successMsg: true, removedFileIds: [] }, () => {
        this.callApiLogActivity("update", true);
        this.getTestReport();
      })
    }

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      this.setState({
        firstName: responseJson.data.attributes.first_name,
        lastName: responseJson.data.attributes.last_name
      })
    }
  }

  responseFailureCallBack = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.getTestReportApiCallId) {
      this.handleGetReportFailure()
    }
  }

  getModalLevelPlaceholder = () => {
    if (this.renderFirstCardCondition()) {
      return configJSON.pheLevelsText
    }
    else if (this.renderSecondCardCondition()) {
      return configJSON.enterYourTyrLevelText
    }
  }

  getLevelErrorMessage = () => {
    return this.state.activeScreenName === configJSON.pheReportText ? configJSON.pheLevelMessage : configJSON.tryLevelMessage
  }

  getViewReportLevel = () => {
    if (this.renderFirstCardCondition()) {
      return this.state.selectedReport?.attributes?.phenylalanine_level
    }
    else if (this.renderSecondCardCondition()) {
      return this.state.selectedReport?.attributes?.tyrosine_level
    }
  }

  getLevelValue = () => {
    let level = this.state.selectedReport?.attributes?.phenylalanine_level || this.state.selectedReport?.attributes?.tyrosine_level
    if (level && /\d+(\.\d+)?/.test(level)) {
      const numericPart = parseFloat(level.match(/\d+(\.\d+)?/)![0]);
      return numericPart.toString();
    }
  }

  handleIsEditReportFormOpen = async () => {
    this.setState({ isEditLoading: true })
    let files: Array<{ file: File, fileId: string }> = [];
    for (const attachedFile of this.state.selectedReport?.attributes?.attached_files?.data) {
      const response = await fetch(attachedFile.attributes.file);
      const blob = await response.blob();
      const file = new File([blob], this.getFileName(attachedFile.attributes.file), { type: blob.type });
      files.push({ file: file, fileId: attachedFile.id });
    }
    this.setState({ selectedFile: files, isEditReportForm: true, showViewReportModal: false, isEditLoading: false })
  }

  getTestReport = async () => {
    this.getTestReportApiCallId = await this.apiCall({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.getUserListApiMethod,
      endPoint: `${configJSON.userReportApiEndPoint}?date=${moment(this.state.presentDate).format("DD/MM/YYYY")}&report_type=${this.state.activeScreenName.substring(0, this.state.activeScreenName.indexOf(' ')).trim()}`
    })
  }

  getUserProfile = async () => {
    this.getUserProfileApiCallId = await this.apiCall({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.getUserListApiMethod,
      endPoint: `${configJSON.userProfileApiEndPoint}`
    });
  }

  apiCall = async (data: APIPayloadType) => {
    const { method, endPoint, body, type = "" } = data;
    const token = await StorageProvider.get("authToken")
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleReportbookDatePicker = (valueDate: Date) => {
    let formatedDate = moment(valueDate).format("DD MMM, YYYY");
    this.setState({ presentDate: formatedDate }, () => {
      this.getTestReport()
    })
  };

  handleDotClick = (event: React.MouseEvent<HTMLElement>, item: TyrosineReport & PhenylalanineReport & GeneticReport) => {
    this.setState({ anchorEl: event.currentTarget, selectedReport: item });
  };

  handleDeleteModalOpen = (reportId: string) => {
    this.setState({ showDeleteModal: true, deleteReportId: reportId })
  }

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false, deleteReportId: "" })
  }

  handleViewReportModalOpen = () => {
    this.setState({ showViewReportModal: true })
  }

  handleViewReportModalClose = () => {
    this.setState({ showViewReportModal: false })
  }

  handleDeleteReport = async () => {
    this.deleteReportApiCallId = await this.apiCall({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.userReportApiEndPoint}/${this.state.deleteReportId}`
    })
  }

  handleGetReportSuccess = async (responseJson: Array<PhenylalanineReport> & Array<TyrosineReport> & Array<GeneticReport>) => {
    const updatedResponse = responseJson.map(item => {
      const reportDate = moment(new Date(item.attributes.report_date.split('T')[0])).format("DD/MM/YYYY");
      return { ...item, attributes: { ...item.attributes, report_date: reportDate } };
    });

    switch (this.state.activeScreenName) {
      case configJSON.pheReportText:
        this.setState({ pheReport: updatedResponse });
        break;
      case configJSON.tyrReportText:
        this.setState({ tyrReport: updatedResponse });
        break;
      case configJSON.geneticTestText:
        this.setState({ geneticReport: updatedResponse });
        break;
      default:
        break;
    }
  }

  handleGetReportFailure = () => {
    switch (this.state.activeScreenName) {
      case configJSON.pheReportText:
        this.setState({ pheReport: [] })
        break;
      case configJSON.tyrReportText:
        this.setState({ tyrReport: [] })
        break;
      case configJSON.geneticTestText:
        this.setState({ geneticReport: [] })
        break;
      default:
        break
    }
  }

  checkFileType = (fileName: string) => {
    return fileName.includes("pdf");
  }

  handleActiveButton = (screenName: string) => {
    this.setState({ activeScreenName: screenName, cardModalOpen: -1 }, () => {
      this.getTestReport();
    });
  }

  handleAddReportModalOpen = () => {
    this.setState({ showAddReportModal: true })
  }

  handleAddReportModalClose = () => {
    this.setState({ showAddReportModal: false, selectedFile: [], isEditReportForm: false })
  }

  renderCardCondition = (tabName: string) => {
    return this.state.activeScreenName === tabName
  }

  renderFirstCardCondition = () => {
    return this.state.activeScreenName === configJSON.pheReportText
  }

  renderSecondCardCondition = () => {
    return this.state.activeScreenName === configJSON.tyrReportText

  }

  renderThirdCardCondition = () => {
    return this.state.activeScreenName === configJSON.geneticTestText
  }
  reportSchema = () => {
    const { activeScreenName } = this.state;
    return Yup.object().shape({
      report_name: Yup.string().required(configJSON.reportNameMessage),
      testDate: Yup.date().nullable().required(configJSON.dateMessage),
      level: activeScreenName === configJSON.pheReportText || activeScreenName === configJSON.tyrReportText
        ? Yup.string().required(this.getLevelErrorMessage())
        : Yup.string(),
      file: Yup.mixed().nullable().required(configJSON.fileError)
    })
  }

  getBorderstyle = (touched?: boolean | undefined, errors?: string) => {
    return touched && errors ? "1px solid red" : ""
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    this.handleFileSelection(droppedFiles);
  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  getDateInFormat = (dateString: string) => {
    const parts = dateString.split('/');
    const daySelected = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, daySelected);
  }

  openPictureUploadInSide = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const selectedFiles = event.target.files;
    this.handleFileSelection(selectedFiles);
  };

  handleFileSelection = (files: FileList) => {
    if (!files.length) return;
    const filesToAdd: Array<{ file: File, fileId: string }> = [];
    Array.from(files).forEach((file: File) => {
      const existing = file.name.split(".").pop()?.toLowerCase();
      if (this.state.selectedFile.length + files.length < 6) {
        if (existing && ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg", "HEIC", "pdf"].includes(existing) && this.state.selectedFile.length < 5) {
          filesToAdd.push({ file, fileId: "" });
        }
        else {
          this.setState({ openTroubleDialog: true })
        }
      }
      else {
        this.setState({ fileExceedModal: true })
      }
    });
    this.setState(prevState => ({
      selectedFile: [...prevState.selectedFile, ...filesToAdd.slice(0, 5 - prevState.selectedFile.length)]
    }));
  }

  handleRemoveMain = (fileIndex: number, setFieldValue: Function) => {
    const newDoument = [...this.state.selectedFile]
    const removeItemId = newDoument[fileIndex].fileId;
    newDoument.splice(fileIndex, 1);
    this.setState({ selectedFile: newDoument, removedFileIds: [...this.state.removedFileIds, removeItemId] }, () => {
      if (this.state.selectedFile.length < 1) {
        setFieldValue("file", null);
      }
    })
  };

  handleExceedModalClose = () => {
    this.setState({ fileExceedModal: false })
  }

  handleFileOpen = (fileIndex: number) => {
    const file = this.state.selectedFile[fileIndex];
  }

  handleAddReports = async (values: ReportFormValue) => {
    const formdata = new FormData();
    this.setState({ loading: true })
    if (!this.state.isEditReportForm) {
      formdata.append("data[report_name]", values.report_name);
      if (this.renderFirstCardCondition()) {
        formdata.append("data[report_type]", "Phenylalanine");
        formdata.append("data[phenylalanine_level]", values.level as string);
      }
      else if (this.renderSecondCardCondition()) {
        formdata.append("data[report_type]", "Tyrosine");
        formdata.append("data[tyrosine_level]", values.level as string);
      }
      else if (this.renderThirdCardCondition()) {
        formdata.append("data[report_type]", "Genetic");
      }
      formdata.append("data[report_date]", moment(values.testDate).format("DD/MM/YYYY"));
      formdata.append("data[report_notes]", values.report_notes as string);
      this.state.selectedFile.forEach((file, fileIndex) => {
        formdata.append(`data[attachments_attributes][${fileIndex}][file]`, file.file, file.file.name);
      });
      this.addReportApiCallId = await this.apiCall({
        contentType: configJSON.getUserListApiContentType,
        method: configJSON.addUserApiMethod,
        endPoint: configJSON.userReportApiEndPoint,
        body: formdata,
        type: "formData"
      })
    }
    else {
      let fileIndex = 0;
      formdata.append("data[attributes][report_name]", values.report_name);
      if (this.renderFirstCardCondition()) {
        formdata.append("data[attributes][report_type]", "Phenylalanine");
        formdata.append("data[attributes][phenylalanine_level]", values.level as string);
      }
      else if (this.renderSecondCardCondition()) {
        formdata.append("data[attributes][report_type]", "Tyrosine");
        formdata.append("data[attributes][tyrosine_level]", values.level as string);
      }
      else if (this.renderThirdCardCondition()) {
        formdata.append("data[attributes][report_type]", "Genetic");
      }
      formdata.append("data[attributes][report_date]", moment(String(values.testDate)).format("DD/MM/YYYY"));
      formdata.append("data[attributes][report_notes]", values.report_notes as string);

      this.fieldSelection(formdata, fileIndex);
      this.updateReportApiCallId = await this.apiCall({
        contentType: configJSON.getUserListApiContentType,
        method: configJSON.editUserApiMethod,
        endPoint: `${configJSON.userReportApiEndPoint}/${this.state.selectedReport.id}`,
        body: formdata,
        type: "formData"
      })
    }
  }

  fieldSelection = (formdata: FormData, fileIndex: number) => {
    this.state.removedFileIds.forEach((fileId: string) => {
      if (fileId !== "") {
        {
          formdata.append(`data[attributes][attachments_attributes][${fileIndex}][id]`, fileId as unknown as Blob)
          formdata.append(`data[attributes][attachments_attributes][${fileIndex}][_destroy]`, true as unknown as Blob);
          fileIndex++;
        }
      }
    })
    this.state.selectedFile.forEach((file) => {
      if (file.fileId === "") {
        formdata.append(`data[attributes][attachments_attributes][${fileIndex}][file]`, file.file, file.file.name);
        fileIndex++;
      }
    });
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  getFileName = (file: string) => {
    let fileData = file.split("/");
    return fileData[fileData.length - 1];
  }

  cancelTroubleDialog = () => {
    this.setState({ openTroubleDialog: false })
  };

  handleSuccesModalClose = () => {
    this.setState({ successMsg: false });
  };

  handleReportOpener = (fileUrl: string, report_name?: string) => {
    this.setState({ reportOpener: true, selectedReportOpen: fileUrl, report_name: report_name as string });
  };

  handleDownload = async () => {
    const fileName = this.getFileName(this.state.selectedReportOpen);
    const response = await downloadFile(this.state.selectedReportOpen);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const reportLink = document.createElement("a");
    reportLink.href = blobUrl;
    if (this.state.selectedReportOpen.endsWith(".pdf")) {
      reportLink.download = fileName ? fileName : "document.pdf";
    } else {
      reportLink.download = fileName ? fileName : "image.png";
    }
    document.body.appendChild(reportLink);
    reportLink.click();
    document.body.removeChild(reportLink);
    window.URL.revokeObjectURL(blobUrl);
  };

  handleDocumentOpener = () => {
    this.setState({ reportOpener: false, report_name: "" });
  };

  getIpAddress = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ipAddressApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchFormDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callApiLogActivity = async (type: string, isSuccess: boolean) => {

    let updateProfile = `activity?action_name=update_report&response=success&message=${this.state.activeScreenName.substring(0, this.state.activeScreenName.indexOf(' ')).trim() +  ' report update ' + (isSuccess ? 'success' : 'failed')}&platform_type=web&ip_address=${this.state.ipAdress}&time=${moment().utc().format('HH:mm')}`
    let viewProfile = `activity?action_name=create_report&response=success&message=${this.state.activeScreenName.substring(0, this.state.activeScreenName.indexOf(' ')).trim() +  ' report create ' + (isSuccess ? 'success' : 'failed')}&platform_type=web&ip_address=${this.state.ipAdress}&time=${moment().utc().format('HH:mm')}`
    const header = {
      "Content-Type": "multipart/form-data",
      token: await StorageProvider.get("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type === 'update' && updateProfile || type === 'create' && viewProfile

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  redirectToLogin = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  }; 

  // Customizable Area End
}
