import React from "react";

// Customizable Area Start
import { Box, Modal,Divider, Checkbox, Button, Popover, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar"
import WebHeader from "../../../components/src/WebHeader.web";
import Chart from 'react-apexcharts';
import Calendar from 'react-calendar';
import { dashBoardImage, dateDrop, prevDateImage, prevNextImage, takenImage, skippedImage, upcomingImage, sortIconimage, sortCheckimage, nosortData,monthNextDate,monthPrevDate } from "./assets";
import 'react-calendar/dist/Calendar.css';
import {  Treatmentdetails} from "./AnalyticsController.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
     <ThemeProvider theme={theme}>
      <WebOnboarding
        title={this.state.onBoardingTitle}
        discripation={this.state.onBoardingText}
        anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
        next={this.nextOnBoarding}
        open={this.state.openOnboarding}
        skip={this.skipOnBoarding}
        anchorhorizontal={this.state.anchorHorizontal}
        transformhorizontal={this.state.transformHorizontal}
        />
        <AnalyticsMainDiv>
        {this.state.chartCalendarLoading && <Loader />}
          <SideBar navigation={this.props.navigation} />
          <AnalyticsHeaderAndBox>
            <AnalyticsHeaderDiv>
            <WebHeader title={"Dashboard"} icon={dashBoardImage} notificationref={this.state.onboardingRefs[0]}/>
            </AnalyticsHeaderDiv>

            <AnalyticsDataDiv>
              <AnalyicHelloMain>
                <AnalyticsHelloDiv style={{ [this.state.wordWrap]: "anywhere" }}><AnalyticsHelloSpan>Hello,</AnalyticsHelloSpan> {this.state.firstName} {this.state.lastName}!</AnalyticsHelloDiv>
                <AnalyticsHelloTittle data-testid="closedchart_modal" onClick={this.handleClosedCalender}>Upload new reports and analyse old reports.</AnalyticsHelloTittle>
              </AnalyicHelloMain>

              <AnalyticsTwoBox>
                <AnalyticsChartBox>
                  <AnalyticsCalenderDiv>
                    <AnalyticSelectDate ref={this.state.onboardingRefs[1]} data-testid="calender_modalopen" onClick={this.handleCalendarClick} >{this.formatDate(this.state.selectedDate)}
                      <img src={dateDrop} />
                    </AnalyticSelectDate>
                    <AnalyticsNextPrevImageDiv>
                      <AnalyticsNextPrevDiv><img style={{ cursor: "pointer" }} data-testid="prev_month" onClick={this.handlePrevDateClick} src={prevDateImage} /></AnalyticsNextPrevDiv>
                      <AnalyticsNextPrevDiv><img style={{ cursor: "pointer" }}  data-testid="next_month" onClick={this.handleNextDateClick} src={prevNextImage} /></AnalyticsNextPrevDiv>
                    </AnalyticsNextPrevImageDiv>
                  </AnalyticsCalenderDiv>

                  <Modal
                    disableEnforceFocus
                    disableAutoFocus
                    open={this.state.isOpen}
                    onClose={this.handleClosedCalender}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <AnalyticsCalenderModel>
                        {this.calendarLoader()}
                      <Calendar
                       data-testid="calendar-component"
                        value={new Date(this.state.selectedDate)}
                        next2Label={null}
                        prev2Label={null}
                        prevLabel={<img src={monthPrevDate} />}
                        nextLabel={<img src={monthNextDate} alt="nextDay"/> }
                        formatMonthYear={(locale, date) => {
                          const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
                          return new Intl.DateTimeFormat(locale, options).format(date);
                        }}
                        onActiveStartDateChange={({ activeStartDate }) => {
                          activeStartDate && this.nextPrevesDate(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
                        }}
                        onChange={(dateChange) => {
                          dateChange && dateChange instanceof Date && this.dateSetDiaplay(dateChange);
                        }}
                        tileContent={
                          ({ date }) => this.setCalenderChart(date)
                        }
                      />
                    </AnalyticsCalenderModel>

                  </Modal>
                  <AnalytcChartDiv>
                    <AnalytcChartInnerDiv>
                      <AnalytcChartInnerOneDiv>
                        <Chart data-testid="chart-component" options={this.state.options} series={this.state.TreatmentFormula} type="radialBar" height={265} />
                      </AnalytcChartInnerOneDiv>
                      <AnalytcCircleDiv>
                        <AnalytcLegendsDiv>Legends</AnalytcLegendsDiv>
                        <AnalytcCircleInnerDiv>
                          <AnalytcChartTextDiv>
                            <AnalytcAllCircleDiv>
                              <AnalytcCircleRedDiv></AnalytcCircleRedDiv>
                            </AnalytcAllCircleDiv>
                            <AnalytcFormulaTretment>Treatment</AnalytcFormulaTretment>
                          </AnalytcChartTextDiv>

                          <AnalytcChartTextDiv>
                            <AnalytcAllCircleDiv>
                              <AnalytcCircleBlueDiv></AnalytcCircleBlueDiv>
                            </AnalytcAllCircleDiv>
                            <AnalytcFormulaTretment>Formula</AnalytcFormulaTretment>
                          </AnalytcChartTextDiv>
                        </AnalytcCircleInnerDiv>
                      </AnalytcCircleDiv>
                    </AnalytcChartInnerDiv>

                  </AnalytcChartDiv>

                </AnalyticsChartBox>

                <AnalyticsSortDataMain>
                  <AnalytcSortData>
                  <TretmentLog>Treatment Log</TretmentLog>
                    <div>
                        <div  ref={this.state.onboardingRefs[2]}>
                          <SortButton
                            disabled={this.state.sortDataAnalytic?.length === undefined}
                            className={this.state.sortDataAnalytic?.length === undefined ? "disable_button" : ""}
                            data-testid="sort_data"
                            aria-controls={Boolean(this.state.anchorEl) ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                            onClick={this.handleClick}
                            endIcon={<img src={sortIconimage} />}
                          >
                            Sort
                          </SortButton>
                          {this.state.sortDataAnalytic && this.state.sortDataAnalytic.length > 0 && (
                            <StyledList
                              data-testid="menu_selectonclosed"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleClose}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              PaperProps={{
                                className:"sortDataScrollDiv",
                                style: {
                                  maxHeight: '173px', width: '155px', backgroundColor: "#F8FAFC"
                                },
                              }}
                            >
                              <ListItem data-testid="uncheked_all" onClick={this.handleSelectAllClick}>                               
                                <ListItemText primaryTypographyProps={{
                                  style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#13689E",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "Raleway",
                                    boxShadow: "none",
                                    cursor:"pointer",
                                 
                                    backgroundColor: "#F8FAFC",

                                  }
                                }} primary="All" />
                              </ListItem>                           
                              {!!this.state.sortDataAnalytic?.length && <Divider style={{ width: "76%", margin: "0 auto", backgroundColor: "#E2E8F0" }} />}

                              {this.state.sortDataAnalytic?.map((menuitem: { treatment_name: string }, indexnum: number) => {
                                return (
                                  <>
                                      <ListItem onClick={this.handleMenuItemClick(indexnum)} style={{ padding: "0 40px 0 10px" }} data-testid="chekedchek_box">
                                        <ListItemIcon style={{minWidth:30}}>
                                          <CustomNewCheckbox
                                            icon={<CustomCheckboxIcon />}
                                            checkedIcon={<CustomCheckedIcon />}
                                            color="default"
                                            checked={this.state.selectedItems.includes(indexnum)}
                                            classes={{
                                              root: `
                                          & .MuiSvgIcon-root {
                                            strokeWidth: 1;
                                            fontSize: 30;
                                            borderRadius: 60;
                                          }
                                          '&:hover': {
                                            backgroundColor: 'transparent',
                                          },
                                          border:"none"
                                          borderRadius: 20px !important;
                                          color: gray;
                                          &.Mui-checked {
                                            color: #0E6C9D;
                                          }
                                          ` }}
                                          />

                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{
                                          style: {
                                            textAlign:"start",
                                            fontFamily: "Raleway",
                                            fontSize: "14px",
                                            fontWeight: this.state.selectedItems.includes(indexnum) ? 600 : 500,
                                            color: "#13689E",
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            padding: "0 15px 0 0",
                                            textOverflow: 'ellipsis',
                                            width: "80px",
                                          }
                                        }} primary={menuitem?.treatment_name} />
                                      </ListItem>
                                 {this.state.sortDataAnalytic?.length && indexnum !== this.state.sortDataAnalytic.length - 1 && (
                                      <Divider style={{ width: "76%", margin: "0 auto", backgroundColor: "#E2E8F0" }} />

                                    )}
                                  </>
                                );
                              })}
                            </StyledList>)}

                      </div>
                    </div>
                  </AnalytcSortData>
                  <TretmentLegendsDiv>
                      <AnalytcLegendsDiv>{configJSON.Legends}</AnalytcLegendsDiv>
                      <AnalyticstakeSkippedDiv>
                        <AnalytcSortTakenText>
                          <Box>
                            <img src={takenImage} />
                          </Box>
                          <Box>
                            {configJSON.Taken}
                          </Box>
                        </AnalytcSortTakenText>
                        <AnalytcSortSkipText>
                          <Box>
                            <img src={skippedImage} />
                          </Box>
                          <Box>
                            {configJSON.Skipped}
                          </Box>
                        </AnalytcSortSkipText>
                        <AnalytcSortUpcomingText>
                          <Box>
                            <img src={upcomingImage} />
                          </Box>
                          <Box>
                            {configJSON.Upcoming}
                          </Box>
                        </AnalytcSortUpcomingText>
                      </AnalyticstakeSkippedDiv>
                    </TretmentLegendsDiv>

                  <AnalytcSortDiv  className="sortDataScrollDiv">
                    <AnalytcSortHeaderDiv>
                      <AnalytcSortHeaderText>{configJSON.TreatmentName}</AnalytcSortHeaderText>
                      <AnalytcSortHeaderText>{configJSON.Dates}</AnalytcSortHeaderText>
                      <AnalytcSortHeaderText>{configJSON.ReminderTimes}</AnalytcSortHeaderText>
                      <AnalytcSortHeaderText>{configJSON.Status}</AnalytcSortHeaderText>
                    </AnalytcSortHeaderDiv>

                      {this.state.sortDataAnalytic ?
                        <div>
                          {this.state.sortDataAnalytic?.map((sortdata: Treatmentdetails, indexno: number) => (
                            <>
                              <AnalytcSortTableDiv key={indexno} style={{
                                backgroundColor: this.getMenuItemClassName(indexno) === "selectedMenuItem" ? "#EBF7FF" : "transparent"
                              }}>
                                <div style={{ minWidth: "80px", textAlign: "center", [this.state.wordWrap]: "anywhere", cursor: "context-menu" }}>{sortdata?.treatment_name}</div>
                                <div style={{ minWidth: "80px", textAlign: "center", cursor: "context-menu" }}>{this.sortTableDate(sortdata?.treatment_date)}</div>

                                <div style={{ display: "flex", flexDirection: "column", gap: "12px", minWidth: "80px", alignItems: "center", cursor: "context-menu" }}>
                                  {sortdata?.reminders?.map((sorttime: {
                                    time: string;
                                    status: "upcoming" | "taken" | "missed" | "unread";
                                  }) => (
                                    <div>{sorttime?.time}</div>
                                  ))}
                                </div>

                                <div style={{ minWidth: "80px", display: "flex", alignItems: "center", textAlign: "center", flexDirection: "column", gap: "6px", cursor: "context-menu" }}>
                                  {sortdata?.reminders?.map((status: {
                                    time: string;
                                    status: "upcoming" | "taken" | "missed" | "unread";
                                  }) => (
                                    <div style={{ display: "flex", gap: "8px", textAlign: "center" }}>
                                      <img src={this.getStatusImage(status?.status)} />
                                      <div>{status?.time}</div>
                                    </div>
                                  ))}

                                </div>
                              </AnalytcSortTableDiv>
                            </>
                          ))}

                        </div>
                        :
                        <NodataSort>
                          <NodataSortImagediv><img src={nosortData} /></NodataSortImagediv>
                          <NodataSortImageText>
                            Your Treatment Log is empty. Start by adding Treatment Reminders to see your data here.
                          </NodataSortImageText>
                        </NodataSort>}
                  </AnalytcSortDiv>


                </AnalyticsSortDataMain>

              </AnalyticsTwoBox>
            </AnalyticsDataDiv>

          </AnalyticsHeaderAndBox>
        </AnalyticsMainDiv>

      </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500
    },
   
  },
  overrides: {
    MuiCheckbox: {
      root: {
        width: 26,
        height: 26,
        borderRadius: 8,
        backgroundColor: '#F5F8FA',
        backgroundImage: 'linear-gradient(180deg, hsla(0, 0%, 100%, .8), hsla(0, 0%, 100%, 0))',
        boxShadow: '0px 2px 8px 0px #00000014',
        border: '1px solid #F1F5F9',
        '& .MuiSvgIcon-root': {
          strokeWidth: 1,
          fontSize: 30,
          borderRadius: 60,
        },
        '&.Mui-checked': {
          color: '#0E6C9D',
        },
        '&.Mui-focusVisible': {
          outline: '2px auto rgba(19, 124, 189, .6)',
          outlineOffset: 2,
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
          background: 'rgba(206, 217, 224, .5)',
        },
      },
    },
  },

});

const AnalyticSelectDate = styled('div')({
  border: "1px solid #E2E8F0",
  width: "117px",
  height: "32px",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  background: "#F1F5F9",
  color: "#334155",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "16px",
  borderRadius:"26px",
  cursor:"pointer",
  justifyContent:"space-evenly",
  marginLeft:"16px"
}); 

const AnalyticsNextPrevImageDiv = styled("div")({
  display: "flex", 
  width: "65px", 
  justifyContent: "space-between", 
  paddingRight: "6px"
});

const AnalyticsNextPrevDiv = styled("div")({
  height: "24px",
  width: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const AnalyticsCalenderDiv = styled('div')({
  maxWidth: "548px", 
  background: "#FFFFFF", 
  boxShadow: "0px 2px 8px 0px #00000014", 
  height: "56px", 
  display: "flex", 
  borderRadius: "12px",
  boxSizing: "border-box",
  alignItems:"center",
  justifyContent:"space-between",
  "@media (max-width: 670px)": {
    maxWidth: "400px"
  },
  "@media (max-width: 530px)": {
    maxWidth: "290px"
  },
  "@media (max-width: 410px)": {
    width:"210px"
  }
});

const AnalyticsCalenderModel= styled('div')({
  top: '50%',
  position: 'absolute',
  border: 'none',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: "border-box",
  '@media (max-width: 600px)': {
    width: '95%',
  },

 
  "& .react-calendar button": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },

  "& .react-calendar": {
    borderRadius: "24px !important",
    width: "353px !important",
    fontFamily: "Raleway !important"
  },

  "& .react-calendar__navigation": {
    margin: "12px 12px 16px 12px !important",
    display: "flex !important",
    alignItems: "center !important"
  },

  "& .react-calendar__month-view__weekdays": {
    textAlign: "center",
    textTransform: "capitalize",
    fontSize:"14px !important",
    fontWeight: 500,
    color: "#64748B"
  },
  "& .react-calendar__navigation__arrow": {
    fontSize: "24px !important",
    color: "#94A3B8 !important",
    fontWeight: "550 !important",
  },

  "& .react-calendar__navigation button:enabled:hover": {
    backgroundColor: "transparent !important"
  },

  "& .react-calendar__navigation button:enabled:focus": {
    backgroundColor: "transparent !important"
  },

  "& .react-calendar__navigation__label": {
    fontFamily: "Raleway !important",
    fontSize: "14px !important",
    fontWeight: 700,
    color: "#0F172A !important"
  },

  "& .react-calendar__viewContainer": {
    margin: "0px 10px 5px 10px !important"
  },
  '& .react-calendar__month-view__days__day--weekend': {
    color: "#0F172A  !important"
  },

  "& abbr": {
    textDecoration: "none !important",
  },
  "& .react-calendar__tile--now:enabled:hover": {
    backgroundColor: "transparent !important"
  },

  "& .react-calendar__tile--now": {
    backgroundColor: "transparent !important",
  },

  "& .react-calendar__tile--active": {
    color: "#0F172A !important",
    fontWeight: "600 !important",
    backgroundColor: "transparent !important",
  },

  "& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus": {
    backgroundColor: "transparent !important"
  },

  "& .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury": {
    color: '#757575 !important',
  }

  

});

const AnalyticsMainDiv = styled(Box)({
  display: "flex",
  boxSizing: "border-box"
});

const AnalyticsHeaderAndBox = styled(Box)({
  width: "100%",
  background: '#F4F4F4',
  fontFamily: "Raleway",
  justifyItems: "center",
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
});

const AnalyticsHeaderDiv = styled('div')({
  background: "#FFFFFF",
  "& .icon": {
    width: "34px",
    height: "34px",
    marginRight: "20px",
  },
  '&.MuiPickersToolbar-toolbar-310': {
    backgroundColor: '#fff',
  },
});

const AnalyticsDataDiv = styled('div')({
  margin: "33px 0px 40px 28px"
});

const AnalyticsTwoBox = styled("div")({
 display: "flex", 
 gap: "24px", 
 marginTop: "40px",
 maxWidth:'1120px',
 "@media (max-width: 1400px)": {
 flexDirection:"column",
 width:"auto",
}
});

const AnalyticsChartBox = styled("div")({
  width:"550px", 
  display: "flex", 
  flexDirection: "column", 
  gap: "24px" ,
  "@media (max-width: 670px)": {
    width:"auto", 
 }
 });

 const AnalyticsSortDataMain = styled("div")({
  width:"548px", 
  display: "flex", 
  flexDirection: "column", 
  gap: "24px" ,
  "@media (max-width: 670px)": {
    width:"auto", 
 }
 });
const AnalyicHelloMain = styled('div')({
  display: "flex",
  flexDirection: "column",
  gap:"8px"
});

const AnalyticsHelloDiv = styled('div')({
  fontFamily: "Raleway", 
  fontSize: "24px", 
  fontWeight: 400, 
  lineHeight: "28.18px", 
  color: "#475569",
  paddingRight:"10px"
});

const AnalyticsHelloSpan = styled('span')({
  fontWeight: 700, 
  color: "#475569"
});

const AnalyticsHelloTittle = styled('div')({
  fontFamily: "Raleway", 
  fontSize: "16px", 
  fontWeight: 400, 
  lineHeight: "18.78px",
  color: "#94A3B8",
  pointerEvents:"none",
  "@media (max-width: 380px)": {
    fontSize: "14px", 
  }
});

const AnalytcAllCircleDiv = styled('div')({
  width: "24px", 
  height: "24px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
});

const AnalytcCircleRedDiv = styled('div')({
  width: "8px", 
  height: "8px",
  background: "#FF6666", 
  borderRadius: "50%" 
});

const AnalytcCircleBlueDiv = styled('div')({
  width: "8px", 
  height: "8px",
  background: "#006AFF", 
  borderRadius: "50%" 
});

const AnalytcLegendsDiv = styled('div')({
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  color:"#1E293B",
  cursor: "context-menu",
  "@media (max-width: 530px)": {
    fontSize: "11px",
  },
});

const AnalytcChartTextDiv = styled('div')({
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center",
  gap:"4px",
});

const AnalytcChartDiv = styled('div')({
  maxWidth: "552px", 
  boxSizing:"border-box",
  background: "#FFFFFF",
  height: "336px", 
  borderRadius: "16px", 
  border: "1px solid #F1F5F9", 
  display: "flex", 
  justifyContent: "center",
  "@media (max-width: 670px)": {
    maxWidth: "400px"
  },
  "@media (max-width: 530px)": {
    maxWidth: "290px"
  },
  "@media (max-width: 410px)": {
    width:"210px",
  }
});

const AnalytcChartInnerDiv = styled('div')({
  minWidth: "256px",
  maxHeight: "246px",
  display:"flex",  
  flexDirection:"column",
  justifyContent:"space-between",
  maxWidth:"256px",
});

const AnalytcChartInnerOneDiv = styled('div')({
  pointerEvents: 'none',
  padding:"14px 0px 21px 0px"
});

const AnalytcCircleDiv = styled('div')({
  display: "flex", 
  justifyContent:"space-between", 
  alignItems: "center",
  "@media (max-width: 410px)": {
    justifyContent:"space-around", 
    flexDirection:"column"
  },
});

const AnalytcCircleInnerDiv = styled('div')({
  display: "flex",
  gap: "4px"
});

const AnalytcFormulaTretment = styled('div')({
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  color: "#0F172A"
});

const AnalytcSortDiv = styled('div')({
  boxSizing: "border-box",
  width: "552px",
  height: "290px",
  background: "#FFFFFF",
  border: "1px solid #F1F5F9",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  "@media (max-width: 670px)": {
    maxWidth: "400px",
    overflow: "auto"
  },
  "@media (max-width: 530px)": {
    maxWidth: "290px",
    overflow: "auto"
  },
  "@media (max-width: 410px)": {
    width: "210px",
    overflow: "auto"
  },

  "&.sortDataScrollDiv": {
    "&::-webkit-scrollbar": {
      width: '4px',
      height:'4px'
    },
    "&::-webkit-scrollbar-track": {
      background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: '8px',
      background: '#94A3B8',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#90A4AE',
    },
    "&::-webkit-scrollbar:vertical": {
      width: '4px',
    },
  },
});

const AnalytcSortData = styled('div')({
  background: "#FFFFFF", 
  boxShadow: "0px 2px 8px 0px #00000014", 
  maxWidth: "548px", 
  display: "flex", 
  height: "56px", 
  borderRadius: "12px",
  alignItems:"center",
  boxSizing: "border-box",
  justifyContent:"space-between",
  overflow:"auto",
  "@media (max-width: 670px)": {
    maxWidth: "400px"
  },
  "@media (max-width: 530px)": {
    maxWidth: "290px"
  },

  "@media (max-width: 410px)": {
    width:"210px",
    overflow:"auto"
  },
});

const AnalytcSortTakenText = styled('div')({
  fontSize: "12px",
  lineHeight: "22px",
  fontFamily: "Raleway",
  color:"#0F172A",
  fontWeight: 500,
  alignItems:"center",
  display:"flex",
  width:"62px",
  justifyContent:"space-around",
  "@media (max-width: 530px)": {
    fontSize: "10px",
    width:"52px",
  },
});

const AnalytcSortSkipText = styled('div')({
  lineHeight: "22px",
  fontSize: "12px",
  fontFamily: "Raleway",
  fontWeight: 500,
  alignItems:"center",
  color:"#0F172A",
  width:"75px",
  display:"flex",
  justifyContent:"space-around",
  "@media (max-width: 530px)": {
    fontSize: "10px",
    width:"65px",
  },
});

const AnalytcSortUpcomingText = styled('div')({
  fontSize: "12px",
  lineHeight: "22px",
  fontWeight: 500,
  fontFamily: "Raleway",
  color:"#0F172A",
  alignItems:"center",
  display:"flex",
  width:"87px",
  justifyContent:"space-around",
  "@media (max-width: 530px)": {
    fontSize: "10px",
    width:"75px",
  },
});

const AnalytcSortHeaderDiv = styled('div')({
  display: 'flex',
  width: '100%',
  minHeight: "64px",
  alignItems: "center",
  gap: "72px",
  backgroundColor: "#F1F5F9",
  borderBottom: "0.5px solid #E2E8F0",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 550,
  lineHeight: "16px",
  color: "#64748B",
  padding: "0px 8px",
  justifyContent: "space-between",
  boxSizing: "border-box",
  "@media (max-width: 671px)": {  
    overflow: "auto",
    width: "fit-content !important"
  }
});

const AnalytcSortTableDiv = styled('div')({
  display: "flex", gap: "72px", alignItems: "center",
  fontFamily: "Raleway",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "16px",
  padding: "14px 8px",
  justifyContent: "space-between",
  color: "#0F172A",
  "@media (max-width: 671px)": {  
    overflow: "auto",
    width: "fit-content !important"
  }
});

const AnalytcSortHeaderText = styled('div')({
  minWidth: "80px",
  textAlign: "center",
  cursor: "context-menu"
});

const CustomCheckboxIcon = styled('span')({
  '&.MuiCheckbox-root-314 :hover': {
    color: 'rgba(0, 0, 0, 1)',
    width: '20px',
    height: '20px',
    borderRadius: '8px',
    backgroundImage: 'linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))',
    border: 'none',
  },
  borderRadius: 6,
  width: 19,
  height: 19,
  backgroundColor: '#F5F8FA',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '$root.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  '&.Mui-disabled': {
    background: 'rgba(206,217,224,.5)',
  },
  border: '1px solid #64748B',
});

const CustomCheckedIcon = styled('span')({
  '&:hover': {
    '.MuiIconButton-root-323': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
  },
  borderRadius: 6,
  width: 20,
  height: 20,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `url(${sortCheckimage})`,
    content: '""',
  },
  backgroundColor: '#0E6C9D',
  '& .MuiSvgIcon-root': {
    strokeWidth: 1,
    fontSize: 30,
    borderRadius: 60,
  },
  color: '#0E6C9D',
});

const CustomNewCheckbox = styled(Checkbox)({
  '&.MuiIconButton-root:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0) !important',
  },
  backgroundColor: '#F5F8FA00',
  backgroundImage: 'linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0))',
  boxShadow: '0px 2px 8px 0px #0000 !important',
  border: '1px solid #F1F5F900 !important',
  '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
  },
  '&.MuiCheckbox-root': {   
    color: 'rgba(0, 0, 0, 0.54)',
  width:"10px",
    borderRadius: '8px',
    background: '#F5F8FA',
    backgroundImage: 'linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0))',
    border: 'none !important',
  },
});

const SortButton = styled(Button)({
  minWidth: "64px",
  minHeight: "32px",
  border: "1px solid #E2E8F0",
  color: "#1E293B",
  fontWeight: 500,
  fontFamily: "Raleway",
  textTransform: "none",
  borderRadius: "50px",
  background: "#F1F5F9",
  fontSize:"12px",
  marginRight:"16px",
  '&:hover': {
    background: '#F1F5F9',
  },
  '&.disable_button': {
    color:"#1E293B",
    
    cursor: 'not-allowed',
  },
});

const NodataSort = styled('div')({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  textAlign:"center"
});

const NodataSortImagediv = styled('div')({
  maxWidth: "184.5px",
  padding:"24px 0px 8px 0px"
});

const NodataSortImageText = styled('div')({
  maxWidth:"504px",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  "@media (max-width: 530px)": {
    fontSize: "11px",
  },
});

const TretmentLog = styled('div')({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  paddingLeft:"16px",
  "@media (max-width: 410px)": {
    fontSize: "13px", 
  },
});

const TretmentLegendsDiv = styled('div')({
  display: "flex", 
  gap: "8px", 
  paddingLeft: "16px", 
  alignItems:"center",
  cursor: "context-menu",
  flexWrap:"wrap", 
  "@media (max-width: 410px)": {
    width: "200px", 
  },
});

const AnalyticstakeSkippedDiv = styled('div')({
  display: "flex", 
  width: "240px", 
  justifyContent: "space-around", 
  alignItems: "center",
  cursor: "context-menu",
 
});

const StyledList = styled(Popover)({
  "&.sortDataScrollDiv": {
  "&::-webkit-scrollbar": {
    width: '4px',
  },
  "&::-webkit-scrollbar-track": {
    background: '#F1F5F9',
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: '8px',
    background: '#94A3B8',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: '#90A4AE',
  },
  "&::-webkit-scrollbar:vertical": {
    width: '4px',
  },
 },
});
// Customizable Area End
