import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { notificationMenuReade, notificationMenuSkip, notificationMsg, notificationPin, notificationTrofi, notificationWatch } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface OptionMenu{
  imageLink:string,
  text:string,
  id:string,
  status: string
}


interface Notification {
  id:string,
  icon: string;
  mainName: string;
  secondaryNamePrefix: string;
  boldText: string;
  time: string;
  type: string;
  attributes: {
    redirect_to: string;
    id: number;
    headings: string;
    contents: string;
    created_at: string;
    updated_at: string;
    account_id: number;
    notification_type: string;
    notification_details_id: number;
    notification_status: string;
    reminder_type: string;
  };
}
interface FirstImgNotification {
  "Treatment Alert": string;
  "Refill Alert": string;
  "Badge Earned!": string;
  "Message Notification": string;
}

interface NotificationResponse {
  today: {
    message: string
    data: Notification[]
    yesterday: {
      message: string
      data: Notification[]
    }
  }
}

interface NotificationData {
  data: NotificationResponse;
}

interface OlderNotification {
  icon: string;
  mainName: string;
  secondaryNamePrefix: string;
  boldText: string;
  id: string;
  type: string;
  attributes: {
    id: number;
    redirect_to: string;
    headings: string;
    contents: string;
    created_at: string;
    updated_at: string;
    account_id: number;
    notification_type: string | null;
    notification_details_id: number | null;
    notification_status: string;
    reminder_type: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  anchorEl: HTMLElement | null;
  isPanadolBold:string[];
  notificationType: {id:string, type: string}[];
  options:OptionMenu[];
  isnotificationsText:Notification[];
  selectedOptionId:string;
  indexThatWehave: string;
  yesterdayoptions:OptionMenu[];
  yesterdaynotificationsText:Notification[];
  yesterdayanchorEl: HTMLElement | null;
  yesterdayisPanadolBold: string[];
  yesterdaynotificationType: {id:string, type: string}[];
  yesterdayselectedOptionId:string;
  yesterdayindexThatWehave:string;
  olderoptions:OptionMenu[];
  oldernotificationsText:OlderNotification[];
  olderanchorEl: HTMLElement | null;
  olderisPanadolBold: string[];
  oldernotificationType: {id:string, type: string}[];
  olderselectedOptionId:string;
  olderindexThatWehave:string;
  getNotificationApi: NotificationResponse | null;
  snackbarOpen:boolean;
  FirstImgNotification:FirstImgNotification[]
  currentPage: number,
  totalPages: number
  notificationStatus: string;
  notificationMessage: string;
  paginationData: {
        current_page: number,
        next_page: number,
        prev_page: number | null,
        total_pages: number,
        total_count: number

  },
  getNotificationDot:boolean;
  reminderType: string;
  redirectTo: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushnotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationCallId: string = "";
  putUpdateNotificationCallId:string = "";
  getOlderNotificationCallId: string = "";
  putmarkeAsReadSatusCallId:string = "";
  singleReadNotificationCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
        selectedOptionId:"",
        anchorEl: null,
        isPanadolBold: [],
        notificationType:[],
        options:[
          {
            id:"1",
            imageLink:notificationMenuReade,
            text:"Taken/Mark as read",
            status: "read"
          },
          {
            id:"2",
            imageLink:notificationMenuSkip,
            text:"Skip Notifications",
            status: "skipped"
          },
        ],
        isnotificationsText : [],
        
        indexThatWehave:"",

      yesterdayselectedOptionId:"",
      yesterdayanchorEl: null,
      yesterdayisPanadolBold: [],
      yesterdaynotificationType:[],
      yesterdayindexThatWehave:"",
      yesterdayoptions:[
        {
          id:"1",
          imageLink:notificationMenuReade,
          text:"Taken/Mark as read",
          status: "read"
        },
        {
          id:"2",
          imageLink:notificationMenuSkip,
          text:"Skip Notifications",
          status: "skipped"
        },
      ],
      yesterdaynotificationsText: [],
      olderselectedOptionId:"",
      olderanchorEl: null,
      olderisPanadolBold: [],
      oldernotificationType:[],
      olderindexThatWehave:"",
      olderoptions:[
        {
          id:"1",
          imageLink:notificationMenuReade,
          text:"Taken/Mark as read",
          status: "read"
        },
        {
          id:"2",
          imageLink:notificationMenuSkip,
          text:"Skip Notifications",
          status: "skipped"
        },
      ],
      oldernotificationsText: [],

      getNotificationApi: null,
      snackbarOpen:false,
       FirstImgNotification :[{
        "Treatment Alert": notificationWatch,
        "Refill Alert": notificationPin,
        "Badge Earned!": notificationTrofi,
        "Message Notification": notificationMsg
      }],
      currentPage: 1,
      totalPages: 5,
      notificationStatus: "",
      notificationMessage: "",
      paginationData:
      {
        current_page: 1,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
        total_count: 5,
      },
      getNotificationDot:true,
      reminderType: "",
      redirectTo: "",
      loading: false,
    };    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getNotificationCallId) {
        this.setState({ getNotificationApi: responseJson.data, isnotificationsText:responseJson.data.today.data || [], yesterdaynotificationsText:responseJson.data.yesterday.data || [] })
      }
      this.apiReciver(apiRequestCallId,responseJson)
     
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const meta = await getStorageData("meta")
    const metaToken = JSON.parse(meta)
    const authToken = await getStorageData("authToken")
     if(!authToken){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
     }
    const token =  authToken || metaToken?.token
    this.setState({ token },() => {this.getNotification(); this.getOlderNotification()})
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  apiReciver = (apiRequestCallId:string,responseJson: { data:[] ; meta: { pagination: { current_page: number; next_page: number; prev_page: number | null; total_pages: number; total_count: number; } }; }) => {
    if (apiRequestCallId === this.putUpdateNotificationCallId && responseJson) {        
      this.setState({anchorEl:null,snackbarOpen: this.state.notificationStatus === "unread" ? false : true, yesterdayanchorEl:null})
      this.getNotification()
      this.getOlderNotification()
    }
    if (apiRequestCallId === this.getOlderNotificationCallId) {
      this.setState({ olderanchorEl: null, oldernotificationsText: responseJson.data || [], paginationData:responseJson.meta.pagination })

    }
    if (apiRequestCallId === this.putmarkeAsReadSatusCallId ) {
      setStorageData("notifyStatus", 'false')
      this.setState({ loading: false });
      this.getNotification()
      this.getOlderNotification() 
    }
    if (apiRequestCallId === this.singleReadNotificationCallId ) {
      this.navigatePkuScreens(this.state.redirectTo)
    }
  }

  closeFuction = (event:React.ChangeEvent<unknown>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null })
  }
  yesterdayCloseFuction = (event:React.ChangeEvent<unknown>) => {
    event.stopPropagation();
    this.setState({ yesterdayanchorEl: null })
  }

  olderCloseFuction = async (event:React.ChangeEvent<unknown>) => {
  event.stopPropagation();
  this.setState({ olderanchorEl: null })
  }

  handleCloseSnackbar = () => {
    this.setState({snackbarOpen :false})
  };

  handlePaginationChange = (event:React.ChangeEvent<unknown>, page:number) => {
    this.setState({ paginationData: {...this.state.paginationData, current_page: page, total_count: 5} }, () => {
      this.getOlderNotification();
    });
    
  };

  markeAsReadSatus = async () => {
    this.setState({ loading: true })
    const { token } = this.state
    const header = {
      token,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putmarkeAsReadSatusCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.markAllReadendPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  getNotification = () => {
    const {token} = this.state

    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOlderNotification = () => {
    const {token} = this.state
    const {current_page,total_count} = this.state.paginationData

    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOlderNotificationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.oldNotificationendPoint}?page=${current_page}&per_page=${total_count}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  putUpdateNotification =  (event: React.MouseEvent<HTMLElement>,status: string) => {    
    event.stopPropagation();
    const { token, indexThatWehave } = this.state
      this.setState({notificationStatus: status})    
      this.setMessageShow(status)    
    const header = {
      token,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putUpdateNotificationCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${indexThatWehave}?notification_status=${status}`
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

   /* istanbul ignore next */
   setMessageShow = (status: string) => {
    if (status === "read") {
      this.setState({
        notificationMessage: `${this.state.reminderType} marked as taken`
      })
    } else if (status === "skipped") {
      this.setState({
        notificationMessage: `${this.state.reminderType} marked as skipped`
      })
    } else if (status === "unread") {
      this.setState((prevState) => ({
        notificationMessage: prevState.notificationMessage
      }));
    }
  }

  singleReadNotification =  (event: React.MouseEvent<HTMLElement>,notificationId: string, type: string | null, redirect_to: string) => {    
    event.stopPropagation();    
    if(type === "treatment_reminder"){
      this.navigatePkuScreens(redirect_to)
   } else {
    const { token } = this.state
    this.setState({
      redirectTo: redirect_to
    })
    const header = {
      token,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.singleReadNotificationCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${notificationId}?notification_status=read`
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>,notification:Notification) => {
    event.stopPropagation();
    this.setState({ anchorEl: event?.currentTarget, indexThatWehave: notification.id, reminderType: notification.attributes.reminder_type});
  };

  handleYesterdayClick =  (event: React.MouseEvent<HTMLElement>, notification: Notification) => {
    event.stopPropagation(); 
    
    this.setState({ yesterdayanchorEl: event?.currentTarget, indexThatWehave: notification.id, reminderType: notification.attributes?.reminder_type });
  };

  navigatePkuScreens = (redirectTo: string) => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), redirectTo);
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  };
  

  handleOlderClick =  (event: React.MouseEvent<HTMLElement>, notification: OlderNotification) => {
    event.stopPropagation();
    this.setState({ olderanchorEl: event?.currentTarget, indexThatWehave: notification.id, reminderType: notification.attributes.reminder_type });
  };

  handleShowStatus = (status: string, type: string | null) => {
    if(type === "treatment_reminder"){
      if (status === "unread") {
        return ""
      } else if (status === "read") {
        return "taken"
      } else {
        return status
      }
    }
  }

  handleTextColor = (status:string) => {
    if(status === "read"){
      return "green"
    } else if (status === "skipped"){
      return "red"
    }else {
      return "black"
    }

  }
  // Customizable Area End
}
