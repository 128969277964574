import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { downloadFile } from "../../../components/src/downloadFile";
import moment from "moment";
interface ResponseData {
  pdf_url: string,
  message: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  fromDate:string | null;
  toDate:string | null;
  openDialog: boolean,
  selectedDate: string,
  isDatePickerOpen: boolean;
  isLoading : boolean;
  sendMail: boolean;
  successmodel: boolean;
  pdfdownload: boolean;
  isType: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaveAsPdfController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  exportDataApiCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      open: true,
      fromDate: null,
      toDate: null,
      openDialog: false,
      selectedDate: '',
      isDatePickerOpen: false,
      isLoading: false,
      successmodel: false,
      sendMail: false,
      pdfdownload:false,
      isType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.exportDataApiCallId) {
        this.handleExportResponse(responseJson)        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let authToken = await getStorageData("authToken");
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
  }
  
  handleClose = () => {
    this.setState({ open: false });
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Task");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);  };

  handleDateChange = (date: string) => {
    this.setState({ fromDate: date, toDate: null });
  }

  toDateInput = (date : string) => {    
    this.setState({toDate: date})
  }

  exportDataApi = async (type: string) => {
    this.setState({isLoading : true, isType: type})
    const meta = await getStorageData("meta")
    const metaToken = JSON.parse(meta)
    const token = await getStorageData("authToken") || metaToken?.token
    const fromDate = moment(this.state.fromDate).format('DD/MM/YYYY');
    const toDate =  moment(this.state.toDate).format('DD/MM/YYYY')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
       token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.exportDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExportDateApi}?from=${fromDate}&to=${toDate}&type=${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSuccessModelClose = () => {
    this.setState({ successmodel: false, open: true, fromDate: null, toDate: null})
  }
  
  handleDownloadModelClose = () => {
    this.setState({ pdfdownload: false })
  }
  /* istanbul ignore next */
  handleExportResponse = async (responese: ResponseData) => {
    const {isType} = this.state
    if (isType === "download") {
      downloadFile(responese && responese.pdf_url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "logbook_export.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        this.setState({ isLoading: false, fromDate: null, toDate: null,pdfdownload:true })
      })
      .catch(error => {
        console.log('There was a problem with your fetch operation:', error);
      })
    } else {
      this.setState({successmodel: true, open: false, isLoading: false })
    }
  }
  
  handleMaxDate = () => {
    const { fromDate } = this.state;
    const currentDate = new Date();
    const maxDate = fromDate ? new Date(Math.min(currentDate.getTime(), new Date(fromDate).getTime() + 6 * 24 * 60 * 60 * 1000)) : currentDate;
    return maxDate
  }
  // Customizable Area End
}
