import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logo } from './assets';
import { Link } from 'react-router-dom';

interface Props{
  title: string;
  body: string;
  redirect_to: string;
  handleCloseToast: () => void
  notificationId: string;
}

const DisplayNotification = ({
  title,
  body,
  redirect_to,
  handleCloseToast,
  notificationId
}:Props) => {

  toast.info(<Display />, {
    icon: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose: 4000,
    onClose: handleCloseToast
  });

  function Display() {    
    return (
      <Link to={`${redirect_to}/${notificationId ?? ""}`} style={{textDecoration: "none", color: "#000000"}}>
        <div
          className="main-div-toast"
          onClick={() => {
            if (redirect_to) {
              handleCloseToast();
            }
          }}
        >
          <div className="header-div-toast">
            <img src={logo} alt="" />

            <p>Cycle Vita App</p>
          </div>
          <h2>{title || ''}</h2>
          <p>{body}</p>
        </div>
      </Link>
    );
  }

  return '';
};

DisplayNotification.defaultProps = {
  title: 'This is title',
  body: 'Some body'
};

DisplayNotification.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string
};

export default DisplayNotification;