Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.deleteMethod = "DELETE";
exports.putMethod = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.getTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getprivacyPolicyApiEndPoint = 'terms_and_conditions/privacy_policy';
exports.getGDPRPolicyApiEndPoint = 'bx_block_content_management/gdpr_policies';
exports.deleteAccountEndPoint = 'destroy_account';
exports.resetPasswordEndPoint = 'reset_password';

exports.userProfileEndPoint = '/bx_block_profile/profile';
exports.btnExampleTitle = "CLICK ME";
exports.ipAddressApi = "https://api64.ipify.org/?format=json";
exports.apiType = "multipart/form-data";
// Customizable Area End