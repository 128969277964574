import React from "react";
// Customizable Area Start
import {
    Button,
    TextField,
    Grid,
    Divider,
    InputAdornment,
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Typography,
    styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    old_pass: Yup.string()
    .required("Old password is required"),
    new_pass: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(
        /^(?=.*\d)/,
        "Password must contain at least one number"
      )
      .matches(
        /^(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      )
      .matches(
        /^[0-9a-zA-Z!@#$%^&*]{8,}$/,
        "Password must be at least 8 characters long"
      ),
    conf_pass: Yup.string()
      .oneOf([Yup.ref("new_pass")], "Passwords must match")
      .required("Password is required"),
  });
  
  const MainContainer = styled(Box)({
    "& .error-message": {
        margin: "0 !important",
        fontFamily: 'Raleway !important',
        fontWeight: 500
      },
    '& .inputbox': {
        background: "#F8FAFC !important",
        fontFamily: 'Raleway !important',
     "& .MuiInputBase-root": {
        fontFamily: 'Raleway !important'
     },
    
      '& .MuiOutlinedInput-input': {
        background: "red !important",
        fontFamily: 'Raleway !important',
        border: "1px solid #CBD5E1",
        borderRadius: "12px !important"
      },
      '& ::placeholder': {
        color: "#64748B",
        fontFamily: 'Raleway',
        opacity: 1
      }
    }
  });
// Customizable Area End


const theme = createTheme({
    palette: {
        primary: {
            main: "#131313",
            contrastText: "#fff"
        },
        
    }
});

import SettingsController, { Props } from "./SettingsController.web";
import { SettingsMainLogo, calender, notificationIcon, visible, inVisible } from "./assets";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import WebHeader from "../../../components/src/WebHeader.web";
// Customizable Area End

export default class Settings extends SettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     getColor = (values:{new_pass:string,conf_pass:string, old_pass: string }) => (
        values.conf_pass && values.old_pass && values.new_pass ? '#13689E' : 'lightblue'
    );
    

    getType = (values:{vis2:boolean}) => values.vis2 ? "text" : "password";

    /* istanbul ignore next */
    changePassword = () => {
        return (
            <>
                <Grid item lg={10} md={9} sm={12} style={styles.changePasswordPrivacy}>
                    <Grid>
                        <Grid container justifyContent="space-between">

                            <Grid item style={styles.changePasswordMainGrid} >
                                <Grid style={styles.recommandTextGrid}></Grid>
                                <Grid>
                                    <Grid style={styles.recipeText}>{this.state.javygatorInfoData.titel || `Change Password`}</Grid>
                                    <Grid style={styles.recipyDisc}>{this.state.javygatorInfoData.sub_title || `Update your account security.`}</Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid>
                        <Formik
                            initialValues={{ new_pass: "", conf_pass: "", old_pass: "", vis1: false, vis2: false }}
                            validationSchema={validationSchema}
                            data-test-id="formik"
                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                this.formSubmitButton(values, { setSubmitting, setErrors })
                            }}>
                            {(props) => {
                                const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
                                return (
                                    <MainContainer>
                                        <form onSubmit={handleSubmit}>
                                            <Grid container justifyContent="space-between" wrap="nowrap">
                                                <Grid container item xs={12} md={8} spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            type={this.state.showPassword ? "text" : "password"}
                                                            variant="outlined"
                                                            className="inputbox"
                                                            fullWidth
                                                            onChange={(event) => { handleChange(event); this.handleRemoveError() }}
                                                            onBlur={handleBlur}
                                                            name="old_pass"
                                                            data-test-id="oldPassword"
                                                            style={styles.oldPasswordTextField}
                                                            placeholder="Old Password"
                                                            value={values.old_pass}
                                                            error={touched.old_pass && !!errors.old_pass || !!this.state.passwordError}
                                                            helperText={touched.old_pass ? errors.old_pass : ""}
                                                            FormHelperTextProps={{
                                                                className: "error-message"
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton data-test-id="toggleVisiblity" onClick={this.togglePasswordVisibility} edge="end">
                                                                            {this.state.showPassword ? <><img src={visible} /> </> : <> <img src={inVisible} /> </>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                style: styles.changePasswordFormik
                                                            }}
                                                        />
                                                        {this.state.passwordError && <Box style={styles.formikPasswordError}>{this.state.passwordError}</Box>}
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            data-test-id="newPassword"
                                                            placeholder="New Password"
                                                            style={styles.newPassword}
                                                            fullWidth
                                                            name="new_pass"
                                                            className="inputbox"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.new_pass}
                                                            variant="outlined"
                                                            error={touched.new_pass && !!errors.new_pass}
                                                            helperText={touched.new_pass ? errors.new_pass : ""}
                                                            type={values.vis1 ? "text" : "password"}
                                                            FormHelperTextProps={{
                                                                className: "error-message"
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position="end"
                                                                        style={styles.newPasswordCss}
                                                                        onClick={() => props.setFieldValue("vis1", !values.vis1)}
                                                                        data-test-id="togglePassword"
                                                                    >
                                                                        {values.vis1 ? <><img src={visible} /> </> : <> <img src={inVisible} /> </>}
                                                                    </InputAdornment>
                                                                ),
                                                                className: "inputbox",
                                                                style: styles.changePasswordFormik
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            placeholder="Confirm Password"
                                                            FormHelperTextProps={{
                                                                className: "error-message"
                                                            }}
                                                            name="conf_pass"
                                                            onChange={handleChange}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            value={values.conf_pass}
                                                            className="inputbox"
                                                            variant="outlined"
                                                            error={touched.conf_pass && !!errors.conf_pass}
                                                            helperText={touched.conf_pass ? errors.conf_pass : ""}
                                                            type={this.getType(values)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        position="end"
                                                                        style={styles.newPasswordCss}
                                                                        onClick={() =>
                                                                            props.setFieldValue("vis2", !values.vis2)
                                                                        }
                                                                        data-test-id="togglePassword"
                                                                    >
                                                                        {values.vis2 ? <><img src={visible} /> </> : <> <img src={inVisible} /> </>}
                                                                    </InputAdornment>
                                                                ),
                                                                style: styles.changePasswordFormik
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item>
                                                    <Button type="submit" style={{ ...styles.changePasswordContinueButton, backgroundColor: this.getColor(values) }}
                                                        disabled={!values.conf_pass || !values.old_pass || !values.new_pass}
                                                    > Continue</Button>
                                                </Grid>
                                            </Grid>

                                        </form>
                                    </MainContainer>
                                );
                            }}
                        </Formik>

                        <Grid>

                        </Grid>
                    </Grid>

                </Grid>
                {this.successModel()}
            </>
        )
    };

    termsConditions = () => {
        return (
            <>
                <Grid item lg={10} md={9} sm={12} style={styles.changePasswordPrivacy}>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item style={styles.changePasswordMainGrid} >
                                <Grid style={{ ...styles.recommandTextGrid, height: "none" }}></Grid>
                                <Grid>
                                    <Grid style={styles.termsConditionstitle}>{this.state.javygatorInfoData.titel || `Terms & Conditions`}</Grid>
                                    <Grid style={styles.termsConditionsSubTitle}>{this.state.javygatorInfoData.sub_title || `URead to our terms for usage.`}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.termsConds !== undefined && this.state.termsConds.map((item: { id: number; created_at: string; description: string; }) => {
                        return (
                            <Box key={item.id}>
                                <Typography style={styles.trmHeadingMain}>Introduction</Typography>
                                <Typography style={styles.trmContaint} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Box>
                        )
                    })}
                </Grid>
            </>
        )
    };

    privacyPolicy = () => {
        return (
            <>
                <Grid  lg={10} md={9} sm={12} item style={styles.changePasswordPrivacy}>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item style={styles.changePasswordMainGrid}>
                                <Grid style={{ ...styles.recommandTextGrid, height: "none" }}></Grid>
                                <Grid>
                                    <Grid style={styles.privacyPolicyText}>
                                        {this.state.javygatorInfoData.titel || `Privacy Policy`}</Grid>
                                    <Grid style={styles.privacyPolicySubTitle}>
                                        {this.state.javygatorInfoData.sub_title || `Read to our privacy policy.`}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.privacyPolicy !== undefined && this.state.privacyPolicy.map((item: { id: number; created_at: string; description: string; }) => {
                        return (
                            <Box key={item.id}>
                                <Typography style={styles.trmHeadingMain}>Introduction</Typography>
                                <Typography style={styles.trmContaint} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Box>
                        )
                    })}
                </Grid>
            </>
        )
    };

    gDPRPolicy = () => {
        return (
            <>
                <Grid  lg={10} md={9} sm={12} style={styles.changePasswordPrivacy} item>
                    <Grid>
                        <Grid  justifyContent="space-between" container>
                            <Grid  style={styles.changePasswordMainGrid} item >
                                <Grid style={{ ...styles.recommandTextGrid, height: "none" }}></Grid>
                                <Grid>
                                    <Grid style={styles.privacyPolicyText}>
                                        {this.state.javygatorInfoData.titel || `GDPR Policy`}
                                    </Grid>
                                    <Grid style={styles.privacyPolicySubTitle}>
                                        {this.state.javygatorInfoData.sub_title || `Read to our GDPR policy.`}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                   
                    {this.state.gDPRPolicy !== undefined && this.state.gDPRPolicy.map((item: { id: number; created_at: string; description: string; }) => {
                        return (
                            <Box key={item.id}>
                                <Typography style={styles.trmHeadingMain}>Introduction</Typography>
                                <Typography style={styles.trmContaint} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Box>
                        )
                    })}
                </Grid>
            </>
        )
    }

    deleteAccount = () => {
        return (
            <>
                <div>
                    <Dialog
                        open={this.state.open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{ style: styles.deleteAccountPaperProps }} >
                        <div>
                            <div style={styles.deleteAccountDiv}>
                                <IconButton edge="end" color="inherit" data-test-id="dialogDeleteModal" onClick={this.handleCloseDeleteModal} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div style={styles.deleteSecoundAccount}>
                                <Box style={styles.dialogTitle}>Are you sure you want to delete your <br/> account?</Box>
                                <Box style={styles.dialogSubtitle}>This action is irreversible. All your data will be permanently removed.</Box>
                            </div>
                                <Divider style={styles.deleteAccountBothButton}></Divider>
                            <DialogActions style={styles.dialogBothButton}>
                                <Button style={styles.deleteAccountKeepAccount}
                                    onClick={this.handleCloseDeleteModal}>Keep Account</Button>
                                <Button style={styles.deleteAccountDeleteAccount}
                                    data-test-id="deleteAccount"
                                    onClick={this.handleClose}>Delete Account</Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>

            </>
        )
    }

    successModel = () => {
        return (
            <Dialog
                keepMounted
                open={this.state.successReset}
                data-test-id="successResetModel"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        maxWidth: "497px",
                        height: 'auto',
                        borderRadius: 24,
                        padding: "16px 32px",
                        background: "#fff"
                    },
                }}
            >
                <DialogContent style={styles.dialogTextContaent}>
                    <DialogContentText id="alert-dialog-slide-description" style={styles.dialogLongText} >
                        Your password has been successfully  updated. Continue to securely explore <br></br> your account with the new credentials.
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions style={styles.dialogButtonDiv}>
                    <Button
                        data-test-id="successId"
                        onClick={() => this.handleSuccessModelClose()}
                        style={styles.dialogCloseButton}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    sideMenu = () => {
        const { selectedSideMenu } = this.state;
        let result;
        if (selectedSideMenu === "Change Password") {
            result = (!this.state.socialMediaType && this.changePassword());
        } else if (selectedSideMenu === "Terms & Conditions") {
            result = this.termsConditions();
        }else if (selectedSideMenu === "Privacy Policy") {
            result = this.privacyPolicy();
        } else if (selectedSideMenu === "GDPR Policy") {
            result = this.gDPRPolicy();
        }
        return result;
    };

    // Customizable Area End
    render() {
        const SideBaru = SideBar        
        // Customizable Area Start
         /* istanbul ignore next */
        return (
            <ThemeProvider theme={theme}>
                <WebOnboarding
                    title={this.state.onBoardingTitle}
                    discripation={this.state.onBoardingText}
                    anchorEl={this.state.socialMediaType ? this.state.onboardingRefs1[this.state.currentStep - 1]?.current : this.state.onboardingRefs[this.state.currentStep - 1]?.current}
                    next={this.nextOnBoarding}
                    open={this.state.openOnboarding}
                    skip={this.skipOnBoarding}
                    anchorhorizontal={this.state.anchorHorizontal}
                    transformhorizontal={this.state.transformHorizontal}
                />
                <div style={styles.renDivCss}>
                    <SideBaru navigation={this.props.navigation} />
                    <Grid style={styles.mainContainer}>
                        {this.deleteAccount()}
                        <WebHeader title={"Settings"} icon={SettingsMainLogo}/>
                        <Grid container  style={styles.mainConCss}>
                            <Grid item lg={2} md={3} sm={12} style={styles.mainConSecoundCss}>
                                <Grid style={styles.sidemenuResponsiveCss}>
                                    {this.state.imageTextOption.map((value: { icon: string; text: string, activeIcon: string }, index: number) =>{
                                        return( !(value.text=='Change Password'&&this.state.socialMediaType)&&
                                        <Grid key={index} style={{ ...styles.imgContainer,backgroundColor: this.state.selectedSideMenu === value.text ? "#F1F5F9" : "#fff" }}
                                            data-test-id="sideMenuSelect"
                                            onClick={() => this.slectFromSide(value.text)} >
                                            <Grid item><img src={this.state.selectedSideMenu === value.text ? value.activeIcon : value.icon} alt={`${value.icon}`} style={styles.sideMenuText} /></Grid>
                                            <Grid item ref={this.state.socialMediaType ? this.state.onboardingRefs1[index] : this.state.onboardingRefs[index]} style={{ ...styles.imgText, fontWeight: this.state.selectedSideMenu === value.text ? 600 : 400 }}>
                                                {value.text}
                                            </Grid>
                                        </Grid>)}
                                    )}
                                </Grid>
                            </Grid>
                            {this.state.selectedSideMenu && this.sideMenu()}
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    renDivCss:{ display: "flex" },
    sidemenuResponsiveCss:{width:"90%"},
    recipeText: { fontSize: "20px", fontWeight: 700, fontFamily: "Raleway", color: "#1A1D1F",marginBottom:"7px" },
    privacyPolicyText: { fontSize: "20px", fontWeight: 700, fontFamily: "Raleway", color: "#1A1D1F",marginBottom:"7px" },
    termsConditionstitle:  { fontSize: "20px", fontWeight: 700, fontFamily: "Raleway", color: "#1A1D1F",marginBottom:"7px" },
    recommandTextGrid: { width: "6px", backgroundColor: "#13689E", borderRadius: "4px", height: "100%", marginRight: "18px"},
    privacyPolicySubTitle: { fontSize: "12px", fontWeight: 400, fontFamily: "Raleway", color: "#64748B" },
    termsConditionsSubTitle: { fontSize: "12px", fontWeight: 400, fontFamily: "Raleway", color: "#64748B" },
    mainConSecoundCss:{ padding: "20px 1px 0px 25px" },
    recipyDisc: { fontSize: "12px", fontWeight: 400, fontFamily: "Raleway", color: "#64748B" },
    mainConCss: { backgroundColor: "#FAFAFA" },
    imgContainer: {
        display: "flex", alignItems: "center",width:"90%",
         backgroundColor: "#F1F5F9", marginBottom: "10px", borderRadius: "8px", cursor: "pointer",
         padding: "12px 13% 12px 3%" ,
    },
    changePasswordFormik:{ fontFamily: "Raleway", fontWeight: 500, borderRadius: "10px", background: "#F8FAFC" },
    imgText: { fontSize: "14px", color: "#0F172A", fontFamily: "Raleway", marginLeft: "10px", fontStyle: 'normal', lineHeight: "22px" },
    deleteAccountDiv:{ width: "96%", textAlign: "end" as "end",margin:"6px 0 0 0" },
    hederNotificationConatiner: { position: "relative", marginRight: "30px" },
    mainContainer: { width: "100%", backgroundColor: "#FAFAFA", height: "auto", minHeight: "100vh" },
    newPasswordCss :{
        color: "rgba(100, 116, 139, 1)",
        cursor: "pointer",
    },
    changePasswordContinueButton : {marginTop: "15px",fontFamily: "Raleway", fontWeight: 700, fontSize: "16px", color: "#FFFFFF", textTransform: "inherit" as "inherit", width: "102px", height: "56px", borderRadius: "8px"},
    deleteAccountPaperProps:{ borderRadius: '25px' },
    formikPasswordError : {fontFamily: "Raleway", fontWeight: 500, marginTop: "2px", color:"#f44336", fontSize: "0.75rem"},
    deleteAccountBothButton:{ width: "100%",color:"#E2E8F0",backgroundColor:"#E2E8F0" },
    deleteSecoundAccount:{padding: "0 25px 15px 25px"},
    massageImgContainer: {
        height: "45px", width: "45px", borderRadius: "12px", boxShadow: "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014", display: "flex",
        justifyContent: "center", alignItems: "center", backgroundColor: "#fff"
    },
    changePasswordCss :{
        padding: "20px 0 0 33px" 
    },
    changePasswordPrivacy:{
        padding: "20px 0 0 25px" 
    },
    deleteAccountKeepAccount :{
        padding: '16px', borderRadius: '50px', color: '#13689E', fontFamily: 'Raleway',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        textTransform: "inherit" as "inherit",
        backgroundColor:"#FCFCFC"
     },
     deleteAccountDeleteAccount:{
        background: '#13689E', padding: '16px', borderRadius: '50px', color: '#FFFFFF', fontFamily: 'Raleway',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        textTransform: "inherit" as "inherit"
    },
    changePasswordMainGrid: {
        display: "flex",
        width: "50%",
        marginBottom:"10px"
    },
    oldPasswordTextField: { borderRadius: "8px",marginTop:'20px' },
    secoundGrid: { display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "8px" },
    trmHeadingMain: { marginTop: "25px", fontSize: "18px", fontWeight: 600, fontFamily: "Raleway", color: "#000000" },
    trmContaint: { color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: "Raleway" },
    newPassword: { fontFamily: "Raleway !important", fontWeight: 500},
    confirmPassword: { width: "305px", height: "52px", borderRadius: "8px" },
    sideMenuText:{ width: "18px", height: "18px" },
    dialogTitle: {
        fontFamily: 'Raleway',
        color: "#0F172A",
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',
        margin:"20px"
    },
    dialogSubtitle: {
        color: '#0F172A',
        fontFamily: 'Raleway',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '26px',
        margin:"20px"
    },
    dialogBothButton: {
        display: 'flex',
        height: '80px',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        gap: '16px',
        flexShrink: 0,
        margin:'13px  13px'
    },
    dialogCloseButton: {
        color: "#007AFF",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "none" as "none"
    },
    dialogButtonDiv: {
        display: "flex", 
        justifyContent: "center",
        color: '#007AFF',
        textAlign: 'center' as 'center',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px'
    },
    dialogLongText:{
        fontFamily: "Raleway",
        fontSize: "22px",
        paddingTop: "16px",
        fontWeight: 600,
        color: '#334155'
    },
    dialogTextContaent: { display: "flex", textAlign: "center" as "center", padding: "10px 10px" },

}
// Customizable Area End