import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { RefObject, createRef } from "react";
// Customizable Area Start
interface StateType {
    [key: string]: boolean;
}

interface OnboardingStep {
    id: number;
    onboarding_title: string;
    onboarding_description: string;
    transformHorizontal: string;
    anchorHorizontal: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    keycontacts: Object[];
    textinbox: boolean;
    onBoarding1: OnboardingStep[];
    openOnboarding: boolean;
    onBoardingTitle: string;
    onBoardingText: string;
    onBoardingRef: string;
    currentStep: number;
    anchorHorizontal: string;
    transformHorizontal: string;
    openOneScreenOnboarding: boolean;
    onBoardingBtnDisable: boolean;
    onboardingRefs: RefObject<HTMLTableCellElement>[];
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    keycontentdataApiCallid: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            keycontacts: [],
            textinbox: false,
            onboardingRefs: [createRef<HTMLTableCellElement>()],
            onBoarding1: [{
                "id": 1,
                "onboarding_title": "Step 1",
                "onboarding_description": "View Contact Details: Access contact information for Clinical Education Managers, Community Pharmacy, Specialty Pharmacy, and Hospital Pharmacy for your convenience and assistance.",
                "transformHorizontal": "left",
                "anchorHorizontal": "left",
            }
            ],
            openOnboarding: false,
            onBoardingTitle: "",
            onBoardingText: "",
            onBoardingRef: "",
            currentStep: 0,
            anchorHorizontal: "left",
            transformHorizontal: "left",
            openOneScreenOnboarding: true,
            onBoardingBtnDisable: false,
            // contactus: true

        }
        // Customizable Area Start

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area End
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.keycontentdataApiCallid) {
                    this.keyDatasetUp(responseJson.data);
                }
            }
        }

    };

    async componentDidMount() {
      
        const isMacos = navigator.platform.toLowerCase().includes('mac') || /(iPhone|iPad|iPod)/.test(navigator.userAgent)
        this.setState({textinbox: isMacos})
        this.keyContentDataGet();

        let onboarding = await getStorageData("onBoarding");
        let authToken = await getStorageData("authToken");
        let userType = await getStorageData("user_type");
        if (!authToken  || userType === 'regular') {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
        if (onboarding === null) {
            setTimeout(() => {
                this.setState({ openOnboarding: true });
                this.nextOnBoarding();
            }, 500)
        }
    };

    // Customizable Area End

    // web events

    // Customizable Area Start

    keyContentDataGet = async () => {
        const token = await getStorageData("authToken");

        const header = {
            "Content-Type": configJSON.contactUsApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.keycontentdataApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.keycontentEndpoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    keyDatasetUp = (responseJson: object[]) => {
        const keyGetData = responseJson;
        this.setState({ keycontacts: keyGetData })
    };


    nextOnBoarding = () => {
        const { onBoarding1, currentStep } = this.state;
        if (onBoarding1.length > currentStep) {
            this.changeStep2();
        }
        else {
            if (this.state.openOneScreenOnboarding) {
                const message = new Message(getName(MessageEnum.NavigationMessage));
                message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(message);
            }
        }
    }

    changeStep2 = () => {
        const { currentStep, onBoarding1 } = this.state;
        this.setState({
            onBoardingTitle: onBoarding1[currentStep].onboarding_title,
            onBoardingText: onBoarding1[currentStep].onboarding_description,
            transformHorizontal: onBoarding1[currentStep].transformHorizontal,
            anchorHorizontal: onBoarding1[currentStep].anchorHorizontal,
            currentStep: currentStep + 1
        })
        const targetRef = this.state.onboardingRefs[currentStep];
        if (targetRef && targetRef.current) {
            targetRef.current.scrollIntoView({
                block: 'center',
            });
        }
    }

    skipOnBoarding = async () => {
        this.setState({
            openOnboarding: false,
            currentStep: 0
        })
        await setStorageData("onBoarding", "true");
    }

    // Customizable Area End
}
