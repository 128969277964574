import React, { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';
import { activityRight, symptoRight,medicationRightImage } from "./assets";
// Customizable Area Start

interface OnboardingStep {
    id: number;
    onboarding_title: string;
    onboarding_description: string;
    transformHorizontal: string;
    anchorHorizontal: string;
  }

interface Mood {
    id: number;
    mood_name: string;
    mood_icon: string;
    selected: boolean;
}

interface Physical {
    id: number;
    activity_name: string;
    activity_icon: string;
    selected: boolean;
}
export interface ResponseDate {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "report_name": string,
        "report_date": string,
        "report_type": string,
        "phenylalanine_level": string,
        "tyrosine_level": string,
        "account_id": number,
        "created_at": string,
        "updated_at": string,
        "report_notes": string,
        "mood":string[],
        "physical_activities":string[],
        "health_symptoms": string[],
        "attached_files": {
            "data": [
                {
                    "id": string,
                    "type": string,
                    "attributes": {
                        "id": number,
                        "file": string
                    }
                }
            ]
        }
    }
}

export interface IncomingData {
    "id": string,
    "type": string,
    "attributes": {
        "date": string,
        "title": string,
        "all_day": boolean,
        "is_reminder": boolean,
        "repeat": string,
        "notes": string,
        "address": string,
        "start_time": string,
        "end_time": string,
        "event_key": string,
        "add_to_calender": boolean,
        "platform": null,
        "calender_id": null,
        "Reminders": [],
    }
};

export interface TretReminder {
    id?: string,
    type?: string,
    attributes: {
        id?: number,
        treatment_name: string,
        treatment_type: string,
        times_taken_per_day: number,
        status?: string,
        intake_end_date: boolean,
        date?:string,
        end_time?: string,
        account_id?: number,
        created_at?:string,
        updated_at?: string,
        reminder_times: {
            data:ReminderTime []
        }
    }
}

export interface ReminderTime {
    attributes: {
      id?:number
      time?: string| undefined,
      name?: string| undefined,
      status?:string| undefined,
      _destroy?: boolean | undefined,
    };
}
  
interface TreatmentData {
    type: string; 
    reminders: { name: string, time?: string }[];
    date: string;
    intake_end_date?:boolean
    treatmentname:string 
    end_time:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading:boolean;
    moments:(dateString: string | undefined, formatString: string | undefined) => moment.Moment;
    pastDate:string
    activitydata: Physical[];
    moodsdata: Mood[];
    idSet:string,
    logbookDate: string;
    selectedActivities: number[];
    selectedMoods: number[];
    isAddingActivity: boolean;
    addActivityValue: string;
    isActivityAdded: boolean;
    addSymptoms: boolean;
    newSymptos: string;
    symptosData: string[];
    symptElementIndex: null | number;
    reportsData: ResponseDate[];
    endpointDate: string
    addNewActivities: string[];
    activitiesModelValue: string;
    styCss:string;
    upcomingEvent: IncomingData[];
    treatmentPlusImage: boolean;
    selectMenu:string;
    currentData:string,
    treatmentData: TreatmentData,
    intakeDatatoogle: boolean,
    refillallData : {frequency: string, reminder: string, date: string},
    refillinput:string ,
    logbookApplyButton:boolean,
    divDisable:boolean,
    apply:boolean,
    manageReminder:boolean,
    combineTreatmentData:TretReminder[],
    dataSavelogbook:boolean,
    linkPath:string,
    editModalReminder : boolean,
    initialSingalReminder:TretReminder[],
    logbookSaveEditButton:boolean,
    editEventSave:boolean,
    dateEventSave: string,
    statusActive:boolean,
    deleateModal:boolean,    
    onBoarding: OnboardingStep[];
    openOnboarding: boolean;
    onBoardingTitle: string;
    onBoardingText: string;
    onBoardingRef: string;
    currentStep: number;
    anchorHorizontal: string;
    transformHorizontal: string;
    openOneScreenOnboarding: boolean,
    onBoardingBtnDisable: boolean,
    prevEndTime: string | undefined,
    prevToggle: boolean | null,
        // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    selectStatusReminderApiCallid : string = "";
    selecteddataApiCallid: string = "";
    selectedputApiCallid: string = "";
    selectedpostApiCallid: string = "";
    addreminderpostApiCallid: string = "";
    addreminderputApiCallid: string = "";
    addstatusputApiCallid: string = "";
    getreminderGetApiCallid: string = "";
    getdeletereminderApicallid: string = "";
    onboardingRefs: React.RefObject<HTMLDivElement>[];
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];
        this.onboardingRefs = Array.from({ length: 7 }, () => createRef());
        // Customizable Area Start


        this.state = {
            // Customizable Area Start
            isLoading:false,
            currentData:'',
            moments:moment,
            pastDate:'',
            activitydata: [],
            moodsdata: [],
            logbookDate: "",
            selectedActivities: [],
            selectedMoods: [],
            idSet:'',
            isAddingActivity: false,
            addActivityValue: "",
            isActivityAdded: false,
            addSymptoms: false,
            newSymptos: "",
            symptosData: [],
            symptElementIndex: null,
            reportsData: [],
            endpointDate: "",
            addNewActivities: [],
            activitiesModelValue: '',
            styCss:'wordWrap',
            upcomingEvent: [],
            treatmentPlusImage : false,
            selectMenu : '',
            treatmentData: { type: "medication", reminders: [{ name: "Reminder 1" , time:"01:00"}], date: "" ,treatmentname:'',end_time:""},
            combineTreatmentData:[],
            intakeDatatoogle: false,
            refillallData: {frequency: 'Monthly', reminder: "01:00", date: "" },
            refillinput : "",
            logbookApplyButton:true,
            divDisable:false,
            manageReminder:false,
            apply:false,
            dataSavelogbook:false,
            linkPath:'',
            editModalReminder:false,
            initialSingalReminder:[],
            logbookSaveEditButton:true,
            editEventSave:false,
            dateEventSave:"this_and_following_reminders",
            statusActive:true,
            deleateModal:false,
            onBoarding: [{
                "id": 1,
                "onboarding_title": "Step 1",
                "onboarding_description": "Select Date: Click here to open the calendar and choose a date. You can also use the keyboard arrow keys to navigate through the calendar. Press 'Enter' to confirm your selection.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 2,
                "onboarding_title": "Step 2",
                "onboarding_description": "Add Physical Activity: Click here to log your exercise.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 3,
                "onboarding_title": "Step 3",
                "onboarding_description": "Add Your Mood: Click here to select and record your current mood. Choose from the options to reflect how you're feeling today.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 4,
                "onboarding_title": "Step 4",
                "onboarding_description": "Log Health Symptoms: Click to record "+"a"+"n"+"y"+" symptoms you're experiencing.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 5,
                "onboarding_title": "Step 5",
                "onboarding_description": "Add Treatment & Set Reminders: Click here to input your medication details. Set reminders for timely doses to stay on track with your health routine.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            },
            {
                "id": 6,
                "onboarding_title": "Step 6",
                "onboarding_description": "View/Add Blood Reports: Click here to access your blood reports. Upload new reports for comprehensive health tracking.",
                "transformHorizontal": "left",
                "anchorHorizontal": "left",
            },
            {
                "id": 7,
                "onboarding_title": "Step 7",
                "onboarding_description": "View Upcoming Events: Click here to check your upcoming events, including scheduled doctor's appointments. Stay organized and never miss an important medical visit.",
                "transformHorizontal": "right",
                "anchorHorizontal": "right",
            }
            ],
            openOnboarding: false,
            onBoardingTitle: "",
            onBoardingText: "",
            onBoardingRef: "",
            currentStep: 0,
            anchorHorizontal: "right",
            transformHorizontal: "right",
            openOneScreenOnboarding: true,
            onBoardingBtnDisable: false,
            prevEndTime:""
            ,
            prevToggle: null,
                    }
        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area End
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch(apiRequestCallId){
                    case this.selecteddataApiCallid:
                        this.dateResponse(responseJson);
                        break;
                    case this.selectedputApiCallid:
                        break;
                    case this.selectStatusReminderApiCallid:
                        this.getDataAddReminder();
                        break;  
                    case this.selectedpostApiCallid:
                        break;
                    case this.addreminderpostApiCallid:
                        this.getDataAddReminder();
                        break;
                    case this.addstatusputApiCallid:
                            this.getDataAddReminder();
                            break;
                    case this.getreminderGetApiCallid:
                            this.reminderGetData(responseJson);
                            break;
                    case this.addreminderputApiCallid:
                        this.getDataAddReminder()
                            break;
                    case this.getdeletereminderApicallid:
                            this.getDataAddReminder();
                            break;
                      }
            }
        }

    };

    async componentDidMount() {
        this.setState({
            treatmentData: {
                ...this.state.treatmentData,
                reminders: [{ name: "Reminder 1", time: this.getConvertor()[0] }]
            }
        });
        this.handlelogbookDatePicker(new Date());
        this.selectDatasetUp();
        this.getDataAddReminder();
        let onboarding = await getStorageData("onBoarding");
        let authToken = await getStorageData("authToken");
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }

        if (onboarding === null) {
            this.setState({ openOnboarding: true });
            this.nextOnBoarding();
        }
    };

    componentDidUpdate(){
        this.getConvertor()[0]
        if ((!this.state.symptosData.includes("No Symptoms") && !this.state.symptosData.includes("Add Symptoms")) && this.state.symptosData.length > 0) {
            this.setState(prevState => ({
                symptosData: [...prevState.symptosData, "Add Symptoms"],
            }))
        }  
    };

    // Customizable Area End

    // web events

    // Customizable Area Start

    deleteReminderApi = async() =>{
        const deleteIdset = this.state.initialSingalReminder[0].id;
        const deleteType = this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true ? this.state.dateEventSave :"this_reminder_only"
        const token = await getStorageData("authToken");
        const header = {
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getdeletereminderApicallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reminderEndPoint}/${deleteIdset}?update_type=${deleteType}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
        this.setState({deleateModal:false})
    };

    remnderStatusPutApi = async(iddata:string | undefined) =>{
        const reminderIndex = this.state.combineTreatmentData.findIndex((reminde) => reminde.id === iddata);
        if (reminderIndex !== -1) {
            const reminder = this.state.combineTreatmentData[reminderIndex];
            const newStatus = reminder.attributes.status === "active" ? "in_active" : "active";
      
            const updatedReminder = {
              ...reminder,
              attributes: {
                ...reminder.attributes,
                    status: newStatus,
                },
            };

            const updatedData = [...this.state.combineTreatmentData];
            updatedData[reminderIndex] = updatedReminder;

            this.setState({
                combineTreatmentData: updatedData,
            });
            const token = await getStorageData("authToken");
            const header = {
                token: token,
            };
            let formdata = new FormData();
            if (iddata !== undefined) {
                formdata.append("id", iddata);
                formdata.append("status", newStatus);
              }

            const requestMessageList = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.addstatusputApiCallid = requestMessageList.messageId;
            requestMessageList.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putApiMethod
            );
            requestMessageList.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.reminderStatusEndPoint}`
            );
            requestMessageList.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata
            );
            requestMessageList.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            runEngine.sendMessage(requestMessageList.id, requestMessageList);

        }

    };

    getDataAddReminder = async () => {
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getreminderGetApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reminderNewEndPoint}?date=${this.state.endpointDate}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    nextOnBoarding = () => {
        const { onBoarding, currentStep } = this.state;
        if (onBoarding.length > currentStep) {
            if(currentStep === 6){
                this.changeStep();
                this.setState({ onBoardingBtnDisable: true });
            }
            else{
                this.changeStep();
            }
        }
        else {
            this.setState({
                openOnboarding: false
            });
            this.resetDataFunction();
            this.props.navigation.navigate('EventsSchedule');
        }
    }

    handleOpneOnboarding = () => {
        this.setState({
            openOnboarding: true,
            openOneScreenOnboarding: false,
            currentStep: 0,
          }, () => this.nextOnBoarding());
    }

    skipOnBoarding = () => {
        this.setState({
            openOnboarding: false,
            currentStep: 0
        })
        this.resetDataFunction();
        setStorageData("onBoarding", "true")
    }

    resetDataFunction = () => {
        this.setState({
          openOnboarding: false,
          openOneScreenOnboarding: true,
          onBoardingBtnDisable: false,
        });
      }

    changeStep = () => {
        const {  currentStep, onBoarding } = this.state;
        this.setState({
          onBoardingTitle: onBoarding[currentStep].onboarding_title,
          onBoardingText: onBoarding[currentStep].onboarding_description,
          transformHorizontal: onBoarding[currentStep].transformHorizontal,
          anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
          currentStep: currentStep + 1
        })
        const targetRef = this.onboardingRefs[currentStep];
        if (targetRef && targetRef.current) {
          targetRef.current.scrollIntoView({
            block: 'center',
          });
        }
    
      }

    selectDatasetUp = async () => {
        this.setState({isLoading:true})
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selecteddataApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.logBookDateEndPoint}?date=${this.state.endpointDate}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    updatetDatasetUp = async () => {
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
       
        const combineBodyData = [...this.state.selectedActivities, ...this.state.addNewActivities]
        const httpBody = {data:{
            physical_activities:combineBodyData,
            mood:this.state.selectedMoods,
            health_symptoms:this.state.symptosData.filter((noele) => noele !== "Add Symptoms")
          }};
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selectedputApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.logBookDateEndPoint}/${this.state.idSet}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    updatetPostDatasetUp = async () => {
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
       
        const combineBodyDatapost = [...this.state.selectedActivities, ...this.state.addNewActivities]
        const httpBodypost = {data:{
            date:this.state.endpointDate,
            physical_activities:combineBodyDatapost,
            mood:this.state.selectedMoods,
            health_symptoms:this.state.symptosData.filter((noele) => noele !== "Add Symptoms")
          }};
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selectedpostApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.logBookDateEndPoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBodypost)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    editPostReminder = async(value: {
        attributes: {
            treatment_name: string,
            treatment_type:string,
            times_taken_per_day: number,
            intake_end_date: boolean | undefined,
            date: string,
            end_time?:string,
            reminder_times: {
                data: {
                    attributes: {
                        time?: string | undefined;
                        name: string;
                    };
                }[];
            }
        }
    }) =>{
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };

        const newReminder=value.attributes.reminder_times.data.map((values)=>{
            return {name:values.attributes.name,time:values.attributes.time,date:moment(value.attributes.date).format('DD/MM/YYYY')}
        })

        const postNewData= {
            "data":{
                "treatment_name": value.attributes.treatment_name,
                "treatment_type": value.attributes.treatment_type, 
                "date":moment(value.attributes.date).format('DD/MM/YYYY'),
                "times_taken_per_day": value.attributes.times_taken_per_day,
                "intake_end_date": value.attributes.intake_end_date,
                "end_time": value.attributes.end_time?moment(value.attributes.end_time).format('DD/MM/YYYY'):'', 
                "reminder_times_attributes":newReminder
            }
        }
        
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addreminderpostApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reminderEndPoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(postNewData)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    handlelogbookDatePicker = (valueDate: Date) => {
        this.setState({pastDate:valueDate.toISOString()})

        this.setState(prevState => ({
            currentData:valueDate.toISOString().split('T')[0],
            treatmentData: {
              ...prevState.treatmentData,
              date: valueDate.toISOString().split('T')[0],
              end_time:valueDate.toISOString().split('T')[0]
            }
          }));
        this.dateCheckDiv(valueDate)
        const utcDate = new Date(valueDate);
        const utcDateString = utcDate.toISOString();
        this.setState({ endpointDate: new Date(utcDate).toLocaleDateString('en-GB') })
        const nthNumber = (number: number) => (number > 3 && number < 21 ? 'th' : number % 10 === 1 ? 'st' : number % 10 === 2 ? 'nd' : number % 10 === 3 ? 'rd' : 'th');

        let formattedDate = new Date(utcDateString)
            .toLocaleDateString('en-GB', configJSON.curdate);
        formattedDate = formattedDate.replace(/\b(\d{1,2})\b/g, (match, newDay) => {
            return match + nthNumber(parseInt(newDay));
        });
        formattedDate = formattedDate.replace(/(\w{3}) (\d{4})/, "$1, $2");
        this.setState({ logbookDate: formattedDate ,logbookApplyButton:true})
        this.selectDatasetUp();
        this.getDataAddReminder();
    };

    dateCheckDiv = (chekdd:Date) =>{
        const selectedDates =chekdd 
        const currentDates = new Date();
        const divsDisableds = new Date(selectedDates) > currentDates;
        this.setState({ divDisable : divsDisableds });
    }
    
    getDivsClassNames = () => {
        return this.state.divDisable ? "disableddiv" : "";
    };

    reminderGetData = (responsejson:{data:TretReminder[]}) =>{
        this.setState({
            combineTreatmentData: responsejson.data ?? []
        })
    };

    dateResponse = (responsejson: { data: {id:string, attributes: { reports: { data: ResponseDate[] },upcoming_events:{data:IncomingData[]} ,mood:{id:number; mood_name: string; mood_icon: string; selected: boolean;}[],physical_activities:{id:number; activity_name: string; activity_icon: string; selected: boolean;}[],health_symptoms:string[],treatment_reminders:{data:TretReminder[]} }} }) => {
              this.setState({idSet:responsejson.data.id?responsejson.data.id:''})
       const moodSelecteddata = responsejson.data.attributes.mood;
       const dynamicData= responsejson.data.attributes.physical_activities??[]

       const activityImagedat = dynamicData.filter((imagescheck) =>  imagescheck.id !== undefined)
       const activityImageDatId = activityImagedat?.filter((activity)=> activity?.selected).map((activity) => activity.id )
       const activitiesWithoutId = dynamicData.filter((activity) => activity.id === undefined).map((activitymname) => activitymname.activity_name);

       const moodSelected = moodSelecteddata?.filter((checkMood) => checkMood.selected).map((checkMood) => checkMood.id) 

       
              this.setState({ reportsData: responsejson.data.attributes.reports.data, upcomingEvent:responsejson.data.attributes.upcoming_events.data,
            addNewActivities:activitiesWithoutId??[],
            activitydata : activityImagedat??[],
            selectedMoods: moodSelected??[],
            moodsdata:moodSelecteddata ?? [],
            isActivityAdded:activitiesWithoutId?.length>0,
            selectedActivities:activityImageDatId,
            symptosData:responsejson.data.attributes.health_symptoms??[],
        })
        setTimeout(() => {this.setState({isLoading:false})
          }, 1000);
    
    };

    handleActivitySelect = (activityId: number) => {
        const { selectedActivities, activitydata } = this.state;

        const addactiveId = activitydata.find((activity) => activity?.activity_name === 'Add Activity')?.id
        if (activityId === addactiveId) {
            this.setState({ isAddingActivity: true });
        }
        else {
            const isSelected = selectedActivities.includes(activityId);
            if (isSelected) {
                this.setState({
                    selectedActivities: selectedActivities.filter(activity => activity !== activityId),
                });
            } else {
                this.setState(prevState => ({
                    selectedActivities: [...prevState.selectedActivities, activityId],
                }))
            }
        }
    };

    activitySelected = (nameActivity: number) => {
       
        this.setState({logbookApplyButton:false})
        const combineData = [...this.state.selectedActivities, ...this.state.addNewActivities]
        const { selectedActivities,activitydata } = this.state;

        const noactiveId = activitydata.find((activity) => activity?.activity_name === 'No Activity')?.id;

        if (nameActivity === noactiveId) {
            combineData.length > 0 && !combineData.includes(noactiveId) ? this.setState({ selectedActivities: selectedActivities }) : this.setState({ selectedActivities: combineData.includes(noactiveId) ? [] : [nameActivity] })
        } else {
            !this.state.selectedActivities.includes(Number(noactiveId)) && this.handleActivitySelect(nameActivity)
        }
    };

    completeClosed = () => {
        this.setState({ isAddingActivity: false, activitiesModelValue:'' });
    };

    handleActivityChange = (actvalue: { target: { value: string } }) => {
        this.setState({
            logbookApplyButton:false,
            activitiesModelValue: actvalue.target.value
        })
    };

    inlinehandleActivityChange = (actvalue: { target: { value: string } }) => {
        this.setState({logbookApplyButton:false})
        if (actvalue.target.value.trim() !== '') {
            let newvalue = actvalue.target.value;
            if(!actvalue.target.value.endsWith(',')){
            newvalue = actvalue.target.value.replace(/,\s*/g, ', ');
             }
            const activityArray = newvalue.split(', ').map(activity => {

                const words = activity.trim().split(/\s+/);
                const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return capitalizedWords.join(' ');

            });
            this.setState({ addNewActivities:  activityArray});
           
        }else{
            this.setState({ addNewActivities: [],isActivityAdded: false,});
        }
        };

        activeStyleBorder = (activity_id: number) => {
            const {activitydata} = this.state;
            const noactiveId = activitydata.find((activity) => activity?.activity_name === 'No Activity')?.id
            const combineData = [...this.state.selectedActivities, ...this.state.addNewActivities]
            const restOne = {
                opacity: 0.5,
                cursor: 'not-allowed'
            }
            return combineData.length > 0 ?
            activity_id !== noactiveId && combineData.includes(Number(noactiveId)) ? restOne : activity_id === noactiveId && !combineData.includes(noactiveId) ? restOne : {}
                : {}
        }
        activityButtons = (activity_id: number) => {
            const {activitydata} = this.state;
            const addactiveId = activitydata.find((activity) => activity?.activity_name === 'Add Activity')?.id
            const combineData = [...this.state.selectedActivities, ...this.state.addNewActivities]

            const getName = this.state.activitydata.map((value: { id: number }) => value.id)
            const styleActive = combineData.length > 0 ? combineData.every((activity) => !getName.includes(Number(activity))) : false
            const filteredData = combineData.filter((item) => !getName.includes(Number(item)));
            return combineData.includes(activity_id) ? 'selected' : this.state.isAddingActivity && activity_id === addactiveId ? 'selected' :
                styleActive && activity_id === addactiveId ? 'selected' : activity_id === addactiveId && styleActive ? 'selected' :
                    !!filteredData.length && activity_id === addactiveId ? 'selected' : 'notSelected';
        };

        activityImage = (activity_id: number) => {
            const {activitydata} = this.state;
            const addactiveId = activitydata.find((activity) => activity?.activity_name === 'Add Activity')?.id
            const combineData = [...this.state.selectedActivities, ...this.state.addNewActivities]
            const getNameImg = this.state.activitydata.map((value: { id: number }) => value.id)
            const styleActiveImg = combineData.length > 0 ? combineData.every((activity) => !getNameImg.includes(Number(activity))) : false
            const filteredDataImg = combineData.filter((item) => !getNameImg.includes(Number(item)));
            const imgDot = <img style={{ position: "absolute", right: "0px", bottom: "0px" }} src={activityRight} />

            return combineData.includes(activity_id) ? imgDot : this.state.isAddingActivity && activity_id === addactiveId ? imgDot :
                styleActiveImg && activity_id === addactiveId ? imgDot : activity_id === addactiveId && styleActiveImg ? imgDot :
                    !!filteredDataImg.length && activity_id === addactiveId ? imgDot : '';
        };

        addActivity = () => {
            this.setState({logbookApplyButton:false})
            const {  activitiesModelValue } = this.state;
            if (activitiesModelValue.trim() !== '') {
                const addnew = activitiesModelValue.includes(',')
                const activityArray = activitiesModelValue.split(',').map(activity => {

                    const words = activity.trim().split(/\s+/);
                    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                    return capitalizedWords.join(' ');

                });
                const words = activitiesModelValue.trim().split(/\s+/);;
                const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
                this.setState(prevState => {
                    return ({
                        addNewActivities: addnew ? [...prevState.addNewActivities, ...activityArray] : [...prevState.addNewActivities, capitalizedWords],
                        isAddingActivity: false,
                        isActivityAdded: true,
                        activitiesModelValue: '',
                    })
                })

            } else {
                this.setState({ isAddingActivity: false, })

            }
        };

    addactRemoveValue = () => {
        this.setState({ addActivityValue: '', activitiesModelValue: '',logbookApplyButton:false });
    };

    makeTime = () => {
        return Array.from({ length: 12 * 24 }, (values, indno) => indno).map((item, indno) => {
            const hours = Math.floor(indno / 12);
            const minutes = (indno % 12) * 5;
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            return formattedTime
        })
    }

    getConvertor=()=>{ 
    const makeTime = this.makeTime();
    if (this.state.intakeDatatoogle) {
      return makeTime;
    } else if(this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true){
        return makeTime;
    }else{
      const getNextTimes = this.getNextTimes(makeTime);
      return makeTime.slice(makeTime.indexOf(getNextTimes as string), makeTime.length);
    }
    }
     findNextValue=(times: string[], target: string)=> {
        const targetIndex = times.indexOf(target);
      
        if (targetIndex !== -1 && targetIndex < times.length - 1) {
        
          return times[targetIndex + 1];
        } else {
         
          return undefined;
        }
      }

    getFutureTime=(currentTime?:string)=>{ 
        const makeTime = this.makeTime();
        const getNextTimes = this.getNextTimes(makeTime);
        return makeTime.slice(makeTime.indexOf(currentTime as string), makeTime.length);
        }

    getNextTimes = (times: string[]) => {
        const currentTime = moment();
        const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();


        for (let time of times) {
            const [hours, minutes] = time.split(':');
            const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);
            if (timeInMinutes > currentMinutes) {
                return time;
            }
        }
        return null;
    };
    combineData = (item: { name?: string, time?: string }) => {
        return (moment(this.state.treatmentData.date).format("YYYY-MM-DD") == moment().format('YYYY-MM-DD')&&!this.state.intakeDatatoogle) ? this.getFutureTime(item.time) : this.makeTime()
    }
    combineUpdateData = (date?:string ) => {
        return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')== moment().format('YYYY-MM-DD') ? this.findNearestTime(this.makeTime()) :''
    }
    checkValidation=(formattedTimeedit:string,newCombineUpdateData:string,intake_end_date?:boolean,item?:ReminderTime)=>{
        return (formattedTimeedit<=newCombineUpdateData&&!intake_end_date)||item?.attributes.time===formattedTimeedit
    }
    fixTime = () => {
        return Array.from({ length: 12 * 24 }, (values, indno) => indno).map((item, indno) => {
            const hours = Math.floor(indno / 12);
            const minutes = (indno % 12) * 5;
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            return formattedTime
        })
    }
     getCurrentTime=()=> {
        const date = new Date();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      }
       findNearestTime=(times: string[])=> {
        const currentTime = this.getCurrentTime();
        const currentTimeMs = new Date(`1970-01-01T${currentTime}:00Z`).getTime();
      
        return times.reduce((prev, curr) => {
          const currTimeMs = new Date(`1970-01-01T${curr}:00Z`).getTime();
          const prevTimeMs = new Date(`1970-01-01T${prev}:00Z`).getTime();
      
          return Math.abs(currTimeMs - currentTimeMs) <= Math.abs(prevTimeMs - currentTimeMs) ? curr : prev;
        });
      }
        renderActivityRemoveValue = () => {
            
            this.setState ({
                logbookApplyButton:false,
                addNewActivities: [],
                isActivityAdded: false,
            });
        };

        handleMoodsSelect = (moodsvalue: number) => {
            this.setState({logbookApplyButton:false})
            const linkData =this.state.moodsdata.map((checkMood)=>{return checkMood.id==moodsvalue?{...checkMood,selected:!checkMood.selected}:{...checkMood}})
            const selectedMoods = linkData.filter((checkMood) => checkMood.selected).map((checkMood) => checkMood.id);
            this.setState({
                moodsdata:linkData,
                selectedMoods:selectedMoods
            });
        };

        addSymptosdataSet = (symData:string) =>{
            this.setState({logbookApplyButton:false})
            if( (this.state.symptosData.length == 0 || ((this.state.symptosData.length == 1) && this.state.symptosData.includes("Add Symptoms")))){
                if (symData === "Add Symptoms" && !this.state.symptosData.includes("No Symptoms")) {
                    this.setState({ addSymptoms: true })  
                    if (this.state.symptosData.length == 0 || ((this.state.symptosData.length == 1) && this.state.symptosData.includes(symData))){
                    this.setState((prevState) => ({
                        symptosData: prevState.symptosData.includes(symData) ? prevState.symptosData.filter(item => item !== symData) : [...prevState.symptosData, symData]
                      }));
                    }
                }
    
                this.setState((prevState) => ({
                    symptosData: prevState.symptosData.includes("No Symptoms") ? [...prevState.symptosData, "No Symptoms"] : prevState.symptosData.filter(item => item !== "No Symptoms")
                  }));
            }
        }; 

        symptosValue = (symvalue: { target: { value: string } }) => {
            this.setState({ logbookApplyButton:false, newSymptos: symvalue.target.value })
        };

        noSymptos =(nosymptosdata:string) =>{
            this.setState({logbookApplyButton:false})
            if(!this.state.symptosData.includes("Add Symptoms")){
                this.setState((prevState) => ({
                    symptosData: prevState.symptosData.includes(nosymptosdata) ? prevState.symptosData.filter(item => item !== nosymptosdata) : [...prevState.symptosData, nosymptosdata]
                  }));
            }
        };

        getSymRight = () => {
            return this.state.symptosData.includes("No Symptoms") ? (
              <img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} src={symptoRight} />
            ) : '';
        };
        
        getAddSymRight = () =>{
           return this.state.symptosData.includes("Add Symptoms") ? (
            <img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} src={symptoRight} />
          ) : '';
        };

        addSymptos = () => {
            this.setState({logbookApplyButton:false})
            const { newSymptos } = this.state;
            if (newSymptos.trim() !== '') {
                const symptosArray = newSymptos.split(',').map(activity => {
                    const wordsSymp = activity.trim().split(' ');
                    const capitalizedSympt = wordsSymp.map(wordsSymp => wordsSymp.charAt(0).toUpperCase() + wordsSymp.slice(1).toLowerCase());
                    return capitalizedSympt.join(' ');
                });

                this.setState(prevState => ({
                    symptosData: [...prevState.symptosData, ...symptosArray],
                    newSymptos: '',
                }));
            }
        };

        noSymDisable = (divDisable:string) =>{
            if(this.state.symptosData.includes(divDisable)){
                return "no_selected"
            } 
        };

        addActivityDisable = () =>{
            if(this.state.activitiesModelValue.length === 0){
                return "disable_button"
            } 
        };

        handleRemoveSym = (symIndex: number) => {
            this.setState({ logbookApplyButton:false, symptElementIndex: symIndex });
        };

        getBorderStyle = (symcolor: number) => {
            const { symptElementIndex } = this.state;
            if (symptElementIndex === symcolor) {
                return {
                    border: '1.5px solid #F87171',
                    background: "#FEF2F2",
                    color: "red"
                };
            }
        };

        symptosErrorText = (texterror: number) => {
            const { symptElementIndex } = this.state;
            if (symptElementIndex === texterror) {
                return {
                    color: "red",
                    fontFamily: "Raleway",
                    fontWeight: 450,
                };
            }
        };

        symptosErrorImage = (imageindex: number) => {
            const { symptElementIndex } = this.state;
            if (symptElementIndex === imageindex) {
                return {
                    backgroundColor: "#F87171",
                };
            }
        };

        symptosRemoveData = () => {
            this.setState({logbookApplyButton:false})
            const { symptElementIndex, symptosData } = this.state;
            if (symptElementIndex !== null) {
                const newSymptomsData = symptosData.filter((element, indexn) => indexn !== symptElementIndex);
                this.setState({ symptosData: newSymptomsData, symptElementIndex: null });
            }
        };

         formatDateString = (startTime: string, endTime: string): string => {
            const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
            const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
        
            const startDate: Date = new Date(startTime.slice(0, 19).replace('T', ' '));
            const endDate: Date = new Date(endTime.slice(0, 19).replace('T', ' '));
        
            const currentDate: Date = new Date();
        
            if (startDate.toDateString() === currentDate.toDateString()) {
                return `Today at ${startDate.toLocaleTimeString('en-US', timeOptions)} - ${endDate.toLocaleTimeString('en-US', timeOptions)}`;
            }
        
            const formattedDate: string = startDate.toLocaleDateString('en-GB', dateOptions)
                .replace(/\d+(st|nd|rd|th)?/, (newdayday) => newdayday)
                .replace(/(\s\d{1,2}\s\w{3})(\s\d{4})/, "$1,$2");
        
            const formattedStartTime: string = startDate.toLocaleTimeString('en-US', timeOptions);
            const formattedEndTime: string = endDate.toLocaleTimeString('en-US', timeOptions);
        
            return `${formattedDate} \u00B7 ${formattedStartTime} - ${formattedEndTime}`;
        };
        
        plusTreat = () =>{
            this.setState({treatmentPlusImage:true})
        };

    intakelDtaToogledata= (event:React.MouseEvent<HTMLElement>, type: string) => {
        this.setState({logbookSaveEditButton:false})
            this.setState({ intakeDatatoogle: !this.state.intakeDatatoogle })
    };

    handleChangeStartDate = (datevalue: string, indexone: number) => {
        const { treatmentData } = this.state;
        this.setState({
            treatmentData: {
                ...treatmentData,
                date: datevalue,
                end_time: datevalue
            }
        });
    };

    handleChangeINtakelDate = (enddatevalue:string , indexnum: number) => {
        const { treatmentData } = this.state;
          this.setState({
            treatmentData: {
              ...treatmentData,
              end_time: enddatevalue
            }
          });
    };

    validationButton = () => {
        const { treatmentData, intakeDatatoogle } = this.state
        const validateTimeSet = treatmentData.reminders.find((item: { time?: string }) => !!item.time);
        const validateTimefor =treatmentData.reminders.every((item: { time?: string }) => !!item.time);
        const switches = intakeDatatoogle ? !!treatmentData.end_time : true

        const combineCondation = !!treatmentData.treatmentname &&!!validateTimefor&& !!treatmentData.date && !!this.state.treatmentData?.reminders?.length && !!treatmentData.type && !!validateTimeSet?.time && switches
        if (!combineCondation) {
            return "disableTeat"
        }else{
            return ""
        }
    };

    saveAddTretment = () => {
        const { treatmentData,combineTreatmentData,intakeDatatoogle } = this.state

        const NewReminderDatatreatmentData = treatmentData.reminders.map((value: { time?: string; name: string }) => {
            return {
                attributes: value
            }
        })
        const capitalizeWords = (wordStr:string) => {
            return wordStr.split(' ').map(wordStr => wordStr.charAt(0).toUpperCase() + wordStr.slice(1)).join(' ');
        };
        const capitalizedTreatmentName = capitalizeWords(treatmentData.treatmentname);

        const tempData = {
            attributes: {
                treatment_name: capitalizedTreatmentName,
                treatment_type: treatmentData.type,
                times_taken_per_day: this.state.treatmentData?.reminders.length,
                intake_end_date: intakeDatatoogle,
                date: treatmentData.date,
                ...(this.state.treatmentData.end_time &&intakeDatatoogle&& {end_time:this.state.treatmentData.end_time}),
                reminder_times: {
                    data: NewReminderDatatreatmentData
                }
            }
        }
        this.editPostReminder(tempData)
        this.setState({ combineTreatmentData: [...combineTreatmentData, tempData],intakeDatatoogle:false, treatmentPlusImage: false, treatmentData: { type: "medication", reminders: [{ name: "Reminder 1" , time: this.getConvertor()[0]}], date: this.state.currentData ,treatmentname:'',end_time:this.state.currentData} })
    };

    editPutReminder = async(value: {
        attributes: {
            treatment_name: string,
            treatment_type:string,
            times_taken_per_day: number,
            intake_end_date: boolean | undefined,
            date: string|undefined,
            end_time?:string
            reminder_times: {
                data: {
                    attributes: {
                        id?: number,
                        name?: string,
                        status?: string,
                        time?: string,
                        _destroy?: boolean | undefined,
                    };
                }[];
            }
        }
    }) =>{
        let dataputType;
        if(moment(this.state.initialSingalReminder[0].attributes.end_time,'DD-MM-YYYY').format('DD/MM/YYYY') !== moment(this.state.prevEndTime,'DD-MM-YYYY').format('DD/MM/YYYY') || JSON.stringify(this.state.initialSingalReminder[0].attributes.intake_end_date) !== JSON.stringify(this.state.prevToggle)){
            dataputType = 'this_and_following_reminders'
        } else {
            dataputType = this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true ? this.state.dateEventSave :"this_reminder_only"
        }
        
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };

        const newReminder=value.attributes.reminder_times.data.map((values)=>{
            if(!values.attributes.id){
                return {name:values.attributes.name,time:values.attributes.time,date:value.attributes.date}
            }
            else if(values.attributes.id && !values.attributes._destroy){
                return {name:values.attributes.name,time:values.attributes.time,date:value.attributes.date, id:values.attributes.id}
            }else{
                return {name:values.attributes.name,time:values.attributes.time,date:value.attributes.date, id:values.attributes.id, _destroy: true}
            }
            
        })

        const postNewData= {
            "data":{
                "treatment_name": value.attributes.treatment_name,
                "treatment_type": value.attributes.treatment_type, 
                "date":value.attributes.date,
                "times_taken_per_day": value.attributes.times_taken_per_day,
                "intake_end_date": value.attributes.intake_end_date,
                ...(value.attributes.end_time &&{end_time:moment(value.attributes.end_time,'DD-MM-YYYY').format('DD/MM/YYYY')}),
                "reminder_times_attributes":newReminder
            }
        }
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addreminderputApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reminderEndPoint}/${this.state.initialSingalReminder[0].id}?update_type=${dataputType}`
        )
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(postNewData)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    saveEditdataPut = () =>{
        const {combineTreatmentData,initialSingalReminder } = this.state
        const capitalizeWords = (wordStr:string) => {
            return wordStr.split(' ').map(wordStr => wordStr.charAt(0).toUpperCase() + wordStr.slice(1)).join(' ');
        };
        const capitalizedTreatmentName = capitalizeWords(initialSingalReminder[0].attributes.treatment_name);
        const newFind=initialSingalReminder[0].attributes.intake_end_date
        let tempPutData = {
            attributes: {
                treatment_name: capitalizedTreatmentName,
                treatment_type: initialSingalReminder[0].attributes.treatment_type,
                times_taken_per_day: initialSingalReminder[0].attributes.times_taken_per_day,
                intake_end_date:newFind,
                date: moment(initialSingalReminder[0].attributes.date,'DD-MM-YYYY').format('DD/MM/YYYY'),
                reminder_times: {
                    data: initialSingalReminder[0].attributes.reminder_times.data
                }
            }
        } as  {attributes: {
            treatment_name: string;
            treatment_type: string;
            times_taken_per_day: number;
            intake_end_date: boolean;
            end_time: string;
            date: string;
            reminder_times: {
                data: ReminderTime[];
            };
        };}
        if(moment(this.state.initialSingalReminder[0].attributes.end_time,'DD-MM-YYYY').format('DD/MM/YYYY') !== moment(this.state.prevEndTime,'DD-MM-YYYY').format('DD/MM/YYYY') || JSON.stringify(this.state.initialSingalReminder[0].attributes.intake_end_date) !== JSON.stringify(this.state.prevToggle)){
            tempPutData = {...tempPutData, attributes: {...tempPutData.attributes, end_time: moment(initialSingalReminder[0].attributes.end_time,'DD-MM-YYYY').format('DD/MM/YYYY')}}
        }
                this.editPutReminder(tempPutData)
        
        this.setState({ combineTreatmentData: [...combineTreatmentData, tempPutData], refillallData: { frequency: 'Monthly', reminder: "01:00", date: "" }, treatmentPlusImage: false, editEventSave:false,dateEventSave:"this_and_following_reminders" })
    
    };

    saveEditdata = () =>{
         if(this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true){
            this.setState({editEventSave:true,editModalReminder:false})
        }else{
            this.setState({editModalReminder:false})
            this.saveEditdataPut()
        }
    };

    handleChangeValue = (event:  React.ChangeEvent<{ name?: string; value: unknown |string}>, child?: React.ReactNode) => {
        const newDataadd = { ...this.state.treatmentData };
        this.setState({ treatmentData: { ...newDataadd, [event.target.name as string]: event.target.value } });
 
    };
     
    handleChangeTreatData = (event: React.ChangeEvent<{ value: unknown |number}>, child: React.ReactNode) => {
        let newDataadd:{ name: string, time?: string }[] = [...this.state.treatmentData?.reminders]
        const preDatas = [...this.state.treatmentData.reminders]
        const { value } = event.target
        const setDateReminder = moment().format('YYYY-MM-DD') === this.state.treatmentData?.date

        if (newDataadd?.length >= Number(value)) {
            newDataadd = newDataadd.slice(0, Number(value))
        } else {
            const ConNum = Number(value) - newDataadd?.length
            for (let indexMax = 0; indexMax < ConNum; indexMax++) {
                const newTime=this.getConvertor()[indexMax+1]
                newDataadd.push({ name: `Reminder ${indexMax + 1 + preDatas.length}`, time:setDateReminder ? newTime: `01:00`})
            }
        }
        this.setState({ treatmentData: { ...this.state.treatmentData, reminders: newDataadd } });
    };

    handleEditChangeValue = (event:  React.ChangeEvent<{ name?: string; value: unknown |string}>, child?: React.ReactNode) => {
        this.setState({logbookSaveEditButton:false})
        const newDataaddedit = { ...this.state.initialSingalReminder[0] };
        const newDataaddAttributes = { ...this.state.initialSingalReminder[0].attributes };
        this.setState({ initialSingalReminder: [{ ...newDataaddedit, attributes:{...newDataaddAttributes,[event.target.name as string]: event.target.value} } ]});
    };

    editTakeFilterTime = (date?: string, intake_end_date?: boolean, item?: ReminderTime) => {
        const makingData = this.makeTime().filter((value: string) => {
            const newCombineUpdateData = this.combineUpdateData(date)
            const checkValidation = this.checkValidation(value, newCombineUpdateData, intake_end_date, item)
            return !checkValidation && value
        })
        !makingData.includes(item?.attributes?.time as string) ? makingData.unshift(item?.attributes?.time as string) : makingData
        return makingData
    }

    handleEditChangeTreatData = (event: React.ChangeEvent<{ value: unknown |number}>, child: React.ReactNode) => {
        this.setState({logbookSaveEditButton:false})
        let editreminders = this.state.initialSingalReminder[0].attributes.reminder_times.data
        const preDatas = [...this.state.initialSingalReminder[0].attributes.reminder_times.data]
        const { value } = event.target
        const newData = this.state.initialSingalReminder[0].attributes.reminder_times.data.map((value: ReminderTime) => {
            return value.attributes.time
        })
        const newDatas=newData[newData.length-1] 
        const findNonTargetTimes=this.findNextValue(this.makeTime(),newDatas||'')
    
        if (editreminders.filter(item => !item.attributes._destroy)?.length >= Number(value)) {
            const updatedData = editreminders.filter(item => !item.attributes._destroy).map((reminder, reminderId) => {
                if( reminderId <  Number(value) ){
                    return reminder
                }else{
                return {...reminder, attributes : {...reminder.attributes, _destroy: true}}}
            })       
            editreminders = updatedData.filter(reminderFilter=> reminderFilter.attributes.id  || (!reminderFilter.attributes.id && !reminderFilter.attributes._destroy))
        } else {
          const newTimeUpdate=this.state.initialSingalReminder[0].attributes.date=== moment().format('DD-MM-YYYY')?this.combineUpdateData(moment().format('DD-MM-YYYY')):findNonTargetTimes
            const ConNum = Number(value) - editreminders.filter(item => !item.attributes._destroy)?.length
            for (let indexNum = 0; indexNum < ConNum; indexNum++) {
                editreminders.push({attributes:{name: `Reminder ${indexNum + 1 + preDatas.filter(item => !item.attributes._destroy)?.length}`, time: newTimeUpdate} })
            }
        }

        this.setState({ initialSingalReminder: [{ ...this.state.initialSingalReminder[0], attributes:{...this.state.initialSingalReminder[0].attributes,
            times_taken_per_day:Number(value),
            reminder_times: {...this.state.initialSingalReminder[0].attributes.reminder_times,
                data:editreminders}} } ]});
    };

     
    handleChangeEndtakelDate = (element: string, indexnum: number) => {
        this.setState({logbookSaveEditButton:false})
        let reminderEditNewEnd = this.state.initialSingalReminder[0]
        this.setState({
            initialSingalReminder: [{
                ...reminderEditNewEnd, attributes: {
                    ...reminderEditNewEnd.attributes,
                    end_time: element
                }
            }]
        });
    };

    validationEditModelButton = () => {
        const { initialSingalReminder } = this.state
        const iniAtt=initialSingalReminder[0].attributes
        const combineCondation = !!iniAtt.treatment_name
        if (!combineCondation) {
            return "disableTeat"
        }else{
            return ""
        }
    };

    addTimeTreat = (value:string,indexno:number) => {
        let newData = [...this.state.treatmentData.reminders]
        newData[indexno] = { ...newData[indexno], time: value }
        this.setState({ treatmentData: { ...this.state.treatmentData, reminders: newData } });
        
    };
    addTimeEditTreat = (value:string,indexno:number) => {
        this.setState({logbookSaveEditButton:false})
        let newData = [...this.state.initialSingalReminder[0].attributes.reminder_times.data]
        newData[indexno] = { ...newData[indexno], attributes:{...newData[indexno].attributes,time: value} }
        this.setState({ initialSingalReminder: [{ ...this.state.initialSingalReminder[0], attributes:{...this.state.initialSingalReminder[0].attributes,
            reminder_times: {...this.state.initialSingalReminder[0].attributes.reminder_times,
                data:newData}} } ]});
        
    };
    
    
    applybuttonColor = () =>{
        return this.state.logbookApplyButton ? "apply_button" :''
    };
    
    editSaveButtonColor = () =>{
        return this.state.logbookSaveEditButton ? "disableTeat" :''
    };

    applyCallputButton = () =>{
        this.setState({logbookApplyButton:true,apply:true}) 
        if(this.state.idSet){
            this.updatetDatasetUp()
         }else{
            this.updatetPostDatasetUp()
         }  
    };

    applyModalclosed = (propsdata:object) => {
            this.setState({apply:false})
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "Analytics");
            message.addData(getName(MessageEnum.NavigationPropsMessage), propsdata);
            this.send(message);
    };


    logBookDataClosedmodal = () =>{
        this.setState({dataSavelogbook:false,editEventSave:false})
    };

    discardChangeData = (disvalue:string) =>{
       if(disvalue === "Discard Changes"){
        this.setState({logbookApplyButton:true,dataSavelogbook:false,})
        this.props.navigation.navigate(this.state.linkPath);
       }else{
         this.setState({apply:true,dataSavelogbook:false,logbookApplyButton:true})
         if(this.state.idSet){
            this.updatetDatasetUp()
         }else{
            this.updatetPostDatasetUp()
         }
       }
    };

    formulaBorder = (formulatype:string) =>{
        return formulatype === "formula" ? "formulaborder" : "";
    };

    takenBackground = (takentype:string,setcolor:string | undefined) =>{
        return (takentype === "medication" && setcolor === "read")? "takenbackgroundcolor":(takentype === "formula" && setcolor === "read")?"mediactionbgcolor":'';
    };

    rightImageTretment = (readStatus: string | undefined, addtypeformula:string) => {
        return (readStatus === "read" && addtypeformula === "formula") ? <img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} src={symptoRight} /> : (readStatus === "read" && addtypeformula === "medication") ? <img style={{ position: "absolute", right: "-4px", bottom: "-2px" }} src={medicationRightImage} />: "";
    };
    
    addTretmentModelClosed = () =>{
        this.setState({ treatmentPlusImage: false,editModalReminder:false, treatmentData: { type: "medication", reminders: [{ name: "Reminder 1" ,  time: this.getConvertor()[0]}], date: this.state.currentData,treatmentname:"",end_time:this.state.currentData},intakeDatatoogle:false })
    };

    chnngesModal = (links:string) =>{
       this.setState({dataSavelogbook:true, linkPath:links})
    };

    addTretModalopen = () => {
      this.setState({treatmentPlusImage:true,manageReminder:false})
    };



    remnderEdit = (idsetup:string | undefined) =>{
        const {combineTreatmentData} = this.state;
        this.setState({manageReminder:false,editModalReminder:true})
        const addEditdata = combineTreatmentData.filter((datafil) =>  datafil.id === idsetup);
        this.setState({
            initialSingalReminder:addEditdata,prevEndTime: addEditdata[0].attributes.end_time, prevToggle: addEditdata[0].attributes.intake_end_date
        })
    };

    editDateFormate = (editremin:string | undefined) =>{
        const parts = editremin?.split('-');
        return parts&& `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    saveChangesModal = () =>{
        this.setState({dataSavelogbook:true})
    };

    manageReminderModal = () =>{
        this.setState({ manageReminder: true })
    };

    manageReminderClosedModal = () =>{
        this.setState({ manageReminder: false })
    };

    setMenus = () =>{
        this.setState({ selectMenu: "type" })
    };
    
    setMenusTime = () =>{
        this.setState({ selectMenu: "time" })
    };

    closedMenuTime = () =>{
        this.setState({  selectMenu: "" })
    };

    selectTimereminder = (indexnum:number) => {
        this.setState({ selectMenu: `timereminder${indexnum}` })
    };

    editinTakedate = () =>{
        this.setState(prevState => ({
            initialSingalReminder: [
              {
                ...prevState.initialSingalReminder[0],
                attributes: {
                  ...prevState.initialSingalReminder[0].attributes,
                  intake_end_date: !prevState.initialSingalReminder[0]?.attributes?.intake_end_date,
                  end_time: prevState.initialSingalReminder[0]?.attributes?.date
                }
              }
            ]
          }));
          
    };

    deleteModalClosed = () =>{
        this.setState({deleateModal:false})
    };

    deleteModalOpen = () =>{
        if(this.state.initialSingalReminder[0]?.attributes?.intake_end_date === true){
            this.setState({deleateModal:true,editModalReminder:false})
        }else{
            this.setState({editModalReminder:false})
            this.deleteReminderApi()
        }
    };

    deleteRadioselectone = () =>{
        this.setState({ dateEventSave: "this_reminder_only" })
    };

    deleteRadioselecttwo = () =>{
        this.setState({ dateEventSave: "this_and_following_reminders" })
    };

    getNextDayDate = (date:string | undefined) => {
        const chekDate = date || this.state.treatmentData.date;
        const nextDay = new Date(chekDate);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay;
    };


    getDayDate = (date:string | undefined) => {
        const chekEditdate = date || this.state.treatmentData.date;
        const nextDay = new Date(chekEditdate);
        nextDay.setDate(nextDay.getDate());
        return nextDay;
    };
    getNavigationEvent = (props: React.ReactNode) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EventsSchedule");
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        this.send(message);
    }

    getNavigationReport = (props: React.ReactNode) =>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        this.send(message);
    }

    handleDoubleClick = (treatmentId:number | undefined) => {
        this.handleStatusReminder(treatmentId);
    };

    handleStatusReminder = async(recivedId : number | undefined) =>{
        const token = await getStorageData("authToken");
        const takenId = recivedId;
        let formdata = new FormData();
        if (takenId !== undefined) {
        formdata.append("id", takenId.toString());
        }
        const header = {
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selectStatusReminderApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reminderTakenEndPoint
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
       
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
       
    }

    formatEditDate = (date:string | undefined, endTime:string|undefined) => {
        if (endTime === null) {
          return moment(date, 'DD-MM-YYYY').format('DD MMM, yyyy');
        } else {
          return moment(endTime, 'DD-MM-YYYY').format('DD MMM, yyyy');
        }
      };
        // Customizable Area End
    }
