import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent, RefObject, createRef } from 'react';

// Customizable Area Start
import {
  getStorageData, setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";
import StorageProvider from "framework/src/StorageProvider";
export interface IAttribute {
  value: string | number;
  is_enable: boolean;
  title: string;
  is_required: boolean;
  field_type: string;
  name: string;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "text"
  | "textarea"
  | "date"
  | "integer"
  | "datetime"
  | "number"
  | "float"
  | "boolean"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  is_enable: boolean;
  title: string;
  is_required: boolean;
  field_type: FieldType;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

interface Readonly<S> {
  [key: string]: S;
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
interface CountryCodeData {
  phone_code: string;
  name: string;
}

interface WizardDataType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    min_count: number;
    logo: string;
  };
}

interface SteackDataType {
  id: number,
  current_streak: number,
  best_streak: number
}

interface OnboardingStep {
  id: number;
  onboarding_title: string;
  onboarding_description: string;
  transformHorizontal: string;
  anchorHorizontal: string;
}

export interface CountryCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}

export interface CountryResponse {
  data: CountryCode[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start


  first_name: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  last_name: string;
  caregiver: string;
  firstDropOpen: boolean;
  thirdDropOpen: boolean;
  secondDropOpen: boolean;
  ageList: any;
  age: string;
  caregiverName: string;
  code: string;
  ageId: string;
  imgData: null | string;
  imgObj: any;
  errors: any;
  validationMsg: string;
  validationTrue: boolean;
  caregiverEmail: string;
  caregiverPhone: string;
  meta: string;
  verifyCode: string;
  firstNameImg: string;
  successMsg: string;
  innerWidth: null | number;
  openModel: boolean;
  openTroubleDialog:boolean;
  caregiverFirst: boolean | null;
    countryCodeData: CountryCodeData[];
selectedCountry: CountryCodeData;
  dropdownOpen: boolean;
  isCountryData: string;
  searchData: string;
  phoneNumbers: string;
  poinsummryTotalScore:number;
  wizardData:WizardDataType;
  streakData:SteackDataType;
  editProfileToggle:boolean;
  onBoarding1: OnboardingStep[];
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  openOneScreenOnboarding: boolean;
  onBoardingBtnDisable: boolean;
  onboardingRefs: (RefObject<HTMLTableCellElement>)[];
  careGiverCountry: CountryCodeData;
  cDropdownOpen: boolean;
  cIsCountryData: string;
  cSearchData: string;
  filteredData: CountryCodeData[];
  isLoading:boolean;
  errorPopUp: boolean;
  locationCountry:string;
  wclPopup: boolean;
  ipAdress: string;
  loginUserType: string | null  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  getAgeApiId: string = "";
  getProfileApiId: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  varifyCodeId: string = "";
  signUpWithoutID: string = "";
  countryCodeApiCallId: string = "";
  getPointSummaryCallId: string = "";
  getWizardListApiCallId: string = "";
  getStreaksListApiCallId: string = "";
  activityLogCallId: string = "";
  getTestReportApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      first_name: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      last_name: "",
      caregiver: "",
      caregiverFirst: null,
      firstDropOpen: false,
      thirdDropOpen: false,
      secondDropOpen: false,
      ageList: [],
      age: "",
      caregiverName: "",
      code: "",
      ageId: "",
      imgData: null,
      imgObj: null,
      errors: {},
      validationMsg: "",
      validationTrue: false,
      caregiverEmail: "",
      caregiverPhone: "",
      meta: "",
      verifyCode: "",
      firstNameImg: "",
      successMsg: "",
      innerWidth: null,
      openModel: false,
      openTroubleDialog:false,
      countryCodeData: [],
      dropdownOpen: false,
      loginUserType: localStorage.getItem("user_type"),
      isCountryData: "",
      selectedCountry: { phone_code: "+1", name: "United States" },
      searchData: "",
      phoneNumbers: "",
      poinsummryTotalScore:0,
      wizardData: {
        id: "1",
        type: "wizard_level",
        attributes: {
          id: 2,
          name: "Wizard Level 1",
          min_count: 50,
          logo: ""
        }
      },
      streakData: {
        id: 1,
        current_streak: 0,
        best_streak: 0
      },
      editProfileToggle:true,
      onboardingRefs: Array.from({ length: 4 }, () =>
                createRef<HTMLTableCellElement>()
            ),
      onBoarding1: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Upload your picture here to personalize your profile.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description":"Select Caregiver Status: Indicate whether you are a caregiver or not. This helps tailor the experience to better suit your needs and preferences.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Enter Patient Email ID: Input the patient's email address for communication and updates. Ensure accurate information for seamless connectivity.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Enter Invitation Code: Input your valid invitation code for access. Ensure accuracy to join seamlessly.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      careGiverCountry: { phone_code: "+1", name: "United States of America" },
      cDropdownOpen: false,
      cIsCountryData: "",
      cSearchData: "",
      filteredData: [],
      isLoading:false,
      errorPopUp: false,
      locationCountry:"",
      wclPopup: true,
      ipAdress: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (!this.state.loginUserType) {
      this.redirectLogin()
    }
    const location = await getStorageData ('location');
    this.setState({locationCountry: location})
    let onboarding = await getStorageData("onBoarding");
    let popup = await getStorageData("wlcPopup");
    if (onboarding === null && (popup == "false" || location == "us")) {
      setTimeout(() => {
        this.setState({editProfileToggle:false, openOnboarding: true });
        this.nextOnBoarding();
      }, 500)
    }
    this.makeRemoteRequest();
    this.getTestReport();
    this.ageGet();
    this.getSummaryList();
    this.getAllStreaksList();
    const meta = JSON.parse(await getStorageData("meta"));
  
    this.profileDataGet(meta);
    this.setState({
      meta: meta.token
    });

   
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setDataForAge(apiRequestCallId, responseJson);
        this.verifyChecked(apiRequestCallId, responseJson);
        this.callSaveApi(apiRequestCallId, responseJson);
        this.saveCoutrycode(apiRequestCallId, responseJson);
        this.setDataForPointSummry(apiRequestCallId, responseJson);
        this.setRewardData(apiRequestCallId, responseJson);
        this.setStreaksData(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId === this.getTestReportApiCallId) {
        this.setState({ipAdress: responseJson.ip})
        this.callApiLogActivity("view_profile");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleWlcClose = async () => {
    this.setState({ wclPopup: false });
    setStorageData("wlcPopup", "false")
    let onboarding = await getStorageData("onBoarding");
    if (onboarding === null) {
      setTimeout(() => {
        this.setState({editProfileToggle:false, openOnboarding: true });
        this.nextOnBoarding();
      }, 500)
    }
  }
  saveCoutrycode = (apiRequestCallId: string, responseJson: CountryResponse) => {
    if (apiRequestCallId === this.countryCodeApiCallId) {
      this.setState({ countryCodeData: this.countryCodesToDropDown(responseJson?.data) });
      this.setState({ filteredData: this.countryCodesToDropDown(responseJson?.data) });
    }
  }

  countryCodesToDropDown = (data: Array<CountryCode>) => {
    return data.map((item) => ({
      name: item.attributes.name,
      phone_code: `+${item.attributes.country_code}`
    }));
  };
  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );
  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
        cDropdownOpen: false,
    }));
    this.setState({
      searchData: "",
      filteredData: this.state.countryCodeData,
    });
  };
  
  handleCountrySelect = (country: CountryCodeData, code: string) => {
        this.setState({
        selectedCountry: country,
        dropdownOpen: false,
        isCountryData: code,
    });
  };

  handleSearchData = (event: ChangeEvent<HTMLInputElement>) => {
  const searchDataList = event.target.value.toLowerCase();
const filteredDatalist = this.state.countryCodeData.filter(item =>
    item.name.toLowerCase().includes(searchDataList) || item.phone_code.toLowerCase().includes(searchDataList)
  );
  this.setState({ 
    searchData: event.target.value,
   filteredData: filteredDatalist
  });
  };

  careGiverToggleDropdown = () => {
    this.setState((prevState) => ({
        cDropdownOpen: !prevState.cDropdownOpen,
        cSearchData: "",
        filteredData: this.state.countryCodeData,
        dropdownOpen: false
    }));
      };
  
  careGiverHandleCountrySelect = (country: CountryCodeData, code: string) => {
        this.setState({
        careGiverCountry: country,
        cDropdownOpen: false,
        cIsCountryData: code,
    });
  };

  careGiverHandleSearchData = (event: ChangeEvent<HTMLInputElement>) => {
    const searchData = event.target.value.toLowerCase();
const filteredData1 = this.state.countryCodeData.filter(item =>
    item.name.toLowerCase().includes(searchData) || item.phone_code.toLowerCase().includes(searchData)
  );
  this.setState({ 
    cSearchData: event.target.value,
   filteredData: filteredData1
  });
  
  };
  

  callSaveApi = async(index: string, responseJson: any) => {
    if (this.signUpWithoutID === index && !responseJson.errors) {
      const meta = JSON.parse(await getStorageData("meta"));
      this.profileDataGet(meta)
      this.setState({
        openModel: true,
        successMsg: responseJson.meta.message,
        errors: {},
        editProfileToggle:true,
      });
      this.callApiLogActivity("update_profile");
    } else {
      let errors = {
        caregiverPhone: "",
        caregiverEmail: "",
        phoneNumber: ""
      };

      if (responseJson.errors) {
        this.setDiffrentError(responseJson, errors)
      }

      this.setState({
        errors: errors
      });
    }
  }
  setDiffrentError = (responseJson: any, errors: {caregiverPhone: string , caregiverEmail: string, phoneNumber: string}) => {
    responseJson.errors.forEach((error: { caregiver_mobile_number: string; caregiver_email: string; full_phone_number: string; }) => {
      if (error.caregiver_mobile_number) {
        errors.caregiverPhone = error.caregiver_mobile_number;
      }
      if (error.full_phone_number) {
        errors.phoneNumber = error.full_phone_number;
      }
    });
  }
  cancelBtn = () => {
    this.setState({openModel: false})
  };
  setCountry=(data:{[key:string]:string})=>{
    this.setState(prevState => ({
      selectedCountry: {
          ...prevState.selectedCountry, 
          phone_code: data.country_code ? `+${data.country_code}` : "+1"
      }
  }));
  };

  setCareCountry=(data:{[key:string]:string})=>{
    this.setState(prevState => ({
      careGiverCountry: {
          ...prevState.careGiverCountry, 
          phone_code: data.caregiver_country_code ? `${data.caregiver_country_code}` : "+1"
      }
  }));
  };

  cancelTroubleDialog  = () => {
    this.setState({openTroubleDialog: false})
  };
  
  setDataForAge = async(index: string, responseJson: any) => {
    if (this.getAgeApiId === index) {

      this.setState({
        ageList: responseJson.age_groups
      })

    } else if (this.getProfileApiId === index) {
      const data = responseJson.data.attributes;

      if (data) {
        const imageUrl = data.profile.data.attributes.profile_image;
        const profileData = data.profile.data.attributes;
        if(profileData?.invitation_code){
          this.setState({
            code: profileData.invitation_code,
            secondDropOpen: true,
            validationTrue: true
          })

        }
        this.expireCodeData(responseJson.data.attributes?.invitation_code_status)
       
        this.setCountry(data) 
        this.setCareCountry(profileData)
              this.setState({
          first_name: data.first_name,
          firstNameImg: data.first_name,
          last_name: data.last_name,
          phoneNumber: data.phone_number,
          email: data.email,
          imgData: imageUrl,
          caregiver: profileData.is_caregiver === null ? "" : profileData.is_caregiver ? "Yes" : "No",
          caregiverName: profileData.caregiver_name,
          caregiverEmail: profileData.caregiver_email,
          caregiverPhone: profileData.caregiver_phone_number,
          age: profileData.age_group.age_group,
          code: profileData.invitation_code,
          caregiverFirst:data.profile.data.attributes.is_caregiver
          }
        );
      }
    }
  }

 /* istanbul ignore next */
  expireCodeData = (code_status: string)=> {
    if(code_status === "in-active"){
      this.setState({validationMsg: "Your code has expired. Please add a new one.", validationTrue: false, errors: {...this.state.errors, code: "Your invitaion code is expired!"}})
    }else if(code_status === "Code has been deleted."){
      setStorageData("user_type", "regular")
    }else {
      setStorageData("user_type", "invited")
    }
  }
  verifyChecked = (index: string, responseJson: any) => {

    if (this.varifyCodeId === index) {
      if (responseJson.errors) {
        const masssage = responseJson.errors[0].message || responseJson.errors[0].token
        this.setState({
          validationMsg: masssage
        })
      } else {
        this.setState({
          validationTrue: true
        })
      }
    }
  }

  removeCode = () => {
    this.setState({errorPopUp: true})
    this.setState({
      code: "",
      validationMsg: "",
      validationTrue: false
    })
  }

  ageGet = () => {
    window.scrollTo(0, 0);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAgeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/list_age_groups`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setLocalstoregeData = () => {
    setStorageData("user_type", "invited")
  }
  profileDataGet = (meta: {token: string}) => {


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profile`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validationCheck = () => {
    const { state } = this;
    const { caregiver } = state;

    const isNotEmpty = (value:string) => value && value.trim() !== '';
    
    const isValidPhoneNumber = (value:string) => {
        if (!value || value.trim() === '') return true;
        
        return configJSON.phonenumberValid?.test(value);
    };

    const isValidEmail = (value:string) => value && value.trim() !== '' && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

    const validations = {
        first_name: { rule: isNotEmpty, message: 'Please enter your first name' },
        last_name: { rule: isNotEmpty, message: 'Please enter your last name' },
        phoneNumber: { rule: isValidPhoneNumber, message: 'Please enter a valid phone number' },
        email: { rule: isValidEmail, message: 'Please enter a valid email address' },
        caregiver: { rule: isNotEmpty, message: 'Please select an option to continue' },
        caregiverName: { rule: () => caregiver === 'Yes' ? isNotEmpty(state.caregiverName) : true, message: 'Please enter caregiver name' },
        caregiverEmail: { rule: () => caregiver !== 'Yes' || isValidEmail(state.caregiverEmail), message: 'Please enter a valid email address' },
        caregiverPhone: { rule: () => caregiver === 'Yes' ? isValidPhoneNumber(state.caregiverPhone) : true, message: 'Please enter a valid phone number' },
    };

    let isValid = true;
    const newErrors: Partial<S> = {};

Object.entries(validations).forEach(([fieldName, { rule, message }]) => {
    let field = fieldName as keyof S;
    
    if (!rule((state as S)[field])) {
        isValid = false;
        newErrors[field] = message;
    }
});

  

    this.setState({ errors: newErrors });

    if (isValid) {
      this.setState({errorPopUp:false})
        this.saveBtnApi();
    }
}


makeimageNull =()=>{
  this.setState({ errorPopUp: true })
  this.setState({
    imgData: null
})
}

  setAllData = (event: React.FormEvent) => {
    event.preventDefault();
    this.validationCheck();
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.setState({ errorPopUp: true })
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    } as any, () => {
      const { errors } = this.state;
      if (errors[name]) {
        const newErrors = { ...errors };
        delete newErrors[name];
        this.setState({ errors: newErrors });
      }
    });
  };

  uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        if (file instanceof Blob) {
            const allowedTypes = ["image/jpeg", "image/png"];
            const fileExtension = file.name?.split(".").pop()?.toLowerCase();
            if (!allowedTypes.includes(file.type) && !allowedTypes.includes(`image/${fileExtension}`)) {
                this.setState({ openTroubleDialog: true });
                return; 
            }

            const imageDataUrl = URL.createObjectURL(file);

            this.setState({
                imgData: imageDataUrl,
                imgObj: file,
                openModel: true,
                successMsg: ""
            });
        }
        this.setState({errorPopUp: true});
    }
};

  saveBtnApi = () => {

    this.setState({
      firstNameImg: this.state.first_name
    })
    const headers = {
      // "content-type":"charset=UTF-8",
      token: this.state.meta,
    };
  
    let formdata = new FormData();
    formdata.append("data[first_name]", this.state.first_name);
    formdata.append("data[last_name]", this.state.last_name);
    formdata.append("data[country_code]", this.state.selectedCountry.phone_code);
    formdata.append("data[phone_number]", this.state.phoneNumber);
    formdata.append("data[full_phone_number]", this.state.selectedCountry.phone_code.replace('+', '')  + this.state.phoneNumber);
    formdata.append("data[is_caregiver]", this.state.caregiverFirst !== null ? this.state.caregiverFirst.toString() : '');
    this.state.caregiverName ? formdata.append("data[caregiver_name]", this.state.caregiverName): formdata.append("data[caregiver_name]", "");
    formdata.append("data[invitation_code]", this.state.code || "");
    this.state.imgObj ? (formdata.append("data[profile_image]", this.state.imgObj)): (formdata.append("data[remove_image]",'' ));
    formdata.append("data[age_group_id]", this.state.ageId);
    this.state.caregiverEmail ? formdata.append("data[caregiver_email]", this.state.caregiverEmail) : formdata.append("data[caregiver_email]", "");
    this.state.caregiverPhone ? formdata.append("data[caregiver_mobile_number]", this.state.careGiverCountry.phone_code.replace("+", "") + this.state.caregiverPhone): formdata.append("data[caregiver_mobile_number]", "");
    this.state.caregiverPhone ? formdata.append("data[caregiver_country_code]", this.state.careGiverCountry.phone_code): formdata.append("data[caregiver_country_code]", "");
    this.state.caregiverPhone ? formdata.append("data[caregiver_phone_number]", this.state.caregiverPhone): formdata.append("data[caregiver_phone_number]", "");
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profile'
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.signUpWithoutID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  dropDownToggle = () => {
    this.setState({errorPopUp: true})
    this.setState({
      firstDropOpen: !this.state.firstDropOpen,
      errors: {
        age: ""
      }
    })
  };

  dropDownToggleFirst = () => {
    this.setState({errorPopUp: true})
        this.setState({
      thirdDropOpen: !this.state.thirdDropOpen,
      caregiverEmail:"",
        caregiverPhone:"",
        caregiverName:"",
        careGiverCountry: { phone_code: "+1", name: "United States" },
      errors: {
        age: "",
        caregiverEmail:"",
        caregiverPhone:"",
        caregiverName:""
      }
    })
  }

  dropDownOpenCloseFirst = (value: string) => {
    this.setState({
      thirdDropOpen: false,
      caregiver: value,
      caregiverFirst: value === "Yes" ? true : false
    });
  }
  dropDownOpenClose = (index: string, value: string) => {
    this.setState({
      firstDropOpen: false,
      age: value,
      ageId: index
    });
  };

  dropDownToggleSec = () => {
    this.setState({
      secondDropOpen: !this.state.secondDropOpen
    })
  };

  dropDownOpenCloseSec = (value: string) => {
    this.setState({
      secondDropOpen: false,
    });
  };

  verifyCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({errorPopUp: true})
    this.setState({
      code: event.target.value,
      errors: { code: "" }
    })
    this.setState({
      validationMsg: ""
    })
  }

  verifyCode = () => {


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.varifyCodeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/invitation_code_verification?invitation_code=${this.state.code}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: this.state.meta
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getSummaryList = async () => {
    this.setState({isLoading:true});
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/points_summary";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPointSummaryCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setDataForPointSummry = (index: string, responseJson: any) => {
    if (this.getPointSummaryCallId === index) {
      if(responseJson){
        this.getWizardList();
        this.setState({poinsummryTotalScore:responseJson.total_score})
      }
    } 
  }

  getWizardList = async () => {
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/wizard_levels";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWizardListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setRewardData = (index:string,responseJson:any)=>{
    if (this.getWizardListApiCallId === index) {
      if(responseJson){
        const getYourReward = responseJson.data?.find((item: any) => item?.attributes?.min_count > this.state.poinsummryTotalScore);
        this.setState({wizardData : getYourReward})
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1000);
      }
    }
  }

  getAllStreaksList = async () => {
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/streaks";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStreaksListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   setStreaksData = (index:string,responseJson:any)=>{
    if (this.getStreaksListApiCallId === index) {
      if(responseJson.data){
        this.setState({streakData : responseJson.data,isLoading:false})
      }
    }
  }

  gamificationScreen =()=>{
    this.props.navigation.navigate("Gamification");
  }

  editProfile=()=>{
    this.setState({editProfileToggle:false});
  }

  nextOnBoarding = () => {   
    const { onBoarding1, currentStep } = this.state;
    if (onBoarding1.length > currentStep) {
        this.changeStep2();
    }
    else {
      if (this.state.openOneScreenOnboarding) {
        this.props.navigation.navigate('Task');
      }
      this.resetDataFunction();
    }
  }


  resetDataFunction = () => {
    this.setState({
      openOnboarding: false,
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
    });
  }

  changeStep2 = () => {
    const { currentStep, onBoarding1 } = this.state;
    const onBoarding = onBoarding1 ;
    this.setState({
      onBoardingTitle: onBoarding[currentStep].onboarding_title,
      onBoardingText: onBoarding[currentStep].onboarding_description,
      transformHorizontal: onBoarding[currentStep].transformHorizontal,
      anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
      currentStep: currentStep + 1
    })
    const targetRef = this.state.onboardingRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }

  }

  skipOnBoarding = async () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    this.resetDataFunction();
    await setStorageData("onBoarding", "true");
  }
  
  filterData(data: Array<CountryCodeData>, searchString: string) {
    return data.filter(item =>
      item?.name.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  redirectToLogin = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "Gamification");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  }; 
  
  redirectLogin = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  }; 

  getTestReport = () => {
    window.scrollTo(0, 0);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestReportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ipAddressApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callApiLogActivity = async (type: string) => {
    let updateProfile= `activity?action_name=update_profile&response=success&message=profile update success&platform_type=web&ip_address=${this.state.ipAdress}&time=${moment().utc().format('HH:mm')}` 
    let viewProfile=  `activity?action_name=view_profile&response=success&message=profile view success&platform_type=web&ip_address=${this.state.ipAdress}&time=${moment().utc().format('HH:mm')}`

    const header = {
      "Content-Type": "multipart/form-data",
      token: await StorageProvider.get("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activityLogCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type === 'update_profile' && updateProfile || type === 'view_profile' && viewProfile
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
