import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  withStyles,
  Box,
  styled,
  Container,
  FormControl,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogActions,
  RadioProps,
  Radio,
  Divider,
  InputAdornment
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import {
  closeIcon,
  code_verified,
  mainLogo,
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import SignupMFAController, { Props } from "./SignupMFAController.web";
import CustomBG from '../../../components/src/CustomBG.web';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";



const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\+\d{1,12}$/, 'Invalid phone number')
    .required('Phone number is required'),
});

interface CustomRadioProp extends RadioProps {
  "data-test-id": string;
}

export const configJSON = require("./config");

export const CustomRadio = withStyles(() => ({
  root: {
    marginRight: "8px",
    padding: "0px",
    "&$checked": {
      color: "red",
    },
    color: "default",
  },
  checked: {},
}))((props: CustomRadioProp) => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={
      <span
        style={{
          backgroundColor: "white",
          display: "inline-block",
          borderRadius: "50%",
          height: 8,
          border: "6px solid #13689E",
          width: 8,
        }}
      />
    }
    icon={
      <span
        style={{
          display: "inline-block",
          border: "1px solid rgba(100, 116, 139, 1)",
          width: 16,
          height: 16,
          borderRadius: "50%",
        }}
      />
    }
    {...props}
  />
));

const CssTextFieldCode = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc6c72b",
      borderWidth: "1.8px",
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },
    "& .MuiFilledInput-underline::after": {
      border: "none",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f13426",
      fontWeight: 500,
    },


    "& .MuiFilledInput-root": {
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.09)",
    },
    "& .MuiInputBase-input": {
      color: "var(--Neutrals-Cool-gray-500, #1B1C1E)",
      backgroundColor: "#f4faffbd",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiInputLabel-root": {
      color: "var(--Neutrals-Cool-gray-500, #64748B)",
    },
    '& ::placeholder': {
      opacity: 1,
      color: "#64748B",
    }
  },
})(TextField);


const CssTextField = withStyles({
  root: {

    '& ::placeholder': {
      color: "#64748B",
      opacity: 1
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1dddc",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#ff0c00",
    },

    "& .MuiFilledInput-underline::after": {
      border: "none",
    },
    "& .MuiFilledInput-underline::before": {
      border: "none",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f0f0f0",
      border: "1px solid rgba(0, 0, 0, 0.09)",
    },

    "& .MuiInputLabel-root": {
      color: "rgb(51, 51, 57)",
    },
    "& .MuiInputBase-input": {
      color: "rgb(51, 51, 57)",
      backgroundColor: "#f4faffbd",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 0",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#f4faffbd",
    },
  },
})(TextField);

const MainContainer = styled(Box)({
 height: "auto",
  display: "flex",
  alignItems: "center",
  minHeight:"100vh"
});

const formatEmail = (emailCode: any) => {
    const parts = emailCode && emailCode?.replace(/\D/g, '')?.match(/.{1,3}/g);
  if (parts) {
    return parts.join('-');
  } else {
    return emailCode;
  }
};

const Wrapper = styled(Box)({
  position: "relative",
    cursor: "pointer",
  "& .dropDownHeader": {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "5px",
    background: "#f4faffbd",
    height: "36px",
    width: "86px",
    color: "black",
    border: "1px solid #ccc6c72b",
    fontWeight: 400,
    fontSize: "16px",
    "& .selectDropdown": {
      color: "black"
    }
  },
  "& .selectedCountry": {
    display: "flex"
  },
  "& .dropDownConatiner":{
    left: 0,
    width: "256px",
    border: "1px solid #F1F5F9",
    borderTop: "none",
    borderRadius: "12px",
    marginTop: "10px",
    background: "#F8FAFC",
    position: "absolute",
    zIndex: 1,
    padding: "10px",
    marginBottom: "10px",
    color: "white",
    backgroundColor:"#F1F5F9",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "& .searchField": {
      marginTop: "10px",
      marginBottom: "12px",
      borderRadius: "50px",
      background: "#E2E8F0",
      color: "black",
      position:"sticky",
      border: "none",
      "& .MuiOutlinedInput-notchedOutline":{
        border: "none !important"
      }
    },
  "& .dropDownOptions": {
    left: 0,
    border: "1px solid #F1F5F9",
    borderTop: "none",
    maxHeight: "160px",
    background: "#F8FAFC",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "12px",
      boaderRadius: "50px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F5F9"
    },
    backgroundColor:"#F1F5F9",
    "&::-webkit-scrollbar-thumb": {
      background: "#94A3B8",
      borderRadius: "9px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#90A4AE"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "none",
      borderRadius: "50px",
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 0px"
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "25px"
    },
        "& .MuiInputBase-input": {
      color: "#64748B",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px"
    },
    "& .optionsValue": {
      padding: "10px",
      display: "flex",
      color: "black",
      "& .active": {
        color: "#13689E",
        fontWeight:600,
        display:"flex"
      },
      "& .updated": {
        display:"flex",
        color: "#151515",
        fontWeight:500
      },
      "& .sortDataScrollDiv": {
        "&::-webkit-scrollbar": {
          width: '4px',
        },
        "&::-webkit-scrollbar-track": {
          background: '#F1F5F9'
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: '8px',
          background: '#94A3B8',
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: '#90A4AE',
        },
        "&::-webkit-scrollbar:vertical": {
          width: '4px',
          height:"4px"
        },
      },
    }
  },
},
  "& .selectedPhoneCode": {
    color: "#0F172A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "5px"
  },
  "& .dividerColor": {
    background: "#E2E8F0"
  },
  "& .phoneCode": {
    marginRight: "15px",
    minWidth:"55px"
  },
  "& .searchDataIcon": {
    color: "#64748B"
  },
})


// Customizable Area End

export default class SignupMFA extends SignupMFAController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  
  getStepViceForm = () => {
    const { step } = this.state;
    let result;

    if (step === 0) {
      result = this.selectMFAValType();
    } else if (step === 1) {
      result = this.enterMFAData();
    } else if (step === 2) {
      result = this.varifyMfa();
    }
    return result;
  };

  selectMFAValType = () => {
    return (
      <>
        <Box style={styles.closeIcon}>
          <img src={closeIcon} alt="x" onClick={() => this.props.navigation.goBack()} data-test-id="firstOnClick"/>
        </Box>
        <div>
          <img alt="Logo" src={mainLogo} style={styles.mainLogo} />
          <Typography style={styles.multiFectorHeadingStyle} variant="h5">
            {`Choose a multi-factor authentication (MFA) method`}
          </Typography>
          <Typography style={styles.paregraphText}>
            {`You must configure MFA before you can signup. Choose how you want to
            authenticate.`}
          </Typography>
          <Formik
            initialValues={{
              rad: this.state.radioBtn || 0,
            }}
            data-test-id="Formik"
            onSubmit={(_values) => {

            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(),
            })}
          >
            {(props) => {
              return (
                <>
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="gender" name="customized-radios" value={props.values.rad}>
                        <div
                          style={{
                            ...styles.radioStyle,
                            opacity: props.values.rad === 0 ? 1 : 0.4,
                            marginTop: "32px",
                          }}
                          onClick={() => props.setFieldValue("rad", 0)}
                          data-test-id="rad1"
                        >
                          <CustomRadio
                            checked={props.values.rad === 0}
                            data-test-id="check"
                          />
                          <img
                            style={styles.verfiyLogostyle}
                            alt="Code"
                            src={code_verified}
                          />
                          <div style={{ display: "grid", gap: "10px" }}>
                            <Typography style={styles.smsTxt}>SMS</Typography>
                            <Typography style={styles.smsPeregraph}>
                              {`Authenticate using a code sent via text message to
                              your phone.`}
                            </Typography>
                          </div>
                        </div>

                        <div
                          style={{
                            ...styles.radioStyle,
                            opacity: props.values.rad === 1 ? 1 : 0.4,
                            marginTop: "24px",
                          }}
                          onClick={() => props.setFieldValue("rad", 1)}
                          data-test-id="rad2"
                        >
                          <CustomRadio
                            checked={props.values.rad === 1}
                            data-test-id="check1"
                          />
                          <img
                            style={styles.verfiyLogostyle}
                            alt="Code"
                            src={code_verified}
                          />
                          <div style={{ display: "grid", gap: "10px" }}>
                            <Typography style={styles.smsTxt}>EMAIL</Typography>
                            <Typography style={styles.smsPeregraph}>
                              {`Authenticate using a code sent via email to your
                              email id.`}
                            </Typography>
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <button
                    style={styles.button
                    }
                    onClick={() =>
                      this.handle_continue_click(props.values.rad)
                      
                    }
                    data-test-id="handle_continue_click"
                  >
                    Continue
                  </button>
                </>
              );
            }}
          </Formik>
        </div>
      </>
    );
  };
  
  dropDownRender = () => {
    return (
      <Box className="dropDownHeader" data-test-id="toggleCountryId"
        onClick={this.toggleDropdown}
      >
        {this.state.countryCodeData ? (
          <Box className="selectedCountry">
            <Typography className="selectedPhoneCode">  {this.state.selectedCountry?.phone_code}</Typography>
          </Box>
        ) : (
          <Typography>{this.state.isCountryData}</Typography>
        )}
        <span className="selectDropdown"><ExpandMoreIcon />
        </span>
      </Box>
    )
  }
  
  enterMFAData = () => {
    const { validTypeEmail } = this.state;
    const email = localStorage.getItem("emailId") as any

    const emailId = email &&  JSON.parse(email);
               
    return (
      <>
        {validTypeEmail ? (
          <>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(val) => {
                this.emailOnsubmit(emailId)
                
                
                              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email is required"),
              })}
              data-test-id="Formik"
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Box style={styles.closeIcon}>
                      <img src={closeIcon} alt="x" data-test-id="secCLick" onClick={
                        this.secCLick}
                        />
                    </Box>
                    <div>
                      <img src={mainLogo} alt="VITA" style={styles.mainLogo} />
                      <Typography style={styles.head} variant="h5">
                        Set up Email MFA
                      </Typography>
                      <Typography style={styles.paraText}>
                        Enter your email id.
                      </Typography>

                      <CssTextFieldCode
                        style={styles.signupInput}
                        value={emailId}
                        name="email"
                        id="filled-required"
                        
                        InputProps={{
                          disabled: true
                      }}
                        placeholder="Enter your Email ID"
                        defaultValue="Hello World"
                        data-test-id="email-change"     
                        variant="outlined"
                      />
                      <button
                        style={{
                          ...styles.button,
                          
                        }}
                        type="submit"
                        data-test-id="handleContinueClick"
                        onClick={()=>this.createMFACodeAPI(emailId)}
                      >
                        Send Code
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <Formik
              initialValues={{ phone: this.state.validData.phone || '' }}
              onSubmit={(val) => {
                this.phoneSubmit(val.phone)
                
                this.createMFACodeAPI("");
                              }}
              data-test-id="Formik"
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .matches(configJSON.phonenumberValid, configJSON.phoneValidation)
                  .required('Phone number is required'),
              })}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Box style={styles.closeIcon}>
                      <img src={closeIcon} alt="x" data-test-id="thrCLick" onClick={
                        this.thrCLick
                        } />
                    </Box>
                    <div>
                      <img alt="VITA" style={styles.mainLogo} src={mainLogo} />
                      <Typography style={styles.head} variant="h5">
                        Set up SMS MFA
                      </Typography>
                      <Typography style={styles.paraText}>
                        Enter your phone number, including country code.
                      </Typography>
                      <Box style={{ display: "flex",gap: "10px", alignItems: "center"}}>
                        <Wrapper>
                          {this.dropDownRender()}
                          {this.state.dropdownOpen && (
                            <Box className="dropDownConatiner">
                              <TextField
                                value={this.state.searchData}
                                onChange={this.handleSearchData}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon className="searchDataIcon" />
                                    </InputAdornment>
                                  ),
                                }}
                                data-test-id="searchDataId"
                                variant="outlined"
                                fullWidth
                                className="searchField"
                                placeholder="Search a country"
                              />
                              <Divider className="dividerColor" />
                              <Box className="dropDownOptions">
                                {this.state.filteredData.map((country, countryIndex: number) => (
                                  <Box className="optionsValue"
                                    data-test-id="countrySelectId"
                                    key={countryIndex}
                                    onClick={() => this.handleCountrySelect(country, country.phone_code)}
                                  >
                                    <Typography
                                      className={
                                        country?.name === this.state.selectedCountry.name
                                          ? "active"
                                          : "updated"
                                      }
                                                                          >
                                  <span className="phoneCode" >{country.phone_code}</span> <span>{country.name}</span>          
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          )}
                        </Wrapper>
                        <CssTextFieldCode
                          placeholder="Enter your phone number"
                          style={styles.signupInput}
                          value={props.values.phone}
                          name="phone"
                          id="filled-required"
                          onChange={(event) => {
                            props.handleChange(event);
                            this.phoneChange(event)
                          }}
                          defaultValue="Hello World"
                          variant="outlined"
                          inputProps={{
                            color: 'rgba(148, 163, 184, 1)',
                          }}
                          data-test-id="phone-change"
                          error={!!props.errors?.phone || this.state.emailError.length > 1}
                        />
                      </Box>
                     <Typography style={styles.errorMessage}>{props.errors?.phone || this.state.emailError}</Typography> 
                      <button

                        type="submit"
                        data-test-id="handleContinueClick"
                        disabled={


                          !props.values.phone
                        }
                        style={{
                          ...styles.button,
                          opacity:

                            props.values.phone
                              ? 1
                              : 0.4,
                        }}
                      >
                        Send Code
                      </button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </>
        )}
      </>
    );
  };

  varifyMfaBtn = (props: any) => {
        
    return (
      <button
        style={{
          ...styles.button,
          opacity:

            props.values.code
              ? 1
              : 0.4,
        }}
        data-test-id="handleContinueClick"
        type="submit"
        disabled={!props.values.code}
      >
        Verify
      </button>
    )
  }
  varifyMfa = () => {
    const email = localStorage.getItem("emailId") as any

const emailId = email &&  JSON.parse(email);
    const { validTypeEmail, validData } = this.state;
    return (
      <>
        {validTypeEmail ? (
          <>
            <Formik
              initialValues={{ emailCode: "" }}
              onSubmit={(val) => {
                this.verifyMFACodeAPI(val.emailCode);
              }}
              data-test-id="Formik"
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                emailCode: Yup.string().required("Code is required"),
              })}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Box style={styles.closeIcon}>
                      <img src={closeIcon} alt="x" onClick={this.closeIcon } data-test-id="closeIcon"/>
                    </Box>
                    <div>
                      <img src={mainLogo} style={styles.mainLogo} alt="VITA" />
                      <Typography style={styles.head} variant="h5">
                        Set up Email MFA
                      </Typography>
                      <Typography style={styles.paraText}>
                        We have sent you the authentication code by EMAIL to "
                        {emailId}". Please enter the code to complete
                        authentication.
                      </Typography>

                      <CssTextField
                      variant="outlined"
                        placeholder="Enter your authentication code"
                        name="emailCode"
                        value={formatEmail(
                          props.values.emailCode
                        )}

                        style={styles.signupInput}
                        id="filled-required"
                        onChange={(event) => {
                          props.handleChange(event);
                          event.target.value.length > 1 && 
                          this.emailCodeChange()
                          
                        }}
                        defaultValue=""
                        data-test-id="email-change"
                        
                        error={!!props.errors?.emailCode || this.state.errorValidCode?.length > 1}
                        inputProps={{
                          color: "rgba(148, 163, 184, 1)",
                        }}
                        helperText={props.errors?.emailCode || this.state.errorValidCode}
                      />
                      <button
                        style={{
                          ...styles.button,
                          opacity:

                            props.values.emailCode
                              ? 1
                              : 0.4,
                        }}
                        type="submit"
                        data-test-id="handleContinueClick"
                        disabled={
                          !props.values.emailCode
                        }
                      >
                        Verify
                      </button>

                      <Typography style={styles.bottomTextCode}>
                        Didn't receive a code?{" "}
                        <Link to="#" style={styles.bottomLink}
                          onClick={() => {
                            this.createMFACodeAPI(emailId);
                            this.receiveCode()
                            
                          }}
                          data-test-id="firstSend">
                          Send a new code
                        </Link>
                      </Typography>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <Formik
              data-test-id="Formik"
              initialValues={{ code: "" }}
              onSubmit={(val) => {
                this.verifyMFACodeAPI(val?.code);
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                code: Yup.string().required("Code is required"),
              })}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Box style={styles.closeIcon} onClick={
                      this.closeIconSec
                      } data-test-id="closeIcon">
                      <img src={closeIcon} alt="x" />
                    </Box>
                    <div>
                      <img style={styles.mainLogo} src={mainLogo} alt="VITA" />
                      <Typography style={styles.head} variant="h5">
                        Set up SMS MFA
                      </Typography>
                      <Typography style={styles.paraText}>
                        We have sent you the authentication code by SMS to "
                        {this.state.selectedCountry.phone_code + this.state.validData.phone }". Please enter the code to complete
                        authentication.
                      </Typography>

                      <CssTextField
                        placeholder="Enter your authentication code"
                        variant="outlined"
                        name="code"
                        style={styles.signupInput}
                        onChange={(e: any) => {
                          props.handleChange(e);
                          e.target.value.length > 1 && 
                          this.CodeChange()
                          
                        }}
                        value={formatEmail(
                          props.values.code
                        )}

                        defaultValue=""
                        id="filled-required"
                        data-test-id="code-change"
                        inputProps={{
                          color: "rgba(148, 163, 184, 1)",
                        }}
                        error={!!props.errors?.code || this.state.errorValidCode?.length > 1}
                        helperText={props.errors?.code || this.state.errorValidCode}
                      />

                      {this.varifyMfaBtn(props)}
                      <Typography style={styles.bottomTextCode}>
                        Didn't receive a code?{" "}
                        <Link to="#" style={styles.bottomLink} onClick={() => {
                          this.createMFACodeAPI("");
                          
                        }} data-test-id="secSend">
                          Send a new code
                        </Link>
                      </Typography>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Dialog open={this.state.firstModelOpen && this.state.modelOpenSec} PaperProps={{ style: { borderRadius: '24px' } }}>


          <DialogContent style={{ borderBottom: "1px solid #E2E8F0" }}>
            <p style={styles.dilogBodyText}>
            {this.state.validTypeEmail ? `We've sent you a new OTP. Please check your email and enter the code to proceed.` : `We've sent you a new OTP, Please check your phone and enter the correct code to proceed.`}
              
            </p>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justifyContent="center"
              className="action-btns"
            >
              <Grid data-test-id="mfaModelCLose" item style={styles.dilogCloseBtnText} onClick={this.goToNext}>
                Close
              </Grid>



            </Grid>
          </DialogActions>

        </Dialog>
        <CustomBG>
          <MainContainer>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12} lg={12} md={12}>
                  <Grid style={styles.headSecondgrid}>
                    {this.getStepViceForm()}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MainContainer>
        </CustomBG>
      </>
      // Customizable Area End
    );
  }
}

const styles: Record<string, React.CSSProperties> = {
  // Customizable Area Start
  closeIconStyle: {
    display: "flex",
    padding: "10px 10px 10px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
  },
  dilogBodyText: {
    marginTop: "0px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Raleway",
    color: "#0F172A",
    padding: "30px 20px 10px 20px",
    textAlign:"center"
  },
  dilogCloseBtnText: { fontSize: "18px", fontWeight: 700, color: "#007AFF", fontFamily: "Inter", margin: "14px 0px", cursor: "pointer" },
  closeIcon: {
    padding: "10px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    background: "#F1F5F9",
    gap: "12px",
    width: "24px",
    alignItems: "center",
    cursor: "pointer",
  },
  mainLogo: {
    margin: "20px 0",
  },
  paraText: {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    marginTop: "8px",
    marginBottom: "32px",
    fontWeight: 500,
  },
  button: {
    borderRadius: "8px",
    height: "46px",
    width: "100%",
    fontSize: "16px",
    border: "none",
    marginTop: "24px",
    backgroundColor: "rgba(19, 104, 158, 1)",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: 'Raleway',
  },

  headSecondgrid: {
    borderRadius: "24px",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    backgroundColor: "#fff",
    padding: "32px",
  },
  head: {
    fontWeight: 700,
    color: "#3C3C3B",
    textAlign: "left",
    fontSize: "32px",
  },

  verfiyLogostyle: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
  signupInput: {
    height: "20px",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "45px",
    color: "#1E293B",
    width: "100%",
  },
  errorMessage: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#D62626"
  },
  multiFectorHeadingStyle: {
    color: "var(--Neutral---Dark-Grey, #3C3C3B)",
    fontFamily: "Raleway",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.64px",
    maxWidth: "384px",
  },
  paregraphText: {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.154px",
    maxWidth: "384px",
    marginTop: "8px",
  },
  smsPeregraph: {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  radioStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "22px",
    cursor: "pointer",
  },

  smsTxt: {
    color: "var(--Text-Black, #151515)",
    fontFamily: "Raleway",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.198px",
  },
  bottomTextCode: { display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", flexWrap:"wrap" },
  bottomLink: {
    color: "var(--Text-Black, #13689E)",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    textDecoration: "none",
    marginLeft: "5px",
  },

  // Customizable Area End
};