export const profileIcon = require("../assets/patientResources.svg");
export const notificationIcon = require("../../customisableuserprofiles/assets/notification.svg");
export const calender = require("../../customisableuserprofiles/assets/image_ (2).svg");
export const image_pill = require("../assets/image_pill.svg");
export const image_restaurant = require("../assets/image_restaurant.svg");
export const image_stethoscope = require("../assets/image_stethoscope.svg");
export const serchIcon = require("../assets/serchIcon.svg");
export const image_favorite = require("../assets/image_favorite.svg");
export const javygoter = require("../assets/javygoter.svg");
export const mainLogo = require("../assets/mainLogo.svg");
export const like = require("../assets/like.svg");
export const liked = require("../assets/liked.svg");
export const noresult = require("../assets/image_ (3).svg");
export const foodImg = require("../assets/image_recipe.svg");
export const group_top = require("../assets/group_top.png");
export const group_tops = require("../assets/group_top (1).png");
export const backButton= require("../assets/backButton.svg");
export const noFavoriteImg= require("../assets/noFavoriteImg.svg");


export const key_contacts_image = require("../assets/keycontacts_image.svg");
export const javygoterSmall = require("../assets/javygoter.svg");
export const javygoter_call = require("../assets/image_call.svg");
export const javygoterSms = require("../assets/group_sms.svg");
export const keybackgroundimage = require("../assets/key_contentbackground_image.svg");



