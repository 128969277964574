Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "pushnotifications";
exports.labelBodyText = "pushnotifications Body";
exports.endPoint = "notifications/notifications";
exports.oldNotificationendPoint = "notifications/older_notifications"
exports.markAllReadendPoint = "notifications/mark_all_as_read"

exports.btnExampleTitle = "CLICK ME";

exports.getNotificationsEndPoint = "push_notifications/push_notifications";
exports.getNotificationsAPIMethod = "GET";
// Customizable Area End
