// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment-timezone";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { ChangeEvent } from 'react';
import StorageProvider from "framework/src/StorageProvider";
export interface Props {
  id: string;
  navigation: any;
}
interface CountryCodeData {
  phone_code: string;
  name: string;
}
export interface CountryCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  step: number;

  validTypeEmail: boolean;
  validData: { email?: string; phone?: string };
  mfa: any;
  opt_meta: any;
  errorValidCode: string;
  emailError: string;
  firstModelOpen: boolean;
  modelOpenSec: boolean;
  radioBtn: number;
  mfaCode: string;
  mfaCodePhone: string;
  codeError: string;
  token: string;
  countryCodeData: CountryCodeData[];
  selectedCountry: CountryCodeData;
  dropdownOpen: boolean;
  isCountryData: string;
  searchData: string;
  phoneNumber: string,
  filteredData: CountryCodeData[];
  ipAdress: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SignupMFAController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createMfaId: string = "";
  verufyMfaInvId: string = "";
  putTimeZoneSignUpCallId: string = "";
  countryCodeApiCallId: string = "";
  getActivityApiCallId: string = "";
  getIpApiCallId: string = "";

  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      step: 0,

      validTypeEmail: false,
      validData: { email: "", phone: "" },
      mfa: {},
      opt_meta: {},
      errorValidCode: "",
      emailError: "",
      firstModelOpen: false,
      modelOpenSec: false,
      radioBtn: 0,
      mfaCode: "",
      mfaCodePhone:"",
      codeError:"",
      token: "",
      countryCodeData: [],
      dropdownOpen: false,
      isCountryData: "",
      selectedCountry: { phone_code: "+1", name: "United States" },
      searchData: "",
      phoneNumber: "",
      filteredData: [],
      ipAdress: ""
      // Customizable Area End
    };
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start

  async componentDidMount() {
    this.makeRemoteRequest();
    this.getIpAddress();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getIpApiCallId) {
        this.setState({ipAdress: responseJson.ip})       
      }

      if (responseJson) {
        this.varifyCOdeSIgnUpreceive(apiRequestCallId, responseJson);
        this.setState({ countryCodeData: this.countryCodesToDropDown(responseJson?.data)});
        this.setState({ filteredData: this.countryCodesToDropDown(responseJson?.data) });
      }
    }
    // Customizable Area End
  }
  varifyCOdeSIgnUpreceive  = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createMfaId) {
      if (responseJson.errors || responseJson.error){
        this.setState({
          emailError:  responseJson.errors[0].account || responseJson.errors[0].full_phone_number,
        });
      } else {
      this.setState({ step: 2, opt_meta: responseJson.meta, firstModelOpen: true });
    }

      
    } 
  
    else if (apiRequestCallId === this.verufyMfaInvId) {
      if(responseJson.errors){

        const errorMsg = responseJson.errors[0].pin || responseJson.errors[0].account || responseJson.errors[0].message;

        this.setState({
          errorValidCode: errorMsg
        });
      } else {
        localStorage.removeItem("allDataInvalid");
        localStorage.removeItem("weGotoMFA");
        this.setState({
          firstModelOpen: false,
        });
        this.putSignUpTimeZone();
        this.props.navigation.navigate("CustomisableUserProfiles");
        this.callApiLogActivity("signup", true);   
      }
      
    } else if (apiRequestCallId === this.putTimeZoneSignUpCallId){
      return
    }
  };

  putSignUpTimeZone =  async() => {
    const meta = JSON.parse(await getStorageData("meta"));
    const timeZone = moment.tz.guess();    

    const header = {token: meta.token};
    const formdata = new FormData()
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    formdata.append("timezone", timeZone)

    this.putTimeZoneSignUpCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeSignUpZoneEndPoint
    );

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putSignUpMethod
    ); 
    
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  
  goToNext = () => {
    this.setState({ firstModelOpen: false, modelOpenSec: false })
  }

  createMFACodeAPI = async (email: any) => {
    
    
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const meta = JSON.parse(await getStorageData("meta"));
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/accounts/send_otp`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        this.state.validTypeEmail
          ? {
            data: {
              type: "email",
              attributes: {
                email: email,
              },
            },
          }
          : {
            data: {
              type: "sms",
              attributes: {
                country_code: this.state.selectedCountry.phone_code,
                phone_number: this.state.validData.phone,
                full_phone_number:  this.state.selectedCountry.phone_code.replace("+", "") + this.state.validData.phone
              },
            },
          }
      )
    );
    this.createMfaId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  verifyMFACodeAPI = async (val: string) => {
    
    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const meta = JSON.parse(await getStorageData("meta"));
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.validTypeEmail
        ? `account/email_confirmation`
        : `/account/accounts/sms_confirmation`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.validTypeEmail ? "PUT" : "POST"
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          otp_token: this.state.opt_meta.otp_token,
          code: Number(val.replace(/-/g, '')),
        },
      }

      )
    );
    this.verufyMfaInvId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };

  phoneChange  = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      emailError: "",
      errorValidCode:"",
      validData: {
        phone: event.target.value
      },

    })
  };

  emailCodeChange = () =>{
    this.setState({
      errorValidCode: "",
    })
  };

  handle_continue_click=(values:number)=>{
    this.setState({
      step: this.state.step + 1,
      validTypeEmail: !!values,
      radioBtn: values
    })
  };

  secCLick=()=>{
    this.setState({ step: this.state.step - 1,emailError:"" })}
  
    thrCLick= ()=>{
      this.setState({ step: this.state.step - 1,emailError:"" })
    };

    closeIcon = ()=>{
      this.setState({ step: this.state.step - 1 })
    };

    closeIconSec = ()=>{
      this.setState({ step: this.state.step - 1 })
    };

    CodeChange = ()=>{
      this.setState({
        errorValidCode: "",
      })
    }

    emailOnsubmit = (emailId: string) =>{
      this.setState({
        validData: { email: emailId },
      });
    }

    phoneSubmit = (phone:string) =>{
      this.setState({
        validData: { phone: phone },
      });
    }

    receiveCode = ()=>{
      this.setState({
        modelOpenSec: true
      });
    }

    countryCodesToDropDown = (data: Array<CountryCode>) => {
      return data.map((item) => ({
        name: item.attributes.name,
        phone_code: `+${item.attributes.country_code}`
      }));
    };

    makeRemoteRequest = () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.countryCodeApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointGetCountryCodes
      );
    
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    toggleDropdown = () => {
      this.setState((prevState) => ({
          dropdownOpen: !prevState.dropdownOpen,
      }));
      this.setState({ searchData: "", filteredData: this.state.countryCodeData });
    };
    
    handleCountrySelect = (country: CountryCodeData, code: string) => {
      this.setState({
          selectedCountry: country,
          dropdownOpen: false,
          isCountryData: code,
      });
    };

    handleSearchData = (event: ChangeEvent<HTMLInputElement>) => {
      const searchData = event.target.value.toLowerCase();
      const filteredDataItem = this.state.countryCodeData.filter(item =>
        item.name.toLowerCase().includes(searchData) || item.phone_code.toLowerCase().includes(searchData)
      );
      this.setState({ 
        searchData: event.target.value,
        filteredData: filteredDataItem
      });
    };
    
    getIpAddress = () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getIpApiCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ipAddressApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({})
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    callApiLogActivity = async (type: string, isSuccess: boolean) => {
      let token = await StorageProvider.get("authToken")
      let viewProfile= `activity?action_name=signup&response=success&message=${isSuccess?'signup success':'signup failed'}&platform_type=web&ip_address=${this.state.ipAdress}&time=${moment().utc().format('HH:mm')}`
      const header = {
        "Content-Type": configJSON.apiType,
        token:token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getActivityApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        type === 'signup' && viewProfile
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
  // Customizable Area End
}
