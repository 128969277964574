// Customizable Area Start
export const dashBoardImage = require("../assets/image_dashboard_image.svg");
export const dateDrop = require("../assets/date_drop_image.svg");
export const prevDateImage = require("../assets/chartprevdate_image.svg");
export const prevNextImage = require("../assets/chartnext_image.svg");
export const takenImage = require("../assets/taken_image.svg");
export const skippedImage = require("../assets/skipped_image.svg");
export const upcomingImage = require("../assets/upcoming_image.svg");
export const sortIconimage = require("../assets/sorticon_image.svg");
export const sortCheckimage = require("../assets/sortcheck_image.svg");
export const nosortData = require("../assets/image_schedule event.svg");
export const monthNextDate = require("../assets/monthnext_image.svg");
export const monthPrevDate = require("../assets/monthprev_image.svg");
export const pheLevelHeader = require("../assets/phelevel_image.svg");
export const selectImages = require("../assets/select_image.svg");
export const redmgImages = require("../assets/redmg_image.svg");
export const greenmgImages = require("../assets/greenmg_image.svg");
// Customizable Area End