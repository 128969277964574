import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { alarm, note } from "./assets";
import moment, { MomentInput } from "moment";

// Customizable Area Start
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities"
import { RefObject, createRef } from "react";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface ReminderTime {
  attributes: {
    id?:number
    time?: string,
    name?: string,
    status?:string,
    _destroy?: boolean ,
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  preferencesOptions: any,
  addModelOpen: boolean,
  intakeData: boolean,
  updateScreen: boolean,
  dateEvent: string,
  updateData: any,
  deletePopup: boolean,
  updateDatePopUp: boolean,
  data: any,
  reminderData: any
  selectedDate: any
  initialDate: any,
  token: string,
  manu: string,
  updateReminders: any,
  updateRefill: boolean,
  addRefill: boolean,
  refillData: any,
  userType: string,
  isDatePicker: any,
  refillinput: string,
  onBoarding1: any;
  onBoarding2: any;
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  openOneScreenOnboarding: boolean;
  onBoardingBtnDisable: boolean;
  onboardingRefs: (RefObject<HTMLTableCellElement> | RefObject<HTMLButtonElement>)[];
  startDate: string | Date | MomentInput;
  editStartDate: Date;
  selectedType: string;
  moments:(dateString: string | undefined, formatString: string | undefined) => moment.Moment;
  gamificationStatus:boolean;
  preSelected: {
    date: Date | null | string,
    intake: boolean
  },
  isIndex:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Preferences extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getReminderApiCallId: string = "";
  putReminderApiCallId: string = ""
  addReminderApiCallId: string = ""
  deleteReminderApiCallId: string = ""
  deleteRefillApiCallId: string = ""
  addRefillApiCallId: string = ""
  putReminderStatusApiCallId: string = ""
  getRefillStatusApiCallId: string = ""
  putRefillApiCallId: string = ""
  getGamificationCallId: string = ""
  upadtGamificationCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];


    this.state = {
      // Customizable Area Start
      moments:moment,
      intakeData: false,
      preferencesOptions: [
        {
          title: "Treatment Reminders",
          desc: "Stay on track with treatment reminders",
          icon: alarm,
          isOn: false,
          treatmentData: []
        },
        {
          title: "Refill Alerts",
          desc: "Timely alerts for treatment refills",
          icon: note,
          isOn: false,
          treatmentData: []
        }
      ],
      addRefill: false,
      addModelOpen: false,
      data: [],
      reminderData: { type: "medication", reminders: [{ name: "Reminder 1", time: "01:00" }], date: new Date() },
      initialDate: "",
      selectedDate: "",
      deletePopup: false,
      updateDatePopUp: false,
      updateScreen: false,
      dateEvent: "this_reminder_only",
      updateData: {},
      token: "",
      manu: "",
      updateReminders: [],
      updateRefill: false,
      refillData: { frequency: 'Monthly', reminder: "01:00", date: new Date() },
      userType: "",
      isDatePicker: "",
      refillinput: '',
      onboardingRefs: [createRef<HTMLTableCellElement>(), createRef<HTMLTableCellElement>(), createRef<HTMLButtonElement>(), createRef<HTMLButtonElement>()],
      onBoarding1: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Treatment Reminders: Stay on track with timely notifications to manage your health effectively",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "Refill Alerts: Enable for timely notifications regarding treatment refills.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Add Reminder: Easily set personalized notifications to stay organized and never miss important Reminders.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Delete Reminders: Remove any unnecessary notifications to streamline your reminders.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      onBoarding2: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Treatment Reminders: Stay on track with timely notifications to manage your health effectively",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step",
        "onboarding_description": "Refill Alerts: Enable for timely notifications regarding treatment refills.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 2",
        "onboarding_description": "Add Reminder: Easily set personalized notifications to stay organized and never miss important Reminders.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 3",
        "onboarding_description": "Delete Reminders: Remove any unnecessary notifications to streamline your reminders.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      startDate: new Date(),
      editStartDate: new Date(),
      selectedType: '',
      gamificationStatus: false,
      preSelected:{
        date: new Date(),
        intake: false
      },
      isIndex:false
      // Customizable Area End
    };
    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  /* istanbul ignore next */
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      reminderData: {
          ...this.state.reminderData,
          reminders: [{ name: "Reminder 1", time: this.getConvertor()[0] }]
      },
      refillData: {
        ...this.state.refillData,
        reminder: this.getConvertor()[0]
      }    
  });
    let meta = await getStorageData("meta")
    let parseMeta = JSON.parse(meta)
    let token = await getStorageData("authToken") || parseMeta?.token
    let authToken = await getStorageData("authToken");
        if (!authToken) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }
    let type = await getStorageData("user_type")
    const rawDate = new Date();
    const formattedDate = moment(rawDate).format("DD/MM/YYYY");
    this.setState({ token: token, userType: type, isDatePicker: formattedDate }, () => {
      this.handleGetReminderApi(formattedDate)
      this.handleGetRefill()
    })
    this.getPrefOptions();

    let onboarding = await getStorageData("onBoarding");
    if (onboarding === null) {
      setTimeout(() => {
        this.setState({ openOnboarding: true });
        this.nextOnBoarding();
      }, 500)
    }
    this.getGamificationStatus();
    // Customizable Area End
  }

  /* istanbul ignore next */
  async getPrefOptions() {
    const remainderStatus = await getStorageData('remainderStatus') || ""
    const alretStatus = await getStorageData('alretStatus') || ""
    if (remainderStatus || alretStatus) {
      this.setState({
        preferencesOptions: [{ ...this.state.preferencesOptions[0], isOn: JSON.parse(remainderStatus) }, { ...this.state.preferencesOptions[1], isOn: JSON.parse(alretStatus) }]
      });
    } else if (!remainderStatus || !alretStatus) {
      this.setState({
        preferencesOptions: [
          {
            title: "Treatment Reminders",
            desc: "Stay on track with treatment reminders",
            icon: alarm,
            isOn: false,
            treatmentData: []
          },
          {
            title: "Refill Alerts",
            desc: "Timely alerts for treatment refills",
            icon: note,
            isOn: false,
            treatmentData: []
          }
        ]
      })
    }
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleRecieve(from, message)

    // Customizable Area End
  }

  // Customizable Area Start

  handleRecieve(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (apiResponse === this.getReminderApiCallId) {
        this.handleReminderResponce(responseJson)
      }
      if (apiResponse === this.getRefillStatusApiCallId) {
        this.handleRefileResponce(responseJson)
      }
      if (apiResponse === this.getGamificationCallId) {
        this.gamifiactionResponse(responseJson);
      }
      if (apiResponse === this.upadtGamificationCallId) {
        this.gamifiactinUpdate(responseJson);
      }
      { this.mainRecieve(apiResponse) }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  mainRecieve = async (apiResponse: any) => {
    if (apiResponse === this.putRefillApiCallId) {
      this.handleGetRefill()
      this.setState({ updateData: {}, updateScreen: false, updateDatePopUp: false })
    }
    if (apiResponse === this.putReminderApiCallId) {
      this.handleGetReminderApi(this.state.isDatePicker)
      this.setState({ updateData: {}, updateScreen: false, updateDatePopUp: false, dateEvent: "this_reminder_only" })
    }
    if (apiResponse === this.addReminderApiCallId) {
      this.handleGetReminderApi(this.state.isDatePicker)
      this.setState({ addModelOpen: false, reminderData: { type: "medication", reminders: [{ name: "Reminder 1", time: this.getConvertor()[0] }], date: new Date() }, startDate: new Date(), intakeData: false })
    }
    if (apiResponse === this.deleteReminderApiCallId) {
      this.handleGetReminderApi(this.state.isDatePicker)
      this.handleGetRefill()
      this.setState({ deletePopup: false, updateData: {}, updateScreen: false, dateEvent: "this_reminder_only" })
    }
    if (apiResponse === this.deleteRefillApiCallId) {
      await this.handleGetRefill()
      this.setState({ deletePopup: false, updateData: {}, updateScreen: false })
    }
    if (apiResponse === this.putReminderStatusApiCallId) {
      this.handleGetReminderApi(this.state.isDatePicker)
      this.setState({ addRefill: false, updateData: {}, updateScreen: false })
    }
    if (apiResponse === this.addRefillApiCallId) {
      await this.handleGetRefill()
      this.setState({ addRefill: false, updateData: {}, updateScreen: false, refillData: { frequency: 'Monthly', reminder: this.getConvertor()[0], date: new Date() }, refillinput: ""})
    }
  }


  nextOnBoarding = () => {
    const data = [
      {
        "title": "Treatment Reminders",
        "desc": "Stay on track with treatment reminders",
        "icon": "/static/media/alarm.c486aea3.svg",
        "isOn": true,
        "treatmentData": [
          {
            "id": "2945",
            "type": "treatment_reminder",
            "attributes": {
              "id": 2945,
              "treatment_name": "Amoxicillin",
              "treatment_type": "medication",
              "times_taken_per_day": 3,
              "status": "active",
              "intake_end_date": false,
              "date": "05-05-2024",
              "end_time": null,
              "account_id": 1029,
              "created_at": "2024-05-05T11:03:23.405+05:30",
              "updated_at": "2024-05-05T11:03:23.405+05:30",
              "reminder_times": {
                "data": [
                  {
                    "id": "7915",
                    "type": "reminder_time",
                    "attributes": {
                      "id": 7915,
                      "name": "Reminder 1",
                      "time": "01:00",
                      "status": "unread"
                    }
                  },
                  {
                    "id": "7916",
                    "type": "reminder_time",
                    "attributes": {
                      "id": 7916,
                      "name": "Reminder 2",
                      "time": "12:00",
                      "status": "unread"
                    }
                  },
                ]
              }
            }
          }
        ]
      },
      {
        "title": "Refill Alerts",
        "desc": "Timely alerts for treatment refills",
        "icon": "/static/media/note.0c5347f0.svg",
        "isOn": false,
        "treatmentData": [
          {
            "id": "186",
            "type": "refill_alert",
            "attributes": {
              "id": 186,
              "refill_name": "test",
              "refill_date": "2024-05-12",
              "refill_time": "24:00",
              "refill_frequency": "quarterly",
              "account_id": 1029,
              "refill_status": "active",
              "created_at": "2024-05-05T09:05:48.786+05:30",
              "updated_at": "2024-05-05T09:05:48.786+05:30"
            }
          }
        ]
      }
    ]

    const { onBoarding1, currentStep } = this.state;
    if (onBoarding1.length > currentStep) {
      if (currentStep === 0) {
        this.changeStep2();
      }
      if (currentStep === 1) {
      
          this.changeStep2();
       
      }
      if (currentStep === 2) {
        this.setState({ preferencesOptions: data }, () => this.changeStep2());
      }
      if (currentStep === 3) {
        this.handleUpdateScreen(0, 0);
        setTimeout(() => this.changeStep2(), 100);
        this.setState({ onBoardingBtnDisable: true });
      }
    }
    else {
      if (this.state.openOneScreenOnboarding) {
        this.props.navigation.navigate('PatientResources');
      }
      this.resetDataFunction();
    }
  }


  resetDataFunction = () => {
    this.setState({
      openOnboarding: false,
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      preferencesOptions: [
        {
          title: "Treatment Reminders",
          desc: "Stay on track with treatment reminders",
          icon: alarm,
          isOn: false,
          treatmentData: []
        },
        {
          title: "Refill Alerts",
          desc: "Timely alerts for treatment refills",
          icon: note,
          isOn: false,
          treatmentData: []
        }
      ],
      updateScreen: false, updateData: {}, updateReminders: []
    });
    this.getPrefOptions();
    this.handleGetReminderApi(this.state.isDatePicker)
    this.handleGetRefill()
  }


  handleOpneOnboarding = () => {
    this.setState({
      openOnboarding: true,
      openOneScreenOnboarding: false,
      currentStep: 0,
      preferencesOptions: [
        {
          title: "Treatment Reminders",
          desc: "Stay on track with treatment reminders",
          icon: alarm,
          isOn: false,
          treatmentData: []
        },
        {
          title: "Refill Alerts",
          desc: "Timely alerts for treatment refills",
          icon: note,
          isOn: false,
          treatmentData: []
        }
      ]
    }, () => this.nextOnBoarding())
  }

  changeStep2 = () => {
    const { onBoarding2, currentStep, onBoarding1 } = this.state;
    const onBoarding = onBoarding1;
    this.setState({
      onBoardingTitle: onBoarding[currentStep].onboarding_title,
      onBoardingText: onBoarding[currentStep].onboarding_description,
      transformHorizontal: onBoarding[currentStep].transformHorizontal,
      anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
      currentStep: currentStep + 1
    })
    const targetRef = this.state.onboardingRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }

  }

  skipOnBoarding = async () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    this.resetDataFunction();
    await setStorageData("onBoarding", "true");
  }



  /* istanbul ignore next */
  handleDatePicker = (value: any) => {
    const rawDate = new Date(value);
    const formattedDate = moment(rawDate).format("DD/MM/YYYY");
    this.setState({ isDatePicker: formattedDate })
    this.handleGetReminderApi(formattedDate)

  }


  handleChange = (event: any) => {
    const newData = { ...this.state.reminderData }
    this.setState({ reminderData: { ...newData, [event.target.name]: event.target.value } });
  }

  handleRefileChange = (event: any) => {
    this.setState({ refillinput: event.target.value })
  }
      /* istanbul ignore next */
  addData = () => {
    const reminders = { ...this.state.reminderData }
    const currentDate = new Date()
    const formattedStartDate = this.state.startDate
    ? moment(this.state.startDate as MomentInput).format('DD/MM/YYYY')
    : moment(currentDate as MomentInput).format('DD/MM/YYYY');
  
    const formattedEndDate = moment( reminders.date).format('DD/MM/YYYY')
    const times = reminders.reminders.map((item: any) => ({ date: moment(this.state.startDate as string).format('DD/MM/YYYY'), name: item.name, time: item.time }))

    let data = {
      treatment_name: reminders.name,
      treatment_type: reminders.type,
      date: formattedStartDate,
      times_taken_per_day: reminders.reminders.length,
      intake_end_date: reminders.intake_end_date,
      end_time: "",
      reminder_times_attributes: times
    }
    if(this.state.intakeData){
      data = {...data, end_time : formattedEndDate}

    }
    this.handleAddApi({ data })
  }

      /* istanbul ignore next */
  handleChangeStartDate = (date: Date | null) => {
    if (date) {
      this.setState({ startDate: date, reminderData: {...this.state.reminderData, date: date} });
    }
  };

  delate = (index: number) => {
    if (index === 0) {
      this.handleDelateApi(this.state.updateData.id)
    } else {
      this.handleDelateRefillApi(this.state.updateData.id)
    }

  }
  handleChangeTreatment = (event: any) => {
    let newData = [...this.state.reminderData?.reminders]
    const preData = [...this.state.reminderData.reminders]
    const { value } = event.target    
    const setDateReminder = moment().format('YYYY-MM-DD') === moment(this.state.startDate).format('YYYY-MM-DD')
        if (newData?.length >= Number(value)) {
            newData = newData.slice(0, Number(value))
        } else {
            const ConNum = Number(value) - newData?.length
            for (let indexMax = 0; indexMax < ConNum; indexMax++) {
                const newTime=this.getConvertor()[indexMax + preData.length]
                newData.push({ name: `Reminder ${indexMax + 1 + preData.length}`, time:setDateReminder ? newTime: `01:00`})
            }
        }
    this.setState({ reminderData: { ...this.state.reminderData, reminders: newData } });
  }

  /* istanbul ignore next */
  validation = () => {
    const { reminderData } = this.state
    let validateTime = reminderData.reminders.find((item: { time: any; }) => !item.time)
    if (!reminderData.name?.trim() || !reminderData.type || validateTime || (reminderData.intake_end_date && !reminderData.date)) {
      return true
    } else {
      return false
    }
  }

  validationForUpdate = () => {
    const { updateData } = this.state
    let validateTime = updateData?.attributes?.reminder_times?.data.find((item: { attributes: any; }) => !item.attributes.time)
    if (!this.state.updateData.attributes.treatment_name || validateTime || (updateData?.attributes.intake_end_date && !updateData.attributes.end_time)) {
      return true
    } else {
      return false
    }
  }

  /* istanbul ignore next */
  validationForRefill = () => {
    const { refillData } = this.state
    if (!this.state.refillinput?.trim() || !refillData.date || !refillData.reminder || !refillData.frequency) {
      return true
    } else {
      return false
    }
  }

  /* istanbul ignore next */
  handleUpdateTime = (event: any) => {
    const { value } = event.target
    let reminder = { ...this.state.updateData }
    const saveReminders = [...this.state.updateReminders]
    let updateData = this.state.updateData?.attributes?.reminder_times?.data
    const prevData = this.state.updateData?.attributes?.reminder_times?.data
    if (saveReminders?.length >= value) {
      let otherData = saveReminders.slice(value, saveReminders.length).map((ele: any) => ({ ...ele, attributes: { ...ele.attributes, _destroy: true } }))
      updateData = saveReminders.slice(0, value)
      reminder = { ...reminder, attributes: { ...reminder.attributes, reminder_times: { ...reminder?.attributes?.reminder_times, data: [...otherData, ...updateData] } } }
    } else {
      for (let indexMax = 0; indexMax <= value - updateData?.length; indexMax++) {
        if (saveReminders.length < value) {
          updateData.push({ attributes: { name: `Reminder ${prevData.length + 1}`, time: this.getConvertor()[indexMax]} })
        } else {
          updateData.push({ attributes: saveReminders[indexMax] })
        }
      }
    }
    this.setState({ updateData: reminder })
  }

  handleAddTime = (event: any, index: number) => {
    let newData = [...this.state.reminderData.reminders]
    const { value } = event.target
    newData[index] = { ...newData[index], time: value }
    this.setState({ reminderData: { ...this.state.reminderData, reminders: newData } });
  }

    /* istanbul ignore next */
  handleChangeReminder = (event: any, index: number) => {
    let updateData = { ...this.state.updateData }
    let data = this.state.updateData.attributes.reminder_times.data
    data[index] = { ...data[index], attributes: { ...data[index].attributes, time: event.target.value } }
    updateData = { ...updateData, attributes: { ...updateData.attributes, reminder_times: { ...updateData.attributes.reminder_times, data: data } } }
    this.setState({ updateData: updateData })
  }
  formateTime = (item: any) => {
    const currentDate = new Date()
    if (item.attributes.id && item.attributes._destroy) {
      return { _destroy: true, id: item.id, date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    } else if (item.attributes.id && !item.attributes._destroy) {
      return { id: item.id, date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    } else {
      return { date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    }
  }
  saveEvent = () => {
    const treatment = this.state.updateData.attributes
    const editEventDate = moment(this.state.startDate as string).format('DD/MM/YYYY')
    const times = treatment.reminder_times.data.map((item: any) => this.formateTime(item))

    let data: any = {
      treatment_name: treatment.treatment_name,
      treatment_type: this.state.selectedType,
      date: editEventDate,
      times_taken_per_day: 3,
      intake_end_date: treatment.intake_end_date,
      reminder_times_attributes: times
    }
    if (!this.verifyEdit()) {
      data = { ...data, end_time: moment(treatment.end_time, 'DD-MM-YYYY').format('DD/MM/YYYY') }
    }
    const formData = new FormData();
    formData.append('status', treatment.status);
    formData.append('id', treatment.id);
    this.handleUpdateStatus(formData)
    this.handleUpdateApi({ data }, treatment.id, this.state.dateEvent)
  this.setState({isIndex: false})
  }

  setReminderTimer = (item: any) => {
    const currentDate = new Date()
    if (item.attributes.id && item.attributes._destroy) {
      return { _destroy: true, id: item.id, date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    } else if (item.attributes.id && !item.attributes._destroy) {
      return { id: item.id, date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    } else {
      return { date: moment(currentDate).format('DD/MM/YYYY'), name: item.attributes.name, time: item.attributes.time }
    }

  }


    /* istanbul ignore next */
  handleChangeBtn = () => {
    const treatment = this.state.updateData.attributes;
    const editDate = moment(this.state.startDate as string).format('DD/MM/YYYY');
    const times = treatment.reminder_times.data.map((item: any) => this.setReminderTimer(item));
    let data: any = {
      treatment_name: treatment.treatment_name,
      treatment_type: this.state.selectedType,
      date: editDate,
      times_taken_per_day: 3,
      reminder_times_attributes: times,
      intake_end_date: treatment.intake_end_date
    };
  
    if(this.verifyEdit()){
       this.setState({ updateDatePopUp: true })
       return
    }
    if ((treatment.intake_end_date && treatment.end_time) || (!treatment.intake_end_date && this.state.preSelected.intake)) {
      data = { ...data, end_time: moment(treatment.end_time, 'DD-MM-YYYY').format('DD/MM/YYYY') };
       this.handleUpdateApi({ data }, treatment.id, "this_and_following_reminders");
    }else {
      this.handleUpdateApi({ data }, treatment.id, "this_reminder_only");
    }
    this.setState({isIndex:false})
    const formData = new FormData();
    formData.append('status', treatment.status);
    formData.append('id', treatment.id);
  
    this.handleUpdateStatus(formData);
  }
  handleGetReminderApi = async (value?: string) => {
    let apiUrl = value ? `${configJSON.getReminderEndPoint}?date=${value}` : configJSON.getReminderEndPoint
    this.getReminderApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: configJSON.httpGetMethod,
      token: this.state.token,
      endPoint: apiUrl,
    });
  }

  handleGetRefill = async () => {
    this.getRefillStatusApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: configJSON.httpGetMethod,
      token: this.state.token,
      endPoint: configJSON.getRefillEndPoint,
    });
  }

  handleUpdateApi = async (data: any, id: any, type: string) => {
    let updateType = type ? `?update_type=${type}` : ""
    this.putReminderApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "PUT",
      token: this.state.token,
      endPoint: configJSON.putReminderEndPoint + "/" + id + updateType,
      apiType: "String",
      body: data
    });
  }

  handleUpdateStatus = async (data: any) => {
    this.putReminderStatusApiCallId = await this.apiCall({
      method: "PUT",
      token: this.state.token,
      endPoint: configJSON.putReminderStatusEndPoint,
      apiType: "formData",
      body: data
    });
  }

  handleAddApi = async (data: any) => {
    this.addReminderApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "POST",
      token: this.state.token,
      endPoint: configJSON.postReminderEndPoint,
      apiType: "String",
      body: data
    });
  }
  handleDelateApi = async (id: any) => {
    let updateType = `?update_type=${this.state.dateEvent}`
    this.deleteReminderApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "DELETE",
      token: this.state.token,
      endPoint: configJSON.postReminderEndPoint + "/" + id + updateType,
      apiType: "String",
    });
  }
  handleDelateRefillApi = async (id: any) => {
    this.deleteRefillApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "DELETE",
      token: this.state.token,
      endPoint: configJSON.postRefillEndPoint + "/" + id,
      apiType: "String",
    });
  }
  apiCall = async (details: {
    contentType?: string;
    method: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
    token: string
  }) => {
    const { contentType, method, endPoint, body, apiType, token } = details;
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "String" ? JSON.stringify(body) : body,
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

 
      /* istanbul ignore next */
  handleChangeDate = (date: Date, index: number) => {
    let reminder = { ...this.state.reminderData };
    this.setState({ reminderData: { ...reminder, date: date } });
  };
  

  

  handleUpdateDate = (date: Date,index: number) => {
    let reminder = { ...this.state.updateData }
    this.setState({ updateData: { ...reminder, attributes: { ...reminder.attributes, end_time: date } } });
  };

        /* istanbul ignore next */
  handleUpdateScreen = (index: number, mainIndex: number) => {
    let updateData = this.state.preferencesOptions[mainIndex].treatmentData[index]
    const endDate = moment().add(1, 'days').format('DD-MM-YYYY')
    updateData = {...updateData, attributes: {...updateData.attributes, refill_date: moment(updateData.attributes?.refill_date as MomentInput, "DD-MM-YYYY"), end_time: moment(updateData.attributes.end_time ? updateData.attributes.end_time : endDate  as MomentInput, "DD-MM-YYYY")}}
    this.setState({isIndex:true, updateScreen: !this.state.updateScreen, preSelected:{intake:updateData.attributes.intake_end_date,date:updateData.attributes.end_time }, startDate: moment(updateData.attributes.date as MomentInput, "DD-MM-YYYY"), updateData: { ...updateData, index: index, mainIndex: mainIndex }, selectedType: updateData?.attributes.treatment_type, updateReminders: updateData?.attributes?.reminder_times?.data ? updateData?.attributes?.reminder_times?.data : "" });
  }

  handleIntakeData = (event: any, type: string) => {
    this.setState({ intakeData: !this.state.intakeData })
    let reminder = { ...this.state.updateData }
    if (type === "update") {
      this.setState({ updateData: { ...reminder, attributes: { ...reminder.attributes, intake_end_date: event.target.checked } } });
    } else {
      this.setState({ reminderData: { ...this.state.reminderData, intake_end_date: event.target.checked } });
    }
  }

    /* istanbul ignore next */
  switchChange = async (event: any, index: number) => {
    let updatedData = [...this.state.preferencesOptions]
    updatedData[index] = { ...updatedData[index], isOn: event.target.checked }
    this.setState({ preferencesOptions: updatedData });
    await setStorageData("remainderStatus", JSON.stringify(updatedData[0].isOn));
    await setStorageData("alretStatus", JSON.stringify(updatedData[1].isOn));

  }

  getNextDayDate = (date:string | undefined) => {
    const chekDate = date || this.state.reminderData.date;
    const nextDay = new Date(chekDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
};

  /* istanbul ignore next */
  handleReminderResponce = async (responseJson: any) => {
    const data = [...this.state.preferencesOptions]
    this.setState({startDate: moment(this.state.isDatePicker as MomentInput, "DD-MM-YYYY"),reminderData:{...this.state.reminderData, date: moment(this.state.isDatePicker, 'DD-MM-YYYY').format('DD MMM, yyyy')}})
    if (responseJson?.data?.length !== 0) {
      const res = responseJson?.data.filter((item: any) => item?.type === "treatment_reminder")
      const toggleStatus = await getStorageData("remainderStatus")      
      data[0] = { ...data[0], treatmentData: res,
         isOn: JSON.parse(toggleStatus)
       }
      this.setState({ preferencesOptions: data, refillData: {...this.state.refillData, reminder: this.getConvertor()[0], date: moment(this.state.isDatePicker as MomentInput, "DD-MM-YYYY")}})   
    } else {
      const alretStatus = await getStorageData('alretStatus') || ""
      setStorageData('remainderStatus', 'false')
      this.setState({
        preferencesOptions: [
          {
            title: "Treatment Reminders",
            desc: "Stay on track with treatment reminders",
            icon: alarm,
            isOn: false,
            treatmentData: []
          },
          {
            title: "Refill Alerts",
            desc: "Timely alerts for treatment refills",
            icon: note,
            isOn: JSON.parse(alretStatus),
            treatmentData: data[0].treatmentData
          }
        ]
      })
      this.handleGetRefill()
    }

  }

  /* istanbul ignore next */
  handleRefileResponce = async (responseJson: any) => {
    const data = [...this.state.preferencesOptions]
    if (responseJson?.data?.length !== 0) {
      const res = responseJson.data.filter((item: any) => item.type === "refill_alert")
      const toggleStatus1 = await getStorageData("alretStatus")
      data[1] = { ...data[1], treatmentData: res,
         isOn: JSON.parse(toggleStatus1)
         }
      this.setState({ preferencesOptions: data})
    } else {
      const remainderStatus = await getStorageData('remainderStatus') || ""
      setStorageData('alretStatus', 'false')
      this.setState({
        preferencesOptions: [
          {
            title: "Treatment Reminders",
            desc: "Stay on track with treatment reminders",
            icon: alarm,
            isOn: JSON.parse(remainderStatus),
            treatmentData: data[0].treatmentData
          },
          {
            title: "Refill Alerts",
            desc: "Timely alerts for treatment refills",
            icon: note,
            isOn: false,
            treatmentData: []
          }
        ]
      })
    }
  }

  /* istanbul ignore next */
  swithTrement = (event: any, index: number, mainIndex: number) => {
    const data = [...this.state.preferencesOptions]
    let treatments = data[mainIndex]?.treatmentData
    treatments[index] = { ...treatments[index], attributes: { ...treatments[index].attributes, status: treatments[index].attributes.status === "active" ? "in_active" : "active" } }
    data[mainIndex] = { ...data[mainIndex], treatmentData: treatments }
    const formData = new FormData();
    formData.append('status', treatments[index].attributes.status);
    formData.append('id', treatments[index].id);
    if (mainIndex !== 1) {
      this.handleUpdateStatus(formData)
    } else {
      const { checked } = event.target
      const updateStatus = {
        attributes: {
          refill_status: checked ? "active" : "in-active"
        }
      }
      this.handleUpdateRefillApi({ data: updateStatus }, treatments[index].id)
    }
    this.setState({ preferencesOptions: data })
  }


  switchUpdate = (event: any, index: number) => {
    let data = { ...this.state.updateData }
    let preferencesOptions = [...this.state.preferencesOptions]
    let treatments = preferencesOptions[data.mainIndex]?.treatmentData
    treatments[index] = { ...treatments[index], isOn: event.target.checked }
    preferencesOptions[data.mainIndex] = { ...preferencesOptions[data.mainIndex], treatmentData: treatments }
    if (data.mainIndex === 0) {
      data = { ...data, attributes: { ...data.attributes, status: data.attributes.status === "active" ? "in_active" : "active" } }
    } else {
      data = { ...data, attributes: { ...data.attributes, refill_status: data.attributes.refill_status === "active" ? "in_active" : "active" } }
    }
    this.setState({ updateData: data, preferencesOptions: preferencesOptions })
  }

  openManu = (item: any, setItem: any) => {
    if (item !== setItem) {
      return setItem
    } else {
      return ""
    }
  }

  reminderVal = (value: any) => {
    return value.filter((ele: any) => typeof (ele.attributes._destroy) !== undefined && !ele.attributes._destroy).length
  }

  handleChangeRefill = (e: any) => {
    let refill = this.state.refillData
    refill = { ...refill, [e.target.name]: e.target.value }
    this.setState({ refillData: refill })
  }


  handleChangeRefillDate = (value: any) => {
    let refill = this.state.refillData
    refill = { ...refill, date: value }
    this.setState({ refillData: refill })
  }

  saveRefill = () => {
    const refill = this.state.refillData
    const data = {
      attributes: {
        refill_name: this.state.refillinput,
        refill_date: moment(refill.date).format('DD/MM/YYYY'),
        refill_time: refill.reminder,
        refill_frequency: refill.frequency?.toLowerCase()
      }
    }
    this.handleAddRefillApi({ data })
  }

  handleAddRefillApi = async (data: any) => {
    this.addRefillApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "POST",
      token: this.state.token,
      endPoint: configJSON.postRefillEndPoint,
      apiType: "String",
      body: data
    });
  }
  handUpdateChange = (e: any) => {
    let updateData = this.state.updateData
    updateData = { ...updateData, attributes: { ...updateData.attributes, [e.target.name]: e.target.value } }
    this.setState({ updateData: updateData })
  }
  handleUpdateChange = (date: string) => {
    let updateData = this.state.updateData;    
    updateData = {...updateData,attributes: {...updateData.attributes,refill_date: date }};
    this.setState({ updateData: updateData });
}

  updateRefill = () => {
    const updateData = this.state.updateData;
    const data = {
      attributes: {
        refill_name: updateData.attributes.refill_name,
        refill_date: moment(updateData.attributes.refill_date).format('DD/MM/YYYY'),
        refill_time: updateData.attributes.refill_time,
        refill_frequency: updateData.attributes.refill_frequency?.toLocaleLowerCase(),
        note: updateData.attributes.note
      }
      
    }
    this.handleUpdateRefillApi({ data }, updateData.id)
  }

  handleUpdateRefillApi = async (data: any, id: any) => {
    this.putRefillApiCallId = await this.apiCall({
      contentType: configJSON.notificationApiContentType,
      method: "PUT",
      token: this.state.token,
      endPoint: configJSON.putRefillEndPoint + "/" + id,
      apiType: "String",
      body: data
    });
  }

  alertButon = () => {
    this.setState({ addRefill: true })
  }

  closeButtonModel = () => {
    this.setState({ addModelOpen: false, reminderData: { type: "medication", reminders: [{ name: "Reminder 1", time: this.getConvertor()[0] }], date: this.getNextDayDate(this.state.moments(this.state.isDatePicker, 'DD-MM-YYYY').format('DD MMM, yyyy')) }, intakeData: false, startDate: moment(this.state.isDatePicker as MomentInput, "DD-MM-YYYY")})
  }

  deleteButtonPopup = () => {
    this.setState({ deletePopup: true })
  }

   /* istanbul ignore next */
  handleDeleteReminder = () => {
    if(this.state.updateData.attributes.intake_end_date){
      this.setState({ deletePopup: true ,isIndex:false})
    }else {
      this.handleDelateApi(this.state.updateData.id)
    }
  }

  addReminderLast = () => {
    this.setState({ addModelOpen: true })
  }

  addAlertLast = () => {
    this.setState({ addRefill: true })
  }

  timerReminderOpen = () => {
    this.setState({ manu: `timereminder1` })
  }
  openFrequencyMenu = () => {
    this.setState({ manu: "time" })
  }

  timerReminderClose = () => {
    this.setState({ manu: `` })
  }

  handleReminder = (event: any, type: string) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        attributes: {
          ...prevState.updateData.attributes,
          [type]: event.target.value

        }
      }
    }));
  }

  /* istanbul ignore next */
  formatedDate = (dateString: string) => {
    const date = moment(dateString, "DD/MM/YYYY");;
    const formattedDate = date.format("Do MMM, YYYY");
    return formattedDate

  }

  checkStatus = (data: any, index: number) => {
    if ((index === 0 && data.status === "active") || (index === 1 && data.refill_status === "active")) {
      return true
    } else {
      return false
    }
  }

  handleTimeChange = (event: { target: { value: string }; }) => {
    this.setState({ selectedType: event.target.value });
  };
 
  combineData = (item: { name?: string, time?: string }) => {
    return (moment(this.state.startDate).format("YYYY-MM-DD") == moment().format('YYYY-MM-DD') && moment(this.state.reminderData.date).format("YYYY-MM-DD") == moment().format('YYYY-MM-DD')) ? this.getFutureTime(item.time) : this.makeTime()
}

getCurrentTime=()=> {
  const date = new Date();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

refillCombineData = () => {  
  return moment(this.state.refillData.date).format("YYYY-MM-DD") == moment().format('YYYY-MM-DD') ? this.getFutureTime(this.state.refillData.reminder) : this.makeTime()
}

combineUpdateData = (date?:string ) => {
  return (moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')== moment().format('YYYY-MM-DD') && moment(this.state.updateData.attributes.end_time, 'DD-MM-YYYY').format('YYYY-MM-DD')== moment().format('YYYY-MM-DD'))? this.findNearestTime(this.makeTime()) :''
}

handleCloseRefill = () => {
  this.setState({
    addRefill: false,
    refillData: {
       frequency: 'Monthly', reminder: this.getConvertor()[0], date: this.state.moments(this.state.isDatePicker, 'DD-MM-YYYY').format('DD MMM, yyyy')
    }
  })
}
getFutureTime=(currentTime?:string)=>{ 
  const makeTime = this.makeTime();
  return makeTime.slice(makeTime.indexOf(currentTime as string), makeTime.length);
  }

  makeTime = () => {
    return Array.from({ length: 12 * 24 }, (values, indno) => indno).map((item, indno) => {
        const hours = Math.floor(indno / 12);
        const minutes = (indno % 12) * 5;
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime
    })
}

getConvertor = () => { 
  const makeTime = this.makeTime();
  if (this.state.intakeData) {
    return makeTime;
  } else if(this.state.updateData?.attributes?.intake_end_date === true){
      return makeTime;
  }else{
    const getNextTimes = this.getNextTimes(makeTime);
    return makeTime.slice(makeTime.indexOf(getNextTimes as string), makeTime.length);
  }
}

getNextTimes = (times: string[]) => {
  const currentTime = moment();
  const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();


  for (let time of times) {
      const [hours, minutes] = time.split(':');
      const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);
      if (timeInMinutes > currentMinutes) {
          return time;
      }
  }
  return null;
};

  /* istanbul ignore next */
findNearestTime=(times: string[])=> {
  const currentTime = this.getCurrentTime();
  const currentTimeMs = new Date(`1970-01-01T${currentTime}:00Z`).getTime();

  return times.reduce((prev, curr) => {
    const currTimeMs = new Date(`1970-01-01T${curr}:00Z`).getTime();
    const prevTimeMs = new Date(`1970-01-01T${prev}:00Z`).getTime();

    return Math.abs(currTimeMs - currentTimeMs) <= Math.abs(prevTimeMs - currentTimeMs) ? curr : prev;
  });
}
checkValidation=(formattedTimeedit:string,newCombineUpdateData:string,intake_end_date?:boolean,item?:ReminderTime)=>{
  return (formattedTimeedit<=newCombineUpdateData)||item?.attributes.time===formattedTimeedit
}
editTakeFilterTime = (date?: string, intake_end_date?: boolean, item?: ReminderTime) => {
  const makingData = this.makeTime().filter((value: string) => {
      const newCombineUpdateData = this.combineUpdateData(date)
      const checkValidation = this.checkValidation(value, newCombineUpdateData, intake_end_date, item)      
      return !checkValidation && value
  })
  
  if (!makingData.includes(item?.attributes?.time as string)) {
    makingData.unshift(item?.attributes?.time as string);
  }
  
  return makingData;
}

  /* istanbul ignore next */
checkValidationRefill=(formattedTimeedit:string,newCombineUpdateData:string ,item?:string)=>{
  return (formattedTimeedit<=newCombineUpdateData)||item ===formattedTimeedit
}

  /* istanbul ignore next */
editTakeFilterTimeRefill = (date?: string, item?: string) => {
  const makingData = this.makeTime().filter((value: string) => {
      const newCombineUpdateData = this.combineUpdateData(date)
      const checkValidation = this.checkValidationRefill(value, newCombineUpdateData, item)
      
      return !checkValidation && value
  })

  if (!makingData.includes(item as string)) {
    makingData.unshift(item as string)
  }
  return makingData;
}

  /* istanbul ignore next */
treatmentType = () => {
  this.setState({ manu: "type" })
}


  gamificationSwitchChange = (event: any) => {
    this.putGamifiactionStatus(event.target.checked);
  }

  putGamifiactionStatus = async (stetus: boolean) => {
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.upadtGamificationCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `reminders/gamification_notification?status=${stetus}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({}));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gamifiactinUpdate = (response: any) => {
    if (response.message === "status update successfully") {
      this.getGamificationStatus();
    }
  }

  getGamificationStatus = async () => {
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getGamificationCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `reminders/gamification_notification_status`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gamifiactionResponse = (response: any) => {
    if (response.data) {
      this.setState({ gamificationStatus: response.data.gamification_notification });
    }
  }

      /* istanbul ignore next */
  verifyTime = () => {
    const {date , intake} = this.state.preSelected
    return (moment(date).format("DD/MM/YYYY") === moment(this.state.updateData?.attributes?.end_time).format("DD/MM/YYYY")) && (intake === this.state.updateData?.attributes?.intake_end_date)
  }

    /* istanbul ignore next */
  verifyEdit = () => {
    const {date , intake} = this.state.preSelected
    const isSameDate = moment(date).format("DD/MM/YYYY") === moment(this.state.updateData?.attributes?.end_time).format("DD/MM/YYYY");
    const isIntakeEndDate = intake && this.state.updateData?.attributes?.intake_end_date;
    return isSameDate && isIntakeEndDate;  
  }
  // Customizable Area End
}