//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import {
  Box, styled
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar"
import WebHeader from "../../../components/src/WebHeader.web";
import { key_contacts_image, javygoterSmall, javygoter_call, javygoterSms, keybackgroundimage } from "./assets";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController.web";
// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
/* istanbul ignore next */
    return (
      // Customizable Area Start
      <div>
        <ThemeProvider theme={theme}>
          <Box style={{ display: "flex", position: "relative" }}>
            <SideBar navigation={this.props.navigation} />
            <KeyMainContainerkeybox>
              <KeyContactsHeader>
                <WebHeader title={"Key Contacts"} icon={key_contacts_image} />
              </KeyContactsHeader>
              <KeyDataMainDiv>
                <KeyDataBoxHeader>
                  <KeyDataBoxInner>
                    <KeyTittleBorderDiv></KeyTittleBorderDiv>
                    <KeyDataTittleBox>
                      <KeyDataTittleOneBold>{configJSON.keycontentTittles}</KeyDataTittleOneBold>
                      <KeyDataTittleNormal>{configJSON.keycontentSubTittles}</KeyDataTittleNormal>
                      <span data-testid="refonBording" ref={this.state.onboardingRefs[0]}></span>
                    </KeyDataTittleBox>
                  </KeyDataBoxInner>
                  <div><img src={javygoterSmall} alt="Javygoter" /></div>
                </KeyDataBoxHeader>

                <KeyDataMainBox>
                  {this.state.keycontacts?.map((keydata, index) => {
                    return (
                      <KeyAllDataBox key={index}>
                        <KeyBoxuUpTittle>{keydata?.attributes?.heading}</KeyBoxuUpTittle>
                        <KeyDataBorderBox>
                          <KeyUserData>
                            <KeyUserWrapDiv>
                              <span><span>{keydata?.attributes?.contact_type === "Pharmacy" ? `${keydata?.attributes?.contact_type} Name:` : "Name:"}&nbsp;</span><span style={{ color: "#334155", wordWrap: "anywhere" }}>{keydata?.attributes?.name}</span></span>
                            </KeyUserWrapDiv>
                            <KeyUserWrapDiv>
                              <span><span>{keydata?.attributes?.contact_type === "Pharmacy" ? "Address:" : "Title:"}&nbsp;</span><span style={{ color: "#334155",wordWrap: "anywhere" }}>{keydata?.attributes?.contact_type === "Pharmacy" ? keydata?.attributes?.address : keydata?.attributes?.title}</span></span>
                            </KeyUserWrapDiv>
                            <KeyUserWrapDiv>
                              <span><span>Email:&nbsp;</span><span data-testid="key-email" onClick={() => window.location.href = `mailto:${keydata?.attributes?.email}`} style={{ color: "#2563EB",wordWrap: "anywhere",cursor: "pointer" }}>{keydata?.attributes?.email}</span></span>
                            </KeyUserWrapDiv>
                          </KeyUserData>
                          <KeyCallSmsMain>
                            <KeySmsCallDiv>
                              <KeyCallImage src={javygoter_call} data-testid="key-call" onClick={() => window.location.href = `tel:${keydata?.attributes?.phone_number}`}  alt="javygotercall" />
                            </KeySmsCallDiv>
                            <KeySmsCallDiv>
                              <KeySmsImage data-testid="key-sms" style={{cursor:this.state.textinbox ? "pointer" : "not-allowed"  }} src={javygoterSms} alt="javygotersms" onClick={() => {if(this.state.textinbox ){ window.location.href = `sms:${keydata?.attributes?.phone_number}`}}} />
                            </KeySmsCallDiv>
                          </KeyCallSmsMain>
                        </KeyDataBorderBox>
                      </KeyAllDataBox>
                    )
                  })}
                </KeyDataMainBox>
              </KeyDataMainDiv>
                <KeyBackgroundImage src={keybackgroundimage} />
            </KeyMainContainerkeybox>
          </Box>

        </ThemeProvider>
        <WebOnboarding
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable && !this.state.openOneScreenOnboarding}
        />
      </div>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const KeyMainContainerkeybox = styled(Box)({
  width: "100%",
  justifyItems: "center",
  justifyContent: "conter",
  fontFamily: "Raleway",
  background: '#F4F4F4',
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
});

const KeyContactsHeader = styled('div')({
  background: "#FFFFFF",
  "& .icon": {
    width: "30px",
    marginRight: "18px",
    height: "38px",
  },
});

const KeyTittleBorderDiv = styled('div')({
  background: "#13689E",
  padding: "0px 4px",
  height: "52px",
  borderRadius: "4px",
  marginRight: "16px",
});

const KeyDataMainDiv = styled('div')({
  maxWidth: "1128px",
  marginBottom: "175px",
  margin: "28px 35px",
  display: "flex",
  flexDirection: 'column',
  "@media (max-width: 500px)": {
    marginBottom: "170px",
  },
  "@media (max-width : 430px)": {
    marginBottom: "145px"
  },
  "@media (max-width : 375px)": {
    marginBottom: "120px",
  },
});

const KeyDataBoxHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 450px)": {
    marginBottom:"20px"
}, 
});

const KeyDataBoxInner = styled('div')({
  display: "flex",
  alignItems: "center"
});

const KeyDataTittleBox = styled('div')({
  display: 'flex',
  flexDirection: "column",
  gap: "4px",
  "@media (max-width: 400px)": {
    gap: "16px",
  },
});

const KeyDataTittleOneBold = styled('div')({
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Raleway"
});

const KeyDataTittleNormal = styled('div')({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Raleway",
  color: "#64748B",
  height: "16px",
  display: "flex",
  alignItems: "center"
});

const KeyDataMainBox = styled('div')({
  paddingTop: "40px",
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
});

const KeyAllDataBox = styled('div')({
  width: "360px",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight:"312px",
  "@media (max-width: 500px)": {
    width: "100%",
  },
});

const KeyBoxuUpTittle = styled('div')({
  fontWeight: "600",
  fontSize: "16px",
  fontFamily: "Raleway",
  minHeight: "56px",
  lineHeight: "28px",
  wordWrap: "anywhere",
});

const KeyDataBorderBox = styled('div')({
  border: "1px solid #CBD5E1",
  borderRadius: "12px",
  height: "240px",
  marginTop: "16px",
  background: "#F8FAFC",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "@media (max-width: 850px)": {
    minHeight: "240px",
    height:"auto",
  },
});

const KeyUserData = styled('div')({
  padding: "22px 16px 0px 16px",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Raleway",
  lineHeight: "24px",
});

const KeyUserWrapDiv = styled('div')({
  paddingBottom: "8px",
  display: "flex",
  minHeight: "24px",
  alignItems: "center",
  fontWeight: 500,
});

const KeyCallSmsMain = styled('div')({
  display: "flex",
  padding: "0px 16px 22px 16px",
  gap: "10px",
  cursor: "pointer"
});

const KeySmsCallDiv = styled('div')({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "28px",
  height: "28px"
});

const KeyCallImage = styled('img')({
  height: "18px",
  width: "18px"
});

const KeySmsImage = styled('img')({
  height: "17.5px",
  width: "22.5px"
});

const KeyBackgroundImage = styled('img')({
  position: "absolute", 
  right: 0, 
  bottom: 0, 
  objectFit: "contain", 
  width: "70vw",
  width: "25vw",
  "@media (max-width: 1390px)": {
      width: "28vw",
  },
  "@media (max-width: 1300px)": {
    width: "30vw",
  }, 
  "@media (max-width: 1200px)": {
    width: "33vw",
  },
  "@media (max-width : 1000px)": {
    width: "40vw",
  },
  "@media (max-width : 892px)": {
    width: "48vw",
  },
  "@media (max-width : 700px)": {
    width: "63vw"
  },
  "@media (max-width : 550px)": {
    width: "78vw"
  },

});

// Customizable Area End
