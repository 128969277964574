import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  Typography,
  Divider,
  Switch,
  Modal,
  Input,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SideBar from "../../../components/src/SideBar"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import { notificationSmall } from "./assets";
// Customizable Area End
const RedioIcon = ({ url }: any) => {
  return (
    <img src={url} />
  )
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#13689E",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        paddingRight: "4px !important",
        "&:focus": {
          backgroundColor: 'none'
        }
      }
    },
    MuiListItem: {
      root: {
        ":hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
          fontWeight: 600,
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected ": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop: "20px"
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "12px",
        background: "#F8FAFC",
        position: "absolute",
        top: "266px",
        left: "732px",
      },
      list: {
        color: "#13689E",
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

      },
    },
    MuiPickersDay: {   
      daySelected: {
        
        "&:hover": {
          backgroundColor: "#D1EDFF !important",
        },
        backgroundColor: "#D1EDFF !important",
        color: "#13689E !important",
        borderRadius: "50%",
        border: "0.8px solid #68B9EC"
    },
    current: { color: "none !important" },

 
  },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        "& p": {
          color: "#0F172A",
          fontSize: "11.13px",
          fontWeight: 700,
          textAlign: "center",
          cursor:"context-menu",
          fontFamily: "Raleway !important",
        },
      },
      dayLabel: {
        fontSize: "11.13px !important",
        color: "#64748B",
        fontWeight:500,
        cursor:"context-menu",
        fontFamily: "Raleway !important",
      },
    },
    MuiPopover: {
      paper: {
        border: "0.8px solid #E2E8F0",
       borderRadius:"12.73px"
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: "Raleway !important",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    
  } as  {
    MuiPickersDay?: {
      daySelected?: {
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
    };
    MuiIconButton?: {
      root?: {
        "&:hover"?: {
          backgroundColor: string;
        };
      };
    };
  },
});

import PreferencesController, { Props, configJSON } from "./PreferencesController.web";
import { checkRedioImg, closeButton, reminder, selectArrow, unCheckRedioImg, preferenceIcon, tooltip, selectImage } from "./assets";
import WebHeader from "../../../components/src/WebHeader.web";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment, { MomentInput } from "moment";
export const AntSwitch2 = withStyles((theme) => ({
  root: {
    width: 33,
    margin: theme.spacing(1),
    marginRight: "2px",
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translate(15px,1px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: '#13689E',
      },
    },
    '& .MuiIconButton-label-66': {
      marginTop: "1px !important",
      marginLeft: "2px !important",
    },
    '&$focusVisible $thumb': {
      color: '#13689E',
      border: '6px solid #fff',
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#13689E !important'
    },
  },
  track: {
    borderRadius: 29 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#EFEFEF",
  },
  thumb: {
    height: 15,
    width: 15,
  },
  checked: {},
  focusVisible: {},
}))(Switch);

// Customizable Area End
export const AntSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 48,
    height: 31,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      marginTop: "1px",
      marginLeft: "2px",
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: '#13689E',
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#13689E !important'
    },
    '&$focusVisible $thumb': {
      color: '#13689E',
      border: '6px solid #fff',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    width: 27,
    height: 27,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);
export default class Preferences extends PreferencesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  updateData = () => {
    return (<>
      {
        this.state.updateData.attributes.reminder_times?.data.map((item: any, i: number) => {
          if (typeof (item.attributes._destroy) !== undefined && !item.attributes._destroy) {
            return (
              <Box className="reminderBoxWrap">
                <Typography className="selectLable">{item.attributes.name}</Typography>
                <Select
                  labelId="demo-simple-select-helper-label"
                  value={item.attributes.time}
                  onChange={(event) => this.handleChangeReminder(event, i)}
                  className="selectTexFild"
                  disableUnderline={true}
                  id="reminderOne"
                  name='reminder'
                  data-test-id="selcetButtonTwo"
                  onOpen={() => this.setState({ manu: `reminderOne${i}` })}
                  onClose={() => this.setState({ manu: "" })}
                  open={this.state.manu === `reminderOne${i}`}
                  IconComponent={() => (
                    <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: `reminderOne${i}` })} />
                  )}
                  MenuProps={
                    { PaperProps: { style: { marginTop: '55px', marginLeft: "-23px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
                >
                  {
                    this.editTakeFilterTime(this.state.updateData.attributes.date, this.state.updateData.attributes.intake_end_date, item).map((item: string, index: number) => (

                      <MenuItem
                        key={index}
                        value={item}
                        style={{
                          borderBottom: index === 23 ? "none" : "1px solid #0000001c"
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )
          }
        }
        )}
    </>)
  }

  intakEndDate = (index: number) => {
    return <>
      {this.state.updateData?.attributes.intake_end_date && <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
        <Typography className="selectLable">Select date</Typography>
         <MuiPickersUtilsProvider utils={MomentUtils} >
              <StartDateCalendar
                style={styles.editPicker}
                autoOk
                disableToolbar
                data-test-id="changeDate"
                variant="inline"
                margin="normal"
                format="DD MMM, YYYY"
                inputProps={{ readOnly: true, 'data-testid': "editSelect_date" }}
                value={this.state.updateData?.attributes.end_time}
                minDate={moment(this.state.startDate as string)}
                onChange={(event) => this.handleUpdateDate(event, index)}
                InputProps={{
                  className: "hovermouse",
                  disableUnderline: true,
                  style: styles.pickerFields,
                }}
                keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                }
              />
            </MuiPickersUtilsProvider>
      </Box>}
    </>
  }

  /* istanbul ignore next */
  mainIndexData = (index: number) => {
    return <>
      {
        (this.state.updateData?.attributes.id && this.state.updateData.mainIndex === index) && <Box className="alert_box">
          <Box className="flex-row space-between items-center width-full ">
            <Input className="name_text" type="text" disableUnderline={true}
              onChange={(event) => this.handleReminder(event, index === 0 ? "treatment_name" : "refill_name")}
              value={this.state.updateData?.mainIndex === 0 ? this.state.updateData.attributes.treatment_name : this.state.updateData.attributes.refill_name}
            />
            <AntSwitch name="checkedC" className="toggle_button" checked={index === 0 ? this.state.updateData.attributes.status === "active" : this.state.updateData.attributes.refill_status === "active"} data-test-id="toggleButtonType" onChange={(event) => this.switchUpdate(event, this.state.updateData.index)} />          </Box>
          <Divider style={{ width: "50%", marginTop: "3px" }} />
          {this.refielAlertEditData(index)}
          
        </Box>}
    </>
  }

      /* istanbul ignore next */
  refielAlertEditData = (index: number) => {
    return <>
      {index === 0 ?
        <>
          <Box className="selectMenuMainBox">
            <Box className="selectBoxWrap">
              <Typography className="selectLable">Treatment Type</Typography>
              <Select
                placeholder="11"
                labelId="demo-simple-select-helper-label"
                name="reminderCount"
                value={this.state.selectedType}
                onChange={(event) => this.handleTimeChange(event as any)}
                className="selectTexFild"
                disableUnderline={true}
                IconComponent={() => (
                  <img src={selectArrow} style={styles.tretmentCss} onClick={() => this.treatmentType()} data-test-id="ImageButton" />
                )}
                id="reminderCount"
                data-test-id="selectOpen"
                open={this.state.manu === "type"}
                onOpen={() => this.setState({ manu: "type" })}
                onClose={() => this.setState({ manu: "" })}
                MenuProps={{ PaperProps: { style: styles.typeDataMenu }, className: "select-input-custom" }}
              >
                {["Medication", "Formula"].map((item, index, array) => (
                  <MenuItem
                    className="Medication"
                    style={{ borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                    key={item}
                    value={item.charAt(0).toLowerCase() + item.slice(1)}>
                    {item}

                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="selectBoxWrap">
              <Typography className="selectLable">Times taken per day</Typography>

              <Select
                placeholder="11"
                labelId="demo-simple-select-helper-label"
                name="reminderCount"
                value={this.reminderVal(this.state.updateData.attributes.reminder_times.data)}
                onChange={(event) => this.handleUpdateTime(event)}
                className="selectTexFild"
                disableUnderline={true}
                IconComponent={() => (
                  <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "reminderCount" })} data-test-id="ImageButton" />
                )}
                id="reminderCount"
                open={this.state.manu === "reminderCount"}
                data-test-id="selectOpen"
                onOpen={() => this.setState({ manu: "reminderCount" })}
                onClose={() => this.setState({ manu: "" })}
                MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-25px", width: "90px", cursor: "pointer", zIndex: 1000 } }, className: "select-input-custom" }}
              >
                {
                  [1, 2, 3, 4, 5].map((item, index, array) => (
                    <MenuItem
                      key={item}
                      value={item}
                      style={{ borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            {this.updateData()}
            <Box className="checkBoxWrap">
              <Typography className="selectLable">Start Date</Typography>
              <MuiPickersUtilsProvider utils={MomentUtils} >
                <StartDateCalendar
                  style={styles.editPicker}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="DD MMM, YYYY"
                  margin="normal"
                  inputProps={{ readOnly: true, 'data-testid': "add_reminder_startDate" }}
                  value={this.state.startDate as string}
                  onChange={(event) => this.handleChangeDate(event, 0)}
                  disabled
                  InputProps={{
                    id: "teststart",
                    disableUnderline: true,
                    style: styles.pickerFields,
                  }}
                  keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className="checkBoxWrap">
              <Typography className="selectLable">Intake end date</Typography>
              <AntSwitch2 name="checkedC" checked={this.state.updateData.attributes.intake_end_date} data-test-id="IntakeData" onChange={(event) => this.handleIntakeData(event, "update")} className="toggle_button" />
            </Box>
            {this.intakEndDate(index)}


          </Box>
          <Box className="BtnWrap">
            <Button
              disabled={this.validationForUpdate()}
              data-test-id="saveButton"
              onClick={this.handleChangeBtn}
              style={{ opacity: `${this.validationForUpdate() ? "0.4" : "1"}`, fontSize: "14px", backgroundColor: this.state.updateData.attributes.treatment_name ? '#13689E' : 'rgba(19, 104, 158, 0.73)' }}
              className="saveBtn"
            >Save</Button>

            <Button
              onClick={() => this.handleDeleteReminder()}
              className="deleteBtn"
              data-test-id="deleteButton"
            > <span ref={this.state.onboardingRefs[3]}>Delete</span></Button>
          </Box>
        </> :
        <>
          <Box className="selectMenuMainBox">
            <Box className="selectBoxWrap">
              <Typography className="selectLable">Select Date</Typography>
              <MuiPickersUtilsProvider utils={MomentUtils} >
                <StartDateCalendar
                  style={styles.editPicker}
                  autoOk
                  disableToolbar
                  data-test-id="selectdateType"
                  variant="inline"
                  format="DD MMM, YYYY"
                  margin="normal"
                  inputProps={{ readOnly: true, 'data-testid': "add_reminder_startDate" }}
                  value={this.state.updateData?.attributes.refill_date}
                  onChange={this.handleUpdateChange}
                  minDate={this.state.updateData?.attributes.refill_date}
                  InputProps={{
                    id: "teststart",
                    disableUnderline: true,
                    style: styles.pickerFields,
                  }}
                  keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className="selectBoxWrap">
              <Typography className="selectLable">Frequency</Typography>
              <Select
                labelId="demo-simple-select-helper-label"
                placeholder="11"
                value={this.state.updateData?.attributes.refill_frequency}
                onChange={this.handUpdateChange}
                name="refill_frequency"
                data-test-id="addReminderCount"
                className="selectTexFild"
                disableUnderline={true}
                id="demo-simple-select-helper"
                open={this.state.manu === "time"}
                onOpen={this.openFrequencyMenu}
                onClose={this.timerReminderClose}
                IconComponent={() => (
                  <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "time" })} data-test-id="image" />
                )}
                MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-25px", width: "140px" } }, className: "select-input-custom" }}
              >
                {["Monthly", "Quarterly", "Annually"].map((item, index, array) => (

                  <MenuItem
                    value={item.toLocaleLowerCase()}
                    key={item}
                    style={{ borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                  >
                    {item}
                  </MenuItem>

                ))}
              </Select>
            </Box>

            <Box className="selectBoxWrap">
              <Typography className="selectLable">Select Time</Typography>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name='refill_time'
                data-test-id="addReminder"
                value={this.state.updateData?.attributes.refill_time}
                onChange={this.handUpdateChange}
                className="selectTexFild"
                disableUnderline={true}
                open={this.state.manu === `timereminder1`}
                onOpen={this.timerReminderOpen}
                onClose={this.timerReminderClose}
                IconComponent={() => (
                  <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "timereminder1" })} />
                )}
                MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-23px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
              >
                {this.editTakeFilterTimeRefill(this.state.updateData?.attributes.refill_date, this.state.updateData?.attributes.refill_time).map((item: string, index: number) => (
                  <MenuItem
                    value={item}
                    key={index}
                    style={{
                      borderBottom: index === 23 ? "none" : "1px solid #0000001c"
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="BtnWrap">
              <Button
                data-test-id="saveButton"
                onClick={this.updateRefill}
                disabled={!this.state.updateData.attributes.refill_name}
                className="saveBtn"
                style={{ backgroundColor: this.state.updateData.attributes.refill_name ? '#13689E' : 'rgba(19, 104, 158, 0.73)', }}
              >Save</Button>
              <Button
                onClick={this.deleteButtonPopup}
                data-test-id="deleteButton"
                className="deleteBtn"

              >Delete</Button>
            </Box>
          </Box>
        </>}
    </>
  }

  updateReminders = () => {
    /* istanbul ignore next */
    return <>
      {this.state.preferencesOptions.map((item: any, index: number) =>
        <>
          {((index === 1) || index === 0) && 
          <>
            <Box className="Preference_Box_wrap" key={item.title + index}>
              <Box className="prefrence_data_box">
                <Typography className="Prefrence_Box_Heading">
                  {item.title}
                </Typography>
                <Typography className="Prefrence_Box_Description">
                  {item.desc}
                </Typography>
              </Box>
              <Box>
                <AntSwitch name="checkedC" className="toggle_button" checked={item.isOn} id="swith" data-test-id="switchButtonType" onChange={(event) => this.switchChange(event, index)} />
              </Box>
            </Box>
            {this.mainIndexData(index)}

          </>
          } 

          {this.state.preferencesOptions.length - 1 !== index && <Divider />}
        </>)}
    </>

  }

  getRefillFrequency(refillFrequency: string) {
    switch (refillFrequency) {
      case "monthly":
        return "Monthly";
      case "quarterly":
        return "Quarterly";
      case "annually":
        return "Annually";
      default:
        return "";
    }
  }

  addReminders = () => {
    /* istanbul ignore next */

    return <>{this.state.preferencesOptions.map((item: any, index: number) =>
      <>
          <>
            <Box className="Preference_Box_wrap" key={item.title + index}>
              {!this.state.preferencesOptions.find((ele: { isOn: boolean; }) => ele.isOn) && <img src={item.icon} />}
              <Box className="prefrence_data_box">
                <Typography className="Prefrence_Box_Heading">
                  {item.title}
                </Typography>
                <Typography className="Prefrence_Box_Description" ref={this.state.onboardingRefs[index]}>
                  {item.desc}
                </Typography>
              </Box>
              <Box>
                <AntSwitch name="checkedC" checked={item.isOn} className="toggle_button" id="swith" data-test-id="switchButton" onChange={(event) => this.switchChange(event, index)} />
              </Box>
            </Box>
            {item?.treatmentData?.map((data: any, i: number) => <>
              {(data?.attributes?.id && item?.isOn) && <Box className="alert_box">
                <Box className="flex-row space-between items-center width-full ">
                  <Typography className="name_text" data-test-id="updateScreen" onClick={() => this.handleUpdateScreen(i, index)}>
                    {data?.attributes.treatment_name ? data?.attributes.treatment_name : data?.attributes.refill_name}
                  </Typography>
                  <AntSwitch name="checkedC" checked={this.checkStatus(data.attributes, index)} className="toggle_button" id="toggleButton" onChange={(event) => this.swithTrement(event, i, index)} />
                </Box>
                {(data.attributes.status === "active" || data.attributes.refill_status === "active") &&
                  <Typography className="Prefrence_Box_Description">
                    {index === 0 ? data?.attributes.reminder_times?.data?.map((time: any, number: number) =>
                      <>
                        <Typography className="reminder_reminders_text">
                          {time?.attributes.name + " • " + time?.attributes.time}
                        </Typography>
                        <Typography className="reminder1_text">
                          {data?.attributes.reminder_times?.data.length - 1 !== number && <Divider />}
                        </Typography>
                      </>) : <>
                      <Typography className="reminder_rifil_text">                        
                        {this.formatedDate(data?.attributes?.refill_date)} • {this.getRefillFrequency(data?.attributes.refill_frequency)} • {data?.attributes.refill_time}
                      </Typography>
                    </>}
                  </Typography>}
                {index === 0 && i === item?.treatmentData.length - 1 && <Button className="add_button" data-test-id="addButton" onClick={() => this.setState({ addModelOpen: true })} startIcon={<AddCircleOutlineIcon style={{ height: "25px", width: "25px" }} />} ><span ref={this.state.onboardingRefs[2]}>Add Reminder</span></Button>}
                {index === 1 && i === item?.treatmentData.length - 1 && <Button className="add_button" data-test-id="addButton" onClick={this.alertButon} startIcon={<AddCircleOutlineIcon style={{ height: "25px", width: "25px" }} />} >Add Alert</Button>}
              </Box>}
            </>)}
          </>
        {this.state.preferencesOptions.length - 1 !== index && <Divider />}
      </>)}</>
  }

  addGamificationStatus = () => {
    return <>
      <Box className="Preference_Box_wrap" >
        <img src={notificationSmall} alt="notificatio" />
        <Box className="prefrence_data_box">
          <Typography className="Prefrence_Box_Heading">
            Gamification Notification
          </Typography>
          <Typography className="Prefrence_Box_Description" >
            Toggle on to receive notifications for badges, streaks, and levels!
          </Typography>
        </Box>
        <Box>
          <AntSwitch name="checkedC" checked={this.state.gamificationStatus} className="toggle_button" id="swith" data-test-id="switchButton" onChange={(event) => this.gamificationSwitchChange(event)} />
        </Box>
      </Box>
      <Divider />
    </>
  }

   /* istanbul ignore next */
  deleteRemindersModal = () => {
    const styleDeleteBox = {
      transform: 'translate(-50%, -50%)',
      maxWidth: "60%",
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      width: 600,
      borderRadius: "24px",
      padding: 0,
      boxShadow: 24,
      p: 4,
      bgcolor: 'background.paper',
    };

    return <DeleteModal
      open={this.state.deletePopup}
      data-test-id="deleteClose"
    >
      <Box sx={styleDeleteBox}>
        <Box style={{ padding: 32 }}>
          <Box className="modelTitle" >
            <CloseIcon style={{ cursor: "pointer" }} data-test-id="closeIcon" onClick={() => this.setState({ deletePopup: false })} />
          </Box>
          <Box className="deleteTitleBox">
            <Typography className="deleteTitleText">
              {this.state.updateData.mainIndex === 0 ? "Delete Event" : "Are you sure you want to delete this refill alert?"}
            </Typography>
            <Typography className="deleteSubText">
              {this.state.updateData.mainIndex === 0 ?
                <Box className="deleteTitleBox">
                  <FormControlLabel
                    value="this_reminder_only"
                    data-test-id="radioButton"
                    control={<Radio
                      color="default"
                      id="legend"
                      checkedIcon={<RedioIcon url={checkRedioImg} />}
                      icon={<RedioIcon url={unCheckRedioImg} />}
                      checked={this.state.dateEvent === "this_reminder_only"} />}
                    label={
                      <Typography
                      style={styles.deleteReminderPopup}
                      >
                        This event
                      </Typography>}
                    onClick={() => this.setState({ dateEvent: "this_reminder_only" })}
                  />

                  <FormControlLabel
                    value="this_reminder_only"
                    control={<Radio
                      color="default"
                      id="legend"
                      checkedIcon={<RedioIcon url={checkRedioImg} />}
                      icon={<RedioIcon url={unCheckRedioImg} />}
                      checked={this.state.dateEvent === "this_and_following_reminders"} />}
                    label={
                      <Typography style={styles.deleteReminderPopup}>
                        This and future events
                      </Typography>}
                    data-test-id="radioFollowing"
                    onClick={() => this.setState({ dateEvent: "this_and_following_reminders" })}
                  />
                </Box> : "This action can't be undone and the refill alert created won't be accessible anymore."}

            </Typography>
          </Box>
        </Box>
        <Divider style={{ color: '#F1F5F9', width: "100%" }} />
        <Box style={{ padding: "0 32px 16px 32px" }}>
          <Box className="deleteBtnBox" >
            <Button className="deleteBtnCancel" data-test-id="deletePopup" onClick={() => this.setState({ deletePopup: false })}>  {this.state.updateData.mainIndex === 0 ? 'Close' : 'Cancel' }</Button>
            <Button className= {this.state.updateData.mainIndex === 0 ? "deleteBtnDeleteClosePopup" : "deleteBtnDelete" }  data-test-id="deleteButtonType" onClick={() => this.delate(this.state.updateData.mainIndex)}> {this.state.updateData.mainIndex === 0 ? "Delete" : "Delete Alert"}</Button>
          </Box>
        </Box>
      </Box>
    </DeleteModal>
  }

  /* istanbul ignore next */
  updateDateModal = () => {
    const styleDeleteBox = {
      transform: 'translate(-50%, -50%)',
      maxWidth: "60%",
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      width: 400,
      borderRadius: "24px",
      boxShadow: 24,
      p: 4,
      bgcolor: 'background.paper',
    };
    return <UpdateModal
      open={this.state.updateDatePopUp}
      data-test-id="updateclose"
    >
      <Box sx={styleDeleteBox}>
        <Box className="modelTitle" >
          <Typography className="deleteTitleText">
            Save Recurring Event
          </Typography>
          <img src={closeButton} data-test-id="addrimnderModelTwo" style={{ cursor: "pointer" }} onClick={() => this.setState({ updateDatePopUp: false })} />

        </Box>
        <Box className="deleteTitleBox">
          <FormControlLabel
            value="this_reminder_only"
            data-test-id="radioButton"
            control={<Radio
              color="default"
              id="legend"
              checkedIcon={<RedioIcon url={checkRedioImg} />}
              icon={<RedioIcon url={unCheckRedioImg} />}
              checked={this.state.dateEvent === "this_reminder_only"} />}
            label={
              <Typography
                className="radio_label"
              >
                This event
              </Typography>}
            onClick={() => this.setState({ dateEvent: "this_reminder_only" })}
          />

          <FormControlLabel
            value="this_reminder_only"
            control={<Radio
              color="default"
              id="legend"
              checkedIcon={<RedioIcon url={checkRedioImg} />}
              icon={<RedioIcon url={unCheckRedioImg} />}
              checked={this.state.dateEvent === "this_and_following_reminders"} />}
            label={
              <Typography
                className="radio_label"
              >
                This and future events
              </Typography>}
            data-test-id="radioFollowing"
            onClick={() => this.setState({ dateEvent: "this_and_following_reminders" })}
          />
        </Box>
        <Box className="deleteBtnBox" >
          <Button className="deleteBtnCancel" data-test-id="updatePopupTwo" onClick={() => this.setState({ updateDatePopUp: false })}>Close</Button>
          <Button className="deleteBtnDelete" data-test-id="saveEventButton" onClick={() => this.saveEvent()}>Save</Button>
        </Box>
      </Box>
    </UpdateModal>
  }
  addRemindersModal = () => {
    const style = {
      maxWidth: "85%",
      position: 'absolute' as 'absolute',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: "24px",
      boxShadow: 24,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
    return <TimesTakenModal
      open={this.state.addModelOpen}
      data-test-id="timeTaken"
    >
      <Box sx={style}>
        <div style={{ padding: '28px 30px 2px 30px' }}>
          <Box className="modelTitleBox">
            <Typography className="titleModal">Add Treatment Reminder</Typography>
            <img src={closeButton} data-test-id="addrimnderModel" style={{ cursor: "pointer" }} onClick={this.closeButtonModel} />
          </Box>
          <Input className="inputTreatment" id="standard-basic" placeholder="Treatment Name" disableUnderline={true} name="name" onChange={this.handleChange} />
        </div>
        <Divider style={{ color: '#F1F5F9', width: "100%" }} />
        <Box className="selectInputBox" style={{ padding: '28px 30px 2px 30px' }}>
          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Treatment Type</Typography>
            <Select
              className="selectInput"
              data-test-id="demoSelect"
              name="type"
              onChange={this.handleChange}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={this.state.reminderData.type}
              inputProps={{
                className: "select-input-custom"
              }}
              open={this.state.manu === "type"}
              onOpen={() => this.setState({ manu: "type" })}
              onClose={() => this.setState({ manu: "" })}
              IconComponent={() => (
                <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "type" })} />
              )}
              disableUnderline={true}
              MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-45px", width: "160px" } }, className: "select-input-custom" }}
            >
              {
                ["Medication", "Formula"].map((item, index, array) => (
                  <MenuItem
                    className="Medication"
                    style={{ borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                    key={item}
                    value={item.charAt(0).toLowerCase() + item.slice(1)}>
                    {item}

                  </MenuItem>
                ))}

            </Select>
          </Box>
          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Times taken per day</Typography>
            <Select
              labelId="demo-simple-select-helper-label"
              placeholder="11"
              value={this.state.reminderData?.reminders?.length}
              onChange={this.handleChangeTreatment}
              name="reminderCount"
              data-test-id="addReminderCount"
              className="selectInput"
              disableUnderline={true}
              id="demo-simple-select-helper"
              open={this.state.manu === "time"}
              onOpen={() => this.setState({ manu: "time" })}
              onClose={() => this.setState({ manu: "" })}
              IconComponent={() => (
                <img src={selectArrow} style={{ cursor: "pointer" }} data-test-id="image" onClick={() => this.setState({ manu: "time" })} />
              )}
              MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-25px", width: "90px" } }, className: "select-input-custom" }}
            >
              {[1, 2, 3,4,5].map((item, index, array) => (

                <MenuItem
                  value={item}
                  key={item}

                  style={{ justifyContent: 'center!important', borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                >
                  <div style={{ textAlign: "center", width: "100%" }}>{item}</div>
                </MenuItem>

              ))}

            </Select>
          </Box>
          {this.state.reminderData?.reminders?.map((item: any, i: number) =>
            <Box className="selectInputBoxWrap">
              <Typography className="selectInputLable">{item.name}</Typography>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name='reminder'
                data-test-id="addReminder"
                value={item?.time}
                onChange={(event) => this.handleAddTime(event, i)}
                disableUnderline={true}
                className="selectInput"
                open={this.state.manu === `timereminder${i}`}
                onOpen={() => this.setState({ manu: `timereminder${i}` })}
                onClose={() => this.setState({ manu: "" })}
                MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-23px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
                IconComponent={() => (
                  <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: `timereminder${i}` })} />
                )}
              >
                {this.combineData(item).map((item: string, index: number) => (
                  <MenuItem
                    style={{
                      borderBottom: index === 23 ? "none" : "1px solid #0000001c"
                    }}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}

              </Select>
            </Box>)}

          <Dateselect>
            <MedicationSelect>
              <div>Start Date</div>
              <MuiPickersUtilsProvider utils={MomentUtils} >
                <StartDateCalendar
                  style={styles.datePicker}
                  autoOk
                  disableToolbar
                  variant="inline"
                  inputProps={{ readOnly: true, 'data-testid': "add_reminder_startDate" }}
                  format="DD MMM, YYYY"
                  value={this.state.startDate as string}
                  minDate={moment(this.state.isDatePicker as MomentInput, "DD-MM-YYYY")}
                  margin="normal"
                  onChange={this.handleChangeStartDate}
                  InputProps={{
                    id: "teststart",
                    disableUnderline: true,
                    style: styles.pickerFields,
                  }}
                  keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                  }
                />                
              </MuiPickersUtilsProvider>

            </MedicationSelect>
          </Dateselect>
          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Intake end date</Typography>
            <div>
              <AntSwitch2 name="checkedC" checked={this.state.intakeData} data-test-id="checkBox" onClick={(event: any) => this.handleIntakeData(event, "add")} className="toggle_button" />
            </div>
          </Box>
          {this.state.intakeData === true && <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Select date</Typography>
            <MuiPickersUtilsProvider utils={MomentUtils} >
              <StartDateCalendar
               style={styles.datePicker}
                autoOk
                disableToolbar
                variant="inline"
                margin="normal"
                format="DD MMM, YYYY"
                data-test-id="selectInputType"
                inputProps={{ readOnly: true, 'data-testid': "editSelect_date" }}
                value={this.state.reminderData.date}
                minDate={moment(this.state.startDate as string)}
                
                onChange={(event) => this.handleChangeDate(event, 0)}
                InputProps={{
                  className: "hovermouse",
                  disableUnderline: true,
                  style: styles.pickerFields,
                }}
                keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                }
              />
            </MuiPickersUtilsProvider>                          
          </Box>}
        </Box>
        <Box style={{ padding: "19px 30px 2px 0px", marginBottom: '22px' }}>
          <Button
            disabled={this.validation()}
            data-test-id="saveButtonType"
            onClick={this.addData}
            className="saveBtn diable"
            style={{
              opacity: `${this.validation() ? "0.4" : "1"}`, textTransform: "none", fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '24px'
            }}
          >Save</Button>
        </Box>
      </Box>
    </TimesTakenModal>
  }


  addRefill = () => {
    const style = {
      position: 'absolute' as 'absolute',
      width: '350px',
      bgcolor: 'background.paper',
      borderRadius: "24px",
      boxShadow: 24,
      p: 4,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      "@media (max-width: 420px)": {
        width: "280px"
      },
      "@media (max-width: 360px)": {
        width: '260px'
      },

    };
    return <AddRefill
      open={this.state.addRefill}
      data-test-id="AddRedileId"
    >
      <Box sx={style}>
        <Box className="modelTitleBox" >
          <Typography className="titleModal">
            Add Refill Alert
          </Typography>
          <img src={closeButton} data-test-id="addrimnderModelImg" style={styles.refileCloseImg} onClick={() => this.handleCloseRefill()} />
        </Box>
        <Input className="inputTreatment" data-test-id="standard-basic" placeholder="Treatment Name" disableUnderline={true} name="name" onChange={this.handleRefileChange} />
        <Divider style={{ color: '#F1F5F9' }} />
        <Box className="selectInputBox">
          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Select Date</Typography>
            <MuiPickersUtilsProvider utils={MomentUtils} >
              <StartDateCalendar
                style={styles.datePicker}
                autoOk
                disableToolbar
                name="date"
                data-test-id="CloseReminderId"
                variant="inline"
                margin="normal"
                format="DD MMM, YYYY"
                inputProps={{ 'data-testid': "CloseReminderId" }}
                value={this.state.refillData.date}
                minDate={new Date()}
                onChange={(event) => this.handleChangeRefillDate(event)}
                InputProps={{
                  readOnly: true,
                  className: "hovermouse",
                  disableUnderline: true,
                  style: styles.pickerFields,
                }}
                keyboardIcon={<img src={selectImage} style={styles.pickerIcon} alt="calendar_startdate" />
                }
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Frequency</Typography>
            <Select
              labelId="demo-simple-select-helper-label"
              placeholder="11"
              onChange={this.handleChangeRefill}
              name="frequency"
              data-test-id="addReminderCount"
              className="selectInput"
              open={this.state.manu === "time"}
              IconComponent={() => (
                <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "time" })} data-test-id="image" />
              )}
              value={this.state.refillData.frequency}
              id="demo-simple-select-helper"
              MenuProps={{ PaperProps: { style: { marginTop: '60px', marginLeft: "-25px", width: "140px" } }, className: "select-input-custom" }}
              onClose={this.timerReminderClose}
              disableUnderline={true}
              onOpen={this.openFrequencyMenu}
            >
              {["Monthly", "Quarterly", "Annually"].map((item, index, array) => (

                <MenuItem
                  value={item}
                  key={item}
                  style={{ borderBottom: index === array.length - 1 ? "none" : "1px solid #0000001c" }}
                >
                  {item}
                </MenuItem>

              ))}
            </Select>
          </Box>

          <Box className="selectInputBoxWrap">
            <Typography className="selectInputLable">Select Time</Typography>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name='reminder'
              data-test-id="addReminder"
              onChange={(event) => this.handleChangeRefill(event)}
              className="selectInput"
              onClose={this.timerReminderClose}
              MenuProps={{ PaperProps: { style: { marginTop: '55px', marginLeft: "-23px", width: "100px", height: "145px" } }, className: "select-input-custom" }}
              value={this.state.refillData.reminder}
              open={this.state.manu === `timereminder1`}
              disableUnderline={true}
              IconComponent={() => (
                <img src={selectArrow} style={{ cursor: "pointer" }} onClick={() => this.setState({ manu: "timereminder1" })} />
              )}
              onOpen={this.timerReminderOpen}
            >
              {this.refillCombineData().map((item: string, index: number) => (
                <MenuItem
                  style={{
                    borderBottom: index === 23 ? "none" : "1px solid #0000001c"
                  }}
                  value={item}
                  key={index}
                >
                  {item}
                </MenuItem>
              ))}

            </Select>
          </Box>

        </Box>
        <Button
          disabled={this.validationForRefill()}
          data-test-id="saveButtonType"
          onClick={this.saveRefill}
          className="saveBtn diable"
          style={{
            opacity: `${this.validationForRefill() ? "0.4" : "1"}`, textTransform: "none", fontFamily: 'Raleway',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '24px'
          }}
        >Save</Button>
      </Box>
    </AddRefill>
  }

  updatedScreenOne = () => {
    return <>
      {this.state.updateScreen === false
        ? <>{this.addGamificationStatus()} {this.addReminders()}</>
        : <>{this.updateReminders()}</>}
    </>
  }
  Modalfuction = () => {
    return <>
      {this.deleteRemindersModal()}
      {this.updateDateModal()}
    </>
  }

  treatmentOptionData = () => {
    return <>
      <Box className="reminders_mainBox">
        <img src={reminder} className="reminder_img" />
        <Box className="remin_textBox">
          <Typography className="remin_titleText">You have no refill alerts set up.</Typography>
          <Typography className="remin_subText">Stay on top of your medications by adding a new refill alert.</Typography>
        </Box>
        <Button startIcon={<AddCircleOutlineIcon style={{ height: "25px", width: "25px" }} />} className="add_button" data-test-id="addMore" onClick={this.addAlertLast}>Add Alert</Button>
      </Box>
    </>
  }

  preferencesOptionSubData = () => {
    return <>
      <Box className="Preference_Box">
        {this.updatedScreenOne()}
        {this.Modalfuction()}
      </Box>
    </>
  }


  /* istanbul ignore next */
  preferencesOptionData = () => {
    const isTreatmentDataEmpty = this.state.preferencesOptions?.[1]?.treatmentData?.length === 0 && this?.state?.preferencesOptions?.[1]?.isOn;
    return (
      <>
        {this.state.preferencesOptions?.[0]?.treatmentData?.length === 0 && this?.state?.preferencesOptions?.[0]?.isOn ? (
          <Box className="reminders_mainBox">
            <img src={reminder} className="reminder_img" />
            <Box className="remin_textBox">
              <Typography className="remin_titleText">You have no reminders set up</Typography>
              <Typography className="remin_subText">Start managing your medication schedule by adding a new reminder.</Typography>
            </Box>
            <Button startIcon={<AddCircleOutlineIcon style={{ height: "25px", width: "25px" }} />} className="add_button" data-test-id="addMore" onClick={this.addReminderLast}>Add Reminder</Button>
          </Box>
        ) : (
          <>
            {isTreatmentDataEmpty ?
              this.treatmentOptionData() :
              this.preferencesOptionSubData()
            }
          </>
        )}
      </>
    );
  };


  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <WebOnboarding
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable && !this.state.openOneScreenOnboarding}
        />
        <Box style={{ display: "flex" }}>
          <SideBar navigation={this.props.navigation} />
          <MainContainerbox>
            <WebHeader title={"Preferences"} icon={preferenceIcon} tip={tooltip} webHeaderCelender={this.handleDatePicker} openOnBoarding={this.handleOpneOnboarding} isIndex={this.state.isIndex} />

            {this.preferencesOptionData()}

            {this.addRemindersModal()}
            {this.addRefill()}
          </MainContainerbox>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MedicationSelect = styled(Box)({
  justifyContent: "space-between",
  fontSize: "14px",
  alignItems: 'center',
  fontFamily: "Raleway",
  fontWeight: 500,
  display: "flex",
  lineHeight: "24px",
});
const Dateselect = styled('span')({
  "& .selectInput": {
    fontFamily: 'Raleway',
    border: '1px solid #E2E8F0',
    borderRadius: '26px',
    fontStyle: 'normal',
    fontSize: '12px',
    color: "#334155",
    lineHeight: '16px',
    backgroundColor: '#F1F5F9',
    paddingInline: '10px',
    paddingBlock: '3px',
    fontWeight: 500,
    height:"32px"
  },
});
const StartDateCalendar = styled(KeyboardDatePicker)({
  width: "112px", 
  alignItems: "center",
  height:"32px",
  fontWeight: 500,
  border: "1px solid #E2E8F0",
  background: '#F1F5F9',
  borderRadius: '26px',
  fontFamily:"Raleway",
  justifyContent: "center",
  color:"#334155",
  display: "flex",
  textAlign: "center",
  margin:0,
});

const TimesTakenModal = styled(Modal)({
  "& .titleModal": {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
  },
  "& .inputTreatment": {
    fontFamily: 'Raleway',
    fontWeight: 500,
    lineHeight: '24px',
    paddingBlock: '12px',
    marginTop: '12px',
    fontSize: '16px',
    fontStyle: 'normal',
    '& ::placeholder': {
      color: "#64748B",
      opacity: 1,
    }
  },
  "& .modelTitleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .saveBtn": {
    display: 'flex',
    width: '88px',
    background: '#13689E',
    color: "white",
    gap: '8px',
    marginTop: "16px",
    marginLeft: "auto",
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
  },
  "& .selectInputBox": {
    paddingBlock: '16px',
    flexDirection: "column",
    gap: '20px',
    display: "flex",
  },
  "& .selectInputBoxWrap": {
    alignItems: 'center',
    justifyContent: "space-between",
    display: "flex",
  },
  "& .selectInput": {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    color: "#334155",
    paddingBlock: '4px',
    border: '1px solid #E2E8F0',
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: '12px',
    borderRadius: '26px',
    paddingInline: '10px',
    backgroundColor: '#F1F5F9',
  },
  "& .selectInputLable": {
    fontFamily: 'Raleway',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '14px',
    color: "#151515"
  },

});
const AddRefill = styled(Modal)({
  "& .titleModal": {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
  },
  "& .modelTitleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: '16px'
  },
  "& .inputTreatment": {
    fontFamily: 'Raleway',
    fontWeight: 500,
    lineHeight: '24px',
    paddingBlock: '12px',
    marginTop: '12px',
    fontSize: '16px',
    fontStyle: 'normal',
  },
  "& .selectInputBox": {
    paddingBlock: '16px',
    flexDirection: "column",
    gap: '20px',
    display: "flex",
  },
  "& .selectInputBoxWrap": {
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
    "& li": {
      justifyContent: 'center',
    }
  },

  "& .selectInput": {
    fontFamily: 'Raleway',
    fontSize: '12px',
    border: '1px solid #E2E8F0',
    fontStyle: 'normal',
    color: "#334155",
    borderRadius: '26px',
    lineHeight: '16px',
    backgroundColor: '#F1F5F9',
    paddingInline: '10px',
    paddingBlock: '3px',
    fontWeight: 500,
  },
  "& .selectInputLable": {
    fontFamily: 'Raleway',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '24px',
    fontSize: '14px',
  },
  "& .saveBtn": {
    marginLeft: "auto",
    display: 'flex',
    width: '88px',
    justifyContent: 'center',
    background: '#13689E',
    color: "white",
    padding: '8px 16px',
    marginTop: "16px",
    alignItems: 'center',
    gap: '8px',
    borderRadius: '50px',
  },
});
const DeleteModal = styled(Modal)({
  "& .modelTitle": {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .deleteTitleBox": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .deleteTitleText": {
    color: 'var(--Neutrals-Cool-gray-900, #0F172A)',
    fontFamily: 'Raleway',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
  },
  "& .deleteSubText": {
    color: '#0F172A',
    fontFamily: 'Raleway',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
  },
  "& .deleteBtnBox": {
    justifyContent: "flex-end",
    gap: "16px",
    display: "flex",
  },
  "& .deleteBtnCancel": {
    display: 'flex',
    width: '150px',
    textAlign: "center",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBlock: "16px",
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '50px',
    cursor: "pointer",
    textTransform: "none",
    background: '#FCFCFC',
    color: "#13689E",
    marginTop: "18px",
  },
  "& .deleteBtnDelete": {
    display: 'flex',
    width: '150px',
    paddingBlock: "16px",
    justifyContent: 'center',
    fontFamily: "Raleway",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none",
    borderRadius: '50px',
    background: '#13689E',
    color: "white",
    marginTop: "18px",
    textAlign: "center",
    alignItems: 'center',
    gap: '8px',
  },
  "& .deleteBtnDeleteClosePopup": {
    display: 'flex',
    width: '150px',
    paddingBlock: "16px",
    justifyContent: 'center',
    fontFamily: "Raleway",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none",
    borderRadius: '50px',
    background: '#EF4444',
    color: "white",
    marginTop: "18px",
    textAlign: "center",
    alignItems: 'center',
    gap: '8px',
  },
})
const UpdateModal = styled(Modal)({
  "& .radio_label": {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    fontFamily: "Raleway !important",
  },
  "& .modelTitle": {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  "& .deleteTitleBox": {
    marginTop: "15px",
    display: "flex",
    gap: "16px",
    marginLeft: "17px",
    flexDirection: "column",
  },
  "& .deleteTitleText": {
    fontStyle: "normal",
    color: "#0F172A",
    fontFamily: "Raleway",
    lineHeight: "32px",
    fontWeight: 700,
    fontSize: "24px",
  },
  "& .deleteSubText": {
    color: "#0F172A",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "17px",
    fontFamily: "Raleway",
  },
  "& .deleteBtnBox": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
  },
  "& .deleteBtnCancel": {
    display: 'flex',
    width: '93px',
    fontFamily: "Raleway",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "16px",
    padding: "6px 10px",
    fontWeight: 700,
    justifyContent: 'center',
    height: '36px',
    gap: '8px',
    alignItems: 'center',
    cursor: "pointer",
    borderRadius: '50px',
    background: '#FCFCFC',
    textTransform: "none",
    marginTop: "18px",
    color: "#13689E",
  },
  "& .deleteBtnDelete": {
    width: '196px',
    padding: "6px 10px",
    display: 'flex',
    justifyContent: 'center',
    height: '36px',
    fontSize: "16px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontStyle: "normal",
    borderRadius: '50px',
    textTransform: "none",
    color: "white",
    background: '#13689E',
    textAlign: "center",
    marginTop: "18px",
    gap: '8px',
    alignItems: 'center',
  },
})
const MainContainerbox = styled(Box)({
  width: "100%",
  justifyContent: "conter",
  justifyItems: "center",
  background: '#F4F4F4',
  "@media (max-width: 1310px)": {
    "& .MuiContainer-maxWidthMd": {
      maxWidth: 700,
    },
  },
  "& .Styled\(MuiBox\)-root-1 .MuiContainer-maxWidthMd-9": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiPaper-rounded-136": {
    borderRadius: "24px",
  },
  '& .MuiContainer-maxWidthMd-9': {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiSwitch-root-45": {
    overflow: "visible",
  },
  "& .width-full": {
    width: "100%"
  },
  "& .scrollBarLogin": {
    display: "flex",
    padding: "32px",
    gap: "14px",
    alignItems: "center",
    margin: 'auto',
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 200px)": {
      height: "auto",
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      display: "none",
    },
  },
  "& .notification": {
    width: '48px',
    height: '48px'
  },
  "& .noification_box": {
    display: "flex",
    width: "100%",
    gap: "18px",
    alignItems: 'center',
    height: "48px",
  },
  "& .heading": {
    display: "flex",
    color: "#000",
    fontFeatureSettings: "'clig' off, 'liga' off",
    lineHeight: "32px",
    letterSpacing: "-0.192px",
    outline: "none",
    marginRight: '18px',
    fontFamily: "Raleway",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .Preference_Box": {
    display: "flex",
    flexDirection: "column",
    maxWidth: "752px",
    paddingInline: "16px",
    marginTop: "28px",
    borderRadius: " 24px",
    background: "#FFF",
    marginInline: '24px',
    marginBottom: "30px"
  },
  "& .Preference_Box_wrap": {
    gap: "17px",
    alignItems: "center",
    display: "flex",
    width: '100%',
    paddingBlock: "16px",
  },
  "& .Prefrence_Box_Heading": {
    color: "#000",
    fontFamily: "Raleway",
    fontWeight: 700,
    lineHeight: "24px",
    outline: "none",
    fontSize: "14px",
    fontStyle: "normal",
  },
  "& .Prefrence_Box_Description": {
    color: "#64748B",
    fontFamily: "Inter",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    outline: "none",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .name_text": {
    fontFamily: 'Raleway',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
    fontStyle: 'normal',
    cursor: "pointer"
  },
  "& .reminder_rifil_text": {
    color: '#57534E',
    fontFamily: 'Raleway',
    lineHeight: '20px',
    marginBlock: '-3px',
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: '14px',
  },
  "& .reminder_reminders_text": {
    color: '#57534E',
    fontFamily: 'Raleway',
    lineHeight: '30px',
    marginBlock: '-3px',
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: '14px',
  },
  "& .reminder_text": {
    color: 'var(--Neutrals-Warm-gray-600, #57534E)',
    fontFamily: 'Raleway',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: '14px',
  },
  "& .reminder1_text": {
    color: 'var(--Neutrals-Warm-gray-600, #57534E)',
    fontFamily: 'Raleway',
    lineHeight: '20px',
    marginBlock: '5px',
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: '14px',
  },
  "& .prefrence_data_box": {
    width: "100%"
  },
  "& .modelTitle": {
    display: "flex",
  },
  "& .modal_input": {
    width: "100%"
  },
  "& .MuiInput-underline-110": {
    borderBottom: "#F1F5F9 !important"
  },
  "& .flex-row": {
    display: "flex"
  },
  "& .space-between": {
    justifyContent: "space-between"
  },
  "& .alert_box": {
    alignItems: "center",
    width: '100%',
    marginTop: '5px',
    paddingBottom: "24"
  },
  "& .items-center": {
    alignItems: "center"
  },
  "& .add_button": {
    display: 'flex',
    padding: '10px 25px',
    color: "#FFF",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    background: '#13689E',
    marginTop: "25px",
    borderRadius: '50px',
    textTransform: "none"
  },
  "& .plus": {
    paddingInline: "2px",
    border: "2px solid white",
    borderRadius: "100%"
  },
  "& .reminders_mainBox": {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  "& .reminder_img": {
    maxWidth: "360px",
    maxHeight: "345px",
  },
  "& .remin_textBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "4px",
  },
  "& .remin_titleText": {
    color: "#151515",
    textAlign: "center",
    fontFamily: "Raleway",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  "& .remin_subText": {
    color: "#475569",
    textAlign: "center",
    fontFamily: "Raleway",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    width: '343px',
  },
  "& .select-input-custom": {
    "&:focus": {
      background: 'red'
    }
  },
  "& .selectMenuMainBox": {
    paddingBlock: '16px',
    display: "flex",
    flexDirection: "column",
    gap: '20px',
  },
  "& .selectBoxWrap": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
  },
  "& .selectLable": {
    fontFamily: 'Raleway',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  "& .selectTexFild": {
    fontFamily: 'Raleway',
    fontSize: '12px',
    fontStyle: 'normal',
    color: "#334155",
    fontWeight: 500,
    lineHeight: '16px',
    border: '1px solid #E2E8F0',
    backgroundColor: '#F1F5F9',
    paddingInline: '10px',
    paddingBlock: '5px',
    borderRadius: '26px',
  },
  "& .checkBoxWrap": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
  },
  "& .textFilde": {
    fontFamily: 'Raleway',
    fontSize: '12px',
    fontStyle: 'normal',
    color: "#334155",
    backgroundColor: '#F1F5F9',
    paddingInline: '10px',
    paddingBlock: '5px',
    borderRadius: '26px',
    fontWeight: 500,
    lineHeight: '16px',
    border: '1px solid #E2E8F0',
  },
  "& .date": {
    display: "none"
  },

  "& .BtnWrap": {
    display: "flex",
    gap: "16px",
  },
  "& .saveBtn": {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    fontFamily: 'Raleway',
    gap: '8px',
    fontSize: "14px",
    fontWeight: 700,
    color: "white",
    marginTop: "16px",
    marginLeft: "auto",
    textTransform: "capitalize",
    borderRadius: '50px',
    background: '#13689E',
  },
  " .diable": {
    opacity: "0.4",
  },
  "& .deleteBtn": {
    display: 'flex',
    width: '50%',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontFamily: 'Raleway',
    fontSize: '14px',
    fontWeight: 700,
    background: '#EF4444',
    color: "white",
    marginTop: "16px",
    marginLeft: "auto",
    textTransform: "capitalize",
    borderRadius: '50px',
  },
  "& .reminderBoxWrap": {
    justifyContent: "space-between",
    alignItems: 'center',
    display: "flex",
  },
});

const styles = {
  datePicker:{
    minWidth:'125px'
  },
  pickerFields: { fontFamily: "Raleway", fontWeight: 500, fontSize: "12px", color: "#334155", paddingLeft: "12px" },
  pickerIcon: {marginRight:'2px'},
  editPicker: {minWidth:'125px'},
  tretmentCss: { cursor: "pointer" },
  typeDataMenu: { marginTop: '60px', marginLeft: "-45px", width: "160px" },
  refileCloseImg: { cursor: "pointer" },
  deleteReminderPopup: {fontFamily: "Raleway",fontSize: "18px",fontWeight: 400,lineHeight: "26px",textAlign: "left" as "left",color:'#0F172A'}
  
}
// Customizable Area End
