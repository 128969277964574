import React, { Component } from "react";
import moment from "moment-timezone";
import { getStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";

interface State {
  timezone: string;
  initialTimezone: string;
}

interface Props {}

class TimezoneWatcher extends Component<Props, State> {
  intervalId: NodeJS.Timeout | null;
  putTimeZoneCallId: string = "";
  subScribedMessages: string[];
  send: (navMes: Message) => void;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.send = message => runEngine.sendMessage('', message);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      timezone: '',
      initialTimezone: '',
    };
    this.intervalId = null;
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
  }

    async componentDidMount() {
        this.initializeTimezone()
        this.intervalId = setInterval(this.checkTimezone, 60000) as unknown as NodeJS.Timeout;
    }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.timezone !== prevState.timezone) {
      if (this.state.initialTimezone) {
        this.callTimezoneChangeAPI(this.state.timezone);
      }      
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.putTimeZoneCallId) {
        // Handle the response from the API if necessary
      }
    }
  }

  initializeTimezone = () => {
    const currentTz = moment.tz.guess();
        this.setState({
          timezone: currentTz,
          initialTimezone: currentTz,
        });
  }

  checkTimezone = async () => {
    const currentTz = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currentTz && currentTz !== this.state.timezone) {
      this.setState({ timezone: currentTz });
    }
  }

  callTimezoneChangeAPI = async (newTimezone: string) => {
    const meta = JSON.parse(await getStorageData("meta"));
    if (!meta.token) {
      return;
    }

    const header = {
      token: meta.token,
    };
    const formdata = new FormData();
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    formdata.append("timezone", newTimezone);

    this.putTimeZoneCallId = apiRequestMessage.messageId;

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account/update_timezone'
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  render() {
    return (
      <></>
    );
  }
}

export default TimezoneWatcher;
