// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "appupdates";
exports.labelBodyText = "appupdates Body";

exports.btnExampleTitle = "CLICK ME";
exports.applatestUpdate = "/bx_block_appupdates/app_updates/get_latest_update";
// Customizable Area End
