export const notification = require("../assets/image_notifications.svg");
export const alarm = require("../assets/alarm.png");
export const note = require("../assets/note.png");
export const closeButton = require("../assets/closeButton.png");
export const selectArrow = require("../assets/selectArrow.png");
export const reminder = require("../assets/reminder.png");
export const profileIcon = require("../assets/profileIcon.svg");
export const msgIcon = require("../assets/image_forum.png");
export const notificationIcon = require("../assets/notification.svg");
export const calender = require("../assets/image_ (2).svg");
export const Avtar = require("../assets/image_ (3).png");
export const strickImg = require("../assets/group_.png");
export const checkRedioImg = require("../assets/checkRedio.svg");
export const unCheckRedioImg = require("../assets/unCheckRedio.svg");
export const strickeIcon = require("../assets/fire_icon.png");

