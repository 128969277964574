import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  Grid,
  Dialog,
  Typography,
  LinearProgress,
  Button,
  Popover,
} from "@material-ui/core";
import { createStyles, styled, Theme, withStyles } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar"
import { profileIconImg, fireIcon, emptySummary, filterListIcon, lockIcon, toolTipIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import WebHeader from "../../../components/src/WebHeader.web";
import WebOnboarding from "../../../components/src/CustomOnboardingBox.web";
import Loader from "../../../components/src/Loader.web";
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,

      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#E7E5E4",

    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);
// Customizable Area End

import GamificationController, {
  Props,
  configJSON,
  LeaderBoardItem,
} from "./GamificationController";

export default class Gamification extends GamificationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // render start Icon


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <WebOnboarding
          classForImg='stepPrompt'
          classForLeftImg='leftStepPrompt'
          classForContainer='stepsContainer'
          classForLeftThreeImg='leftStepThreePrompt'
          classForLeftFourImg='leftStepFourPrompt'
          title={this.state.onBoardingTitle}
          discripation={this.state.onBoardingText}
          anchorEl={this.state.onboardingRefs[this.state.currentStep - 1]?.current}
          next={this.nextOnBoarding}
          open={this.state.openOnboarding}
          skip={this.skipOnBoarding}
          anchorhorizontal={this.state.anchorHorizontal}
          transformhorizontal={this.state.transformHorizontal}
          btnDisable={this.state.onBoardingBtnDisable}
        />
        <Box style={{ display: "flex", flexDirection: "row-reverse", fontFamily: "Raleway" }}>
        {this.state.isLoading && <Loader />}
          <Grid style={{ backgroundColor: "#FAFAFA", height: "auto", minHeight: "100vh", width: "100%", padding: "0px 0px 45px 0px", boxSizing: "border-box" }}>
            <WebHeader title={"Profile Information"} tip={toolTipIcon} icon={profileIconImg} openOnBoarding={this.handleOpneOnboarding} />
            <Grid style={webStyle.progressMainDiv}>
              <Typography style={webStyle.yourProgressHadding}>
                Your Progress
              </Typography>
              <div>
                <img src={this.state.awardSingleData?.attributes.logo} style={webStyle.rewordImg} alt="" />
              </div>
              <OnbordingTwodiv> 
              <Typography style={webStyle.wizardTextDiv}  ref={this.state.onboardingRefs[0]} >
                {this.state.yourAwardName?.first} <span style={webStyle.wizardTextSpan}>{this.state.yourAwardName?.second} {this.state.yourAwardName?.third}</span>
              </Typography>
                <FirstDataOnbording  ></FirstDataOnbording>
              </OnbordingTwodiv>
              <Box style={{ width: '100%', maxWidth: "935px", marginTop: "25px", marginBottom: "10px" }}>
                <BorderLinearProgress style={webStyle.progressLine} variant="determinate" value={this.state.summaryPointData &&  this.state.summaryPointData.data &&  this.state.summaryPointData.data.length !== 0 ? (this.state.summaryPointData && this.state.summaryPointData.total_score / 2500) * 100 : 0} />
                <Box style={webStyle.rewardPointMainDiv}>
                  <Typography style={webStyle.rewardPoint}>
                    {this.state.summaryPointData.total_score}
                  </Typography>
                  <Typography style={webStyle.rewardPoint}>
                    {this.state.awardSingleData?.attributes?.min_count}/<span style={{ color: "#64748B" }}>2500</span>
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography style={webStyle.rewardPointText}>
                  Earn points by tracking your diet and medication to get closer to the next level!
                </Typography>
              </Box>

              <Box style={webStyle.streackStatsMainDiv}>
                <Typography style={webStyle.streackStatsHadding}>
                  Streak Stats
                </Typography>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <OnbordingTwodiv>
                  <Typography style={webStyle.streackStatsText}  ref={this.state.onboardingRefs[1]} >
                    <img src={fireIcon} alt="" />
                    Current Streaks
                  </Typography>
                  <span style={{position:"relative",top:"20px"}} />
                </OnbordingTwodiv>
                  <span style={webStyle.streackStatsText2}>
                    {this.state.streaksData.current_streak} days
                  </span>
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography style={webStyle.streackStatsText}>
                    <img src={fireIcon} alt="" />
                    Personal Best Streaks
                  </Typography>
                  <span style={webStyle.streackStatsText2}>
                    {this.state.streaksData.best_streak} days
                  </span>
                </Box>
              </Box>

              <Box style={webStyle.buttonMainDiv}>
                <Button data-test-id="open-reward" style={this.state.showRewoard ? webStyle.buttonSelect : webStyle.buttonNoSelect} onClick={this.showBagesData}>Badges</Button>
                <OnbordingThreeDiv>
                <Button  data-test-id="open-summry-point" style={this.state.showRewoard ? webStyle.buttonNoSelect : webStyle.buttonSelect} onClick={this.showPointsData} >Points Overview</Button>
                <span style={{position:"relative",top:"24px",left:"20px" }} ref={this.state.onboardingRefs[3]}></span>
                </OnbordingThreeDiv>
              </Box>

              {this.state.showRewoard && <Box style={webStyle.badgesMainDiv}>
                <Typography style={webStyle.streackStatsHadding}>
                  Medication Adherence
                </Typography>
                <Box style={webStyle.badgesScrollMainDiv}>
                  {this.state.bagesData.map((item:{id: number,badge_name: string, badge_details: string,badge_duration: string,badge_image: string,earned: boolean,date: string,badgeStatus?: string}, index: number) => (
                    
                    <Box key={item.id} onClick={() => this.openAwardDialogBox(index)} style={webStyle.badgeIcon}>
                      <Box style={webStyle.badgeIconContainer}>
                        <img src={item.badge_image} alt={item.badge_name} style={webStyle.bagesIconImg} />
                        {!item.earned && <img style={webStyle.lockIcon} src={lockIcon} alt="lock" />}
                      </Box>
                      <OnbordingThreeDiv>
                      {index=== 0 && <SecondDataOnbording ref={this.state.onboardingRefs[2]}></SecondDataOnbording>}
                      <span style={webStyle.badgeText} >{item.badge_name}</span>
                      </OnbordingThreeDiv>
                      <span style={webStyle.badgeTime}>{item.date}</span>
                    </Box>
                  ))}
                </Box>
              </Box>}

              {!this.state.showRewoard && <Box style={webStyle.pointsSummaryMainDiv}>
                <Box style={webStyle.pointsSummaryTitleMainDiv}>
                  <Typography style={webStyle.streackStatsHadding}>
                    Points Summary
                  </Typography>
                  <span data-test-id="open-filtre-popup" onClick={this.handleClick} style={{ cursor: "pointer" }}>
                    <img src={filterListIcon} alt="" />
                  </span>
                  <Popover
                    id={this.getPopoverStatus().popoverId}
                    open={this.getPopoverStatus().open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{ style: { boxShadow: " 0px 2px 8px 0px #00000014", borderRadius: "10px" } }}

                  >
                    <Box style={webStyle.popoverButtonGroup}>
                      <Box style={{ width: "100%" }}>
                        <Button onClick={this.showTodayData} style={this.state.showAll ? webStyle.selectBtn : webStyle.noselectBtn} >
                          All
                        </Button>
                      </Box>
                      <Box style={webStyle.borderBox}></Box>
                      <Box style={{ width: "100%" }}>
                        <Button onClick={this.showAllData} style={this.state.showAll ? webStyle.noselectBtn : webStyle.selectBtn} >
                          Today
                        </Button>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
                <Box style={{ borderBottom: "1px solid #E2E8F0", margin: "12px 0px" }}></Box>
                <span style={{position:"relative",top:"24px",left:"20px" }} ></span>

                {this.getFilteredData()?.length !== 0 ? <Box>
                  {this.getFilteredData()?.map((item:{id: string,attributes:{id: number,name: string,date: string,account_id: number,score: number}},index:number) => (
                    <Box key={item.id} style={webStyle.summaryTextGroup}>
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography style={webStyle.summaryDetailText}>
                          {item.attributes.name}
                        </Typography>
                        <Typography style={webStyle.summaryDate}>
                          {this.formatDate(item.attributes.date)}
                        </Typography>
                      </Box>
                      <Typography style={webStyle.summaryPoint}>
                        +{item.attributes.score}xp
                      </Typography>
                    </Box>
                  ))}
                </Box> :
                  <Box style={webStyle.emptySummaryMainDiv}>
                    <img src={emptySummary} alt="emptySummary" />
                    <Typography style={webStyle.emptySummaryText}>
                      No points earned yet. Start tracking your progress to earn points and level up!
                    </Typography>
                  </Box>
                }
              </Box>}
            </Grid>
          </Grid>
          <SideBar navigation={this.props.navigation} />
        </Box >

        <Dialog open={this.state.awardDialogBoxOpen} onClose={this.closeAwardDialogBox} style={{ height: "100vh" }} PaperProps={{ style: { borderRadius: '20px', }, }} >
          <List style={webStyle.rewordDialogMainDiv}>
            <div style={webStyle.dialogHading}>
              <span style={webStyle.dialogHadingCloseBtn} onClick={this.closeAwardDialogBox} >
                <CloseIcon />
              </span>
            </div>
            <List >
              <ListItem disableGutters style={webStyle.awardImgDiv}>
                <img style={webStyle.bagesIconImg} src={this.state.singleRewardData?.badge_image} alt={this.state.singleRewardData?.badge_name} />
                <Typography style={webStyle.awardImgHadding}>
                  {this.state.singleRewardData?.badge_name}
                </Typography>
                <Typography style={webStyle.awardImgText}>
                  {this.state.singleRewardData?.badge_details}
                </Typography>
              </ListItem>
            </List>

          </List >
        </Dialog >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  progressMainDiv: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Raleway",
    padding: "0px 20px"
  } as const,
  yourProgressHadding: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#151515",
    padding: "40px"
  },
  rewordImg: {
    width: "80px",
    height: "80px",
  },
  wizardTextDiv: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#334155",
    marginTop: "10px",
  },
  wizardTextSpan: {
    color: "#13689E",
    paddingLeft: "4px"
  },
  progressLine: {
    width: "100%",
  },
  rewardPointText: {
    color: "#64748B",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center"
  } as const,
  rewardPointMainDiv: {
    width: "100%",
    maxWidth: "935px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px"
  },
  rewardPoint: {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    '& span': {
      color: "#64748B"
    }
  },
  streackStatsMainDiv: {
    width: "100%",
    maxWidth: "552px",
    padding: "24px",
    backgroundColor: "#F5F5F4",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "20px",
    boxSizing: "border-box"
  } as const,
  streackStatsHadding: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  pointsSummaryTitleMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  popoverButtonGroup: {
    display: "flex",
    flexDirection: "column",
    width: "112px",
    backgroundColor: "#F8FAFC",
    borderRadius: "8px",
    alignItems: "center",
  } as const,
  selectBtn: {
    lineHeight: "22px",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Raleway",
    color: "#13689E",
    textTransform: "none",
    width: "100%",
    paddingTop: "10px ",
    paddingBottom: "10px "
  } as const,
  noselectBtn: {
    lineHeight: "22px",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Raleway",
    color: "#13689E",
    textTransform: "none",
    width: "100%",
    paddingTop: "10px ",
    paddingBottom: "10px "
  } as const,
  borderBox: {
    borderBottom: "1px solid #E2E8F0",
    width: "80px",
  },
  buttonMainDiv: {
    display: "flex",
    marginTop: "32px",
    gap: "16px",
  },
  buttonNoSelect: {
    color: "#334155",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    borderBottom: "3px solid transparent",
    textTransform: "none",
    padding: "8px 18px"
  } as const,
  buttonSelect: {
    color: "#13689E",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    borderBottom: "3px solid #13689E",
    borderRadius: "0px",
    textTransform: "none",
    padding: "8px 18px"
  } as const,
  streackStatsText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#0F172A",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  streackStatsText2: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#64748B",
  },
  badgesMainDiv: {
    width: "100%",
    padding: "24px",
    maxWidth: "552px",
    backgroundColor: "#F5F5F4",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "33px",
    boxSizing: "border-box"
  } as const,
  badgesScrollMainDiv: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "555px",
  } as const,
  bagesIconImg: {
    width: "72px",
    height: "72px",
  },
  badgeIcon: {
    width: "114px",
    height: "136px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  } as const,
  badgeIconContainer: {
    position: 'relative',
  } as const,
  lockIcon: {
    position: 'absolute',
    bottom: '2px',
    right: '-3px',
    zIndex: 2,
  } as const,
  badgeText: {
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#000000",
    textAlign: "center",
    marginTop: "7px"
  } as const,
  badgeTime: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#64748B",
    textAlign: "center"
  } as const,
  pointsSummaryMainDiv: {
    width: "100%",
    padding: "24px",
    maxWidth: "552px",
    backgroundColor: "#F5F5F4",
    borderRadius: "16px",
    marginTop: "33px",
    maxHeight: "644px",
    overflowY: "auto",
    boxSizing: "border-box",
  } as const,
  "& .pointsSummaryMainDiv": {
    "&::-webkit-scrollbar": {
      width: '8px',
    },
    "&::-webkit-scrollbar-track": {
      background: '#F1F5F9'
    },
    "&::-webkit-scrollbar-thumb": {
      background: '#94A3B8',
      borderRadius: '9px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#90A4AE',
    },
  },
  summaryTextGroup: {
    marginBottom: "20px"
  },
  emptySummaryMainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as const,
  emptySummaryText: {
    fontWeight: 500,
    color: "#1E293B",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    maxWidth: "503px",
  } as const,
  summaryDetailText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#1E293B"
  },
  summaryPoint: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#059669"
  },
  summaryDate: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#475569"
  },
  rewordDialogMainDiv: {
    maxWidth: "398px",
    padding: "24px",
  },
  dialogHadingCloseBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "50px",
    backgroundColor: "#E2E8F0",
    color: "#1C1B1F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    cursor: "pointer"
  },
  dialogHading: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    height: "40px",
    marginBottom: "20px",
  },
  awardImgDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "28px",
    padding: "0px 24px"
  } as const,
  awardImgHadding: {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "32px",
    color: "#000000",
  },
  awardImgText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#475569",
  }
};

const OnbordingTwodiv = styled('div')({
  display:'flex',
  flexDirection:"column"
});

const OnbordingThreeDiv = styled('div')({
  display:'flex',
  flexDirection:"column"
});

const FirstDataOnbording = styled('span')({
  position:"relative",
  top:'28px',
});

const SecondDataOnbording = styled('span')({
  position:"relative",
  right:'10px',
  top:"23px",
});

// Customizable Area End
