// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import Cookies from 'js-cookie';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Cfaccesscontrolshipaacompliance from "../../blocks/cfaccesscontrolshipaacompliance/src/Cfaccesscontrolshipaacompliance";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Interactivecalculator2 from "../../blocks/interactivecalculator2/src/Interactivecalculator2";
import Cfinviteonlysignupprocess from "../../blocks/cfinviteonlysignupprocess/src/Cfinviteonlysignupprocess";
import Cfaccesscontrols3hipaacompliance from "../../blocks/cfaccesscontrols3hipaacompliance/src/Cfaccesscontrols3hipaacompliance";
import Cfmobileandcloudconsiderations3hipaacompliance from "../../blocks/cfmobileandcloudconsiderations3hipaacompliance/src/Cfmobileandcloudconsiderations3hipaacompliance";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfbusinessassociateagreementbaahipaacompliance from "../../blocks/cfbusinessassociateagreementbaahipaacompliance/src/Cfbusinessassociateagreementbaahipaacompliance";
import Cfdatabackupandrecoveryhipaacompliance from "../../blocks/cfdatabackupandrecoveryhipaacompliance/src/Cfdatabackupandrecoveryhipaacompliance";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPasswordMFA from "../../blocks/forgot-password/src/ResetPasswordMFA.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Cfaudittrails3hipaacompliance from "../../blocks/cfaudittrails3hipaacompliance/src/Cfaudittrails3hipaacompliance";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web"
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Cfriskanalysisandmanagementhipaacompliance1 from "../../blocks/cfriskanalysisandmanagementhipaacompliance1/src/Cfriskanalysisandmanagementhipaacompliance1";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Cfcompliancedocumentationhipaacompliance from "../../blocks/cfcompliancedocumentationhipaacompliance/src/Cfcompliancedocumentationhipaacompliance";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Splashscreen from "../../blocks/splashscreen/src/SplashscreenLoader.web";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Settings from "../../blocks/settings2/src/Settings";
import Cfdatabackupandrecovery2hipaacompliance from "../../blocks/cfdatabackupandrecovery2hipaacompliance/src/Cfdatabackupandrecovery2hipaacompliance";
import Cfmobileandcloudconsiderationshipaacompliance from "../../blocks/cfmobileandcloudconsiderationshipaacompliance/src/Cfmobileandcloudconsiderationshipaacompliance";
import Cfdataencryption3hipaacompliance from "../../blocks/cfdataencryption3hipaacompliance/src/Cfdataencryption3hipaacompliance";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfaudittrails2hipaacompliance from "../../blocks/cfaudittrails2hipaacompliance/src/Cfaudittrails2hipaacompliance";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Cfaccesscontrols2hipaacompliance from "../../blocks/cfaccesscontrols2hipaacompliance/src/Cfaccesscontrols2hipaacompliance";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Cfaudittrailshipaacompliance from "../../blocks/cfaudittrailshipaacompliance/src/Cfaudittrailshipaacompliance";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import VideoEmbeddingYoutube from "../../blocks/videoembeddingyoutube/src/VideoEmbeddingYoutube";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfriskanalysisandmanagement4hipaacompliance from "../../blocks/cfriskanalysisandmanagement4hipaacompliance/src/Cfriskanalysisandmanagement4hipaacompliance";
import Cfriskanalysisandmanagement3hipaacompliance from "../../blocks/cfriskanalysisandmanagement3hipaacompliance/src/Cfriskanalysisandmanagement3hipaacompliance";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailRegistrationWithInvCode from "../../blocks/email-account-registration/src/EmailRegistrationWithInvCode";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import SignupMFA from "../../blocks/email-account-registration/src/SignupMFA.web";
import EmailRegistrationWithoutInvCode from "../../blocks/email-account-registration/src/EmailRegistrationWithoutInvCode";
import Cfongoingcompliancehipaa from "../../blocks/cfongoingcompliancehipaa/src/Cfongoingcompliancehipaa";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Cfriskanalysisandmanagementhipaacompliance from "../../blocks/cfriskanalysisandmanagementhipaacompliance/src/Cfriskanalysisandmanagementhipaacompliance";
import Cfcompliancedocumentation2hipaacompliance from "../../blocks/cfcompliancedocumentation2hipaacompliance/src/Cfcompliancedocumentation2hipaacompliance";
import Cfcyclevitaintegration from "../../blocks/cfcyclevitaintegration/src/Cfcyclevitaintegration";
import Cfdatabaseintegrationx2 from "../../blocks/cfdatabaseintegrationx2/src/Cfdatabaseintegrationx2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfmobileandcloudconsiderations2hipaacompliance from "../../blocks/cfmobileandcloudconsiderations2hipaacompliance/src/Cfmobileandcloudconsiderations2hipaacompliance";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Cfdataencryption2hipaacompliance from "../../blocks/cfdataencryption2hipaacompliance/src/Cfdataencryption2hipaacompliance";
import Cfbusinessassociateagreementbaa2hipaacompliance from "../../blocks/cfbusinessassociateagreementbaa2hipaacompliance/src/Cfbusinessassociateagreementbaa2hipaacompliance";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfuserauthentication2hipaacompliance from "../../blocks/cfuserauthentication2hipaacompliance/src/Cfuserauthentication2hipaacompliance";
import Cfdataencryptionhipaacompliance from "../../blocks/cfdataencryptionhipaacompliance/src/Cfdataencryptionhipaacompliance";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfuserauthenticationhipaacompliance from "../../blocks/cfuserauthenticationhipaacompliance/src/Cfuserauthenticationhipaacompliance";
import Preferences from "../../blocks/notificationsettings/src/Preferences.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfile.web";
import PatientResources from "../../blocks/contentmanagement/src/PatientResources.web";
import EventsSchedule from "../../blocks/events/src/EventsSchedule.web"
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement.web";
import Task from "../../blocks/tasks/src/Task.web"
import Pheleveltrend from "../../blocks/analytics/src/Pheleveltrend.web";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { setStorageData, removeStorageData } from "../../framework/src/Utilities";
import DisplayNotification from "../../components/src/DisplayNotification";
import TimezoneWatcher from "../../components/src/TimezoneWatcher.web"
import ContactSupport from "../../blocks/email-account-registration/src/ContactSupport.web"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Offline, Online } from "react-detect-offline";
import ConnectinFaildeBox from "../../components/src/ConnectinFaildeBox.web";
import Appupdates from "../../blocks/appupdates/src/Appupdates.web"
import { firebaseAPI, messaging} from "./firebase";
import {getToken, onMessage} from "firebase/messaging"

const routeMap = {
  Preferences: {
    exact: true,
    component: Preferences,
    path: "/Preferences"
  },
  Preferences1: {
    exact: true,
    component: Preferences,
    path: "/Preferences/:id"
  },
ContactSupport:{
  component:ContactSupport,
path:"/ContactSupport"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Cfaccesscontrolshipaacompliance:{
 component:Cfaccesscontrolshipaacompliance,
path:"/Cfaccesscontrolshipaacompliance"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Interactivecalculator2:{
 component:Interactivecalculator2,
path:"/Interactivecalculator2"},
Cfinviteonlysignupprocess:{
 component:Cfinviteonlysignupprocess,
path:"/Cfinviteonlysignupprocess"},
Cfaccesscontrols3hipaacompliance:{
 component:Cfaccesscontrols3hipaacompliance,
path:"/Cfaccesscontrols3hipaacompliance"},
Cfmobileandcloudconsiderations3hipaacompliance:{
 component:Cfmobileandcloudconsiderations3hipaacompliance,
path:"/Cfmobileandcloudconsiderations3hipaacompliance"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cfbusinessassociateagreementbaahipaacompliance:{
 component:Cfbusinessassociateagreementbaahipaacompliance,
path:"/Cfbusinessassociateagreementbaahipaacompliance"},
Cfdatabackupandrecoveryhipaacompliance:{
 component:Cfdatabackupandrecoveryhipaacompliance,
path:"/Cfdatabackupandrecoveryhipaacompliance"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ResetPasswordMFA:{
  component:ResetPasswordMFA,
 path:"/ResetPasswordMFA"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Gamification:{
  exact: true,
 component:Gamification,
path:"/Gamification"},
Gamification1: {
    exact: true,
    component: Gamification,
    path: "/Gamification/:id"
  },
Cfaudittrails3hipaacompliance:{
 component:Cfaudittrails3hipaacompliance,
path:"/Cfaudittrails3hipaacompliance"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
PrivacyPolicy:{
  component:PrivacyPolicy,
 path:"/PrivacyPolicy"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
Cfriskanalysisandmanagementhipaacompliance1:{
 component:Cfriskanalysisandmanagementhipaacompliance1,
path:"/Cfriskanalysisandmanagementhipaacompliance1"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Cfcompliancedocumentationhipaacompliance:{
 component:Cfcompliancedocumentationhipaacompliance,
path:"/Cfcompliancedocumentationhipaacompliance"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Splashscreen:{
 component:Splashscreen,
path:"/",
exact: true},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Settings:{
  component:Settings,
component:Settings,
path:"/Settings/:userId"},
Settings:{
component:Settings,
path:"/Settings"},
Cfdatabackupandrecovery2hipaacompliance:{
 component:Cfdatabackupandrecovery2hipaacompliance,
path:"/Cfdatabackupandrecovery2hipaacompliance"},
Cfmobileandcloudconsiderationshipaacompliance:{
 component:Cfmobileandcloudconsiderationshipaacompliance,
path:"/Cfmobileandcloudconsiderationshipaacompliance"},
Cfdataencryption3hipaacompliance:{
 component:Cfdataencryption3hipaacompliance,
path:"/Cfdataencryption3hipaacompliance"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Cfaudittrails2hipaacompliance:{
 component:Cfaudittrails2hipaacompliance,
path:"/Cfaudittrails2hipaacompliance"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Cfaccesscontrols2hipaacompliance:{
 component:Cfaccesscontrols2hipaacompliance,
path:"/Cfaccesscontrols2hipaacompliance"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
Task:{
  component:Task,
 path:"/Task"},
 Analytics:{
  component:Analytics,
 path:"/Analytics"},
 Pheleveltrend:{
  component:Pheleveltrend,
  path:"/Pheleveltrend"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailRegistrationWithInvCode:{
 component:EmailRegistrationWithInvCode,
path:"/emailregistrationwithinvcode"
},
CustomForm:{
  component: CustomForm,
 path: "/CustomForm"
 },
SignupMFA:{
  component:SignupMFA,
 path:"/SignupMFA"
 },
EmailRegistrationWithoutInvCode:{
  component:EmailRegistrationWithoutInvCode,
 path:"/EmailRegistrationWithoutInvCode",
 exact: true
 },
 Cfaudittrailshipaacompliance:{
 component:Cfaudittrailshipaacompliance,
path:"/Cfaudittrailshipaacompliance"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfriskanalysisandmanagement4hipaacompliance:{
 component:Cfriskanalysisandmanagement4hipaacompliance,
path:"/Cfriskanalysisandmanagement4hipaacompliance"},
Cfriskanalysisandmanagement3hipaacompliance:{
 component:Cfriskanalysisandmanagement3hipaacompliance,
path:"/Cfriskanalysisandmanagement3hipaacompliance"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfongoingcompliancehipaa:{
 component:Cfongoingcompliancehipaa,
path:"/Cfongoingcompliancehipaa"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Cfriskanalysisandmanagementhipaacompliance:{
 component:Cfriskanalysisandmanagementhipaacompliance,
path:"/Cfriskanalysisandmanagementhipaacompliance"},
Cfcompliancedocumentation2hipaacompliance:{
 component:Cfcompliancedocumentation2hipaacompliance,
path:"/Cfcompliancedocumentation2hipaacompliance"},
Cfcyclevitaintegration:{
 component:Cfcyclevitaintegration,
path:"/Cfcyclevitaintegration"},
Cfdatabaseintegrationx2:{
 component:Cfdatabaseintegrationx2,
path:"/Cfdatabaseintegrationx2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfmobileandcloudconsiderations2hipaacompliance:{
 component:Cfmobileandcloudconsiderations2hipaacompliance,
path:"/Cfmobileandcloudconsiderations2hipaacompliance"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Cfdataencryption2hipaacompliance:{
 component:Cfdataencryption2hipaacompliance,
path:"/Cfdataencryption2hipaacompliance"},
Cfbusinessassociateagreementbaa2hipaacompliance:{
 component:Cfbusinessassociateagreementbaa2hipaacompliance,
path:"/Cfbusinessassociateagreementbaa2hipaacompliance"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfuserauthentication2hipaacompliance:{
 component:Cfuserauthentication2hipaacompliance,
path:"/Cfuserauthentication2hipaacompliance"},
Cfdataencryptionhipaacompliance:{
 component:Cfdataencryptionhipaacompliance,
path:"/Cfdataencryptionhipaacompliance"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
 path:"/CustomisableUserProfiles"},

 PatientResources:{
  component:PatientResources,
 path:"/PatientResources/RecipesDetail/:recipesId"
},
 PatientResources:{
  component:PatientResources,
 path:"/PatientResources/:userId"},
 PatientResources:{
  component:PatientResources,
 path:"/PatientResources"},
 ContentManagement:{
  component:ContentManagement,
 path:"/ContentManagement"},
Cfuserauthenticationhipaacompliance:{
 component:Cfuserauthenticationhipaacompliance,
path:"/Cfuserauthenticationhipaacompliance"},
EventsSchedule: {
  exact: true,
  component: EventsSchedule,
  path: "/EventsSchedule"
},
EventsSchedule1: {
  exact: true,
  component: EventsSchedule,
  path: "/EventsSchedule/:id"
},
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      "Raleway",
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});


class App extends Component {
constructor(){
  super();
  this.state = {
    show: false,
    notification: {
      title: '',
      body: '',
      redirect_to: '',
      notificationId: ''
    }
  }
}
  async requestPermission() {
    //let messaging = firebase.messaging();
    if (messaging) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // Generate Token
        const token = await getToken(messaging , {
          vapidKey:
          "BCp7eUnT63rM0v3I6NnlmY7-vK20CjvQOy6f3e-zHThTQF3iLPaDF1X8Gcd0VujK0p2NmScCv1zR-9v8dGAYePo"
        });
      console.log("token:",token)
      Cookies.set('notificationToken', token);
      setStorageData("notificationToken", token)
  }
  if (permission === 'denied') {
    Cookies.remove('notificationToken');
    removeStorageData("notificationToken")
  }
}
  }

  componentDidMount(){
    this.requestPermission()
    // forground
    if (messaging) {
      console.log("register messaging")
      onMessage(messaging, (payload) => {
        const parseData = JSON.parse(payload?.data?.payload)
        setTimeout(() => {
          this.setState(
            {
              show: true,
              notification: {
                title: parseData?.data?.heading || '',
                body: parseData?.data?.message || '',
                redirect_to: parseData?.data?.redirect_to || "",
                notificationId: parseData.data.notification_id || ""
              }
            },
            () => {
              setTimeout(() => {
                this.handleCloseToast();
              }, 4000);
            }
          );
        }, 1000);
      });
    }
  }
  handleCloseToast = () => {
    this.setState({
      show: false
    })
  }
  render() {
    return (
      <>
       {this.state.show ? (
          <DisplayNotification
            title={this.state.notification.title}
            body={this.state.notification.body}
            redirect_to={this.state.notification.redirect_to}
            notificationId={this.state.notification.notificationId}
            handleCloseToast={this.handleCloseToast}
          />
        ) : (
          <></>
        )}
      <View style={{ height: '100vh', width: '100vw', overflowX: "hidden" }}>
        <ThemeProvider theme={theme}>
            <Online>
              {WebRoutesGenerator({ routeMap })}
            </Online>
            <Offline>
              <ConnectinFaildeBox />
            </Offline>
            <Appupdates />
        </ThemeProvider>
          <ToastContainer position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light" />
        {/* <ModalContainer /> */}
        <TimezoneWatcher/>
      </View>
      </>
    );
  }
}

export default App;
