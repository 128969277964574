import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";
import { RefObject, createRef } from "react";

interface OnboardingStep {
  id: number;
  onboarding_title: string;
  onboarding_description: string;
  transformHorizontal: string;
  anchorHorizontal: string;
}
interface AwardSingleData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    min_count: number;
    logo?: string;
  };
}

interface Badge {
  id: number;
  badge_name: string;
  badge_details: string;
  badge_duration: string;
  badge_image: string;
  earned: boolean;
  date: string;
}

interface WizardDataItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    min_count: number;
    logo: string;
  };
}

interface SummaryPoint {
 data:
  {
    "id": string,
    "type": string,
    "attributes": {
      "id": number,
      "name": string,
      "date": string,
      "account_id": number,
      "score": number
    }
 }[],
 total_score:number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading:boolean;
  loading: boolean;
  tabIndex: number;
  userList: Array<LeaderBoardItem>;
  filterList: Array<LeaderBoardItem>;
  page: number;
  filterFlag: boolean;
  courseIds: Array<{}>;
  userIds: Array<{}>;
  userData: Array<{}>;
  leaderBoardList: Array<{}>;
  originalList: Array<LeaderBoardItem>;
  selectedRank: {
    min: number;
    max: number;
  };
  selectedScore: {
    min: number;
    max: number;
  };
  selectedCourse: Array<string | number>;
  selectedUser: Array<string | number>;
  token: string;
  ranges: Array<Range>;
  rangeIdToDelete: string;
  createRangeModalOpen: boolean;
  rangeTextFields: Array<RangeTextField>;
  createMappingModalOpen: boolean;
  createMappingCourseId: number | null;
  createMappingRangeId: number | null;
  errors: string;
  updateMapping: boolean;
  updateRangeData: RangeData;
  ranking: Array<LeaderBoardItem>;
  leaderboard: Array<LeaderBoardItem>;
  courses: Array<CoursesResponse>;
  selectedCourseForLeaderBoard: number | null;
  selectedCourseName: string;
  userRankings: Array<LeaderBoardItem>;
  userRole: "user" | "admin" | "";
  awardDialogBoxOpen: boolean;
  showRewoard: boolean;
  awardId: number | null;
  summaryPointData: SummaryPoint;
  wizardData: WizardDataItem[];
  awardSingleData?: AwardSingleData;
  awardIndexNumber: number;
  yourAwardName:  { first?:string, second?:string, third?:string };
  anchorEl: HTMLButtonElement | null;
  showAll: boolean;
  bagesData: Badge[];
  singleRewardData: {id: number,badge_image:string,badge_name:string,badge_details:string,badge_duration: string,earned: boolean,date: string};
  streaksData: {"id": 0,"current_streak": 0,"best_streak": 0;}
  onBoarding1: OnboardingStep[];
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  openOneScreenOnboarding: boolean;
  onBoardingBtnDisable: boolean;
  onboardingRefs: (RefObject<HTMLTableCellElement> | RefObject<HTMLButtonElement>)[];
  // Customizable Area End
}

// Customizable Area Start
export interface Range {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    range_data: RangeData;
  };
}

export interface RangeData {
  [key: string]: string;
}

interface CreateRangeRequest {
  range: {
    range_data: RangeData;
  };
}

interface CreateMappingRequest {
  range_id: number;
}

interface UpdateMappingRequest {
  range_id: number;
  course_id: number;
  range_data: RangeData;
}

interface RangeTextField {
  id: number;
  rangeField: string;
  starField: string;
}

export interface DropdownField {
  value: string;
  id: number;
}

export interface LeaderBoardItem {
  id: number;
  account_id: number;
  performance_tracker_course_id: number;
  total: string;
  ranking: number;
  top_score: number;
  created_by: number;
  range_id: number;
  course_name?: string;
}

interface LeaderBoardResponse {
  id: string;
  type: string;
  attributes: LeaderBoardItem;
}

export interface CoursesResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    enabled: boolean;
    created_at: Date;
    updated_at: Date;
  };
}
// Customizable Area End

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const rangeTextFieldsDefault = [
  {
    id: 1,
    rangeField: "",
    starField: "",
  },
];
// Customizable Area Start

export default class GamificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRangesCallId: string = "";
  deleteRangesCallId: string = "";
  createRangeCallId: string = "";
  createMappingCallId: string = "";
  updateMappingCallId: string = "";
  getLeaderboardCallId: string = "";
  getPerformanceTrackerCoursesCallId: string = "";
  getUserRankingCallId: string = "";
  getSummaryListApiCallId: string = "";
  getWizardListApiCallId: string = "";
  getBagesListApiCallId: string = "";
  getStreaksListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading:false,
      loading: true,
      tabIndex: 0,
      userList: [],
      filterList: [],
      page: 1,
      filterFlag: false,
      courseIds: [],
      userIds: [],
      userData: [],
      leaderBoardList: [],
      originalList: [],
      selectedUser: [],
      selectedCourse: [],
      selectedRank: {
        min: 1,
        max: 5,
      },
      selectedScore: {
        min: 1,
        max: 100,
      },
      token: "",
      ranges: [],
      rangeIdToDelete: "",
      createRangeModalOpen: false,
      rangeTextFields: rangeTextFieldsDefault,
      createMappingModalOpen: false,
      createMappingCourseId: null,
      createMappingRangeId: null,
      errors: "",
      updateMapping: false,
      updateRangeData: { "0-5": "1", "5-9": "2", "10-15": "3" },
      ranking: [],
      leaderboard: [],
      courses: [],
      selectedCourseForLeaderBoard: null,
      selectedCourseName: "",
      userRankings: [],
      userRole: "",
      awardDialogBoxOpen: false,
      showRewoard: true,
      awardId: null,
      summaryPointData: {data:[],total_score:0},
      wizardData: [],
      awardSingleData: {
        "id": "1",
        "type": "wizard_level",
        "attributes": {
          "id": 1,
          "name": "Wizard Level 1",
          "min_count": 50,
          "logo": ""
        }
      },
      awardIndexNumber: 0,
      yourAwardName: {},
      anchorEl: null,
      showAll: true,
      bagesData: [],
      singleRewardData: {
        id: 0,badge_image:'',badge_name:'',badge_details:'',badge_duration: '',earned: false,date: ''
      },
      streaksData: {
        "id": 0,
        "current_streak": 0,
        "best_streak": 0
      },
      onboardingRefs: [createRef<HTMLTableCellElement>(), createRef<HTMLTableCellElement>(), createRef<HTMLButtonElement>(), createRef<HTMLButtonElement>()],
      onBoarding1: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Earn points by staying consistent with your health goals. Unlock new levels as you progress, earning rewards along the way!",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description":"Maintain your medication routine every day to build a streak. The more consistent you are, the closer you get to reaching your goals!",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Track your progress with badges. Each badge represents a milestone achieved in your journey.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Get an overview of your points and see how you're progressing towards the next level.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.id === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ loading: false, token: token }, () => {
        this.sendGetCoursesRequest();
        this.sendUserRankingRequest();
        this.sendLeaderBoardRequest();
      });
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.getRangesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ loading: false });
        this.showAlert(configJSON.error, errorResponse);
      }

      if (responseJson && responseJson.data) {
        this.setState({
          loading: false,
          ranges: responseJson.data,
        });
      }
      if (responseJson && responseJson.errors) {
        this.setState({ loading: false });
        this.parseApiErrorResponse(responseJson.errors);
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.deleteRangesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ loading: false });
        this.showAlert(configJSON.error, errorResponse);
      }

      if (responseJson && responseJson.message) {
        this.deleteRangeFromState();
        this.showAlert(configJSON.success, responseJson.message);
      }
      if (responseJson && responseJson.errors) {
        this.setState({ loading: false });
        this.parseApiErrorResponse(responseJson.errors);
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.createRangeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ createRangeModalOpen: false, loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        this.setState(
          {
            ranges: [...this.state.ranges, responseJson.data],
            createRangeModalOpen: false,
            loading: false,
            rangeTextFields: rangeTextFieldsDefault,
          },
          () => this.showAlert(configJSON.success, configJSON.success)
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ createRangeModalOpen: false, loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }

      if (responseJson && responseJson.message) {
        this.setState(
          {
            createRangeModalOpen: false,
            loading: false,
          },
          () =>
            this.showAlert(
              configJSON.error,
              JSON.stringify(responseJson.message)
            )
        );
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.createMappingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ createMappingModalOpen: false, loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        this.setState(
          {
            createMappingModalOpen: false,
            loading: false,
            createMappingCourseId: null,
            createMappingRangeId: null,
          },
          () => this.showAlert(configJSON.success, configJSON.success)
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ createMappingModalOpen: false, loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }
      if (responseJson && responseJson.message) {
        this.setState(
          {
            createMappingModalOpen: false,
            loading: false,
          },
          () => this.showAlert(configJSON.error, responseJson.message)
        );
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.updateMappingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ createMappingModalOpen: false, loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        this.setState(
          {
            createMappingModalOpen: false,
            loading: false,
            createMappingCourseId: null,
            createMappingRangeId: null,
          },
          () => this.showAlert(configJSON.success, configJSON.success)
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ createMappingModalOpen: false, loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }
      if (responseJson && responseJson.message) {
        this.setState(
          {
            createMappingModalOpen: false,
            loading: false,
          },
          () => this.showAlert(configJSON.error, responseJson.message)
        );
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.getLeaderboardCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        const leaderboardResponse =
          responseJson.data as Array<LeaderBoardResponse>;
        const leaderBoardItems = leaderboardResponse.map(
          (item) => item.attributes
        );

        this.setState(
          {
            loading: false,
            leaderboard: leaderBoardItems,
          },
          () => {
            this.addCourseInfoToLeaderBoard();
          }
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.getPerformanceTrackerCoursesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        this.setState(
          {
            loading: false,
            courses: responseJson.data,
          },
          () => {
            this.sendLeaderBoardRequest();
          }
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.getUserRankingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.setState({ loading: false }, () =>
          this.showAlert(configJSON.error, errorResponse)
        );
      }

      if (responseJson && responseJson.data) {
        const userRankingResponse =
          responseJson.data as Array<LeaderBoardResponse>;
        const userRankingItems = userRankingResponse.map(
          (item) => item.attributes
        );

        this.setState(
          {
            loading: false,
            userRankings: userRankingItems,
          },
          () => {
            this.addCourseInfoToRankings();
          }
        );
      }
      if (responseJson && responseJson.errors) {
        this.setState({ loading: false }, () =>
          this.parseApiErrorResponse(responseJson.errors)
        );
      }
      if (responseJson && responseJson.message) {
        this.setState({ loading: false, userRankings: [] }, () =>
          this.showAlert(configJSON.error, responseJson.message)
        );
      }
    }
    if (this.getSummaryListApiCallId === apiRequestCallId) {
      if (responseJson) {
        this.setState({ summaryPointData: responseJson })
      }
    }
    if (this.getWizardListApiCallId === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({ wizardData: responseJson.data }, () => this.getYourReward())
        setTimeout(() => {this.setState({isLoading:false})
        }, 1000);
      }
    }
    if (this.getBagesListApiCallId === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({ bagesData: responseJson.data });
      }
    }
    if (this.getStreaksListApiCallId === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({ streaksData: responseJson.data });
      }
    }
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    this.getSummaryList();
    this.getWizardList();
    this.getAllBagesList();
    this.getAllStreaksList();
    const authToken = await getStorageData("authToken")
    if (!authToken) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    let onboarding = await getStorageData("onBoarding");
    if (onboarding === null) {
      setTimeout(() => {
        this.setState({ openOnboarding: true });
        this.nextOnBoarding();
      }, 500)
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  sendLeaderBoardRequest = () => {
    if (this.state.courses.length === 0) {
      return;
    }

    let leaderboardCourseId = this.state.courses[0].attributes.id;

    if (this.state.selectedCourseForLeaderBoard) {
      leaderboardCourseId = this.state.selectedCourseForLeaderBoard;
    } else {
      this.setState({
        selectedCourseForLeaderBoard: leaderboardCourseId,
      });
    }

    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLeaderboardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLeaderboardApi +
      leaderboardCourseId +
      "?page=1&items_per_page=10"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendGetCoursesRequest = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPerformanceTrackerCoursesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPerformanceTrackerCoursesApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendUserRankingRequest = () => {
    if (this.state.courses.length === 0) {
      return;
    }

    let leaderboardCourseId = this.state.courses[0].attributes.id;

    if (this.state.selectedCourseForLeaderBoard) {
      leaderboardCourseId = this.state.selectedCourseForLeaderBoard;
    } else {
      this.setState({
        selectedCourseForLeaderBoard: leaderboardCourseId,
      });
    }

    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserRankingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserRankingApi + leaderboardCourseId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // get data of Rank
  getRankData = (isMobile: boolean = false) => {
    this.setState({ loading: true });
    const courseIds = this.state.userRankings.map((item: LeaderBoardItem) => {
      return {
        id: item["performance_tracker_course_id"],
        name: item["course_name"],
      };
    });
    const userIds = this.state.userRankings.map((item: LeaderBoardItem) => {
      return {
        id: item["account_id"],
        name: item["account_id"],
      };
    });

    const sortedRank = this.state.userRankings.sort(
      (
        first: {
          ranking: number;
        },
        second: {
          ranking: number;
        }
      ) => second.ranking - first.ranking
    );

    this.setState({
      userRankings: isMobile
        ? sortedRank?.slice(0, configJSON.itemsPerPage)
        : sortedRank,
      filterList: sortedRank,
      originalList: sortedRank,
      courseIds: this.removeDuplicateIds(courseIds),
      userIds: this.removeDuplicateIds(userIds),
      loading: false,
      selectedScore: {
        min: 1,
        max: Number((sortedRank?.[0] && sortedRank?.[0]?.["top_score"]) || 100),
      },
    });
  };

  // get leaderboard data
  getLeaderBoardData = (isMobile: boolean = false) => {
    this.setState({ loading: true });
    const courseIds = this.state.leaderboard.map((item: LeaderBoardItem) => {
      return {
        id: item["performance_tracker_course_id"],
        name: item["course_name"],
      };
    });
    const userIds = this.state.leaderboard.map((item: LeaderBoardItem) => {
      return {
        id: item["account_id"],
        name: item["account_id"],
      };
    });
    const sortedLeaderBoard = this.state.leaderboard.sort(
      (
        a: {
          ranking: number;
        },
        b: {
          ranking: number;
        }
      ) => b.ranking - a.ranking
    );
    this.setState({
      userList: isMobile
        ? sortedLeaderBoard?.slice(0, configJSON.itemsPerPage)
        : sortedLeaderBoard,
      originalList: sortedLeaderBoard,
      filterList: sortedLeaderBoard,
      courseIds: this.removeDuplicateIds(courseIds),
      userIds: this.removeDuplicateIds(userIds),
      loading: false,
      selectedScore: {
        min: 1,
        max: Number(
          (sortedLeaderBoard?.[0] && sortedLeaderBoard?.[0]?.["top_score"]) ||
          100
        ),
      },
    });
  };

  // handle reset
  resetHandle = (isMobile: boolean = false) => {
    this.setState({
      filterFlag: false,
      selectedUser: [],
      selectedCourse: [],
      selectedRank: {
        min: 1,
        max: 5,
      },
      selectedScore: {
        min: 1,
        max: Number(
          (this.state.originalList?.[0] &&
            this.state.originalList?.[0]?.["top_score"]) ||
          100
        ),
      },
      page: 1,
      loading: false,
      filterList: this.state.originalList,
      userList: isMobile
        ? this.state.originalList?.slice(0, configJSON.itemsPerPage)
        : this.state.originalList,
    });
  };

  // handle tab change
  tabChange = (tab: number, isMobile: boolean = false) => {
    this.setState({
      tabIndex: tab,
      courseIds: [],
      userIds: [],
      filterFlag: false,
    });
    if (tab == 0) {
      this.getRankData(isMobile);
    }
    if (tab === 1) {
      this.getLeaderBoardData(isMobile);
    }
    if (tab === 2) {
      this.getRanges();
    }
    this.resetHandle(isMobile);
  };

  getRanges = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRangesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRangesApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // handle filter
  handleFilter = (isMobile: boolean = false) => {
    const {
      selectedCourse,
      originalList,
      selectedUser,
      selectedRank,
      selectedScore,
    } = this.state;
    this.setState({
      userList: [],
      loading: true,
      filterFlag: false,
      filterList: [],
    });
    let filterData: Array<LeaderBoardItem> = [];
    filterData = originalList.filter(
      (item) =>
        Number(item.ranking) >= selectedRank.min &&
        Number(item.ranking) <= selectedRank.max &&
        Number(item.total) >= selectedScore.min &&
        Number(item.total) <= selectedScore.max
    );
    if (selectedCourse.length > 0) {
      filterData = filterData.filter(
        (item) =>
          selectedCourse.indexOf(item.performance_tracker_course_id) !== -1
      );
    }
    if (selectedUser.length > 0) {
      filterData = filterData.filter(
        (item: LeaderBoardItem) =>
          selectedUser.indexOf(item["account_id"]) !== -1
      );
    }
    this.setState({
      userList: isMobile
        ? filterData?.slice(0, configJSON.itemsPerPage)
        : filterData,
      filterList: filterData,
      loading: false,
      page: 1,
    });
  };

  // handle  drop down min and max value
  populateDropdownMixMaxData = (min: number, max: number) => {
    return Array(Math.ceil(max - min) + 1)
      .fill(min)
      ?.map((x, y) => {
        return { value: x + y };
      });
  };

  // load more on mobile
  loadMoreData = () => {
    this.setState({ loading: true });
    const data = this.state.filterList.slice(
      (this.state.page + 1 - 1) * configJSON.itemsPerPage,
      (this.state.page + 1) * configJSON.itemsPerPage
    );
    this.setState({
      userList: [...this.state.userList, ...data],
      page: this.state.page + 1,
    });
    this.setState({ loading: false });
  };

  // handle filter
  setFilter = (value: boolean) => {
    this.setState({ filterFlag: value });
  };

  // get name by id
  getNameByID = (list: Array<{}>, selected: Array<{}>) => {
    const selecteduserName: Array<string> = [];
    list?.filter((e: { id?: string | number; name?: string }) =>
      selected.find((id) => {
        if (Number(e.id) == Number(id)) {
          selecteduserName.push(e.name as string);
        }
      })
    );
    return selecteduserName.join(", ");
  };

  //  remove duplicate id
  removeDuplicateIds = (list: Array<{}>) => {
    return list?.filter(
      (
        value: {
          id?: string;
          name?: string;
        },
        index: number,
        array: Array<Object>
      ) =>
        array.findIndex(
          (value2: { id?: string | number }) => value2.id === value.id
        ) === index
    );
  };

  //  handle min and max rank
  populateRankData = (minValue: number, maxValue: number) => {
    this.setState((prevState) => ({
      selectedRank: {
        ...prevState.selectedRank,
        min: minValue,
        max: maxValue,
      },
    }));
  };

  // handle min and max score
  populateScoreData = (minValue: number, maxValue: number) => {
    this.setState((prevState) => ({
      selectedScore: {
        ...prevState.selectedScore,
        min: minValue,
        max: maxValue,
      },
    }));
  };

  //handle course array
  populateCoursesData = (value: Array<string | number>) => {
    this.setState({ selectedCourse: value });
  };

  //handle users array
  populateUsersData = (value: Array<string | number>) => {
    this.setState({ selectedUser: value });
  };

  // get data as per page select
  getListPerPage = () => {
    if (this.state.tabIndex === 0) {
      return this.state.userRankings;
    } else {
      return this.state.userList.slice(
        (this.state.page - 1) * configJSON.itemsPerPage,
        this.state.page * configJSON.itemsPerPage
      );
    }
  };

  // page count
  getPageCount = () => {
    return Math.ceil(this.state.userList.length / configJSON.itemsPerPage);
  };

  // pagination
  setPagination = (value: number) => {
    this.setState({ page: value });
  };

  handleDeleteRange = (id: string) => {
    this.setState({ loading: true, rangeIdToDelete: id });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRangesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRangeApi + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteRangeFromState = () => {
    const newRanges = this.state.ranges.filter(
      (range) => range.id !== this.state.rangeIdToDelete
    );
    this.setState({ loading: false, ranges: newRanges, rangeIdToDelete: "" });
  };

  handleCreateRangeModalOpen = () => {
    this.setState({ createRangeModalOpen: true });
  };

  handleCreateRangeModalClose = () => {
    this.setState({ createRangeModalOpen: false });
  };

  handleChangeRangeField = (text: string, fieldId: number) => {
    let allRangeTextFields = this.state.rangeTextFields;

    const indx = allRangeTextFields.findIndex((item) => item.id === fieldId);

    allRangeTextFields[indx] = {
      ...allRangeTextFields[indx],
      rangeField: text,
    };

    this.setState({ rangeTextFields: allRangeTextFields });
  };

  handleChangeValueField = (text: string, fieldId: number) => {
    let allRangeTextFields = this.state.rangeTextFields;

    const indx = allRangeTextFields.findIndex((item) => item.id === fieldId);

    allRangeTextFields[indx] = {
      ...allRangeTextFields[indx],
      starField: text,
    };

    this.setState({ rangeTextFields: allRangeTextFields });
  };

  handleCreateRange = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createRangeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRangeApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    const requestBody: CreateRangeRequest = {
      range: {
        range_data: this.createRangeData(),
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createRangeData = (): RangeData => {
    let rangeData: RangeData = {};

    this.state.rangeTextFields.forEach((item) => {
      let newRangeData: RangeData = {
        [item.rangeField]: item.starField,
      };

      rangeData = { ...rangeData, ...newRangeData };
    });

    return rangeData;
  };

  addRangeTextField = () => {
    let maxIndex = 0;
    this.state.rangeTextFields.forEach((item) => {
      if (item.id > maxIndex) {
        maxIndex = item.id;
      }
    });

    this.setState({
      rangeTextFields: [
        ...this.state.rangeTextFields,
        { id: maxIndex + 1, rangeField: "", starField: "" },
      ],
    });
  };

  removeTextField = (fieldId: number) => {
    const newFields = this.state.rangeTextFields.filter(
      (item) => item.id !== fieldId
    );
    this.setState({
      rangeTextFields: newFields,
    });
  };

  handleCreateMappingModalOpen = (isUpdate: boolean) => {
    this.setState({ createMappingModalOpen: true, updateMapping: isUpdate });
  };

  handleCreateMappingModalClose = () => {
    this.setState({ createMappingModalOpen: false });
  };

  handleCreateMapping = () => {
    const {
      createMappingCourseId,
      createMappingRangeId,
      updateMapping,
      updateRangeData,
    } = this.state;

    if (createMappingCourseId && createMappingRangeId) {
      this.setState({ errors: "" });
      if (updateMapping) {
        if (!updateRangeData) {
          this.setState({ errors: configJSON.createMappingError });
          return;
        }
        this.sendUpdateMappingRequest(
          createMappingCourseId,
          createMappingRangeId
        );
      } else {
        this.sendCreateMappingRequest(
          createMappingCourseId,
          createMappingRangeId
        );
      }
    } else {
      this.setState({ errors: configJSON.createMappingError });
    }
  };

  sendUpdateMappingRequest = (
    createMappingCourseId: number,
    createMappingRangeId: number
  ) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateMappingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateMappingApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    const requestBody: UpdateMappingRequest = {
      range_id: createMappingRangeId,
      course_id: createMappingCourseId,
      range_data: this.state.updateRangeData,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendCreateMappingRequest = (
    createMappingCourseId: number,
    createMappingRangeId: number
  ) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMappingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createMappingApi + createMappingCourseId.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    const requestBody: CreateMappingRequest = {
      range_id: createMappingRangeId,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCourseIdTextChange = (courseId: number) => {
    this.setState({ createMappingCourseId: courseId });
  };
  handleRangeIdTextChange = (rangeId: number) => {
    this.setState({ createMappingRangeId: rangeId });
  };

  addCourseInfoToLeaderBoard = () => {
    const newLeaderBoard = this.state.leaderboard.map((item) => {
      const course = this.state.courses.find(
        (course) => course.attributes.id === item.performance_tracker_course_id
      );
      if (course) {
        item.course_name = course.attributes.name;
      }
      return item;
    });

    this.setState({ leaderboard: newLeaderBoard }, () =>
      this.getLeaderBoardData()
    );
  };

  addCourseInfoToRankings = () => {
    const newRankings = this.state.userRankings.map((item) => {
      const course = this.state.courses.find(
        (course) => course.attributes.id === item.performance_tracker_course_id
      );
      if (course) {
        item.course_name = course.attributes.name;
      }
      return item;
    });

    this.setState({ userRankings: newRankings }, () => this.getRankData());
  };

  handleCourseChangeMobile = (
    value: string,
    index: number,
    data: Array<DropdownField>
  ) => {
    this.setState({ selectedCourseName: value });
    this.handleSelectedCourseChange(data[index].id);
  };

  handleSelectedCourseChange = (value: number) => {
    this.setState({ selectedCourseForLeaderBoard: value }, () => {
      if (this.state.tabIndex === 0) {
        this.sendUserRankingRequest();
      } else if (this.state.tabIndex === 1) {
        this.sendLeaderBoardRequest();
      }
    });
  };

  handleUserSelection = () => {
    this.setState({ userRole: "user" });
  };
  handleAdminSelection = () => {
    this.setState({ userRole: "admin" });
  };

  populateCoursesDropdown = () => {
    return this.state.courses.map((course) => {
      return { value: course.attributes.name, id: course.attributes.id };
    });
  };

  checkRoleForTab = (key: string): boolean => {
    const adminPages = ["Ranking", "LeaderBoard", "Ranges", "Mappings"];
    const userPages = ["Ranking", "LeaderBoard"];

    if (this.state.userRole === "admin") {
      return adminPages.includes(key);
    } else {
      return userPages.includes(key);
    }
  };

  openAwardDialogBox = (index: number) => {
    this.setState({ awardDialogBoxOpen: !this.state.awardDialogBoxOpen, awardId: index + 1, singleRewardData: this.state.bagesData[index] });
  }

  closeAwardDialogBox = () => {
    this.setState({ awardDialogBoxOpen: !this.state.awardDialogBoxOpen, awardId: null });
  }

  showBagesData = () => {
    this.setState({ showRewoard: true });
  }

  showPointsData = () => {
    this.setState({ showRewoard: false });
  }

  getSummaryList = async () => {
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/points_summary";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSummaryListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getWizardList = async () => {
    this.setState({isLoading:true})
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/wizard_levels";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWizardListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllBagesList = async () => {
    const requestEndPoint = "bx_block_educational_gamification/badges";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBagesListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllStreaksList = async () => {
    const requestEndPoint = "bx_block_educational_gamification/user_gemifications/streaks";
    const header = { "Content-Type": "application/json", "token": await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStreaksListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const days = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${days} ${month}, ${year}`;
  };

  getYourReward = () => {
    const getYourReward = this.state.wizardData?.find((item) => item.attributes.min_count > this.state.summaryPointData.total_score);
    const index = this.state.wizardData.findIndex((item) => item.attributes.min_count > this.state.summaryPointData.total_score)
    this.setState({ awardSingleData: getYourReward, awardIndexNumber: index }, () => this.getYourRewardName())
  }

  getYourRewardName = () => {
    let datas = this.state.awardSingleData as AwardSingleData;
    setTimeout(() => {
      const [first, second, third] = datas.attributes.name.split(" ");
      this.setState({ yourAwardName: { first, second, third } });
    }, 200)
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Get only the date part
  }

  showTodayData = () => {
    this.setState({ showAll: true });
  }

  showAllData = () => {
    this.setState({ showAll: false });
  }

  getPopoverStatus = () => {
    const open = Boolean(this.state.anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;
    return { open, popoverId };
  }

  getFilteredData = () => {
    const todayDate = this.getTodayDate();
    return this.state.showAll
      ? this.state.summaryPointData.data
      : this.state.summaryPointData.data.filter((item) => item.attributes.date === todayDate);
  }

  nextOnBoarding = () => {
   
    const { onBoarding1, currentStep } = this.state;
    if (onBoarding1.length > currentStep) {
      if (currentStep === 3) { 
        this.setState({ onBoardingBtnDisable: true, showRewoard:false },()=>this.changeStep2());
      }
      else if(currentStep === 2){
        this.setState({showRewoard:true},()=>this.changeStep2())
      }else{
        this.changeStep2();
      }
    }
    else {
      if (this.state.openOneScreenOnboarding) {
        this.props.navigation.navigate('CustomisableUserProfiles');
      }
      this.resetDataFunction();
    }
  }


  resetDataFunction = () => {
    this.setState({
      openOnboarding: false,
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
    });
  }


  handleOpneOnboarding = () => {
    this.setState({
      openOnboarding: true,
      openOneScreenOnboarding: false,
      currentStep: 0,
    }, () => this.nextOnBoarding())
  }

  changeStep2 = () => {
    const { currentStep, onBoarding1 } = this.state;
    const onBoarding = onBoarding1 ;
    this.setState({
      onBoardingTitle: onBoarding[currentStep].onboarding_title,
      onBoardingText: onBoarding[currentStep].onboarding_description,
      transformHorizontal: onBoarding[currentStep].transformHorizontal,
      anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
      currentStep: currentStep + 1
    })
    const targetRef = this.state.onboardingRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }

  }

  skipOnBoarding = async () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    this.resetDataFunction();
    await setStorageData("onBoarding", "true");
  }

  // Customizable Area End
}
