import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Card, Container, Grid, IconButton, InputAdornment, OutlinedInput, Typography, styled, Dialog, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { VitaImg, visibleIcon, unvisibleIcon  } from "./assets";
import { ErrorMessage, Field, Formik } from "formik";
import CustomBG from "../../../components/src/CustomBG.web";

const MainContainer = styled(Box)({
    display: "flex",
   margin:'auto',
   height:"100vh !impotant",
    justifyContent: "conter",
    "@media (max-width: 1310px)": {
        "& .MuiContainer-maxWidthMd": {
            maxWidth: 700,
        },
    },
    '& .MuiContainer-maxWidthMd-9': {
        display: "flex",
        justifyContent: "center",
        justifyItems: "center"
    },
    "& .scrollBar": {
        margin:' auto',
        display: "flex",
        maxWidth: "498px",
        height: 'fit-content',
        padding: "32px",
        borderRadius: "24px",
        boxShadow:
            "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        gap: "14px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "&::-webkit-scrollbar-track": {
            display: "none",
        },
        "&::-webkit-scrollbar-thumb": {
            display: "none",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            display: "none",
        },
        "@media (max-width: 200px)": {
            height: "auto",
            overflow: "auto",
        },
    },
    "& .card-container": {
        background: "#F8FAFC",
        borderRadius: 24,

        backgroundColor: "#FFF",
        "@media (max-width: 600px)": {
            height: "auto",
        },
        "@media (max-width:900px)": {
            height: "auto",
        },
    },

    "& .MuiContainer-maxWidthMd": {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
    },
    "& .getstarted": {
        color: "var(--Neutral---Dark-Grey, #3C3C3B)",
        fontFamily: "Raleway",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "38px",
        letterSpacing: "0.02px",
        paddingBottom: '20px',
    },
    "& .passwordText": {
        padding: "8px 0",
        color: "var(--Neutrals-Cool-gray-600, #475569)",
        fontFamily: "Raleway",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22px",
        letterSpacing: "0.154px",
    },
    "& .txtInputNewPassword": {
        height: "20px",
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "5px",
        width: "100%",
        color: "#1E293B",
        backgroundColor: "#F1F5F9",
        border: "1px solid #E2E8F0",
        padding: '25px 16px',
        fontFamily: "Raleway",
        fontStyle: "normal",
        '&.inputboxLoginBox:hover': {
            border: "1px solid black",
        },
        '& ::placeholder':{
            color:"#64748B",
            opacity:1
        }
    },
    "& .MuiOutlinedInput-root-175.Mui-focused .MuiOutlinedInput-notchedOutline-181": {
        borderColor: "red",
        borderWidth: "1px"
    },
    "& .MuiCard-root": {
        overflow: "auto",
    },

    "& .btnDisable": {
        display: "flex",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        border: "8px",
        background: "#13689E",
        opacity: 0.4,
        textTransform: 'capitalize',
        fontWeight: 700,
        lineHeight: "24px",
        color: "rgba(255, 255, 255, 1)",
        marginTop: "10px",
        borderRadius: "8px",
        fontSize: "16px",
        fontFamily: 'Raleway',
    },
    '& .EnableLogin': {
        display: "flex",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        border: "8px",
        background: "#13689E",
        textTransform: 'capitalize',
        fontWeight: 700,
        lineHeight: "24px",
        color: "rgba(255, 255, 255, 1)",
        marginTop: "10px",
        borderRadius: "8px",
        fontSize: "16px",
        fontFamily: 'Raleway',
    },
    '& .VitaImgLOgo': {
        padding: "16px 0",
    },
    '& .logoOutBox': {
        marginBottom: '5px',
        background: "#F1F5F9",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50px",
        width: "24px",
        padding: "10px",
        alignItems: "center",
        gap: "12px",
    },
    '& .error-message': {
        color: "var(--Status-Red-600, #DC2626)",
        fontFamily: "Raleway",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
        marginTop: "4px",
    },
    '& .error': {
        display: "flex",
        width: "100%",
        alignSelf: "stretch",
        borderRadius: "5px",
        border: "1px solid var(--Status-Red-600, #DC2626)",
        height: "20px",
        fontWeight: 500,
        fontSize: "16px",
        margin: "4px 0",
        color: "#1E293B",
        backgroundColor: "var(--Neutrals-Cool-gray-50, #F8FAFC)",
        background: "var(--surface, #2B2B2B)",
        padding: '25px 16px',
        '&.error:hover': {
            border: "1px solid red !important",
        },
    },
    "& .MuiTypography-body1":{
        fontFamily: "Inter",
    },
});
// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";
import { Divider } from "react-native-elements";

const theme = createTheme({
    palette: {
        primary: {
            main: "#454545",
            contrastText: "#fff",
        },
    },
});

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    newPassword = (errors:any,touched:any) => {
        return (<>
            <Grid item xs={12}>
                <Field
                    as={OutlinedInput}
                    name="newPassword"
                    placeholder="Confirm Password"
                    data-test-id="Confirm_Password"
                    className={`${touched.newPassword && errors.newPassword ? 'error' : 'txtInputNewPassword'}`}
                    inputProps={{
                        style: {
                            padding: "4px 0",
                            outline: "none",
                        }
                    }}
                    type={this.state.btnConfirmPasswordShowHide ? 'password' : 'text'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                data-test-id="showpassword"
                                onClick={this.handleClickShowConfPassword}
                            >
                                {this.state.btnConfirmPasswordShowHide ? <img src={unvisibleIcon} /> : <img src={visibleIcon} />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {errors.newPassword && <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error-message"
                />}
                {this.state.passwordError && 
                <Typography variant="caption" color="error" className='error-message' >
                                    {this.state.passwordError}
                                  </Typography> }
            </Grid>
        </>)
    }
    continueBtn = (values:any,errors: any, touched: any) => {
        return (<>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={values.password && values.newPassword && values.password === values.newPassword && !errors.password &&
                        !errors.newPassword  ? false : true}
                    fullWidth
                    data-test-id="submit-btn"
                    className={`${values.password && values.newPassword && values.password === values.newPassword && !errors.password &&
                        !errors.newPassword  ? 'EnableLogin' : 'btnDisable'}`}
                >
                    Continue
                </Button>
            </Grid></>)
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <CustomBG>
                    <MainContainer>
                        <Container maxWidth="sm" >
                            <Box className="scrollBar">
                                <Card elevation={0} className="card-container">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <img
                                                src={VitaImg}
                                                alt="Your Image"
                                                className="VitaImgLOgo"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="getstarted">Reset your password</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Formik
                                                data-test-id="Formik"
                                                initialValues={{ password: '', newPassword: '' }}
                                                validate={this.validateForm}
                                                onSubmit={this.handleSubmitfunc}
                                            >
                                                {({
                                                    values,
                                                    handleSubmit,
                                                    errors,
                                                    touched,
                                                }) => (

                                                    <form onSubmit={handleSubmit}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Field
                                                                    // color="secondary"
                                                                    as={OutlinedInput}
                                                                    name="password"
                                                                    placeholder="New Password"
                                                                    data-test-id="InputPassword"
                                                                    inputProps={{
                                                                        style: {
                                                                            padding: "8px 0",
                                                                        }
                                                                    }}
                                                                    className={`${touched.password && errors.password ? 'error' : 'txtInputNewPassword'}`}
                                                                    type={this.state.enablePasswordField ? 'password' : 'text'}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                edge="end"
                                                                                data-test-id="password"
                                                                                onClick={this.handleClickShowPassword}
                                                                            >
                                                                                {this.state.enablePasswordField ? <img src={unvisibleIcon} /> : <img src={visibleIcon} />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {errors.password && <ErrorMessage
                                                                    name="password"
                                                                    component="div"
                                                                    className="error-message"
                                                                />}
                                                            </Grid>
                                                            {this.newPassword(errors, touched)}
                                                            {this.continueBtn(values, errors, touched)}
                                                        </Grid>
                                                    </form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                        </Container>
                        <Dialog
                            keepMounted
                            open={this.state.passChangeModal}
                            onClose={this.handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{
                                style: {
                                    maxWidth: "497px",
                                    height: 'auto',
                                    borderRadius: 24,
                                    padding: "16px 32px",
                                    background: "#fff"
                                },
                            }}
                        >
                            <DialogContent style={{ display: "flex", textAlign: "center", padding: "10px 10px" }}>
                                <DialogContentText id="alert-dialog-slide-description" style={{
                                    fontFamily: "Raleway",
                                    fontSize: "18px",
                                    paddingTop: "16px",
                                    fontWeight: 400,
                                    color: '#0F172A'
                                }} >
                                    {"Your password has been successfully changed."}
                                </DialogContentText>
                            </DialogContent>
                            <Divider />
                            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    onClick={() => this.handleClose()}
                                    style={{
                                        color: "#007AFF",
                                        fontFamily: "Inter",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        textTransform: "none"
                                    }}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </MainContainer>
                </CustomBG>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}