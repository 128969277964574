// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Modal,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { appupdatesClosed } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import AppupdatesController, {
  Props,
  configJSON,
} from "./AppupdatesController";

export default class Appupdates extends AppupdatesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box>
                <Modal
                  open={this.state.appMaintenance}
                  onClose={this.appMaintenanceModalclosed}
                  aria-labelledby="modal-modal-title"
                  disableEnforceFocus
                  disableAutoFocus
                  aria-describedby="modal-modal-description"
                  disableBackdropClick
                >
                  <AppMaintenance sx={{ boxShadow: 24 }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <img data-testid="appupdatesClosed" onClick={this.appMaintenanceModalclosed} style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} src={appupdatesClosed} />
                    </div>
                    <AppMaintenanceTextOne>App maintenance</AppMaintenanceTextOne>
                    <AppMaintenanceTextTwo>Our team is working hard to resolve an issue. Please come back soon.</AppMaintenanceTextTwo>
                    <AppMaintenanceButtonDiv>
                      <AppMaintenanceCancelButt data-testid="appMaintenanceModalclosed" onClick={this.appMaintenanceModalclosed}>Cancel</AppMaintenanceCancelButt>
                      <AppMaintenanceEmail data-testid="emailmeclick" onClick={this.mailtoSendMe}>Email Me</AppMaintenanceEmail>
                    </AppMaintenanceButtonDiv>
                  </AppMaintenance>
                </Modal>
            </Box>
            <Box>
                <Modal
                  open={this.state.downtime}
                  onClose={this.downtimeModalclosed}
                  aria-labelledby="modal-modal-title"
                  disableEnforceFocus
                  disableAutoFocus
                  aria-describedby="modal-modal-description"
                  disableBackdropClick
                >
                  <AppMaintenance sx={{ boxShadow: 24 }}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <img data-testid="appupdatesClosed" style={{ height: "14px", width: "14px", padding: "25px 25px 0px 0px", cursor: "pointer" }} onClick={this.downtimeModalclosed} src={appupdatesClosed} />
                    </div>
                    <AppMaintenanceTextOne>Temporary service downtime</AppMaintenanceTextOne>
                    <AppMaintenanceTextTwo>We're experiencing downtime for services, please try after some time.</AppMaintenanceTextTwo>
                    <AppMaintenanceButtonDiv>
                      <AppMaintenanceEmail onClick={this.downtimeModalclosed}>Cancel</AppMaintenanceEmail>
                    </AppMaintenanceButtonDiv>
                  </AppMaintenance>
                </Modal>
            </Box>
          </Container>
        </ThemeProvider>
    );
  }
}

const AppMaintenance = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  background: '#ffffff',
  width: 600,
  border: 'none',
  borderRadius: "24px",
  padding: 0,
  display: "flex",
  maxHeight: "320px",
  transform: 'translate(-50%, -50%)',
  flexDirection: "column",
  '@media (max-width: 600px)': {
    width: '92%',
  },
});

const AppMaintenanceTextOne = styled(Typography)({
  color: "black",
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "24px",
  padding: "21px 40px 16px 40px",
  '@media (max-width: 450px)': {
    fontSize: "19px",
  },
});

const AppMaintenanceTextTwo = styled(Typography)({
  fontSize: "18px",
  color: "black",
  fontWeight: 400,
  fontFamily: "Raleway",
  padding: "0px 40px 55px 40px",
  borderBottom: "1px solid #E2E8F0",
  '@media (max-width: 450px)': {
    fontSize: "15px",
  },
});

const AppMaintenanceButtonDiv = styled('div')({
  display: "flex",
  padding: "24px 24px 24px 0px",
  justifyContent: "end",
});

const AppMaintenanceCancelButt = styled(Button)({
  minHeight: "56px",
  minWidth: "140px",
  fontWeight: 700,
  color: "#13689E",
  textTransform: "none",
  fontFamily: "Raleway",
  paddingRight:"16px",
  '&:hover': {
    background: 'none',
  },
});

const AppMaintenanceEmail = styled(Button)({
  color: "white",
  minHeight: "56px",
  fontFamily: "Raleway",
  fontWeight: 700,
  textTransform: "none",
  minWidth: "151px",
  background: "#13689E",
  borderRadius: "50px",
  '&:hover': {
    background: '#13689E',
  },
});
// Customizable Area End
