import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import moment from 'moment';
import { RangeKeyDict  } from 'react-date-range';

interface MonthlyIntakeData {
  date: string;
  intake: number[];
}

interface ChartOptions {
  chart: {
    type: string;
    height: number;
    width:number | string ;
    backgroundColor: string;
  };
  title: { 
    text: null
  },
  tooltip: {
    enabled: boolean
},
  xAxis: {
    title: {
      text: string;
      style: {
        color: string;
        fontSize: string;
        fontWeight: number;
        fontFamily: string;
      };
    };
    categories: string[] | false;
    labels: {
      rotation:number;
      style: {
        color: string;
        fontSize: string;
        fontWeight: number;
        fontFamily: string;
      }
    };
    gridLineWidth:number;
    lineWidth:number;
  };
  plotOptions: {
    series: {
      marker: {
        enabled: boolean, 
        states: {
          hover: {
            enabled: boolean 
          }
        }
      },
      states: {
        hover: {
          enabled: boolean 
        }
      }
    }
      area: {
        fillOpacity: number,
        lineWidth: number,
        color: {
          linearGradient: {
              x1: number,
              y1: number,
              x2: number,
              y2: number,
          },
           stops: Array<[number, string]>;
      },
    
    }
  },
  yAxis: {
    min: number,
    max: number,
    title: {
      text: string;
      style: {
        color: string;
        fontSize: string;
        fontWeight: number;
        fontFamily: string;
      }
    };
    plotBands: [{
      from: number,
      to: number,
      color: string
    }, {
      from: number,
      to: number,
      color: string
    }]
    labels: {
      style: {
        color: string;
        fontSize: string;
        fontWeight: number;
        fontFamily: string;
      }
    };
  };
  series: {
    name: string;
    data: number[];
    color: string;
    zoneAxis: string;
    zones: { value?: number; color: string }[];
    marker: { enabled: boolean };
  }[];

  legend: {
    enabled: boolean,
  };
  credits: {
    enabled: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  preSelect:string,
  currentToggel:string;
  levelMonthly:string;
  valueChart: string;
  selectMenu:boolean;
  options: ChartOptions;
  isMonthlySelected:boolean;
  anchorEl:HTMLElement | null;
  clickState:boolean,
  anchorElDaterange:HTMLElement | null;
  monthlyDate:Date|null
  isLoading: boolean;
  ranges: {
    startDate:undefined| Date,
    endDate: undefined| Date, 
    key: string
  }[],
  tempRanges: {
    startDate:undefined| Date,
    endDate: undefined| Date, 
    key: string
  }[],
  selectDaterange:boolean;
  loginUserType: string | null  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PheleveltrendController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getphelevelCallid: string = "";
  getPheandTyrCallid : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      preSelect:'Monthly',
      isLoading:false,
      currentToggel: 'Phe',
      levelMonthly: 'Monthly',
      valueChart: 'Monthly',
      selectMenu: false,
      options: {
      chart: {
        type: 'area',
        height: 600,
        width:2500,
        backgroundColor: 'transparent',
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
    },
      xAxis: {
        title: {
          text: 'Time (Monthly)',
          style: {
            color: '#334155',
            fontSize: '10px',
            fontWeight: 500,
            fontFamily: 'Raleway',
           
          }
        },
        categories: [],
        labels: {
          rotation: 0,
          style: {
            color: '#000000',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Raleway',
          },
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true, 
            states: {
              hover: {
                enabled: false 
              }
            }
          },
          states: {
            hover: {
              enabled: false 
            }
          }
        },
        area: {
          fillOpacity: 0.2,
          lineWidth: 0.7,
          color: {
            linearGradient: {
                x1: 0,
                y1: 6,
                x2: 0,
                y2: 6
            },
            stops: [
              [0, '#FF0000'],
              [7, '#10B981']
            ]
        },
      }
      },
      yAxis: {
        min: 0,
        max: 20,
        title: {
          text: 'mg/dL',
          style: {
            color: '#334155',
            fontSize: '10px',
            fontWeight: 500,
            fontFamily: 'Raleway',
          }
        },
        plotBands: [{
          from: 6,
          to: 20,
          color: 'rgb(254, 242, 242)'
      }, {
          from: 0,
          to: 6,
          color: 'rgb(236, 253, 245)' 
      }],
        labels: {
          style: {
            color: '#000000',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Raleway',
          },
        },
      },
      series: [{
        name: 'add',
        data: [],
        color: '#00E396',
        zoneAxis: 'y',
        zones: [{
          value: 6.1,
          color: '#059669',
        }, {
          color: '#FF4560',
        }],
        marker: {
          enabled: false,
        },
      }],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    },
      isMonthlySelected: false,
      anchorEl: null,
      anchorElDaterange: null,
      clickState:false,
      ranges: [
        {
          startDate: new Date(),
          endDate: new Date(), 
          key: 'selection'
        }
      ],
      tempRanges: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ],
      monthlyDate:new Date(),
      selectDaterange:false,
      loginUserType: localStorage.getItem("user_type"),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area Start
 
    // Customizable Area Start
  }

 
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.getphelevelCallid) {
                   this.getResponseChartdata(responseJson)
            }
        }
        this.setState({isLoading:false})
    }
    // Customizable Area End
  }

  // Customizable Area Start

    async componentDidMount() {
        this.getPhechartData()
        if (!this.state.loginUserType) {
          this.redirectLogin()
        }
    };

    componentDidUpdate(prevProps:Props, prevState:S) {
      if ((prevState.options.series[0].data !== this.state.options.series[0].data) && this.state.options.series[0].data.length === 1) {
        this.setState({
          options:{
            ...this.state.options,
             series: this.state.options.series.map(serie => ({
            ...serie,
            marker: {
              enabled: true,
            },
          })),
          }
        });
      }
      if((prevState.options.series[0].data !== this.state.options.series[0].data) && this.state.options.series[0].data.length !== 1){
        this.setState({
          options:{
            ...this.state.options,
             series: this.state.options.series.map(serie => ({
            ...serie,
            marker: {
              enabled: false,
            },
          })),
          }
        });
      }
      if ((prevState.options.series[0].data !== this.state.options.series[0].data)) {
        this.updateColorchartData();

      }
      if (prevState.valueChart !== this.state.valueChart) {
        this.updateXAxisTitle();
      }
      if (this.state.ranges !== prevState.ranges) {
        this.setState({
          preSelect: 'Custom Range',
          valueChart: 'Custom Range',
          ranges: this.state.tempRanges  
        }, () => {
          this.handlePopoverClose()
        });
      }
    };
    /* istanbul ignore next */
    chartWidth = () =>{
      switch (this.state.valueChart) {
        case 'Monthly':
          return  2500;
          case 'Quarterly':
            return 7200;
          case 'Custom Range':
              return ((this.state.options.series[0].data.length * 200) > 1400) ? this.state.options.series[0].data.length * 100 : 1400;
        default:
          return '100%';
      }
    };
    /* istanbul ignore next */
    updateColorchartData = async () => {
      const colorValue = this.state.options.series[0]?.data;
      const maxValueColor = Math.max(...colorValue);
      this.setState(prevState => {
        
        return {
          options: {
            ...prevState.options,
            chart: {
              ...prevState.options.chart,
              width: this.chartWidth(),
            },
            yAxis: {
              ...prevState.options.yAxis,
              min: 0,
              max: this.yaxisMaxvalueadd(maxValueColor),

              plotBands: [
                {
                  from: this.state.currentToggel === 'Phe' ? 6 : 29,
                  to: this.yaxisFromTodata(maxValueColor),
                  color: 'rgb(254, 242, 242)'
                },
                {
                  from: 0,
                  to: this.state.currentToggel === 'Phe' ? 6 : 120,
                  color: 'rgb(236, 253, 245)'
                }
              ],
            },
            series: prevState.options.series.map(series => ({
              ...series,
                zones: [{
                  value: this.state.currentToggel === 'Phe' ? 6.001 : 120.001,
                  color: '#059669',
                }, 
                {
                  color: this.getColor(),
                }],
            })),
          },
        };
      });
    };
    /* istanbul ignore next */
    getColor() {
      const data = this.state.options.series[0]?.data || [];
      const selected = this.state.currentToggel === 'Phe' ? 6 : 120
      const hasValueGreaterThan6 = data.some(value => value > selected);
      return hasValueGreaterThan6 ? '#FF4560' : '#059669';
    }
  
   yaxisFromTodata = (valuecolor:number) =>{
    if(valuecolor === 0){
      return this.state.currentToggel === 'Phe' ? 20 : 160
    }else{
      return valuecolor * 10000000000000
    }
   };

   yaxisMaxvalueadd = (valuemax:number) =>{
    if(valuemax === 0){
      return this.state.currentToggel === 'Tyr' ? 150 : 20
    }else{
     return valuemax
    }
   };

    updateXAxisTitle() {
      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          xAxis: {
            ...prevState.options.xAxis,
            title: {
              ...prevState.options.xAxis?.title,
              text: `Time (${this.state.valueChart})`,
            },
          },
        },
      }));
    }

    getPhechartData = async() => {
      this.setState({isLoading:true})
      const token = await getStorageData("authToken");
      const { valueChart, currentToggel, monthlyDate, ranges } = this.state
  
      const mainParams = `${configJSON.phelevelchartEndpoint}?type=${currentToggel.toLowerCase()}&scope=${valueChart.toLowerCase()}`
      const monthPath = `${mainParams}&date=${this.formatDateToMmyy(monthlyDate)}`
      const customDatePath = `${configJSON.phelevelchartEndpoint}?type=${currentToggel.toLowerCase()}&scope=custom_date&from=${moment(ranges[0].startDate).format('DD-MM-YYYY')}&to=${moment(ranges[0].endDate).format('DD-MM-YYYY')} `

      let basePath;
      switch (valueChart) {
        case 'Monthly':
          basePath = monthPath;
          break;
        case 'Custom Range':
          basePath = customDatePath;
          break;
        default:
          basePath = mainParams;
          break;
      }

        const header = {
          "Content-Type": configJSON.apiContentType,
          token: token,
        };
        const requestMessageList = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getphelevelCallid = requestMessageList.messageId;
        requestMessageList.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        requestMessageList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          basePath);
        requestMessageList.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    
    };

  submitcustomDate = () => {
    this.setState({
      preSelect:'Custom Range',
      valueChart: 'Custom Range',
      ranges: this.state.tempRanges
    })
    this.getPhechartData()
  };

  getResponseChartdata = async(responseJson: MonthlyIntakeData[]) => {
    const monthlyDate =  Array.isArray(responseJson) && responseJson.map((getdate) => getdate.date)
    const monthlyDateValue = Array.isArray(responseJson) && responseJson.map((getIntakevalue) => getIntakevalue.intake[0])
    this.setState(prevState => ({
      options: {
        ...prevState.options,
        xAxis: {
          ...prevState.options.xAxis,
          categories: monthlyDate
        },
        series: [{
          ...prevState.options.series[0],
          data: monthlyDateValue || []
        }]
      }
    }));
  };

    swichTypeData = (swichData:string) => {

     const mgUmol = swichData === 'Tyr' ? 'umol/L' : 'mg/dL';
      this.setState({ currentToggel: swichData }, () => {
        this.getPhechartData();
      });
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          yAxis: {
            ...prevState.options.yAxis,
            title: {
              text: mgUmol, 
              style: {
                color: '#334155',
                fontSize: '10px',
                fontFamily: 'Raleway',
                fontWeight: 500,
              },
            }
          },
        },
      }));
    };

    setMenus = () => {
        this.setState({ selectMenu: !this.state.selectMenu })
    };

  
    handleChangeValue = (event:  React.ChangeEvent<{ name?: string; value: unknown |string}>, child?: React.ReactNode) => {
      const value = event.target.value as string;
        this.setState({
          valueChart:  value,
          isMonthlySelected: value === 'Monthly',
          selectDaterange: value === 'Custom Range',
          anchorEl:   value==='Monthly' ? (event.currentTarget as unknown as HTMLElement):  null,
          anchorElDaterange:value==='Custom Range'?(event.currentTarget as unknown as HTMLElement): null,
        });
        value==='Quarterly'&& this.quarterlychangeCall()
    };

  
  handleClickValue = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement & { value: number | null |string};
    if(target.value!==undefined){
    target.innerText == 'Monthly' && this.setState({
      valueChart:this.state.preSelect,
      isMonthlySelected: true,
      anchorEl: event.currentTarget as unknown as HTMLElement,
    });

    (target.innerText||target.value)== 'Custom Range'  &&  this.setState({
      valueChart:this.state.preSelect,
      selectDaterange: true,
      clickState:true,
      anchorElDaterange: event.currentTarget as unknown as HTMLElement,
    });
  }
  }


  quarterlychangeCall=()=>{
    this.setState({ preSelect:'Quarterly' , tempRanges: [
      {
        startDate: new Date(),
        endDate: new Date(), 
        key: 'selection'
      }
    ]})
    this.getPhechartData()
  };

    handlePopoverClose = () => {
      this.setState((preState)=>{
        return({ valueChart:preState.preSelect,isMonthlySelected: false, anchorEl: null,anchorElDaterange:null,selectDaterange:false })});
        if(this.state.preSelect === 'Custom Range' && (this.state.tempRanges[0].startDate !== this.state.ranges[0].startDate || this.state.tempRanges[0].endDate !== this.state.ranges[0].endDate )){
          this.setState({
            tempRanges: this.state.ranges
          })
        }
    };
      
    selectMonth = (monthDate:Date | null) =>{
      this.setState({monthlyDate:monthDate,preSelect:'Monthly', tempRanges: [
        {
          startDate: new Date(),
          endDate: new Date(), 
          key: 'selection'
        }
      ]})
      this.getPhechartData();
      this.handlePopoverClose();
      
    }

    formatDateToMmyy = (dateFormating: Date | null) => {
      const date = moment(dateFormating);
      const formattedDate = date.format('MMM YYYY');
      return formattedDate;
    }

  customSetDate = (item: RangeKeyDict) => {
    this.setState({
      preSelect:'Custom Range',
      tempRanges: [{
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: 'selection'
      }]
    })
  }

  redirectLogin = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  }; 
  // Customizable Area End
}
