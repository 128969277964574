import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { image_pill, image_restaurant, image_stethoscope } from "../src/assets";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { RefObject, createRef } from "react";
export interface IAttribute {
  value: string | number;
  is_enable: boolean;
  title: string;
  is_required: boolean;
  field_type: string;
  name: string;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "text"
  | "textarea"
  | "date"
  | "integer"
  | "datetime"
  | "number"
  | "float"
  | "boolean"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  is_enable: boolean;
  title: string;
  is_required: boolean;
  field_type: FieldType;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

interface getAllDataError {
  message: string
}

interface meta { token: string }

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface RecipeAttributes {
  name: string;
  difficulty: string;
  featured: boolean;
  image: string | null;
  ingredients: string;
  is_favourite: boolean;
  nutritional_information: string;
  recommended: boolean;
  servings: number | null;
  youtube_link: string;
  directions: string;
  id: string | number
}

interface Recipe {
  id: string;
  type: string;
  attributes: RecipeAttributes;
}

interface Pagination {
  current_page: number;
  next_page: number | null;
  per_page: number;
  prev_page: number | null;
  total_pages: number;
  total_record_count: number;
}

interface ThirdObj {
  data: Recipe[];
  pagination: Pagination;
  errors: string, message: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  first_name: string;
  categorys: any;
  recipeData: any;
  viewAllState: boolean;
  favoriteState: boolean;
  selectedSideText: string;
  viewAllText: string;
  viewAllTexts: string;
  selectedFoodType: string;
  serchText: string;
  meta: meta;
  catagoryData: any;
  recommendedRecipes: any;
  featuredRecipes: any;
  openRecipes: any;
  favouritesTick: any;
  favoriteAllDatas: any;
  imageOption: any;
  javygatorInfoData: any;
  pkuInfoData: any;
  advanceSearchData: any;
  advanceSearchDataNot: string;
  showCloseIcon: boolean;
  userType: any
  isLoading: boolean;
  onboardingRefs: RefObject<HTMLTableCellElement>[];
  onBoarding1: any;
  onBoarding2: any;
  openOnboarding: boolean;
  onBoardingTitle: string;
  onBoardingText: string;
  onBoardingRef: string;
  currentStep: number;
  anchorHorizontal: string;
  transformHorizontal: string;
  openOneScreenOnboarding: boolean;
  onBoardingBtnDisable: boolean;
  getOpenRecipeID: string,
  getRecipePath: string
  loginUserType: string | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getcategoriesApiId: string = "";
  recipesGetApiId: string = "";
  callCategoryBasedRecipesId: string = "";
  callRecommendedRecipesId: string = "";
  callFeaturedRecipesId: string = "";
  favouritesPostId: string = "";
  favoriteAllData: string = "";
  javygatorInfoId: string = "";
  pkuInfoId: string = "";
  removefavouritesPostId: string = "";
  advanceSearchId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      first_name: "",
      categorys: [],
      recipeData: [],
      viewAllState: false,
      favoriteState: false,
      selectedSideText: "",
      viewAllText: "",
      viewAllTexts: "",
      selectedFoodType: "Breakfast",
      serchText: "",
      meta: { token: "" },
      catagoryData: [],
      recommendedRecipes: [],
      featuredRecipes: [],
      openRecipes: false,
      favouritesTick: [],
      favoriteAllDatas: [],
      imageOption: [],
      javygatorInfoData: {},
      pkuInfoData: {},
      advanceSearchData: [],
      advanceSearchDataNot: "",
      showCloseIcon: false,
      userType: "",
      isLoading: true,
      getRecipePath: "",
      getOpenRecipeID: "",
      onboardingRefs: Array.from({ length: 6 }, () =>
        createRef<HTMLTableCellElement>()
      ),
      onBoarding1: [{
        "id": 1,
        "onboarding_title": "Step 1",
        "onboarding_description": "Javygtor Information: Access details including indications, important safety information, warnings, and precautions for comprehensive understanding.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 2,
        "onboarding_title": "Step 2",
        "onboarding_description": "Phenylketonuria Information: Access Comprehensive details regarding phenylketonuria, including symptoms, causes, diagnosis, treatment options",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 3,
        "onboarding_title": "Step 3",
        "onboarding_description": "Recipes: Explore a variety of diet-friendly recipes for breakfast, lunch, and dinner to support your nutritional goals.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 4,
        "onboarding_title": "Step 4",
        "onboarding_description": "Search Recipes: Find your desired recipe quickly by entering keywords, ingredients, or dish names here.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      {
        "id": 5,
        "onboarding_title": "Step 5",
        "onboarding_description": "Favorite Recipes: Save your preferred recipes for easy access and quick meal planning.",
        "transformHorizontal": "right",
        "anchorHorizontal": "right",
      },
      {
        "id": 6,
        "onboarding_title": "Step 6",
        "onboarding_description": "Categories: Explore categorized diet recipes for breakfast, lunch, dinner, and snacks to suit your nutritional preferences and needs.",
        "transformHorizontal": "left",
        "anchorHorizontal": "left",
      },
      ],
      onBoarding2: [
        {
          "id": 1,
          "onboarding_title": "Step 1",
          "onboarding_description": "Phenylketonuria Information: Access Comprehensive details regarding phenylketonuria, including symptoms, causes, diagnosis, treatment options",
          "transformHorizontal": "left",
          "anchorHorizontal": "left",
        },
        {
          "id": 2,
          "onboarding_title": "Step 2",
          "onboarding_description": "Recipes: Explore a variety of diet-friendly recipes for breakfast, lunch, and dinner to support your nutritional goals.",
          "transformHorizontal": "left",
          "anchorHorizontal": "left",
        },
        {
          "id": 3,
          "onboarding_title": "Step 3",
          "onboarding_description": "Search Recipes: Find your desired recipe quickly by entering keywords, ingredients, or dish names here.",
          "transformHorizontal": "left",
          "anchorHorizontal": "left",
        },
        {
          "id": 3,
          "onboarding_title": "Step 3",
          "onboarding_description": "Search Recipes: Find your desired recipe quickly by entering keywords, ingredients, or dish names here.",
          "transformHorizontal": "left",
          "anchorHorizontal": "left",
        },
        {
          "id": 4,
          "onboarding_title": "Step 4",
          "onboarding_description": "Favorite Recipes: Save your preferred recipes for easy access and quick meal planning.",
          "transformHorizontal": "right",
          "anchorHorizontal": "right",
        },
        {
          "id": 5,
          "onboarding_title": "Step 5",
          "onboarding_description": "Categories: Explore categorized diet recipes for breakfast, lunch, dinner, and snacks to suit your nutritional preferences and needs.",
          "transformHorizontal": "left",
          "anchorHorizontal": "left",
        },
      ],
      openOnboarding: false,
      onBoardingTitle: "",
      onBoardingText: "",
      onBoardingRef: "",
      currentStep: 0,
      anchorHorizontal: "right",
      transformHorizontal: "right",
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
      loginUserType: localStorage.getItem("user_type")

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const fileUrl = window.location.pathname;
    const extractFileUrl = decodeURIComponent(fileUrl.substring(fileUrl.lastIndexOf("/") + 1));
        this.setState({ selectedSideText: extractFileUrl });
    window.scrollTo(0, 0);
    const type = await getStorageData("user_type")
    if (type) {
      this.setState({ userType: type })
    }
        const imageOptions = [
      { src: image_pill, text: "Javygtor Information" },
      { src: image_stethoscope, text: "Phenylketonuria (PKU) Information" },
      { src: image_restaurant, text: "Recipes" },
    ];

    const imageOptionSec = [
      { src: image_stethoscope, text: "Phenylketonuria (PKU) Information" },
      { src: image_restaurant, text: "Recipes" },
    ];

    const realMeta = await getStorageData("meta");
    const meta = JSON.parse(realMeta)
    
    this.categoryGet(meta);
    this.recipesGet(meta, "3");

    this.callRecommendedRecipes(meta);
    this.callFeaturedRecipes(meta);
    this.javygatorInfoGet(meta);
    this.pkuInfoGet(meta);
    this.setState({
      meta: meta,
    },()=>{ this.checkDetailPage()
    });
    this.checkPage();
    const userType = localStorage.getItem("user_type") || "";
    const filePath = window.location.pathname;    
    if (userType === "regular") {
      this.setState({
        imageOption: imageOptionSec,
      });
      if (!filePath.includes('Recipes') ) {
        this.setState({
          selectedSideText: "Phenylketonuria (PKU) Information",
        },()=> window.history.pushState(null, '', `/PatientResources/${this.state.selectedSideText}`));
      }
  
    } else {
      this.setState({
        imageOption: imageOptions,
             });
      if (!filePath.includes('Recipes') && !filePath.includes("Phenylketonuria")) {
        this.setState({
          selectedSideText: "Javygtor Information",
        },()=> window.history.pushState(null, '', `/PatientResources/${this.state.selectedSideText}`));
      }
    }

    let onboarding = await getStorageData("onBoarding");
    if (onboarding === null) {
      setTimeout(() => {
        this.setState({ openOnboarding: true });
        this.nextOnBoarding();
      }, 500);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  checkDetailPage() {
    const filePath = window.location.pathname;
    const segments = filePath.split("/");
    const indexId = segments[segments.length - 1];
    const baseIndex = filePath.indexOf("/RecipesDetail/");
    const idPart = filePath.substring(baseIndex);
    const desiredPart = filePath.substring(filePath.indexOf("/RecipesDetail"));
    const condition = /^\/RecipesDetail\/\d+$/.test(desiredPart);
    if (condition) {
      this.setState({ selectedSideText: idPart });
      this.recipesGet(this.state.meta, indexId);
      this.setState({ openRecipes:true});
    }
  };

  checkPage() {
    const filePath = window.location.pathname;
    const segment = filePath.split("/");
    const baseValue = segment.indexOf("/RecipesDetail/");
    const idPart = filePath.substring(baseValue);
    const desiredValue = idPart.substring(filePath.indexOf("/RecipesDetail"));
    this.setState({ getRecipePath: desiredValue})
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        this.getAllData(apiRequestCallId, responseJson);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  nextOnBoarding = () => {
    const { onBoarding1, currentStep, userType, openOneScreenOnboarding } = this.state;

    if (onBoarding1.length > currentStep) {
      if (userType === "invited") {
        if (currentStep === 0 || currentStep === 1 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6) {
          this.changeStep2();
        } else if (currentStep === 2) {
          this.slectFromSide("Recipes");
          setTimeout(() => this.changeStep2(), 200);
        }
      } else {
        if (currentStep === 0 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6) {
          this.changeStep2();
        } else if (currentStep === 1) {
          this.slectFromSide("Recipes");
          setTimeout(() => this.changeStep2(), 200);
        } else if (currentStep === 2) {
          this.setState({ currentStep: currentStep + 1 }, () => this.nextOnBoarding());
        }
      }
    } else {
      if (openOneScreenOnboarding) {
        if (userType === "invited") {
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        } else {
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        }
      }
      this.resetDataFunction();
    }
  }

  resetDataFunction = () => {
    this.setState({
      openOnboarding: false,
      openOneScreenOnboarding: true,
      onBoardingBtnDisable: false,
    });
  }

  handleOpneOnboarding = () => {
    this.setState({
      openOnboarding: true,
      openOneScreenOnboarding: false,
      currentStep: 0,
    }, () => this.nextOnBoarding())
  }

  changeStep2 = () => {
    const { onBoarding2, currentStep, onBoarding1 } = this.state;
    const onBoarding = this.state.userType === "invited" ? onBoarding1 : onBoarding2;
    this.setState({
      onBoardingTitle: onBoarding[currentStep].onboarding_title,
      onBoardingText: onBoarding[currentStep].onboarding_description,
      transformHorizontal: onBoarding[currentStep].transformHorizontal,
      anchorHorizontal: onBoarding[currentStep].anchorHorizontal,
      currentStep: currentStep + 1
    })
    const targetRef = this.state.onboardingRefs[currentStep];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }

  skipOnBoarding = async () => {
    this.setState({
      openOnboarding: false,
      currentStep: 0
    })
    this.resetDataFunction();
    await setStorageData("onBoarding", "true");
  }

  getAllData = (ides: string, responseJson: ThirdObj) => {
    if (this.advanceSearchId === ides && !responseJson.errors) {
      if (responseJson.message) {
        this.setState({ advanceSearchDataNot: responseJson?.message || "", advanceSearchData: [], });
      } else {
        this.setState({ advanceSearchData: responseJson?.data || [], advanceSearchDataNot: "" });
      }
    } else if (this.getcategoriesApiId === ides && !responseJson.errors) {
      this.setState({ categorys: responseJson.data || [], selectedFoodType: responseJson.data[0].attributes.name || "", });
      this.callCategoryBasedRecipes({ token: "" }, responseJson.data[0].attributes.id);
    }
    { this.resonceApi(ides, responseJson) }

  }

  resonceApi = (ides: string, responseJson: any) => {
    if (this.recipesGetApiId === ides && !responseJson.errors) {
      this.setState({ recipeData: responseJson.data || [] });
        } if (this.callCategoryBasedRecipesId === ides && !responseJson.errors) {
      this.setState({ catagoryData: responseJson.data || [], });
    } if (this.callRecommendedRecipesId === ides && !responseJson.errors) {
      this.setState({ recommendedRecipes: responseJson.data || [] });
    } if (this.callFeaturedRecipesId === ides && !responseJson.errors) {
      this.setState({ featuredRecipes: responseJson.data || [], });
    } if (this.favouritesPostId === ides && !responseJson.errors) {
      this.likesItem();
      const idFavouritesTick = responseJson?.data?.attributes?.favouriteable_id;
      this.setState((prevState) => ({
        ...prevState, favouritesTick: [...prevState.favouritesTick, idFavouritesTick],
      }));
    } if (this.removefavouritesPostId === ides && !responseJson.errors) {
      this.likesItem();
    } if (this.favoriteAllData === ides && !responseJson.errors) {
      this.setState({ favoriteAllDatas: responseJson.data || [] });
    } if (this.javygatorInfoId === ides && !responseJson.errors) {
      this.setState({ javygatorInfoData: responseJson?.data?.attributes });
    } if (this.pkuInfoId === ides && !responseJson.errors) {
      this.setState({ pkuInfoData: responseJson?.data?.attributes });
    }
    this.setState({ isLoading: false })
  }

  slectFromSide = (value: string) => {
        this.setState({
      selectedSideText: value
    },()=> window.history.pushState(null, '', `/PatientResources/${this.state.selectedSideText}`));
  };
  exploreRecipes = () => {
    this.setState({
      serchText: "",
      advanceSearchDataNot: "",
      viewAllState: false, viewAllText: "",
      favoriteState: false,
      showCloseIcon: false,
      selectedFoodType: "Snacks"
    })
  };

  likesItem = () => {
    const { meta } = this.state;
    this.callRecommendedRecipes(meta)
    this.callFeaturedRecipes(meta)
    this.categoryGet(meta);
    this.searchFavourite();
  }

/* istanbul ignore next */
  favouritesItems = (value: string, type: string, is_favourite: boolean, favRapices: any, likeItems: string) => {
    const updatedRecipes = favRapices.map((recipe: { id: any; type: string; attributes: { is_favourite: boolean; }; }) => {
      if (recipe.id === value && recipe.type === type) {
        recipe.attributes.is_favourite = !recipe.attributes.is_favourite;
      }
      return recipe;
    });

    {/* @ts-ignore */ }
    this.setState({ [favRapices]: updatedRecipes, likeItem: likeItems });

    this.setState(prevState => ({
      recipeData: {
        ...prevState.recipeData,
        attributes: {
          ...prevState.recipeData?.attributes,
          is_favourite: !is_favourite
        }
      }
    }));
    
    const { favouritesTick } = this.state;
    const parsedValue = Number(value);

    if (is_favourite) {
      const filteredFavourites = favouritesTick.filter(
        (item: number) => item !== parsedValue
      );
      this.setState({ favouritesTick: filteredFavourites });

      const verifyCodeSignMes = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_favourites/favourites/${value}`
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": "application/json",
          token: this.state.meta.token,
        })
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({})
      );

      this.removefavouritesPostId = verifyCodeSignMes.messageId;
      runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);

    } else {

      const verifyCodeSignMes = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_favourites/favourites`
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": "application/json",
          token: this.state.meta.token,
        })
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      verifyCodeSignMes.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            favouriteable_id: value,
            favouriteable_type: "BxBlockContentManagement::Recipe",
          },
        })
      );
      
      this.favouritesPostId = verifyCodeSignMes.messageId;
      runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
    }

  };
  categoryGet = (meta: { token: string }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getcategoriesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  javygatorInfoGet = (meta: { token: string }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.javygatorInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/javygator_info`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  pkuInfoGet = (meta: { token: string }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pkuInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/pku_info`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openRecipes = (ides: string, value: string) => {
    this.setState({ openRecipes: true, });
    this.recipesGet(this.state.meta, ides);
    this.setState({ getOpenRecipeID: ides});
    window.history.pushState(null, '', `/PatientResources/RecipesDetail/${ides}`);
  };

  recipesGet = (meta: { token: string }, index: number | string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recipesGetApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/recipes/${index}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewAllOpen = (value: string) => {
    this.setState({
      viewAllState: true,
      viewAllText: value,
      viewAllTexts: value,
    });
  };

  openFavorite = (value: string) => {
    if (!this.state.favoriteState) {
      this.setState({
        viewAllState: true,
        favoriteState: !this.state.favoriteState,
        viewAllText: value,

      });
    } else {
      if (this.state.viewAllTexts.length > 1) {
        this.setState({
          viewAllState: true,
          favoriteState: !this.state.favoriteState,
          viewAllText: this.state.viewAllTexts,
        });
      } else {

        this.setState({
          viewAllState: false,
          favoriteState: false,
          viewAllText: "",
        });
      }
    }
    this.searchFavourite();
  };
  searchFavourite = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favoriteAllData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/recipes/favourite_recipes`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: this.state.meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectFoodType = (value: string, index: number) => {
    this.setState({
      selectedFoodType: value,
    });
    this.callCategoryBasedRecipes({ token: "" }, index);
  };

  callCategoryBasedRecipes = (meta: { token: string }, index: number | string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callCategoryBasedRecipesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/recipes/category_based_recipes?category_id=${index}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token || this.state.meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callRecommendedRecipes = (meta: { token: string }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callRecommendedRecipesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/recipes/recommended_recipes`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callFeaturedRecipes = (meta: { token: string }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.callFeaturedRecipesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/recipes/featured_recipes`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchRecipes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.setState({
      serchText: value,
      showCloseIcon: !!value
    });
    this.advanceSearchGet(value);
  };

  clearSearchText = () => {
    this.setState({
      serchText: '',
      showCloseIcon: false
    });
  };

  advanceSearchGet = (value: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advanceSearchId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/search/search_recipes?name=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: this.state.meta.token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  backButton = () => {
    this.setState({ openRecipes: false ,selectedFoodType: "Snacks",selectedSideText:"Recipes"},()=> window.history.pushState(null, '', `/PatientResources/Recipes`));
  }

  viewAllBackButton = () => {
    this.setState({ viewAllState: false, viewAllText: "",selectedFoodType: "Snacks" });
  }

  redirectToLogin = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    navMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navMes);
  }; 
  // Customizable Area End
}