import React, { Component } from "react";
import { leftSideImg, rightSideImg } from "./assets";
import { Box, styled } from "@material-ui/core";
type MyProps = {
  
};

export default class CustomBG extends Component<MyProps> {


  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  render() {
      return (
        <MainContainerbox>
            {this.props.children}
            <img src={leftSideImg} alt="leftSideImg" className="ImgOne" />
            <img src={rightSideImg} alt="rightSideImg" className="ImgTwo" />
        </MainContainerbox>
      );
    }
}
const MainContainerbox = styled(Box)({
    height:'100vh',
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
    justifyItems: "center",
    "& .ImgOne": {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: -1,
        minWidth: 214,
        maxWidth: 254,
        "@media (max-width: 1000px)": {
            display: "none",
        },
    },
    "& .ImgTwo": {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: -1,
        minWidth: 214,
        maxWidth: 254,
        "@media (max-width: 1000px)": {
            display: "none",
        },
    },
})

