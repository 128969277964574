import React, { Component, RefObject } from "react";
import { Box, Button, Card, CardContent, Container, Dialog, DialogTitle, Divider, Grid, Popper, TextField, Typography, styled } from "@material-ui/core";
import { calender, notificationCal, notificationCalCheck, notificationFire, notificationIcon, notificationImgDots, notificationMeg, notificationPin, notificationTrofi, notificationWatch, poperNoNotification } from "./assets";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { Link } from "react-router-dom";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { IBlock } from "../../framework/src/IBlock";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


type MyProps = {
    title: string,
    icon: string,
    tip?: string
    webHeaderCelender?: any,
    iconTwo?: string,
    isFutuereDate?: boolean,
    caladerref?: RefObject<HTMLDivElement>,
    notificationref?: RefObject<HTMLDivElement>,
    openOnBoarding?: any,
    navigation?: {
        navigate: Function
    }
    getNotificationDot?:boolean,
    isIndex?:boolean
};

interface NotificationResponse {
    today: {
        message: string
        data: Notification[]
        yesterday: {
            message: string
            data: Notification[]
        }
    }
}

interface Notification {
    mainText: string;
    id: string,
    icon: string;
    mainName: string;
    secondaryNamePrefix: string;
    boldText: string;
    time: string;
    type: string;
    attributes: {
        id: number;
        headings: string;
        contents: string;
        created_at: string;
        updated_at: string;
        account_id: number;
        notification_type: string;
        notification_details_id: number;
        notification_status: string;
        redirect_to: string;
    };
}

type stateType = {
    token: string;
    isModel: boolean,
    openDialog: boolean,
    selectedDate: string | Date,
    isDatePickerOpen: boolean,
    showMenu: boolean;
    anchorEl: HTMLElement | null;
    isnotificationsText: Notification[];
    getNotificationApi11: NotificationResponse | null;
    getOlderNotificationApi: NotificationResponse | null;
    todayNotifications: Notification[];
    yesterdayNotifications: Notification[];
    olderNotifications: Notification[];
    unreadNotifications: Notification[];
    statusIsActive: null | boolean;
    isDisable:boolean;
    openDate: boolean;
};


export default class WebHeader extends Component<MyProps, stateType> {
    send : (navMes: Message) => void;
    getNotificationCallId: string = "";
    getOlderNotificationCallId: string = "";
    putUpdateNotificationCallId: string = "";
    subScribedMessages: string[];
    getShowNotificationCallId:string = "";

    constructor(props: MyProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = message => runEngine.sendMessage('',message);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];
        this.state = {
            token: "",
            isModel: false,
            openDialog: false,
            selectedDate: new Date(),
            isDatePickerOpen: false,
            showMenu: false,
            anchorEl: null,
            isnotificationsText: [],
            getNotificationApi11: null,
            getOlderNotificationApi: null,
            todayNotifications: [],
            yesterdayNotifications:[],
            olderNotifications: [],
            unreadNotifications: [],
            statusIsActive: null,
            isDisable:false,
            openDate: false,
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getNotificationCallId) {
                    this.setState({ getNotificationApi11: responseJson.data, todayNotifications: responseJson.data.today.data, yesterdayNotifications: responseJson.data.yesterday.data || [] },()=> this.checkUnread())
            }
            if (apiRequestCallId === this.getOlderNotificationCallId) {
                this.setState({ getOlderNotificationApi: null, olderNotifications: responseJson.data || [] }, async () => {
                    await this.checkUnread()
                })
            }
            if (apiRequestCallId === this.getShowNotificationCallId) {
                setStorageData("notifyStatus", responseJson?.unread)
                this.setState({ statusIsActive: responseJson?.unread })
            }
            if (apiRequestCallId === this.putUpdateNotificationCallId ) {
                this.getNotification();
                this.getOlderNotification();
              }
        }
    }

   
    putUpdateNotification =  (index: string, type: string) => {    
        const header = {
          token:this.state.token,
        };
        if(type === "treatment_reminder"){
            return 
        }
        const apiRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.putUpdateNotificationCallId = apiRequestMessage.messageId;
    
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `notifications/notifications/${index}?notification_status=read`
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
    
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
      }

    checkUnread = () => {
        const { todayNotifications = [], yesterdayNotifications = [], olderNotifications = [] } = this.state || {};
        
        const todayUnreadNoti = todayNotifications.filter((item) => item.attributes.notification_status === "unread");
        const yesterdayUnreadNoti = yesterdayNotifications.filter((item) => item.attributes.notification_status === "unread");
        const olderUnreadNoti = olderNotifications.filter((item) => item.attributes.notification_status === "unread");
        
        this.setState({
            unreadNotifications: [...todayUnreadNoti, ...yesterdayUnreadNoti, ...olderUnreadNoti]
        });
    }
    

    async componentDidMount() {
        const meta = await getStorageData("meta")
        const metaToken = JSON.parse(meta)
        const authToken = await getStorageData("authToken")
        const token = authToken || metaToken?.token
        const status = await getStorageData("notifyStatus")
        if(status) {
            this.setState({
                statusIsActive: status
            })
        }
        this.setState({ token }, () => { this.getNotification() ,this.getShowNotification(), this.getOlderNotification()})
        let route = window.location.pathname 
        if(route == '/Pushnotifications'){
            this.setState({isDisable:true})
        }
        const notificationId = window.location.pathname
        const splitPath = notificationId.split("/")
        const originalId = splitPath[splitPath.length - 1]
        
        if(this.isNumber(Number(originalId)) && !notificationId.includes('Pushnotifications')){
            this.setState({ token }, () => { this.putUpdateNotification(originalId, "read")})
        }
    }
    isNumber = (value :string | number) => {
        return typeof value === 'number' && !isNaN(value);
    }
    handleDateChange = (date: any) => {
        this.setState({ selectedDate: date});
        this.props?.webHeaderCelender?.(date)
    }

    handleMenuClose() {
        this.setState({ showMenu: false, anchorEl: null});
    }

    toggleDatePicker = () => {
        this.setState({
            openDate: false
        });
    };

    calenderModel = () => {
        this.setState({ openDialog: true });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (this.state.isDisable ){
            return
        }
        this.setState({
            showMenu: !this.state.showMenu,
            anchorEl: this.state.anchorEl ? null : event.currentTarget
        });
    };


    getNotification = () => {
        const { token } = this.state

        const header = {
            token,
            "Content-Type": "application/json",
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getNotificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "notifications/notifications"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getOlderNotification = () => {
        const { token } = this.state

        const header = {
            token,
            "Content-Type": "application/json",
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getOlderNotificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "notifications/older_notifications"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getShowNotification = () => {
        const { token } = this.state

        const header = {
            token,
            "Content-Type": "application/json",
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getShowNotificationCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "notifications/status"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

 

   async componentDidUpdate(prevProps: Readonly<MyProps>, prevState: Readonly<stateType>, snapshot?: any) {
        const status = await getStorageData("notifyStatus")        
        const user = await getStorageData("user_type")    
            
        if(status && this.state.statusIsActive && status !== this.state.statusIsActive?.toString()){
            this.getShowNotification()
        }
    }

    handleOpenPicker = () => {
        this.setState({
            openDate: true,
            showMenu: false,
            anchorEl:null
        })
    }

    handleClosePicker = () => {
        this.setState({
            openDate: false,
        })
    }
    render() {
        const { title, icon, iconTwo, tip, caladerref,isIndex, openOnBoarding ,notificationref} = this.props;
        return (
            <ThemeProvider theme={theme}>
            <MainHeaderBox>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <div className="container">
                            <img src={icon} alt="profileIcon" className="icon"></img>
                            <p className="title">{title}</p>
                            <img src={tip} style={{ marginLeft: "10px", cursor: "pointer" }} onClick={openOnBoarding} />
                            {(iconTwo && <img src={iconTwo} alt="iicon" className="iicon" onClick={openOnBoarding}></img>)}
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <div className="msgiconBox" style={{ marginRight: "15px" }}>
                                <div className="massageImg" ref={title === "Dashboard" ? notificationref : null}>
                                
                                    <img src={this.state.unreadNotifications.length !== 0 ? notificationImgDots  : notificationIcon} onClick={this.handleMenuOpen} /> 
                                </div>
                                <Popper
                                    open={this.state.showMenu} 
                                    anchorEl={this.state.anchorEl} placement="bottom-end" 
                                    style={styles.poperMainGrid}
                                    >
                                        <Container maxWidth="xs">
                                            <Card style={styles.popperBorderCss}>
                                                <CardContent style={styles.notificationMainTitle}>
                                                    <Typography variant="h6" gutterBottom style={styles.popperMainText}>Notification</Typography>
                                                    {this.state.unreadNotifications.length !== 0 ?
                                                        <>{this.state.unreadNotifications.slice(0, 3).map((notification, index) => (
                                                            <Link to={notification.attributes.redirect_to} style={styles.removeUnderline} onClick={() => this.putUpdateNotification(notification.id, notification.attributes.notification_type)}>
                                                                <div key={index} style={styles.clickPointer}>
                                                                    <div style={styles.popperFirstNo}>
                                                                        {notification.attributes.headings === "Refill Alert" && (
                                                                            <img style={styles.notificationIconCss} src={notificationPin} alt="notificationPin" />
                                                                        )}
                                                                        {notification.attributes.headings === "Treatment Alert" && (
                                                                            <img style={styles.notificationIconCss} src={notificationWatch} alt="notificationWatch" />
                                                                        )}
                                                                        {notification.attributes.headings === "Don't Break Your Streak!" && (
                                                                            <img src={notificationFire} alt="notificationFire" />
                                                                        )}
                                                                        {notification.attributes.headings === "Adherence Milestone: 1 Week!" && (
                                                                            <img src={notificationPin} style={styles.notificationIconCss} alt="notificationPin" />
                                                                        )}
                                                                        {notification.attributes.headings === "Message Notification" && (
                                                                            <img src={notificationMeg} alt="notificationMsg" style={styles.notificationIconCss} />
                                                                        )}
                                                                        {notification.attributes.headings.includes("Event") && (
                                                                            <img src={notificationCal} style={styles.notificationIconCss} alt="notificationCal" />
                                                                        )}
                                                                        {notification.attributes.headings.includes("Milestone") && (
                                                                            <img src={notificationCalCheck} style={styles.notificationIconCss} alt="notificationCalCheck" />
                                                                        )}
                                                                        {notification.attributes.headings === "Streak Broken" && (
                                                                            <img src={notificationFire} style={styles.notificationIconCss} alt="notificationFire" />
                                                                        )}
                                                                        {notification.attributes.headings === "Badge Earned!" && (
                                                                            <img src={notificationTrofi} style={styles.notificationIconCss} alt="notificationTrofi" />
                                                                        )}
                                                                        {notification.attributes.headings === "Week-Long Streak Achieved!" && (
                                                                            <img style={styles.notificationIconCss} src={notificationFire} alt="notificationFire" />
                                                                        )}
                                                                        <div>
                                                                            <Typography variant="body1" style={styles.notificationFirstText}>{notification.attributes.headings}</Typography>
                                                                            <PropertyDeveloperCss>
                                                                                <Typography className={notification.attributes.notification_status === "read" ? 'todaycontect' : ''} dangerouslySetInnerHTML={{ __html: notification.attributes.contents }} style={styles.textSecoundaryName} />
                                                                            </PropertyDeveloperCss>
                                                                        </div>
                                                                    </div>
                                                                    {index < this.state.unreadNotifications.slice(0, 3).length - 1 && <Divider style={styles.dividerColor} />}
                                                                </div>
                                                            </Link>
                                                        ))}
                                                        </>
                                                        :
                                                        <>
                                                            <Grid style={styles.withOutDataCantent}>
                                                                <img style={{ justifyContent: "center", display: "flex", width: "100%", marginBottom: "26px", height: "230px" }} src={poperNoNotification} />
                                                            </Grid>
                                                            <Grid style={styles.cantentBothData}>
                                                                <Typography style={styles.withOutCantentFirst}>No notifications yet!</Typography>
                                                                <Typography style={styles.withOutCantentSecound}>Create new alerts from the Reminder tab,<br /> schedule events on the calendar, or stay <br /> tuned for messages from your health expert.</Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Link to="/Pushnotifications" style={styles.removeUnderline}>
                                                        <Button color="primary" style={styles.popperButton}>See all notifications</Button>
                                                    </Link>
                                                </CardContent>
                                            </Card>
                                        </Container>
                                </Popper>
                            </div>
                            <Divider style={{ backgroundColor: "#000", height: "40px", width: "2px" }} />
                            <div className="msgiconBox" style={{ margin: "0px 0px 0px 15px" }}>
                                <div className="massageImg" ref={title === "Log Book" ? caladerref : null}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} >
                                        <StartDateCalendar
                                            autoOk
                                            disableToolbar
                                            disabled={isIndex}
                                            variant="inline"
                                            format="DD MMM, YYYY"
                                            onOpen={() => this.handleOpenPicker()}
                                            margin="normal"
                                            onClose={this.toggleDatePicker}
                                            value={this.state.selectedDate}
                                            onChange={this.handleDateChange}
                                            InputProps={{
                                                id: "teststart",
                                                disableUnderline: true,
                                                style: styles.pickerFields,
                                            }}
                                            keyboardIcon={<img src={calender} style={styles.pickerIcon} alt="calendar_startdate" />
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeaderBox>
            </ThemeProvider>
        );
    }
}
export const PropertyDeveloperCss = styled(Box)({
    '& .todaycontect': {
        '& strong': {
            fontWeight: 400,
        }
    },
});

const theme = createTheme({
    palette: {
      primary: {
        main: "#13689E",
        contrastText: "#fff"
      }
    },
    overrides: {
      MuiSelect: {
        select: {
          paddingRight: "4px !important",
          "&:focus": {
            backgroundColor: 'none'
          }
        }
      },
      MuiListItem: {
        root: {
          ":hover, &.Mui-selected": {
            backgroundColor: "transparent !important",
            fontWeight: 600,
          },
        },
      },
      MuiMenuItem: {
        root: {
          "&:hover, &.Mui-selected ": {
            backgroundColor: "transparent !important",
          },
        },
      },
      MuiGrid: {
        root: {
          "&.MuiGrid-spacing-xs-2": {
            "@media (max-width: 960px)": {
              marginTop: "20px"
            },
          },
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: "12px",
          background: "#F8FAFC",
          position: "absolute",
          top: "266px",
          left: "732px",
        },
        list: {
          color: "#13689E",
          fontFamily: 'Raleway',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
  
        },
      },
      MuiPickersDay: {   
        daySelected: {
          
          "&:hover": {
            backgroundColor: "#D1EDFF !important",
          },
          backgroundColor: "#D1EDFF !important",
          color: "#13689E !important",
          borderRadius: "50%",
          border: "0.8px solid #68B9EC"
      },
      current: { color: "none !important" },
  
   
    },
      MuiPickersCalendarHeader: {
        transitionContainer: {
          "& p": {
            color: "#0F172A",
            fontSize: "11.13px",
            fontWeight: 700,
            textAlign: "center",
            cursor:"context-menu",
            fontFamily: "Raleway !important",
          },
        },
        dayLabel: {
          fontSize: "11.13px !important",
          color: "#64748B",
          fontWeight:500,
          cursor:"context-menu",
          fontFamily: "Raleway !important",
        },
      },
      MuiPopover: {
        paper: {
          border: "0.8px solid #E2E8F0",
          borderRadius:"12.73px",
          top: "95px !important",
        },
      },
      MuiTypography: {
        body2: {
          fontFamily: "Raleway !important",
        },
      },
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
      },
      
    } as  {
      MuiPickersDay?: {
        daySelected?: {
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
      MuiIconButton?: {
        root?: {
          "&:hover"?: {
            backgroundColor: string;
          };
        };
      };
    },
  });

const StartDateCalendar = styled(KeyboardDatePicker)({
    width: "112px", 
    height:"32px",
    borderRadius: '26px',
    fontWeight: 500,
    fontFamily:"Raleway",
    color:"#334155",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    margin:0,
  });
  

const MainHeaderBox = styled(Box)({
    display: "flex",
    background: "#ffffff",
    paddingInline: "24px",
    paddingBlock: '10px',
    borderBottom: "1px solid #E2E8F0",
    "& .container": {
        display: "flex",
        alignItems: "center"
    },
    "& .icon": {
        width: "44px",
        height: "55px",
        marginRight: "25px"
    },
    "& .title": {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Raleway",
    },
    "& .iicon": {
        paddingLeft: "11px",
        cursor: "pointer",
    },
    "& .msgiconBox": {
        position: "relative",
        marginRight: "30px",
    },
    "& .massageImg": {
        height: "45px",
        width: "45px",
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014, 0px 2px 8px 0px #00000014",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        cursor: "pointer"
    },
});


const styles = {
    notificationSecoundText: { fontFamily: 'Raleway', fontSize: '14px', fontWeight: 400, lineHeight: '16px', textAlign: 'left' as 'left', color: "#0F172A" },
    notificationMainTitle: { padding: "15px" },
    notificationIconCss: { backgroundColor: "#F8FAFC", borderRadius: "15px", padding: "6px" },
    popperMainText: { fontFamily: 'Raleway', fontSize: '23px', fontWeight: 700, lineHeight: '32px', textAlign: 'left' as 'left', color: "#0F172A" },
    popperFirstNo: { display: "flex",alignItems: "center", gap: "20px", padding: "11px 7px 19px 0" },
    dividerColor: { color: "#E2E8F0" },
    textSecoundaryName: { fontFamily: 'Raleway', fontSize: '14px', fontWeight: 400, lineHeight: '16px', textAlign: 'left' as 'left', color: '#64748B',width: '90%'},
    poperMainGrid: { marginTop: "40px", },
    popperBorderCss: { borderRadius: "15px", padding: "15px" },
    clickPointer:{cursor: "pointer"},
    notificationFirstText: { fontFamily: 'Raleway', fontSize: '16px', fontWeight: 600, lineHeight: '24px', textAlign: 'left' as 'left', color: "#0F172A" },
    popperButton: { width: '100%', height: '48px', padding: '12px 16px', gap: '8px', borderRadius: '50px ', backgroundColor: "#13689E", textTransform: "none" as "none", fontFamily: 'Raleway', fontSize: '16px', fontWeight: 700, lineHeight: '24px', textAlign: 'left' as 'left', color: "#FFFFFF",textDecoration: 'none' },
    withOutDataCantent:{ display: "flex", justifyContent: "center", marginTop: "35px" },
    cantentBothData: { margin: "35px 0px 20px 0px" },
    withOutCantentFirst: {fontFamily: 'Raleway',fontSize: '20px',fontWeight: 600,lineHeight: '28.18px',letterSpacing: '0.011em',textAlign: 'center' as 'center',color: "#151515"},
    withOutCantentSecound: {fontFamily: 'Raleway',fontSize: '14px',fontWeight: 500,lineHeight: '24px',letterSpacing: '0.011em',textAlign: 'center' as 'center',color: "#475569"},
    removeUnderline: { textDecoration : "none"},
    pickerFields: { fontFamily: "Raleway",fontWeight: 500, fontSize: "12px", color: "#334155", paddingRight: "5px"},
    pickerIcon: {marginRight:'2px'},  
}
