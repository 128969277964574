import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: string;
    id: string;
    // Customizable Area Start
    classes: string;
    location: string;
    history: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    pdfselectopen:boolean,
    email: string,
    subject: string,
    yourquery: string,
    file: unknown | null ,
    selectedFile:File[],
    open: boolean,
    complteopen : boolean,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contactusApiCallid: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            open:false,
            complteopen:false,
            pdfselectopen:false,
            selectedFile:[],
            email: '',
            subject: '',
            yourquery: '',
            file: '',
        }
        // Customizable Area Start

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area End
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.contactusApiCallid) {
                    this.setState({ complteopen: true });
                }
            }
        }

    };
    // Customizable Area End

    // web events

    // Customizable Area Start
    contactusDataaValue = (values:{email:string,subject:string,yourquery:string}) => {
        this.setState({
            email: values.email,
            subject: values.subject,
            yourquery: values.yourquery,
           
        })
        
        this.contactusDataAdd(values)
    };

    openPictureUpload = async (): Promise<void> => {
        const inputPicherPortable = document.createElement('input');
        inputPicherPortable.type = 'file';
        inputPicherPortable.accept = ".jpg,.jpeg,.pdf";
        inputPicherPortable.multiple = true;
        inputPicherPortable.click();
        inputPicherPortable.setAttribute('data-testid', 'file-input');
        inputPicherPortable.onchange = (event: Event) => {
            if (event.target instanceof HTMLInputElement) {
                this.openPictureUploadInSide(event.target.files);
            }
        };
    };

    handleClose = () => {
        this.setState({ open: false });
    };

     /* istanbul ignore next */
     openPictureUploadInSide = async (files: FileList | null): Promise<void> => {
        if (!files) return;
        const uplodeFilesArray = Array.from(files);
        uplodeFilesArray.forEach(async (file: File, index: number) => {
            const extet: string[] = file.name.split('.');
            if (this.state.selectedFile.length < 5) {
                if (["jpg", "jpeg", "pdf"].includes(extet[extet.length - 1].toLowerCase())) {
                    this.setState({ selectedFile: [...this.state.selectedFile, file] });
                } else {
                    this.setState({ pdfselectopen: true });
                }
            } else {
                this.setState({ open: true });
            }
        });
    };

    complteclosed = () => {
        this.setState({ complteopen: false });
    };

    pdfValidationclosed = () => {
        this.setState({ pdfselectopen: false });
    };

    propertyImageUpload = (formData: FormData) => {

        if (this.state.selectedFile?.length > 0) {
            this.state.selectedFile.forEach((file: File, index: number) => {
                file && formData.append("data[images][]", file);
            })
        }
        this.setState({selectedFile:[]})
    };

    handleRemoveMain = (ind: number) => {
        const newDoument = [...this.state.selectedFile]
        newDoument.splice(ind, 1)
        this.setState({ selectedFile: newDoument })
    };

    getBorderstyle = (touched?:boolean | undefined,errors?:string) =>{
     return touched && errors ? '1px solid red' : '1px solid rgb(203, 213, 225)'
    };

    contactusDataAdd = async (value: { email: string; subject: string; yourquery: string; }) =>{

        const header = {
            
        };

        let formdata = new FormData();
        formdata.append("data[email]", value.email);
        formdata.append("data[subject]", value.subject);
        formdata.append("data[description]", value.yourquery);

        this.propertyImageUpload(formdata)


        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.contactusApiCallid = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.contactusEndpoint}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    // Customizable Area End
}
