// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import Cookies from "js-cookie";
export interface Props {
  id: string;
  navigation: any;
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  step: number;
  modalTypeError: boolean;
  isAgree: boolean;
  emailError: string;
  initialized: boolean;
  data: any;
  btnDisabel: boolean;
  loading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailRegistrationWithInvoutCodeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpWithoutID: string = "";
  apiGoogleLoginId: string = "";
  apiGetCountryCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
   
    this.state = {
      // Customizable Area Start
      step: 0,
      modalTypeError: false,
      isAgree: false,
      emailError: "",
      initialized: false,
      data: null,
      btnDisabel: false,
      loading: false,
      // Customizable Area End
    };
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
   /* istanbul ignore next */
 async componentDidMount(){
  this.position()
  const data = await getStorageData("datas")
   
   if(data){
   this.setState({
    data: JSON.parse(data)
   })}else{
    this.setState({
      data: true
    })
   }
  let isChecked = await getStorageData("isChecked")  
  if(isChecked === 'true'){
 this.setState({isAgree: true })
  }
  
}
 /* istanbul ignore next */
  position = async () => {
    navigator.geolocation.getCurrentPosition(
      position =>
        this.handleCountryApi(position.coords.latitude, position.coords.longitude),
      err => console.log(err)
    );
  }
 /* istanbul ignore next */
  handleCountryApi = (latitude: number, longitude: number) => {
    const getCountryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCountryCallId = getCountryMessage.messageId;
    getCountryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    getCountryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCountryMessage.id, getCountryMessage);
  };


handleTermsCondition = (props: any) => {
  props.setFieldValue("tc", !props.values.tc)
  this.setState({isAgree: !this.state.isAgree})
  setStorageData("isChecked", JSON.stringify(!this.state.isAgree))
}


  loginWithGoogle = (e: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const body = {
      "data": {
        "type": "social_account",
        "attributes": {
          "email": e.profileObj?.email,
          "user_type": "regular",
          "full_name": e.profileObj?.name,
          "unique_auth_id": e.googleId,
          "is_terms_accepted": true,
        }
      }
    }

    const requestApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGoogleLoginId = requestApiMessage.messageId;
    requestApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginAPiEndPoint
    );
    requestApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  }
    /* istanbul ignore next */
  handleGoogleSignUp = async () => {
    const signAuth = getAuth()
    const googleProvider = new GoogleAuthProvider();
    const result =  await signInWithPopup?.(signAuth, googleProvider).catch((error)=>console.log("err", error))

    const notificationToken = Cookies.get("notificationToken")
      if(result) {
        const googleUser: any = result.user
        let fname = googleUser.displayName?.split(" ")[0]
        let lname = googleUser.displayName?.split(" ")[1]
        let values = {
          data: {
            type: "social_account", //social_account
            attributes: {
              full_name: googleUser.displayName,
              last_name: lname,
              email: googleUser.email,
              first_name: fname,
              is_terms_accepted: true,
              unique_auth_id: googleUser.uid,
            },
            device_type:'web',
            device_id: notificationToken
          }
        }
        this.verifySignUpAPiWithOut(values, "google")
      }
  }
   /* istanbul ignore next */
  handleAppleSignUp = async () => {
    const authSignup = getAuth()
    const provider = new OAuthProvider('apple.com');
    const result =  await signInWithPopup?.(authSignup,provider).catch((error)=>console.log("err", error))

      if(result) {
        const googleUser: any = result.user
        let fname = googleUser.displayName?.split(" ")[0]
        let lname = googleUser.displayName?.split(" ")[1]
        let values = {
          data: {
            type: "social_account", //social_account
            attributes: {
              full_name: googleUser.displayName,
              last_name: lname,
              email: googleUser.email,
              first_name: fname,
              is_terms_accepted: true,
              unique_auth_id: googleUser.uid,
            },
            device_id: Cookies.get("notificationToken"),
            device_type:'web'
          }
        }
        this.verifySignUpAPiWithOut(values, "apple")
      };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.signUpAPIRec(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

   /* istanbul ignore next */
  signUpAPIRec = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetCountryCallId) {
      setStorageData("location",responseJson?.address.country_code) 
    }
    if (apiRequestCallId === this.signUpWithoutID && !responseJson.errors) {
      if(responseJson.meta.message === 'You are already registered with given email.'){
        this.setState({
          emailError: responseJson.meta.message,
          loading: false
        })
      }else{
        localStorage.removeItem("datas");
        setStorageData("user_type", responseJson.data.attributes.user_type);
        setStorageData("emailId", JSON.stringify(responseJson.data.attributes.email));
        this.setState({
          btnDisabel: false,
          loading: false
        })
        this.props.navigation.navigate("SignupMFA");
        setStorageData("meta", JSON.stringify(responseJson.meta));
        setStorageData("authToken", responseJson?.meta?.token);
      }
    } else {
      this.setState({
        emailError: responseJson.errors[0].account,
        loading: false
      })
    }
  };

  // Customizable Area End

  // Customizable Area Start

  goToTerms= (data: any) => {
this.props.navigation.navigate("TermsConditions");



const datas = {
  email: data?.email,
            user_type: "regular",
            password: data?.pass,
            is_terms_accepted: this.state.isAgree,
            first_name: data?.firstName,
            last_name: data?.lastName,
}
setStorageData("datas",JSON.stringify(datas) );
  }

  goToPrivacy= (data: any) => {
    this.props.navigation.navigate("PrivacyPolicy");
    const datas = {
      email: data?.email,
                user_type: "regular",
                password: data?.pass,
                is_terms_accepted: this.state.isAgree,
                first_name: data?.firstName,
                last_name: data?.lastName,
    }
    setStorageData("datas",JSON.stringify(datas) );
  }
  validatePassword = (setPassword: string) => {
    const isCapitalLetter = /[A-Z]/.test(setPassword);
    const isLowerCaseLetter = /[a-z]/.test(setPassword);
    const isNumber = /\d/.test(setPassword);
    const isMinLength = setPassword?.length >= 8;

    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
    };
  };

 
    
  verifySignUpAPiWithOut = (data: any, type?: string) => {
    this.setState({
      btnDisabel: true,
      loading: true
    })
    const signUpWithoutMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    signUpWithoutMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    signUpWithoutMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/accounts`
    );
    signUpWithoutMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    signUpWithoutMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type ? JSON.stringify(data):
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            email: data?.email || this.state.data?.email,
            user_type: "regular",
            password: data?.pass || this.state.data?.password,
            is_terms_accepted: this.state.isAgree,
            first_name: data?.firstName || this.state.data?.first_name,
            last_name: data?.lastName || this.state.data?.last_name,
          },
          device_id: Cookies.get("notificationToken"),
          device_type:'web'
        },
      })
    );
    this.signUpWithoutID = signUpWithoutMes.messageId;
    runEngine.sendMessage(signUpWithoutMes.id, signUpWithoutMes);
  };

  firstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        first_name: event.target.value,
      },
    });
  };

  lastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        last_name: event.target.value,
      },
    });
  };

  emailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      emailError: "",
      data: {
        ...this.state.data,
        email: event.target.value,
      },
    })
  };

  passwordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        password: event.target.value,
      },
    });
  };

  clearError = () => {
    
    this.setState({ emailError: "",btnDisabel: false })
  }

  // Customizable Area End
}
