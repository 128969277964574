import React from "react";
import { Box, CircularProgress, Grid, styled } from "@material-ui/core";

interface myProps {
  loading: boolean;
}

const Container = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9999,
  backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
  display: "flex",
  overflow: "auto",
  justifyContent: "center", // Fixed typo here: "conter" to "center"
  alignItems: "center", 
});
const LogoContainer = styled(Box)({
  padding: "0px 80px",
  '& .vitaLogo': {
    color: 'rgb(19, 104, 158)',
  },
});

export default function Loader() {
  return (
    <Container>
      <Grid>
      <LogoContainer>
          <Box className="loaderBox">
                <CircularProgress className="vitaLogo" size={80}/>
              </Box>
        </LogoContainer>
      </Grid>
    </Container>
  )
}
